import React, {useState} from 'react'
import {useFormik, FormikProps} from 'formik'
import {accountSchema} from '../../utils/account-main-schema'
import {AccountFormInner} from '../../components/forms/account-form-inner'
import {AccountLayout} from '../../account-layout'
import {ICustomer} from 'shared/types/user'
import { useAccount } from '../../hooks/use-account'


export const AccountMainInformationPage = () => {
    const [isEditing, setIsEditing] = useState(false)
    const {updateCustomerMutation, initialValues} = useAccount();

    const toggleEditMode = () => {        
        formik.resetForm()
        setIsEditing((prevValue) => !prevValue)        
    }

    const formik: FormikProps<ICustomer> = useFormik({
        initialValues,
        validationSchema: accountSchema,
        enableReinitialize:true,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            try {
                updateCustomerMutation.mutate(values);             
                setIsEditing(false)             
            } catch (error) {
                console.error(error)
                setStatus('Something is went wrong! Try again later.')
                setSubmitting(false)
            }
        },
    })

    return (
        <AccountLayout formik={formik} isEditing={isEditing} isEditable toggleEditMode={toggleEditMode}>
            <AccountFormInner formik={formik} isEditing={isEditing}/>
        </AccountLayout>
    )
}