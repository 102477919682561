import React from "react";
import { Link } from "react-router-dom";
import { CODES_ROUTES } from "shared/config/routes";
import { IErrorCodes } from "shared/types/codes-upload";

interface IErrorCodesTableRow {
  code: IErrorCodes
}

export const ErrorCodesTableRow: React.FC<IErrorCodesTableRow> = ({code}) => {
  return (
    <tr className='border-bottom'>
      <td>{code.code}</td>
      <td>{code.error}</td>
      <td>
        <Link
          to={CODES_ROUTES.ERROR_CODES.redirectToUploadHistory(
            code.uploads_history_id.toString()
          )}
        >
          {code.uploads_history_id}
        </Link>
      </td>
    </tr>
  )
}