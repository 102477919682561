import React from "react"
import { IApiKey } from "shared/types/user"
import { ApiTableRow } from "./api-table-row"

interface IApiTable{
    keys:IApiKey[]
    dateFormatter:(value:Date)=>string
    isExpiredToken:(value:Date)=>boolean
    toggleActiveStateOfKey:(value:IApiKey)=>void
}

export const TypeApiKey = {
    0:'Production',
    1:'Sandbox'
}

const ApiTableComponent:React.FC<IApiTable> = ({keys,dateFormatter,isExpiredToken,toggleActiveStateOfKey}) => {
    return (
        <table className='table gs-3 gy-4 gx-3 text-exl-primary'>
            <thead>
            <tr className="fw-normal fs-6 border-bottom border-gray-200 bg-light align-middle">
                <th>Key</th>
                <th>Name</th>              
                <th>Last Used</th>
                <th>Type</th>
                <th>Active</th>
            </tr>
            </thead>          
            {
                (keys&&keys?.length) ? 
                <tbody>
                    {keys.map((key:IApiKey)=>
                        <ApiTableRow 
                            key={key.id} 
                            apiKey={key} 
                            dateFormatter={dateFormatter} 
                            isExpiredToken={isExpiredToken} 
                            toggleActiveStateOfKey={toggleActiveStateOfKey}
                        />
                    )}
                </tbody> : 
                <tbody>
                    <tr className="border-bottom text-center text-muted">
                        <td colSpan={5}>No keys found with this name.</td>
                    </tr>
                </tbody>
            }                                                          
        </table>
    )
}

export const ApiTable = React.memo(ApiTableComponent)