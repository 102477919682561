import React from 'react'
import {ButtonLink} from '../../../../../shared/components/button/button-link'
import {CODES_ROUTES} from '../../../../../shared/config/routes'
import {TabMenu} from '../../../../../shared/components/tab-menu/tab-menu'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {ButtonGroup} from 'react-bootstrap'
import { useErrorCodes } from './use-error-codes'
import { ErrorCodesTable } from '../../components/table/error-codes-table/error-codes-table'
import { ErrorCodesSearchBar } from '../../components/error-codes-search-bar'
import { CODES_NAV_ROUTES } from '../../codes-routes'


export const ErrorCodesPage = () => {
    const {models, commands} = useErrorCodes()

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold m-0'>Codes</h1>
        <ButtonGroup>
          <ButtonLink to={CODES_ROUTES.CREATE_CODES.path} mode='purple'>
            Upload Codes
          </ButtonLink>
        </ButtonGroup>
      </div>
      <div className='card p-7'>
        <TabMenu routes={CODES_NAV_ROUTES} className='mb-5' />
        <div className='mb-5'>
          <ErrorCodesSearchBar
            codeValue={models.codeValue}
            setCodeValue={commands.setCodeValue}
            errorValue={models.errorValue}
            setErrorValue={commands.setErrorValue}
            uploadIdValue={models.uploadIdValue}
            setUploadIdValue={commands.setUploadIdValue}
            handleDateChangeFormValue={commands.handleDateChangeFormValue}
          />
        </div>
        <ErrorCodesTable
          codesList={models.codes}
          isLoading={models.isTableLoading}
        />
        {models.codes && models.codes.length ? (
          <Pagination
            currentPage={Number(models.values.page) || 1}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
    </>
  )
}
