import {CartApi} from 'app/api/cart-api/cart-api'
import {ICart} from 'shared/types/cart'
import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

interface ICartState {
  cartData: ICart | null
  isLoading: boolean
  isUpdating: boolean
  getCart: (userId: number) => Promise<void>
  setCart: (cart: ICart | null) => void
  changeQuantity: (quantity: number, codeId: number) => void
  setIsUpdating: (isUpdating: boolean) => void
}

export const useCartState = create<ICartState>()(
  devtools(
    (set) => ({
      cartData: null,
      isLoading: false,
      isUpdating:false,
      getCart: async function (userId: number) {
        try {
          set({isLoading: true})
          const tempCartData = await CartApi.getCart(userId)
          set({cartData: tempCartData})
          set({isLoading: false})
        } catch (e) {
          set({isLoading: false})
        }
      },
      setCart(cart: ICart | null) {
        if (!cart) {
          set({cartData: null})
          return
        }
        const codes = cart.codes.map((code) => ({
          ...code,
          quantity: code.quantity as number,
          initial_quantity: code.quantity as number,
        }))
        set({cartData: {...cart, codes}})
      },
      changeQuantity(quantity: number, codeId: number) {
        set((state) => {
          const code = state.cartData.codes[codeId]
          const vat = state.cartData.vat
          code.quantity = quantity ? quantity : null
          const amount = (
            Number(state.cartData.amount) -
            Number(code.sub_total) +
            Number(code.price) * quantity
          ).toFixed(2)
          code.sub_total = (quantity * Number(code.price)).toFixed(2)
          const total = (Number(amount) + Number(vat)).toFixed(2)
          state.cartData.codes.splice(codeId, 1, code)
          return {
            cartData: {
              ...state.cartData,
              amount,
              vat,
              total,
              codes: state.cartData.codes,
            },
          }
        })
      },
      setIsUpdating(isUpdating: boolean) {
        set({isUpdating})
      },
    }),
    {name: 'useCartState', store: 'useCartState'}
  )
)
