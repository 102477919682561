import {validationMessageType} from '../components/modals/alert-validation-product-discount-modal'
import {DiscountProduct} from '../../../../shared/types/discount-types'

const TO_FIXER: number = 2
interface IValidationProductDiscountModel {
  formData: DiscountProduct
  isGeneral: boolean
}
type GetMessagesParams = {
  newPrice: number
  purchasePrice: number
  codesPrices: number[]
  codesPurchasePrice: number[]
}
type GetMessagesCodesParams = Omit<
  GetMessagesParams,
  'newPrice' | 'purchasePrice'
>
export const calculationPriceWithDiscount = (
  discount: number,
  type: number,
  sellPrice: number
): number => {
  if (!discount || !type) return sellPrice
  if (type === 1) {
    return sellPrice - discount
  }
  if (type === 2) {
    const percentage = discount / 100
    return sellPrice - sellPrice * percentage
  }
}

export const getMessagesUsingGeneralPrice = ({
  newPrice,
  purchasePrice,
  codesPrices,
  codesPurchasePrice,
}: GetMessagesParams): validationMessageType[] => {
  if (newPrice > purchasePrice) return []
  if (newPrice === purchasePrice) return [{purchasePrice, newPrice, id: 1}]
  if (newPrice < purchasePrice) {
    return [{purchasePrice, newPrice, id: 1}]
  }
  if (codesPrices.length) {
    return codesPurchasePrice.map((group, index) => {
      if (group === newPrice)
        return {purchasePrice: group, newPrice: newPrice, id: index}
      if (group > newPrice)
        return {purchasePrice: group, newPrice: newPrice, id: index}
    })
  }
}

export const getMessagesForCodes = ({
  codesPrices,
  codesPurchasePrice,
}: GetMessagesCodesParams): validationMessageType[] => {
  if (!codesPrices.length || !codesPurchasePrice.length) return []

  const validMess =
    codesPurchasePrice.map((group, index) => {
      if (!group || !codesPrices[index]) {
        return {
          purchasePrice: group,
          newPrice: codesPrices[index] ? codesPrices[index] : 0,
          id: index,
        }
      }
      if (group === codesPrices[index]) {
        return {purchasePrice: group, newPrice: codesPrices[index], id: index}
      }
      if (group > codesPrices[index]) {
        return {purchasePrice: group, newPrice: codesPrices[index], id: index}
      }
      return null
    }) || []

  return validMess.filter((codes) => codes !== null)
}

export const getValidationMessage = (
  formData: IValidationProductDiscountModel
): validationMessageType[] => {
  if (!formData) return []

  const allPurchase =
    formData.formData?.codes.map((group) => Number(group.purchase_price)) || []
  const allPrice: number[] =
    formData.formData?.codes.map((group) =>
      calculationPriceWithDiscount(
        group.discount,
        Number(group.type),
        Number(group.sell_price)
      )
    ) || []

  if (!formData.isGeneral) {
    return (
      getMessagesForCodes({
        codesPrices: allPrice,
        codesPurchasePrice: allPurchase,
      }) || []
    )
  } else {
    return (
      getMessagesUsingGeneralPrice({
        newPrice: calculationPriceWithDiscount(
          formData.formData.discount,
          Number(formData.formData.type),
          Number(formData.formData.general_sell_price)
        ),
        purchasePrice: Number(formData.formData.purchase_price),
        codesPrices: allPrice,
        codesPurchasePrice: allPurchase,
      }) || []
    )
  }
}
