import React from 'react'
import {motion} from 'framer-motion'

interface ScrollToAnchorProps {
  idName: string
  children: React.ReactNode
  onClick?: () => void
  className?: string
}

const ScrollToAnchor: React.FC<ScrollToAnchorProps> = ({
  idName,
  children,
  onClick,
  className,
}) => {
  const scrollToAnchor = () => {
    const element = document.getElementById(idName)
    if (element) {
      const topPadding = -85
      const targetTop = element.offsetTop + topPadding
      window.scrollTo({
        top: targetTop,
        behavior: 'smooth',
      })
    }
  }


  return (
    <motion.a
      onClick={() => {
        scrollToAnchor()
        if (onClick) {
          onClick()
        }
      }}
      className={className}
    >
      {children}
    </motion.a>
  )
}

export default ScrollToAnchor
