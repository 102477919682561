import {useMemo} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {toast} from 'react-toastify'
import {useModalManager} from 'shared/context/modal-manager'
import {ICurrenciesList, OptionsApi} from 'app/api/options-api/options-api'
import {AxiosError} from 'axios'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {ICurrency} from 'shared/types/codes-upload'

export const useCurrenciesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const queryClient = useQueryClient()
  const modalManager = useModalManager()

  const queryParams = useMemo(() => {
    return {
      page: search.get('page') ? Number(search.get('page')) : 1,
    }
  }, [location.search])

  const currenciesQuery = useQuery({
    queryKey: [OPTIONS_QUERY_KEYS.currencies, queryParams],
    queryFn: async () => await OptionsApi.getCurrencies(queryParams),
    refetchOnWindowFocus: false,
  })

  const updateCurrencyMutation = useMutation<ICurrency, AxiosError<{message: string}>, ICurrency>(
    OptionsApi.updateCurrency,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [OPTIONS_QUERY_KEYS.currencies, queryParams],
          (prevData: ICurrenciesList) => {
            const currencyIndex = prevData.data.findIndex((currency) => currency.id === data.id)
            prevData.data.splice(currencyIndex, 1, data)
            return prevData
          }
        )
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.currencies, queryParams])
        toast.success('Currency updated successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleUpdateClick = (currency: ICurrency) => {
    updateCurrencyMutation.mutate(currency)
  }

  const handleCreateClick = () => {
    modalManager.open(OPTIONS_MODAL_NAME.create_currency)
  }

  const handleDeleteClick = (currency: ICurrency) => {
    modalManager.open(OPTIONS_MODAL_NAME.delete_currency, currency)
  }

  const setCurrentPage = (page: string) => {
    setSearchParams({
      page,
    })
  }

  const isSaveButtonLoading = updateCurrencyMutation.isLoading

  const isTableLoading = currenciesQuery.isLoading

  return {
    models: {
      values: queryParams,
      currencies: currenciesQuery.data ? currenciesQuery.data.data : [],
      meta: currenciesQuery.data ? currenciesQuery.data.meta : null,
      isTableLoading,
      isSaveButtonLoading,
    },
    commands: {
      setCurrentPage,
      handleUpdateClick,
      handleDeleteClick,
      handleCreateClick,
    },
  }
}
