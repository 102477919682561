import React from 'react';
import {ProductItem} from "./product-item/product-item";
import {IProductCatalogItem} from "shared/types/products";
import {LoadWrapper} from "shared/components/load-wrapper/load-wrapper";

interface IProductList {
    products: IProductCatalogItem[]
    isLoading: boolean
    onCreatePreOrder: (productId: number, quantity: number) => void
    handelAddProductToCart: (quantity: number, productId: number) => void
    isAddedToCart: boolean
}

const ProductListComponent: React.FC<IProductList> = ({
                                                          products,
                                                          isLoading,
                                                          onCreatePreOrder,
                                                          isAddedToCart,
                                                          handelAddProductToCart
                                                      }) => {
    return (
        <LoadWrapper isLoading={isLoading} >
            <div className='d-flex flex-wrap '>
                {products.length
                    ? products.map(product =>
                        <ProductItem
                            isLoading={isAddedToCart}
                            handelAddProductToCart={handelAddProductToCart}
                            key={product.id}
                            {...product}
                            onCreatePreOrder={onCreatePreOrder}/>)
                    : <div className="border-bottom text-center text-muted col-12 ">
                        <p>Nothing was found </p>
                    </div>}

            </div>
        </LoadWrapper>
    );
};

export const ProductList = React.memo(ProductListComponent);
