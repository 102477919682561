import * as React from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash.debounce'
import {forwardRef, InputHTMLAttributes, useId, useMemo} from 'react'
import {Form} from 'react-bootstrap'
import { useRef, useState } from 'react'
import Select, {components, DropdownIndicatorProps, GroupBase, InputActionMeta, SelectInstance, SingleValue } from 'react-select'
import { Icon } from '../icon/icon'
import { ICON_COLLECTION } from '../icon/icon-list'
import styles from './select.module.scss'
import { SettingsApi} from 'app/api/settings-api/settings-api'
import { ICountry  } from 'shared/types/country'
import { CUSTOMER_QUERY_KEYS } from 'shared/constants/query-keys'

export interface SelectProps{
  value?: number
  className?: string
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder']
  isDisabled?: boolean
  isLoading?: boolean
  error?: boolean
  errorText?: React.ReactNode
  name?: InputHTMLAttributes<HTMLInputElement>['name']
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus']
  tabIndex?: InputHTMLAttributes<HTMLInputElement>['tabIndex']
  onClick?: InputHTMLAttributes<HTMLInputElement>['onClick']
  onChange?: ((newValue: SingleValue<ICountry>) => void)
  onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur']
  onFocus?: InputHTMLAttributes<HTMLInputElement>['onFocus']
}

const DropdownIndicator:React.FC<DropdownIndicatorProps<ICountry, false, GroupBase<ICountry>>> = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon icon={ ICON_COLLECTION.arrowDown }/>
      </components.DropdownIndicator>
    )
  );
};

const performSearchRequest = async (searchText: string) => {
  return await SettingsApi.getCountriesByName(searchText);
}

const RootCountrySelect:React.ForwardRefRenderFunction<
  SelectInstance<ICountry, false, GroupBase<ICountry>>,
  SelectProps
> = (
  {
    value,
    className,
    placeholder,
    error,
    isDisabled = false,
    errorText,
    name,
    autoFocus,
    onChange,
    onBlur,
    onFocus,
  },
  ref
) => {
  const id = useId()
  
  const [inputText, setInputText] = useState<string>('')
  const [searchText, setSearchText] = useState<string>('')

  const { isLoading, data } = useQuery(
    searchText ? [CUSTOMER_QUERY_KEYS.countries, searchText] : [CUSTOMER_QUERY_KEYS.countries],
    async () => await performSearchRequest(searchText),
  )

  const handleSearchDebounced = useRef(
    debounce((searchText:string) => setSearchText(searchText), 300)    
  ).current

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== 'input-blur' && meta.action !== 'menu-close') {            
      setInputText(inputText)
      handleSearchDebounced(inputText)
    }
  }

  const noOptionsMessage = (obj: { inputValue: string }) => {    
    if (obj.inputValue.trim().length === 0) {
      return null
    }
    return 'No matching countries'
  }

  const selectedValue = useMemo(() => {
    return data?.find(option => option.id === value);
  }, [data, value]);

  return (
    <>
      <Select
        ref={ref}
        options={data}
        components={{
          IndicatorSeparator:null,
          DropdownIndicator,
        }}
        inputValue={inputText}      
        isLoading={!!searchText && isLoading}
        filterOption={null}
        classNamePrefix="select"
        isDisabled={isDisabled}
        id={id}
        inputId={id}
        value={selectedValue}
        formatOptionLabel={(option)=>option.official_name}
        className={className}
        autoFocus={autoFocus}
        placeholder={placeholder}        
        name={name}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onInputChange={handleInputChange}
        styles={{
          input: (base) => ({
            ...base,       
            '&::placeholder':{
              color:error&&errorText?'#ED4343':'inherit'
            }                    
          }),
          container: (base) => ({
            ...base,                                             
          }),
          singleValue:(base)=>({
            ...base,            
            paddingLeft:2,
            color:error&&errorText?'#ED4343':'#727BB5',                        
          }),
          valueContainer: (base) => ({
            ...base,
            paddingTop: 5.5,              
            paddingBottom: 5.5,                        
          }),
          dropdownIndicator:(base,state)=>({
            ...base,
            display:state.isDisabled?'none':'block'
          }),
          control: (base,state) => ({
            ...base,      
            fontSize:14,                  
            borderRadius: 6,            
            backgroundColor:state.isDisabled?'inherit':'#FAFAFF',
            border:error&&errorText?'1px solid #ED4343':'none',            
            "&:hover": {
              borderColor: error&&errorText? "":'rgba(114, 118, 117, 0.4)',
            },
            boxShadow: "none",
            cursor:state.isDisabled?'text':'pointer',   
          }),
          option: (base) => ({
            ...base,
            backgroundColor: "#fff",
            paddingLeft: 15,
            paddingRight: 15,
            color:'#000',
            cursor:'pointer',
            "&:hover": {
              backgroundColor: "#E9ECFE",
              transition:'background-color .3s ease-out'
            },
          }),
          menuList: (base) => ({
            ...base,            
            maxHeight:200,
            fontSize:14,
            border:'none',
            boxShadow:'0px 8px 15px 0px rgba(0, 0, 0, 0.10)'
          }),
          placeholder:(base) => ({
            ...base,
            paddingLeft:2,       
            whiteSpace:'nowrap',
            overflow:'hidden',
            opacity:error&&errorText?1:0.3,
            color:error&&errorText?'#ED4343':'#727BB5'
          })
        }}
      />
      {error && errorText && (
        <Form.Control.Feedback className={styles.error} type='invalid'>
          {errorText}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export const CountrySelect = forwardRef(RootCountrySelect)
