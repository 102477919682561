import {RouteObject} from "react-router-dom";
import {CUSTOMERS_ROUTES, DASHBOARD_ROUTES} from "../../shared/config/routes";
import {RoleGuard} from "../guards/role-guard";
import {RouteGuardActions, RouteGuardEntities} from "../../casl/ability";
import {CustomersPage} from "../../modules/dashboard/customers/pages/customers-page";
import {CustomersPagesWrapper} from "../../modules/dashboard/customers/pages/customers-pages-wrapper";
import {CustomerMain} from "../../modules/dashboard/customers/pages/customer-main/customer-main";
import {CustomerFiles} from "../../modules/dashboard/customers/pages/customer-files/customer-files";
import {CustomerWallets} from "../../modules/dashboard/customers/pages/customer-wallets/customer-wallets";
import {CustomerApiPage} from "../../modules/dashboard/customers/pages/customer-api/customer-api";
import {CustomerSettingsPage} from "../../modules/dashboard/customers/pages/customer-settings/customer-setting";
import {
    CustomerRestrictionsPage
} from "../../modules/dashboard/customers/pages/customer-restrictions/customer-restrictions";
import { CustomerTwoFactorPage } from "modules/dashboard/customers/pages/customer-two-factor/customer-two-factor";

export const customersRoutes: RouteObject[] = [
    {
        path: DASHBOARD_ROUTES.CUSTOMERS.path,
        element: <RoleGuard
            I={RouteGuardActions.manage}
            a={RouteGuardEntities.Admin}
        ><CustomersPage/>
        </RoleGuard>
    },
    {
        element: <CustomersPagesWrapper/>,
        children: [
            {
                path: CUSTOMERS_ROUTES.CUSTOMER.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerMain/>
                </RoleGuard>
            },
            {
                path: CUSTOMERS_ROUTES.CUSTOMER_FILES.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerFiles/>
                </RoleGuard>
            },
            {
                path: CUSTOMERS_ROUTES.CUSTOMER_WALLETS.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerWallets/>
                </RoleGuard>
            },
            {
                path: CUSTOMERS_ROUTES.API.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerApiPage/>
                </RoleGuard>
            },
            {
                path: CUSTOMERS_ROUTES.SETTINGS.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerSettingsPage/>
                </RoleGuard>
            },
            {
                path: CUSTOMERS_ROUTES.RESTRICTIONS.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerRestrictionsPage/>
                </RoleGuard>
            },
            {
                path: CUSTOMERS_ROUTES.TWO_FACTOR.path, element: <RoleGuard
                    I={RouteGuardActions.manage}
                    a={RouteGuardEntities.Admin}
                ><CustomerTwoFactorPage/>
                </RoleGuard>
            },
        ]
    },
]