import React, {useState, useEffect, useRef, useMemo} from 'react'
import styles from './wallet-widget.module.scss'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import clsx from 'clsx'
import {usePopper} from 'react-popper'
import {Loader} from 'shared/components/loader/loader'
import {IWallet} from 'shared/types/api-types/wallets-type'
import {CurrencyFormatter} from 'shared/helpers/currency-formatter'
type IWalletWidgetBodyProps = {
  wallets: IWallet[]
  defaultWallet: IWallet
  handelSetDefault?: (walletId: number) => void
  isLoading?: boolean
  className?: string
  isMinimize?: boolean
}

export const WalletWidgetBody: React.FC<IWalletWidgetBodyProps> = ({
  wallets,
  defaultWallet,
  handelSetDefault,
  isLoading,
  className,
  isMinimize,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const rootRef = useRef(null)
  const otherWallets = useMemo(() => {
    return wallets.filter((wallet) => wallet.id !== defaultWallet.id)
  }, [wallets, defaultWallet])
  const onToggleOpen = () => {
    setIsOpen((prevState) => !prevState)
  }
  const {styles: popperStyles, attributes} = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ],
    }
  )

  const handleClickOnSideBar = () => {
    setIsOpen(false)
  }

  const handleClickOutside = (event) => {
    if (rootRef.current && !rootRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', handleClickOutside)
      document.querySelectorAll('.menu-item').forEach((link) => {
        link.addEventListener('click', handleClickOnSideBar)
      })
    } else {
      window.removeEventListener('click', handleClickOutside)
      document.querySelectorAll('.menu-item').forEach((link) => {
        link.removeEventListener('click', handleClickOnSideBar)
      })
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
      document.querySelectorAll('.menu-item').forEach((link) => {
        link.removeEventListener('click', handleClickOnSideBar)
      })
    }
  }, [isOpen])
  const classWallet = isMinimize ? 'wallet' : 'wallet-base'
  if (!wallets || !wallets.length || !defaultWallet) return null

  return (
    <>
      <div
        className={clsx(styles[`${classWallet}-container`], className)}
        onClick={onToggleOpen}
        ref={(ref) => {
          setReferenceElement(ref)
          rootRef.current = ref
        }}
      >
        {isLoading && <Loader mode={'blur'} />}
        <div
          className={clsx(
            styles[classWallet],
            styles[`${classWallet}--default`],
            {
              [styles[`${classWallet}--active`]]: isOpen,
            }
          )}
        >
          <p className={styles[`${classWallet}__currency`]}>
            <Icon
              icon={ICON_COLLECTION.badgeDefault}
              className={styles[`${classWallet}__default-icon`]}
            />
            {defaultWallet.currency.symbol}
          </p>
          <div>
            <p className={styles[`${classWallet}__default`]}>
              <Icon icon={ICON_COLLECTION.badgeDefault} />
              Default wallet
            </p>
            <p className={styles[`${classWallet}__text`]}>
              <span>Balance: </span>
              {CurrencyFormatter(defaultWallet.amount, defaultWallet.currency)}
            </p>
          </div>
          {otherWallets && otherWallets.length ? (
            <button className={styles[`${classWallet}__wallet-button`]}>
              <Icon icon={ICON_COLLECTION.arrowUp} />
            </button>
          ) : null}
        </div>
      </div>

      {isOpen && otherWallets && otherWallets.length ? (
        <div
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
          className={styles[`${classWallet}__wallet-list`]}
        >
          {isLoading && <Loader mode={'blur'} />}
          {otherWallets.map((wallet) => (
            <div
              key={wallet.id}
              className={clsx(styles[classWallet])}
              onClick={
                handelSetDefault ? () => handelSetDefault(wallet.id) : undefined
              }
            >
              <p className={styles[`${classWallet}__currency`]}>
                {wallet.currency.symbol}
              </p>
              <div>
                <p className={styles[`${classWallet}__text`]}>
                  <span>Balance: </span>
                  {CurrencyFormatter(wallet.amount, wallet.currency)}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}
