const STOCK_STATUS_BUTTON_NAME = {
  add: 'Add',
  add_all: 'Add All',
  pre_order: 'Pre-Order',
  pre_order_all: 'Pre-Order All',
}

export const STOCK_AMOUNT_FLAG = 'STOCK_AMOUNT_FLAG'
export const MAX_STOCK_AMOUNT: number = 10000
type AmountConditionResponseType = {
  isValid: boolean
  errorMessage?: string
}

export type StatusProductItemType = {
  status: string
  name: string
  single_button_name: string
  amount: string
  btn_name: string
  is_disabled_max_button: boolean
  is_disabled_input: boolean
  is_disabled_action_button: boolean
  is_disabled_action_button_all: boolean
  is_in_stock_btn: boolean
  getAmountCondition: (
    targetValue: number,
    preorder_quantity: number,
    product_quantity: number
  ) => AmountConditionResponseType
  initProductAmount: number
}

const PRODUCT_STATUS_NAMES = {
  hidden: 'Hidden',
  pre_order: 'Pre order',
  in_stock: 'In stock',
  unlimited: 'unlimited',
  out_of_stock: 'Out of stock',
}

interface PreorderStatusType {
  hidden: StatusProductItemType
  unlimited: StatusProductItemType
  pre_order: StatusProductItemType
  in_stock: StatusProductItemType
  out_of_stock: StatusProductItemType
}
export const PRODUCT_STATUS_CONFIG: PreorderStatusType = {
  hidden: {
    status: PRODUCT_STATUS_NAMES.hidden,
    name: 'Hidden',
    single_button_name: STOCK_STATUS_BUTTON_NAME.add_all,
    amount: STOCK_AMOUNT_FLAG,
    btn_name: STOCK_STATUS_BUTTON_NAME.add,
    is_disabled_max_button: true,
    is_disabled_input: true,
    is_disabled_action_button: true,
    is_disabled_action_button_all: true,
    is_in_stock_btn: true,
    getAmountCondition: (targetValue, preorder_quantity, product_quantity) => {
      return {
        isValid: null,
        errorMessage: null,
      }
    },
    initProductAmount: 0,
  },
  unlimited: {
    status: PRODUCT_STATUS_NAMES.unlimited,
    name: 'Pre order',
    single_button_name: STOCK_STATUS_BUTTON_NAME.pre_order_all,
    amount: 'Unlimited',
    btn_name: STOCK_STATUS_BUTTON_NAME.pre_order,
    is_disabled_max_button: true,
    is_disabled_input: false,
    is_disabled_action_button: false,
    is_disabled_action_button_all: true,
    is_in_stock_btn: false,
    getAmountCondition: (targetValue, preorder_quantity, product_quantity) => {
      let errorMessage = null
      if (targetValue === 0) {
        errorMessage = 'Quantity cannot be less than one'
      }
      if (targetValue > MAX_STOCK_AMOUNT) {
        errorMessage = `The quantity cannot be more than the maximum available ${MAX_STOCK_AMOUNT}`
      }
      return {
        isValid: targetValue > 0 && targetValue <= MAX_STOCK_AMOUNT,
        errorMessage: errorMessage,
      }
    },
    initProductAmount: 1,
  },
  pre_order: {
    status: PRODUCT_STATUS_NAMES.pre_order,
    name: 'Pre order',
    single_button_name: STOCK_STATUS_BUTTON_NAME.pre_order_all,
    amount: STOCK_AMOUNT_FLAG,
    btn_name: STOCK_STATUS_BUTTON_NAME.pre_order,
    is_disabled_max_button: false,
    is_disabled_input: false,
    is_disabled_action_button: false,
    is_disabled_action_button_all: false,
    is_in_stock_btn: false,
    getAmountCondition: (targetValue, preorder_quantity, product_quantity) => {
      let errorMessage = null
      if (targetValue === 0) {
        errorMessage = 'Quantity cannot be less than one'
      }
      if (targetValue > preorder_quantity) {
        errorMessage = `The quantity cannot be more than the maximum available ${preorder_quantity}`
      }
      return {
        isValid: targetValue > 0 && targetValue <= preorder_quantity,
        errorMessage: errorMessage,
      }
    },
    initProductAmount: 1,
  },
  in_stock: {
    status: PRODUCT_STATUS_NAMES.in_stock,
    name: 'In stock',
    single_button_name: STOCK_STATUS_BUTTON_NAME.add_all,
    amount: STOCK_AMOUNT_FLAG,
    btn_name: STOCK_STATUS_BUTTON_NAME.add,
    is_disabled_max_button: false,
    is_disabled_input: false,
    is_disabled_action_button: false,
    is_disabled_action_button_all: false,
    is_in_stock_btn: true,
    getAmountCondition: (targetValue, preorder_quantity, product_quantity) => {
      let errorMessage = null
      if (targetValue === 0) {
        errorMessage = 'Quantity cannot be less than one'
      }
      if (targetValue > product_quantity) {
        errorMessage = `The quantity cannot be more than the maximum available ${product_quantity}`
      }
      return {
        isValid: targetValue > 0 && targetValue <= product_quantity,
        errorMessage: errorMessage,
      }
    },
    initProductAmount: 1,
  },
  out_of_stock: {
    status: PRODUCT_STATUS_NAMES.out_of_stock,
    name: 'Out of stock',
    single_button_name: STOCK_STATUS_BUTTON_NAME.add_all,
    amount: STOCK_AMOUNT_FLAG,
    btn_name: STOCK_STATUS_BUTTON_NAME.add,
    is_disabled_max_button: true,
    is_disabled_input: true,
    is_disabled_action_button: true,
    is_disabled_action_button_all: true,
    is_in_stock_btn: false,
    getAmountCondition: (targetValue, preorder_quantity, product_quantity) => {
      return {
        isValid: null,
        errorMessage: null,
      }
    },
    initProductAmount: 0,
  },
}
type ProductStatusControllerType = {
  isHidden: boolean
  isUnlimited: boolean
  quantity: number
  stock: boolean
  preorderQuantity: number
  preorderAvailable: boolean
}

export class _ProductStatusController {
  private isHidden: boolean
  private isUnlimited: boolean
  private quantity: number
  private stock: boolean
  private preorderQuantity: number
  private preorderAvailable: boolean

  public isStock: boolean
  public isPreorder: boolean
  constructor(value: ProductStatusControllerType) {
    this.isHidden = value.isHidden
    this.isUnlimited = value.isUnlimited
    this.quantity = value.quantity
    this.stock = value.stock
    this.preorderQuantity = value.preorderQuantity
    this.preorderAvailable = value.preorderAvailable

    this.isStock = this.stock && this.quantity > 0
    this.isPreorder = this.preorderAvailable && this.preorderQuantity > 0
  }

  getStatus(): StatusProductItemType {
    if (this.isHidden) return PRODUCT_STATUS_CONFIG.hidden

    if (this.isStock) return PRODUCT_STATUS_CONFIG.in_stock

    if (this.isUnlimited) return PRODUCT_STATUS_CONFIG.unlimited

    if (this.isPreorder) return PRODUCT_STATUS_CONFIG.pre_order

    return PRODUCT_STATUS_CONFIG.out_of_stock
  }
  getGroupCodesStatus(): StatusProductItemType {
    if (this.isHidden) return PRODUCT_STATUS_CONFIG.hidden

    if (this.isStock) return PRODUCT_STATUS_CONFIG.in_stock

    if (this.isPreorder || this.isUnlimited) return PRODUCT_STATUS_CONFIG.pre_order

    return PRODUCT_STATUS_CONFIG.out_of_stock
  }
}
