import React from 'react'
import {ButtonList} from '../../../../../shared/components/button-list/button-list'
import {Button} from '../../../../../shared/components/button/button'
import {Select} from '../../../../../shared/components/select/select'
import {ButtonLink} from '../../../../../shared/components/button/button-link'
import {PRODUCT_ROUTES} from '../../../../../shared/config/routes'
import {Tooltip} from '../../../../../shared/components/tooltip/tooltip'
import {Icon} from '../../../../../shared/components/icon/icon'
import {ICON_COLLECTION} from '../../../../../shared/components/icon/icon-list'
import clsx from 'clsx'
import styles from '../product-discount-card/product-discount-card.module.scss'
import {GeneralDiscountPrice} from '../general-disocunt-price/general-discount-price'
import {PriceVariations} from '../price-variations/price-variations'
import {useCreateProductDiscount} from './use-create-product-discount'
import {Loader} from '../../../../../shared/components/loader/loader'

interface ICreateProductDiscountProps {
  discountId: string
}
const CreateProductDiscountComponent: React.FC<ICreateProductDiscountProps> = ({
  discountId,
}) => {
  const {models, commands} = useCreateProductDiscount(discountId)

  return (
    <>
      {models.isOpenCreatForm && (
        <div className='card p-5 shadow mb-5 rounded position-relative'>
          {models.isLoading && <Loader mode={'blur'} />}
          <div className='d-flex align-items-center justify-content-between'>
            <div className=''>
              <p>Product</p>
              <Select
                error={!!(models.errors.id && models.touched.id)}
                errorText={
                  models.errors.id && models.touched.id && models.errors.id
                }
                options={models.optionsProducts}
                value={models.currentProduct?.id ?? null}
                onChange={commands.onChangeDiscountProduct}
                isLoading={models.isLoadingProduct}
                handleInputChange={commands.handleInputChange}
                className='w-sm-300px'
              />
            </div>
            <div className='d-flex align-items-center'>
              {models.currentProduct.id && (
                <ButtonLink
                  to={PRODUCT_ROUTES.SINGLE_PRODUCT.generate(
                    `${models.currentProduct.id}`
                  )}
                  mode='secondary'
                >
                  Go to Product
                </ButtonLink>
              )}
              <Tooltip
                placement='left'
                content='You can remove a certain product from stock and edit all indicators on the corresponding product page.'
              >
                <Icon className='mx-3' icon={ICON_COLLECTION.questionMark} />
              </Tooltip>
            </div>
          </div>
          <div
            className={clsx(styles['section-price'], {
              [styles['section-price--active']]: models.isOpen,
            })}
          >
            <GeneralDiscountPrice
              onChangeGeneralSellPrice={commands.onChangeGeneralSellPrice}
              errors={models.errors}
              touched={models.touched}
              onChangeProductDiscount={commands.onChangeProductDiscount}
              onToggleGeneralPrice={commands.onToggleGeneralPrice}
              isDisabled={models.isUsedGeneralPrice}
              purchasePrice={models.currentProduct.purchase_price}
              discount={models.currentProduct.discount}
              sellPrice={models.currentProduct.general_sell_price}
              type={Number(models.currentProduct.type)}
            />
            <PriceVariations
              onChangeCodsSellPrice={commands.onChangeCodsSellPrice}
              errors={!!models.codesErrors}
              isLoading={models.isCodesLoading}
              onChangeProductDiscount={commands.onChangeCodesDiscount}
              codesGroups={models.currentProduct.codes}
              isDisabled={models.isUsedGeneralPrice}
            />

            <ButtonList className='justify-content-end mt-5'>
              <Button
                label='Add discount to product'
                mainButton
                isLoading={models.isLoading}
                disabled={models.isLoading}
                onClick={commands.onSubmit}
              />
            </ButtonList>
          </div>
        </div>
      )}
      <ButtonList>
        {!models.isOpenCreatForm ? (
          <Button
            label='Add new product'
            mainButton
            onClick={commands.onOpenCreatForm}
          />
        ) : (
          <Button
            label='Cancel'
            onClick={commands.onOpenCloseForm}
            disabled={models.isLoading}
          />
        )}
      </ButtonList>
    </>
  )
}
export const CreateProductDiscount = React.memo(CreateProductDiscountComponent)
