import React from 'react'
import {useSingleSupplierPage} from './use-single-supplier-page'
import {Button} from 'shared/components/button/button'
import {SingleSupplierContent} from '../../components/single-supplier-content'
import {Loader} from 'shared/components/loader/loader'
import {DeleteSupplierModal} from '../../components/modals/delete-supplier-modal'

export const SingleSupplierPage = () => {
  const {models, commands, formik} = useSingleSupplierPage()
  if (models.isTableLoading) {
    return <Loader />
  }
  return (
    <>
      <div className='card p-7'>
        <div className='d-flex align-items-center justify-content-between mb-5'>
          <div className='d-flex align-items-center gap-5 mb-5'>
            <Button
              type='button'
              onClick={commands.handleGoBackClick}
              label={'<'}
              className='min-w-25px'
            />

            <h1 className='text-exl-primary bold m-0 position-relative w-sm-200px'>
              {models.supplierName}
            </h1>
          </div>
          <div className='d-flex align-items-center gap-5'>
            {models.isEditing ? (
              <div className='d-flex align-items-center gap-5'>
                <Button
                  label='Cancel'
                  type='button'
                  onClick={commands.handleCancelForm}
                  disabled={models.isUpdateLoading}
                />
                <Button
                  label='Save'
                  mainButton
                  type='submit'
                  onClick={commands.handleSubmitForm}
                  isLoading={models.isUpdateLoading}
                  disabled={models.isUpdateLoading}
                />
              </div>
            ) : (
              !models.isEditing && (
                <Button
                  label='Edit'
                  type='button'
                  mainButton
                  onClick={commands.toggleEditMode}
                />
              )
            )}
            <Button
              label='Delete'
              mode='remove'
              type='button'
              onClick={() => commands.handleDeleteSupplier(models.supplierId)}
            />
          </div>
        </div>
        <SingleSupplierContent
          formik={formik}
          isEditing={models.isEditing}
          isLoadingRegions={models.isLoadingRegions}
          handlePhoneInput={commands.handlePhoneInput}
          handleCountrySelect={commands.handleCountrySelect}
        />
      </div>
      <DeleteSupplierModal />
    </>
  )
}
