import {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {CATEGORIES_QUERY_KEYS} from 'shared/constants/query-keys'
import {toast} from 'react-toastify'
import {
  CatalogApi,
  ICodesGroupItem,
  IUpdateCodesGroupParams,
  IUpdateProductParams,
} from 'app/api/dashboard-api/catalog-api'
import {useFormik} from 'formik'
import {AxiosError} from 'axios'
import {CodesApi} from 'app/api/dashboard-api/codes-api'
import {DownloadService} from 'shared/services/download-service'
import {useModalManager} from 'shared/context/modal-manager'
import {
  CATALOG_MODAL_NAMES,
  DASHBOARD_MODAL_NAMES,
} from 'shared/constants/modal-names'
import {CartApi, ICartParamsDTO} from 'app/api/cart-api/cart-api'
import {useCartState} from 'app/store/cart/state'
import {useUserState} from 'app/store/user/state'
import {ERROR_ROUTE} from 'shared/config/routes'
import {EventBus, GLOBAL_EVENTS} from 'shared/utils/event-bus'
import {useTemporaryUser} from '../../../hooks/use-temporary-user'
import {getInvalidPrice} from '../../helpers/product-validate-price'
import {ChangeCodesValueArgType, SingleProductType} from './single-roduct-types'
import {getProductUpdatedVale} from './handel-update-product'
import {
  initialProductValues,
  validateProduct,
} from '../../constants/product-init'
import {BlobErrorResponse} from 'shared/helpers/blob-error-response'

export const useSingleProductPage = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [isActionsLoading, setIsActionsLoading] = useState(false)
  const [codesGroup, setCodesGroup] = useState<ICodesGroupItem[]>([])
  const location = useLocation()
  const productId = location.pathname.split('/')[3]
  const queryClient = useQueryClient()
  const modalManager = useModalManager()
  const navigate = useNavigate()
  const {temporaryUserId} = useTemporaryUser()
  const [isTouched, setIsTouched] = useState(false)
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const {setCart} = useCartState()

  const formik = useFormik<SingleProductType>({
    initialValues: initialProductValues,
    onSubmit: (values) => {
      const payload = getProductUpdatedVale(values)
      const tempPayload = payload
      // const tempPayload = removeEmptyFields(payload)
      const finalPayload =
        !formik.values.editImage && !formik.values.image
          ? {...tempPayload, image: 'delete'}
          : tempPayload
      const requestData: IUpdateProductParams = {
        productId: productId,
        payload: finalPayload,
      }
      const purchasePriceList = codesGroup.map((purchasePrice) =>
        Number(purchasePrice.purchase_price)
      )
      const invalidPriceList = getInvalidPrice(
        Number(formik.values.general_price),
        purchasePriceList
      )
      if (invalidPriceList.length && values.is_general_price_available) {
        modalManager
          .open(CATALOG_MODAL_NAMES.product_alert_validation, invalidPriceList)
          .then(() => {
            updateProductMutation.mutate(requestData)
          })
          .catch((error) => {
            return
          })
      } else {
        updateProductMutation.mutate(requestData)
      }
    },
    validate: validateProduct,
  })

  const productQuery = useQuery({
    queryKey: [
      CATEGORIES_QUERY_KEYS.single_product,
      {productId, temporaryUserId},
    ],
    queryFn: async () => CatalogApi.getOneProduct(productId, temporaryUserId),
    onError: (error) => {
      toast.error('Something went wrong.')
      navigate(ERROR_ROUTE.path)
    },
  })

  const codesGroupQuery = useQuery({
    queryKey: [
      CATEGORIES_QUERY_KEYS.codes_group,
      {productId, temporaryUserId, isEdit},
    ],
    queryFn: async () =>
      isEdit
        ? CatalogApi.getCodesGroupForAdmin(productId, temporaryUserId)
        : CatalogApi.getCodesGroup(productId, temporaryUserId),
    onSuccess: (data) => {
      setCodesGroup(data)
    },
    onError: async (error) => {
      toast.error(await BlobErrorResponse(error))
    },
    refetchOnWindowFocus: false,
  })
  const addToCartMutation = useMutation<
    any,
    AxiosError<{error: string}>,
    {userId: number; params: ICartParamsDTO}
  >(CartApi.addToCart, {
    onSuccess: (cart) => {
      queryClient.invalidateQueries(
        [CATEGORIES_QUERY_KEYS.single_product, {productId, temporaryUserId}],
        {exact: true}
      )
      queryClient.invalidateQueries(
        [
          CATEGORIES_QUERY_KEYS.codes_group,
          {productId, temporaryUserId, isEdit},
        ],
        {exact: true}
      )
      setIsCartHasProducts(true)
      const productsInCart = cart.codes.reduce(
        (sum, code) => sum + code.quantity,
        0
      )
      setProductsInCartQuantity(productsInCart)
      setCart(cart)
      toast.success('Added to Cart')
    },
    onError: (error) => {
      toast.error(error?.response.data.error)
    },
  })
  const addAllToCart = () => {
    addToCartMutation.mutate({
      userId: temporaryUserId,
      params: {
        quantity: productQuery.data.quantity,
        product_id: Number(productId),
        product_hash: productId,
      },
    })
  }
  const addGroupCodesToCart = (quantity: number, product_hash: string) => {
    addToCartMutation.mutate({
      userId: temporaryUserId,
      params: {
        quantity,
        product_hash,
      },
    })
  }
  const preOrderToCart = (quantity: number, product_hash: string) => {
    modalManager
      .open(DASHBOARD_MODAL_NAMES.pre_order_group_codes_warning)
      .then(() => {
        addToCartMutation.mutate({
          userId: temporaryUserId,
          params: {
            quantity,
            product_hash,
          },
        })
      })
      .catch((error) => {
        return
      })
  }

  const updateProductMutation = useMutation<
    ICodesGroupItem[],
    AxiosError<{message?: string; error?: string}>,
    IUpdateProductParams
  >(CatalogApi.updateProduct, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        [CATEGORIES_QUERY_KEYS.single_product, {productId, temporaryUserId}],
        data
      )
      queryClient.invalidateQueries(
        [CATEGORIES_QUERY_KEYS.single_product, {productId, temporaryUserId}],
        {exact: true}
      )
      queryClient.invalidateQueries(
        [
          CATEGORIES_QUERY_KEYS.codes_group,
          {productId, temporaryUserId, isEdit},
        ],
        {exact: true}
      )
      onEditOff()
    },
    onError: (error) => {
      error?.response.data.message && toast.error(error?.response.data.message)
      error?.response.data.error && toast.error(error?.response.data.error)
    },
  })

  const onEditOn = () => setIsEdit(true)
  const onEditOff = () => setIsEdit(false)

  const handelSetFormikField = <T>(name: string, value: T) => {
    formik.setFieldValue(name, value || null)
    setIsTouched(true)
  }
  const setResponseToFormik = () => {
    if (productQuery.data) {
      let temp = initialProductValues
      for (const value in temp) {
        temp[value] = productQuery.data[value]
      }
      formik.setValues({
        ...temp,
        is_general_price_available: !!productQuery.data['general_price'],
      })
    }
    setIsTouched(false)
  }

  useEffect(setResponseToFormik, [productQuery.data])

  // ======== codes ======

  const updateCodesGroupMutation = useMutation<
    ICodesGroupItem[],
    AxiosError<{message: string}>,
    IUpdateCodesGroupParams
  >(CatalogApi.updateCodesGroup, {
    onSuccess: (data) => {
      setCodesGroup((prevState) => [...data])
    },
  })

  const codesChecked = useMemo(() => {
    if (codesGroup.length) {
      return codesGroup.filter((code) => code.checked)
    } else {
      return []
    }
  }, [codesGroup])

  const handleChangeCodesValue = (codesArgs: ChangeCodesValueArgType) => {
    const {hash, name, value, purchasePrice} = codesArgs

    updateCodesGroupMutation.mutate({
      productId: productId,
      payload: {
        code_hash: hash,
        [name]: value,
      },
    })
  }
  const handleResetAllCheckBoxes = () => {
    const data = codesGroup.map((item) => ({...item, checked: false}))
    setCodesGroup((prevState) => [...data])
    setSelectAll(!selectAll)
  }
  const handleCheckboxChange = (hash: string) => {
    const newData = codesGroup.map((item) =>
      item.hash === hash ? {...item, checked: !item.checked} : item
    )
    setCodesGroup((prevState) => [...newData])
    setSelectAll(newData.every((item) => item.checked))
  }
  const setGroupsItemValue = (
    hash: string,
    name: string,
    value: number | boolean
  ) => {
    const groupsItem = codesGroup.map((item) =>
      item.hash === hash ? {...item, [name]: value} : item
    )
    setCodesGroup((prevState) => [...groupsItem])
  }
  const onEditImage = (URL: string | null) => {
    formik.setFieldValue('image', URL ? {original_url: URL} : null)
    setIsTouched(true)
  }
  const onUpdateFile = (file: File) => {
    if (file) {
      formik.setFieldValue('editImage', file)
    }
    setIsTouched(true)
  }
  const onCancel = () => {
    setResponseToFormik()
    onEditOff()
    setIsTouched(false)
  }

  const onDownloadGroupCodes = async () => {
    try {
      setIsActionsLoading(true)
      const codesIdList = codesChecked.map((group) => group.hash)
      const response = await CodesApi.getCodesCSV({
        hashes: codesIdList,
        product: productId,
      })
      await DownloadService.downloadObjectAsZip(response, 'CSV')
      handleResetAllCheckBoxes()
      setIsActionsLoading(false)
    } catch (error) {
      setIsActionsLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }
  const onRemoveProduct = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.remove_product, productId)
  }
  const onRemoveImage = () => {
    formik.setFieldValue('editImage', null)
    setIsTouched(true)
  }
  const onOpenModalForRemove = () => {
    const codesIdList = codesChecked.map((group) => group.hash)
    modalManager.open(CATALOG_MODAL_NAMES.remove_group_codes, {
      productId: productId,
      payload: codesIdList,
    })
  }

  const isLoadingPage = productQuery.isLoading || codesGroupQuery.isLoading

  const isLoadingCodes =
    codesGroupQuery.isLoading || updateCodesGroupMutation.isLoading

  useEffect(() => {
    EventBus.on(GLOBAL_EVENTS.reset_groups_codes, (response) => {
      if (response) {
        setCodesGroup(response)
      } else {
        codesGroupQuery.refetch({cancelRefetch:true})
      }
    })
    EventBus.on(GLOBAL_EVENTS.reset_products, () => productQuery.refetch({cancelRefetch:true}))
    return () => {
      EventBus.off(GLOBAL_EVENTS.reset_groups_codes, (response) => {
        if (response) {
          setCodesGroup(response)
        } else {
          codesGroupQuery.refetch({cancelRefetch:true})
        }
      })
      EventBus.off(GLOBAL_EVENTS.reset_products, () => productQuery.refetch({cancelRefetch:true}))
    }
  }, [])

  const onCreatePreOrder = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.pre_order_warning, {
      quantity: formik.values.preorder_quantity,
      productId,
    })
  }
  return {
    models: {
      facePrice: productQuery.data ? productQuery.data.face_price : null,
      isLoadingCart: addToCartMutation.isLoading,
      isActionsLoading,
      isLoadingPage,
      isLoadingForm: updateProductMutation.isLoading,
      data: formik.values,
      codes:
        !formik.values.stock && isEdit && formik.values.preorder_avaibable
          ? []
          : codesGroup,
      isEdit,
      isLoadingCodes,
      codesChecked,
      isTouched,
      errors: formik.errors,
    },
    commands: {
      onEditOn,
      onEditOff,
      handelSetFormikField,
      setResponseToFormik,
      handleResetAllCheckBoxes,
      handleCheckboxChange,
      setGroupsItemValue,
      onCancel,
      onEditImage,
      handleChangeCodesValue,
      onUpdateProduct: () => formik.submitForm(),
      onUpdateFile,
      onDownloadGroupCodes,
      onRemoveProduct,
      onRemoveImage,
      removeGroupsCodes: onOpenModalForRemove,
      addAllToCart,
      addGroupCodesToCart,
      onCreatePreOrder,
      preOrderToCart,
    },
  }
}
