import {useFormik, FormikProps} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import styles from './Login.module.scss'
import {RegisterFormInner} from '../../../../modules/auth/pages/register/register-form-inner'
import {useAuth as useAuthEXl} from 'modules/auth/hooks/use-auth'
import {useIntl} from 'react-intl'

const initialValues = {
  companyName: '',
  email: '',
  password: '',
  password_confirmation: '',
}

export interface IFormikProps {
  email: string
  password: string
  companyName: string
  password_confirmation: string
}

const registrationSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Company name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),

  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  password_confirmation: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function Registration() {
  const {registerMutation} = useAuthEXl()
  const intl = useIntl()

  const formik: FormikProps<IFormikProps> = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        company_name: values.companyName,
        password: values.password,
        password_confirmation: values.password_confirmation,
      }
      registerMutation.mutate(data)
    },
  })

  return (
    <form
      className='form w-sm-300px mx-auto'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-6 mt-5'>
        <h1
          className={clsx(
            'fw-bolder mb-0',
            styles.header,
            styles.textPrimaryDark
          )}
        >
          {intl.formatMessage({id: 'EXL.AUTH.REGISTRATION.TITLE'})}
        </h1>
      </div>
      {(formik.status || registerMutation.error) && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='text-center font-weight-bold'>
            {registerMutation.error?.response?.data?.message}
          </div>
        </div>
      )}
      <RegisterFormInner
        formik={formik}
        isLoading={registerMutation.isLoading}
      />
    </form>
  )
}
