import React, { useState } from "react"
import { Button } from "shared/components/button/button"
import { Icon } from "shared/components/icon/icon"
import { ICON_COLLECTION } from "shared/components/icon/icon-list"
import { Toggle } from "shared/components/toggle/toggle"
import { IApiKey } from "shared/types/user"

interface IApiTableRow {
    apiKey:IApiKey
    dateFormatter:(value:Date) => string
    isExpiredToken:(value:Date)=>boolean
    toggleActiveStateOfKey:(value:IApiKey)=>void
}

const TypeApiKey = {
    0:'Production',
    1:'Sandbox'
}

const ApiTableRowComponent:React.FC<IApiTableRow> = ({apiKey,dateFormatter,isExpiredToken,toggleActiveStateOfKey}) => {
    const [isHidden,setIsHidden] = useState<boolean>(true)
    const handleViewClick = () => {
        setIsHidden(prevValue=>!prevValue)
    }
    return (
        <tr>
            <td className='min-w-150px d-flex justify-content-between align-items-center'>
                <span className="text-break">
                    {
                        isHidden ? 
                        '************************************************' : 
                        apiKey.token
                    }
                </span>
                <Button 
                    mode='text' 
                    type='button' 
                    className='min-w-auto p-0' 
                    label={
                        isHidden ?
                        <Icon icon={ICON_COLLECTION.showPassword}/> :
                        <Icon icon={ICON_COLLECTION.hidePassword}/>
                    } 
                    onClick={handleViewClick}
                />                        
            </td>
            <td>{apiKey.name}</td>
            <td>{dateFormatter(apiKey.last_used_at)}</td>
            <td>{TypeApiKey[apiKey.is_sandbox]}</td>
            <td className='w-50px'>
                <Toggle isActive={isExpiredToken(apiKey.expires_at)} setActive={()=>toggleActiveStateOfKey(apiKey)}/>
            </td>
        </tr>
    )
}

export const ApiTableRow = React.memo(ApiTableRowComponent)