import React from 'react';
import { DatePickerDialog } from 'shared/components/day-picker/day-picker';
import { Icon } from 'shared/components/icon/icon';
import { ICON_COLLECTION } from 'shared/components/icon/icon-list';
import { Input } from 'shared/components/input/input';
import { IOption, Select } from 'shared/components/select/select';
import { initialFormPurchaseName } from 'shared/constants/purchases';

interface IPurchaseSearchBar {
    searchValue:string
    supplierValue:number
    statusValue:number
    currencyValue:number
    isSuppliersLoading:boolean
    isCurrenciesLoading:boolean
    suppliers:IOption[]    
    currencies:IOption[]    
    handleChangeFormValue:(name: initialFormPurchaseName, value: IOption)=>void
    handleDateChangeFormValue:(startDate:string,endDate:string) => void
    handleInputChangeSuppliers: (text: string) => void
    handleInputChangeCurrencies: (text: string) => void
    setSearchValue: (text: string) => void
}

const STATUSES: IOption[] = [
    {
        label: 'Empty',        
        id: 1
    },
    {
        label: 'Undelivered',        
        id: 2
    },
    {
        label: 'Delivered',
        id: 3
    },
]

const PurchaseSearchBarComponent: React.FC<IPurchaseSearchBar> = (props) => {
    const {
        searchValue,
        suppliers,
        currencies,
        supplierValue,   
        statusValue,
        currencyValue,
        isSuppliersLoading,   
        isCurrenciesLoading,  
        handleChangeFormValue,
        handleDateChangeFormValue,
        handleInputChangeSuppliers,
        handleInputChangeCurrencies,
        setSearchValue    
    } = props

    return (
        <div className="mb-5">
            <div className="row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-5 position-relative">
                <div className="col mb-5">
                    <Input 
                        startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch}/>}
                        placeholder='Search'
                        value={searchValue}
                        onChange={event=>setSearchValue(event.target.value)}
                    />
                </div>
                <div className="col mb-5">
                    <DatePickerDialog 
                        handleDateChangeFormValue={handleDateChangeFormValue}
                    />                   
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={suppliers} 
                        isLoading={isSuppliersLoading}
                        value={supplierValue}
                        handleInputChange={handleInputChangeSuppliers}
                        onChange={option => handleChangeFormValue('supplier', option)}
                        placeholder='Supplier'
                    />                    
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={currencies} 
                        isLoading={isCurrenciesLoading}
                        value={currencyValue}
                        handleInputChange={handleInputChangeCurrencies}
                        onChange={option => handleChangeFormValue('currency', option)}
                        placeholder='Currency'
                    />                    
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={STATUSES} 
                        value={statusValue}
                        onChange={option => handleChangeFormValue('status', option)}
                        placeholder='Status'
                    />                    
                </div>
            </div>
        </div> 
    )
}

export const PurchasesSearchBar = React.memo(PurchaseSearchBarComponent)