import React from 'react'
import {Button} from 'shared/components/button/button'
import {useSingleProductPage} from './use-single-product-page'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {Loader} from 'shared/components/loader/loader'
import {ActionsGroup} from '../../components/actions-group'
import {Can} from 'casl'
import {RouteGuardEntities, RouteGuardActions} from 'casl/ability'
import {ProductForm} from '../../components/product-form/product-form'
import {useNavigate} from 'react-router-dom'
import {PRODUCT_ROUTES} from 'shared/config/routes'
import {CodesGroups} from '../../components/codes-groups'
import {ModalRemoveProduct} from '../../components/modals/modal-remove-product'
import {RemoveGroupCodes} from '../../components/modals/remove-group-codes'
import {PreOrderWarningModal} from '../../components/pre-order-warning-modal'
import {PreOrderGroupCodesModal} from '../../components/modals/pre-order-group-codes-modal'
import {AlertValidationProductModel} from '../../components/modals/alert-validation-product'
import {AlertCodesValidationModel} from '../../components/modals/alert-codes-validation'

export const SingleProductPage = () => {
  const {models, commands} = useSingleProductPage()
  const navigate = useNavigate()
  const handleGoBackClick = () => navigate(PRODUCT_ROUTES.PRODUCT.path)

  if (!models.data || models.isLoadingPage) return <Loader />

  return (
    <div className='position-relative'>
      {models.isLoadingForm && <Loader mode='blur' />}
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <LoadWrapper isLoading={models.isLoadingPage || !models.data}>
          <div className='d-flex align-items-center'>
            <Button
              label='<'
              type='button'
              onClick={handleGoBackClick}
              className='min-w-25px '
            />
            <h1 className='text-exl-primary bold m-0 px-3 w-30'>
              {models.data ? models.data.name : 'Product'}
            </h1>
          </div>
        </LoadWrapper>
        <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
          <ActionsGroup
            isDisableSave={!models.isTouched}
            isEdit={models.isEdit}
            onRemoveGroup={commands.removeGroupsCodes}
            onRemove={commands.onRemoveProduct}
            onDownload={commands.onDownloadGroupCodes}
            isLoading={models.isLoadingForm || models.isActionsLoading}
            onSave={commands.onUpdateProduct}
            onEditOff={commands.onEditOff}
            onEditOn={commands.onEditOn}
            codeAmount={models.codesChecked.length}
            onCancel={commands.onCancel}
          />
        </Can>
      </div>
      <LoadWrapper isLoading={models.isLoadingPage}>
        <div className='card p-7'>
          <ProductForm
            errors={models.errors}
            onCreatePreOrder={commands.onCreatePreOrder}
            isLoadingCart={models.isLoadingCart}
            onRemoveImage={commands.onRemoveImage}
            onUpdateFile={commands.onUpdateFile}
            value={models.data}
            isEdit={models.isEdit}
            setValue={commands.handelSetFormikField}
            onEditImage={commands.onEditImage}
            addAllToCart={commands.addAllToCart}
          >
            <CodesGroups
              preOrderToCart={commands.preOrderToCart}
              productStock={models.data.stock}
              face_price={models.facePrice}
              isUnlimited={models.data.is_preorder_unlimited}
              isEdit={models.isEdit}
              isHidden={models.data.is_hidden}
              addToCart={commands.addGroupCodesToCart}
              isLoading={models.isLoadingCodes || models.isLoadingCart}
              codes={models.codes}
              handleCheckboxChange={commands.handleCheckboxChange}
              handleChangeCodesValue={commands.handleChangeCodesValue}
              region={models.data.region}
            />
          </ProductForm>
        </div>
      </LoadWrapper>
      <ModalRemoveProduct />
      <RemoveGroupCodes />
      <PreOrderWarningModal />
      <PreOrderGroupCodesModal />
      <AlertValidationProductModel />
      <AlertCodesValidationModel />
    </div>
  )
}
