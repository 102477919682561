import React from 'react';
import {DiscountCard} from "./discount-card/discount-card";
import {DiscountItemType, IDiscountUpdateBodyParams} from "shared/types/discount-types";

interface IDiscountListPros {
    discountList: DiscountItemType[],
    onUpdateDiscount: (id: number, params: IDiscountUpdateBodyParams, index: number) => void
    onRemove: (id: number)=>()=> void
}

const DiscountListComponent: React.FC<IDiscountListPros> = ({discountList, onUpdateDiscount,onRemove}) => {

    return (
        <div className="row mt-3">
            {discountList.length ?
                discountList.map((discount, index) =>
                    <div className="col-12 col-md-6 col-lg-3" key={discount.id}>
                        <DiscountCard
                            onRemove = {onRemove(discount.id)}
                            index={index}
                            onUpdateDiscount={onUpdateDiscount}
                            isLoading={discount.isLoading}
                            id={discount.id}
                            title={discount.name}
                            userCount={discount.users_count}
                            isActive={discount.is_active}
                        />
                    </div>
                ) : <div className="border-bottom text-center text-muted col-12 ">
                    <p>Nothing was found </p>
                </div>
            }
        </div>
    );
};
export const DiscountList = React.memo(DiscountListComponent)

