import React from 'react';
import {AccountLayout} from "../../../../app/layouts/account-layout";
import {Outlet} from "react-router-dom";

export const AccountPagesWrapper = () => {
    return (
        <AccountLayout>
           <Outlet/>
        </AccountLayout>
    );
};

