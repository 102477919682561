import {SidebarMenuMain} from './SidebarMenuMain'
import {ILink} from "../../../../../shared/types/base";

interface ISidebarMenu {
    links: ILink[]
}

const SidebarMenu: React.FC<ISidebarMenu> = ({links}) => {
    return (
        <div className='app-sidebar-menu overflow-hidden flex-column-fluid'  >
            <div
                id='kt_app_sidebar_menu_wrapper'
                className='app-sidebar-wrapper hover-scroll-overlay-y  my-5'
                data-kt-scroll='true'
                data-kt-scroll-activate='true'
                // data-kt-scroll-height='auto'
                data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
                data-kt-scroll-wrappers='#kt_app_sidebar_menu'
                data-kt-scroll-offset='5px'
                data-kt-scroll-save-state='true'
                style={{overflowY: 'auto'}}

            >
                <div
                    className='menu menu-column menu-rounded menu-sub-indention'
                    id='#kt_app_sidebar_menu'
                    data-kt-menu='true'
                    data-kt-menu-expand='false'
                >
                    {
                        !links.length ?
                            <div className="spinner-border text-light m-auto" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            : <SidebarMenuMain links={links}/>
                    }
                </div>
            </div>
        </div>
    )
}

export {SidebarMenu}
