import EventEmitter from 'eventemitter3'

export enum GLOBAL_EVENTS {
  reset_invoice = 'reset_invoice',
  reset_groups_codes = 'reset_groups_codes',
  reset_products = 'reset_products',
}

export enum STATISTIC_FILERS_EVENTS {
  top_selling = 'top_selling',
  last_orders = 'last_orders',
  top_clients = 'top_clients',
  low_stock = 'low_stock',
  api_usage = 'api_usage',
  revenue = 'revenue',
  proffit = 'proffit',
  units_sold = 'units_sold',
  orders_chart = 'orders_chart',
  orders_avg = 'orders_avg',
  codes_upload_statistic = 'codes_upload_statistic',
  users_on_site = 'users_on_site',
}

export enum DISCOUNT_EVENTS {
  remove_discount = 'remove_discount',
  remove_product_discount = 'remove_product_discount',
  user_list = 'user_list',
}

export enum PURCHASE_TRACKER_EVENTS {
  delete_purchase = 'delete_purchase',
  delete_purchase_product = 'delete_purchase_product',
}

export enum CHAT_TEMPLATE_MESSAGES_EVENT {
  template_from_order = 'template_from_order'
}

export type EventBusEvents =
  | GLOBAL_EVENTS
  | STATISTIC_FILERS_EVENTS
  | DISCOUNT_EVENTS
  | PURCHASE_TRACKER_EVENTS
  | CHAT_TEMPLATE_MESSAGES_EVENT

const eventEmitter = new EventEmitter()

const Emitter = {
  on: (event: EventBusEvents, fn) => eventEmitter.on(event, fn),

  once: (event: EventBusEvents, fn) => eventEmitter.once(event, fn),

  off: (event: EventBusEvents, fn) => eventEmitter.off(event, fn),

  emit: (event: EventBusEvents, payload?) => eventEmitter.emit(event, payload),
}

Object.freeze(Emitter)

export {Emitter as EventBus}
