import * as React from 'react'
import { InputHTMLAttributes, forwardRef} from 'react'
import {Form, FormControlProps} from 'react-bootstrap'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './phone-input.scss'
import styles from './input.module.scss'
import clsx from 'clsx'

export interface PhoneInputProps {
  value?: string
  disabled?: FormControlProps['disabled']
  inputClass?: string
  buttonClass?: string
  country?:string
  autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete']
  readOnly?: InputHTMLAttributes<HTMLInputElement>['readOnly']
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder']
  error?: boolean
  errorText?: React.ReactNode
  id?: string
  name?: string
  autoFocus?: string
  tabIndex?: InputHTMLAttributes<HTMLInputElement>['tabIndex']
  onClick?: InputHTMLAttributes<HTMLInputElement>['onClick']
  onChange: (value: string, data?:{}|CountryData, event?:React.ChangeEvent<HTMLInputElement>,formattedValue?: string) => void
  onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur']
  onFocus?: InputHTMLAttributes<HTMLInputElement>['onFocus']
}

export const RootPhoneInput: React.ForwardRefRenderFunction<HTMLInputElement,PhoneInputProps> = (
  {
    value,
    disabled = false,
    inputClass,
    buttonClass,
		autoComplete,
    country='us',
		readOnly,
		placeholder,
    error,
    errorText,
    id,
		name,
		onChange,
		onBlur,
    onFocus,
    onClick
  },
  ref
) => {
  return (    
    <>
      <PhoneInput
        country={country}
        inputClass={clsx(inputClass,styles.formControl,!value&&styles.placeholder)}
        buttonClass={buttonClass}        
        inputProps={{ 
          placeholder,
          ref:{ref},
          autoComplete:autoComplete,
          id:id,
          name:name,
          readOnly:readOnly,
        }}         
        disabled={disabled}
        value={value}    
        isValid={!error}
        onBlur={onBlur}
        onChange={value=>onChange(value)}
        onClick={onClick}
        onFocus={onFocus}        
      />
      {error && errorText && (
        <Form.Control.Feedback className={styles.error} type='invalid'>
          {errorText}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export const PhoneInputField = forwardRef(RootPhoneInput);