import React from 'react';
import {Input} from "shared/components/input/input";
import {Icon} from "shared/components/icon/icon";
import {ICON_COLLECTION} from "shared/components/icon/icon-list";
import {IOption, Select} from "shared/components/select/select";
import {Button} from "shared/components/button/button";
import {initialFormName} from "../pages/codes-page/codes-page";

interface ICodesSearchBar {
  productsOptions: IOption[]
  suppliersOptions: IOption[]
  isLoadingProducts: boolean
  isLoadingSuppliers:boolean
  stockOption: IOption[]
  handleChangeFormValue: (name: initialFormName, value: IOption) => void
  productValue: number
  supplierValue: number
  stockValue: number
  searchValue: string
  setSearchValue: (text: string) => void
  onDownloadCSV: () => void
  isCSVLoading: boolean
  handleInputChangeSuppliers: (text: string) => void
  handleInputChangeProducts: (text: string) => void
  handleClearFilters: () => void
  resetCheckedState: () => void
}

const CodesSearchBarComponent: React.FC<ICodesSearchBar> = (props) => {
    const {
      handleInputChangeProducts,
      handleInputChangeSuppliers,
      productsOptions,
      suppliersOptions,
      isLoadingProducts,
      isLoadingSuppliers,
      stockOption,
      productValue,
      supplierValue,
      stockValue,
      handleChangeFormValue,
      searchValue,
      setSearchValue,
      onDownloadCSV,
      isCSVLoading,
      handleClearFilters,
      resetCheckedState,
    } = props

    return (
      <div className='mb-5'>
        <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-6'>
          <div className='col mb-5'>
            <Input
              startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch} />}
              placeholder='Search'
              value={searchValue}
              onChange={(event) => { setSearchValue(event.target.value); resetCheckedState()}}
            />
          </div>
          <div className='col mb-5'>
            <Select
              options={productsOptions}
              value={productValue ? productValue : null}
              isClearable
              placeholder='Product'
              handleInputChange={handleInputChangeProducts}
              isLoading={isLoadingProducts}
              onChange={(option) => handleChangeFormValue('product', option)}
            />
          </div>
          <div className='col mb-5'>
            <Select
              options={suppliersOptions}
              value={supplierValue}
              isClearable
              handleInputChange={handleInputChangeSuppliers}
              isLoading={isLoadingSuppliers}
              placeholder='Supplier'
              onChange={(option) => handleChangeFormValue('supplier', option)}
            />
          </div>
          <div className='col mb-5'>
            <Select
              options={stockOption}
              value={stockValue}
              placeholder='In Stock'
              isClearable
              onChange={(option) => handleChangeFormValue('stock', option)}
            />
          </div>
          <div className='col mb-5'>
            <Button
              className='p-0 min-w-auto'
              label='Download CSV'
              mode='text'
              disabled={isCSVLoading}
              isLoading={isCSVLoading}
              onClick={onDownloadCSV}
            />
          </div>
          <div className='col mb-5'>
            <Button
              className='p-0 min-w-auto'
              label='Сlear filters'
              mode='text'
              onClick={handleClearFilters}
            />
          </div>
        </div>
      </div>
    )
};

export const CodesSearchBar = React.memo(CodesSearchBarComponent)

