import React from 'react'
import {Button} from 'shared/components/button/button'
import {RegionsTable} from '../../components/tables/regions/regions-table'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {useRegionsPage} from './use-regions-page'
import {DeleteRegionModal} from '../../components/modals/regions/delete-region-modal'
import {CreateRegionModal} from '../../components/modals/regions/create-region-modal'

export const RegionsOptions = () => {
  const {models, commands} = useRegionsPage()
  return (
    <>
      <div className='d-flex flex-end'>
        <Button label='Add new region' mainButton onClick={commands.handleCreateClick} />
      </div>
      <div className='card position-relative'>
        <RegionsTable
          regions={models.regions}
          isLoading={models.isTableLoading}
          isSaveButtonLoading={models.isSaveButtonLoading}
          handleSaveChanges={commands.handleUpdateClick}
          handleDeleteClick={commands.handleDeleteClick}
        />
        {models.regions && models.regions.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <DeleteRegionModal />
      <CreateRegionModal />
    </>
  )
}
