/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik, FormikProps} from 'formik'
import styles from './Login.module.scss'
import {LoginFormInner} from 'modules/auth/pages/login/login-form-inner'
import {useAuth as useAuthEXl} from 'modules/auth/hooks/use-auth'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
  password: '',
}

export interface IFormikProps {
  email: string
  password: string
}

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const {loginMutation, error} = useAuthEXl()
  const handleSubmit = (values:IFormikProps) => {
    loginMutation.mutate(values)
  }
  const formik: FormikProps<IFormikProps> = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      handleSubmit(values)
    }
  })
  return (
    <form
      className='form w-sm-300px mx-auto'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-6 mt-5'>
        <h1
          className={clsx(
            'fw-bolder mb-0',
            styles.header,
            styles.textPrimaryDark
          )}
        >
          {intl.formatMessage({id: 'EXL.AUTH.LOGIN.TITLE'})}
        </h1>
      </div>
      {(formik.status || error) && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='text-center  font-weight-bold'>{error}</div>
        </div>
      )}
      <LoginFormInner formik={formik} isLoading={loginMutation.isLoading} />
    </form>
  )
}
