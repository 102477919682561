import React from 'react'
import styles from './benefit-card.module.scss'

interface BenefitCardProps {
  benefitText: string
  image: string
  bg: string
  imgPosition: {
    top: string
    right: string
  }
}

const BenefitCardComponent: React.FC<BenefitCardProps> = ({
  benefitText,
  image,
  bg,
  imgPosition,
}) => {
  return (
    <div
      className={`${styles['benefit__card']}`}
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <img src={image} alt='' style={imgPosition} />
      <div className={styles['benefit__text']}>{benefitText}</div>
    </div>
  )
}
export const BenefitCard = React.memo(BenefitCardComponent)
