import {FormikProps} from 'formik'
import {ICustomer} from 'shared/types/user'
import { Dropzone } from 'shared/components/dropzone/dropzone'
import { FileList } from '../file-list/file-list';
import { DeleteFileModal } from '../delete-file';

interface IAccountMain {
    formik: FormikProps<ICustomer>
    isLoading?:boolean
    isEditable?:boolean
    handleChangeFiles:(files:File[]) => void
}

export const AccountFormFiles: React.FC<IAccountMain> = ({formik, isLoading, isEditable=false, handleChangeFiles}) => {    
    
    return (
        <div className='card-body pt-5'>
            {isEditable && (
                <>
                    <h6 className='w-xl-800px text-exl-primary'>Before we can approve your account, we need to verify your identity through the following documents:</h6>
                    <br/>
                    <h6 className='w-xl-800px text-exl-primary'>Please provide a copy of your ID, Business Register documents and Utility documents. Required documents can vary depending on the location of your business. For more specific information, please get in contact.</h6>
                    <Dropzone             
                        {...formik.getFieldProps('documents')}
                        onChange={handleChangeFiles} 
                        accept={['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf','image/jpeg','image/png']}
                        errorMessage={formik.errors.documents}
                        isLoading={isLoading}
                        disabled={isLoading}
                        label="Upload"
                        classNames='my-5'
                    />           
                    <p className='text-muted'>You can upload or drag & drop .png, .jpeg, .jpg, .docx, .pdf files at one time</p>        
                </>
            )}            
            {formik.values.documentList && 
                (<FileList documentList={formik.values.documentList}/>
                )} 
            <DeleteFileModal/>                      
        </div>            
    )
}
