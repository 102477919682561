import React, {useState} from 'react'
import {useStateModalManager} from 'shared/context/modal-manager'
import {WALLETS_MODAL_NAMES} from 'shared/constants/modal-names'
import {Modal} from 'shared/components/modal-base'
import {selectUserData} from 'app/store/user/selects'
import {useWalletsState} from 'app/store/wallets/state'
import TopUpFrom from './top-up-form'
import WithdrawFrom from './withdraw-form'
import clsx from 'clsx'
import styles from 'shared/components/tab-menu/tab-menu.module.scss'
import {useMutation} from 'react-query'
import {
  IAdjustWalletParams,
  IWallet,
  IWalletDefaultPrams,
} from 'shared/types/api-types/wallets-type'
import {AxiosError} from 'axios'
import {WalletsApi} from 'app/api/dashboard-api/wallets-api'
import {toast} from 'react-toastify'
import {Loader} from 'shared/components/loader/loader'
import {EventBus, GLOBAL_EVENTS} from 'shared/utils/event-bus'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

export const AdjustWalletModal = () => {
  const [isWithdraw, setIsWithdraw] = useState(false)
  const onWithdraw = () => setIsWithdraw(true)
  const offWithdraw = () => setIsWithdraw(false)

  const user = selectUserData()
  const {onUpdateWallet} = useWalletsState()
  const [beforeData, setBeforeData] = useState<IWalletDefaultPrams>(null)
  const modalState = useStateModalManager(WALLETS_MODAL_NAMES.adjust_wallet, {
    onBeforeOpen: ({user, wallet, currency}: IWalletDefaultPrams) => {
      setBeforeData({user, wallet, currency})
    },
  })
  const adjustWalletMutation = useMutation<
    IWallet,
    AxiosError<{message: string}>,
    IAdjustWalletParams
  >(WalletsApi.adjustWallet, {
    onSuccess: (data) => {
      if (user.id === Number(beforeData.user)) {
        onUpdateWallet(data)
      }
      EventBus.emit(GLOBAL_EVENTS.reset_invoice)
      modalState.close()
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })
  const onAdjustWalet = (amount: string | number) => {
    if (beforeData) {
      adjustWalletMutation.mutate({
        params: beforeData,
        amount: decimalFormatter(amount),
      })
    }
  }
  const handleClose = () => modalState.close()
  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!adjustWalletMutation.isLoading}
    >
      <div className='p-7 position-relative'>
        {adjustWalletMutation.isLoading && <Loader mode='blur' />}
        <h2 className='mb-5 text-center'>Adjust Wallet Balance</h2>
        <div className=' mb-5  d-flex overflow-auto py-1'>
          <ul className='nav nav-line-tabs nav-line-tabs-2x fs-3 flex-nowrap w-100 align-items-center'>
            <li
              className={clsx(`cursor-pointer`, !isWithdraw && styles.active)}
              onClick={offWithdraw}
            >
              Top up
            </li>
            <li
              className={clsx(
                ` cursor-pointer mx-3`,
                isWithdraw && styles.active
              )}
              onClick={onWithdraw}
            >
              Withdraw
            </li>
          </ul>
        </div>
        {isWithdraw ? (
          <WithdrawFrom
            onSubmit={onAdjustWalet}
            onClose={handleClose}
            isLoading={adjustWalletMutation.isLoading}
            currency={beforeData?.currency}
          />
        ) : (
          <TopUpFrom
            onSubmit={onAdjustWalet}
            onClose={handleClose}
            isLoading={adjustWalletMutation.isLoading}
            currency={beforeData?.currency}
          />
        )}
      </div>
    </Modal.Root>
  )
}
