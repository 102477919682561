import {BaseHttpServices} from "shared/services/base-http-services";
import {IRegion} from "../../../shared/types/base";
import {ICategoryOption} from "../../../shared/types/api-types/caterogy-api-types";
import {IUser} from "../../../shared/types/user";
import { IPurchase } from "shared/types/purchase";

export interface IOptionsDTO {
    id: number,
    name: string
}

export interface ICurrenciesDTO {
    id: number,
    iso: string
    name: string
    symbol: string
}


interface IProduct {
    categories: Array<unknown>
    currency: ICurrenciesDTO,
    description: string,
    id: number
    image?: unknown,
    name: string,
    price: Array<number | null>
    product_type: number
    quantity: number
    sell_price: string
    purchase_price: string
    region?: IRegion
    stock: boolean
    general_price?: string
    min_pre_order_price?: number
    max_purchase_price?:number
}


export class SelectsApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }
  getSuppliers = async (name: string): Promise<IOptionsDTO[]> => {
    const payload = await this.http.get(`/suppliers`, {
      params: {name: name},
    })
    return payload.data.data
  }
  getUnknownSupplier = async (supplier_id?:number): Promise<IOptionsDTO> => {
    const payload = await this.http.get(`/suppliers/${supplier_id}`)
    return payload.data.data
  }
  getProducts = async (name?: string): Promise<IProduct[]> => {
    const payload = await this.http.get(`/products`, {params: {name: name}})
    return payload.data.data
  }

  getCurrencies = async (): Promise<ICurrenciesDTO[]> => {
    const payload = await this.http.get(`/currencies`)
    return payload.data.data
  }
  getCategories = async (name?: string): Promise<ICategoryOption[]> => {
    const payload = await this.http.get(`/categories`, {params: {name: name}})
    return payload.data.data
  }
  getRegions = async (name?: string): Promise<IRegion[]> => {
    const payload = await this.http.get(`/regions`, {params: {name: name}})
    return payload.data.data
  }

  getUsers = async (company_name?: string): Promise<IUser[]> => {
    const payload = await this.http.get(`/users`, {params: {company_name}})
    return payload.data.data
  }

  getPurchases = async (invoice_number?:string): Promise<IPurchase[]> => {
    const payload = await this.http.get(`/purchase`, {params: {search: invoice_number}})
    return payload.data.data
  }

  getUnknownPurchase = async (purchase_id?:number): Promise<IPurchase> => {
    const payload = await this.http.get(`/purchase/${purchase_id}`)
    return payload.data.data
  }
}

export const SelectsApi = new SelectsApiService(new BaseHttpServices());