import React from 'react';
import { DatePickerDialog } from 'shared/components/day-picker/day-picker';
import { Icon } from 'shared/components/icon/icon';
import { ICON_COLLECTION } from 'shared/components/icon/icon-list';
import { Input } from 'shared/components/input/input';
import { IOption, Select } from 'shared/components/select/select';
import { ORDER_METHODS, ORDER_STATUSES, initialFormOrderName } from 'shared/constants/orders';

interface IOrdersSearchBar {
    searchValue:string
    statusValue:number
    methodValue:number
    handleChangeFormValue:(name: initialFormOrderName, value: IOption)=>void
    handleDateChangeFormValue:(startDate:string,endDate:string) => void
    setSearchValue: (text: string) => void
}

const AllOrdersSearchBarComponent: React.FC<IOrdersSearchBar> = (props) => {
    const {
        searchValue,
        statusValue,
        methodValue,
        handleChangeFormValue,
        handleDateChangeFormValue,
        setSearchValue    
    } = props

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSearchValue(event.target.value);


    return (
      <div className='mb-5'>
        <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-4 position-relative'>
          <div className='col mb-5'>
            <Input
              startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch} />}
              placeholder='Search'
              value={searchValue}
              onChange={onChangeSearch}
            />
          </div>
          <div className='col mb-5'>
            <DatePickerDialog handleDateChangeFormValue={handleDateChangeFormValue} />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              options={ORDER_METHODS}
              value={methodValue}
              onChange={(option) => handleChangeFormValue('method', option)}
              placeholder='Method'
            />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              options={ORDER_STATUSES}
              value={statusValue}
              onChange={(option) => handleChangeFormValue('status', option)}
              placeholder='Status'
            />
          </div>
        </div>
      </div>
    )
}

export const AllOrdersSearchBar = React.memo(AllOrdersSearchBarComponent)