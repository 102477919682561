import { useDebounce } from '_metronic/helpers'
import {CodesApi} from 'app/api/dashboard-api/codes-api'
import {useEffect, useMemo, useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {ERROR_CODES_QUERY_KEYS} from 'shared/constants/query-keys'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'

export const useErrorCodes = () => {
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [codeValue, setCodeValue] = useState(search.get('code'))
  const [errorValue, setErrorValue] = useState(search.get('error'))
  const [uploadIdValue, setUploadIdValue] = useState(
    search.get('uploads_history')
  )

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      code: search.get('code') || null,
      error: search.get('error') || null,
      uploads_history: Number(search.get('uploads_history')) || null,
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      page: search.get('page') ? Number(search.get('page')) : null,
    })
  }, [location.search])

  const codesQuery = useQuery({
    queryKey: [ERROR_CODES_QUERY_KEYS.codes, queryParams],
    queryFn: async () => CodesApi.getErrorCodes(queryParams),
    refetchOnWindowFocus: false,
    refetchOnMount:false
  })

  function manageParams(name: string, value: string | number | null) {
    const ParamsList = {
      code: queryParams.code ? {code: `${queryParams.code}`} : {},
      error: queryParams.error ? {error: `${queryParams.error}`} : {},
      uploads_history: queryParams.uploads_history
        ? {uploads_history: `${queryParams.uploads_history}`}
        : {},
      start_date: queryParams.start_date
        ? {start_date: `${queryParams.start_date}`}
        : {},
      end_date: queryParams.end_date
        ? {end_date: `${queryParams.end_date}`}
        : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.code,
      ...params.error,
      ...params.uploads_history,
      ...params.start_date,
      ...params.end_date,
      ...params.page,
    })
    const data = codesQuery.data.data.map((item) => ({...item}))
    queryClient.setQueryData([ERROR_CODES_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: data,
    })
  }

  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    const params = manageParams('page', 1)
    if (startDate === '' || endDate === '')
      setSearchParams({
        ...params.code,
        ...params.error,
        ...params.uploads_history,
      })
    else
      setSearchParams({
        start_date: startDate,
        end_date: endDate,
        ...params.code,
        ...params.error,
        ...params.uploads_history,
      })
  }
    
    const debouncedСodeTerm = useDebounce(codeValue, 1000)
    useEffect(() => {
      const params = manageParams('code', debouncedСodeTerm)
      setSearchParams({
        ...params.code,
        ...params.error,
        ...params.uploads_history,
      })
    }, [debouncedСodeTerm])

    const debouncedErrorTerm = useDebounce(errorValue, 1000)
    useEffect(() => {
      const params = manageParams('error', debouncedErrorTerm)
      setSearchParams({
        ...params.code,
        ...params.error,
        ...params.uploads_history,
      })
    }, [debouncedErrorTerm])

    const debouncedUploadIdTerm = useDebounce(uploadIdValue, 1000)
    useEffect(() => {
      const params = manageParams('uploads_history', debouncedUploadIdTerm)
      setSearchParams({
        ...params.code,
        ...params.error,
        ...params.uploads_history,
      })
    }, [debouncedUploadIdTerm])

  const isTableLoading = codesQuery.isLoading
  return {
    models: {
      values: queryParams,
      codes: codesQuery.data ? codesQuery.data.data : [],
      meta: codesQuery.data ? codesQuery.data.meta : null,
      isTableLoading,
      codeValue,
      errorValue,
      uploadIdValue,
    },
    commands: {
      setCurrentPage,
      handleDateChangeFormValue,
      setCodeValue,
      setErrorValue,
      setUploadIdValue,
    },
  }
}
