import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {IOption} from 'shared/components/select/select'
import {IOrderList, IReorder, OrderApi} from 'app/api/order-api/order-api'
import {useDebounce} from '_metronic/helpers'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {ORDER_QUERY_KEYS} from 'shared/constants/query-keys'
import {initialFormOrderName} from 'shared/constants/orders'
import {DownloadReportApi} from 'app/api/dashboard-api/download-report-api'
import {toast} from 'react-toastify'
import {DownloadService} from 'shared/services/download-service'
import {ICart} from 'shared/types/cart'
import {AxiosError} from 'axios'
import {IOrder} from 'shared/types/order'
import { BlobErrorResponse } from 'shared/helpers/blob-error-response'
import { useCartState } from '../../../../../app/store/cart/state'
import { useUserState } from 'app/store/user/state'
import { selectAuthUserData } from 'app/store/auth/selects'

export const useAllOrdersPage = () => {
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const {setCart} = useCartState()
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const userId = selectAuthUserData()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(search.get('number'))
  const queryClient = useQueryClient()
  const queryParams = useMemo(() => {
    return removeEmptyFields({
      number: search.get('number'),
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      status: Number(search.get('status')) || null,
      method: Number(search.get('method')) || null,
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const ordersQuery = useQuery({
    queryKey: [ORDER_QUERY_KEYS.all_orders, queryParams],
    queryFn: async () => await OrderApi.getAllOrders(queryParams),
  })

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      number: queryParams.number ? {number: queryParams.number} : {},
      start_date: queryParams.start_date
        ? {start_date: queryParams.start_date}
        : {},
      end_date: queryParams.end_date ? {end_date: queryParams.end_date} : {},
      status: queryParams.status ? {status: `${queryParams.status}`} : {},
      method: queryParams.method ? {method: `${queryParams.method}`} : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('number', debouncedSearchTerm)
    setSearchParams({
      ...params.number,
      ...params.start_date,
      ...params.end_date,
      ...params.status,
      ...params.method,
    })
  }, [debouncedSearchTerm])

  const handleChangeFormValue = (
    name: initialFormOrderName,
    value: IOption
  ) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams({
      ...params.number,
      ...params.start_date,
      ...params.end_date,
      ...params.status,
      ...params.method,
    })
  }
  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    const params = manageParams('page', 1)
    if (startDate === '' || endDate === '')
      setSearchParams({
        ...params.number,
        ...params.status,
        ...params.method,
      })
    else
      setSearchParams({
        ...params.number,
        start_date: startDate,
        end_date: endDate,
        ...params.status,
        ...params.method,
      })
  }

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.number,
      ...params.start_date,
      ...params.end_date,
      ...params.status,
      ...params.method,
      ...params.page,
    })
  }

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        number: queryParams.number,
        status: queryParams.status,
        method: queryParams.method,
        start_date: queryParams.start_date,
        end_date: queryParams.end_date,
      }
      const csvData = await DownloadReportApi.getOrderReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Orders Report')
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const reorderMutation = useMutation<
    ICart,
    AxiosError<{message: string}>,
    IReorder
  >(OrderApi.reorderByUser, {
    onSuccess: (cart: ICart,variables:IReorder) => {
      queryClient.invalidateQueries([ORDER_QUERY_KEYS.orders, queryParams],{exact:false})
      if (variables.userId === userId){
        setCart(cart)
        const productsInCart = cart.codes.reduce(
          (sum, code) => sum + (code.quantity as number),
          0
        )
        setIsCartHasProducts(true)
        setProductsInCartQuantity(productsInCart)
      }
      toast.success(
        "Order items has been added to the customer's cart successfully!"
      )
    },
    onError: (data: AxiosError<{message?: string; error?: string}>) => {
      data.response.data.message && toast.error(data.response.data.message)
      data.response.data.error && toast.error(data.response.data.error)
    },
  })

  const cancelPreorderMutation = useMutation<
    IOrder,
    AxiosError<{message: string}>,
    number
  >(OrderApi.cancelPreorder, {
    onSuccess: (order: IOrder, variable: number) => {
      queryClient.setQueryData(
        [ORDER_QUERY_KEYS.all_orders, queryParams],
        (prevData: IOrderList) => {
          const orderId = prevData.data.findIndex(
            (order) => order.id === variable
          )
          prevData.data.splice(orderId, 1, order)
          return prevData
        }
      )
      queryClient.invalidateQueries([ORDER_QUERY_KEYS.orders, queryParams])
      toast.success('Preorder cancelled successfully!')
    },
    onError: (data: AxiosError<{message: string}>) => {
      toast.error(data.response.data.message)
    },
  })

  const isTableLoading = ordersQuery.isLoading

  const handleReorderClick = ({userId, orderId}: IReorder) => {
    reorderMutation.mutate({userId, orderId})
  }

  const handleCancelPreorderClick = (orderId: number) => {
    cancelPreorderMutation.mutate(orderId)
  }
  return {
    models: {
      values: queryParams,
      orders: ordersQuery.data ? ordersQuery.data.data : [],
      meta: ordersQuery.data ? ordersQuery.data.meta : null,
      isTableLoading,
      searchValue,
      isCSVLoading,
      isReorderLoading: reorderMutation.isLoading,
      orderIdForLoader: reorderMutation.variables?.orderId,
      isCancelPreorderLoading: cancelPreorderMutation.isLoading,
      preorderIdForLoader: cancelPreorderMutation.variables,
    },
    commands: {
      handleChangeFormValue,
      handleDateChangeFormValue,
      setCurrentPage,
      setSearchValue,
      onDownloadCSV,
      handleReorderClick,
      handleCancelPreorderClick,
    },
  }
}
