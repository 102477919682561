import React, {useMemo, useState} from "react"
import {useMutation, useQueryClient} from "react-query"
import {ButtonList} from "shared/components/button-list/button-list"
import {Button} from "shared/components/button/button"
import {Input} from "shared/components/input/input"
import {Modal} from "shared/components/modal-base"
import {Select} from "shared/components/select/select"
import {INVOICES_MODAL_NAME} from "shared/constants/modal-names"
import {useStateModalManager} from "shared/context/modal-manager"
import {IAdjustWalletParams, IWallet} from "shared/types/api-types/wallets-type"
import {AxiosError} from "axios"
import {WalletsApi} from "app/api/dashboard-api/wallets-api"
import {toast} from "react-toastify"
import {useWalletsState} from "app/store/wallets/state"
import {INVOICE_QUERY_KEYS} from "shared/constants/query-keys"
import {selectAuthUserData} from "../../../../../app/store/auth/selects";
import {EventBus, GLOBAL_EVENTS} from "../../../../../shared/utils/event-bus";
import {InvoiceApi} from "app/api/invoices-api/invoices-api"
import {DownloadService} from "shared/services/download-service"
import {useFormik} from "formik";
import {Loader} from "../../../../../shared/components/loader/loader";
import { decimalFormatter } from "shared/helpers/decimalFormatter"

interface ModalBeforeData {
  user: number
  wallets: IWallet[]
  total?: number
  wallet?: number
}

interface IWalletFormValue {
    amount?: number
    wallet?: number
}

const initialValues: IWalletFormValue = {
  amount: null,
  wallet: null,
}

interface IErrorForm {
    amount?: string
    wallet?: string
}

export const CreateInvoiceModal:React.FC<IWalletFormValue> = () => {
    const [user, setUser] = useState(NaN);
    const [wallets, setWallets] = useState([]);

    const modalState = useStateModalManager(INVOICES_MODAL_NAME.create_invoice, {
        onBeforeOpen: (data: ModalBeforeData) => {
            setUser(data.user)
            setWallets(data.wallets)
            if (data.total) {
              formik.setValues({
                amount: data.total,
                wallet: data.wallet,
              })
            }
        },
    })

    const {onUpdateWallet} = useWalletsState()
    const currentUser = selectAuthUserData()
    const queryClient = useQueryClient()

    const handleClose = () => {
        formik.resetForm()
        modalState.close()
    }

    const validate = (values: IWalletFormValue) => {
        const error: IErrorForm = {}
        if (!values.amount) {
            error.amount = 'Amount is required field !'
        }
        if (values.amount < 0) {
            error.amount = 'Amount - must be greater than zero '
        }
        if (!values.wallet) {
            error.wallet = 'Wallet is required field !'
        }
        return error
    }
    const createInvoiceMutation = useMutation<
        IWallet,
        AxiosError<{ message: string }>,
        IAdjustWalletParams
    >(WalletsApi.topUpWallet, {
        onSuccess: async data => {
            queryClient.invalidateQueries(INVOICE_QUERY_KEYS.invoices)
            if (currentUser === user) {
                onUpdateWallet(data)
            }
            const response = await InvoiceApi.getInvoicePDF(user, data.invoice_id)
            DownloadService.downloadObjectAsZip(response, `${data.updated_at}.pdf`)
            EventBus.emit(GLOBAL_EVENTS.reset_invoice)
            formik.resetForm()
            queryClient.invalidateQueries(INVOICE_QUERY_KEYS.all_invoices)
            queryClient.invalidateQueries(INVOICE_QUERY_KEYS.invoices)
            toast.success('Invoice created successfully!')
            modalState.close()
        },
        onError: error => {
            toast.error(error.response.data.message)
        }
    })
    const handleCreateInvoiceClick = (value: IWalletFormValue) => {
        if (user) {
            createInvoiceMutation.mutate({params: {user, wallet: value.wallet}, amount: decimalFormatter(value.amount)})
        }
    }
    const formik = useFormik<IWalletFormValue>(
        {
            initialValues,
            onSubmit: handleCreateInvoiceClick,
            validate
        }
    )

    const currentWallet: IWallet = wallets.find(
      (wallet) => formik.values.wallet === wallet.id
    )
    const walletCurrency = currentWallet?.currency.symbol

    const walletsValue = useMemo(() => {
        if (!wallets && !wallets.length) return []
        return wallets.map(wallet => {
            return {id: wallet.id, label: wallet.currency.iso}
        })
    }, [wallets]);

    const handleChangeWallet = (option) => {
      formik.setFieldValue('wallet', option.id)
    }

    return (
        <Modal.Root
            open={modalState.open}
            onClose={handleClose}
            isClosable={!createInvoiceMutation.isLoading}
        >
            <div className="p-5 position-relative">
                {createInvoiceMutation.isLoading && <Loader mode={'blur'}/>}
                <h5 className='text-center my-5'>Fill in the amount
                    you want to deposit into your account. Select which wallet to deposit
                    to. You will then be provided a reference number please provide this
                    when paying.
                </h5>
                <div className="d-flex justify-content-center align-items-start gap-5">
                    <div>
                        <label>Amount</label>
                        <Input
                            value={formik.values.amount}
                            type='number'
                            mode='number'
                            isPriceInput
                            name='amount'
                            currency={walletCurrency}
                            error={formik.touched.amount && !!formik.errors.amount}
                            errorText={formik.touched.amount && formik.errors.amount}
                            {...formik.getFieldProps('amount')}
                        />
                    </div>
                    <div>
                        <label>Wallet</label>
                        <Select
                            options={walletsValue}
                            onChange={handleChangeWallet}
                            value={formik.values.wallet}
                            error={!!(formik.touched.wallet && formik.errors.wallet)}
                            errorText={(formik.touched.wallet && formik.errors.wallet) ? formik.errors.wallet : null}
                            placeholder="Select wallet"
                            className="w-150px"
                        />
                    </div>
                </div>
                <ButtonList className='justify-content-end mt-10 mb-5'>
                    <Button label='Cancel' onClick={handleClose}/>
                    <Button
                        label='Create'
                        mainButton
                        onClick={formik.submitForm}
                        isLoading={createInvoiceMutation.isLoading}
                        disabled={createInvoiceMutation.isLoading}
                    />                    
                </ButtonList>
            </div>
        </Modal.Root>
    )
}