import {components} from "react-select";
import {Icon} from "../icon/icon";
import {ICON_COLLECTION} from "../icon/icon-list";
import React from "react";

export const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon icon={ICON_COLLECTION.arrowDown}/>
            </components.DropdownIndicator>
        )
    );
};