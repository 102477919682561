import React from 'react'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {INVOICES_MODAL_NAME} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'

export const ConfirmDeleteAdminInvoiceModal = () => {
  const modalState = useStateModalManager(INVOICES_MODAL_NAME.delete_admin_invoice)

  const handleConfirmDeleteClick = () => {
    modalState.resolveCallback()
    modalState.close()
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
  }

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
    >
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to delete this invoice?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose}/>
          <Button
            label='Delete'
            mode='remove'
            onClick={handleConfirmDeleteClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
