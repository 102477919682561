import {BaseHttpServices} from "shared/services/base-http-services";
import {
    IApiUsageResponse, IGeneralStatisticResponse,
    ILastOrderResponse,
    IStatisticFilters, ITopClientsResponse,
    ITopSellingResponse
} from "shared/types/api-types/statistic-type";


type ILowStockResponse = ITopSellingResponse

export class StatisticsApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getTopSelling = async (data: IStatisticFilters): Promise<ITopSellingResponse> => {
        const payload = await this.http.get(`/statistic/top-selling`,
            {
                params: {...data, per_page: 5},
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getLastOrders = async (data: IStatisticFilters): Promise<ILastOrderResponse> => {
        const payload = await this.http.get(`/statistic/last-orders`,
            {
                params: {...data, per_page: 5},
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };

    getTopClients = async (data: IStatisticFilters): Promise<ITopClientsResponse> => {
        const payload = await this.http.get(`/statistic/top-clients`,
            {
                params: {...data, per_page: 5},
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getLowStock = async (data: IStatisticFilters): Promise<ILowStockResponse> => {
        const payload = await this.http.get(`/statistic/low-stock`,
            {
                params: {...data, per_page: 5},
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getApiUsage = async (data: IStatisticFilters): Promise<IApiUsageResponse> => {
        const payload = await this.http.get(`/statistic/api-usage`,
            {
                params: {...data, per_page: 5},
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getRevenue = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/revenue`,
            {
                params: data,
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getProffit = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/proffit`, {
          params: data,
          responseType: data?.csv && data?.csv === 1 ? 'blob' : 'json',
        })
        return payload.data;
    };
    getUnitsSold = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/units-sold`,
            {
                params: data,
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getOrdersStatistic = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/orders`,
            {
                params: data,
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getOrdersAVG = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/orders-avg`,
            {
                params: data,
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getCodesUploadStatistic = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/codes-upload`,
            {
                params: data,
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };
    getUsersOnSite = async (data: IStatisticFilters): Promise<{ data: { [propName: string]: number } }> => {
        const payload = await this.http.get(`/statistic/users-on-site`,
            {
                params: data,
                responseType: (data?.csv && data?.csv === 1) ? 'blob' : "json",
            });
        return payload.data;
    };

    getGeneralStatistic = async (): Promise<IGeneralStatisticResponse> => {
        const payload = await this.http.get(`/statistic/general`);
        return payload.data.data;
    };

}

export const StatisticsApi = new StatisticsApiService(new BaseHttpServices());