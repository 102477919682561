import { IOption } from "shared/components/select/select"

export const ORDER_STATUSES: IOption[] = [
  {
    label: 'Pending',
    id: 1,
  },
  {
    label: 'Completed',
    id: 2,
  },
  {
    label: 'Failed',
    id: 3,
  },
  {
    label: 'Pre-ordered',
    id: 4,
  },
  {
    label: 'Unpaid Pre-order',
    id: 5,
  },
  {
    label: 'Part-delivered',
    id: 6,
  },
]

export const ORDER_METHODS: IOption[] = [
  {
    label: 'WEB',
    id: 1,
  },
  {
    label: 'API',
    id: 2,
  },
]

export type initialFormOrderName = 'number' | 'start_date' | 'end_date' | 'status' | 'method'