import {RouteObject} from 'react-router-dom'

import {CatalogPage} from 'modules/dashboard/catalog/pages/catalog-page/catalog-page'
import {InvoicesPage} from 'modules/dashboard/invoices/pages/invoices-page'
import {CodesPage} from 'modules/dashboard/codes/pages/codes-page/codes-page'
import {AccountMainInformationPage} from 'modules/dashboard/account/pages/main/account-main'
import {AccountFilesPage} from 'modules/dashboard/account/pages/files/account-files'
import {AccountWalletsPage} from 'modules/dashboard/account/pages/wallets/account-wallets'
import {RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import {RoleGuard} from '../guards/role-guard'
import {CodesUploadHistoryPage} from 'modules/dashboard/codes/pages/codes-upload-history/codes-upload-history'
import {CreateCodes} from 'modules/dashboard/codes/pages/codes-create/codes-create'
import {AccountPagesWrapper} from 'modules/dashboard/account/pages/account-pages-wrapper'
import {ProductsPage} from 'modules/dashboard/catalog/pages/products-page/products-page'
import {
  ACCOUNT_ROUTES,
  CODES_ROUTES,
  DASHBOARD_ROUTES,
  PRODUCT_ROUTES,
  PURCHASES_ROUTES,
  SUPPLIER_ROUTES,
} from 'shared/config/routes'
import {SingleProductPage} from 'modules/dashboard/catalog/pages/single-product-page/single-product-page'
import {CreateProductPage} from 'modules/dashboard/catalog/pages/create-product/create-product-page'
import {AccountApiPage} from 'modules/dashboard/account/pages/api/account-api'
import {AccountSettingsPage} from 'modules/dashboard/account/pages/settings/account-settings'
import {optionsRoutes} from './options-routes'
import {customersRoutes} from './customers-routes'
import {ordersRoutes} from './orders-routes'
import {PurchaseTrackerPage} from 'modules/dashboard/purchase-tracker/pages/purchase-tracker'
import {PurchasePage} from 'modules/dashboard/purchase-tracker/pages/purchase'
import {StatisticPage} from '../../modules/dashboard/statistic/statistic-page'
import {AllInvoicesPage} from 'modules/dashboard/all-invoices/pages/all-invoices-page'
import {discountsRoutes} from './discounts-routes'
import {AllWalletsPage} from 'modules/dashboard/all-wallets/pages/all-wallets-page'
import {ErrorCodesPage} from 'modules/dashboard/codes/pages/error-codes/error-codes'
import {SupplierTrackerPage} from 'modules/dashboard/supplier-tracker/pages/supplier-tracker/supplier-tracker'
import {ProductSupplierPage} from 'modules/dashboard/supplier-tracker/pages/product-suppliers-page/product-suppliers-page'
import { SuppliersPage } from 'modules/dashboard/supplier-tracker/pages/suppliers/suppliers'
import { CreateSupplierPage } from '../../modules/dashboard/supplier-tracker/pages/create-supplier-page/create-supplier-page';
import { SingleSupplierPage } from '../../modules/dashboard/supplier-tracker/pages/single-supplier-page/single-supplier-page';
import { AccountTwoFactorPage } from '../../modules/dashboard/account/pages/two-factor/account-two-factor-page';

export const dashboardRoutes: RouteObject[] = [
  {
    path: DASHBOARD_ROUTES.CATALOG.path,
    element: <CatalogPage />,
  },
  {
    path: PRODUCT_ROUTES.PRODUCT.path,
    element: <ProductsPage />,
  },
  {
    path: PRODUCT_ROUTES.CREATE_PRODUCT.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
        <CreateProductPage />
      </RoleGuard>
    ),
  },
  {
    path: PRODUCT_ROUTES.SINGLE_PRODUCT.path,
    element: <SingleProductPage />,
  },
  {
    path: DASHBOARD_ROUTES.ALL_INVOICES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <AllInvoicesPage />
      </RoleGuard>
    ),
  },
  {
    path: DASHBOARD_ROUTES.INVOICES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
        <InvoicesPage />
      </RoleGuard>
    ),
  },

  {
    path: DASHBOARD_ROUTES.CODES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CodesPage />
      </RoleGuard>
    ),
  },
  {
    path: CODES_ROUTES.CREATE_CODES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CreateCodes />
      </RoleGuard>
    ),
  },
  {
    path: CODES_ROUTES.UPLOAD_HISTORY.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CodesUploadHistoryPage />
      </RoleGuard>
    ),
  },
  ...ordersRoutes,

  {
    path: DASHBOARD_ROUTES.INVOICES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
        <InvoicesPage />
      </RoleGuard>
    ),
  },

  {
    path: DASHBOARD_ROUTES.CODES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CodesPage />
      </RoleGuard>
    ),
  },
  {
    path: CODES_ROUTES.CREATE_CODES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CreateCodes />
      </RoleGuard>
    ),
  },
  {
    path: CODES_ROUTES.UPLOAD_HISTORY.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CodesUploadHistoryPage />
      </RoleGuard>
    ),
  },
  {
    path: CODES_ROUTES.ERROR_CODES.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <ErrorCodesPage />
      </RoleGuard>
    ),
  },
  {
    element: <AccountPagesWrapper />,
    children: [
      {path: ACCOUNT_ROUTES.MAIN.path, element: <AccountMainInformationPage />},
      {path: ACCOUNT_ROUTES.FILES.path, element: <AccountFilesPage />},
      {
        path: DASHBOARD_ROUTES.ALL_WALLETS.path,
        element: (
          <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
            <AllWalletsPage />
          </RoleGuard>
        ),
      },
      {
        path: ACCOUNT_ROUTES.WALLETS.path,
        element: (
          <RoleGuard
            I={RouteGuardActions.manage}
            a={RouteGuardEntities.Customer}
          >
            <AccountWalletsPage />
          </RoleGuard>
        ),
      },
      {
        path: ACCOUNT_ROUTES.API.path,
        element: (
          <RoleGuard
            I={RouteGuardActions.manage}
            a={RouteGuardEntities.CustomerNotAdmin}
          >
            <AccountApiPage />
          </RoleGuard>
        ),
      },
      {path: ACCOUNT_ROUTES.SETTINGS.path, element: <AccountSettingsPage />},
      {path: ACCOUNT_ROUTES.TWO_FACTOR.path, element: <AccountTwoFactorPage />},
    ],
  },
  ...customersRoutes,
  ...optionsRoutes,
  {
    path: DASHBOARD_ROUTES.PURCHASE_TRACKER.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <PurchaseTrackerPage />
      </RoleGuard>
    ),
  },
  {
    path: PURCHASES_ROUTES.PURCHASE.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <PurchasePage />
      </RoleGuard>
    ),
  },
  {
    path: SUPPLIER_ROUTES.SUPPLIERS_TRACKER.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <SupplierTrackerPage />
      </RoleGuard>
    ),
  },
  {
    path: SUPPLIER_ROUTES.SUPPLIER.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <ProductSupplierPage />
      </RoleGuard>
    ),
  },
  {
    path: SUPPLIER_ROUTES.SUPPLIERS.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <SuppliersPage />
      </RoleGuard>
    ),
  },
  {
    path: SUPPLIER_ROUTES.CREATE_SUPPLIER.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <CreateSupplierPage />
      </RoleGuard>
    ),
  },
  {
    path: SUPPLIER_ROUTES.SINGLE_SUPPLIER.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <SingleSupplierPage />
      </RoleGuard>
    ),
  },
  {
    path: DASHBOARD_ROUTES.STATISTIC.path,
    element: (
      <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
        <StatisticPage />
      </RoleGuard>
    ),
  },
  ...discountsRoutes,
]
