import React from 'react'
import {Pagination} from '../../../components/pagination/pagination'
import {useAllOrdersPage} from './use-all-orders-page'
import {AllOrdersSearchBar} from '../../components/all-orders-search-bar'
import {AllOrdersTable} from '../../components/table/orders/all-orders-table'
import {Button} from 'shared/components/button/button'

export interface IOrdersInitValue {
  number: string
  start_date: string
  end_date: string
  status: number
  method: number
  page: number
}

export const AllOrdersPage = () => {
  const {models, commands} = useAllOrdersPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-sm-center mb-5 flex-column flex-wrap flex-sm-row'>
        <h1 className='text-exl-primary bold'>All Orders</h1>
        <Button
          label='Export CSV'
          type='button'
          isLoading={models.isCSVLoading}
          disabled={models.isCSVLoading}
          onClick={commands.onDownloadCSV}
        />
      </div>
      <div className='card p-7'>
        <AllOrdersSearchBar
          searchValue={models.searchValue}
          statusValue={models.values.status}
          methodValue={models.values.method}
          handleChangeFormValue={commands.handleChangeFormValue}
          handleDateChangeFormValue={commands.handleDateChangeFormValue}
          setSearchValue={commands.setSearchValue}
        />
        <AllOrdersTable
          orders={models.orders}
          isLoading={models.isTableLoading}
          isReorderLoading={models.isReorderLoading}
          isPreorderCancelling={models.isCancelPreorderLoading}
          preorderIdForLoader={models.preorderIdForLoader}
          orderIdForLoader={models.orderIdForLoader}
          handleReorderAction={commands.handleReorderClick}
          handleCancelPreorderAction={commands.handleCancelPreorderClick}
        />
        {models.orders && models.orders.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
    </>
  )
}
