import React, {useState} from 'react'
import {apiContent, walletContent} from '../../constants/main'
import {useInView} from 'react-intersection-observer'
import clsx from 'clsx'
import {LANDING_NAVIGATION} from 'modules/landing/constants/landing-navigation'
import styles from './landing-api.module.scss'
import {ManualCard} from "./manual-card";

const API_TYPES = {
    api: 'api',
    manual: 'manual'
}

const LandingApi = () => {
    const [apiType, setApiType] = useState(API_TYPES.api)
    const onActiveAPI = () => setApiType(API_TYPES.api)
    const onInactiveAPI = () => setApiType(API_TYPES.manual)
    const [titleRef, titleInView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    })
    return (
        <section id={LANDING_NAVIGATION.api.id} className={styles['api']}>
            <div className={styles['api__container']}>
                <div className={styles['api-header']}>
                    <h2
                        onClick={onActiveAPI}
                        className={clsx(styles['api-header__title'],
                            apiType === API_TYPES.api ? [styles['api-header__title--active']] : [styles['api-header__title--inactive']],
                            {
                                [styles['api-header--in-view']]: titleInView,

                            })}
                        ref={titleRef}
                    >
                        API
                    </h2>
                    <h2
                        onClick={onInactiveAPI}
                        className={clsx(styles['api-header__title'],
                            apiType === API_TYPES.manual ? [styles['api-header__title--active']] : [styles['api-header__title--inactive']], {
                                [styles['api-header--in-view']]: titleInView,

                            })}
                        ref={titleRef}
                    >
                        Manual
                    </h2>
                </div>
                <ManualCard
                    isRevers={true}
                    show={!!apiType}
                    text={apiContent[apiType].text}
                    image={apiContent[apiType].image}/>


                <ManualCard
                    isRevers={false}
                    text={walletContent.text}
                    image={walletContent.image}/>
            </div>

        </section>
    )
}

export default LandingApi
