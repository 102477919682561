import React, {ReactNode, useEffect, useMemo, useState} from 'react'
import styles from '../../pages/single-product-page/single-product-page.module.scss'
import imgMoc from 'shared/assets/images/placehoder.jpg'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {ISingleProduct} from 'shared/types/products'
import {Textarea} from 'shared/components/textarea/textarea'
import {Input} from 'shared/components/input/input'
import {UploadImage} from '../upload-image/upload-image'
import {Toggle} from 'shared/components/toggle/toggle'
import {Select} from 'shared/components/select/select'
import {MultiSelect} from 'shared/components/select/multi-select'
import {useSelectController} from '../../../../../shared/hooks/use-select-controller'
import {
  CATEGORIES_QUERY_KEYS,
  CODES_QUERY_KEYS,
  REGIONS_QUERY_KEYS,
} from '../../../../../shared/constants/query-keys'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {RegionsApi} from 'app/api/dashboard-api/regions-api'
import {thousandFormatter} from 'shared/helpers/formatter'
import {
  _ProductStatusController,
  StatusProductItemType,
  STOCK_AMOUNT_FLAG,
} from '../product-item/status-config'
import {decimalFormatter} from 'shared/helpers/decimalFormatter'

interface IProductFormProps {
  value: Omit<ISingleProduct, 'id'>
  isEdit: boolean
  setValue: <T>(name: string, value: T) => void
  onEditImage: (blob: string | null) => void
  onUpdateFile: (file: File) => void
  errors?: {
    name?: string
    face_price?: string
  }
  onRemoveImage: () => void
  addAllToCart?: () => void
  onCreatePreOrder?: () => void
  isLoadingCart?: boolean
  children?: ReactNode
}
type ProductKeysType = keyof ISingleProduct

const ProductFormComponent: React.FC<IProductFormProps> = (props) => {
  const {
    value,
    isEdit,
    setValue,
    onEditImage,
    onUpdateFile,
    errors,
    onRemoveImage,
    addAllToCart,
    isLoadingCart,
    onCreatePreOrder,
    children,
  } = props

  const [initGeneralPrice, setinitGeneralPrice] = useState(
    Number(value.general_price)
  )
  const isStock = value.stock && value.quantity > 0

  const stockAmountCodes = isStock ? value.quantity : value.preorder_quantity
  const handelSetDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => setValue('description', event.target.value)

  const handelSetValue =
    (name: ProductKeysType) => (event: React.ChangeEvent<HTMLInputElement>) =>
      name === 'name'
        ? setValue(name, event.target.value)
        : setValue(name, decimalFormatter(event.target.value))
  const handelSetGeneralSellPrice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue('general_price', decimalFormatter(event.target.value))
    if (decimalFormatter(event.target.value) > 0) {
      setValue('is_general_price_available', true)
    } else {
      setValue('is_general_price_available', false)
    }
  }
  const handelSetPreorderQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(
      'preorder_quantity',
      event.target.value === '' ? '' : decimalFormatter(event.target.value)
    )
  }
  const handelSetStock = () => setValue('is_hidden', !value.is_hidden)
  const handelUpdateisUnlimited = () =>
    setValue('is_preorder_unlimited', !value.is_preorder_unlimited)
  const onToggleSelPrice = () => {
    setValue(
      'general_price',
      !value.is_general_price_available ? initGeneralPrice : '0'
    )
    setValue('is_general_price_available', !value.is_general_price_available)
  }
  const onTogglePrOrder = () => {
    if (value.preorder_avaibable) {
      setValue('preorder_avaibable', false)
    } else {
      setValue('preorder_avaibable', true)
    }
  }

  const {
    options: currencyOptions,
    isLoading: isLoadingCurrencies,
    formationOptions: optionsCurrencies,
  } = useSelectController({
    key: CODES_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
    format: 'currencyOptions',
  })

  const {
    isLoading: isLoadingCategory,
    handleInputChange: handleInputChangeCategory,
    formationOptions: categories,
  } = useSelectController({
    key: CATEGORIES_QUERY_KEYS.categories,
    Fn: SelectsApi.getCategories,
    params: 'name',
    format: 'multiOptions',
  })

  const {isLoading: isLoadingRegions, formationOptions: regionsOptions} =
    useSelectController({
      key: REGIONS_QUERY_KEYS.regions,
      Fn: RegionsApi.getRegions,
    })

  const categoriesValue = useMemo(() => {
    if (!value.categories && !value.categories.length) return []
    return value.categories.map((item) => {
      return {value: item.id, label: item.name}
    })
  }, [value.categories])

  const onChangeCategory = (value) => {
    const temCategory = value.map((item) => {
      return {id: item.value, name: item.label}
    })
    setValue('categories', temCategory)
  }
  const onChangeCurrency = (value) => setValue('currency', value)
  const onChangeRegion = (value) => setValue('region', value)

  const ProductStatusController = new _ProductStatusController({
    isHidden: value.is_hidden,
    isUnlimited: value.is_preorder_unlimited,
    quantity: value.quantity,
    preorderQuantity: value.preorder_quantity,
    preorderAvailable: value.preorder_avaibable,
    stock: value.stock,
  })
  const businessStatus: StatusProductItemType =
    ProductStatusController.getStatus()

  const curCurrency = currencyOptions.find(
    (cur) => value?.currency?.id === cur.id
  )
  const currencySymbol = curCurrency?.symbol

  return (
    <div className='row'>
      <div className='col-md-3'>
        <div className='position-relative min-h-200px'>
          {isEdit && (
            <UploadImage
              onRemove={onRemoveImage}
              setImage={onEditImage}
              onUpdateFile={onUpdateFile}
            />
          )}
          <figure className={styles.hero}>
            <img
              src={value.image ? value.image.original_url : imgMoc}
              alt='original_url'
              className={styles.hero__image}
            />
          </figure>
        </div>
      </div>
      <div className='col-md-9 mt-5 mt-md-0'>
        {isEdit ? (
          <>
            <Input
              placeholder='Product name'
              error={errors ? !!errors.name : null}
              errorText={errors && errors?.name}
              autoFocus
              type='text'
              value={value.name}
              onChange={handelSetValue('name')}
              className=' w-sm-300px'
            />
            <Input
              placeholder='Face price'
              error={errors ? !!errors.face_price : null}
              errorText={errors && errors?.face_price}
              autoFocus
              type='number'
              isPriceInput
              currency={currencySymbol}
              value={value.face_price}
              onChange={handelSetValue('face_price')}
              className=' w-sm-300px mt-5'
            />
            <Select
              options={regionsOptions}
              isLoading={isLoadingRegions}
              value={value.region ? value.region.id : null}
              onChange={onChangeRegion}
              placeholder='Regions'
              className='w-sm-300px mt-5'
            />
            <MultiSelect
              options={categories}
              isLoading={isLoadingCategory}
              isMulti
              value={categoriesValue}
              onInputChange={handleInputChangeCategory}
              placeholder='Categories'
              onChange={onChangeCategory}
              className='w-sm-300px mt-5'
            />
          </>
        ) : (
          <>
            {value.face_price && (
              <p>
                Face Price: {value.currency.symbol}
                {thousandFormatter(value.face_price)}{' '}
              </p>
            )}
            {value.region && <p>Region: {value.region.name}</p>}

            {categoriesValue.length ? (
              <>
                <p>Categories:</p>
                <ul className='list-group w-sm-50'>
                  {categoriesValue.map((category) => (
                    <li key={category.value} className='list-group-item'>
                      {category.label}
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
          </>
        )}
        {!value.description?.length && !isEdit ? null : (
          <Textarea
            value={value.description}
            className='mt-5'
            disabled={!isEdit}
            placeholder={isEdit ? 'Description' : ''}
            mode='light'
            onChange={handelSetDescription}
          />
        )}
        {isEdit ? (
          <>
            <div className='d-flex align-items-center mt-5'>
              <p className='mb-0 mx-3'>Hide Product</p>
              <Toggle isActive={value.is_hidden} setActive={handelSetStock} />
            </div>
            <div className='d-flex align-items-center mt-5'>
              <p className='mb-0 mx-3'>Allow Pre-Order</p>
              <Toggle
                isActive={value.preorder_avaibable}
                setActive={onTogglePrOrder}
                // disabled={!isPride}
              />
            </div>
            {value.preorder_avaibable ? (
              <div className='d-flex align-items-center mt-5'>
                <Input
                  type='number'
                  mode='number'
                  step={1}
                  min={0}
                  integerLimit={4}
                  className='mb-0 mx-3 w-100px'
                  value={value.preorder_quantity}
                  onChange={handelSetPreorderQuantity}
                />
              </div>
            ) : null}
            <div className='d-flex align-items-center mt-5'>
              <p className='mb-0 mx-3'>Unlimited Pre-Order</p>
              <Toggle
                isActive={value.is_preorder_unlimited}
                setActive={handelUpdateisUnlimited}
              />
            </div>
            <div className='d-flex align-items-center mt-5'>
              <p className='mb-0 mx-3'>General Sell Price</p>
              <Toggle
                isActive={value.is_general_price_available}
                setActive={onToggleSelPrice}
              />
            </div>

            <div className='d-flex align-items-center mt-5'>
              <Input
                type='number'
                mode='number'
                className='mb-0 mx-3 w-100px'
                isPriceInput
                currency={currencySymbol}
                value={value.general_price}
                onChange={handelSetGeneralSellPrice}
              />
              <Select
                options={optionsCurrencies}
                className='w-sm-200px'
                isLoading={isLoadingCurrencies}
                value={value.currency ? value.currency.id : null}
                onChange={onChangeCurrency}
              />
            </div>
          </>
        ) : null}
        {!isEdit && (
          <ButtonList className='d-flex align-items-center mt-10'>
            <Button
              label={businessStatus.single_button_name}
              mainButton
              onClick={
                businessStatus.is_in_stock_btn ? addAllToCart : onCreatePreOrder
              }
              disabled={
                businessStatus.is_disabled_action_button_all || isLoadingCart
              }
              isLoading={isLoadingCart}
            />

            <h6>{`${businessStatus.name}: ${
              businessStatus.amount === STOCK_AMOUNT_FLAG
                ? stockAmountCodes
                : businessStatus.amount
            }`}</h6>
          </ButtonList>
        )}
        {children}
      </div>
    </div>
  )
}

export const ProductForm = React.memo(ProductFormComponent)
