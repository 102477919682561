import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from "react-query";
import {STATISTICS_QUERY_KEYS} from "shared/constants/query-keys";
import {StatisticsApi} from "app/api/dashboard-api/statistics-api";
import {IStatisticFilters, PeriodsType} from "shared/types/api-types/statistic-type";
import {BarChart} from "./bar-chart";
import {STATISTIC_MODAL_NAME} from "shared/constants/modal-names";
import {EventBus, STATISTIC_FILERS_EVENTS} from "shared/utils/event-bus";
import {useModalManager} from "shared/context/modal-manager";
import {DownloadService} from "shared/services/download-service";
import {toast} from "react-toastify";
import { INITIAL_PARAMS } from '../../constants/init-params';

export const OrdersChart = () => {
    const [params, setParams] = useState<IStatisticFilters>(INITIAL_PARAMS)
    const [isCSVLoading, setIsCSVLoading] = useState(false);
    const modalManager = useModalManager();
    const ordersChartQuery = useQuery({
        queryKey: [STATISTICS_QUERY_KEYS.orders_chart, params],
        queryFn: () => StatisticsApi.getOrdersStatistic(params),
        onError: () => {
            toast.error('Something went wrong with Orders chart')
        },
    })
    const ordersChart = useMemo(() => {
        const datesArray = [];
        const valuesArray: number[] = [];
        if (!ordersChartQuery.data || !ordersChartQuery.data.data) {
            return {
                datesArray,
                valuesArray: [{name: '+', data: []}]
            }
        }
        for (const date in ordersChartQuery.data.data) {
            datesArray.push(date);
            valuesArray.push(ordersChartQuery.data.data[date]);
        }
        return {
            datesArray,
            valuesArray: [{name: 'Orders', data: valuesArray}]
        }
    }, [ordersChartQuery.data]);

    const handelChangePeriod = (period: PeriodsType) => {
        setParams(prevState => {
            return {...prevState, period}
        })
    }
    const onOpenFilters = () => {
        modalManager.open(STATISTIC_MODAL_NAME.filter_statistics, {
            key: STATISTIC_FILERS_EVENTS.orders_chart,
            params,
        });
    };
    const onDownloadCSV = async () => {
        try {
            setIsCSVLoading(true)
            const response = await StatisticsApi.getOrdersStatistic({...params, csv: 1})
            await DownloadService.downloadObjectAsZip(response, 'Orders Statistic CSV')
            setIsCSVLoading(false)
        } catch (error) {
            setIsCSVLoading(false)
            toast.error('Download CSV Error!')
        }
    }

    useEffect(() => {
        EventBus.on(STATISTIC_FILERS_EVENTS.orders_chart, (data) => setParams({...data}))
        return () => {
            EventBus.off(STATISTIC_FILERS_EVENTS.orders_chart, (data) => setParams({...data}))
        };
    }, []);


    return (
      <BarChart
        onDownload={onDownloadCSV}
        title='Orders'
        onOpenFilters={onOpenFilters}
        names={ordersChart.datesArray}
        isLoading={ordersChartQuery.isLoading || isCSVLoading}
        period={params.period || 3}
        values={ordersChart.valuesArray}
        handelChangePeriod={handelChangePeriod}
        isYAxisInteger={true}
      />
    )
};

