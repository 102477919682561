import React from 'react'
import {CodesItem} from './codes-item/codes-item'
import {ICodesGroupItem} from 'app/api/dashboard-api/catalog-api'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {Loader} from '../../../../shared/components/loader/loader'
import {ChangeCodesValueArgType} from "../pages/single-product-page/single-roduct-types";
import { IRegion } from 'shared/types/base'

interface ICodesGroupsProps {
  codes: ICodesGroupItem[]
  handleCheckboxChange: (hash: string) => void
  isEdit: boolean
  handleChangeCodesValue: (codesArgs: ChangeCodesValueArgType) => void
  isLoading: boolean
  addToCart: (quantity: number, product_hash: string) => void
  isHidden?: boolean
  isUnlimited?: boolean
  face_price?: string
  productStock: boolean
  preOrderToCart: (quantity: number, product_hash: string) => void
  region: IRegion
}

const CodesGroupsComponent: React.FC<ICodesGroupsProps> = ({
  codes,
  handleCheckboxChange,
  isEdit,
  handleChangeCodesValue,
  isLoading,
  addToCart,
  isHidden,
  face_price,
  productStock,
  preOrderToCart,
  isUnlimited,
  region,
}) => {
  return (
    <div className='row mt-7'>
      <div className='col-12 position-relative'>
        {isLoading && <Loader mode='blur' />}
        {codes.length
          ? codes.map((code) => (
              <CodesItem
                preOrderToCart={preOrderToCart}
                productStock={productStock}
                key={code.hash}
                isUnlimited={isUnlimited}
                {...code}
                region={region}
                addToCart={addToCart}
                face_price={face_price}
                onCheckCode={handleCheckboxChange}
                isEdit={isEdit}
                isHidden={isHidden}
                handleChangeCodesValue={handleChangeCodesValue}
              />
            ))
          : null}
      </div>
    </div>
  )
}

export const CodesGroups = React.memo(CodesGroupsComponent)
