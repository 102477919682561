import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import styles from './ForgotPassword.module.scss'
import {CodeCheckFormInner} from '../../../../modules/auth/pages/forgot-password/code-check-inner'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {AUTH_ROUTES} from '../.../../../../../shared/config/routes'
import {useAuth} from "modules/auth/hooks/use-auth";
import {useIntl} from "react-intl";

const initialValues = {
    code: '',
}

export type NavigationStateDTO = {
    email: string;
    code: string;
}

export interface IFormikProps {
    code: string;
}

const formatEmail = (email: string) => {
    return email[0] + '***' + email.match(/@.*/)
}

const codeCheckSchema = Yup.object().shape({
    code: Yup.string()
        .min(6, 'Minimum 6 symbols')
        .max(6, 'Maximum 6 symbols')
        .required('Code is required'),
})

export const CodeCheck = () => {
    const {codeCheckMutation} = useAuth()
    const navigate = useNavigate()
    const location = useLocation();
    const intl = useIntl()
    if (!location.state) {
        return <Navigate to={AUTH_ROUTES.ROOT.path}/>
    }

    const locationState = (location.state as { email: string });
    const navigateToResetPasswordPage = ({email, code}: NavigationStateDTO) => {
        navigate(AUTH_ROUTES.RESET_PASSWORD.path, {state: {locationState, code}})
    }
    // eslint-disable-next-line
    const formik = useFormik({ // eslint-disable-next-line
        initialValues,
        validationSchema: codeCheckSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            codeCheckMutation.mutate(values.code)
        },
    })
    // eslint-disable-next-line
    useEffect(() => {
        if (codeCheckMutation.data) {
            navigateToResetPasswordPage({email: locationState.email, code: formik.values.code});
        }
    }, [codeCheckMutation.isSuccess])


    return (
        <form
            className='form mx-auto'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center'>
                <h1 className={clsx('fw-bolder mt-5', styles.header, styles.textPrimaryDark)}>
                    {intl.formatMessage({id: 'EXL.AUTH.CODE_CHECK.TITLE'})}</h1>
                <div className={clsx('fw-semibold fs-6 my-11', styles.textPrimaryDark)}>
                    {`${intl.formatMessage({id: 'EXL.AUTH.CODE_CHECK.DESCRIPTION_AFTER_EMAIL'})} 
                    ${formatEmail(locationState.email)} ${intl.formatMessage({id: 'EXL.AUTH.CODE_CHECK.DESCRIPTION_BEFORE_EMAIL'})}`}
                </div>
            </div>

            {formik.status || codeCheckMutation.error && (
                <div className='mb-lg-15 text-center m-auto w-sm-300px alert alert-danger'>
                    <div className=' font-weight-bold'>
                        {codeCheckMutation.error?.response?.data?.message}
                    </div>
                </div>
            )}

            <CodeCheckFormInner formik={formik} isLoading={codeCheckMutation.isLoading}/>
        </form>
    )
}
