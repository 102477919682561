import React from 'react';
import {FormikProps} from 'formik/dist/types'
import {useNavigate} from 'react-router-dom';
import {Input} from "../../../../shared/components/input/input";
import {IFormikProps} from "../../../../app/modules/auth/components/ForgotPassword";
import {Button} from "../../../../shared/components/button/button";
import {AUTH_ROUTES} from '../../../../shared/config/routes';
import {ButtonLink} from "../../../../shared/components/button/button-link";
import {useIntl} from "react-intl";

interface IForgotPasswordPage {
    formik: FormikProps<IFormikProps>;
    isLoading: boolean;
}

export const ForgotPasswordFormInner: React.FC<IForgotPasswordPage> = ({formik, isLoading}) => {
    const intl = useIntl()
    return (
        <>
            <div className='fv-row mb-6 w-sm-300px mx-auto'>
                <Input
                    placeholder='E-mail'
                    {...formik.getFieldProps('email')}
                    type='email'
                    name='email'
                    autoComplete='on'
                    error={formik.touched.email && !!formik.errors.email}
                    errorText={formik.errors.email}
                    disabled={isLoading}
                />
            </div>

            <div className='d-grid mb-3 w-sm-300px mx-auto'>
                <Button
                    label={intl.formatMessage({id: 'EXL.AUTH.FORGOT_PASS.BUTTON'})}
                    mainButton
                    type='submit'
                    disabled={!formik.isValid || isLoading}
                    isLoading={isLoading}
                />
            </div>

            <div className='d-grid mb-20 w-sm-300px mx-auto'>
                <ButtonLink to={AUTH_ROUTES.ROOT.path} mode='secondary'>
                    {intl.formatMessage({id: 'EXL.AUTH.BACK'})}
                </ButtonLink>
            </div>
        </>
    );
};

