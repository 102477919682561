import React from 'react'
import {useAbilityContext} from 'casl'
import {useMenuList} from 'shared/hooks/use-menu-list'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {useApiPage} from '../../hooks/use-api'
import {Loader} from 'shared/components/loader/loader'
import {CreateApiKeyModal} from '../../components/create-api-key'
import {ApiHeader} from '../../components/api/header/api-header'
import {ApiSearchBar} from '../../components/api/search-bar/api-search-bar'
import {ApiTable} from '../../components/api/table/api-table'

export const AccountApiPage = () => {
  const ability = useAbilityContext()
  const {accountLinks} = useMenuList(ability)
  const {models, commands} = useApiPage()

  return (
    <>
      <ApiHeader
        handleCreateKeyClick={commands.handleCreateKeyClick}
        handleApiDocumentation={commands.handleGoApiDocumentation}
      />
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={accountLinks} className='mb-5' />
        <ApiSearchBar
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
        />
        <div className='table-responsive position-relative'>
          {(models.isTableLoading || models.isActiveToggles) && (
            <Loader mode={'blur'} />
          )}
          <ApiTable
            keys={models.keys}
            dateFormatter={commands.dateFormatter}
            isExpiredToken={commands.isExpiredToken}
            toggleActiveStateOfKey={commands.toggleActiveStateOfKey}
          />
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta?.total}
            pageSize={models.meta?.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        </div>
        <CreateApiKeyModal />
      </div>
    </>
  )
}
