import React from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ActionsButton,
  IActionsButtons,
} from 'shared/components/button/actions-button'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Tooltip} from 'shared/components/tooltip/tooltip'
import {CODES_ROUTES, PURCHASES_ROUTES} from 'shared/config/routes'
import {PURCHASE_STATUS} from 'shared/constants/constants'
import {IPurchase} from 'shared/types/purchase'
import {CurrencyFormatter} from 'shared/helpers/currency-formatter'
import {DateFormatter} from 'shared/utils/date-formatter'

interface IPurchasesTableRow {
  purchase: IPurchase
  handleConfirmDelete: (purchaseId: number) => void
  handleDuplicateClick: (
    purchaseId: number
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  handleEditPurchase: (
    purchase: IPurchase
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const STATUS_COLOR = {
  0: 'warning',
  1: 'success',
  2: 'danger',
}

const PurchasesTableRowComponent: React.FC<IPurchasesTableRow> = ({
  purchase,
  handleConfirmDelete,
  handleDuplicateClick,
  handleEditPurchase,
}) => {
  const STATUS_NUMBER = purchase.quantity
    ? +(purchase.delivered === purchase.quantity)
    : 2
  const navigate = useNavigate()
  const handleDeleteClick =
    (purchaseId: number) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleConfirmDelete(purchaseId)
    }
  const handleViewClick = () => {
    navigate(PURCHASES_ROUTES.PURCHASE.generate(`${purchase.id}`))
  }
  const handleUploadClick = () => {
    navigate(
      CODES_ROUTES.CREATE_CODES.pathFromPurchases({
        purchase_id: `${purchase.id}`,
        product_id: null,
        supplier_id: `${purchase.supplier.id}`,
        currency_id: `${purchase.currency.id}`,
        purchase_price: null,
      })
    )
  }
  const PURCHASE_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'View',
      onClick: handleViewClick,
    },
    {
      label: 'Upload',
      onClick: handleUploadClick,
    },
    {
      label: 'Edit',
      onClick: handleEditPurchase(purchase),
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick(purchase.id),
    },
    {
      label: 'Duplicate',
      onClick: handleDuplicateClick(purchase.id),
    },
  ]
  return (
    <tr className='border-bottom'>
      <td>{purchase.id}</td>
      <td className='min-w-100px' style={{whiteSpace: 'pre'}}>
        {DateFormatter(purchase.created_at)}
      </td>
      <td className='text-break'>
        {purchase.invoice_number ? purchase.invoice_number : '-'}
      </td>
      <td>{purchase.supplier.name}</td>
      <td>{purchase.currency.iso}</td>
      <td>
        {purchase.net
          ? CurrencyFormatter(purchase.net, purchase.currency)
          : `-`}
      </td>
      <td>
        {purchase.vat
          ? CurrencyFormatter(purchase.vat, purchase.currency)
          : `-`}
      </td>
      <td>
        {purchase.fee
          ? CurrencyFormatter(purchase.fee, purchase.currency)
          : `-`}
      </td>
      <td>
        {purchase.total
          ? CurrencyFormatter(purchase.total, purchase.currency)
          : `-`}
      </td>
      <td>{`${purchase.delivered}/${purchase.quantity}`}</td>
      <td>
        <span className={`badge badge-light-${STATUS_COLOR[STATUS_NUMBER]}`}>
          {PURCHASE_STATUS[STATUS_NUMBER]}
        </span>
      </td>
      <td className='d-flex gap-5 border-0'>
        <Tooltip
          placement='bottom'
          content={`${
            purchase.comment ? purchase.comment : 'Comment is empty.'
          }`}
        >
          <Icon className='mx-3' icon={ICON_COLLECTION.questionMark} />
        </Tooltip>
        <ActionsButton label='Actions' buttons={PURCHASE_ACTION_BUTTONS} />
      </td>
    </tr>
  )
}

export const PurchasesTableRow = React.memo(PurchasesTableRowComponent)
