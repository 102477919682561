import React from 'react'
import {Button} from 'shared/components/button/button'
import {useBrandsPage} from './use-brands-options'
import {BrandsTable} from '../../components/tables/brands/brands-table'
import {DeleteBrandModal} from '../../components/modals/brands/delete-brand-modal'
import {CreateBrandModal} from '../../components/modals/brands/create-brand-modal'

export const BrandsOptions = () => {
  const {models, commands} = useBrandsPage()
  return (
    <>
      <div className='d-flex flex-end'>
        <Button
          label='Add new brand'
          mainButton
          onClick={commands.handleCreateClick}
        />
      </div>
      <div className='card position-relative'>
        <p>Recommended image size W: 209 H: 63 (10X3)</p>
        <p>
          If we want the image to look correctly, we need to observe the
          recommended aspect ratio.<br/> The image will be compressed and stretched
          to the full height of the section
        </p>
        <BrandsTable
          brands={models.brands}
          isLoading={models.isTableLoading}
          isSaveButtonLoading={models.isSaveButtonLoading}
          handleSaveChanges={commands.handleUpdateClick}
          handleDeleteClick={commands.handleDeleteClick}
        />
      </div>
      <DeleteBrandModal />
      <CreateBrandModal />
    </>
  )
}
