import { ChatButton } from "_metronic/partials/chat/components/ChatButton";

const SidebarFooter = () => {
    return (
        <div className='app-sidebar-footer flex-column-auto mb-10 px-6' id='kt_app_sidebar_footer'>
            <ChatButton/>
        </div>
    )
}

export {SidebarFooter}
