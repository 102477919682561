import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {CUSTOMER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useDebounce} from '_metronic/helpers'
import {
  CustomerApi,
  IApiKeys,
  IUpdateApiKeyParams,
} from 'app/api/user-api/customer-api'
import {IApiKey} from 'shared/types/user'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {useModalManager} from 'shared/context/modal-manager'
import {ACCOUNT_MODAL_NAMES} from 'shared/constants/modal-names'
import {DASHBOARD_ROUTES, DOCUMENTATION_ROUTES} from 'shared/config/routes'
import {useAccountContext} from 'shared/context/app-accout-context'
import {selectAuthUserData} from 'app/store/auth/selects'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'

export const useApiPage = () => {
  const user = selectAuthUserData()
  const {customer} = useAccountContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(
    search.get('name') || ''
  )
  const queryClient = useQueryClient()
  const modalManager = useModalManager()
  const navigate = useNavigate()
  const queryParams = useMemo(() => {
    return removeEmptyFields({
      name: search.get('name'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const keysQuery = useQuery({
    queryKey: [
      CUSTOMER_QUERY_KEYS.apiKeys,
      customer.user_id || user,
      queryParams,
    ],
    queryFn: () =>
      CustomerApi.getApiKeys(customer.user_id || user, queryParams),
    refetchOnWindowFocus: false,
  })

  const updateApiKeyMutation = useMutation<
    IApiKey,
    AxiosError<{error: string}>,
    IUpdateApiKeyParams
  >(CustomerApi.updateApiKey, {
    onSuccess: (data: IApiKey) => {
      queryClient.setQueryData(
        [CUSTOMER_QUERY_KEYS.apiKeys, customer.user_id || user, queryParams],
        (prevData: IApiKeys) => {
          const keys = prevData.data
          const keyId = prevData.data.findIndex(({id}) => id === data.id)
          keys.splice(keyId, 1, data)
          return {
            ...prevData,
            data: keys,
          }
        }
      )
    },
    onError: async (error) => {
      queryClient.invalidateQueries(
        [CUSTOMER_QUERY_KEYS.apiKeys, customer.user_id || user, queryParams],
        {exact: true}
      )
      toast.error(error.response.data.error)
    },
  })

  const isTableLoading = keysQuery.isLoading

  const isActiveToggles = updateApiKeyMutation.isLoading

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      name: queryParams.name ? {name: queryParams.name} : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('name', debouncedSearchTerm)
    setSearchParams({
      ...params.name,
    })
  }, [debouncedSearchTerm])

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.name,
      ...params.page,
    })
  }

  const dateFormatter = (date: Date | null): string => {
    const keyDate = new Date(date)
    return date
      ? keyDate.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      : '-'
  }

  const isExpiredToken = (expiresAt: Date | null): boolean => {
    const expiredDate = new Date(expiresAt).getTime()-5000
    return expiresAt ? new Date() < new Date(expiredDate) : true
  }

  const toggleActiveStateOfKey = async (apiKey: IApiKey) => {
    queryClient.setQueryData(
      [CUSTOMER_QUERY_KEYS.apiKeys, customer.user_id || user, queryParams],
      (prevData: IApiKeys) => {
        const keys = prevData.data
        const keyId = keys.findIndex(({id}) => id === apiKey.id)
        keys.splice(keyId, 1, {
          ...keys[keyId],
          expires_at: keys[keyId].expires_at ? null : new Date(),
        })
        return {
          ...prevData,
          data: keys,
        }
      }
    )
    const payload = {
      userId: customer.user_id,
      apiId: apiKey.id,
      enable: !isExpiredToken(apiKey.expires_at),
    }
    updateApiKeyMutation.mutate(payload)
  }

  const handleCreateKeyClick = () => {
    modalManager.open(ACCOUNT_MODAL_NAMES.create_api_key)
  }

  const handleGoBackClick = () => {
    navigate(DASHBOARD_ROUTES.CUSTOMERS.path)
  }

  const handleGoApiDocumentation = () => {
    navigate(DOCUMENTATION_ROUTES.ROOT.path)
  }

  return {
    models: {
      customer,
      values: queryParams,
      keys: keysQuery.data ? keysQuery.data.data : [],
      meta: keysQuery.data ? keysQuery.data.meta : null,
      isTableLoading,
      isActiveToggles,
      searchValue,
    },
    commands: {
      setCurrentPage,
      setSearchValue,
      dateFormatter,
      isExpiredToken,
      toggleActiveStateOfKey,
      handleCreateKeyClick,
      handleGoBackClick,
      handleGoApiDocumentation,
    },
  }
}
