import React from 'react'
import {useFormik} from 'formik'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {Input} from 'shared/components/input/input'
import {
  IOneExternalProduct,
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {useMutation, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {Loader} from '../../../../../shared/components/loader/loader'

const INITIAL_VALUES = {
  product_name: null,
}

interface IFormValue {
  product_name?: string
}

interface IErrorForm {
  product_name?: string
}

export const AddProductModal = () => {
  const queryClient = useQueryClient()
  const modalState = useStateModalManager(SUPPLIERS_MODAL_NAME.create_product)

  const onSubmit = (values: IFormValue) => {
    createProductMutation.mutate(values.product_name)
  }

  const validate = (values: IFormValue) => {
    const error: IErrorForm = {}
    if (!values.product_name) {
      error.product_name = 'Name is required field !'
    }
    return error
  }

  const formik = useFormik<IFormValue>({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validate,
  })
  const createProductMutation = useMutation<
    IOneExternalProduct,
    AxiosError<{error: string}>,
    string
  >(SuppliersApi.createExternalProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker,
      ])
      toast.success(`Product was created successfully!`)
      handleClose()
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const handleClose = () => {
    modalState.close()
    formik.resetForm()
  }

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!createProductMutation.isLoading}
    >
      {createProductMutation.isLoading && <Loader mode='blur' />}
      <form className='p-7' onSubmit={formik.handleSubmit}>
        <h2 className='text-center text-exl-primary'>
          Create external product
        </h2>
        <div className='d-flex flex-column gap-3 my-5'>
          <Input
            type='text'
            placeholder='Name'
            error={formik.touched.product_name && !!formik.errors.product_name}
            errorText={formik.errors.product_name}
            {...formik.getFieldProps('product_name')}
          />
        </div>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            disabled={createProductMutation.isLoading}
            onClick={handleClose}
            type='button'
          />
          <Button
            label='Create'
            type='submit'
            isLoading={createProductMutation.isLoading}
            disabled={createProductMutation.isLoading}
            mainButton
          />
        </ButtonList>
      </form>
    </Modal.Root>
  )
}
