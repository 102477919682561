import React, {useEffect, useState} from 'react';
import {useStateModalManager} from "shared/context/modal-manager";
import {WALLETS_MODAL_NAMES} from "shared/constants/modal-names";
import {Modal} from 'shared/components/modal-base';
import {Input} from "shared/components/input/input";
import {ButtonList} from "shared/components/button-list/button-list";
import {Button} from "shared/components/button/button";
import {Loader} from "shared/components/loader/loader";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {WalletsApi} from "app/api/dashboard-api/wallets-api";
import {
    IAdjustWalletParams,
    IWallet,
    IWalletDefaultPrams
} from "shared/types/api-types/wallets-type";
import {useFormik} from "formik";
import {selectUserData} from "app/store/user/selects";
import {useWalletsState} from "app/store/wallets/state";
import {Toggle} from "../../../../../shared/components/toggle/toggle";
import {EventBus, GLOBAL_EVENTS} from "../../../../../shared/utils/event-bus";
import { decimalFormatter } from 'shared/helpers/decimalFormatter';

interface IWalletFormValue {
    amount?: number
}

const initialValues: IWalletFormValue = {
    amount: null,
}

interface IErrorForm {
    amount?: string
}

export const CreditBalanceModal = () => {
    const user = selectUserData()
    const {onUpdateWallet} = useWalletsState()
    const [beforeData, setBeforeData] = useState<IWalletDefaultPrams>(null);
    const [isOpenCreditBalance, setOpenCreditBalance] = useState(false);
    const modalState = useStateModalManager(WALLETS_MODAL_NAMES.credit_balance, {
        onBeforeOpen: ({user, wallet, credit_balance, currency}: IWalletDefaultPrams) => {
            setBeforeData({user, wallet, currency})
            if (credit_balance) {
                setOpenCreditBalance((credit_balance > 0 )? true : false)
                formik.setFieldValue('amount', credit_balance)
            }
        }
    });

    const editBalanceMutation = useMutation<
        IWallet,
        AxiosError<{ message: string }>,
        IAdjustWalletParams
    >(WalletsApi.editCreditBalance,
        {
            onSuccess: data => {
                if (user.id === Number(beforeData.user)) {
                    onUpdateWallet(data)
                } else {
                    EventBus.emit(GLOBAL_EVENTS.reset_invoice)
                }
                formik.resetForm()
                modalState.close()
            },
            onError: error => {
                toast.error(error.response.data.message)
            }
        })
    const handleEditBalance = (values: IWalletFormValue) => {
        if (beforeData) {
            editBalanceMutation.mutate({
                params: beforeData,
                amount: decimalFormatter(values.amount)
            })
        }
    }
    const validate = (values: IWalletFormValue) => {
        const error: IErrorForm = {}
        if (values.amount === null || values.amount < 0) {
            error.amount = 'Amount is required field !'
        }
        return error
    }

    const formik = useFormik<IWalletFormValue>(
        {
            initialValues,
            onSubmit: handleEditBalance,
            validate
        }
    )
    const onSubmitForm = () => formik.submitForm()
    const handleClose = () => {
        setOpenCreditBalance(false)
        setBeforeData(null)
        formik.resetForm()
        modalState.close()
    }
    const toggleBalance = () => {
        setOpenCreditBalance(prevState => {
            if (prevState) {
                formik.setFieldValue('amount', 0)
            }
            return !prevState
        })
    }

    useEffect(() => {
        if (decimalFormatter(formik.values.amount) > 0) {
            setOpenCreditBalance(true)
        }
    }, [formik.values.amount])
    return (
      <Modal.Root
        open={modalState.open}
        onClose={handleClose}
        isClosable={!editBalanceMutation.isLoading}
      >
        <div className='p-7 position-relative'>
          {editBalanceMutation.isLoading && <Loader mode='blur' />}
          <h2 className='mb-5 text-center'>Adjust Credit Limit</h2>
          <div className='d-flex align-items-center mb-5'>
            <p className='mb-0 me-3'>Open credit balance:</p>
            <Toggle isActive={isOpenCreditBalance} setActive={toggleBalance} />
          </div>
          <div className='mb-7'>
            <p>Credit balance</p>
            <Input
              type={'number'}
              className='w-sm-225px'
              value={formik.values.amount}
              name='amount'
              disabled={!isOpenCreditBalance}
              currency={beforeData?.currency}
              error={formik.touched.amount && !!formik.errors.amount}
              errorText={formik.touched.amount && formik.errors.amount}
              {...formik.getFieldProps('amount')}
              isPriceInput
            />
          </div>
          <ButtonList className='justify-content-end'>
            <Button
              label='Cancel'
              onClick={handleClose}
              disabled={editBalanceMutation.isLoading}
              isLoading={editBalanceMutation.isLoading}
            />
            <Button
              mainButton
              label='Apply Changes'
              disabled={editBalanceMutation.isLoading}
              isLoading={editBalanceMutation.isLoading}
              onClick={onSubmitForm}
            />
          </ButtonList>
        </div>
      </Modal.Root>
    )
};

