import {
  ICreateSupplierDTO,
  ISingleSupplier,
  IUpdateSupplierDTO,
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {FormikProps, useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {SUPPLIER_ROUTES} from 'shared/config/routes'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {validationSchema} from '../../components/schemes/create-supplier-schema'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {useModalManager} from 'shared/context/modal-manager'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import { SelectsApi } from 'app/api/settings-api/selects-api'
import { useSelectController } from 'shared/hooks/use-select-controller'
import { IOption } from 'shared/components/select/select'
import { SingleValue } from 'react-select'
import { ICountry } from 'shared/types/country'

const initialValues = {
  name: null,
  main_email: null,
  country_id: null,
  company_director: null,
  company_address: null,
  city: null,
  zip_post_code: null,
  website: null,
  VAT_number: null,
  company_registration_number: null,
  account_payable_contact: null,
  account_payable_email: null,
  phone_number: null,
}

export const useSingleSupplierPage = () => {
  const [isEditing, setIsEditing] = useState(false)
  const modalManager = useModalManager()
  const navigate = useNavigate()
  const {supplierId} = useParams()
  const queryClient = useQueryClient()

  const singleSupplierQuery = useQuery({
    queryKey: [SUPPLIERS_TRACKER_QUERY_KEYS.single_supplier, supplierId],
    queryFn: async () => await SuppliersApi.getSingleSupplier(supplierId),
    refetchOnWindowFocus: false,
  })

  const handleGoBackClick = () => {
    navigate(SUPPLIER_ROUTES.SUPPLIERS.path)
  }

  const updateSupplierMutation = useMutation<
    ISingleSupplier,
    AxiosError<{message: string}>,
    IUpdateSupplierDTO
  >(SuppliersApi.updateSingleSupplier, {
    onSuccess: () => {
      setIsEditing(false)
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.single_supplier,
      ])
      toast.success('Update supplier successfully')
    },
    onError: () => {
      toast.error('Error update supplier')
    },
  })

  const toggleEditMode = () => {
    setIsEditing((prevValue) => !prevValue)
  }

  const formik: FormikProps<ICreateSupplierDTO> = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      updateSupplierMutation.mutate({
        data: values,
        supplierId: singleSupplierQuery.data.data.id.toString(),
      })
    },
  })

  const {
    isLoading: isLoadingRegions,
    handleInputChange: handleChangeRegions,
    formationOptions: optionRegions,
  } = useSelectController({
    key: SUPPLIERS_TRACKER_QUERY_KEYS.regions,
    Fn: SelectsApi.getRegions,
    params: 'name',
  })

  const handleChangeFormValue = (name: string, value: IOption) => {
    formik.setFieldValue(name, value.id)
  }

  const handlePhoneInput = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  const transformFieldName = (fieldName) => {
    const noUnderscores = fieldName.replace(/_/g, ' ')
    return noUnderscores
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  }

  const handleSubmitForm = () => {
    formik.submitForm()
  }
  const handleCancelForm = () => {
    formik.resetForm()
    const updatedData = {...singleSupplierQuery.data.data}
    for (const key in updatedData) {
      if (updatedData[key] === null) {
        updatedData[key] = ''
      }
    }
    formik.setValues(updatedData)
    toggleEditMode()
  }

  const handleDeleteSupplier = (supplierId) => {
    modalManager
      .open(SUPPLIERS_MODAL_NAME.delete_supplier, supplierId)
      .then(() => {
        navigate(SUPPLIER_ROUTES.SUPPLIERS.path)
      })
      .catch((error) => {
        return
      })
  }

  const handleCountrySelect = (newValue: SingleValue<ICountry>) => {
    formik.setFieldValue('country_id', newValue?.id)
  }

  useEffect(() => {
    if (singleSupplierQuery.data) {
      formik.setValues(singleSupplierQuery.data.data)
    }
  }, [singleSupplierQuery.data])

  const isTableLoading = singleSupplierQuery.isLoading
  const isUpdateLoading = updateSupplierMutation.isLoading
  return {
    formik,
    models: {
      isUpdateLoading,
      isEditing,
      supplierId: singleSupplierQuery.data
        ? singleSupplierQuery.data.data.id
        : null,
      supplierName: singleSupplierQuery.data
        ? singleSupplierQuery.data.data.name
        : 'No Name',
      supplier: singleSupplierQuery.data ? singleSupplierQuery.data.data : [],
      meta: singleSupplierQuery.data ? singleSupplierQuery.data.meta : null,
      isTableLoading,
      isLoadingRegions,
      optionRegions,
    },
    commands: {
      handleGoBackClick,
      toggleEditMode,
      handleSubmitForm,
      transformFieldName,
      handleDeleteSupplier,
      handleChangeRegions,
      handleChangeFormValue,
      handlePhoneInput,
      handleCancelForm,
      handleCountrySelect,
    },
  }
}
