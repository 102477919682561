import React from 'react';
import LandingLayout from 'app/layouts/landing-layout';
import LandingHero from './components/landing-hero/landing-hero';
import LandingOffer from './components/landing-offer/landing-offer';
import LandingBenefit from './components/landing-benefit/landing-benefit';
import LandingPortal from './components/landing-portal/landing-portal';
import LandingDiscover from './components/landing-discover/landing-discover';
import LandingApi from './components/landing-api/landing-api';
import LandingBrands from './components/landing-brands/landing-brands';
import LandingContact from './components/landing-contact/landing-contact';

export const LandingPage: React.FC = () => {
    return (
        <LandingLayout>
            <LandingHero />
            <LandingOffer />
            <LandingBenefit />
            <LandingPortal />
            <LandingDiscover />
            <LandingApi />
            <LandingBrands />
            <LandingContact />
        </LandingLayout>
    );
};

