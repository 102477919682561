import React, {useMemo, useState} from 'react';
import {CATALOG_MODAL_NAMES, DASHBOARD_MODAL_NAMES} from "shared/constants/modal-names";
import {useStateModalManager} from 'shared/context/modal-manager';
import {Modal,} from 'shared/components/modal-base';
import {Button} from "shared/components/button/button";
import {Input} from "shared/components/input/input";
import {ButtonList} from "shared/components/button-list/button-list";
import {useMutation, useQueryClient} from "react-query";
import {AxiosError} from "axios";
import {CategoryApi} from "app/api/dashboard-api/category-api";
import {toast} from "react-toastify";
import {CATEGORIES_QUERY_KEYS} from "shared/constants/query-keys";
import {useLocation} from "react-router-dom";
import {removeEmptyFields} from "shared/utils/remove-empty-fields";
import {EditImage} from "../edit-image/edit-image";
import {ICreateCategoryDTO, ICreateCategoryResponse, IUpdateCategory} from "shared/types/api-types/caterogy-api-types";
import {useFormik} from "formik";
import {Loader} from "shared/components/loader/loader";
import {ICategoryUpdateProps} from "../../pages/catalog-page/use-catalog-page";

interface ICategoryFormValue extends Omit<ICreateCategoryDTO, 'parent_category_id'> {
    url?: string
}

const initialValues: ICategoryFormValue = {
    image: null,
    name: null,
    url: null
}

interface IErrorForm {
    name?: string
}

export const UpdateCategory = () => {
    const [idCategory, setIdCategory] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const queryClient = useQueryClient()
    const modalState = useStateModalManager(CATALOG_MODAL_NAMES.update_category, {
        onBeforeOpen: (data: ICategoryUpdateProps) => {
            formik.setFieldValue('url', data.url || null)
            formik.setFieldValue('image', data.url || null)
            formik.setFieldValue('name', data.name)
            setMainImage(data.url || null)
            setIdCategory(data.id)
        }
    });
    const handleClose = () => modalState.close()
    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const validate = (values: ICategoryFormValue) => {
        const error: IErrorForm = {}
        if (!values.name || values.name === '') {
            error.name = 'Category name is required field !'
        }
        return error
    }
    const queryParams = useMemo(() => {
        return removeEmptyFields({
            name: search.get('name'),
        })
    }, [location.search])

    const updateCategoryMutation = useMutation<
        ICreateCategoryResponse,
        AxiosError<{ message: string }>,
        IUpdateCategory
    >(CategoryApi.updateCategory,
        {
            onSuccess: data => {
                queryClient.invalidateQueries([CATEGORIES_QUERY_KEYS.category_page, queryParams], {exact: true});
                toast.success('Success :)')
                formik.resetForm()
                modalState.close()

            },
            onError: error => {
                toast.error(error.message)
            }
        })
    const handelUpdate = (values: ICategoryFormValue) => {
        const tempImage = values.image && values.url ? values.image : null

        const payload = removeEmptyFields({...values, url: null,
            image: tempImage ? values.image : 'delete' })
        updateCategoryMutation.mutate({idCategory, payload})
    }
    const formik = useFormik<ICategoryFormValue>(
        {
            initialValues,
            onSubmit: handelUpdate,
            validate
        }
    )
    const handelChangeCategoryName = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('name', event.target.value)
    }
    const handelChangeImage = (value: File | null) => {
        if (value) {
            formik.setFieldValue('image', value)
        }
    }
    const handelChangeUrl = (value: string) => {
        formik.setFieldValue('url', value)
    }

    const onRemoveImage = () => {
        formik.setFieldValue('image', null)
        formik.setFieldValue('url', null)
    }
    return (
        <Modal.Root
            open={modalState.open}
            onClose={handleClose}
            isClosable={!updateCategoryMutation.isLoading}
        >
            <div className="p-7 position-relative">
                {updateCategoryMutation.isLoading && <Loader mode="blur"/>}
                <h2 className='mb-5'>Edit Category</h2>
                <EditImage className='mb-5' onRemove={onRemoveImage}
                           url={formik.values.url}
                           onUpdateFile={handelChangeImage}
                           setImage={handelChangeUrl}/>

                <h5 className='mb-3'>Category name</h5>
                <Input
                    error={formik.touched.name ? !!formik.errors.name : false}
                    className='mb-5'
                    placeholder='Type here'
                    value={formik.values.name}
                    onChange={handelChangeCategoryName}/>
                <ButtonList className='justify-content-end'>
                    <Button
                        label="Cancel"
                        onClick={handleClose}
                        disabled={updateCategoryMutation.isLoading}
                    />
                    <Button
                        onClick={() => formik.submitForm()}
                        mainButton
                        label="Apply Changes"
                        disabled={updateCategoryMutation.isLoading}
                        isLoading={updateCategoryMutation.isLoading}

                    />
                </ButtonList>
            </div>

        </Modal.Root>
    );
};

