import LandingFooter from 'modules/landing/components/landing-footer/landing-footer'
import LandingHeader from 'modules/landing/components/landing-header/landing-header'
import React from 'react'

interface LandingLayoutProps {}

const LandingLayout: React.FC<React.PropsWithChildren<LandingLayoutProps>> = ({children}) => {
  return (
    <div className='wrapper' style={{position: "relative"}}>
      <LandingHeader />
      {children}
      <LandingFooter />
    </div>
  )
}

export default LandingLayout
