import React from 'react';
import clsx from 'clsx';
import { LoadWrapper } from 'shared/components/load-wrapper/load-wrapper';
import { PurchasesTableRow } from './purchase-tracker-table-row';
import { IPurchase } from 'shared/types/purchase';

export interface IPurchasesTable {
  purchases: IPurchase[]
  isLoading: boolean
  handleConfirmDelete: (purchaseId:number) => void
  handleDuplicateClick: (purchaseId:number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  handleEditPurchase: (purchase: IPurchase) => (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const PurchasesTable: React.FC<IPurchasesTable> = ({
  purchases,
  isLoading,
  handleConfirmDelete,
  handleDuplicateClick,
  handleEditPurchase
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
              <th>ID</th>
              <th>Date</th>
              <th>Invoice</th>
              <th>Supplier</th>
              <th>Currency</th>
              <th>Net</th>
              <th>Vat</th>
              <th>Fee</th>
              <th>Amount</th>
              <th>Quantity</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          {purchases && purchases.length ? (
            <tbody>
              {purchases.map((purchase: IPurchase) => (
                <PurchasesTableRow
                  key={purchase.id}
                  purchase={purchase}
                  handleDuplicateClick={handleDuplicateClick}
                  handleConfirmDelete={handleConfirmDelete}
                  handleEditPurchase={handleEditPurchase}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={12}>No purchases found with this filters.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}


