import React, {useEffect, useState} from 'react'
import {Select} from 'shared/components/select/select'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {CUSTOMER_QUERY_KEYS} from 'shared/constants/query-keys'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {IOption} from 'shared/components/select/select-type'
import {useTemporaryUserState} from '../../../../app/store/temporary-customer/state'
import {
  selectTemporaryCompanyName,
  selectTemporaryDefaultWallet,
  selectTemporaryIsLoading,
  selectTemporaryIsLoadingWallet,
  selectTemporaryUserId,
  selectTemporaryWalletsData,
} from '../../../../app/store/temporary-customer/selects'
import {WalletWidgetBody} from '../../wallet/components/wallet-widget/wallet-widget-body'
import {Loader} from '../../../../shared/components/loader/loader'
import {useCartState} from '../../../../app/store/cart/state'
import {selectUserData} from '../../../../app/store/user/selects'
import { WalletsApi } from 'app/api/dashboard-api/wallets-api'
import { IWallet, IWalletDefaultPrams } from 'shared/types/api-types/wallets-type'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

export const TemporaryUserSelect = () => {
  const [customerValue, setCustomerValue] = useState(null)
  const {initTemporaryUser, resetTemporaryUser, setDefaultWallet} = useTemporaryUserState()
  const wallets = selectTemporaryWalletsData()
  const defaultWallet = selectTemporaryDefaultWallet()
  const isLoadingStore = selectTemporaryIsLoading()
  const isLoadingWallet = selectTemporaryIsLoadingWallet()
  const {getCart} = useCartState()
  const temporaryUserId = selectTemporaryUserId()
  const temporaryCompanyName = selectTemporaryCompanyName()
  const user = selectUserData()
  const {
    isLoading: isLoadingCustomers,
    handleInputChange: handleInputChangeCustomers,
    formationOptions: customers,
  } = useSelectController({
    key: CUSTOMER_QUERY_KEYS.customers,
    Fn: SelectsApi.getUsers,
    params: 'company_name',
    format: 'usersOptions',
  })
  const onChangeCustomer = (option: IOption) => setCustomerValue(option)

  const setDefaultWalletMutation = useMutation<
    IWallet,
    AxiosError<{message: string}>,
    IWalletDefaultPrams
  >(WalletsApi.setDefaultWallet, {
    onSuccess: (data) => {
      setDefaultWallet(data)
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })
  const handelSetDefault = (walletId: number) => {
    setDefaultWalletMutation.mutate({user: customerValue.id, wallet: walletId})
  }

  useEffect(() => {
    ;(async () => {
      if (temporaryUserId && temporaryCompanyName) {
        handleInputChangeCustomers(temporaryCompanyName)
        setCustomerValue({label: temporaryCompanyName, id: temporaryUserId})
      }
    })()
  }, [temporaryCompanyName])

  useEffect(() => {
    ;(async () => {
      try {
        if (customerValue) {
          initTemporaryUser(customerValue.id)
        } else {
          resetTemporaryUser()
          handleInputChangeCustomers('')
          await getCart(user.id)
        }
      } catch (e) {
        console.error(e)
      }
    })()
  }, [customerValue])
  return (
    <div className='d-flex justify-content-center align-items-center position-relative'>
      {isLoadingStore && <Loader mode='blur' />}
      <WalletWidgetBody
        className={'mt-0 me-3'}
        wallets={wallets}
        defaultWallet={defaultWallet}
        isLoading={isLoadingWallet || setDefaultWalletMutation.isLoading}
        handelSetDefault={handelSetDefault}
      />
      <Select
        options={customers}
        isClearable
        isLoading={isLoadingCustomers}
        handleInputChange={handleInputChangeCustomers}
        value={customerValue ? customerValue.id : null}
        onChange={onChangeCustomer}
        placeholder='Customers'
        className='w-sm-200px w-md-300px me-3 text-start'
      />
    </div>
  )
}
