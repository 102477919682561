import * as Yup from 'yup'
export const accountSettingsSchema = Yup.object().shape({
    email: Yup.string()
        .email('Wrong email format')
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email field must be filled'),
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .test('password','Password field must be filled!',(value,context) =>{
            if (context.from[0].value.password_confirmation) return !!value && context.from[0].value.password_confirmation
            return true
        }),
    password_confirmation: Yup.string()
        .when('password',(password,field) =>{
            if (password[0]) return field.required('Confirm Password must be filled').oneOf([Yup.ref('password')], "Password and Confirm Password didn't match") 
            return field
        }),
})
