import React, {useMemo, useState} from 'react'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {useFormik} from 'formik'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {validationSchema} from '../schemes/suppliers-add-supplier-schema'
import {IOption, Select} from 'shared/components/select/select'
import {Input} from 'shared/components/input/input'
import {
  IAddSupplierDTO,
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {SelectCreatable} from 'shared/components/select/creatable-select'
import {ISupplier} from 'shared/types/supplier'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {useLocation} from 'react-router-dom'
import {useMutation, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {Loader} from '../../../../../shared/components/loader/loader'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

const INITIAL_VALUES = {
  product_id: null,
  currency_id: null,
  price: null,
  supplier_id: null,
}

export const AddSupplierModal = () => {
  const [productName, setProductName] = useState(null)
  const queryClient = useQueryClient()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const modalState = useStateModalManager(SUPPLIERS_MODAL_NAME.add_supplier, {
    onBeforeOpen: (product_id: number, product_name) => {
      setProductName(product_name)
      formik.setFieldValue('product_id', product_id)
      formik.setErrors({})
    },
  })

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      name: search.get('name'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])
  const onSubmit = (values: IAddSupplierDTO) => {
    updateSupplierMutation.mutate({...values, price: decimalFormatter(values.price)})
  }
  const formik = useFormik<IAddSupplierDTO>({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validationSchema,
  })
  const updateSupplierMutation = useMutation<
    ISupplier,
    AxiosError<{error: string}>,
    IAddSupplierDTO
  >(SuppliersApi.updateSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        [SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker, queryParams],
        {exact: true}
      )
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers,
      ])
      toast.success(`Supplier was added successfully!`)
      handleClose()
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const handleClose = () => {
    modalState.close()
    formik.resetForm()
  }

  const {
    isLoading: isLoadingSuppliers,
    formationOptions: optionsSuppliersMemo,
  } = useSelectController({
    key: SUPPLIERS_TRACKER_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    params: 'name',
  })

  const supplierValue = useMemo(() => {
    if (!optionsSuppliersMemo || !optionsSuppliersMemo.length) return null
    return (
      optionsSuppliersMemo.find(
        (supplier) => supplier.id === formik.values.supplier_id
      ) || null
    )
  }, [formik.values.supplier_id])

  const {
    options: currenciesOptions,
    isLoading: isLoadingCurrencies,
    formationOptions: optionsCurrenciesMemo,
  } = useSelectController({
    key: SUPPLIERS_TRACKER_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
  })

  const curCurrency = currenciesOptions.find((cur) => formik.values.currency_id === cur.id)
  const currencySymbol = curCurrency?.symbol

  const handleChangeSelects = (name: string) => (value: IOption | null) => {
    formik.setFieldValue(name, value ? value.id : null)
  }

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!updateSupplierMutation.isLoading}
    >
      {updateSupplierMutation.isLoading && <Loader mode='blur' />}
      <form className='p-7' onSubmit={formik.handleSubmit}>
        <h2 className='text-center text-exl-primary'>
          Add Supplier for {productName}
        </h2>
        <div className='d-flex flex-column gap-3 my-5'>
          <SelectCreatable
            isClearable={false}
            options={optionsSuppliersMemo}
            isLoading={isLoadingSuppliers}
            value={supplierValue}
            error={formik.touched.supplier_id && !!formik.errors.supplier_id}
            errorText={
              formik.touched.supplier_id && formik.errors.supplier_id
                ? formik.errors.supplier_id
                : null
            }
            onChange={handleChangeSelects('supplier_id')}
            placeholder='Supplier'
          />
          <Input
            type='number'
            {...formik.getFieldProps('price')}
            error={formik.touched.price && !!formik.errors.price}
            errorText={formik.errors.price}
            currency={currencySymbol}
            isPriceInput
          />
          <Select
            options={optionsCurrenciesMemo}
            isLoading={isLoadingCurrencies}
            {...formik.getFieldProps('currency_id')}
            error={formik.touched.currency_id && !!formik.errors.currency_id}
            errorText={
              formik.touched.currency_id && formik.errors.currency_id
                ? formik.errors.currency_id
                : null
            }
            onChange={handleChangeSelects('currency_id')}
            placeholder='Currency'
          />
        </div>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            disabled={updateSupplierMutation.isLoading}
            onClick={handleClose}
            type='button'
          />
          <Button
            label='Add'
            type='submit'
            isLoading={updateSupplierMutation.isLoading}
            disabled={updateSupplierMutation.isLoading}
            mainButton
          />
        </ButtonList>
      </form>
    </Modal.Root>
  )
}
