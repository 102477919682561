import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import styles from './ForgotPassword.module.scss'
import {useAuth} from 'modules/auth/hooks/use-auth'
import {useIntl} from 'react-intl'
import { TwoFactorAuthInner } from 'modules/auth/pages/two-factor-auth/two-factor-auth-inner'
import { useLocation, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuthState } from 'app/store/auth/state'
import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { AuthApi, I2Fa } from 'app/api/auth-api/auth-api'

const initialValues = {
  one_time_password: '',
}

export type NavigationState = {
  token: string
  userId: number
}

export interface ITwoFactorAuthFormikProps {
  one_time_password: string
}

const twoFactorAuthSchema = Yup.object().shape({
  one_time_password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Code is required'),
})

export function TwoFactorAuth() {
  const location = useLocation()
  const receivedData: NavigationState = location.state as NavigationState
  const {setUser} = useAuthState()
  const intl = useIntl()

  const twoFactorAuthMutation = useMutation<
    string,
    AxiosError<{message: string}>,
    I2Fa
  >(AuthApi.twoFactorAuth, {
    onSuccess: () => {
      setUser(receivedData.token, receivedData.userId)
    },
  })

  const formik = useFormik({
    initialValues,
    validationSchema: twoFactorAuthSchema,
    onSubmit: (values) => {
      twoFactorAuthMutation.mutate({data: values.one_time_password, token: receivedData.token})
    },
  })

  return (
    <form
      className='form mx-auto '
      noValidate
      id='kt_login_two_factor_auth'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center'>
        <h1
          className={clsx(
            'fw-bolder mt-5 mb-6',
            styles.header,
            styles.textPrimaryDark
          )}
        >
          {intl.formatMessage({id: 'EXL.AUTH.LOGIN.TITLE'})}
        </h1>
        <div className={clsx('fw-semibold fs-6 my-11', styles.textPrimaryDark)}>
          Open your Google Authenticator app and get one time code
        </div>
      </div>

      {formik.status ||
        (twoFactorAuthMutation.error && (
          <div className='mb-lg-15 text-center m-auto w-sm-300px alert alert-danger'>
            <div className='font-weight-bold'>
              {twoFactorAuthMutation.error?.response?.data?.message}
            </div>
          </div>
        ))}

      <TwoFactorAuthInner
        formik={formik}
        isLoading={twoFactorAuthMutation.isLoading}
      />
    </form>
  )
}
