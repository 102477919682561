import React from 'react'
import {RedocStandalone} from 'redoc'
import {ACCOUNT_ROUTES, ERROR_ROUTE, LANDING_ROUTES} from 'shared/config/routes'
import {Button} from 'shared/components/button/button'
import {useNavigate} from 'react-router-dom'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {DocumentationApi} from 'app/api/documentation-api/documentation-api'
import {useQuery} from 'react-query'
import {DOCUMENTATION_QUERY_KEYS} from 'shared/constants/query-keys'
import {Loader} from 'shared/components/loader/loader'

export const DocumentationPage = () => {
  const navigate = useNavigate()
  const navigateToApi = () => {
    navigate(ACCOUNT_ROUTES.API.path)
  }
  const navigateToHome = () => {
    navigate(`${LANDING_ROUTES.ROOT.path}#contact`)
  }

  const singleSupplierQuery = useQuery({
    queryKey: [DOCUMENTATION_QUERY_KEYS.documentation],
    queryFn: async () => await DocumentationApi.getDocumentationJson(),
    refetchOnWindowFocus: false,
    onError: () => {
      navigate(ERROR_ROUTE.path)
    },
  })

  if (singleSupplierQuery.isLoading) {
    return <Loader />
  }

  return (
    <div>
      <div className='d-flex gap-6 align-items-center p-5 border-bottom border-3 border-secondary'>
        <Button onClick={navigateToApi} label='<' className='min-w-25px' />
        <div onClick={navigateToHome} className='d-flex align-items-center'>
          <Icon icon={ICON_COLLECTION.logoDark} className='cursor-pointer' />
        </div>
      </div>
      <RedocStandalone
        spec={
          singleSupplierQuery.data &&
          JSON.parse(JSON.stringify(singleSupplierQuery.data))
        }
        options={{
          hideLoading: true,
          nativeScrollbars: false,
          theme: {
            colors: {primary: {main: 'green'}},
            typography: {
              fontSize: '15px',
              code: {
                fontSize: '15px',
              },
            },
          },
        }}
      />
    </div>
  )
}
