import React from 'react'
import {ButtonLink} from '../../../../../shared/components/button/button-link'
import {CODES_ROUTES} from '../../../../../shared/config/routes'
import {TabMenu} from '../../../../../shared/components/tab-menu/tab-menu'
import {useUploadHistory} from './use-upload-history'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import { UploadHistoryTable } from '../../components/table/upload-history-table/upload-history-table'
import { UploadHistorySearchBar } from '../../components/upload-history-search-bar'
import { ButtonGroup } from 'react-bootstrap'
import { Button } from 'shared/components/button/button'
import { CODES_NAV_ROUTES } from '../../codes-routes'
import { ConfirmDeleteUploadHistoryModal } from '../../components/modals/confirm-delete-upload-history-modal'

export const CodesUploadHistoryPage = () => {
  const {models, commands} = useUploadHistory()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold m-0'>Codes</h1>
        <ButtonGroup>
          {models.uploadHistoryChecked.length ? (
              <Button
                label={`Delete (${models.uploadHistoryChecked.length})`}
                disabled={models.isTableLoading || models.isCodeDeleting}
                isLoading={models.isTableLoading||models.isCodeDeleting}
                onClick={commands.onDeleteUploadHistory}
              />
          ) : null}
          <ButtonLink to={CODES_ROUTES.CREATE_CODES.path} mode='purple'>
            Upload Codes
          </ButtonLink>
        </ButtonGroup>
      </div>
      <div className='card p-7'>
        <TabMenu routes={CODES_NAV_ROUTES} className='mb-5' />
        <div className='mb-5'>
          <UploadHistorySearchBar
            productsOptions={models.productsOptions}
            suppliersOptions={models.suppliersOptions}
            isLoadingSupplier={models.isLoadingSuppliers}
            isLoadingProduct={models.isLoadingProduct}
            productValue={models.values.product}
            supplierValue={models.values.supplier}
            handleChangeFormValue={commands.handleChangeFormValue}
            handleInputChangeProducts={commands.handleInputChangeProducts}
            handleInputChangeSuppliers={commands.handleInputChangeSuppliers}
            handleDateChangeFormValue={commands.handleDateChangeFormValue}
            uploadsHistoryValue={models.uploadsHistoryValue}
            setUploadsHistoryValue={commands.setUploadsHistoryValue}
          />
        </div>
        <UploadHistoryTable
          codesList={models.codes}
          isLoading={models.isTableLoading}
          isSelectAll={models.selectAll}
          handleSelectAll={commands.handleSelectAllQuery}
          handleCheckboxChange={commands.handleCheckboxChangeQuery}
        />
        {models.codes && models.codes.length ? (
          <Pagination
            currentPage={Number(models.values.page) || 1}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <ConfirmDeleteUploadHistoryModal/>
    </>
  )
}
