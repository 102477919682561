import React from 'react'
import {benefitTitle} from '../../constants/main'
import {benefitText} from '../../constants/main'
import {BenefitCard} from '../benefit-card/benefit-card'
import ParallaxTilt from 'react-parallax-tilt'
import styles from './landing-benefit.module.scss'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'

const LandingBenefit = () => {
  return (
    <section className={styles['benefit']} id={LANDING_NAVIGATION.benefits.id}>
      <div className={styles['benefit__container']}>
        <h2 className={styles['benefit__title']}>{benefitTitle.title}</h2>
        <div className={`${styles['benefit__cards']} ${styles['benefit__cards--desktop']}`}>
          {benefitText.map((benefit) => (
            <ParallaxTilt key={benefit.id} tiltMaxAngleX={10} tiltMaxAngleY={10} scale={1}>
              <BenefitCard
                benefitText={benefit.title}
                imgPosition={benefit.imgPosition}
                bg={benefit.bg}
                image={benefit.image}
              />
            </ParallaxTilt>
          ))}
        </div>
        <div className={`${styles['benefit__cards']} ${styles['benefit__cards--mobile']}`}>
          {benefitText.map((benefit) => (
            <BenefitCard
              key={benefit.id}
              benefitText={benefit.title}
              imgPosition={benefit.imgPosition}
              bg={benefit.bg}
              image={benefit.image}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default LandingBenefit