import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {IMessage, MessagePayload} from 'shared/types/api-types/chat-types'
import {MessageRow} from './components/MessageRow'
import {Loader} from 'shared/components/loader/loader'
import {
  selectAbilityToUpdateChat,
  selectPage,
  selectPerPage,
} from 'app/store/chat/selects'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {SupportApi} from 'app/api/support-api/support-api'
import {ICustomer} from 'shared/types/user'
import {toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'
import {CHAT_TEMPLATE_MESSAGES_EVENT, EventBus} from 'shared/utils/event-bus'
import {useObserver} from 'shared/hooks/use-observer'

type ChatInnerProps = {
  isDrawer?: boolean
  messages?: IMessage[] | MessagePayload[] | null
  userId?: number
  customer?: ICustomer
  isLoading?: boolean
  getMessages?: (userId: number, page?: number) => void
}

const ChatInner: FC<ChatInnerProps> = ({
  isDrawer = false,
  userId,
  messages,
  isLoading,
  customer,
  getMessages,
}) => {
  const [message, setMessage] = useState<string>()
  const abilityToUpdateByScroll = selectAbilityToUpdateChat()
  const perPage = selectPerPage()
  const page = selectPage()
  const sendMessageMutation = useMutation<
    IMessage,
    AxiosError<{message: string}>,
    MessagePayload
  >(SupportApi.sendMessage, {
    onSuccess: (data: IMessage) => {
      getMessages(customer.user_id || userId)
      setMessage('')
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error.response.data.message)
    },
  })

  const handleSendMessage = () => {
    sendMessageMutation.mutate({
      text: message,
      user_id: customer.user_id,
    })
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const chatRef = useRef<HTMLDivElement>(null)

  const firstMessageRef = useRef<HTMLDivElement>(null)
  const lastMessageRef = useRef<HTMLDivElement>(null)

  const scroll = () => {
    lastMessageRef.current?.scrollIntoView({behavior: 'smooth'})
  }

  const handleUpdateChatWithPage = () => {
    chatRef.current.scrollTo({top: 25})
    getMessages(customer?.user_id || userId, page + 1)
  }

  useObserver(
    firstMessageRef,
    abilityToUpdateByScroll,
    isLoading,
    handleUpdateChatWithPage
  )

  useEffect(() => {
    scroll()
    EventBus.on(
      CHAT_TEMPLATE_MESSAGES_EVENT.template_from_order,
      (orderId: number) =>
        setMessage(
          `Order number:${orderId}\r\nVoucher code:\r\nIssue Description:\r\n`
        )
    )
    return () => {
      EventBus.off(CHAT_TEMPLATE_MESSAGES_EVENT.template_from_order, () =>
        setMessage(``)
      )
    }
  }, [])

  useEffect(() => {
    if (messages.length <= perPage) return scroll()
  }, [messages])

  return (
    <>
      <div
        className='card-body pb-0 position-relative'
        id={
          isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'
        }
      >
        {isLoading && <Loader mode='blur' />}
        <div
          ref={chatRef}
          className={clsx('scroll-y me-n5 pe-5', {
            'h-300px h-lg-auto': !isDrawer,
            'no-scroll': isLoading,
            'overflow-hidden':isLoading
          })}
          data-kt-element='messages'
          data-kt-scroll={'true'}
          data-kt-scroll-activate={'{default: false, lg: true}'}
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
          style={{height: '675px'}}
        >
          <div ref={firstMessageRef}></div>
          {messages && messages?.length ? (
            messages.map((message) => (
              <MessageRow
                key={message.id}
                message={message}
                userId={userId}
                isDrawer={isDrawer}
              />
            ))
          ) : (
            <span className='text-muted fs-7 mb-1'>
              There is no messages in chat.
            </span>
          )}
          <div ref={lastMessageRef}></div>
        </div>
      </div>
      <div
        className='card-footer d-flex flex-column flex-end py-2'
        id={
          isDrawer
            ? 'kt_drawer_chat_messenger_footer'
            : 'kt_chat_messenger_footer'
        }
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={3}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          style={{resize: 'none'}}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
          disabled={sendMessageMutation.isLoading}
        ></textarea>

        <button
          className='btn btn-exl-primary text-white position-relative'
          type='button'
          data-kt-element='send'
          onClick={handleSendMessage}
          disabled={sendMessageMutation.isLoading || !message}
        >
          {sendMessageMutation.isLoading ? <Spinner size={'sm'} /> : 'Send'}
        </button>
      </div>
    </>
  )
}

export {ChatInner}
