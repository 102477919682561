import React, { useState } from 'react'
import {CarouselDots} from './carousel-dots/carousel-dots'
import { Icon } from 'shared/components/icon/icon'
import { ICON_COLLECTION } from 'shared/components/icon/icon-list'
import styles from './carousel.module.scss'

interface CarouselProps {
  children: React.ReactNode
}

interface CustomStyles {
  '--active': number
  '--offset': number
  '--direction': number
  '--abs-offset': number
  'pointerEvents': 'auto' | 'none'
  opacity: '0' | '1'
  display: 'none' | 'block'
}

const Carousel: React.FC<CarouselProps> = ({children}) => {
  const [active, setActive] = useState(2)
  const count = React.Children.count(children)
  const MAX_VISIBILITY = 3

  return (
    <div className={styles['carousel']}>
      {active > 0 && (
        <button
          className={`${styles['nav']} ${styles['left']}`}
          onClick={() => setActive((i) => i - 1)}
        >
          <Icon icon={ICON_COLLECTION.SliderArrowLeft} />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className={styles['card-container']}
          style={
            {
              '--active': i === active ? 1 : 0,
              '--offset': (active - i) / 3,
              '--direction': Math.sign(active - i),
              '--abs-offset': Math.abs(active - i) / 3,
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
              display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
            } as CustomStyles
          }
        >
          {React.cloneElement(child as React.ReactElement, {setActive})}
        </div>
      ))}
      {active < count - 1 && (
        <button
          className={`${styles['nav']} ${styles['right']}`}
          onClick={() => setActive((i) => i + 1)}
        >
          <Icon icon={ICON_COLLECTION.SliderArrowRight} />
        </button>
      )}
      <CarouselDots count={count} activeIndex={active} setActive={setActive} />
    </div>
  )
}

export default Carousel
