import * as Yup from 'yup'
export const accountSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Name is required'),
  contact_email: Yup.string()
    .nullable()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols'),
  company_name: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Company name is required'),
  company_registration_number: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols'),
  VAT_number: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols'),
  website: Yup.string()
    .nullable()
    .matches(
      /(?:https?):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?|\bwww\.[-a-z0-9]+(\.[-a-z0-9]+)*\b/gi,
      'Website is not correct!'
    ),
  city: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('City is required'),
  company_address: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Street address is required'),
  company_director: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols'),
  account_payable_contact: Yup.string()
    .nullable()
    .min(10, 'Minimum 10 symbols')
    .required('Account paylable contact is required'),
  account_payable_email: Yup.string()
    .nullable()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Account paylable email is required'),
  zip_post_code: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 255 symbols'),
  country_of_incorporation_id: Yup.number()
    .integer()
    .required('Country of incorporation is required'),
  phone_number: Yup.string()
    .nullable()
    .min(10, 'Minimum 10 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Phone is required'),
  email_to_receive_digital_delivery_and_invoice: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Email is required'),
  business_type: Yup.number()
    .integer()
    .nullable()
    .required('Business type is required'),
})
