import React, {useState} from 'react';
import {Modal} from "shared/components/modal-base";
import {ButtonList} from "shared/components/button-list/button-list";
import {Button} from "shared/components/button/button";
import {useStateModalManager} from "shared/context/modal-manager";
import {DISCOUNT_MODAL_NAME} from "shared/constants/modal-names";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {DiscountsApi} from "app/api/dashboard-api/discounts-api";
import {DISCOUNT_EVENTS, EventBus} from 'shared/utils/event-bus';

export const RemoveDiscount = () => {
    const [discountId, setDiscountId] = useState(null);
    const modalState = useStateModalManager(DISCOUNT_MODAL_NAME.remove_discount, {
        onBeforeOpen: (discountId: number) => {
            setDiscountId(discountId)
        }
    });
    const handleClose = () => {
        modalState.close()
        modalState.rejectCallback('error')
    }
    const deleteDiscountMutation = useMutation<
        string,
        AxiosError<{ error: string }>,
        number>(DiscountsApi.deleteDiscount, {
        onError: (error) => {
            modalState.rejectCallback('error')
            toast.error(error?.response.data.error)
        },
        onSuccess: (data) => {
            toast.success(data)
            setDiscountId(null)
            modalState.resolveCallback(discountId)
            modalState.close()
        }
    })
    const handelRemoveProduct = () => {
        if (discountId) {
            deleteDiscountMutation.mutate(discountId)
        }
    }
    return (
        <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!deleteDiscountMutation.isLoading}>
            <div className="p-5 text-center">
                <p className='text-exl-primary mb-10'>
                    Are you sure you want to remove this discount ?
                </p>
                <ButtonList className='justify-content-end'>
                    <Button label='Cancel'
                            onClick={handleClose}
                            disabled={deleteDiscountMutation.isLoading}
                            isLoading={deleteDiscountMutation.isLoading}/>
                    <Button label='Remove'
                            mode={'remove'}
                            disabled={deleteDiscountMutation.isLoading}
                            isLoading={deleteDiscountMutation.isLoading}
                            onClick={handelRemoveProduct}/>
                </ButtonList>
            </div>

        </Modal.Root>
    );
};

