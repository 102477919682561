import {BaseHttpServices} from '../../../shared/services/base-http-services'

export class DocumentationApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getDocumentationJson = async (): Promise<string> => {
    const payload = await this.http.get(`/docs/public`)
    return payload.data
  }
}

export const DocumentationApi = new DocumentationApiService(new BaseHttpServices())
