import React, {useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {STATISTICS_QUERY_KEYS} from 'shared/constants/query-keys'
import {StatisticsApi} from 'app/api/dashboard-api/statistics-api'
import {
  IStatisticFilters,
  PeriodsType,
} from 'shared/types/api-types/statistic-type'
import {BarChart} from './bar-chart'
import {STATISTIC_MODAL_NAME} from 'shared/constants/modal-names'
import {EventBus, STATISTIC_FILERS_EVENTS} from 'shared/utils/event-bus'
import {useModalManager} from 'shared/context/modal-manager'
import {DownloadService} from 'shared/services/download-service'
import {toast} from 'react-toastify'
import {INITIAL_PARAMS} from '../../constants/init-params'

export const Revenue = () => {
  const [params, setParams] = useState<IStatisticFilters>({
    ...INITIAL_PARAMS,
    symbol: '£',
  })
  const [isCSVLoading, setIsCSVLoading] = useState(false)
  const modalManager = useModalManager()
  const revenueQuery = useQuery({
    queryKey: [STATISTICS_QUERY_KEYS.revenue, params],
    queryFn: () => StatisticsApi.getRevenue(params),
    onError: () => {
      toast.error('Something went wrong with Revenue chart')
    },
  })
  const revenue = useMemo(() => {
    const datesArray = []
    const valuesArray: number[] = []
    if (!revenueQuery.data || !revenueQuery.data.data) {
      return {
        datesArray,
        valuesArray: [{name: '+', data: []}],
      }
    }
    for (const date in revenueQuery.data.data) {
      datesArray.push(date)
      valuesArray.push(parseFloat(revenueQuery.data.data[date].toFixed(2)))
    }
    return {
      datesArray,
      valuesArray: [{name: 'Revenue', data: valuesArray}],
    }
  }, [revenueQuery.data])

  const handelChangePeriod = (period: PeriodsType) => {
    setParams((prevState) => {
      return {...prevState, period}
    })
  }
  const onOpenFilters = () => {
    modalManager.open(STATISTIC_MODAL_NAME.filter_statistics, {
      key: STATISTIC_FILERS_EVENTS.revenue,
      params,
    })
  }
  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const response = await StatisticsApi.getRevenue({...params, csv: 1})
      await DownloadService.downloadObjectAsZip(response, 'CSV')
      setIsCSVLoading(false)
    } catch (e) {
      setIsCSVLoading(false)
      toast.error('Download CSV Error!')
    }
  }
  useEffect(() => {
    EventBus.on(STATISTIC_FILERS_EVENTS.revenue, (data) => setParams({...data}))
    return () => {
      EventBus.off(STATISTIC_FILERS_EVENTS.revenue, (data) =>
        setParams({...data})
      )
    }
  }, [])

  return (
    <BarChart
      beforeParams={params.symbol}
      beforeValue={params.symbol}
      isYAxisInteger={true}
      isPriceChart={true}
      onDownload={onDownloadCSV}
      title='Revenue'
      onOpenFilters={onOpenFilters}
      names={revenue.datesArray}
      isLoading={revenueQuery.isLoading || isCSVLoading}
      period={params.period || 3}
      values={revenue.valuesArray}
      handelChangePeriod={handelChangePeriod}
    />
  )
}
