import React from 'react'
import {CodesTable} from '../../components/table/codes-table/codes-table'
import {ButtonLink} from 'shared/components/button/button-link'
import {CODES_ROUTES} from 'shared/config/routes'
import {Button} from 'shared/components/button/button'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {CodesSearchBar} from '../../components/codes-search-bar'
import {ButtonGroup} from 'react-bootstrap'
import {Pagination} from '../../../components/pagination/pagination'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {useCodesPage} from './use-codes-page'
import {CODES_NAV_ROUTES} from '../../codes-routes'
import {ConfirmDeleteCodesModal} from '../../components/modals/confirm-delete-codes-modal'
import {ConfirmChangeStatusModal} from '../../components/modals/confirm-change-status-modal'

export type initialFormName = 'code' | 'product' | 'supplier' | 'stock'

export const CodesPage = () => {
  const {models, commands} = useCodesPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold m-0'>Codes</h1>
        <ButtonGroup>
          <Button
            label={
              models.codesChecked.length
                ? `Remove (${models.codesChecked.length})`
                : 'Remove All'
            }
            disabled={models.isTableLoading || models.isCodesDeleting}
            isLoading={models.isTableLoading || models.isCodesDeleting}
            onClick={commands.onDeleteCodes}
          />
          <Button
            label={
              models.codesChecked.length
                ? `Out of Stock (${models.codesChecked.length})`
                : 'Out of Stock All'
            }
            className='mx-3'
            disabled={models.isTableLoading || models.isCodesBatchStatusUpdating}
            isLoading={models.isTableLoading || models.isCodesBatchStatusUpdating}
            onClick={commands.onUpdateBatchStatus}
          />
          <ButtonLink to={CODES_ROUTES.CREATE_CODES.path} mode='purple'>
            Upload Codes
          </ButtonLink>
        </ButtonGroup>
      </div>
      <div className='card p-7'>
        <TabMenu routes={CODES_NAV_ROUTES} className='mb-5' />
        <CodesSearchBar
          productsOptions={models.productsOptions}
          suppliersOptions={models.suppliersOptions}
          isLoadingProducts={models.isLoadingProducts}
          isLoadingSuppliers={models.isLoadingSuppliers}
          productValue={models.values.product}
          stockValue={Number(models.values.stock)}
          supplierValue={models.values.supplier}
          handleChangeFormValue={commands.handleChangeFormValue}
          stockOption={models.stockOptions}
          handleInputChangeProducts={commands.handleInputChangeProducts}
          handleInputChangeSuppliers={commands.handleInputChangeSuppliers}
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
          resetCheckedState={commands.resetCheckedState}
          onDownloadCSV={commands.onDownloadCSV}
          isCSVLoading={models.isCSVLoading}
          handleClearFilters={commands.handleClearFilters}
        />
        <CodesTable
          isLoading={models.isTableLoading}
          codesList={models.codes}
          isSelectAll={models.selectAll}
          handleSelectAll={commands.handleSelectAllQuery}
          handleCheckboxChange={commands.handleCheckboxChangeQuery}
        />

        {models.codes && models.codes.length ? (
          <Pagination
            currentPage={Number(models.values.page) || 1}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <ConfirmDeleteCodesModal />
      <ConfirmChangeStatusModal />
    </>
  )
}
