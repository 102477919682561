import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useModalManager} from 'shared/context/modal-manager'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {IBrand} from 'app/api/landing-api/landing-api'
import {OptionsApi} from 'app/api/options-api/options-api'

export const useBrandsPage = () => {
  const queryClient = useQueryClient()
  const modalManager = useModalManager()

  const brandsQuery = useQuery({
    queryKey: [OPTIONS_QUERY_KEYS.brands],
    queryFn: async () => await OptionsApi.getBrands(),
    refetchOnWindowFocus: false,
  })

  const updateBrandMutation = useMutation<
    IBrand,
    AxiosError<{message: string}>,
    IBrand
  >(OptionsApi.updateBrand, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        [OPTIONS_QUERY_KEYS.brands],
        (prevData: IBrand[]) => {
          const brandIndex = prevData.findIndex((brand) => brand.id === data.id)
          prevData.splice(brandIndex, 1, data)
          return prevData
        }
      )
      queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.brands])
      toast.success('Brand updated successfully!')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })

  const handleUpdateClick = (brand: IBrand) => {
    updateBrandMutation.mutate(brand)
  }

  const handleCreateClick = () => {
    modalManager.open(OPTIONS_MODAL_NAME.create_brand)
  }

  const handleDeleteClick = (brand: IBrand) => {
    modalManager.open(OPTIONS_MODAL_NAME.delete_brand, brand)
  }

  const isSaveButtonLoading = updateBrandMutation.isLoading

  const isTableLoading = brandsQuery.isLoading

  return {
    models: {
      brands: brandsQuery.data ? brandsQuery.data : [],
      isTableLoading,
      isSaveButtonLoading,
    },
    commands: {
      handleUpdateClick,
      handleDeleteClick,
      handleCreateClick,
    },
  }
}
