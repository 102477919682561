import React from 'react'
import {DISCOUNT_ROUTES} from 'shared/config/routes'
import {useDiscountsPage} from './use-discounts-page'
import {DiscountList} from '../../components/discount-list'
import {Pagination} from '../../../components/pagination/pagination'
import {Loader} from 'shared/components/loader/loader'
import {ButtonLink} from 'shared/components/button/button-link'
import {RemoveDiscount} from '../../components/modals/remove-discount'

export const DiscountsPage = () => {
  const {models, commands} = useDiscountsPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold m-0'>
          {DISCOUNT_ROUTES.ROOT.title}
        </h1>
        <ButtonLink to={DISCOUNT_ROUTES.CREATE.path} mode={'purple'}>
          Create Discount
        </ButtonLink>
      </div>
      <div className='card mt-3 p-10 position-relative'>
        <div className='position-relative'>
          {models.isLoadingPage && <Loader />}
          <DiscountList
            discountList={models.discounts}
            onUpdateDiscount={commands.onUpdateDiscount}
            onRemove={commands.onOpenRemoveDiscountWindow}
          />
          {models.meta && (
            <Pagination
              onPageChange={commands.onPageChange}
              totalCount={models.meta.total}
              currentPage={models.meta.current_page}
              pageSize={models.meta.per_page}
            />
          )}
        </div>        
      </div>
      <RemoveDiscount />
    </>
  )
}
