import {IProductSupplier} from 'app/api/suppliers-api/suppliers-api'
import React, {useEffect, useState} from 'react'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {Loader} from 'shared/components/loader/loader'
import { ICurrency } from 'shared/types/codes-upload'

interface ISupplierTableRow {
  supplier: IProductSupplier
  currencies: string[]
  allCurrencies: ICurrency[]
  handleDeleteSupplier: (supplierId: number) => void
  updateProductSupplier: (
    supplierId: number,
    currency: string,
    price: number
  ) => void
  isUpdateSupplierLoading: boolean
  updatingSupplier: number
}

export const SupplierTableRow: React.FC<ISupplierTableRow> = ({
  supplier,
  currencies,
  allCurrencies,
  isUpdateSupplierLoading,
  updatingSupplier,
  handleDeleteSupplier,
  updateProductSupplier,
}) => {
  const [updatedPrices, setUpdatedPrices] = useState<Record<string, number>>()
  const [updateTimeout, setUpdateTimeout] = useState(null)

  const currencySymbols = allCurrencies.reduce(
    (acc, currency) => ({
      ...acc,
      [currency.name]: currency.symbol,
    }),
    {}
  )

  const handlePriceChange = (currency, price) => {
    setUpdatedPrices({...updatedPrices, [currency]: price})
    if (updateTimeout) {
      clearTimeout(updateTimeout)
    }
    const newTimeout = setTimeout(() => {
      updateProductSupplier(
        supplier.supplier_id,
        currency,
        price
      )
    }, 1500)
    setUpdateTimeout(newTimeout)
  }

  useEffect(() => {
    setUpdatedPrices(supplier.currencies)
  }, [supplier.currencies])

  return (
    <tr className='border-bottom position-relative py-3'>
      <td>{supplier.supplier_name}</td>
      {currencies &&
        currencies.map((currency) => (
          <td key={currency}>
            <Input
              type='number'
              className='w-sm-150px'
              disabled={isUpdateSupplierLoading}
              isPriceInput
              value={
                updatedPrices && updatedPrices[currency]
                  ? updatedPrices[currency]
                  : ''
              }
              currency={currencySymbols[currency]}
              onChange={(e) =>
                handlePriceChange(currency, e.target.value)
              }
            />
            {updatingSupplier === supplier.supplier_id &&
              isUpdateSupplierLoading && <Loader mode='blur' />}
          </td>
        ))}
      <td>
        <Button
          type='button'
          mode='text'
          className='p-0 min-w-auto'
          label={'Delete'}
          onClick={() => handleDeleteSupplier(supplier.supplier_id)}
        />
      </td>
    </tr>
  )
}
