import {FindEntityResponse} from 'shared/hooks/use-filter-request'
import {BaseHttpServices} from 'shared/services/base-http-services'
import {
  IAdjustWalletParams,
  IAllWalletsList,
  IAllWalletsParams,
  ICreateWalletParams,
  IWallet,
  IWalletDefaultPrams,
  IWalletInvoiceResponse,
  IWalletInvoicesParams,
} from 'shared/types/api-types/wallets-type'

export class WalletsApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  createWallet = async (data: ICreateWalletParams): Promise<IWallet> => {
    const payload = await this.http.post(`/users/${data.userId}/wallets`, {
      currency_id: data.currency_id,
    })
    return payload.data.data
  }

  getWallets = async (userId: number | string): Promise<IWallet[]> => {
    const payload = await this.http.get(`/users/${userId}/wallets`)
    return payload.data.data
  }
  getWalletInvoices = async (
    data: IWalletInvoicesParams
  ): Promise<FindEntityResponse<IWalletInvoiceResponse>> => {
    if (!data.user || !data.wallet) return null
    const payload = await this.http.get(
      `/users/${data.user}/wallets/${data.wallet}/invoices`,
      {
        params: {
          page: data.page,
        },
        signal: data.signal,
      }
    )
    return payload.data
  }
  setDefaultWallet = async (data: IWalletDefaultPrams): Promise<IWallet> => {
    const payload = await this.http.post(
      `/users/${data.user}/wallets/${data.wallet}/default`
    )
    return payload.data.data
  }

  adjustWallet = async (data: IAdjustWalletParams): Promise<IWallet> => {
    const payload = await this.http.post(
      `/users/${data.params.user}/wallets/${data.params.wallet}/adjust`,
      {amount: data.amount}
    )
    return payload.data.data
  }

  topUpWallet = async (data: IAdjustWalletParams): Promise<IWallet> => {
    const payload = await this.http.post(
      `/users/${data.params.user}/wallets/${data.params.wallet}/top-up`,
      {amount: data.amount}
    )
    return payload.data.data
  }

  editCreditBalance = async (data: IAdjustWalletParams): Promise<IWallet> => {
    const payload = await this.http.post(
      `/users/${data.params.user}/wallets/${data.params.wallet}/credit`,
      {amount: data.amount}
    )
    return payload.data.data
  }

  getAllWallets = async (
    params: IAllWalletsParams | null
  ): Promise<IAllWalletsList> => {
    const payload = await this.http.get(`/wallets`, {
      params: params,
    })
    return payload.data
  }
}

export const WalletsApi = new WalletsApiService(new BaseHttpServices())
