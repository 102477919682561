import {AppAbility, RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import {IOption} from '../components/select/select-type'

export const STOCK_OPTIONS_CATALOG = (ability: AppAbility): IOption[] => [
  ...ability.can(RouteGuardActions.manage, RouteGuardEntities.Admin)
    ? [{
        label: 'Out of stock',
        id: 1,
      }]
    : [],
  {
    label: 'In stock',
    id: 2,
  },
  {
    label: 'Preorder',
    id: 3,
  }
]

export const STOCK_OPTIONS_CODES: IOption[] = [
  {
    label: 'Out of stock',
    id: 2,
  },
  {
    label: 'In stock',
    id: 1,
  }
]

export const ORDER_OPTIONS: IOption[] = [
  {
    label: 'High to Low',
    id: 1,
  },
  {
    label: 'Low to High',
    id: 2,
  },
]