import React, { useEffect } from "react";
import { useAccount } from "modules/dashboard/account/hooks/use-account";
import { FormikProps, useFormik } from "formik";
import { ICustomerRestrictions } from "shared/types/user";
import { CustomerLayout } from "../../layouts/customer-layout";
import { CustomerFormRestrictions } from "../../components/forms/customer-form-restrictions";

export const CustomerRestrictionsPage = () => {
    const {updateCustomerRestrictionsMutation, customer, restrictionsValues} = useAccount();

    useEffect(()=>{
        formik.resetForm()
        updateCustomerRestrictionsMutation.reset()
    },[updateCustomerRestrictionsMutation.isSuccess])

    const formik: FormikProps<ICustomerRestrictions> = useFormik({
        initialValues:restrictionsValues,
        enableReinitialize:true,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            try {
                updateCustomerRestrictionsMutation.mutate({userId:customer.user_id,params:values})                
            } catch (error) {
                setStatus('Something is went wrong! Try again later.')
                setSubmitting(false)
            }
        },
    })

    return (
        <CustomerLayout formik={formik}>
            <CustomerFormRestrictions formik={formik} isLoading={updateCustomerRestrictionsMutation.isLoading}/>            
        </CustomerLayout>
    )
}