import {Link} from 'react-router-dom'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {MutableRefObject, useEffect, useRef} from 'react'
import {ICON_COLLECTION} from '../../../../shared/components/icon/icon-list'
import {Icon} from '../../../../shared/components/icon/icon'
import {LANDING_ROUTES} from '../../../../shared/config/routes'
import {useSidebarController} from '../../../../shared/hooks/use-sidebar-controller'

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
  isOpen: boolean
}

const SidebarLogo = (props: PropsType) => {
  return (
    <div className='app-sidebar-logo px-6 ' id='kt_app_sidebar_logo'>
      <Link to={LANDING_ROUTES.ROOT.path} className='app-sidebar-logo__link'>
        <Icon
          icon={
            props.isOpen ? ICON_COLLECTION.logo : ICON_COLLECTION.LogoHeader
          }
        />
      </Link>
    </div>
  )
}

export {SidebarLogo}
