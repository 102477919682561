import {BaseHttpServices} from "shared/services/base-http-services";
import { ICart, IUpdateCartDTO } from "shared/types/cart";
import { IOrder } from "shared/types/order";


export interface ICartParamsDTO {
    product_id?: number
    product_hash?: string
    quantity?: number
}


export class CartApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    addToCart = async (date: {userId: number, params: ICartParamsDTO}): Promise<ICart> => {
        const payload = await this.http.post(`/users/${date.userId}/cart/add`, date.params);
        return payload.data.data;
    };
    
    getCart = async (userId:number):Promise<ICart> => {
        const payload = await this.http.get(`/users/${userId}/cart`);
        const codes = payload.data.data.codes.map((code)=>({...code,initial_quantity:code.quantity}));
        return {...payload.data.data,codes};
    }

    updateCart = async ({userId,product_hash,quantity}:IUpdateCartDTO):Promise<ICart> => {
        const payload = await this.http.post(`/users/${userId}/cart/update`,{
            product_hash,
            quantity
        });
        return payload.data.data;
    }

    clearCart = async (userId:number):Promise<ICart> => {
        const payload = await this.http.post(`/users/${userId}/cart/clear`);
        return payload.data.data;
    }

    checkoutCart = async (userId:number):Promise<IOrder> => {
        const payload = await this.http.post(`/users/${userId}/cart/checkout`);
        return payload.data.data;
    }
}

export const CartApi = new CartApiService(new BaseHttpServices());