import React, { useState } from 'react'
import {FormikProps} from 'formik'
import {ICustomerRestrictions} from 'shared/types/user'
import { Toggle } from 'shared/components/toggle/toggle'
import { Button } from 'shared/components/button/button'
import { Loader } from 'shared/components/loader/loader'

interface ICustomerRestrictionsComponent {
    formik: FormikProps<ICustomerRestrictions>
    isLoading?:boolean
}

export const CustomerFormRestrictions: React.FC<ICustomerRestrictionsComponent> = ({formik,isLoading=false}) => {
    const [isActive,setIsActive] = useState<boolean>(!formik.values.is_api_blocked);
    const handleToggle = () => {
        formik.setFieldValue('is_api_blocked',!!!formik.values.is_api_blocked);
        setIsActive(prevData=>!prevData)
        formik.submitForm();
    }

    const handleBlockClick = () => {
        formik.setFieldValue('is_blocked',!formik.values.is_blocked);
    }
    
    return (
        <>
            {(isLoading)&&<Loader mode={'blur'} />}
            <div className='card-body mt-10'>
                <div className='d-flex align-items-center gap-5 text-muted'>
                    <label>Allow user to use API</label>
                    <Toggle isActive={isActive} setActive={handleToggle}/>
                </div>
                
                <Button
                    className='bg-danger text-white mt-10'
                    type='submit'
                    label={formik.values.is_blocked ? 'Unblock user' :'Block user'}
                    onClick={handleBlockClick}
                />
            
            </div>
        </>
    )
}
