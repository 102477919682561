import React from 'react';
import {Modal} from "../../../../../shared/components/modal-base";
import {useStateModalManager} from "../../../../../shared/context/modal-manager";
import {DASHBOARD_MODAL_NAMES} from "../../../../../shared/constants/modal-names";
import {ButtonList} from "../../../../../shared/components/button-list/button-list";
import {Button} from "../../../../../shared/components/button/button";
import { useAccount } from 'modules/dashboard/account/hooks/use-account';

export const DeclineWarningModal = () => {
    const modalState = useStateModalManager(DASHBOARD_MODAL_NAMES.decline_customer_warning);
    const {declineCustomerMutation, customer} = useAccount();

    const handleClose = () => modalState.close()
    const handleDeclineCustomer = () => {
        declineCustomerMutation.mutate(customer.user_id);
    }
    return (
        <Modal.Root 
            open={modalState.open} 
            onClose={handleClose}
            isClosable={!declineCustomerMutation.isLoading}
        >
            <div className="p-5">
                <h2 className='text-center mb-5'>Decline Customer</h2>
                <p className='text-exl-primary my-10 text-center'>
                    Are you sure for decline this customer?
                </p>
                <ButtonList className='justify-content-end'>
                    <Button label='Cancel' onClick={handleClose} disabled={declineCustomerMutation.isLoading}/>
                    <Button label='OK' mainButton isLoading={declineCustomerMutation.isLoading} disabled={declineCustomerMutation.isLoading} onClick={handleDeclineCustomer}/>
                </ButtonList>
            </div>

        </Modal.Root>
    );
};

