import {useEffect, useState} from 'react'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {CartApi} from 'app/api/cart-api/cart-api'
import {ICart, IUpdateCartDTO} from 'shared/types/cart'
import {useModalManager} from 'shared/context/modal-manager'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {selectCartData, selectIsGettingCart} from 'app/store/cart/selects'
import {useCartState} from 'app/store/cart/state'
import {useUserState} from 'app/store/user/state'
import {EventBus, GLOBAL_EVENTS} from 'shared/utils/event-bus'
import {
  selectTemporaryIsLoading,
  selectTemporaryUserId,
} from 'app/store/temporary-customer/selects'
import {selectUserData} from 'app/store/user/selects'
import {useParams} from 'react-router-dom'
import {selectIsUpdatingCart} from 'app/store/cart/selects'

export const useCartPage = () => {
  const user = selectUserData()
  const temporaryUserId = selectTemporaryUserId()
  const {cartId} = useParams()
  const currentUser = temporaryUserId ? temporaryUserId : user.id
  const INITIAL_VALUES = {
    userId: currentUser,
    product_hash: null,
    quantity: null,
  }
  const cart = selectCartData()
  const [payload, setPayload] = useState<IUpdateCartDTO>(INITIAL_VALUES)
  const {setCart, changeQuantity, setIsUpdating} = useCartState()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const cartIsLoading = selectIsGettingCart()
  const userIsLoading = selectTemporaryIsLoading()
  const modalManager = useModalManager()
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const isCheckoutDisabled = selectIsUpdatingCart()
  const updateCartMutation = useMutation<
    ICart,
    AxiosError<{error: string}>,
    IUpdateCartDTO
  >(CartApi.updateCart, {
    onSuccess: (data) => {      
      if (!data.codes.length) {
        setIsCartHasProducts(false)
      }
      const productsInCart = data.codes.reduce(
        (sum, code) => sum + (code.quantity as number),
        0
      )
      setProductsInCartQuantity(productsInCart)
      setPayload(INITIAL_VALUES)
      setIsDeleting(false)
      setIsUpdating(false)       
      const codes = data.codes.map((code) => ({
        ...code,
        initial_quantity: code.quantity as number,
      }))
      setCart({...data, codes})
      EventBus.emit(GLOBAL_EVENTS.reset_groups_codes)
      EventBus.emit(GLOBAL_EVENTS.reset_products)      
    },
    onError: async (error) => {
      const cart = await CartApi.getCart(currentUser)
      setPayload(INITIAL_VALUES)
      const codes = cart.codes.map((code) => ({
        ...code,
        initial_quantity: code.quantity as number,
      }))
      setCart({...cart, codes})
      setIsUpdating(false)
      const productsInCart = cart.codes.reduce(
        (sum, code) => sum + (code.quantity as number),
        0
      )
      setProductsInCartQuantity(productsInCart)
      toast.error(error.response.data.error)
    },
  })

  const handleDeleteClick =
    (hash: string) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsDeleting(true)
      updateCartMutation.mutate({
        userId: currentUser,
        product_hash: hash,
        quantity: 0,
      })
      setPayload(INITIAL_VALUES)
    }

  const handleChangeQuantityProduct =
    (hash: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      event.target.focus()
      if (event.target.value !== '0' && !Number(event.target.value)) {
        setPayload({
          userId: currentUser,
          product_hash: hash,
          quantity: 0,
        })
      } else {
        setPayload({
          userId: currentUser,
          product_hash: hash,
          quantity: Number(event.target.value),
        })
      }
      const codeId = cart.codes.map((code) => code.hash).indexOf(hash)
      changeQuantity(
        Number(event.target.value) && Number(event.target.value) !== 0
          ? Number(event.target.value)
          : null,
        codeId
      )
      const productsInCart = cart.codes.reduce(
        (sum, code) => sum + (code.quantity as number),
        0
      )
      setProductsInCartQuantity(productsInCart)
    }
  const handleOnFocusInput = () => {
    setIsEditing(true)
  }

  const handleOnBlurInput = () => {
    setIsEditing(false)
  }
  useEffect(() => {
    setIsUpdating(isEditing)
    if (!isEditing) {
      const codeId = cart?.codes.findIndex(
        (code) => code.hash === payload.product_hash
      )
      const initialQuantity = cart?.codes[codeId]?.initial_quantity
      if (
        (payload.quantity &&
          initialQuantity &&
          payload.quantity !== initialQuantity) ||
        (payload.quantity === 0 && cart.codes.length)
      )
        updateCartMutation.mutate(payload)
    }
  }, [isEditing])

  useEffect(() => {
    setIsUpdating(updateCartMutation.isLoading)
  }, [updateCartMutation.isLoading])

  const handleClearCart = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.clear_cart)
  }

  const handleCheckoutClick = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.checkout)
  }

  const isSomeProductUpdating = (hash: string) => {
    const codeId = cart?.codes.findIndex(
      (code) => code.hash === payload.product_hash
    )
    const initialQuantity = cart?.codes[codeId]?.initial_quantity
    return (
      (payload.quantity === 0 && payload.product_hash !== hash) ||
      ((updateCartMutation.isLoading || payload.product_hash !== hash) &&
        !!payload.quantity &&
        payload.product_hash !== hash &&
        initialQuantity !== payload.quantity)
    )
  }

  const isCurrentProductUpdating = (hash: string) => {
    return (
      updateCartMutation.isLoading &&
      updateCartMutation.variables.product_hash === hash
    )
  }

  return {
    models: {
      payload,
      user,
      cart,
      cartIsLoading,
      currency: cart?.currency ? cart.currency.symbol : '-',
      itemsInCart: cart?.codes.length,
      isDeleting,
      currentUser,
      cartIdParam: cartId,
      userIsLoading,
      isEditing,
      isCheckoutDisabled,
    },
    commands: {
      updateCartMutation,
      handleDeleteClick,
      handleChangeQuantityProduct,
      isCurrentProductUpdating,
      isSomeProductUpdating,
      handleClearCart,
      handleOnBlurInput,
      handleCheckoutClick,
      setPayload,
      handleOnFocusInput,
    },
  }
}
