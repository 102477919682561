import React from 'react'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {SUPPLIERS_NAV_ROUTES} from '../../supliers-routes'
import {useSuppliersPage} from './use-suppliers'
import {SuppliersTable} from '../../components/table/suppliers-table/suppliers-table'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {SuppliersrSearchBar} from '../../components/suppliers-search-bar'
import {Button} from 'shared/components/button/button'
import { DeleteSupplierModal } from '../../components/modals/delete-supplier-modal'

export const SuppliersPage = () => {
  const {models, commands} = useSuppliersPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold'>Suppliers</h1>
        <div className='d-flex align-items-center gap-5'>
          <Button
            mainButton
            onClick={commands.onCreateSupplierClick}
            label='Create Supplier'
          />
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
        </div>
      </div>
      <div className='card p-7 position-relative'>
        <TabMenu routes={SUPPLIERS_NAV_ROUTES} className='mb-5' />
        <SuppliersrSearchBar
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
        />
        <SuppliersTable
          suppliers={models.suppliers}
          isLoading={models.isTableLoading}
          deleteSupplier={commands.handleDeleteSupplier}
          viewSingleSupplier={commands.handleViewSingleSupplier}
        />
        {models.suppliers && models.suppliers.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <DeleteSupplierModal />
    </>
  )
}
