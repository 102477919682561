import {
  IAllSuppliersList,
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {AxiosError} from 'axios'
import React, {useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'

export const DeleteSupplierModal = () => {
  const [supplierId, setSupplierId] = useState<number>()
  const modalState = useStateModalManager(
    SUPPLIERS_MODAL_NAME.delete_supplier,
    {
      onBeforeOpen: (supplierId: number) => {
        setSupplierId(supplierId)
      },
    }
  )

  const queryClient = useQueryClient()

  const deleteSupplierMutation = useMutation(
    async ({supplierId}: {supplierId: number}) => {
      await SuppliersApi.deleteSupplier(supplierId)
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          [SUPPLIERS_TRACKER_QUERY_KEYS.all_suppliers],
          (prevData: IAllSuppliersList) => {
            const tempProducts = prevData?.data.filter(
              (supplier) => supplier.id !== variables.supplierId
            )
            return {...prevData, data: tempProducts}
          }
        )
        queryClient.invalidateQueries([
          SUPPLIERS_TRACKER_QUERY_KEYS.all_suppliers,
        ])
        toast.success(`Supplier was deleted successfully!`)
        modalState.resolveCallback()
        handleClose()
      },
      onError: (error: AxiosError<{error: string}>) => {
        handleClose()
        toast.error(error?.response.data.error)
      },
    }
  )

  const handleDeleteSupplier = (supplierId: number) => {
    deleteSupplierMutation.mutate({ supplierId })
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
}

  const isLoading = deleteSupplierMutation.isLoading

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!isLoading}>
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to delete this supplier?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' disabled={isLoading} onClick={handleClose} />
          <Button
            label='Delete'
            mode='remove'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => handleDeleteSupplier(supplierId)}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
