import React, {useState} from 'react'
import {useFormik} from 'formik'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {Input} from 'shared/components/input/input'
import {
  IOneExternalProduct,
  IUpdateExternalProductDTO,
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {useMutation, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {Loader} from '../../../../../shared/components/loader/loader'

interface IEditFormValue {
  id?: number
  name?: string
}

interface IErrorForm {
  id?: number
  name?: string
}

const INITIAL_VALUES = {
  id: null,
  name: null,
}

export const UpdateExternalProductModal = () => {
  const [prodName, setProdName] = useState<string>('')
  const queryClient = useQueryClient()
  const modalState = useStateModalManager(SUPPLIERS_MODAL_NAME.update_product, {
    onBeforeOpen: (product_id: number, product_name: string) => {
      formik.setFieldValue('id', product_id)
      formik.setFieldValue('name', product_name)
      setProdName(product_name)
      formik.setErrors({})
    },
  })

  const onSubmit = (values: IUpdateExternalProductDTO) => {
    editProductMutation.mutate(values)
  }
  const validate = (values: IEditFormValue) => {
    const error: IErrorForm = {}
    if (!values.name) {
      error.name = 'Name is required field !'
    }
    return error
  }

  const formik = useFormik<IUpdateExternalProductDTO>({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validate,
  })
  const editProductMutation = useMutation<
    IOneExternalProduct,
    AxiosError<{error: string}>,
    IUpdateExternalProductDTO
  >(SuppliersApi.updateExternalProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(
          [SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker]
      )
      toast.success(`Product was edited successfully!`)
      handleClose()
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const handleClose = () => {
    modalState.close()
    formik.resetForm()
  }

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!editProductMutation.isLoading}
    >
      {editProductMutation.isLoading && <Loader mode='blur' />}
      <form className='p-7' onSubmit={formik.handleSubmit}>
        <h2 className='text-center text-exl-primary'>Edit {prodName}</h2>
        <div className='d-flex flex-column gap-3 my-5'>
          <Input
            type='text'
            placeholder='Product Name'
            {...formik.getFieldProps('name')}
            error={formik.touched.name && !!formik.errors.name}
            errorText={formik.errors.name}
            disabled={editProductMutation.isLoading}
          />
        </div>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            disabled={editProductMutation.isLoading}
            onClick={handleClose}
            type='button'
          />
          <Button
            label='Save'
            type='submit'
            isLoading={editProductMutation.isLoading}
            disabled={editProductMutation.isLoading}
            mainButton
          />
        </ButtonList>
      </form>
    </Modal.Root>
  )
}
