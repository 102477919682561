import clsx from 'clsx'
import React from 'react'
import {NavLink} from 'react-router-dom'
import styles from './tab-menu.module.scss'

interface IRoute {
    title: string
    path: string
}

interface ITabMenu {
    routes: IRoute[]
    className?: string
}

const TabMenuComponent: React.FC<ITabMenu> = ({routes, className}) => {
    return (
        <div className={clsx('d-flex overflow-auto py-1', className)}>
            <ul className='nav nav-line-tabs nav-line-tabs-2x fs-3 flex-nowrap w-100 align-items-center'>
                {routes.map(({path, title}) => (
                    <li className='nav-item' key={title}>
                        <NavLink
                            className={({isActive}) =>
                                clsx(`nav-link fw-bold text-center`, styles.navLink, isActive && styles.active)
                            }
                            to={path}
                            end
                        >
                            {title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    )
}
export const TabMenu = React.memo(TabMenuComponent)