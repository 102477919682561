import React from 'react';
import {CategoryList} from "../../components/category-list";
import {useCatalogPage} from "./use-catalog-page";
import {CatalogSearchBar} from "../../components/catalog-search-bar";
import {Button} from "shared/components/button/button";
import {Can} from "casl";
import {RouteGuardEntities, RouteGuardActions} from "casl/ability";
import {CreateCategory} from '../../components/modals/create-category'
import {Pagination} from "../../../components/pagination/pagination";
import {ButtonList} from "shared/components/button-list/button-list";
import {UpdateCategory} from "../../components/modals/modal-update-category";
import { ModalRemoveCategory } from '../../components/modals/modal-remove-category';

export const CatalogPage = () => {

    const {models, commands} = useCatalogPage()
    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-5">
                <h1 className="text-exl-primary bold m-0">Catalog</h1>
                <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                    <ButtonList>
                        <Button label={'Create Category'} mainButton onClick={commands.handleCreateProduct}/>
                    </ButtonList>
                </Can>

            </div>
            <div className='card p-7'>
                <CatalogSearchBar
                    regionOptions={models.optionRegions}
                    regionValue={models.values.region}
                    handleInputChangeRegion={commands.handleInputChangeRegions}
                    searchValue={models.searchValue}
                    setSearchValue={commands.setSearchValue}
                    isLoading={models.isLoadingSelects}
                    categoryOptions={models.categoryOptions}
                    handleChangeFormValue={commands.handleChangeFormValue}
                    categoryValue={models.values.category}
                    stockOption={models.stockOptions}
                    stockValue={models.values.in_stock}
                    currencyOption={models.currencyOption}
                    currencyValue={models.values.currency}
                    handleInputChangeCategory={commands.handleInputChangeCategory}
                />
                <CategoryList
                    handelSelect={commands.handleChangeFormValue}
                    onClearQuery={commands.onClearQuery}
                    isLoading={models.isLoadingList}
                    catalog={models.catalog}
                    onEditCategory={commands.handleUpdateCategory}
                    onRemoveCategory={commands.handleRemoveCategory}
                />
                {(models.catalog && models.catalog.length) ?
                    <Pagination
                        currentPage={Number(models.values.page) || 1}
                        totalCount={models.meta.total}
                        pageSize={models.meta.per_page}
                        onPageChange={page => commands.setCurrentPage(page)}

                    /> : null
                }
            </div>
            <CreateCategory/>
            <UpdateCategory/>
            <ModalRemoveCategory/>
        </>
    );
};

