import {RouteObject} from 'react-router-dom'
import {DOCUMENTATION_ROUTES} from '../../shared/config/routes'
import { DocumentationPage } from 'modules/documentation/page/documentation-page'

export const documentationRoutes: RouteObject[] = [
  {
    path: DOCUMENTATION_ROUTES.ROOT.path,
    element: <DocumentationPage />,
  },
]
