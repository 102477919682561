import React, { ChangeEvent } from "react"
import { Icon } from "shared/components/icon/icon"
import { ICON_COLLECTION } from "shared/components/icon/icon-list"
import { Input } from "shared/components/input/input"

interface IApiSearchBar {
    searchValue:string
    setSearchValue:(value:string)=>void
}

export const ApiSearchBar:React.FC<IApiSearchBar> = ({searchValue,setSearchValue}) => {
    const handleSearchValue = (event: ChangeEvent<HTMLInputElement>)=>setSearchValue(event.target.value)
    
    return (
        <div className='row'>
          <Input 
              startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch}/>}
              placeholder='Search'
              value={searchValue}
              className='w-lg-auto w-100 mb-5'
              onChange={handleSearchValue}
          />
        </div> 
    )
}