import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {ISlider, ISliderImage, OptionsApi} from 'app/api/options-api/options-api'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {useModalManager} from 'shared/context/modal-manager'

export const useSlidersPage = () => {
  const queryClient = useQueryClient()
  const modalManager = useModalManager()

  const slidersQuery = useQuery({
    queryKey: [OPTIONS_QUERY_KEYS.sliders],
    queryFn: async () => await OptionsApi.getSliders(),
    refetchOnWindowFocus: false,
  })

  const updateSliderMutation = useMutation<ISlider, AxiosError<{message: string}>, ISlider>(
    OptionsApi.updateSlider,
    {
      onSuccess: (data: ISlider) => {
        queryClient.setQueryData([OPTIONS_QUERY_KEYS.sliders], (prevData: ISlider[]) => {
          const regionIndex = prevData.findIndex((region) => region.slug === data.slug)
          prevData.splice(regionIndex, 1, data)
          return prevData
        })
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.sliders])
        toast.success(`Slider ${data.slug} updated successfully!`)
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleUpdate = (slider: ISlider) => {  
    updateSliderMutation.mutate(slider)
  }

  const handleDeleteFile =
    (slug: string, image: ISliderImage) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      modalManager.open(OPTIONS_MODAL_NAME.delete_slider_image, {slug, id: image.id})
    }

  const isTableLoading = slidersQuery.isLoading

  return {
    models: {
      sliders: slidersQuery.data ? slidersQuery.data : [],
      isTableLoading,
      isUpdating: updateSliderMutation.isLoading,
    },
    commands: {
      handleUpdate,
      handleDeleteFile,
    },
  }
}
