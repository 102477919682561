import React from "react"
import { IPurchase, IPurchaseProduct } from "shared/types/purchase"
import { PurchaseProductTableRow } from "./purchase-products-table-row"

interface IPurchaseProductsTableComponent {
  products: IPurchaseProduct[]
  purchase: IPurchase
  handleConfirmDeleteClick: (purchaseId:number,productId:number) => void
  handleEditProductFromPurchase: (product:IPurchaseProduct) => (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const PurchaseProductsTableComponent: React.FC<IPurchaseProductsTableComponent> = ({
  products,
  purchase,
  handleConfirmDeleteClick,
  handleEditProductFromPurchase
}) => {
  return (
    <div className='table-responsive'>
      <table className='table gs-3 gy-4 gx-3 text-exl-primary'>
        <thead>
          <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
            <th>Product Name</th>
            <th>Purchase Price</th>
            <th>Quantity</th>
            <th>Net</th>
            <th>Vat</th>
            <th>Total</th>
            <th>Delivered</th>
            <th>Actions</th>
          </tr>
        </thead>
        {products && products.length ? (
          <tbody>
            {products.map((product: IPurchaseProduct) => (
              <PurchaseProductTableRow
                key={product.id}
                product={product}
                purchase={purchase}
                handleConfirmDeleteClick={handleConfirmDeleteClick}
                handleEditProductFromPurchase={handleEditProductFromPurchase}
              />
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr className='border-bottom text-center text-muted'>
              <td colSpan={8}>No products in this purchase.</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

export const PurchaseProductsTable = React.memo(PurchaseProductsTableComponent)