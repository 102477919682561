import React from 'react';
import {selectUserData} from "app/store/user/selects";
import {WalletWidget} from "../../../../modules/dashboard/wallet/components/wallet-widget/wallet-widget";

export const SidebarBalance = () => {
    const userData = selectUserData()

    if (!userData || !userData.customer) return null
    return (
            <WalletWidget/>
    );
};

