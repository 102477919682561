import clsx from 'clsx'
import {useEffect, useRef} from 'react'
import {ILayout, useLayout} from '../../core'
import {SidebarMenu} from './sidebar-menu/SidebarMenu'
import {SidebarLogo} from './SidebarLogo'
import {SidebarBalance} from "./SidebarBalance";
import {useAppNavigationContext} from "../../../../shared/context/app-navigation-context";
import {Icon} from "../../../../shared/components/icon/icon";
import {ICON_COLLECTION} from "../../../../shared/components/icon/icon-list";
import {useSidebarController} from "../../../../shared/hooks/use-sidebar-controller";
import { SidebarFooter } from './SidebarFooter'
import { Can } from 'casl'
import { RouteGuardActions, RouteGuardEntities } from 'casl/ability'

export const Sidebar: React.FC = () => {
    const {config} = useLayout()
    const sidebarRef = useRef<HTMLDivElement>(null)
    const {navigationLinks} = useAppNavigationContext();
    const {sidebarToggle, isOpen} = useSidebarController()
    useEffect(() => {
        updateDOM(config)
    }, [config])


    if (!config.app?.sidebar?.display) {
        return null
    }

    return (
        <>
            {(config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') && (
                <div
                    ref={sidebarRef}
                    id='kt_app_sidebar'
                    className={clsx('app-sidebar justify-content-between p-0 rounded-end-4', config.app?.sidebar?.default?.class)}
                >
                    <button
                        onClick={sidebarToggle}
                        className={clsx(
                            ' btn btn-icon d-none d-lg-block btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute  start-100 translate-middle rotate',
                            {active: isOpen}
                        )}
                        style={{top: '50px', zIndex:'110'}}

                    >
                        {/*<KTIcon iconName='double-left' className='fs-2 rotate-180'/>*/}
                        <Icon icon={ICON_COLLECTION.sidebarArrow} className='fs-2 rotate-180'/>
                    </button>
                    <div style={{overflowY: 'auto', overflowX: 'hidden'}} className='pt-5'>
                        <SidebarLogo sidebarRef={sidebarRef} isOpen = {isOpen}/>
                        <SidebarBalance/>
                        <SidebarMenu links={navigationLinks}/>
                    </div>
                    <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Users}>
                        <SidebarFooter/>                    
                    </Can>
                </div>
            )}
        </>
    )
}

const updateDOM = (config: ILayout) => {
    if (config.layoutType === 'dark-sidebar' || config.layoutType === 'light-sidebar') {
        if (config.app?.sidebar?.default?.minimize?.desktop?.enabled) {
            if (config.app?.sidebar?.default?.minimize?.desktop?.default) {
                document.body.setAttribute('data-kt-app-sidebar-minimize', 'on')
            }

            if (config.app?.sidebar?.default?.minimize?.desktop?.hoverable) {
                document.body.setAttribute('data-kt-app-sidebar-hoverable', 'true')
            }
        }

        if (config.app?.sidebar?.default?.minimize?.mobile?.enabled) {
            if (config.app?.sidebar?.default?.minimize?.mobile?.default) {
                document.body.setAttribute('data-kt-app-sidebar-minimize-mobile', 'on')
            }

            if (config.app?.sidebar?.default?.minimize?.mobile?.hoverable) {
                document.body.setAttribute('data-kt-app-sidebar-hoverable-mobile', 'true')
            }
        }

        if (config.app?.sidebar?.default?.collapse?.desktop?.enabled) {
            if (config.app?.sidebar?.default?.collapse?.desktop?.default) {
                document.body.setAttribute('data-kt-app-sidebar-collapse', 'on')
            }
        }

        if (config.app?.sidebar?.default?.collapse?.mobile?.enabled) {
            if (config.app?.sidebar?.default?.collapse?.mobile?.default) {
                document.body.setAttribute('data-kt-app-sidebar-collapse-mobile', 'on')
            }
        }

        if (config.app?.sidebar?.default?.push) {
            if (config.app?.sidebar?.default?.push?.header) {
                document.body.setAttribute('data-kt-app-sidebar-push-header', 'true')
            }

            if (config.app?.sidebar?.default?.push?.toolbar) {
                document.body.setAttribute('data-kt-app-sidebar-push-toolbar', 'true')
            }

            if (config.app?.sidebar?.default?.push?.footer) {
                document.body.setAttribute('data-kt-app-sidebar-push-footer', 'true')
            }
        }

        if (config.app?.sidebar?.default?.stacked) {
            document.body.setAttribute('app-sidebar-stacked', 'true')
        }

        document.body.setAttribute('data-kt-app-sidebar-enabled', 'true')
        document.body.setAttribute(
            'data-kt-app-sidebar-fixed',
            config.app?.sidebar?.default?.fixed?.desktop?.toString() || ''
        )

        const appSidebarDefaultDrawerEnabled = config.app?.sidebar?.default?.drawer?.enabled
        let appSidebarDefaultDrawerAttributes: { [attrName: string]: string } = {}
        if (appSidebarDefaultDrawerEnabled) {
            appSidebarDefaultDrawerAttributes = config.app?.sidebar?.default?.drawer?.attributes as {
                [attrName: string]: string
            }
        }

        const appSidebarDefaultStickyEnabled = config.app?.sidebar?.default?.sticky?.enabled
        let appSidebarDefaultStickyAttributes: { [attrName: string]: string } = {}
        if (appSidebarDefaultStickyEnabled) {
            appSidebarDefaultStickyAttributes = config.app?.sidebar?.default?.sticky?.attributes as {
                [attrName: string]: string
            }
        }

        setTimeout(() => {
            const sidebarElement = document.getElementById('kt_app_sidebar')
            // sidebar
            if (sidebarElement) {
                const sidebarAttributes = sidebarElement
                    .getAttributeNames()
                    .filter((t) => t.indexOf('data-') > -1)
                sidebarAttributes.forEach((attr) => sidebarElement.removeAttribute(attr))

                if (appSidebarDefaultDrawerEnabled) {
                    for (const key in appSidebarDefaultDrawerAttributes) {
                        if (appSidebarDefaultDrawerAttributes.hasOwnProperty(key)) {
                            sidebarElement.setAttribute(key, appSidebarDefaultDrawerAttributes[key])
                        }
                    }
                }

                if (appSidebarDefaultStickyEnabled) {
                    for (const key in appSidebarDefaultStickyAttributes) {
                        if (appSidebarDefaultStickyAttributes.hasOwnProperty(key)) {
                            sidebarElement.setAttribute(key, appSidebarDefaultStickyAttributes[key])
                        }
                    }
                }
            }
        }, 0)
    }
}


