import {Suspense, useEffect} from 'react'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AppRoutes} from './routing/AppRoutes'
import {useUserState} from './store/user/state'
import {selectAuthUserData} from './store/auth/selects'
import defineAbilityFor from 'casl/ability'
import {AbilityContext} from 'casl/index'
import {selectIsGettingUser, selectUserData} from './store/user/selects'
import {Layout} from './layouts/layout'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useCartState} from './store/cart/state'
import {useWalletsState} from './store/wallets/state'
import {useLocation, useNavigate} from 'react-router-dom'
import {AUTH_ROUTES} from 'shared/config/routes'

const App = () => {
  const {getProfile} = useUserState()
  const {getCart} = useCartState()
  const {getWallets} = useWalletsState()
  const userAuth = selectAuthUserData()
  const userData = selectUserData()
  const isGettingUser = selectIsGettingUser()
  const location = useLocation()
  const navigate = useNavigate()
  const state = location.state as {from: Location}
  useEffect(() => {
    navigate(location.pathname || AUTH_ROUTES.ROOT.path, {
      replace: true,
      state: state
    })
    if (userAuth) {
      getProfile(userAuth)
      getCart(userAuth)
      getWallets(userAuth)
    }
  }, [userAuth])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <AbilityContext.Provider value={defineAbilityFor(userData)}>
          <Layout
            userData={userData}
            isUserAuth={!!userAuth}
            isLoading={isGettingUser}
          >
            <LayoutProvider>
              <MasterInit />
              <AppRoutes />
              <ToastContainer
                limit={2}
                position='bottom-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
              />
            </LayoutProvider>
          </Layout>
        </AbilityContext.Provider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
