import {RouteObject} from "react-router-dom";
import {DISCOUNT_ROUTES} from "../../shared/config/routes";
import {RoleGuard} from "../guards/role-guard";
import {RouteGuardActions, RouteGuardEntities} from "../../casl/ability";
import {DiscountsPage} from "../../modules/dashboard/discounts/pages/discounts-page/discounts-page";
import {SingleDiscountPage} from "../../modules/dashboard/discounts/pages/single-discount-page/single-discount-page";
import {CreateDiscount} from "../../modules/dashboard/discounts/pages/create-discount/create-discount";

export const discountsRoutes: RouteObject[] = [
    {
        path: DISCOUNT_ROUTES.ROOT.path,
        element: <RoleGuard
            I={RouteGuardActions.manage}
            a={RouteGuardEntities.Admin}
        ><DiscountsPage/>
        </RoleGuard>
    },
    {
        path: DISCOUNT_ROUTES.DISCOUNT.path,
        element: <RoleGuard
            I={RouteGuardActions.manage}
            a={RouteGuardEntities.Admin}
        ><SingleDiscountPage/>
        </RoleGuard>
    },
    {
        path: DISCOUNT_ROUTES.CREATE.path,
        element: <RoleGuard
            I={RouteGuardActions.manage}
            a={RouteGuardEntities.Admin}
        ><CreateDiscount/>
        </RoleGuard>
    },
]