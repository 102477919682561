import {FormikProps} from 'formik'
import {Input} from 'shared/components/input/input'
import {RowContainer} from '../row-container/row-container'
import {ICustomerSettings} from 'shared/types/user'
import {PasswordInput} from 'shared/components/input/password-input'
import {Toggle} from 'shared/components/toggle/toggle'

interface IAccountSettings {
  formik: FormikProps<ICustomerSettings>
  isEditing: boolean
  handleToggleOrderEnabled: () => void
  handleToggleInvoiceEnabled: () => void
}

export const AccountFormSettings: React.FC<IAccountSettings> = ({
  formik,
  isEditing,
  handleToggleOrderEnabled,
  handleToggleInvoiceEnabled,
}) => {
  return (
    <div className='card-body mt-10 position-relative'>
      <RowContainer className='mb-5' label='Email'>
        <Input
          placeholder='Enter Email'
          {...formik.getFieldProps('email')}
          readOnly={!isEditing}
          disabled={!isEditing}
          name='email'
          autoComplete='on'
          error={formik.touched.email && !!formik.errors.email}
          errorText={formik.errors.email}
        />
      </RowContainer>

      <RowContainer className='mb-5' label='Password Reset'>
        <PasswordInput
          placeholder='Enter Password'
          {...formik.getFieldProps('password')}
          name='password'
          readOnly={!isEditing}
          disabled={!isEditing}
          value={(isEditing && formik.values.password) || ''}
          autoComplete='on'
          error={!!formik.errors.password}
          errorText={formik.errors.password}
        />
      </RowContainer>

      {isEditing && (
        <RowContainer className='mb-5' label='Password Confirmation'>
          <PasswordInput
            placeholder='Enter Password Confirmation'
            {...formik.getFieldProps('password_confirmation')}
            name='password_confirmation'
            readOnly={!isEditing}
            disabled={!isEditing}
            value={(isEditing && formik.values.password_confirmation) || ''}
            autoComplete='on'
            error={
              formik.touched.password && !!formik.errors.password_confirmation
            }
            errorText={formik.errors.password_confirmation}
          />
        </RowContainer>
      )}
      <RowContainer label='Receive Order emails' className='mb-5'>
        <div className='mw-75px'>
          <Toggle
            isActive={formik.values.is_order_email_enabled}
            setActive={handleToggleOrderEnabled}
            disabled={!isEditing}
          />
        </div>
      </RowContainer>
      <RowContainer label='Receive Invoices emails'>
        <div className='mw-75px'>
          <Toggle
            isActive={formik.values.is_invoice_email_enabled}
            setActive={handleToggleInvoiceEnabled}
            disabled={!isEditing}
          />
        </div>
      </RowContainer>
    </div>
  )
}
