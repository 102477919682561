import {BaseHttpServices} from "shared/services/base-http-services";
import { IChatResponse, IMessage, MessagePayload } from "shared/types/api-types/chat-types";

export class SupportApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getMessages = async (
        userId: number,
        page?:number
    ): Promise<IChatResponse> => {
        const payload = await this.http.get(`/users/${userId}/chat`,{
            params:{page}
        });      
        return payload.data;
    };

    sendMessage = async (message:MessagePayload) : Promise<IMessage> => {
        const payload = await this.http.post(`/users/${message.user_id}/chat`,message);        
        return payload.data.data;
    }
}

export const SupportApi = new SupportApiService(new BaseHttpServices());