import React, {ChangeEvent, useEffect, useMemo, useState} from 'react'
import {Input} from 'shared/components/input/input'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {PURCHASES_QUERY_KEYS} from 'shared/constants/query-keys'
import {toast} from 'react-toastify'
import {PURCHASES_MODAL_NAME} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {
  IProductInPurchaseParams,
  PurchaseApi,
} from 'app/api/purchases-api/purchases-api'
import {IPurchaseProduct} from 'shared/types/purchase'
import {IOption} from 'shared/components/select/select'
import {MutationConfig,useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {SelectCreatable} from 'shared/components/select/creatable-select'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

interface ModalBeforeData {
  purchase: number
}

export const AddProductModal = () => {
  const [purchase, setPurchase] = useState<number | null>(null)
  const modalState = useStateModalManager(PURCHASES_MODAL_NAME.add_product, {
    onBeforeOpen: (data: ModalBeforeData) => {
      setPurchase(data.purchase)
    },
  })
  const [params, setParams] = useState<IProductInPurchaseParams>({
    purchase,
    params: null,
  })
  const queryClient = useQueryClient()
  const handleClose = () => {
    setParams({purchase, params: null})
    modalState.close()
  }

  const addProductMutation = useMutation<
    IPurchaseProduct,
    AxiosError<{error?: string; message?: string}>,
    IProductInPurchaseParams
  >(PurchaseApi.addProductToPurchase, {
    onSuccess: (data: IPurchaseProduct) => {
      queryClient.invalidateQueries([PURCHASES_QUERY_KEYS.purchase_product])
      setParams({purchase, params: null})
      toast.success(`Purchase was created successfully!`)
    },
    onError: async (error) => {
      toast.error(error.response.data.error || error.response.data.message)
    },
  })

  const handleAddProductClick = () => {
    addProductMutation.mutate({
      ...params, params: {
      ...params.params,
      purchase_price: decimalFormatter(params.params.purchase_price)
    }})
  }

  const handleChangeInput =
    (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
      if (Number(event.target.value) < 0) {
        setParams((prevData: IProductInPurchaseParams) => {
          return {
            purchase,
            params: {
              ...prevData.params,
              [name]: '0',
            },
          }
        })
        return
      }
      setParams((prevData: IProductInPurchaseParams) => {
        return {
          purchase,
          params: {
            ...prevData.params,
            [name]: event.target.value,
          },
        }
      })
    }

  const {
    options: productOptions,
    isLoading: isLoadingProduct,
    handleInputChange,
    formationOptions: productsOptionsMemo
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.product,
    Fn: SelectsApi.getProducts,    
    format: 'productsOptions',
    params: 'name',
  })

  
  

  useEffect(() => {
    if (addProductMutation.isSuccess) {
      handleClose()
      addProductMutation.reset()
    }
  }, [addProductMutation.isSuccess])

  const isAddButtonDisabled =
    addProductMutation.isLoading ||
    !params.params?.product_id ||
    !params.params?.purchase_price ||
    !params.params?.quantity

  const createProductsMutation = useMutation({
    mutationFn: PurchaseApi.setProducts,
    onSuccess: (data) => {
      queryClient.setQueryData(
        [PURCHASES_QUERY_KEYS.product, 'options'],
        [data.data]
      )
      queryClient.invalidateQueries([PURCHASES_QUERY_KEYS.product, 'options'], {
        exact: true,
      })
      const createdProductFormatted = MutationConfig['productsOptions']([data.data])[0]
      productOptions.unshift(data.data)
      productsOptionsMemo.unshift(createdProductFormatted)
      setParams((prevData) => {
        return {
          purchase,
          params: {
            ...prevData.params,
            product_id: data.data.id,
          },
        }
      })
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error?.response.data.message)
    },
  })

  const onCreateProduct = (name: string) => {
    createProductsMutation.mutate({name: name})
  }

  const productValue = useMemo(() => {
    if (!productsOptionsMemo || !productsOptionsMemo.length) return null
    return (
      productsOptionsMemo.find(
        (product) => product.id === params.params?.product_id
      ) || null
    )
  }, [params.params?.product_id])

  const curProd = productOptions.find((prod) => productValue?.id === prod.id)
  const prodCurrency = curProd?.currency?.symbol || ''

  const handleChangeProductsSelect = async (
    value: IOption | null
  ) => {
    const product = productOptions.find((option) => option.id === value?.id)
    if (!product) {
      setParams((prevData: IProductInPurchaseParams) => {
        return {
          purchase,
          params: null,
        }
      })
    } else
      setParams((prevData: IProductInPurchaseParams) => {
        return {
          purchase,
          params: {
            ...prevData.params,
            purchase_price: Number(product.purchase_price),
            product_id: value?.id,
          },
        }
      })
  }

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!addProductMutation.isLoading}
    >
      <div className='p-10'>
        <h2 className='text-center mb-5'>Add product</h2>
        <label>Product</label>
        <SelectCreatable
          options={productsOptionsMemo}
          handleInputChange={handleInputChange}
          isLoading={isLoadingProduct || createProductsMutation.isLoading}
          isDisabled={addProductMutation.isLoading}
          placeholder='Product'
          value={productValue}
          onChange={(option) => handleChangeProductsSelect(option)}
          onCreate={(name) => onCreateProduct(name)}
        />
        <label>Purchase Price</label>
        <Input
          value={params.params?.purchase_price}
          onChange={handleChangeInput('purchase_price')}
          type='number'
          mode='number'
          currency={prodCurrency}
          isPriceInput
          step={0.5}
          min={0}
          disabled={addProductMutation.isLoading}
        />
        <label>Quantity</label>
        <Input
          value={params.params?.quantity}
          onChange={handleChangeInput('quantity')}
          type='number'
          mode='number'
          placeholder='0'
          integerLimit={4}
          step={1}
          min={0}
          disabled={addProductMutation.isLoading}
        />
        <ButtonList className='flex-column my-5'>
          <Button
            label='Add'
            mainButton
            isLoading={addProductMutation.isLoading}
            disabled={isAddButtonDisabled}
            onClick={handleAddProductClick}
          />
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={addProductMutation.isLoading}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
