export const decimalFormatter = (value) => {
  if (value === undefined || value === null) {
    return value
  }

  const formattedValue = value.toString().replace(/[^\d.-]/g, '')

  const minusIndex = formattedValue.indexOf('-')
  const dotIndex = formattedValue.indexOf('.')

  if (minusIndex !== -1 && dotIndex !== -1 && minusIndex > dotIndex) {
    return formattedValue.replace(/-/g, (match, offset) =>
      offset === minusIndex ? '-' : ''
    )
  }

  return dotIndex ? formattedValue : Number(formattedValue)
}
