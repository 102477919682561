import React, {useRef} from 'react';
import {toast} from "react-toastify";
import {Icon} from "shared/components/icon/icon";
import {ICON_COLLECTION} from "shared/components/icon/icon-list";
import {Button} from "shared/components/button/button";
import styles from './upload-image.module.scss'
import clsx from "clsx";

interface IUploadImageProps {
    setImage: (newPresets: string | null) => void
    onUpdateFile: (fide: File) => void
    onRemove?: () => void
    mode?: string
}

export const UploadImage:React.FC<IUploadImageProps> = ({setImage, onUpdateFile, onRemove, mode = null}) => {
    const fileRef = useRef<HTMLInputElement | null>(null);

    const readJsonFile = (event: React.ChangeEvent<HTMLInputElement>, funcSet: (data: string | null) => void) => {
        const fileReader = new FileReader();
        const {files} = event.target;
        if (!files || !event) return;
        onUpdateFile(files[0])
        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (e) => {
            funcSet(e.target?.result ? e.target?.result.toString() : null);
        };
        if (fileRef.current) {
            fileRef.current.value = '';
            onUpdateFile(null)
            setImage(null);
        }
    };

    const addToOtherPresets = (newPresets: string | null) => {
        if (newPresets) {
            setImage(newPresets);
        } else {
            toast.error('File is Empty');
        }
    };
    const onClear = () => {
        setImage(null)
        onRemove()
    };
    return (
        <>
            <Button onClick={() => fileRef.current!.click()} mainButton
                    className={clsx(styles.button, styles[`button--${mode}`])} label={
                <>
                    <input
                        ref={fileRef}
                        type="file"
                        id="ext-input-file"
                        accept="image/png, image/jpeg, image/svg+xml"
                        onChange={(e) => readJsonFile(e, addToOtherPresets)}
                        hidden
                        multiple={false}
                    />
                    <Icon icon={ICON_COLLECTION.edit}/>
                </>
            }/>
            <Button onClick={onClear} mainButton
                    className={clsx(styles.clear, styles[`clear--${mode}`])}
                    label={<Icon icon={ICON_COLLECTION.close}/>}/>
        </>
    );
};

