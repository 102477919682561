import {useEffect, useMemo, useState} from 'react'
import {useDebounce} from '../../_metronic/helpers'
import {toast} from 'react-toastify'

interface ISelectController<T> {
  key: string
  Fn: (params?: string | null) => Promise<T[]>
  getUnkownFn?: (id: number) => Promise<T>
  params?: string
  format?:
    | 'defaultOptions'
    | 'currencyOptions'
    | 'multiOptions'
    | 'usersOptions'
    | 'purchasesOptions'
    | 'productsOptions'
}

export const MutationConfig = {
  defaultOptions: (data) => {
    return data.map((item) => {
      return {id: item.id, label: item.name}
    })
  },
  currencyOptions: (data) => {
    return data.map((item) => {
      return {id: item.id, label: item.iso}
    })
  },
  multiOptions: (data) => {
    return data.map((item) => {
      return {value: item.id, label: item.name}
    })
  },
  usersOptions: (data) => {
    return data.map((item) => {
      return {
        id: item.id,
        label: item.customer ? item.customer.company_name : item.email,
      }
    })
  },
  purchasesOptions: (data) => {
    return data.map((item) => {
      return {id: item.id, label: `${item.id}`}
    })
  },
  productsOptions: (data) => {
    return data.map((item) => {
      return {
        id: item.id,
        label: `${item.name} | ${
          item?.currency ? item?.currency?.symbol : 'Without Currency'
        } | ${item?.region ? item?.region?.name : 'Without Region'}`,
      }
    })
  },
}

export const useSelectController = <T>({
  key,
  Fn,
  getUnkownFn,
  params,
  format = 'defaultOptions',
}: ISelectController<T>) => {
  const [options, setOptions] = useState<T[]>([])
  const [search, setSearch] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isGettingUnknownItems, setIsGettingUnknownItems] =
    useState<boolean>(false)
  const searchDebounce = useDebounce(search, 1000)
  const searchParams: string | null = useMemo(() => {
    if (searchDebounce) {
      return searchDebounce.toString()
    } else {
      return null
    }
  }, [searchDebounce])
  const initOptionsData = async () => {
    try {
      const options = await (params ? Fn(searchParams) : Fn(null))
      setOptions(options)
      setIsLoading(false)
    } catch (error: any) {
      throw error
    }
  }

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        await initOptionsData()
      } catch (error: any) {
        setIsLoading(false)
        toast.error(error.response.data.message || error.response.data.error)
      }
    })()
  }, [searchDebounce])

  const handleInputChange = (text: string) => setSearch(text)

  const formationOptions = useMemo(() => {
    if (!options) return []
    return MutationConfig[format](options)
  }, [options])

  const getUnknownItem = async (name: string, id: number) => {
    try {
      setIsGettingUnknownItems(true)
      const data = getUnkownFn ? await getUnkownFn(id) : await Fn(name)   
      if (!data) {
        setIsGettingUnknownItems(false)
        return null
      }
      const unknownItem = getUnkownFn
        ? (data as T)
        : (data as T[]).find((item) => {
            // @ts-ignore
            return item.name === name && item.id === id
          })          
      if (!unknownItem) {
        setIsGettingUnknownItems(false)
        return null
      }
      setIsGettingUnknownItems(false)
      return unknownItem
    } catch (e) {
      setIsGettingUnknownItems(false)
      console.error(e)
    }
  }

  return {
    options,
    isLoading,
    isGettingUnknownItems,
    // isFetching: selectOptionsQuery.isFetching,
    setNewOptions: setOptions,
    handleInputChange,
    formationOptions,
    getUnknownItem,
  }
}
