import React from 'react'
import {useSingleDiscountPage} from './use-single-discount-page'
import {DiscountHeader} from '../../components/discount-header/discount-header'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {DiscountUsersList} from '../../components/modals/discount-users-list'
import {ProductDiscountList} from '../../components/product-discount-list'
import {CreateProductDiscount} from '../../components/create-product-discount/create-product-discount'
import {RemoveProductDiscount} from '../../components/modals/remove-product-discount'
import {AlertValidationProductDiscountModel} from '../../components/modals/alert-validation-product-discount-modal'
import { AlertValidationAllowSellAtLossModal } from '../../components/modals/alert-validation-discount-sell-loss-modal'

export const SingleDiscountPage = () => {
  const {models, commands} = useSingleDiscountPage()

  return (
    <>
      <div className='card p-7'>
        <DiscountHeader
          value={models.discount}
          onOpenUsersList={commands.onOpenUsersList}
          isLoading={models.isLoadingDiscount || models.isUpdating}
          handleChangeName={commands.handleChangeName}
          handleDateChangeFormValue={commands.handleDateChangeFormValue}
          handleToggleIsActive={commands.handleToggleIsActive}
          handleToggleIsAllowToSellLess={commands.handleToggleIsAllowToSellLess}
        />
        <ButtonList className='justify-content-end'>
          <Button
            label='Update'
            onClick={commands.onUpdateDiscount}
            mainButton
            isLoading={models.isUpdating}
            disabled={models.isUpdating}
          />
        </ButtonList>
        <ProductDiscountList
          discountId={Number(models.discountId)}
          products={models.products}
          onOpenProductRemoveWindow={commands.onOpenProductRemoveWindow}
        />
        <CreateProductDiscount discountId={models?.discountId} />
      </div>
      <DiscountUsersList />
      <RemoveProductDiscount />
      <AlertValidationProductDiscountModel />
      <AlertValidationAllowSellAtLossModal/>
    </>
  )
}
