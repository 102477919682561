import React from 'react'
import {useInvoicesPage} from './use-all-invoices-page'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {AllInvoicesTable} from '../components/table/all-invoices-table'
import {CreateInvoiceModal} from 'modules/dashboard/invoices/components/modals/create-invoice-modal'
import {InvoicesSearchBar} from 'modules/dashboard/invoices/components/invoices-search-bar'
import {ConfirmDeleteAdminInvoiceModal} from '../components/modals/confirm-delete-invoice-modal'
import {Button} from 'shared/components/button/button'

export type initialFormName =
  | 'number'
  | 'status'
  | 'source'
  | 'type'
  | 'start_date'
  | 'end_date'

export const AllInvoicesPage = () => {
  const {models, commands} = useInvoicesPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-sm-center mb-5 flex-column flex-wrap flex-sm-row'>
        <h1 className='text-exl-primary bold'>Invoices</h1>
        <div className='d-flex gap-5 flex-column flex-sm-row w-100 w-sm-auto'>
          <Button
            label='Top-up'
            type='button'
            onClick={commands.handleCreateInvoiceClick}
          />
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
          <Button
            label={models.invoicesChecked.length ? 'Download selected': 'Download all'}
            mainButton
            type='button'
            isLoading={models.isPDFsLoading}
            disabled={models.isPDFsLoading}
            onClick={commands.onDownloadPDFs}
          />
        </div>
      </div>
      <div className='card p-7 position-relative'>
        <InvoicesSearchBar
          searchValue={models.searchValue}
          statusValue={models.values.status}
          sourceValue={models.values.source}
          typeValue={models.values.type}
          isLoading={models.isTableLoading}
          handleChangeFormValue={commands.handleChangeFormValue}
          handleDateChangeFormValue={commands.handleDateChangeFormValue}
          setSearchValue={commands.setSearchValue}
        />
        <AllInvoicesTable
          invoices={models.invoices}
          isLoading={models.isTableLoading}
          isSelectedAll={models.isSelectedAll}
          handleSelectAll={commands.handleSelectAllQuery}
          handleSelectModel={commands.handleCheckboxChangeQuery}
          handleApproveInvoice={commands.handleApproveInvoiceClick}
          isApproveLoading={commands.isApproveLoading}
          handleDeleteInvoice={commands.onDeleteInvoiceClick}
        />
        {models.invoices && models.invoices.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <CreateInvoiceModal />
      <ConfirmDeleteAdminInvoiceModal />
    </>
  )
}
