import React from 'react'
import {
  selectDefaultWallet,
  selectIsLoadingWallets,
  selectWalletsData,
} from '../../../../../app/store/wallets/selects'
import {useMutation} from 'react-query'
import {
  IWallet,
  IWalletDefaultPrams,
} from '../../../../../shared/types/api-types/wallets-type'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {WalletsApi} from '../../../../../app/api/dashboard-api/wallets-api'
import {useWalletsState} from '../../../../../app/store/wallets/state'
import {selectUserData} from '../../../../../app/store/user/selects'
import {WalletWidgetBody} from './wallet-widget-body'

export const WalletWidget = () => {
  const wallets = selectWalletsData()
  const isLoadingWallets = selectIsLoadingWallets()
  const defaultWallet = selectDefaultWallet()
  const user = selectUserData()
  const {setDefaultWallet} = useWalletsState()

  const setDefaultWalletMutation = useMutation<
    IWallet,
    AxiosError<{message: string}>,
    IWalletDefaultPrams
  >(WalletsApi.setDefaultWallet, {
    onSuccess: (data) => {
      setDefaultWallet(data)
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })
  const handelSetDefault = (walletId: number) => {
    setDefaultWalletMutation.mutate({user: user.id, wallet: walletId})
  }

  return (
    <WalletWidgetBody
      isMinimize={true}
      wallets={wallets}
      defaultWallet={defaultWallet}
      isLoading={isLoadingWallets || setDefaultWalletMutation.isLoading}
      handelSetDefault={handelSetDefault}
    />
  )
}
