import { Can } from 'casl'
import { RouteGuardActions, RouteGuardEntities } from 'casl/ability'
import {Button} from 'shared/components/button/button'

interface IAccountHeader {
  isEditing: boolean
  isEditable: boolean
  isLoading: boolean
  toggleEditMode: React.MouseEventHandler<HTMLButtonElement>
  isSettingsPage?: boolean
  handleRedirectToDocs?: () => void
}

export const AccountFormHeader: React.FC<IAccountHeader> = ({
  isEditing,
  isEditable,
  isLoading,
  toggleEditMode,
  isSettingsPage,
  handleRedirectToDocs,
}) => {
  return (
    <>
      <h1 className={'text-exl-primary mb-5'}>My Account</h1>
      <div className='d-flex flex-column flex-md-row gap-5'>
        {isSettingsPage && (
          <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
            <Button
              label='API Documentation'
              type='button'
              onClick={handleRedirectToDocs}
            />
          </Can>
        )}
        {isEditing && isEditable ? (
          <>
            <Button label='Cancel' type='button' onClick={toggleEditMode} />
            <Button
              label='Save'
              type='submit'
              mainButton
              isLoading={isLoading}
              disabled={isLoading}
            />
          </>
        ) : (
          isEditable && (
            <Button
              label='Edit'
              type='button'
              mainButton
              onClick={toggleEditMode}
            />
          )
        )}
      </div>
    </>
  )
}
