import {STATISTIC_FILERS_EVENTS} from "shared/utils/event-bus";

export const FILTER_VIEW_CONDITIONS = {
    dayPicker: [STATISTIC_FILERS_EVENTS.top_selling,
        // STATISTIC_FILERS_EVENTS.api_usage,
        STATISTIC_FILERS_EVENTS.last_orders,
        STATISTIC_FILERS_EVENTS.orders_avg,
        STATISTIC_FILERS_EVENTS.revenue,
        STATISTIC_FILERS_EVENTS.proffit,
        STATISTIC_FILERS_EVENTS.units_sold,
        STATISTIC_FILERS_EVENTS.top_clients,
        STATISTIC_FILERS_EVENTS.users_on_site,
        STATISTIC_FILERS_EVENTS.codes_upload_statistic,
        STATISTIC_FILERS_EVENTS.orders_chart],
    category: [STATISTIC_FILERS_EVENTS.top_selling,
        STATISTIC_FILERS_EVENTS.codes_upload_statistic,
        STATISTIC_FILERS_EVENTS.revenue,
        STATISTIC_FILERS_EVENTS.proffit,
        STATISTIC_FILERS_EVENTS.units_sold,
        STATISTIC_FILERS_EVENTS.low_stock],
    currency: [STATISTIC_FILERS_EVENTS.last_orders,
        STATISTIC_FILERS_EVENTS.orders_avg,
        STATISTIC_FILERS_EVENTS.revenue,
        STATISTIC_FILERS_EVENTS.proffit,
        STATISTIC_FILERS_EVENTS.units_sold,
        STATISTIC_FILERS_EVENTS.orders_chart,
        STATISTIC_FILERS_EVENTS.low_stock,
        STATISTIC_FILERS_EVENTS.top_selling,
        STATISTIC_FILERS_EVENTS.top_clients],
    method: [STATISTIC_FILERS_EVENTS.last_orders,
        STATISTIC_FILERS_EVENTS.orders_avg,
        STATISTIC_FILERS_EVENTS.api_usage,
        STATISTIC_FILERS_EVENTS.orders_chart,
        STATISTIC_FILERS_EVENTS.revenue,
        STATISTIC_FILERS_EVENTS.proffit,
        STATISTIC_FILERS_EVENTS.units_sold,
        STATISTIC_FILERS_EVENTS.top_selling,
        STATISTIC_FILERS_EVENTS.top_clients],
    product: [STATISTIC_FILERS_EVENTS.top_selling,
        STATISTIC_FILERS_EVENTS.revenue,
        STATISTIC_FILERS_EVENTS.proffit,
        STATISTIC_FILERS_EVENTS.codes_upload_statistic,
        STATISTIC_FILERS_EVENTS.units_sold,]
}