import React from 'react'
import {Button} from 'shared/components/button/button'
import {ButtonList} from 'shared/components/button-list/button-list'

interface IActionsGroupProps {
  isEdit: boolean
  onEditOn: () => void
  onEditOff: () => void
  onCancel: () => void
  codeAmount: number
  onSave: () => void
  onDownload: () => void
  isLoading: boolean
  onRemove: () => void
  onRemoveGroup: () => void
  isDisableSave?: boolean
}

const ActionsGroupComponent: React.FC<IActionsGroupProps> = ({
  isEdit,
  isLoading,
  onCancel,
  onEditOn,
  codeAmount,
  onSave,
  onDownload,
  onRemove,
  onRemoveGroup,
  isDisableSave,
}) => {
  return (
    <ButtonList>
      {!isEdit && <Button label='Edit' onClick={onEditOn} />}
      <Button
        label='Remove Product'
        mode='remove'
        onClick={onRemove}
        isLoading={isLoading}
        disabled={isLoading}
      />
      {isEdit && (
        <>
          {codeAmount > 0 && (
            <Button
              label={`Remove (${codeAmount})`}
              mode='remove'
              isLoading={isLoading}
              onClick={onRemoveGroup}
              disabled={isLoading}
            />
          )}
          <Button
            label={`Download ${
              codeAmount > 0 ? `(${codeAmount})` : 'all'
            } codes`}
            isLoading={isLoading}
            onClick={onDownload}
            disabled={isLoading}
          />
          <Button
            label='Cancel'
            onClick={onCancel}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Button
            label='Save'
            mainButton
            onClick={onSave}
            isLoading={isLoading}
            disabled={isLoading || isDisableSave}
          />
        </>
      )}
    </ButtonList>
  )
}

export const ActionsGroup = React.memo(ActionsGroupComponent)
