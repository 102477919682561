import {useMemo} from 'react';
import {ILink, ITabs} from "../types/base";
import {ICON_COLLECTION} from "../components/icon/icon-list";
import {ACCOUNT_ROUTES, DASHBOARD_ROUTES, DISCOUNT_ROUTES, OPTIONS_ROUTES} from "../config/routes";
import {AppAbility, RouteGuardActions, RouteGuardEntities} from "../../casl/ability";

export const useMenuList = (ability: AppAbility) => {

    const links: ILink[] = useMemo(() => {
        const list: ILink[] = [
            {
                icon: ICON_COLLECTION.statistic,
                ...DASHBOARD_ROUTES.STATISTIC,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },           
            {
                icon: ICON_COLLECTION.folder,
                ...DASHBOARD_ROUTES.ALL_ORDERS,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            }, 
            
            {
                icon: ICON_COLLECTION.bank,
                ...DASHBOARD_ROUTES.ALL_INVOICES,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },
            {
                icon: ICON_COLLECTION.shopping,
                ...DASHBOARD_ROUTES.CATALOG,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Customer,
                ),
            },
            {
                icon: ICON_COLLECTION.folder,
                ...DASHBOARD_ROUTES.ORDERS,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.CustomerNotAdmin,
                ),
            },
            {
                icon: ICON_COLLECTION.bank,
                ...DASHBOARD_ROUTES.INVOICES,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.CustomerNotAdmin,
                ),
            },
            {
                icon: ICON_COLLECTION.key,
                ...DASHBOARD_ROUTES.CODES,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },                         
            {
                icon: ICON_COLLECTION.customers,
                ...DASHBOARD_ROUTES.CUSTOMERS,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },
            {
                icon: ICON_COLLECTION.creditCard,
                ...ACCOUNT_ROUTES.WALLETS,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.CustomerNotAdmin,
                ),
            },
            {
                icon: ICON_COLLECTION.creditCard,
                ...DASHBOARD_ROUTES.ALL_WALLETS,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },
            {
                icon: ICON_COLLECTION.purchase,
                ...DASHBOARD_ROUTES.PURCHASE_TRACKER,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },                                    
            {
                icon: ICON_COLLECTION.supplier,
                ...DASHBOARD_ROUTES.SUPPLIER_TRACKER,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },
            {
                icon: ICON_COLLECTION.discounts,
                ...DISCOUNT_ROUTES.ROOT,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            },
            {
                icon: ICON_COLLECTION.setting,
                ...DASHBOARD_ROUTES.ACCOUNT
            },                        
            {
                icon: ICON_COLLECTION.options,
                ...OPTIONS_ROUTES.ROOT,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Admin,
                ),
            }
        ]
        return list.filter((link) => ('available' in link ? link.available : true));
    }, [ability.rules])


    const accountLinks: ITabs[] = useMemo(() => {
        const list: ITabs[] = [

            DASHBOARD_ROUTES.ACCOUNT,
            ACCOUNT_ROUTES.FILES,
            {
                ...ACCOUNT_ROUTES.WALLETS,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.Customer,
                ),
            },
            {
                ...ACCOUNT_ROUTES.API,
                available: ability.can(
                    RouteGuardActions.manage,
                    RouteGuardEntities.CustomerNotAdmin,
                ),
            },
            ACCOUNT_ROUTES.SETTINGS,
            ACCOUNT_ROUTES.TWO_FACTOR
        ]
        return list.filter((link) => ('available' in link ? link.available : true));
    }, [ability.rules])

    return {links, accountLinks}
};

