import React, {useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {OptionsApi} from 'app/api/options-api/options-api'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {ICurrency} from 'shared/types/codes-upload'

export const DeleteCurrencyModal = () => {
  const [currency, setCurrency] = useState<ICurrency>(null)
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.delete_currency, {
    onBeforeOpen: (currency: ICurrency) => {
      setCurrency(currency)
    },
  })

  const handleClose = () => {
    setCurrency(null)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const deleteCurrencyMutation = useMutation<ICurrency, AxiosError<{message: string}>, ICurrency>(
    OptionsApi.deleteCurrency,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.currencies])
        setCurrency(null)
        toast.success('Currency deleted successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleDeleteClick = () => {
    deleteCurrencyMutation.mutate(currency)
  }

  useEffect(() => {
    handleClose()
    deleteCurrencyMutation.reset()
  }, [deleteCurrencyMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!deleteCurrencyMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Delete Currency</h2>
        <p className='text-exl-primary my-10 text-center'>Are you sure for delete this currency?</p>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={deleteCurrencyMutation.isLoading}
          />
          <Button
            label='OK'
            mainButton
            isLoading={deleteCurrencyMutation.isLoading}
            disabled={deleteCurrencyMutation.isLoading}
            onClick={handleDeleteClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
