import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {IWallet} from "shared/types/api-types/wallets-type";
import {WalletsApi} from "../../api/dashboard-api/wallets-api";

interface IUseWalletsState {
    wallets: IWallet[]
    isLoading: boolean;
    setWallet: (wallet: IWallet) => void
    onUpdateWallet: (wallet: IWallet) => void
    setDefaultWallet: (wallet: IWallet) => void
    getWallets: (userId: number | string) => Promise<void>;
}

export const useWalletsState = create<IUseWalletsState>()(
    devtools(
        (set, get) => ({
            wallets: [],
            isLoading: false,
            getWallets: async function (userId) {
                try {
                    set({isLoading: true});
                    const tempWalletsData = await WalletsApi.getWallets(userId);
                    set({wallets: tempWalletsData});
                    set({isLoading: false});
                } catch (e) {
                    set({isLoading: false});
                }
            },
            setWallet(wallet) {
                set({wallets: [...get().wallets, wallet]})
            },
            setDefaultWallet(wallet) {
                set({isLoading: true});
                const tempWallets: IWallet[] = get().wallets.map((walletItem: IWallet) => {
                    if (walletItem.id === wallet.id) {
                        return {...walletItem, is_default: 1}
                    } else {
                        return {...walletItem, is_default: 0}
                    }
                })
                set({wallets: tempWallets})
                set({isLoading: false});
            },
            onUpdateWallet(wallet) {
                const tempWallets: IWallet[] = get().wallets
                const walletIndex = tempWallets.findIndex(walletIndex => walletIndex.id === wallet.id)
                if (walletIndex >= 0) {
                    tempWallets.splice(walletIndex, 1, wallet)
                    set({wallets: tempWallets})
                }
            }
        }),
        {name: 'useWalletsState', store: 'useWalletsState'},
    ),
);
