import React, {forwardRef, ReactElement, useId, useState} from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import ReactDOM from 'react-dom'
import {Button} from './button'
import styles from './actions-button.module.scss'
import clsx from 'clsx'
export interface IActionsButtons {
  id?:string
  label: string | ReactElement
  disabled?: boolean
  isLoading?: boolean
  mainButton?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export interface IActionsButtonProps {
  classNames?: string
  label: string | ReactElement
  buttons: IActionsButtons[]
}

type CustomToggleProps = {
  children?: string | ReactElement
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {}
}

const CustomToggle = React.forwardRef(
  (
    {children, onClick}: CustomToggleProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <Button
        ref={ref}
        label={children}
        className='p-0 min-w-auto'
        type='button'
        mode='text'
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.preventDefault()
          onClick(event)
        }}
      />
    )
  }
)

type ActionsButtonPortalProps = {
  children: React.ReactNode
}

const ActionsButtonPortal: React.FC<ActionsButtonPortalProps> = ({children}) =>
  ReactDOM.createPortal(children, document.getElementById('root'))

const ActionsButtonRoot: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  IActionsButtonProps
> = ({classNames, label, buttons}, ref) => {
  const id = useId()

  const [isOpen,setIsOpen] = useState<boolean>(false)
  const handleToggleClick = () => {
    setIsOpen(prevValue => !prevValue)
  }

  return buttons && buttons.length === 1 ? (
    <Button
      type='button'
      mode={'text'}
      ref={ref}
      id={buttons[0].id}
      isLoading={buttons[0].isLoading}
      mainButton={buttons[0].mainButton}
      disabled={buttons[0].disabled ? buttons[0].disabled : false}
      onClick={buttons[0].onClick}
      className={'p-0 min-w-auto'}
      label={buttons[0].label}
    />
  ) : (
    <Dropdown show={isOpen} onToggle={handleToggleClick}>
      <Dropdown.Toggle
        as={CustomToggle}
        id={`dropdown-item-button ${id}`}
        className={classNames}
      >
        {label}
        <span style={{paddingLeft: '5px'}}>&#x25bc;</span>
      </Dropdown.Toggle>
      <ActionsButtonPortal>
        <Dropdown.Menu className={clsx(styles['actions-list'],isOpen && styles['show'])}>
          {buttons &&
            buttons.length &&
            buttons.map((button, idx) => (
              <Button
                type='button'
                mode='text'
                key={idx}
                id={button.id}
                label={button.label}
                isLoading={button.isLoading}
                mainButton={button.mainButton}
                disabled={button.disabled ? button.disabled : false}
                onClick={button.onClick}
              />
            ))}
        </Dropdown.Menu>
      </ActionsButtonPortal>
    </Dropdown>
  )
}

export const ActionsButton = forwardRef(ActionsButtonRoot)
