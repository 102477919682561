import React, {useState} from 'react'
import clsx from 'clsx'
import {AxiosError} from 'axios'
import {useMutation} from 'react-query'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import styles from './checkout.module.scss'
import productImg from 'shared/assets/images/product.png'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {CartApi} from 'app/api/cart-api/cart-api'
import {useUserState} from 'app/store/user/state'
import {useCartState} from 'app/store/cart/state'
import {ORDERS_ROUTES} from 'shared/config/routes'
import {IOrder} from 'shared/types/order'
import {thousandFormatter} from 'shared/helpers/formatter'
import {ICart} from 'shared/types/cart'
import {selectAuthUserData} from 'app/store/auth/selects'
import { useWalletsState } from 'app/store/wallets/state'
import { useTemporaryUserState } from 'app/store/temporary-customer/state'
import { useAbilityContext } from 'casl'
import { RouteGuardActions, RouteGuardEntities } from 'casl/ability'

interface ICheckoutData {
  cart: ICart
  userId: number
}

export const Checkout = () => {
  const ability = useAbilityContext()
  const [cart, setCart] = useState<ICart>(null)
  const [userId, setUserId] = useState<number>(null)
  const authUserId = selectAuthUserData()
  const modalState = useStateModalManager(DASHBOARD_MODAL_NAMES.checkout, {
    onBeforeOpen: ({cart, userId}: ICheckoutData) => {
      setCart(cart)
      setUserId(userId)
    },
  })
  const {setCart: setCartInStore} = useCartState()
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const navigate = useNavigate()
  const {getWallets} = useWalletsState()
  const {getWallets:getTemporaryWallets} = useTemporaryUserState()
  const checkoutMutation = useMutation<
    IOrder,
    AxiosError<{error: string}>,
    number
  >(CartApi.checkoutCart, {
    onSuccess: (data: IOrder) => {
      setIsCartHasProducts(false)
      setProductsInCartQuantity(0)
      setCartInStore(null)
      toast.success('Checkout was success!')      
      modalState.close()
      if (userId === authUserId){
        if (ability.can(RouteGuardActions.manage, RouteGuardEntities.CustomerNotAdmin))
          navigate(ORDERS_ROUTES.ORDER.generate(`${data.id}`))
        else {
          navigate(ORDERS_ROUTES.ALL_ORDERS_ORDER.generate(`${data.user_id}`,`${data.id}`))
        }
        getWallets(userId)
      }        
      else{
        getTemporaryWallets(userId)
        navigate(ORDERS_ROUTES.USER_ORDER.generate(`${userId}`, `${data.id}`))        
      }        
    },
    onError: (error) => {
      toast.error(error.response.data.error)
    },
  })

  const handleClose = () => modalState.close()

  const handleCheckoutClick = () => {
    checkoutMutation.mutate(userId)
  }

  const amount = cart
    ? `${cart?.currency?.symbol}${thousandFormatter(cart?.amount)}`
    : '0'
  const vat = cart
    ? `${cart?.currency?.symbol}${thousandFormatter(cart?.vat)}`
    : '0'
  const total = cart
    ? `${cart?.currency?.symbol}${thousandFormatter(cart?.total)}`
    : '0'

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!checkoutMutation.isLoading}
    >
      <div className='p-10'>
        <div className='d-flex justify-content-between align-items-start'>
          <h1 className='mb-5 text-exl-primary'>
            Please confirm your purchase
          </h1>
          <Button
            mode='text'
            type='button'
            className='min-w-auto p-0'
            label={<Icon icon={ICON_COLLECTION.cancel} />}
            onClick={handleClose}
          />
        </div>
        <div className='separator my-2'></div>
        <div className={styles['product-list']}>
          {cart?.codes.map((code) => (
            <div className={styles.item} key={code.hash}>
              <section className={styles.item__section}>
                <img
                  width={54}
                  height={54}
                  src={code.product.image?.original_url || productImg}
                  alt='Product'
                  className={styles.item__img}
                />
                <span className={styles.item__name}>
                  {code.product.name || '-'}
                </span>
              </section>
              <div className={styles.item__info}>
                <section className={styles.item__params}>
                  <p className={styles.item__params__name}>Quantity</p>
                  <p
                    className={styles.item__params__value}
                  >{`${code.quantity}`}</p>
                </section>
                <section className={styles.item__params}>
                  <p className={styles.item__params__name}>Price</p>
                  <p
                    className={styles.item__params__value}
                  >{`${code.product?.currency?.symbol}${code.price}`}</p>
                </section>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.summary__info}>
          <p className={styles['summary__info--item']}>
            <span>Subtotal:</span>
            <span>{amount}</span>
          </p>
          <p
            className={clsx(
              styles['summary__info--vat'],
              styles['summary__info--item']
            )}
          >
            <span>VAT:</span>
            <span>{vat}</span>
          </p>
          <p className={styles['summary__info--item']}>
            <span>Total:</span>
            <span>{total}</span>
          </p>
        </div>
        <ButtonList className='justify-content-end flex-column-reverse flex-sm-row'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={checkoutMutation.isLoading}
          />
          <Button
            label='Confirm'
            mainButton
            isLoading={checkoutMutation.isLoading}
            disabled={checkoutMutation.isLoading}
            onClick={handleCheckoutClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
