import React from 'react';
import imgMoc from "shared/assets/images/placehoder.jpg";
import styles from "./edit-image.module.scss";
import {UploadImage} from "../upload-image/upload-image";
import clsx from "clsx";

interface IEditImageProps {
    url?: string
    className?: string
    setImage: (newPresets: string | null) => void
    onUpdateFile: (fide: File) => void
    onRemove: () => void
}

const EditImageComponent: React.FC<IEditImageProps> = ({url, className, setImage, onUpdateFile, onRemove}) => {
    return (
        <div className={clsx(className, 'position-relative')}>
            <UploadImage setImage={setImage}
                         onRemove={onRemove}
                         mode='top-right'
                         onUpdateFile={onUpdateFile}/>
            <img src={url ? url : imgMoc} alt="original_url"
                 className={styles.hero__image}/>
        </div>
    );
};

export const EditImage = React.memo(EditImageComponent)
