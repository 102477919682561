import React, {Fragment, memo} from 'react';
import {useInView} from "react-intersection-observer";
import styles from "./landing-api.module.scss";
import clsx from "clsx";
import {AnimationWrapper} from "../../../../shared/hoc/animation-wrapper";

interface ManualCardProps {
    isRevers: boolean
    text: string,
    image: string,
    renderProblem?: boolean,
    show?: boolean
}


export const ManualCard: React.FC<ManualCardProps> = memo(({
                                                               renderProblem = false,
                                                               show = true,
                                                               isRevers,
                                                               text,
                                                               image
                                                           }) => {
    const [cardRef, cardInView] = useInView({
        triggerOnce: true,
        threshold: 0,
        delay: 150,
    })
    const Wrapper = renderProblem ? Fragment : AnimationWrapper;
    return (
        <Wrapper show={show}>
            <div className={styles['api-item']}>
                <div
                    className={clsx(styles['api-item__content'], {
                        [styles['api-item__content--reversed']]: isRevers,
                        // [styles['api-item__content--in-view']]: cardInView,
                    })}
                    ref={cardRef}
                >
                    <p className={styles['api-item__text']}>{text}</p>
                    <img src={image} alt='API_Image' className={styles['api-item__image']}/>
                </div>
            </div>
        </Wrapper>
    );
});

