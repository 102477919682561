import clsx from 'clsx';
import React from 'react';
import {usePopper} from 'react-popper';

interface TooltipProps {
    content: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    className?:string
    children: React.ReactNode;    
}

const TooltipComponent: React.FC<TooltipProps> = ({content, placement = 'top', className, children}) => {
    const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 10],
                },
            },
        ],
    });

    return (
        <div
            ref={setReferenceElement}
            onMouseEnter={() => setPopperElement(document.createElement('div'))}
            onMouseLeave={() => setPopperElement(null)}
            className={clsx(className,'cursor-pointer')}
        >

            {children}
            {popperElement && (
                <div ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                    <div style={styles.offset} className='text-white bg-black rounded-2 opacity-75 p-3 w-300px'>
                        {content}
                    </div>
                </div>
            )}
        </div>
    );
};

export const Tooltip = React.memo(TooltipComponent)
