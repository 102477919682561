/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../helpers'
import {useLayout} from '../../core'
import {Navbar} from './Navbar'
import {Icon} from '../../../../shared/components/icon/icon'
import {ICON_COLLECTION} from '../../../../shared/components/icon/icon-list'
import {useSidebarController} from '../../../../shared/hooks/use-sidebar-controller'
import {CompanyInfo} from '../../../../shared/components/widgets/company-info/company-info'
import {TemporaryUserSelect} from '../../../../modules/dashboard/components/temporary-users-select/temporary-user-select'
import {RouteGuardActions, RouteGuardEntities} from '../../../../casl/ability'
import {Can} from '../../../../casl'

export function HeaderWrapper() {
  const {openMobileSidebar} = useSidebarController()
  const {config, classes} = useLayout()
  if (!config.app?.header?.display) {
    return null
  }

  return (
    <div id='kt_app_header' className='app-header justify-content-between'>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
      >
        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <button
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                // id='kt_app_sidebar_mobile_toggle'
                onClick={openMobileSidebar}
              >
                <KTIcon iconName='abstract-14' className=' fs-1' />
              </button>
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 mx-3'>
                <Link to='/' className='d-lg-none'>
                  <Icon icon={ICON_COLLECTION.logo} />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        id='kt_app_header_wrapper'
        className='app-container d-flex align-items-stretch justify-content-end flex-lg-grow-1'
      >
        <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
          <TemporaryUserSelect />
        </Can>
        <Navbar />
        <CompanyInfo />
      </div>
    </div>
  )
}
