import React, {useState} from 'react';
import {useStateModalManager} from "shared/context/modal-manager";
import {DASHBOARD_MODAL_NAMES} from "shared/constants/modal-names";
import {Modal} from "shared/components/modal-base";
import {ButtonList} from "shared/components/button-list/button-list";
import {Button} from "shared/components/button/button";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {CatalogApi} from "app/api/dashboard-api/catalog-api";
import {useNavigate} from "react-router-dom";
import {PRODUCT_ROUTES} from "shared/config/routes";
import {toast} from "react-toastify";

export const ModalRemoveProduct = () => {
    const [productId, setProductId] = useState(NaN);
    const navigate = useNavigate()
    const modalState = useStateModalManager(DASHBOARD_MODAL_NAMES.remove_product, {
        onBeforeOpen: (productId: number) => {
            setProductId(productId)
        }
    });
    const deleteProductMutation = useMutation<
        { name: string },
        AxiosError<{ message: string }>,
        number>(CatalogApi.deleteProduct, {
        onSuccess: (data) => {
            modalState.close()
            toast.success(`${data.name} successfully removed `)
            navigate(PRODUCT_ROUTES.PRODUCT.path)
        },
        onError: (error) => {
            toast.error(error?.response.data.message)
        }
    })
    const handelRemoveProduct = () => {
        if (productId) {
            deleteProductMutation.mutate(productId)
        }
    }
    const handleClose = () => modalState.close()
    return (
        <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!deleteProductMutation.isLoading}>
            <div className="p-5 text-center">
                <p className='text-exl-primary mb-10'>
                    Are you sure you want to remove this product ?
                </p>
                <ButtonList className='justify-content-end'>
                    <Button label='Cancel'
                            onClick={handleClose}
                            disabled={deleteProductMutation.isLoading}
                            isLoading={deleteProductMutation.isLoading}/>
                    <Button label='Remove'
                            mode={'remove'}
                            disabled={deleteProductMutation.isLoading}
                            isLoading={deleteProductMutation.isLoading}
                            onClick={handelRemoveProduct}/>
                </ButtonList>
            </div>

        </Modal.Root>
    );
};

