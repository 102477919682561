import React, {ChangeEvent} from 'react'
import {Button} from '../../../../../shared/components/button/button'
import {DatePickerDialog} from '../../../../../shared/components/day-picker/day-picker'
import {Toggle} from '../../../../../shared/components/toggle/toggle'
import {Loader} from '../../../../../shared/components/loader/loader'
import {Input} from '../../../../../shared/components/input/input'
import {DISCOUNT_ROUTES} from '../../../../../shared/config/routes'
import {useNavigate} from 'react-router-dom'

interface IDiscountHeader {
  value: {
    usersCount: number
    name: string
    isActive: boolean
    start: string
    finish: string
    is_allow_to_sell_loss:boolean
  }
  isLoading?: boolean
  handleToggleIsActive: () => void
  handleToggleIsAllowToSellLess: () => void
  handleDateChangeFormValue: (startDate: string, endDate: string) => void
  handleChangeName: (event: ChangeEvent<HTMLInputElement>) => void
  onOpenUsersList: () => void
}

const DiscountHeaderComponent: React.FC<IDiscountHeader> = (props) => {
  const {
    value,
    isLoading,
    handleToggleIsActive,
    handleToggleIsAllowToSellLess,
    handleDateChangeFormValue,
    handleChangeName,
    onOpenUsersList,
  } = props
  const navigate = useNavigate()
  const onNavigateToDiscountList = () => {
    navigate(DISCOUNT_ROUTES.ROOT.path)
  }
  if (isLoading) return <Loader />
  return (
    <div className='d-flex flex-wrap align-items-start justify-content-between position-relative'>
      {isLoading && <Loader />}
      <div className='w-100 w-md-50'>
        <div className='d-flex align-items-center'>
          <Button
            label='<'
            type='button'
            className='min-w-25px me-3'
            onClick={onNavigateToDiscountList}
          />
          <Input
            mode='large'
            value={value.name}
            onChange={handleChangeName}
            placeholder='Discount name...'
          />
        </div>
        <p className='mt-5'>{value.usersCount} users</p>
      </div>
      <div className='d-flex flex-wrap align-items-center'>
        <DatePickerDialog
          className='mb-5'
          from={value.start}
          to={value.finish}
          handleDateChangeFormValue={handleDateChangeFormValue}
        />
        <Button label='Users' className='mx-2 mx-sm-5 mb-5' onClick={onOpenUsersList} />
        <div className='d-flex align-items-center me-5 mb-5'>
          <p className='me-3 mb-0'>Active</p>
          <Toggle isActive={value.isActive} setActive={handleToggleIsActive} />
        </div>
        <div className='d-flex align-items-center mb-5'>
          <p className='me-3 mb-0'>Allow to sell at a loss</p>
          <Toggle isActive={value.is_allow_to_sell_loss} setActive={handleToggleIsAllowToSellLess} />
        </div>
      </div>
    </div>
  )
}
export const DiscountHeader = React.memo(DiscountHeaderComponent)
