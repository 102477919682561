import React from 'react'
import CheckBox from '../../../../shared/components/check-box/check-box'
import {IDiscountUser} from '../../../../shared/types/discount-types'
import {Loader} from '../../../../shared/components/loader/loader'

interface IDiscountUsersTableProps {
  users: IDiscountUser[]
  onChangeCheckbox: (id: number) => void
  isLoading?: boolean
}

const DiscountUsersTableComponent: React.FC<IDiscountUsersTableProps> = ({
  users,
  onChangeCheckbox,
  isLoading,
}) => {
  const handelChangeCheckbox = (id: number) => () => onChangeCheckbox(id)

  return (
    <div className='card-body py-3 px-0 table-responsive position-relative'>
      {isLoading && <Loader mode='blur' />}
      <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
        {users && users.length ? (
          <tbody>
            {users.map((user) => (
              <tr className='border-bottom ' key={user.id}>
                <td>
                  <CheckBox
                    onChange={handelChangeCheckbox(user.id)}
                    checked={user.checked}
                  />
                </td>
                <td className='min-w-150px text-end'>
                  {user?.customer ? user.customer.company_name : 'no name'}
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr className='border-bottom text-center text-muted'>
              <td colSpan={8}>Nothing was found</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}
export const DiscountUsersTable = React.memo(DiscountUsersTableComponent)
