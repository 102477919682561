import React from 'react'
import {useAllWalletsPage} from './use-all-wallets-page'
import {AllWalletsSearchBar} from '../components/all-wallets-search-bar'
import {AllWalletsTable} from '../components/table/all-wallets-table'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import { Button } from 'shared/components/button/button'

export const AllWalletsPage = () => {
  const { models, commands } = useAllWalletsPage()
  const searchData = {
    desc: 1,
    asc: 2,
  }
  return (
    <>
      <div className='d-flex justify-content-between align-items-sm-center mb-5 flex-column flex-wrap flex-sm-row'>
        <h1 className='text-exl-primary bold'>All Wallets</h1>
        <Button
          label='Export CSV'
          type='button'
          isLoading={models.isCSVLoading}
          disabled={models.isCSVLoading}
          onClick={commands.onDownloadCSV}
        />
      </div>
      <div className='card p-7'>
        <AllWalletsSearchBar
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
          currencyValues={models.optionsCurrencies}
          isLoadingCurrencies={models.isLoadingCurrencies}
          currencyValue={models.values.currency}
          amountOrderValue={searchData[models.values.amount_order] || null}
          addedOrderValue={searchData[models.values.added_order] || null}
          spentOrderValue={searchData[models.values.spent_order] || null}
          creditOrderValue={searchData[models.values.credit_order] || null}
          handleChangeFormValue={commands.handleChangeFormValue}
          handleChangeCurrencyValue={commands.handleChangeCurrencyValue}
        />
        <AllWalletsTable
          wallets={models.wallets}
          isLoading={models.isTableLoading}
        />
        {models.wallets && models.wallets.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
    </>
  )
}
