import { FormikProps, useFormik } from "formik"
import { useAccount } from "modules/dashboard/account/hooks/use-account"
import React, { useState } from "react"
import { ICustomer } from "shared/types/user"
import { accountSchema } from "modules/dashboard/account/utils/account-main-schema"
import { CustomerLayout } from "../../layouts/customer-layout"
import { AccountFormInner } from "modules/dashboard/account/components/forms/account-form-inner"

export const CustomerMain = () => {  

  const [isEditing, setIsEditing] = useState(false)
  const {updateCustomerMutation, initialValues} = useAccount();

    const toggleEditMode = () => {        
        formik.resetForm()
        setIsEditing((prevValue) => !prevValue)        
    }

    const formik: FormikProps<ICustomer> = useFormik({
        initialValues,
        validationSchema: accountSchema,
        enableReinitialize:true,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            try {
                updateCustomerMutation.mutate(values);             
                setIsEditing(false)             
            } catch (error) {
                setStatus('Something is went wrong! Try again later.')
                setSubmitting(false)
            }
        },
    })

    return (
        <CustomerLayout formik={formik} isEditing={isEditing} isEditable  toggleEditMode={toggleEditMode}>
            <AccountFormInner formik={formik} isEditing={isEditing}/>            
        </CustomerLayout>
    )
}