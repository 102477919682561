import {IOrder} from 'shared/types/order'
import {BaseHttpServices} from '../../../shared/services/base-http-services'
import {IMeta} from 'shared/types/api-types/api-types'
import {ICart} from 'shared/types/cart'

export interface IOrderList {
  data: IOrder[]
  meta: IMeta
}

interface IOrdersParams {
  number?: string | number
  start_date?: string | Date
  end_date?: string | Date
  status?: string | number
  method?: string | number
  page?: string | number
}

export interface IReorder {
  userId: number
  orderId: number
}

export class OrderApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getAllOrders = async (params: IOrdersParams | null): Promise<IOrderList> => {
    const payload = await this.http.get(`/orders`, {
      params: params,
    })
    return payload.data
  }

  getOrdersByUser = async (
    userId: number,
    params: IOrdersParams | null
  ): Promise<IOrderList> => {
    const payload = await this.http.get(`/users/${userId}/orders`, {
      params: params,
    })
    return payload.data
  }

  getOrderByUser = async (userId: number, orderId: number): Promise<IOrder> => {
    const payload = await this.http.get(`/users/${userId}/orders/${orderId}`)
    return payload.data.data
  }

  getCodesCSV = async (
    userId: number,
    orderId: number,
    onlyNew: boolean,
    hash?: string
  ): Promise<string> => {
    const payload = await this.http.get(
      `/users/${userId}/orders/${orderId}/download${hash?`/${hash}`:''}`,
      {
        params:{          
          only_new:onlyNew
        },
        responseType: 'blob',
      }
    )
    return payload.data
  }

  getInvoice = async (userId: number, orderId: number): Promise<string> => {
    const payload = await this.http.get(
      `/users/${userId}/orders/${orderId}/pdf`,
      {
        responseType: 'blob',
      }
    )
    return payload.data
  }

  reorderByUser = async ({userId, orderId}: IReorder): Promise<ICart> => {
    const payload = await this.http.post(
      `/users/${userId}/orders/${orderId}/reorder`
    )
    return payload.data.data
  }

  cancelPreorder = async (orderId: number): Promise<IOrder> => {
    const payload = await this.http.post(
      `/preorders/cancel_preorder/${orderId}`
    )
    return payload.data.data
  }
}

export const OrderApi = new OrderApiService(new BaseHttpServices())
