import React, {useEffect, useMemo, useState} from 'react'
import {SelectCreatable} from 'shared/components/select/creatable-select'
import {Input} from 'shared/components/input/input'
import {UploadMethods} from './upload-methods/upload-methods'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {DASHBOARD_ROUTES} from 'shared/config/routes'
import {FormikProps} from 'formik/dist/types'
import {ICodesUpload} from 'shared/types/codes-upload'
import {
  MutationConfig,
  useSelectController,
} from '../../../../shared/hooks/use-select-controller'
import {
  CODES_QUERY_KEYS,
  ERROR_CODES_QUERY_KEYS,
  UPLOAD_HISTORY_QUERY_KEYS,
} from '../../../../shared/constants/query-keys'
import {CodesApi} from '../../../../app/api/dashboard-api/codes-api'
import {SelectsApi} from '../../../../app/api/settings-api/selects-api'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {Select} from 'shared/components/select/select'
import {useNavigate} from 'react-router-dom'
import {decimalFormatter} from 'shared/helpers/decimalFormatter'

interface ICodesForm {
  formik: FormikProps<ICodesUpload>
  isActiveText: boolean
  isActiveUpload: boolean
  setText: () => void
  setUpload: () => void
  onUpload: (files: File[]) => void
  amountCounter: number
  imagesCounter: number
  isLoading: boolean
  setMinPreOrderPrice: (minPreOrderPrice: number) => void
  setMaxPurchasePrice: (maxPurchasePrice: number) => void
}

const CodesFormComponent: React.FC<ICodesForm> = (props) => {
  const {
    formik,
    isActiveText,
    isActiveUpload,
    setText,
    setUpload,
    onUpload,
    amountCounter,
    imagesCounter,
    isLoading,
    setMinPreOrderPrice,
    setMaxPurchasePrice,
  } = props
  const [productOption, setProductOption] = useState(null)
  const [supplierOption, setSupplierOption] = useState(null)
  const [purchaseOption, setPurchaseOption] = useState(null)

  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const createSupplierMutation = useMutation({
    mutationFn: CodesApi.setSupplier,
    onSuccess: (data) => {
      formik.setFieldValue('supplier_name', data.data.name)
      formik.setFieldValue('supplier_id', data.data.id)
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error?.response.data.message)
    },
  })
  const createProductsMutation = useMutation({
    mutationFn: CodesApi.setProducts,
    onSuccess: async (data) => {
      formik.setFieldValue('product_id', data.data.id)
      formik.setFieldValue('product_name', data.data.name)
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error?.response.data.message)
    },
  })
  const {
    options: optionsPurchases,
    isLoading: isLoadingPurchases,
    handleInputChange: handleInputChangePurchases,
    formationOptions: optionsPurchasesMemo,
    isGettingUnknownItems: isGettingUnknownPurchases,
    getUnknownItem: getUnknownPurchase,
  } = useSelectController({
    key: CODES_QUERY_KEYS.purchases,
    Fn: SelectsApi.getPurchases,
    getUnkownFn:SelectsApi.getUnknownPurchase,
    format: 'purchasesOptions',
  })

  const {
    options: optionsProducts,
    isLoading: isLoadingProduct,
    handleInputChange,
    isGettingUnknownItems: isGettingUnknownProducts,
    getUnknownItem: getUnknownProduct,
    formationOptions: productsOptionsMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.product,
    Fn: SelectsApi.getProducts,
    params: 'name',
    format: 'productsOptions',
  })
  const {
    options: currecniesOptions,
    isLoading: isLoadingCurrencies,
    handleInputChange: handleInputChangeCurrencies,
    formationOptions: optionsCurrenciesMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
    format: 'currencyOptions',
  })
  const currentCurrency = currecniesOptions.find(
    (cur) => formik.values.currency_id === cur.id
  )
  const currencySymbol = currentCurrency?.symbol

  const {
    options: optionsSuppliers,
    isLoading: isLoadingSuppliers,
    handleInputChange: handleInputChangeSuppliers,
    isGettingUnknownItems: isGettingUnknownSuppliers,
    getUnknownItem: getUnknownSupplier,
    formationOptions: optionsSuppliersMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    getUnkownFn:SelectsApi.getUnknownSupplier,
    params: 'name',
  })

  const currencyValue = useMemo(() => {
    if (!optionsCurrenciesMemo || !optionsCurrenciesMemo.length) return null
    return (
      optionsCurrenciesMemo.find(
        (currency) => currency.id === formik.values.currency_id
      ) || null
    )
  }, [optionsCurrenciesMemo, formik.values.currency_id])

  useEffect(() => {
    if (!productsOptionsMemo.length) return setProductOption(null)
    const productValue = optionsProducts.find(
      (product) => product.id === formik.values.product_id
    )
    if (productValue) {
      const productValuePrice = productValue.general_price
        ? productValue.general_price
        : productValue.sell_price

      formik.setFieldValue('sell_price', productValuePrice)
      const productValueFormated = MutationConfig['productsOptions']([
        productValue,
      ])[0]
      return setProductOption(productValueFormated)
    }
    if (formik.values.product_id && formik.values.product_name) {
      const product = {
        label: formik.values.product_name,
        id: formik.values.product_id,
      }
      ;(async () => {
        const unknownProduct = await getUnknownProduct(
          formik.values.product_name.split('|')[0].trim(),
          formik.values.product_id
        )

        const price =
          unknownProduct && unknownProduct.general_price
            ? unknownProduct?.general_price
            : unknownProduct?.sell_price

        setMaxPurchasePrice(Number(unknownProduct?.max_purchase_price))
        setMinPreOrderPrice(Number(unknownProduct?.min_pre_order_price))
        formik.setFieldValue('sell_price', price)
        optionsProducts.unshift(unknownProduct)
        productsOptionsMemo.unshift(product)
      })()
      return setProductOption(product)
    }
    if (!productValue && formik.values.product_name) {
      ;(async () => {
        const unknownProduct = await getUnknownProduct(
          formik.values.product_name,
          formik.values.product_id
        )

        const prod = MutationConfig['productsOptions']([unknownProduct])[0]
        const price =
          unknownProduct && unknownProduct.general_price
            ? unknownProduct?.general_price
            : unknownProduct?.sell_price
        setMaxPurchasePrice(Number(unknownProduct?.max_purchase_price))
        setMinPreOrderPrice(Number(unknownProduct?.min_pre_order_price))
        formik.setFieldValue('sell_price', price)
        optionsProducts.unshift(unknownProduct)
        productsOptionsMemo.unshift(prod)
        return setProductOption(prod)
      })()
    }
    if (!productValue) return setProductOption(null)
  }, [
    productsOptionsMemo,
    formik.values.product_id,
    formik.values.product_name,
  ])

  useEffect(() => {
    if (!optionsSuppliersMemo.length) return setSupplierOption(null)
    const supplierValue = optionsSuppliers.find(
      (supplier) => supplier.id === formik.values.supplier_id
    )
    if (supplierValue) {
      const sup = MutationConfig['defaultOptions']([supplierValue])[0]
      return setSupplierOption(sup)
    }    
    if (!supplierValue && formik.values.supplier_id) {
      ;(async () => {
        const unknownSupplier = await getUnknownSupplier(
          `${formik.values.supplier_id}`,
          formik.values.supplier_id
        )
        const sup = MutationConfig['defaultOptions']([unknownSupplier])[0]
        optionsSuppliers.unshift(unknownSupplier)
        optionsSuppliersMemo.unshift(sup)
        return setSupplierOption(sup)
      })()
    }
    if (!supplierValue) return setSupplierOption(null)
  }, [
    optionsSuppliersMemo,
    formik.values.supplier_id,
  ])

  useEffect(() => {
    if (!optionsPurchasesMemo.length) return setPurchaseOption(null)
    const purchaseValue = optionsPurchases.find(
      (purchase) => purchase.id === formik.values.purchase_id
    )    
    if (purchaseValue) {
      const purch = MutationConfig['purchasesOptions']([purchaseValue])[0]
      return setPurchaseOption(purch.id)
    }
    if (!purchaseValue && formik.values.purchase_id) {
      ;(async () => {
        const unknownPurchase = await getUnknownPurchase(
          `${formik.values.purchase_id}`,
          formik.values.purchase_id
        )        
        const purch = MutationConfig['purchasesOptions']([unknownPurchase])[0]
        optionsPurchases.unshift(unknownPurchase)
        optionsPurchasesMemo.unshift(purch)
        return setPurchaseOption(purch.id)
      })()
    }
    if (!purchaseValue) return setPurchaseOption(null)
  }, [
    optionsPurchasesMemo,
    formik.values.purchase_id
  ])

  const onCreateSupplier = async(name: string) => {    
    createSupplierMutation.mutate(name)
  }
  const onCreateProduct = (name: string) => {
    createProductsMutation.mutate({name: name})
  }
  const onChange = (fieldName, option) => {
    formik.setFieldValue(fieldName, option ? option?.id : null)
  }
  const onChangeProduct = (option) => {
    formik.setFieldValue('product_id', option ? option?.id : null)
    formik.setFieldValue('product_name', option ? option?.label : null)
    if (option) {
      const currentProduct = optionsProducts.find(
        (product) => product.id === option?.id
      )
      if (currentProduct) {
        const price = currentProduct.general_price
          ? currentProduct.general_price
          : currentProduct.sell_price
        formik.setFieldValue(
          'purchase_price',
          currentProduct.purchase_price || null
        )
        formik.setFieldValue('sell_price', price)
        formik.setFieldValue(
          'currency_id',
          currentProduct.currency ? currentProduct.currency.id : null
        )
        setMaxPurchasePrice(Number(currentProduct.max_purchase_price))
        setMinPreOrderPrice(Number(currentProduct.min_pre_order_price))
      }
    } else {
      formik.setFieldValue('purchase_price', null)
      formik.setFieldValue('sell_price', null)
      formik.setFieldValue('currency_id', null)
    }
  }

  const onCancel = () => {
    queryClient.invalidateQueries(
      [
        CODES_QUERY_KEYS.codes,
        UPLOAD_HISTORY_QUERY_KEYS.codes,
        ERROR_CODES_QUERY_KEYS.codes,
      ],
      {exact: false}
    )
    navigate(DASHBOARD_ROUTES.CODES.path)
  }

  return (
    <form onSubmit={formik.handleSubmit} encType='multipart/form-data'>
      <h3 className='text-exl-primary mb-5'>Main Information</h3>
      <label>Product</label>
      <SelectCreatable
        options={productsOptionsMemo}
        handleInputChange={handleInputChange}
        isLoading={
          isLoadingProduct ||
          createProductsMutation.isLoading ||
          isGettingUnknownProducts
        }
        className='w-sm-300px mb-5'
        placeholder='Product'
        value={productOption}
        error={
          formik.touched.product_id &&
          !!formik.errors.product_id &&
          !formik.values.product_id
        }
        errorText={
          formik.touched.product_id &&
          !formik.values.product_id &&
          formik.errors.product_id
        }
        onChange={(option) => onChangeProduct(option)}
        onCreate={(name) => onCreateProduct(name)}
      />
      <label>Supplier</label>
      <SelectCreatable
        options={optionsSuppliersMemo}
        isLoading={
          isLoadingSuppliers ||
          createSupplierMutation.isLoading ||
          isGettingUnknownSuppliers
        }
        handleInputChange={handleInputChangeSuppliers}
        value={supplierOption}
        className='mb-5 w-sm-300px'
        placeholder='Supplier'
        error={formik.touched.supplier_id && !!formik.errors.supplier_id}
        errorText={formik.touched.supplier_id && formik.errors.supplier_id}
        onChange={(option) => onChange('supplier_id', option)}
        onCreate={onCreateSupplier}
      />
      <label>Purchase</label>
      <Select
        isClearable
        value={purchaseOption}
        handleInputChange={handleInputChangePurchases}
        className='mb-5 w-sm-300px'
        placeholder='Purchase ID'
        options={optionsPurchasesMemo}
        isLoading={isLoadingPurchases || isGettingUnknownPurchases}
        error={formik.touched.purchase_id && !!formik.errors.purchase_id}
        errorText={formik.touched.purchase_id && formik.errors.purchase_id}
        onChange={(option) => onChange('purchase_id', option)}
      />

      <div className='d-flex flex-wrap my-5'>
        <div className='d-flex flex-column mw-225px'>
          <label>Purchase Price</label>
          <Input
            className='w-sm-225px '
            isPriceInput
            type='number'
            {...formik.getFieldProps('purchase_price')}
            value={formik.values.purchase_price}
            name='purchase_price'
            currency={currencySymbol}
            error={
              formik.touched.purchase_price && !!formik.errors.purchase_price
            }
            errorText={
              formik.touched.purchase_price && formik.errors.purchase_price
            }
            onChange={(e) => {
              formik.setFieldValue(
                'purchase_price',
                e.target.value === '' ? '' : decimalFormatter(e.target.value)
              )
            }}
          />
        </div>
        <div className='d-flex flex-column mx-sm-3 mt-3 mt-sm-0 mw-225px'>
          <label>Selling Price</label>
          <Input
            className='w-sm-225px'
            type='number'
            isPriceInput
            {...formik.getFieldProps('sell_price')}
            value={formik.values.sell_price}
            name='sell_price'
            currency={currencySymbol}
            error={
              (formik.touched.sell_price && !!formik.errors.sell_price) ||
              (formik.touched.purchase_price && !!formik.errors.sell_price)
            }
            errorText={
              (formik.touched.sell_price && formik.errors.sell_price) ||
              (formik.touched.purchase_price && formik.errors.sell_price)
            }
            onChange={(e) => {
              formik.setFieldValue(
                'sell_price',
                e.target.value === '' ? '' : decimalFormatter(e.target.value)
              )
            }}
          />
        </div>
        <div className='d-flex flex-column mt-md-0 mt-3 me-3 mw-225px'>
          <label>Currency</label>
          <SelectCreatable
            options={optionsCurrenciesMemo}
            handleInputChange={handleInputChangeCurrencies}
            isLoading={isLoadingCurrencies}
            value={currencyValue}
            className='w-sm-200px'
            placeholder='Currency'
            error={
              formik.touched.currency_id &&
              !!formik.errors.currency_id &&
              !formik.values.currency_id
            }
            onChange={(option) => onChange('currency_id', option)}
          />
          {formik.touched.currency_id &&
            !formik.values.currency_id &&
            formik.errors.currency_id && (
              <p className='error'>{formik.errors.currency_id}</p>
            )}
        </div>
      </div>
      <p className='text-muted'>
        If general price is used, it will be overwritten.
      </p>
      <h3 className='text-exl-primary mb-5'>Upload Method</h3>

      {formik.status && <p className='error'>{formik.status}</p>}

      <UploadMethods
        formik={formik}
        isActiveText={isActiveText}
        isActiveUpload={isActiveUpload}
        setText={setText}
        amountCounter={amountCounter}
        imagesCounter={imagesCounter}
        setUpload={setUpload}
        onUpload={(files: File[]) => onUpload(files)}
      />
      <ButtonList className='mt-20'>
        <Button
          label='Upload Codes'
          mainButton
          type='submit'
          isLoading={isLoading}
          disabled={isLoading}
        />
        <Button
          label={'Cancel'}
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onCancel}
        />
      </ButtonList>
    </form>
  )
}
export const CodesForm = React.memo(CodesFormComponent)
