import React, {useMemo} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {PurchaseApi} from 'app/api/purchases-api/purchases-api'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {DASHBOARD_ROUTES, ERROR_ROUTE} from 'shared/config/routes'
import {PURCHASES_QUERY_KEYS} from 'shared/constants/query-keys'
import {PurchaseHeader} from '../components/purchase-header'
import {PurchaseProductsTable} from '../components/table/purchase-products-table'
import {AddProductModal} from '../components/modals/add-product-modal'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {EditPurchaseProductModal} from '../components/modals/edit-purchase-product-modal'
import {IPurchase, IPurchaseProduct} from 'shared/types/purchase'
import {ConfirmDeletePurchaseProductModal} from '../components/modals/confirm-delete-purchase-product-modal'
import {useModalManager} from 'shared/context/modal-manager'
import {PURCHASES_MODAL_NAME} from 'shared/constants/modal-names'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export const PurchasePage = () => {
  const navigate = useNavigate()
  const {purchaseId} = useParams()
  const modalManager = useModalManager()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const queryParams = useMemo(() => {
    return {page: search.get('page') ? search.get('page') : '1'}
  }, [location.search])
  const queryClient = useQueryClient()
  const handleGoBackClick = () => {
    navigate(DASHBOARD_ROUTES.PURCHASE_TRACKER.path)
  }
  const {data: purchase, isLoading} = useQuery({
    queryKey: [PURCHASES_QUERY_KEYS.purchase, purchaseId],
    queryFn: async () => await PurchaseApi.getPurchaseById(Number(purchaseId)),
    onError: () => {
      navigate(ERROR_ROUTE.path)
    },
  })
  const {data: products, isLoading: isProductLoading} = useQuery({
    queryKey: [PURCHASES_QUERY_KEYS.purchase_product, purchaseId],
    queryFn: async () =>
      await PurchaseApi.getPurchaseProduct(queryParams, Number(purchaseId)),
    onError: () => {
      navigate(ERROR_ROUTE.path)
    },
  })
  const setCurrentPage = (page: string) => {
    setSearchParams({
      page: page,
    })
  }
  const copyPurchaseMutation = useMutation<
    IPurchase,
    AxiosError<{message: string}>,
    number
  >(PurchaseApi.copyPurchase, {
    onSuccess: (data: IPurchase) => {
      queryClient.invalidateQueries([PURCHASES_QUERY_KEYS.purchases])
      toast.success(`Purchase was copied successfully!`)
    },
    onError: async (error) => {
      toast.error(error.response.data.message)
    },
  })

  const handleCopyClick = (purchaseId:number) =>(event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    copyPurchaseMutation.mutate(purchaseId)
  }

  const handleConfirmDeleteClick = (purchaseId:number,productId:number) => {
    modalManager.open(PURCHASES_MODAL_NAME.confirm_delete_purchase_product,purchaseId, productId)
  }

  const handleEditPurchaseProduct =
    (purchaseProduct: IPurchaseProduct) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      modalManager.open(
        PURCHASES_MODAL_NAME.update_product_in_purchase,
        purchaseProduct
      )
    }

  return (
    <div className='position-relative'>
      <LoadWrapper isLoading={isLoading || isProductLoading}>
        <div className='bg-body rounded shadow-sm p-10 mx-auto overflow-hidden position-relative'>
          <PurchaseHeader
            purchase={purchase}
            isCopyButtonLoading={copyPurchaseMutation.isLoading}
            handleCopyClick={handleCopyClick}
            handleGoBackClick={handleGoBackClick}
          />
          <PurchaseProductsTable
            products={products?.data}
            purchase={purchase}
            handleEditProductFromPurchase={handleEditPurchaseProduct}
            handleConfirmDeleteClick={handleConfirmDeleteClick}
          />
          {products && products?.data.length ? (
            <Pagination
              currentPage={Number(queryParams.page)}
              totalCount={products.meta.total}
              pageSize={products.meta.per_page}
              onPageChange={(page) => setCurrentPage(page)}
            />
          ) : null}
        </div>
      </LoadWrapper>
      <AddProductModal />
      <EditPurchaseProductModal />
      <ConfirmDeletePurchaseProductModal />
    </div>
  )
}