import React from 'react'
import {Input} from 'shared/components/input/input'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {useFormik} from 'formik'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

interface IWalletFormValue {
  amount?: number
}

const initialValues: IWalletFormValue = {
  amount: null,
}

interface IErrorForm {
  amount?: string
}

interface IWithdrawFromParams {
  onSubmit: (amount: string | number) => void
  isLoading: boolean
  onClose: () => void
  currency: string
}

const WithdrawFrom: React.FC<IWithdrawFromParams> = ({
  onSubmit,
  isLoading,
  onClose,
  currency,
}) => {
  const handleSubmit = (values: IWalletFormValue) => {
    onSubmit(values.amount)
  }
  const validate = (values: IWalletFormValue) => {
    const error: IErrorForm = {}
    const amount = decimalFormatter(values.amount)
    if (!amount) {
      error.amount = 'Amount is required field !'
    }
    if (amount >= 0) {
      error.amount = 'Amount should be negative !'
    }
    return error
  }

  const formik = useFormik<IWalletFormValue>({
    initialValues,
    onSubmit: handleSubmit,
    validate,
  })
  const formikSubmit = () => formik.submitForm()
  return (
    <>
      <div className='mb-7'>
        <p>Withdraw</p>
        <Input
          type={'number'}
          mode='number'
          className='w-sm-225px'
          value={formik.values.amount}
          name='amount'
          canBeNegative={true}
          isPriceInput
          currency={currency}
          error={formik.touched.amount && !!formik.errors.amount}
          errorText={formik.touched.amount && formik.errors.amount}
          {...formik.getFieldProps('amount')}
        />
      </div>
      <ButtonList className='justify-content-end'>
        <Button
          label='Cancel'
          onClick={onClose}
          disabled={isLoading}
          isLoading={isLoading}
        />
        <Button
          mainButton
          onClick={formikSubmit}
          label='Apply Changes'
          disabled={isLoading}
          isLoading={isLoading}
        />
      </ButtonList>
    </>
  )
}

export default WithdrawFrom
