import React, { useState } from 'react'
import { offerText } from '../../constants/main'
import {accordionOffer} from '../../constants/main'
import { motion } from 'framer-motion'
import LandingAccordions from './accordions/accordions'
import LandingCards from './image-cards/image-cards'
import LandingImageCard from './image-cards/image-card/image-card'
import styles from './landing-offer.module.scss'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'
import { useQuery } from 'react-query'
import { LANDING_QUERY_KEYS } from 'shared/constants/query-keys'
import { LandingApi } from 'app/api/landing-api/landing-api'
import { toast } from 'react-toastify'

const LandingOffer = () => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(0)

  const handleAccordionClick = (index) => {
    setActiveAccordionIndex(index === activeAccordionIndex ? null : index)
  }

  const {isLoading, data, error} = useQuery({
    queryKey: [LANDING_QUERY_KEYS.sliderOffer],
    queryFn: async () => {
      const slidersData = await LandingApi.getSLiders()
      const sliderHero = slidersData.data.find(
        (slider) => slider.slug === 'slider2'
      )
      return sliderHero.image
    },
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error('Error loading slider images')
    },
  })

  return (
    <section id={LANDING_NAVIGATION.solutions.id} className={styles['offer']}>
      <div className={styles['offer__container']}>
        <div className={styles['offer--text']}>
          <motion.h2
            initial={{y: -50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 0.5, delay: 0.3}}
            className={styles['offer__title']}
          >
            {offerText.title}
          </motion.h2>
          <motion.div
            initial={{y: -50, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            transition={{duration: 0.5, delay: 0.5}}
            className={styles['offer__subtitle']}
          >
            {offerText.subtitle}
          </motion.div>
        </div>
        <div className={styles['accordion-and-cards-container']}>
          <div className={styles['accordion-container']}>
            {accordionOffer.map((accordion, index) => (
              <LandingAccordions
                key={accordion.id}
                index={index}
                accordionOffer={accordion}
                activeIndex={activeAccordionIndex}
                onClick={handleAccordionClick}
              />
            ))}
          </div>
          <div className={styles['card-container']}>
            {isLoading || error ? (
              <LandingCards activeAccordionIndex={activeAccordionIndex}>
                {[...new Array(5)].map((_, i) => (
                  <LandingImageCard key={i} isLoading={isLoading} />
                ))}
              </LandingCards>
            ) : (
              <LandingCards activeAccordionIndex={activeAccordionIndex}>
                {data.map((card, i) => (
                  <LandingImageCard key={i} image={card.original_url} />
                ))}
              </LandingCards>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingOffer
