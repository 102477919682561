import React from 'react';
import {WalletCard} from '../wallet-card/wallet-card'
import clsx from 'clsx'
import {IWallet} from "../../../../../shared/types/api-types/wallets-type";
import {Loader} from "../../../../../shared/components/loader/loader";

interface WalletsListProps {
    className?: string
    wallets: IWallet[]
    isLoading?: boolean
    isUpdating?: boolean
    activeWallet: number,
    setActiveWallet: (id: number) => void
    handelSetDefault: (id: number) => void
}

const WalletsListComponent: React.FC<WalletsListProps> = (props) => {
    const {
        className,
        wallets,
        isLoading,
        isUpdating,
        handelSetDefault,
        setActiveWallet,
        activeWallet
    } = props
    return (
        <div className={clsx("d-flex flex-wrap ", className)}>
            {isLoading && <Loader mode={"blur"}/>}
            {(wallets && wallets.length) ?
                wallets.map(wallet =>
                    <WalletCard key={wallet.id}
                                handelSetDefault = {handelSetDefault}
                                setActiveWallet={setActiveWallet}
                                isActive={activeWallet === wallet.id}
                                wallet={wallet}
                    />) : null

            }
        </div>
    );
};

export const WalletsList = React.memo(WalletsListComponent)
