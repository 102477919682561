import {BaseHttpServices} from '../../../shared/services/base-http-services'
import {IMeta} from 'shared/types/api-types/api-types'
import {ILink} from 'shared/types/base'
import {IInvoice} from 'shared/types/invoice'

export interface IInvoicesList {
  data: IInvoice[]
  meta: IMeta
  links: ILink
}

interface IInvoicesParams {
  number?: string | number
  status?: string | number
  source?: string | number
  start_date?: string
  end_date?: string
  page?: string | number
}

export interface IApproveInvoiceParams {
  user: number
  invoice: number
}

interface IInvoicesPDFsParams {
  search?: string
  status?: number
  type?: number
  source?: number
  start_date?: string
  end_date?: string
}

export interface IDeleteInvoiceParams {
  userId: number
  invoiceId: number
}

export class InvoiceApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getAllInvoices = async (
    params: IInvoicesParams | null
  ): Promise<IInvoicesList> => {
    const payload = await this.http.get(`/invoice`, {
      params: params,
    })
    return payload.data
  }

  getInvoicesByUser = async (
    userId: number,
    params: IInvoicesParams | null
  ): Promise<IInvoicesList> => {
    const payload = await this.http.get(`/users/${userId}/invoice`, {
      params: params,
    })
    return payload.data
  }

  getInvoicePDF = async (
    userId: number,
    invoiceId: number
  ): Promise<string> => {
    const payload = await this.http.get(
      `/users/${userId}/invoice/${invoiceId}/pdf`,
      {
        responseType: 'blob',
      }
    )
    return payload.data
  }

  getInvoicesPDFs = async (
    userId: number,
    selected_models: number[],
    params: IInvoicesPDFsParams | null
  ): Promise<string> => {
    const payload = await this.http.post(
      `/users/${userId}/invoices/pdf`,
      {
        selected_models,
        ...params
      },
      {
        responseType: 'blob',
      }
    )
    return payload.data
  }

  getAdminInvoicesPDFs = async (
    selected_models: number[],
    params: IInvoicesPDFsParams | null
  ): Promise<string> => {
    const payload = await this.http.post(
      '/invoice/pdf',
      {
        selected_models,
        ...params
      },
      {
        responseType: 'blob',
      }
    )
    return payload.data
  }

  approveInvoice = async ({
    user,
    invoice,
  }: IApproveInvoiceParams): Promise<IInvoice> => {
    const payload = await this.http.post(
      `/users/${user}/invoice/${invoice}/approve`
    )
    return payload.data.data
  }

  deleteInvoice = async ({
    userId,
    invoiceId,
  }: IDeleteInvoiceParams): Promise<string> => {
    const payload = await this.http.delete(
      `/users/${userId}/invoice/${invoiceId}`
    )
    return payload.data
  }
}

export const InvoiceApi = new InvoiceApiService(new BaseHttpServices())
