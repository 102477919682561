import React, {ChangeEvent, useState} from 'react'
import { ActionsButton, IActionsButtons } from 'shared/components/button/actions-button'
import {Input} from 'shared/components/input/input'
import {Loader} from 'shared/components/loader/loader'
import {IRegion} from 'shared/types/base'

interface IRegionsTableRow {
  region: IRegion
  isSaveButtonLoading: boolean
  handleSaveChanges: (region: IRegion) => void
  handleDeleteClick: (region: IRegion) => void
}

const RegionsTableRowComponent: React.FC<IRegionsTableRow> = ({
  region,
  isSaveButtonLoading,
  handleSaveChanges,
  handleDeleteClick,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [regionData, setRegionData] = useState<IRegion>(region)
  const handleEditClick = () => {
    setIsEditing(true)
  }
  const handleCancelClick = () => {
    setRegionData(region)
    setIsEditing(false)
  }
  const handleDeleteRegion = () => {
    handleDeleteClick(region)
  }
  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setRegionData((prevData: IRegion) => {
      return {
        ...prevData,
        name: event.target.value,
      }
    })
  }
  const handleSaveChangesClick = () => {
    handleSaveChanges(regionData)
    setIsEditing(false)
  }
  const REGIONS_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: isEditing ? 'Cancel' : 'Edit',
      onClick: isEditing ? handleCancelClick : handleEditClick,
    },
    {
      label: isEditing ? 'Save' : 'Delete',
      onClick: isEditing ? handleSaveChangesClick : handleDeleteRegion,
    },
  ]
  return (
    <tr className='border-bottom'>
      <td>
        <Input
          value={regionData.name}
          disabled={!isEditing}
          readOnly={!isEditing}
          onChange={handleChangeName}
          placeholder='Name'
        />
      </td>
      <td className='d-flex flex-end border-0'>
        <div className='d-flex gap-5 position-relative flex-end w-150px'>
          <ActionsButton classNames='my-2' label='Actions' buttons={REGIONS_ACTION_BUTTONS}/>
          {isSaveButtonLoading && <Loader classNane='bg-white justify-content-end' />}
        </div>
      </td>
    </tr>
  )
}

export const RegionsTableRow = React.memo(RegionsTableRowComponent)
