export const getInvalidPrice = (price: number, purchasePriceList: number[]) => {
  if (!purchasePriceList.length) return []
  const validMess = purchasePriceList.map((group, index) => {
    if (group === price)
      return {purchasePrice: group, newPrice: price, id: index}
    if (group > price) return {purchasePrice: group, newPrice: price, id: index}
    return null
  })
  return validMess.filter((codes) => codes !== null)
}
