import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {SupportApi} from "../../api/support-api/support-api";
import { IMessage } from 'shared/types/api-types/chat-types';
import { IMeta } from 'shared/types/api-types/api-types';


interface IChatState {
    messages: IMessage[] | null;
    isLoading: boolean;
    meta:IMeta
    getMessages: (userId: number,page?:number) => Promise<void>;
    setMessages: (messages: IMessage[] | null) => void;
}

export const useChatState = create<IChatState>()(
    devtools(
        (set) => ({
            messages: [],
            isLoading: false,
            meta:null,
            getMessages: async function (userId,page=1) {
                try {
                    set({isLoading: true});
                    const tempMessagesData = await SupportApi.getMessages(userId,page);
                    set((state)=>{
                        const messages = tempMessagesData.data.sort((a:IMessage, b:IMessage) => a.id - b.id)
                        if (page!==1) {
                            return {messages:[...messages,...state.messages]}
                        }
                        return {messages: messages}
                    })
                    set({meta:tempMessagesData.meta})
                    set({isLoading: false});
                } catch (e) {
                    set({isLoading: false});
                }
            },
            setMessages(messages: IMessage[] | null) {
                set({messages});
            }
        }),
        {name: 'useChatState', store: 'useChatState'},
    ),
);
