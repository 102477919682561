/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '_metronic/assets/ts/_utils'
import {useThemeMode} from '_metronic/partials'
import clsx from 'clsx'
import {Loader} from 'shared/components/loader/loader'
import {Button} from 'shared/components/button/button'
import {PeriodsType} from 'shared/types/api-types/statistic-type'
import { thousandFormatter } from 'shared/helpers/formatter'

type Series = {
  name: string
  data: number[]
}

interface BarChartProps {
  className?: string
  names: any[]
  title?: string
  values: Series[]
  handelChangePeriod?: (period: PeriodsType) => void
  period?: PeriodsType
  isLoading?: boolean
  onOpenFilters?: () => void
  onDownload?: () => void
  isYAxisInteger?: boolean
  isPriceChart?: boolean
  beforeValue?: string
  beforeParams?: string
}

const BarChart: React.FC<BarChartProps> = (props) => {
  const {
    className,
    title = '',
    names,
    values,
    handelChangePeriod,
    period,
    isLoading,
    onOpenFilters,
    onDownload,
    isYAxisInteger = false,
    isPriceChart = false,
    beforeValue,
    beforeParams,
  } = props
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        names,
        values,
        isYAxisInteger,
        isPriceChart,
        beforeValue,
        beforeParams
      )
    )
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, values])

  return (
    <div className={`card ${className} shadow mt-5 position-relative`}>
      {isLoading && <Loader mode='blur' />}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
        </h3>
        {names.length ? (
          <div className='card-toolbar' data-kt-buttons='true'>
            <Button label={'Download CSV'} mode='text' onClick={onDownload} />
            <button
              onClick={() => handelChangePeriod(1)}
              className={clsx(
                'btn btn-sm btn-color-muted btn-active  btn-active-exl-primary  px-4 me-1',
                {
                  'active text-white': period === 1,
                }
              )}
            >
              Day
            </button>

            <button
              className={clsx(
                'btn btn-sm btn-color-muted btn-active  btn-active-exl-primary  px-4 me-1',
                {
                  'active text-white': period === 2,
                }
              )}
              onClick={() => handelChangePeriod(2)}
            >
              Week
            </button>

            <button
              className={clsx(
                'btn btn-sm btn-color-muted btn-active  btn-active-exl-primary  px-4 me-1 ',
                {
                  'active text-white': period === 3,
                }
              )}
              onClick={() => handelChangePeriod(3)}
            >
              Month
            </button>
            {onOpenFilters && (
              <Button
                label={'Filter'}
                mode='small'
                className='ms-3'
                onClick={onOpenFilters}
              />
            )}
          </div>
        ): ''}
      </div>

      <div className='card-body'>
        {names.length ? (
          <div
            ref={chartRef}
            id='kt_charts_widget_2_chart'
            style={{height: '350px'}}
          ></div>
        ) : (
          <span>Nothing was found</span>
        )}
      </div>
    </div>
  )
}

export {BarChart}

function getChartOptions(
  height: number,
  names: any,
  values: Series[],
  isYAxisInteger?: boolean,
  isPriceChart?: boolean,
  beforeValue?: string,
  beforeParams?: string,
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  const yAxisFormatter =
    isYAxisInteger === true
      ? function (val) {
          return `${beforeParams || ''}${thousandFormatter(Math.round(val))}`
        }
      : function (val) {
          return val.toFixed(2)
        }

  return {
    series: values,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: names,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: yAxisFormatter,
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          if (isPriceChart) {
            return `${beforeValue || ''}${thousandFormatter(val.toFixed(2))}`
          } else {
            return `${beforeValue || ''}${thousandFormatter(val)}`
          }
        },
      },
    },
    colors: ['#1B2561', '#1B2561'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
