import { ACCEPT_TYPES_FOR_IMAGES } from 'shared/constants/constants'
import * as Yup from 'yup'
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name field is required !'),
    image: Yup.mixed<File>().required('Image is required !')
      .test('fileFormat', 'Unsupported file(s) format', (value) => {
        if (!value) return false
        return ACCEPT_TYPES_FOR_IMAGES.includes(value.type)
      })
      .test('fileSize', 'File(s) size must be less than 1 GB', (value) => {
        if (!value) return false
        return value.size <= 1024 * 1024 * 1024 // 1 GB in Bytes
      }),
  })