import React, {useEffect, useState} from 'react'
import {useFormik, FormikProps} from 'formik'
import { AccountLayout } from '../../account-layout'
import { ICustomer } from 'shared/types/user'
import { AccountFormFiles } from '../../components/forms/account-form-files'
import { useAccount } from 'modules/dashboard/account/hooks/use-account'
import { filesSchema } from '../../utils/account-file-schema'

export const AccountFilesPage = () => {
  const [isEditing, setIsEditing] = useState(false)
  
  const toggleEditMode = () =>{
    formik.resetForm()
    setIsEditing((prevValue) => !prevValue)
  }

  const {updateCustomerMutation, initialValues} = useAccount(); 

  const handleChangeFiles = (files:File[]) => {           
      formik.setFieldValue('documents',files);     
  }    
 
  const formik: FormikProps<ICustomer> = useFormik({
    initialValues,
    validationSchema: filesSchema,
    enableReinitialize:true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        updateCustomerMutation.mutate(values);
        setIsEditing(false)
      } catch (error) {
        console.error(error)
        setStatus('Something is went wrong! Try again later.')
        setSubmitting(false)
      }
    },
  })  

  useEffect(()=>{
    if (formik.values.documents && formik.values.documents.length && !formik.errors.documents) {
      formik.submitForm();
    }
  },[formik.values.documents, formik.errors.documents])

  return (
    <AccountLayout formik={formik} isEditing={isEditing} toggleEditMode={toggleEditMode}>
      <AccountFormFiles formik={formik} isEditable isLoading={updateCustomerMutation.isLoading} handleChangeFiles={handleChangeFiles}/>
    </AccountLayout>
  )
}


