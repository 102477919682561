import {IReorder} from 'app/api/order-api/order-api'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ActionsButton,
  IActionsButtons,
} from 'shared/components/button/actions-button'
import {ORDERS_ROUTES} from 'shared/config/routes'
import {ORDER_METHOD, ORDER_STATUS} from 'shared/constants/constants'
import {IOrder} from 'shared/types/order'
import { CurrencyFormatter } from 'shared/helpers/currency-formatter'
import {CHAT_TEMPLATE_MESSAGES_EVENT, EventBus} from 'shared/utils/event-bus'

interface IOrdersTableRow {
  order: IOrder
  isReorderLoading: boolean
  isPreorderCancelling: boolean
  preorderIdForLoader: number
  orderIdForLoader: number
  handleReorderAction: ({userId, orderId}: IReorder) => void
  handleCancelPreorderAction: (orderId: number) => void
}

export const STATUS_COLOR = {
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'primary',
  5: 'warning',
  6: 'warning',
}

const DateFormatter = (date: Date): string => {
  const orderDate = new Date(date)
  const orderLocaleDate = orderDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const orderLocaleTime = orderDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })
  return orderDate < new Date()
    ? orderLocaleDate + '\n' + orderLocaleTime
    : `Planning ${orderLocaleDate + '\n' + orderLocaleTime}`
}

const OrdersTableRowComponent: React.FC<IOrdersTableRow> = ({
  order,
  isReorderLoading,
  isPreorderCancelling,
  orderIdForLoader,
  preorderIdForLoader,
  handleReorderAction,
  handleCancelPreorderAction,
}) => {
  const navigate = useNavigate()
  const handleViewClick = () => {
    navigate(ORDERS_ROUTES.ORDER.generate(order.id.toString()))
  }
  const handleCancelClick = () => {
    handleCancelPreorderAction(order.id)
  }
  const handleReorderClick = () => {
    handleReorderAction({userId: Number(order.user_id), orderId: order.id})
  }
  const handleChatClick = () => {
    EventBus.emit(
      CHAT_TEMPLATE_MESSAGES_EVENT.template_from_order,
      order.number
    )
  }

  const isPreOrder =
    order.status === 4 || order.status === 5 || order.status === 6

  const ORDERS_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'View Order',
      onClick: handleViewClick,
    },
    {
      label: isPreOrder ? 'Cancel' : 'Reorder',
      isLoading:
        isPreOrder
          ? isPreorderCancelling && order.id === preorderIdForLoader
          : isReorderLoading && order.id === orderIdForLoader,
      onClick: isPreOrder ? handleCancelClick : handleReorderClick,
    },
    {
      label: 'Support',
      id: 'kt_drawer_chat_toggle',
      onClick: handleChatClick,
    },
  ]
  return (
    <tr className='border-bottom'>
      <td className='min-w-100px' style={{whiteSpace: 'pre'}}>
        {DateFormatter(order.created_at)}
      </td>
      <td>{order.number}</td>
      <td>{ORDER_METHOD[order.method]}</td>
      <td>{CurrencyFormatter(order.total,order.currency)}</td>
      <td>
        <span className={`badge badge-light-${STATUS_COLOR[order.status]}`}>
          {ORDER_STATUS[order.status]}
        </span>
      </td>
      <td>
        <ActionsButton label='Actions' buttons={ORDERS_ACTION_BUTTONS} />
      </td>
    </tr>
  )
}

export const OrdersTableRow = React.memo(OrdersTableRowComponent)
