import React, {ReactNode, useEffect, useState} from 'react';
import {Loader} from "../loader/loader";

interface ILoadWrapper {
    isLoading: boolean
    children: ReactNode
    className?: string
}
const LoadWrapperComponent: React.FC<ILoadWrapper> = ({isLoading, children, className}) => {    
    return (
        <div className='position-relative'>
            {(isLoading) ? <Loader classNane={className}/> : children}
        </div>
    );
};
export const LoadWrapper = React.memo(LoadWrapperComponent)
