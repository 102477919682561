import {useMemo} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {toast} from 'react-toastify'
import {useModalManager} from 'shared/context/modal-manager'
import {IRegionsList, OptionsApi} from 'app/api/options-api/options-api'
import {IRegion} from 'shared/types/base'
import {AxiosError} from 'axios'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'

export const useRegionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const queryClient = useQueryClient()
  const modalManager = useModalManager()

  const queryParams = useMemo(() => {
    return {
      page: search.get('page') ? Number(search.get('page')) : 1,
    }
  }, [location.search])

  const regionsQuery = useQuery({
    queryKey: [OPTIONS_QUERY_KEYS.regions, queryParams],
    queryFn: async () => await OptionsApi.getRegions(queryParams),
    refetchOnWindowFocus: false,
  })

  const updateRegionMutation = useMutation<IRegion, AxiosError<{message: string}>, IRegion>(
    OptionsApi.updateRegion,
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          [OPTIONS_QUERY_KEYS.regions, queryParams],
          (prevData: IRegionsList) => {
            const regionIndex = prevData.data.findIndex((region) => region.id === data.id)
            prevData.data.splice(regionIndex, 1, data)
            return prevData
          }
        )
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.regions, queryParams])
        toast.success('Region updated successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleUpdateClick = (region: IRegion) => {
    updateRegionMutation.mutate(region)
  }

  const handleCreateClick = () => {
    modalManager.open(OPTIONS_MODAL_NAME.create_region)
  }

  const handleDeleteClick = (region: IRegion) => {
    modalManager.open(OPTIONS_MODAL_NAME.delete_region, region)
  }

  const setCurrentPage = (page: string) => {
    setSearchParams({
      page,
    })
  }

  const isSaveButtonLoading = updateRegionMutation.isLoading

  const isTableLoading = regionsQuery.isLoading

  return {
    models: {
      values: queryParams,
      regions: regionsQuery.data ? regionsQuery.data.data : [],
      meta: regionsQuery.data ? regionsQuery.data.meta : null,
      isTableLoading,
      isSaveButtonLoading,
    },
    commands: {
      setCurrentPage,
      handleUpdateClick,
      handleDeleteClick,
      handleCreateClick
    },
  }
}
