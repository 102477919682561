/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useQuery} from 'react-query'
import {useParams} from 'react-router-dom'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {selectIsGettingMessages, selectMessages} from 'app/store/chat/selects'
import {ChatInner} from '../../chat/ChatInner'
import {IMessage} from 'shared/types/api-types/chat-types'
import {useChatState} from 'app/store/chat/state'
import {CUSTOMER_QUERY_KEYS} from 'shared/constants/query-keys'
import {CustomerApi} from 'app/api/user-api/customer-api'
import {UserApi} from 'app/api/user-api/user-api'
import {Button} from 'shared/components/button/button'
import {selectUserData} from 'app/store/user/selects'
import {Can} from 'casl'
import {RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import clsx from 'clsx'

const DrawerMessenger: FC = () => {
  const {customerId} = useParams()
  const user = selectUserData()
  const {getMessages} = useChatState()
  const messages: IMessage[] = selectMessages()
  const isLoading = selectIsGettingMessages()

  const {data: customer} = useQuery({
    queryKey: [
      CUSTOMER_QUERY_KEYS.customer,
      Number.parseInt(customerId) || user.id,
    ],
    queryFn: async () => {
      const customerData = await CustomerApi.getCustomerById(
        Number.parseInt(customerId) || user.id
      )
      const userData = await UserApi.getUserById(customerId || user.id)
      return {
        ...customerData,
        email: userData.email,
        is_blocked: userData.is_blocked,
        is_api_blocked: userData.is_api_blocked,
        is_invoice_email_enabled: userData.is_invoice_email_enabled,
        is_order_email_enabled: userData.is_order_email_enabled,
      }
    },
    refetchOnWindowFocus: false,
  })

  const handleUpdateChat = () => {
    getMessages(customer?.user_id || user.id)
  }

  useEffect(() => {
    getMessages(customer?.user_id || user.id)
  }, [customer, user])

  return (
    <div
      id='kt_drawer_chat'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='chat'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'300px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_chat_toggle'
      data-kt-drawer-close='#kt_drawer_chat_close'
    >
      <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div className={clsx('card-header pe-5',{'justify-content-end':customer?.user_id===user.id})} id='kt_drawer_chat_messenger_header'>
          <Can
            I={RouteGuardActions.manage}
            a={
              RouteGuardEntities.Admin
            }
          >
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <p className='fs-4 fw-bolder text-gray-900 m-0 p-0'>
                  {customer?.company_name}
                </p>
              </div>
            </div>
          </Can>

          <div className='card-toolbar'>
            <Button
              className='p-0 min-w-auto'
              type='button'
              mode='text'
              label={'Refresh'}
              onClick={handleUpdateChat}
              isLoading={isLoading}
              disabled={isLoading}
            />
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_chat_close'
            >
              <Icon icon={ICON_COLLECTION.close} />
            </div>
          </div>
        </div>
        <ChatInner
          isDrawer={true}
          messages={messages}
          userId={user.id}
          isLoading={isLoading}
          customer={customer}
          getMessages={getMessages}
        />
      </div>
    </div>
  )
}

export {DrawerMessenger}
