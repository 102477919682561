import {FC, ReactElement} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {useSidebarController} from "../../../../../shared/hooks/use-sidebar-controller";

type Props = {
    to: string
    title: string
    icon?: string
    fontIcon?: string
    hasBullet?: boolean,
    svgIcon?: ReactElement
}


const SidebarMenuItem: FC<Props & WithChildren> = (props) => {
    const {
        children,
        to,
        title,
        icon,
        svgIcon,
        fontIcon,
        hasBullet = false,
    } = props
    const {pathname} = useLocation()
    const isActive = checkIsActive(pathname, to)
    const {config} = useLayout()
    const {app} = config
    const {closeMobileSidebar} = useSidebarController()

    return (
        <div className='menu-item'>
            <Link className={clsx('menu-link without-sub rounded-0', {active: isActive})} to={to}
                  onClick={closeMobileSidebar}>
                {hasBullet && (
                    <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
                )}

                {svgIcon && svgIcon}
                {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                    <span className='menu-icon'>
            {' '}
                        <KTIcon iconName={icon} className='fs-2'/>
          </span>
                )}
                {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
                    <i className={clsx('bi fs-3', fontIcon)}></i>
                )}
                 <span className='menu-title'>{title}</span>
            </Link>
            {children}
        </div>
    )
}

export {SidebarMenuItem}
