import React, {useState} from 'react'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'
import {ICart} from 'shared/types/cart'
import {CartApi} from 'app/api/cart-api/cart-api'
import {useCartState} from 'app/store/cart/state'
import {useUserState} from 'app/store/user/state'

interface IClearCartData {
  userId: number
}

export const ClearCart = () => {
  const [userId, setUserId] = useState<number>(null)
  const modalState = useStateModalManager(DASHBOARD_MODAL_NAMES.clear_cart, {
    onBeforeOpen: ({userId}: IClearCartData) => {
      setUserId(userId)
    },
  })
  const {setCart} = useCartState()
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const clearCartMutation = useMutation<
    ICart,
    AxiosError<{error: string}>,
    number
  >(CartApi.clearCart, {
    onSuccess: (data) => {
      setIsCartHasProducts(false)
      setProductsInCartQuantity(0)
      setCart(data)
      modalState.close()
    },
    onError: async (error) => {
      const cart = await CartApi.getCart(userId)
      const productsInCart = cart.codes.reduce(
        (sum, code) => sum + (code.quantity as number),
        0
      )
      setProductsInCartQuantity(productsInCart)
      const codes = cart.codes.map((code)=>({...code,initial_quantity:(code.quantity as number)}));
      setCart({...cart,codes})
      toast.error(error.response.data.error)
    },
  })
  const handleClose = () => modalState.close()
  const handleClearCart = () => {
    clearCartMutation.mutate(userId)
  }
  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!clearCartMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Clear cart</h2>
        <p className='text-exl-primary my-10 text-center'>
          Are you sure you want to clear the cart?
        </p>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={clearCartMutation.isLoading}
          />
          <Button
            label='Clear'
            mainButton
            isLoading={clearCartMutation.isLoading}
            disabled={clearCartMutation.isLoading}
            onClick={handleClearCart}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
