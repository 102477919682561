import React from 'react'
import styles from './image-card.module.scss'
import { Loader } from 'shared/components/loader/loader'

interface LandingImageCardProps {
  image?: string
  isLoading?: boolean
}

const LandingImageCard: React.FC<LandingImageCardProps> = ({
  image,
  isLoading,
}) => {
  return (
    <figure className={`${styles['card']} position-relative`}>
      {isLoading ? <Loader mode='blur' /> : <img src={image} alt='' />}
    </figure>
  )
}

export default LandingImageCard
