import React, {useMemo} from 'react'
import clsx from 'clsx'
import {Input} from '../../../../../shared/components/input/input'
import {IOption, Select} from '../../../../../shared/components/select/select'
import {
  DiscountProduct,
  DiscountProductKeys,
} from '../../../../../shared/types/discount-types'
import {FormikTouched} from 'formik'
import {calculationPriceWithDiscount} from '../../helpers/discount-calculation'
import {thousandFormatter} from "../../../../../shared/helpers/formatter";
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

interface ICalculationLineProps {
  isGeneralComponentType?: boolean
  className?: string
  sellPrice: string | number
  purchasePrice?: number | string
  discount?: number
  type: number
  isDisabled?: boolean
  touched?: FormikTouched<DiscountProduct>
  errors?: {
    type?: string
    discount?: string
    general_sell_price?: string
  }
  onChangeProductDiscount: (
    name: DiscountProductKeys,
    value: number | string
  ) => void
  onChangeSellPrice: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const TYPE_OPTIONS: IOption[] = [
  {
    id: 1,
    label: 'Currency',
  },
  {
    id: 2,
    label: '%',
  },
]
const TO_FIXER: number = 2
const CalculationLineComponent: React.FC<ICalculationLineProps> = ({
  className,
  purchasePrice,
  sellPrice,
  discount,
  type,
  isDisabled,
  onChangeProductDiscount,
  errors,
  touched,
  onChangeSellPrice,
  isGeneralComponentType,
}) => {
  const newPrice = useMemo(() => {
    return calculationPriceWithDiscount(
      decimalFormatter(discount),
      type,
      Number(sellPrice)
    ).toFixed(TO_FIXER)
  }, [sellPrice, discount, type])

  const onChangeInput =
    (name: DiscountProductKeys) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeProductDiscount(name, event.target.value === '' ? '' : decimalFormatter(event.target.value))
    }
  const onChangeDiscountType = (option: IOption) => {
    onChangeProductDiscount('type', option.id)
  }
  return (
    <div
      className={clsx(className, 'd-flex  align-items-start flex-wrap', {
        'opacity-50': isDisabled,
      })}
    >
      {purchasePrice && (
        <div className='me-7 '>
          <p className='mb-2'>
            {isGeneralComponentType
              ? 'Last purchase price:'
              : 'Purchase Price:'}
          </p>
          <p className='mb-1 p-3'>{purchasePrice}</p>
        </div>
      )}
      <div className='me-7'>
        <p className='mb-2'>
          {isGeneralComponentType ? 'General sell price' : 'Sell Price:'}
        </p>
        <Input
          disabled={isDisabled}
          type='number'
          mode='number'
          error={!!(errors?.general_sell_price && touched?.general_sell_price)}
          errorText={
            errors?.general_sell_price && touched?.general_sell_price
              ? errors.general_sell_price
              : null
          }
          value={sellPrice}
          isPriceInput
          onChange={onChangeSellPrice}
          className='w-sm-150px '
          placeholder='Sell price'
        />
      </div>
      <div className='me-3'>
        <p className='mb-1'>Discount:</p>
        <Input
          disabled={isDisabled}
          type='number'
          mode='number'
          error={!!(errors?.discount && touched?.discount)}
          errorText={
            errors?.discount && touched?.discount ? errors.discount : null
          }
          isPriceInput
          value={discount}
          onChange={onChangeInput('discount')}
          className='w-sm-150px '
          placeholder='Discount:'
        />
      </div>
      <div className='me-3'>
        <p className='mb-1'>Type:</p>
        <Select
          error={!!(errors?.type && touched?.type)}
          errorText={errors?.type && touched?.type ? errors.type : null}
          options={TYPE_OPTIONS}
          className='w-sm-150px '
          isDisabled={isDisabled}
          onChange={onChangeDiscountType}
          value={type || null}
        />
      </div>
      <div className='me-3'>
        <p className='mb-2'>New Price:</p>
        <h5 className='mb-0 p-3'>{thousandFormatter(newPrice)}</h5>
      </div>
    </div>
  )
}
export const CalculationLine = React.memo(CalculationLineComponent)
