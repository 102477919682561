import React from 'react'
import { Link } from 'react-router-dom'
import CheckBox from 'shared/components/check-box/check-box'
import { CODES_ROUTES } from 'shared/config/routes'
import { IUploadHistory } from 'shared/types/codes-upload'
import { DateFormatter } from 'shared/utils/date-formatter'

interface IUploadHistoryTableRow {
  code: IUploadHistory
  handleCheckboxChange: (id: number) => void
}

const UploadHistoryTableRowComponent: React.FC<IUploadHistoryTableRow> = ({
  code,
  handleCheckboxChange,
}) => {
  return (
    <tr className='border-bottom'>
      <td>
        <CheckBox
          onChange={() => handleCheckboxChange(code.id)}
          checked={code.checked}
        />
      </td>
      <td>
        <Link to={CODES_ROUTES.UPLOAD_HISTORY.generate(code.id.toString())}>
          {code.id}
        </Link>
      </td>
      <td>{DateFormatter(code.created_at)}</td>
      <td>{code.supplier_name}</td>
      <td>{code.product_name}</td>
      <td>{code.quantity}</td>
      {code.code_type === 1 ? <td>Text</td> : <td>Img</td>}
      <td>
        {code.errors > 0 ? (

        <Link
          to={CODES_ROUTES.UPLOAD_HISTORY.redirectToErrorCodes(
            code.id.toString()
          )}
        >
          {code.errors}
        </Link>
        ) : 0}
      </td>
    </tr>
  )
}

export const UploadHistoryTableRow = React.memo(UploadHistoryTableRowComponent)
