import {useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {IOption} from 'shared/components/select/select'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {WALLETS_QUERY_KEYS} from 'shared/constants/query-keys'
import {WalletsApi} from 'app/api/dashboard-api/wallets-api'
import {initialFormWalletsName} from 'shared/constants/wallets'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import { useDebounce } from '_metronic/helpers'
import { toast } from 'react-toastify'
import { BlobErrorResponse } from 'shared/helpers/blob-error-response'
import { DownloadService } from 'shared/services/download-service'
import { DownloadReportApi } from 'app/api/dashboard-api/download-report-api'

export const useAllWalletsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(search.get('search'))
  const searchDataVariables = {
    1: 'desc',
    2: 'asc',
  }

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      search: search.get('search') || null,
      currency: Number(search.get('currency')) || null,
      amount_order: search.get('amount_order') || null,
      added_order: search.get('added_order') || null,
      spent_order: search.get('spent_order') || null,
      credit_order: search.get('credit_order') || null,
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const walletsQuery = useQuery({
    queryKey: [WALLETS_QUERY_KEYS.all_wallets, queryParams],
    queryFn: async () => await WalletsApi.getAllWallets(queryParams),
  })

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      search: queryParams.search ? {search: queryParams.search} : {},
      currency: queryParams.currency
        ? {currency: `${queryParams.currency}`}
        : {},
      amount_order: queryParams.amount_order
        ? {amount_order: `${queryParams.amount_order}`}
        : {},
      added_order: queryParams.added_order
        ? {added_order: `${queryParams.added_order}`}
        : {},
      spent_order: queryParams.spent_order
        ? {spent_order: `${queryParams.spent_order}`}
        : {},
      credit_order: queryParams.credit_order
        ? {credit_order: `${queryParams.credit_order}`}
        : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('search', debouncedSearchTerm)
    setSearchParams({
      ...params.search,
      ...params.added_order,
      ...params.amount_order,
      ...params.credit_order,
      ...params.spent_order,
      ...params.currency,
    })
  }, [debouncedSearchTerm])

  const handleChangeFormValue = (
    name: initialFormWalletsName,
    value: IOption
  ) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams(
      removeEmptyFields({
        ...params.search,
        ...params.currency,
        amount_order: searchDataVariables[params.amount_order.amount_order],
        added_order: searchDataVariables[params.added_order.added_order],
        spent_order: searchDataVariables[params.spent_order.spent_order],
        credit_order: searchDataVariables[params.credit_order.credit_order],
       })
      )
  }

  const handleChangeCurrencyValue = (option: IOption) => {
    const currencyValue = option ? option.id : ''
    const params = manageParams('currency', currencyValue)
    setSearchParams(
      removeEmptyFields({
        ...params.search,
        ...params.currency,
        ...params.added_order,
        ...params.amount_order,
        ...params.spent_order,
        ...params.credit_order,
      })
    )
  }

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.currency,
      ...params.amount_order,
      ...params.added_order,
      ...params.spent_order,
      ...params.credit_order,
      ...params.page,
    })
  }

  const {isLoading: isLoadingCurrencies, formationOptions: optionsCurrencies} =
    useSelectController({
      key: WALLETS_QUERY_KEYS.currencies,
      Fn: SelectsApi.getCurrencies,
      params: 'name',
      format: 'currencyOptions',
    })

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        search: queryParams.search,
        currency: queryParams.currency,
        amount_order: queryParams.amount_order,
        added_order: queryParams.added_order,
        spent_order: queryParams.spent_order,
        credit_order: queryParams.credit_order
      }
      const csvData = await DownloadReportApi.getWalletsReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Wallets Report')
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const isTableLoading = walletsQuery.isLoading
  return {
    models: {
      values: queryParams,
      wallets: walletsQuery.data ? walletsQuery.data.data : [],
      meta: walletsQuery.data ? walletsQuery.data.meta : null,
      isTableLoading,
      optionsCurrencies,
      isLoadingCurrencies,
      searchValue,
      isCSVLoading
    },
    commands: {
      handleChangeFormValue,
      setCurrentPage,
      setSearchValue,
      handleChangeCurrencyValue,
      onDownloadCSV
    },
  }
}
