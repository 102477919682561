import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {PURCHASES_QUERY_KEYS} from 'shared/constants/query-keys'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {IOption} from 'shared/components/select/select'
import {useDebounce} from '_metronic/helpers'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {IPurchaseList, PurchaseApi} from 'app/api/purchases-api/purchases-api'
import {initialFormPurchaseName} from 'shared/constants/purchases'
import {useModalManager} from 'shared/context/modal-manager'
import {PURCHASES_MODAL_NAME} from 'shared/constants/modal-names'
import {IPurchase} from 'shared/types/purchase'
import {DownloadReportApi} from 'app/api/dashboard-api/download-report-api'
import {DownloadService} from 'shared/services/download-service'
import {toast} from 'react-toastify'
import { AxiosError } from 'axios'
import { BlobErrorResponse } from 'shared/helpers/blob-error-response'

export const usePurchasesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(search.get('search'))
  const modalManager = useModalManager()
  const queryClient = useQueryClient()
  const queryParams = useMemo(() => {
    return removeEmptyFields({
      search: search.get('search'),
      supplier: Number(search.get('supplier')) || null,
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      status: Number(search.get('status')) || null,
      currency: Number(search.get('currency')) || null,
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const {
    isLoading: isLoadingSuppliers,
    handleInputChange: handleInputChangeSuppliers,
    formationOptions: optionsSuppliersMemo,
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    params: 'name',
  })
  const {
    isLoading: isLoadingCurrencies,
    handleInputChange:handleInputChangeCurrencies,
    formationOptions: optionsCurrenciesMemo,
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
    format: 'currencyOptions',
  })

  const purchasesQuery = useQuery({
    queryKey: [PURCHASES_QUERY_KEYS.purchases, queryParams],
    queryFn: async () => await PurchaseApi.getAllPurchases(queryParams),
    refetchOnWindowFocus: false,
  })

  const handleAddPurchaseClick = () => {
    modalManager.open(PURCHASES_MODAL_NAME.create_purchase)
  }

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      search: queryParams.search ? {search: queryParams.search} : {},
      start_date: queryParams.start_date
        ? {start_date: queryParams.start_date}
        : {},
      end_date: queryParams.end_date ? {end_date: queryParams.end_date} : {},
      supplier: queryParams.supplier
        ? {supplier: `${queryParams.supplier}`}
        : {},
      status: queryParams.status ? {status: `${queryParams.status}`} : {},
      currency: queryParams.currency
        ? {currency: `${queryParams.currency}`}
        : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('search', debouncedSearchTerm)
    setSearchParams({
      ...params.search,
      ...params.start_date,
      ...params.end_date,
      ...params.supplier,
      ...params.status,
      ...params.currency,
    })
  }, [debouncedSearchTerm])

  const handleChangeFormValue = (
    name: initialFormPurchaseName,
    value: IOption
  ) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams({
      ...params.search,
      ...params.start_date,
      ...params.end_date,
      ...params.supplier,
      ...params.status,
      ...params.currency,
    })
  }
  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    const params = manageParams('page', 1)
    if (startDate === '' || endDate === '')
      setSearchParams({
        ...params.search,
        ...params.status,
        ...params.supplier,
        ...params.currency,
      })
    else
      setSearchParams({
        ...params.search,
        ...params.status,
        start_date: startDate,
        end_date: endDate,
        ...params.supplier,
        ...params.currency,
      })
  }
  const handleEditPurchase =
    (purchase: IPurchase) =>
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      modalManager.open(PURCHASES_MODAL_NAME.update_purchase, purchase)
    }

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.search,
      ...params.start_date,
      ...params.end_date,
      ...params.supplier,
      ...params.status,
      ...params.currency,
      ...params.page,
    })
  }

  const handleConfirmDeleteClick = (purchaseId: number) => {
    modalManager.open(PURCHASES_MODAL_NAME.confirm_delete_purchase, purchaseId)
  }

  const isTableLoading = purchasesQuery.isLoading

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        search: queryParams.search,
        supplier: queryParams.supplier,
        start_date: queryParams.start_date,
        end_date: queryParams.end_date,
        status: queryParams.status,
        currency: queryParams.currency,
      }
      const csvData = await DownloadReportApi.getPuchaseReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Purchase Report')
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const copyPurchaseMutation = useMutation<
    IPurchase,
    AxiosError<{message: string}>,
    number
  >(PurchaseApi.copyPurchase, {
    onSuccess: (data: IPurchase) => {
      queryClient.invalidateQueries([PURCHASES_QUERY_KEYS.purchases,queryParams])
      queryClient.setQueryData([PURCHASES_QUERY_KEYS.purchases,queryParams],(prevData:IPurchaseList)=>{
        prevData.data.unshift(data)
        return prevData;
      })
      toast.success(`Purchase was copied successfully!`)
    },
    onError: async (error) => {
      toast.error(error.response.data.message)
    },
  })

  const handleDuplicateClick = (purchaseId:number) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>  {
    copyPurchaseMutation.mutate(purchaseId)
  } 

  return {
    models: {
      values: queryParams,
      purchases: purchasesQuery.data ? purchasesQuery.data.data : [],
      meta: purchasesQuery.data ? purchasesQuery.data.meta : null,
      isTableLoading,
      searchValue,
      isLoadingSuppliers,
      isLoadingCurrencies,
      suppliers: optionsSuppliersMemo,
      currencies: optionsCurrenciesMemo,
      isCSVLoading,
    },
    commands: {
      handleChangeFormValue,
      handleDateChangeFormValue,
      setCurrentPage,
      setSearchValue,
      handleInputChangeSuppliers,
      handleAddPurchaseClick,
      handleEditPurchase,
      handleConfirmDeleteClick,
      handleInputChangeCurrencies,
      handleDuplicateClick,
      onDownloadCSV,
    },
  }
}
