import React from 'react';
import {useAbilityContext} from "../../casl";
import {IUser} from "../../shared/types/user";
import {useMenuList} from "../../shared/hooks/use-menu-list";
import {Loader} from "../../shared/components/loader/loader";
import {AppNavigationContext} from 'shared/context/app-navigation-context'

interface LayoutProps {
    isUserAuth: boolean;
    userData?: IUser | null;
    isLoading: boolean;
}

export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = ({
                                                                           isUserAuth,
                                                                           userData,
                                                                           isLoading,
                                                                           children,
                                                                       }) => {

    const ability = useAbilityContext();

    const {links} = useMenuList(ability)

    if (!isUserAuth) {
        return (<>{children}</>)
    }
    if (isLoading || !userData) {
        return (<Loader/>);
    }


    return (
        <AppNavigationContext.Provider value={{navigationLinks: links}}>
            {children}
        </AppNavigationContext.Provider>
    );
};

