import React from 'react';
import clsx from 'clsx';
import { AllInvoicesTableRow } from './all-invoices-table-row';
import { LoadWrapper } from 'shared/components/load-wrapper/load-wrapper';
import { IInvoice } from 'shared/types/invoice';
import CheckBox from 'shared/components/check-box/check-box';


export interface IInvoicesTable {
  invoices: IInvoice[]
  isLoading: boolean
  isSelectedAll: boolean
  isApproveLoading: (invoice: number) => boolean
  handleSelectAll: () => void
  handleSelectModel: (value: number) => void
  handleApproveInvoice: (user: number, invoice: number) => void
  handleDeleteInvoice: (invoiceId: number) => void
}

export const AllInvoicesTable: React.FC<IInvoicesTable> = ({
  invoices,
  isLoading,
  isSelectedAll,
  handleSelectAll,
  handleSelectModel,
  handleApproveInvoice,
  isApproveLoading,
  handleDeleteInvoice,
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light text-exl-primary text-nowrap'>
              <th>
                <CheckBox onChange={handleSelectAll} checked={isSelectedAll} />
              </th>
              <th>Date</th>
              <th>Invoice Number</th>
              <th>Company Name</th>
              <th>Source</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Profit</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          {invoices && invoices.length ? (
            <tbody>
              {invoices.map((invoice: IInvoice) => (
                <AllInvoicesTableRow
                  isApproveLoading={isApproveLoading}
                  key={invoice.id}
                  invoice={invoice}
                  handleSelectModel={handleSelectModel}
                  handleApproveInvoice={handleApproveInvoice}
                  handleDeleteInvoice={handleDeleteInvoice}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={10}>No invoices found with this filters.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}


