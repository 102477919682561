import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import {IUser} from 'shared/types/user';
import {UserApi} from "../../api/user-api/user-api";


interface IUserState {
    userData: IUser | null;
    isLoading: boolean;
    isCartHasProducts:boolean;
    cartQuantity:number;
    isEmailVerified:boolean;
    getProfile: (userId: number) => Promise<void>;
    setProfile: (user: IUser | null) => void;
    setIsCartHasProducts:(isCartHasProducts:boolean) => void;
    setProductsInCartQuantity:(cartQuantity:number) => void;
}

export const useUserState = create<IUserState>()(
    devtools(
        (set) => ({
            userData: null,
            cartQuantity:0,
            isLoading: false,
            isCartHasProducts:false,
            isEmailVerified:false,
            getProfile: async function (userId) {
                try {
                    set({isLoading: true});
                    const tempUserData = await UserApi.getUserById(userId);
                    set({userData: tempUserData});
                    set({isCartHasProducts: tempUserData.cart_has_products});
                    set({cartQuantity: tempUserData.cart_quantity});
                    set({isEmailVerified: !!tempUserData.email_verified_at});
                    set({isLoading: false});
                } catch (e) {
                    set({isLoading: false});
                }
            },
            setProfile(user: IUser | null) {
                set({userData: user});
            },
            setIsCartHasProducts(isCartHasProducts:boolean){
                set({isCartHasProducts});
            },
            setProductsInCartQuantity(cartQuantity:number){
                set({cartQuantity});
            }
        }),
        {name: 'useUserState', store: 'useUserState'},
    ),
);
