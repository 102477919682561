import React, {ChangeEvent, useState} from 'react'
import {Button} from 'shared/components/button/button'
import {Loader} from 'shared/components/loader/loader'
import {Textarea} from 'shared/components/textarea/textarea'
import {IAdminSetting} from 'shared/types/api-types/admin-setting-types'

interface IAdminSettingsTableRow {
  setting: IAdminSetting
  isSaveButtonLoading: boolean
  handleSaveChanges: (setting: IAdminSetting) => void
}

const AdminSettingsTableRowComponent: React.FC<IAdminSettingsTableRow> = ({
  setting,
  isSaveButtonLoading,
  handleSaveChanges,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [settingData, setSettingData] = useState<IAdminSetting>(setting)
  const handleEditClick = () => {
    setIsEditing(true)
  }
  const handleCancelClick = () => {
    setSettingData(setting)
    setIsEditing(false)
  }
  const handleChangeValues = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSettingData((prevData: IAdminSetting) => {
      return {
        ...prevData,
        value: event.target.value,
      }
    })
  }
  const handleSaveChangesClick = () => {
    handleSaveChanges(settingData)
    setIsEditing(false)
  }

  return (
    <tr className='border-bottom'>
      <td className='text-exl-primary pt-7 text-break min-w-200px'>{settingData.key}</td>
      <td className='mw-550px text-exl-primary'>
        {isEditing ? (
          <Textarea
            value={settingData.value}
            placeholder='Value'
            mode='light'
            disabled={!isEditing}
            readonly={!isEditing}
            onChange={handleChangeValues}
          />
        ) : (
          <p className='text-truncate text-exl-primary m-0'>{settingData.value}</p>
        )}
      </td>
      <td className='d-flex flex-end border-0 pt-7 gap-0'>
        <div className='d-flex gap-5 position-relative flex-end w-150px'>
          {isEditing && (
            <Button
              label='Cancel'
              type='button'
              mode='text'
              className='p-0 min-w-auto'
              onClick={handleCancelClick}
            />
          )}
          <Button
            label={isEditing ? 'Save' : 'Edit'}
            type='button'
            mode='text'
            className='p-0 min-w-auto'
            onClick={isEditing ? handleSaveChangesClick : handleEditClick}
          />
          {isSaveButtonLoading && (
            <Loader classNane='bg-white justify-content-end' />
          )}
        </div>
      </td>
    </tr>
  )
}

export const AdminSettingsTableRow = React.memo(AdminSettingsTableRowComponent)
