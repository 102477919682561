import React from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import styles from './accordions.module.scss'

interface LandingAccordionsProps {
  index: number
  activeIndex: number
  onClick: (index: number) => void
  accordionOffer: {
    id: number
    title: string
    text: string
    image: string
  }
}

const LandingAccordions: React.FC<LandingAccordionsProps> = ({
  index,
  activeIndex,
  onClick,
  accordionOffer,
}) => {
  const isActive = index === activeIndex

  const handleClick = () => {
    if (!isActive) {
      onClick(index)
    }
  }

  return (
    <div
      className={`${styles['accordion']} ${
        isActive ? styles['active'] : ''
      }`}
      onClick={handleClick}
    >
      <h4 className={styles['accordion__title']}>{accordionOffer.title}</h4>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{height: 0, opacity: 0}}
            animate={{height: 'auto', opacity: 1}}
            exit={{height: 0, opacity: 0}}
            transition={{duration: 0.3, ease: 'easeInOut'}}
            className={styles['accordion__content']}
          >
            {accordionOffer.text}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default LandingAccordions
