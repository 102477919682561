import React from 'react'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Input} from 'shared/components/input/input'
import { IOption, Select } from 'shared/components/select/select'

interface IAdminSettingsSearchBar {
  searchValue: string
  paymentValue: number,
  setSearchValue: (text: string) => void
  handleChangeSelectValue: (option: IOption) => void
}

const SELECT_CURRENCIES_OPTIONS: IOption[] = [
  {
    id: 1,
    label: 'GBP Payments',
  },
  {
    id: 2,
    label: 'USD Payments',
  },
  {
    id: 3,
    label: 'EUR Payments',
  },
]

export const AdminSettingsSearchBar: React.FC<IAdminSettingsSearchBar> = ({
  searchValue,
  paymentValue,
  setSearchValue,
  handleChangeSelectValue,
}) => {
  return (
    <div className='mb-5'>
      <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-5 position-relative'>
        <div className='col mb-5'>
          <Input
            startAdornment={
              <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
            }
            placeholder='Search'
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
        <div className='col mb-5'>
          <Select
            className='w-sm-250px'
            options={SELECT_CURRENCIES_OPTIONS}
            value={paymentValue}
            placeholder='Select Currencies Payments'
            isClearable
            onChange={(option) => handleChangeSelectValue(option)}
          />
        </div>
      </div>
    </div>
  )
}