import React from 'react'
import {Button} from '../button/button'
import ErrorImg from '../../assets/images/404.png'
import NotFoundStyles from './not-found-page.module.scss'
import {useNavigate} from "react-router-dom";
import {LANDING_ROUTES} from "../../config/routes";

const NotFoundPage = () => {
    const navigate = useNavigate()
    const handelNavigateToHome = () => {
        navigate(LANDING_ROUTES.ROOT.path)
    }
    return (
        <main className={NotFoundStyles['not-found']}>
            <section className={NotFoundStyles['not-found__container']}>
                <div className={NotFoundStyles['not-found__image']}>
                    <img src={ErrorImg} alt=''/>
                </div>
                <p className={NotFoundStyles['not-found__text']}>
                    "Oops! Looks like we didn't land here. But you can head back to the catalog by clicking
                    the button below."
                </p>
                <Button label='Back to home'
                        className={NotFoundStyles['not-found__button']}
                        onClick={handelNavigateToHome}
                />
            </section>
        </main>
    )
}

export default NotFoundPage
