import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Name is required'),
  company_director: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols'),
  company_address: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Street address is required'),
  city: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('City is required'),
  zip_post_code: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols'),
  website: Yup.string()
    .nullable()
    .matches(
      /(?:https?):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?|\bwww\.[-a-z0-9]+(\.[-a-z0-9]+)*\b/gi,
      'Website is not correct!'
    ),
  VAT_number: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols'),
  company_registration_number: Yup.string()
    .nullable()
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols'),
  main_email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Email is required'),
  account_payable_contact: Yup.string()
    .nullable()
    .min(10, 'Minimum 10 symbols')
    .required('Account paylable contact is required'),
  account_payable_email: Yup.string()
    .nullable()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(30, 'Maximum 30 symbols')
    .required('Account paylable email is required'),
  phone_number: Yup.string()
    .nullable()
    .min(10, 'Minimum 10 symbols')
    .required('Phone is required'),
  country_id: Yup.number()
    .integer()
    .required('Region is required'),
})
