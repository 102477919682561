import {useEffect, useMemo, useState} from 'react';
import {useQuery} from "react-query";
import {CATEGORIES_QUERY_KEYS, CODES_QUERY_KEYS, REGIONS_QUERY_KEYS,} from "shared/constants/query-keys";
import {removeEmptyFields} from "shared/utils/remove-empty-fields";
import {createSearchParams, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {IOption} from "shared/components/select/select";
import {useDebounce} from "_metronic/helpers";
import {useModalManager} from 'shared/context/modal-manager';
import {CATALOG_MODAL_NAMES, DASHBOARD_MODAL_NAMES} from "shared/constants/modal-names";
import {toast} from "react-toastify";
import {CategoryApi} from "app/api/dashboard-api/category-api";
import {PRODUCT_ROUTES} from "shared/config/routes";
import {useSelectController} from "../../../../../shared/hooks/use-select-controller";
import {SelectsApi} from "../../../../../app/api/settings-api/selects-api";
import { useAbilityContext } from 'casl';
import { STOCK_OPTIONS_CATALOG } from 'shared/constants/select-options';

export interface ICategoryUpdateProps {
    id: number
    name?: string,
    url?: string
}

export const useCatalogPage = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const ability = useAbilityContext()
    const location = useLocation()
    const search = new URLSearchParams(location.search)
    const [searchValue, setSearchValue] = useState(search.get('name'));
    const modalManager = useModalManager();
    const navigate = useNavigate();

    const queryParams = useMemo(() => {
        return removeEmptyFields({
            name: search.get('name'),
            category: Number(search.get('category')) || null,
            region: Number(search.get('region')) || null,
            currency: Number(search.get('currency')) || null,
            in_stock: Number(search.get('in_stock')) || null,
            page: search.get('page') ? Number(search.get('page')) : null
        })
    }, [location.search])

    function manageParams(name: string, value: string | number | null) {
        const ParamsList = {
            name: queryParams.name ? {search: queryParams.name} : {},
            category: queryParams.category ? {category: `${queryParams.category}`} : {},
            region: queryParams.region ? {region: `${queryParams.region}`} : {},
            currency: queryParams.currency ? {currency: `${queryParams.currency}`} : {},
            in_stock: queryParams.in_stock ? {in_stock: `${queryParams.in_stock}`} : {},
            page: queryParams.page ? {page: `${queryParams.page}`} : {},
        }
        ParamsList[name] = (value !== null && value !== '') ? {[name]: `${value}`} : {}
        return ParamsList
    }

    const categoryPageQuery = useQuery({
        queryKey: [CATEGORIES_QUERY_KEYS.category_page, queryParams],
        queryFn: async () => CategoryApi.getCategories(queryParams),
        onError: error => {
            toast.error('Something went wrong. ')
        }
    })

    const handleChangeFormValue = (name: string, value: IOption) => {
        if (value) {
            navigate({
                pathname: PRODUCT_ROUTES.PRODUCT.path,
                search: `?${createSearchParams([[name, `${value.id}`]])}`
            })
        }
    }
    const onClearQuery = () => {
        navigate(PRODUCT_ROUTES.PRODUCT.path)

    }
    const setCurrentPage = (page: number) => {
        const params = manageParams('page', page)
        setSearchParams({
            ...params.name,
            ...params.page,
        })
    }
    const debouncedSearchTerm = useDebounce(
        searchValue, 1000)
    useEffect(() => {
        const params = manageParams('name', debouncedSearchTerm)
        setSearchParams({
            ...params.name,
        })
    }, [debouncedSearchTerm]);

    const {
        isLoading: isLoadingCurrencies,
        formationOptions: optionsCurrencies
    } = useSelectController({
        key: CODES_QUERY_KEYS.currencies,
        Fn: SelectsApi.getCurrencies,
        params: 'name',
        format: 'currencyOptions'
    })
    const {
        isLoading: isLoadingCategory,
        handleInputChange: handleInputChangeCategory,
        formationOptions: categories
    } = useSelectController({
        key: CATEGORIES_QUERY_KEYS.categories,
        Fn: SelectsApi.getCategories,
        params: 'name',
    })
    const {
        isLoading: isLoadingRegions,
        handleInputChange: handleInputChangeRegions,
        formationOptions: optionRegions
    } = useSelectController({
        key: REGIONS_QUERY_KEYS.regions,
        Fn: SelectsApi.getRegions,
        params: 'name',
    })

    const handleCreateProduct = () => {
        modalManager.open(DASHBOARD_MODAL_NAMES.create_product);
    };

    const handleUpdateCategory = (data: ICategoryUpdateProps) => {
        modalManager.open(CATALOG_MODAL_NAMES.update_category, data);
    };

    const handleRemoveCategory = (categoryId:number) => {
        modalManager.open(DASHBOARD_MODAL_NAMES.remove_category, categoryId,queryParams);
    };

    const stockOptions = useMemo(()=>STOCK_OPTIONS_CATALOG(ability),[ability])
    const isLoadingList = categoryPageQuery.isLoading
    const isLoadingSelects = isLoadingCurrencies || isLoadingCategory || isLoadingRegions

    return {
        models: {
            optionRegions,
            searchValue,
            isLoadingList,
            values: queryParams,
            isLoadingSelects,
            categoryOptions: categories,
            currencyOption: optionsCurrencies,
            catalog: categoryPageQuery.data ? categoryPageQuery.data.data : [],
            meta: categoryPageQuery.data ? categoryPageQuery.data.meta : null,
            stockOptions
        },
        commands: {
            setSearchValue,
            handleChangeFormValue,
            handleCreateProduct,
            onClearQuery,
            setCurrentPage,
            handleUpdateCategory,
            handleInputChangeCategory,
            handleInputChangeRegions,
            handleRemoveCategory
        }
    }
};

