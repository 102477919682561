export enum DASHBOARD_MODAL_NAMES {
  create_product = 'create_product',
  pre_order_warning = 'pre_order_warning',
  pre_order_group_codes_warning = 'pre_order_group_codes_warning',
  decline_customer_warning = 'decline_customer_warning',
  delete_file = 'delete_file',
  remove_product = 'remove_product',
  clear_cart = 'clear_cart',
  checkout = 'checkout',
  remove_category = 'remove_category',
  remove_customer = 'remove_customer'
}

export enum CATALOG_MODAL_NAMES {
  update_category = 'update_category',
  remove_group_codes = 'remove_group_codes',
  product_alert_validation = 'product_alert_validation',
  codes_group_alert_validation = 'codes_group_alert_validation',
}

export enum ACCOUNT_MODAL_NAMES {
  create_api_key = 'create_api_key',
  add_two_factor = 'add_two_factor',
}

export enum WALLETS_MODAL_NAMES {
  create_wallet = 'create_wallet',
  adjust_wallet = 'adjust_wallet',
  credit_balance = 'credit_balance',
}

export enum INVOICES_MODAL_NAME {
  create_invoice = 'create_invoice',
  delete_admin_invoice = 'delete_admin_invoice',
  delete_invoice = 'delete_invoice',
}

export enum CODES_MODAL_NAME {
  delete_codes = 'delete_codes',
  change_status = 'change_status',
  delete_upload_history = 'delete_upload_history',
  upload_codes_alert_validation_preorder = 'upload_codes_alert_validation_preorder',
  upload_codes_alert_validation = 'upload_codes_alert_validation',
}

export enum PURCHASES_MODAL_NAME {
  create_purchase = 'create_purchase',
  add_product = 'add_product',
  update_purchase = 'update_purchase',
  update_product_in_purchase = 'update_product_in_purchase',
  confirm_delete_purchase = 'confirm_delete_purchase',
  confirm_delete_purchase_product = 'confirm_delete_purchase_produc',
}

export enum SUPPLIERS_MODAL_NAME {
  add_supplier = 'add_supplier',
  create_product = 'create_product',
  delete_product_supplier = 'delete_product_supplier',
  delete_supplier = 'delete_supplier',
  update_product = 'update_product',
  delete_product = 'delete_product',
}

export  enum STATISTIC_MODAL_NAME {
    filter_statistics = 'filter_statistics'
}
export enum OPTIONS_MODAL_NAME {
  delete_region = 'delete_region',
  create_region = 'create_region',
  delete_currency = 'delete_currency',
  create_currency = 'create_currency',
  delete_brand = 'delete_brand',
  create_brand = 'create_brand',
  delete_slider_image = 'delete_slider_image',
}
export enum DISCOUNT_MODAL_NAME {
  remove_discount = 'remove_discount',
  remove_product_discount = 'remove_product_discount',
  users_list = 'users_list',
  alert_validation = 'alert_validation',
  alert_allow_sell_at_loss = 'alert_allow_sell_at_loss'
}
