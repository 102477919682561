import React from 'react';
import {Input} from "shared/components/input/input";
import {Icon} from "shared/components/icon/icon";
import {ICON_COLLECTION} from "shared/components/icon/icon-list";
import {IOption, Select} from "shared/components/select/select";

interface ICatalogSearchBar {
    searchValue: string
    setSearchValue: (text: string) => void
    categoryOptions: IOption[]
    stockOption: IOption[]
    regionOptions: IOption[]
    isLoading: boolean
    handleChangeFormValue: (name: string, option: IOption) => void
    categoryValue: number | null
    regionValue: number | null
    stockValue: number | null
    currencyValue: number | null,
    currencyOption: IOption[],
    handleInputChangeCategory?: (text: string) => void
    handleInputChangeRegion?: (text: string) => void
}


const CatalogSearchBarComponent: React.FC<ICatalogSearchBar> = (props) => {
    const {
        searchValue,
        setSearchValue,
        regionValue,
        categoryOptions,
        isLoading,
        handleChangeFormValue,
        categoryValue,
        stockOption,
        stockValue,
        currencyValue,
        currencyOption,
        handleInputChangeCategory,
        handleInputChangeRegion,
        regionOptions,
    } = props

    return (
        <div className="mb-5">
            <div className="row align-items-center row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-6">
                <div className="col mb-5">
                    <Input startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch}/>}
                           placeholder='Search'
                           value={searchValue}
                           onChange={(event) => setSearchValue(event.target.value)}
                    />
                </div>
                <div className="col mb-5">
                    <Select options={categoryOptions}
                            handleInputChange={handleInputChangeCategory}
                            value={categoryValue}
                            isClearable
                            placeholder='Category'
                            isLoading={isLoading}
                            onChange={option => handleChangeFormValue('category', option)}
                    />
                </div>
                <div className="col mb-5">
                    <Select options={regionOptions}
                            value={regionValue}
                            placeholder='Region'
                            isClearable
                            isLoading={isLoading}
                            handleInputChange={handleInputChangeRegion}
                            onChange={option => handleChangeFormValue('region', option)}
                    />
                </div>
                <div className="col mb-5">
                    <Select options={currencyOption}
                            value={currencyValue}
                            isClearable
                            isLoading={isLoading}
                            placeholder='Currency'
                            onChange={option => handleChangeFormValue('currency', option)}
                    />
                </div>
                <div className="col mb-5">
                    <Select options={stockOption}
                            value={stockValue}
                            placeholder='In Stock'
                            isClearable
                            isLoading={isLoading}
                            onChange={option => handleChangeFormValue('in_stock', option)}
                    />
                </div>
            </div>
        </div>
    );
};

export const CatalogSearchBar = React.memo(CatalogSearchBarComponent)
