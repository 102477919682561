export const DateFormatter = (date: string | Date): string => {
  if (!date) return '-'
  const datePart = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const timePart = new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  })
  return datePart+'\n'+timePart
}
