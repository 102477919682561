export enum APP_ROLES {
    ADMIN = 'admin',
    CUSTOMER = "customer",
    UNAPPROVED_CUSTOMER = "unapproved_customer",
}

export const CUSTOMER_STATUS = {
    1:'Approved',
    2:'Pending',
    3:'Declined',
    4:'Blocked'
}

export const PURCHASE_STATUS = {
    0:'Undelivered',
    1:'Delivered',
    2:'Empty'
}

export const ORDER_STATUS = {
  1: 'Processing',
  2: 'Completed',
  3: 'Failed',
  4: 'Pre-ordered',
  5: 'Unpaid Pre-order',
  6: 'Part-delivered',
}

export const ORDER_METHOD = {
    1:'WEB',
    2:'API'
}

export const INVOICE_SOURCE = {
    1:'WEB',
    2:'API'
}

export const INVOICE_TYPE = {
    1:'Balance deposit',
    2:'Balance withdrawal',
    3:'Order invoice',
    4:'Top-up'
}

export const INVOICE_STATUS = {
    1:'Unpaid',
    2:'Paid',
    3:'Canceled',
}

export const ACCEPT_TYPES_FOR_IMAGES = ['image/jpeg', 'image/png', 'image/svg+xml']