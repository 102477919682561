import React from 'react'
import {portalTitle} from '../../constants/main'
import { portalText } from '../../constants/main'
import {useInView} from 'react-intersection-observer'
import clsx from 'clsx'
import styles from './landing-portal.module.scss'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'

const LandingPortal = () => {
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })
  const [cardRef, cardInView] = useInView({
    triggerOnce: true,
    threshold: 0,
    delay: 150,
  })
  return (
    <section id={LANDING_NAVIGATION.offers.id} className={styles['portal']}>
      <div className={styles['portal__container']}>
        <h2
          className={clsx(styles['portal__title'], {
            [styles['portal__title--in-view']]: titleInView,
          })}
          ref={titleRef}
        >
          {portalTitle.title}
        </h2>
        <div className={styles['portal-list']}>
          {portalText.map((portal, index) => (
            <div
              key={portal.id}
              className={clsx(styles['portal-list__card'], {
                [styles['portal-list__card--reversed']]: index % 2 === 0,
                [styles['portal-list__card--in-view']]: cardInView,
              })}
              ref={cardRef}
            >
              <p>{portal.text}</p>
              <img src={portal.image} alt=''/>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default LandingPortal
