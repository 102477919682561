import React from 'react';
import {FormikProps} from 'formik/dist/types'
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {PasswordInput} from "shared/components/input/password-input";
import {Input} from "shared/components/input/input";
import {IFormikProps} from "app/modules/auth/components/Login";
import {Button} from "shared/components/button/button";
import styles from 'app/modules/auth/components/Login.module.scss'
import {AUTH_ROUTES, LANDING_ROUTES} from 'shared/config/routes';
import {ButtonLink} from "../../../../shared/components/button/button-link";
import {useIntl} from "react-intl";

interface ILoginPage {
    formik: FormikProps<IFormikProps>;
    isLoading: boolean;
}

export const LoginFormInner: React.FC<ILoginPage> = ({formik, isLoading}) => {
    const intl = useIntl()
    return (
        <>
            <div className='fv-row mb-5'>
                <Input
                    placeholder={intl.formatMessage({id: 'EXL.AUTH.INPUT.EMAIL'})}
                    {...formik.getFieldProps('email')}
                    type='email'
                    name='email'
                    autoComplete='on'
                    error={formik.touched.email && !!formik.errors.email}
                    errorText={formik.errors.email}
                    disabled={isLoading}
                />
            </div>

            <div className='fv-row mb-6'>
                <PasswordInput
                    placeholder={intl.formatMessage({id: 'EXL.AUTH.INPUT.PASS'})}
                    {...formik.getFieldProps('password')}
                    autoComplete='off'
                    error={formik.touched.password && !!formik.errors.password}
                    errorText={formik.errors.password}
                    disabled={isLoading}
                />
            </div>

            <div className='d-grid mb-5'>
                <Button
                    label={intl.formatMessage({id: 'EXL.AUTH.LOGIN.BUTTON'})}
                    mainButton
                    type='submit'
                    disabled={formik.isSubmitting || !formik.isValid || isLoading}
                    isLoading={isLoading}
                />
            </div>

            <div className='d-flex justify-content-center mb-5 '>
                <Link to={AUTH_ROUTES.FORGOT_PASSWORD.path} className='link-primary fs-6'>
                    {intl.formatMessage({id: 'EXL.AUTH.LOGIN.BUTTON.FORGET_PASS'})}
                </Link>
            </div>
            <div className={clsx('text-center fs-6 mb-20', styles.textPrimaryDark)}>
                {intl.formatMessage({id: 'EXL.AUTH.LOGIN.REG_ACCOUNT'})}{' '}
                <Link to={AUTH_ROUTES.REGISTRATION.path} className='link-primary'>
                    {intl.formatMessage({id: 'EXL.AUTH.LOGIN.REG_HERE'})}
                </Link>
            </div>
            <div className='d-flex justify-content-center mb-20'>
                <ButtonLink to={LANDING_ROUTES.ROOT.path}>
                    {intl.formatMessage({id: 'EXL.AUTH.BACK_TO_SITE'})}
                </ButtonLink>
            </div>
        </>
    );
};

