import React, {useRef, useState} from 'react'
import {selectIsGettingUser, selectUserData} from '../../../../app/store/user/selects'
import {Button} from 'shared/components/button/button'
import {usePopper} from 'react-popper'
import FocusTrap from 'focus-trap-react'
import {useLogout} from 'modules/auth/hooks/use-logout'
import {useNavigate} from 'react-router-dom'
import {DASHBOARD_ROUTES} from 'shared/config/routes'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'

export const CompanyInfo = () => {
  const user = selectUserData()

  const [isOpen, setIsOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-end',
  })

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }


  const {logout} = useLogout()
  const navigate = useNavigate()

  const navigateToAccount = () => {
    navigate(DASHBOARD_ROUTES.ACCOUNT.path)
    setIsOpen(false)
  }

  const NAV_BUTTONS_AUTH = [
    {
      id: 1,
      label: 'My Account',
      icon: ICON_COLLECTION.authUser,
      onClick: navigateToAccount,
      className: '',
    },
    {
      id: 2,
      label: 'Log Out',
      icon: ICON_COLLECTION.navLogout,
      onClick: logout,
      className: 'text-danger',
    },
  ]

  const isLoading = selectIsGettingUser()

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div ref={popperRef} onClick={handleOpen}>
        <Button
          type='button'
          mode={'navbar'}
          label={
            <span className='d-flex gap-5'>
              {user.customer?.company_name ?? <p className='m-0 text-gray-500'>No name</p>}
              <Icon icon={ICON_COLLECTION.arrowDown} className='mw-50'/>
            </span>
          }
          ref={buttonRef}
          onClick={handleOpen}
        />
      </div>
      {isOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onActivate: handleOpen,
            onDeactivate: handleClose,
            fallbackFocus: buttonRef.current!,
          }}
        >
          <div
            style={popper.styles.popper}
            className='dialog-sheet'
            ref={setPopperElement}
            {...popper.attributes.popper}
            role='dialog'
            aria-label='Dropdown'
          >
            {!isLoading ? (
              <div className='menu-content d-flex flex-column bg-white shadow rounded py-1 ps-1'>
                {NAV_BUTTONS_AUTH.map((button) => (
                  <Button
                    key={button.id}
                    mode='text'
                    onClick={button.onClick}
                    label={
                      <div className='fs-5 d-flex gap-2 pe-14'>
                        <Icon icon={button.icon} />
                        <span className={button.className}>{button.label}</span>
                      </div>
                    }
                  />
                ))}
              </div>
            ) : null}
          </div>
        </FocusTrap>
      )}
    </div>
  )
}
