import * as React from 'react'
import {forwardRef, useId,} from 'react'
import {Form} from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import {
    GroupBase,
    SelectInstance,

} from 'react-select'
import styles from './select.module.scss'

import {SELECT_STYLE_CONFIG} from "./select-style";
import {DropdownIndicator} from "./dropdown-indicator";
import {IOption, SelectProps} from "./select-type";


const CreatableSelectComponent: React.ForwardRefRenderFunction<
    SelectInstance<IOption, false, GroupBase<IOption>>,
    SelectProps
> = (
    {
        value,
        className,
        placeholder,
        error,
        options,
        isDisabled = false,
        isLoading,
        errorText,
        name,
        autoFocus,
        onChange,
        onBlur,
        onFocus,
        onCreate,
        handleInputChange,
    },
    ref
) => {
    const id = useId()
    const stylesSelect = SELECT_STYLE_CONFIG(errorText, error)

    return (
        <>
            <CreatableSelect
                ref={ref}
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator
                }}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable
                options={options}
                onInputChange={handleInputChange}
                classNamePrefix="select"
                id={id}
                inputId={id}
                value={value}
                className={className}
                autoFocus={autoFocus}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                onCreateOption={onCreate}
                onBlur={onBlur}
                onFocus={onFocus}
                loadingMessage={() => 'Loading...'}
                styles={stylesSelect}
            />
            {error && errorText && (
                <Form.Control.Feedback className={styles.error} type='invalid'>
                    {errorText}
                </Form.Control.Feedback>
            )}
        </>
    )
}

export const SelectCreatable = forwardRef(CreatableSelectComponent)

