import React, {useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import isEqual from 'lodash/isEqual'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {PURCHASES_MODAL_NAME} from 'shared/constants/modal-names'
import {PURCHASES_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {
  IPurchaseProductsList,
  PurchaseApi,
} from 'app/api/purchases-api/purchases-api'
import {Input} from 'shared/components/input/input'
import {IPurchaseProduct} from 'shared/types/purchase'
import {AxiosError} from 'axios'
import {useFormik} from 'formik'
import {validationSchema} from '../schemas/purchase-product-validation-schema'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

export const EditPurchaseProductModal = () => {
  const [purchaseProduct, setPurchaseProduct] = useState<IPurchaseProduct>(null)
  const modalState = useStateModalManager(
    PURCHASES_MODAL_NAME.update_product_in_purchase,
    {
      onBeforeOpen: (purchaseProduct: IPurchaseProduct) => {
        formik.setValues(purchaseProduct)
        setPurchaseProduct(purchaseProduct)
      },
    }
  )

  const handleClose = () => {
    setPurchaseProduct(null)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const updatePurchaseProductMutation = useMutation<
    IPurchaseProduct,
    AxiosError<{error: string}>,
    IPurchaseProduct
  >(PurchaseApi.updatePurchaseProduct, {
    onSuccess: (data: IPurchaseProduct) => {
      queryClient.setQueryData(
        [
          PURCHASES_QUERY_KEYS.purchase_product,
          `${purchaseProduct.purchase_id}`,
        ],
        (prevData: IPurchaseProductsList) => {
          const index = prevData.data.findIndex((item) => item.id === data.id)
          prevData.data.splice(index, 1, data)
          return prevData
        }
      )
      queryClient.invalidateQueries([
        PURCHASES_QUERY_KEYS.purchase_product,
        `${purchaseProduct.purchase_id}`,
      ])
      toast.success(`Purchase product was updated successfully!`)
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const onSubmit = (values: IPurchaseProduct) => {
    updatePurchaseProductMutation.mutate({...values, purchase_price: decimalFormatter(values.purchase_price)})
  }

  const formik = useFormik<IPurchaseProduct>({
    initialValues: purchaseProduct,
    onSubmit,
    validationSchema,
  })

  const isDisabledUpdateButton =
    updatePurchaseProductMutation.isLoading ||
    !!Object.keys(formik.errors).length ||
    isEqual(formik.values, purchaseProduct) ||
    formik.values.quantity===0

  const handleUpdateClick = () => {
    formik.submitForm()
  }

  useEffect(() => {
    handleClose()
    updatePurchaseProductMutation.reset()
  }, [updatePurchaseProductMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!updatePurchaseProductMutation.isLoading}
    >
      <div className='p-10'>
        <h2 className='text-center mb-5'>
          Edit product {purchaseProduct?.product.name}
        </h2>
        <label>Purchase Price</label>
        <Input
          {...formik.getFieldProps('purchase_price')}
          value={formik.values?.purchase_price || ''}
          error={formik.touched.purchase_price && !!formik.errors.purchase_price}
          errorText={formik.errors.purchase_price}
          type='number'
          mode='number'
          currency={purchaseProduct?.currency?.symbol}
          step={0.5}
          min={0}
          isPriceInput
          disabled={updatePurchaseProductMutation.isLoading}
        />
        <label>Quantity</label>
        <Input
          {...formik.getFieldProps('quantity')}
          value={formik.values?.quantity || ''}
          error={formik.touched.quantity && !!formik.errors.quantity}
          errorText={formik.errors.quantity}
          integerLimit={4}
          type='number'
          mode='number'
          step={1}
          min={0}
          disabled={updatePurchaseProductMutation.isLoading}
        />
        <ButtonList className='flex-column my-5'>
          <Button
            label='Update'
            mainButton
            isLoading={updatePurchaseProductMutation.isLoading}
            disabled={isDisabledUpdateButton}
            onClick={handleUpdateClick}
          />
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={updatePurchaseProductMutation.isLoading}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
