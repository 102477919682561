import React from 'react';
import styles from './catalog-item.module.scss'
import clsx from "clsx";
import imgALL from 'shared/assets/images/placehoder.jpg'
import {Icon} from "shared/components/icon/icon";
import {ICON_COLLECTION} from "shared/components/icon/icon-list";
import {RouteGuardActions, RouteGuardEntities} from "casl/ability";
import {Can} from "casl";
import {ICategoryUpdateProps} from "../../pages/catalog-page/use-catalog-page";

interface ICatalogItemProps {
    id: number
    name: string
    image: string | null
    onEditCategory?: (data: ICategoryUpdateProps) => void
    onRemoveCategory?: (categoryId:number) => void
}

const CategoryItemComponent: React.FC<ICatalogItemProps> = ({name, image, onEditCategory, onRemoveCategory, id}) => {

    const onEdit = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        onEditCategory({
            id, name,
            url: image
        })
    }

    const onRemove = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        onRemoveCategory(id)
    }

    return (
        <div className={clsx("card", styles.card)}>
            <figure className="figure">
                <div className={styles.card__icons}>
                    <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                        {onRemoveCategory && <button className={styles.card__icon} onClick={onRemove}>
                            <Icon icon={ICON_COLLECTION.removeCircle}/>
                        </button>}
                        {onEditCategory && <button className={styles.card__icon} onClick={onEdit}>
                            <Icon icon={ICON_COLLECTION.editCircle}/>
                        </button>}
                    </Can>
                </div>
                <img className={clsx("card-img-top", styles.card__img)}
                     src={image
                         ? image
                         : imgALL}
                     alt="A generic square placeholder image with rounded corners in a figure."/>
            </figure>
            <h2 className="text-exl-primary bold  text-center">{name || '...'}</h2>
        </div>
    );
};

export const CategoryItem = React.memo(CategoryItemComponent)
