import {IRegionsList, OptionsApi} from 'app/api/options-api/options-api'
import {AxiosError} from 'axios'
import React, {useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {IRegion} from 'shared/types/base'

export const DeleteRegionModal = () => {
  const [region, setRegion] = useState<IRegion>(null)
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.delete_region, {
    onBeforeOpen: (region: IRegion) => {
      setRegion(region)
    },
  })

  const handleClose = () => {
    setRegion(null)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const deleteRegionMutation = useMutation<IRegion, AxiosError<{message: string}>, IRegion>(
    OptionsApi.deleteRegion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.regions])
        setRegion(null)
        toast.success('Region deleted successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleDeleteClick = () => {
    deleteRegionMutation.mutate(region)
  }

  useEffect(() => {
    handleClose()
    deleteRegionMutation.reset()
  }, [deleteRegionMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!deleteRegionMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Delete Region</h2>
        <p className='text-exl-primary my-10 text-center'>Are you sure for delete this region?</p>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} disabled={deleteRegionMutation.isLoading} />
          <Button
            label='OK'
            mainButton
            isLoading={deleteRegionMutation.isLoading}
            disabled={deleteRegionMutation.isLoading}
            onClick={handleDeleteClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
