import React, { useState } from 'react'
import { heroText } from '../../constants/main'
import Carousel from './carousel/carousel'
import CarouselCard from './carousel/carousel-card/carousel-card'
import {TypeAnimation} from 'react-type-animation'
import clsx from 'clsx'
import styles from './landing-hero.module.scss'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'
import { useQuery } from 'react-query'
import { LANDING_QUERY_KEYS } from 'shared/constants/query-keys'
import { LandingApi } from 'app/api/landing-api/landing-api'

const LandingHero = () => {
  const [isAnimationEnd, setIsAnimationEnd] = useState(false)
  const {isLoading, data, error} = useQuery({
    queryKey: [LANDING_QUERY_KEYS.sliderHero],
    queryFn: async () => {
      const slidersData = await LandingApi.getSLiders()
      const sliderHero = slidersData.data.find(
        (slider) => slider.slug === 'slider1'
      )
      return sliderHero.image
    },
    refetchOnWindowFocus: false,
  })

  return (
    <section id={LANDING_NAVIGATION.hero.id} className={styles['hero']}>
      <div className={styles['hero__container']}>
        <h1
          className={clsx(styles['hero__title'], {
            [styles['hero__title--anim-end']]: isAnimationEnd === true,
          })}
        >
          <TypeAnimation
            preRenderFirstString={true}
            sequence={[
              500,
              heroText.title.split(' ')[0],
              2000,
              heroText.title,
              4000,
              heroText.titleMotivate,
              4000,
              heroText.title,
              () => setIsAnimationEnd(true),
            ]}
            speed={30}
            deletionSpeed={30}
          />
        </h1>

        <h4 className={styles['hero__subtitle']}>{heroText.subtitle}</h4>
        <div className={styles['hero__slider']}>
          {isLoading || error ? (
            <Carousel>
              {[...new Array(5)].map((_, index) => (
                <CarouselCard index={index} key={index} isLoading={isLoading} />
              ))}
            </Carousel>
          ) : (
            <Carousel>
              {data.map((card, index) => (
                <CarouselCard
                  index={index}
                  key={index}
                  image={card.original_url}
                />
              ))}
            </Carousel>
          )}
        </div>
      </div>
    </section>
  )
}

export default LandingHero
