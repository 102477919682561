import React from 'react'
import styles from './carousel-card.module.scss'
import { Loader } from 'shared/components/loader/loader'

interface CarouselCardProps {
  setActive?: (index: number) => void
  image?: string
  index?: number
  isLoading?: boolean
}

const CarouselCard: React.FC<CarouselCardProps> = ({
  setActive,
  image,
  index,
  isLoading,
}) => {
  const handleCardClick = () => {
    setActive(index)
  }

  return (
    <div
      className={`${styles['card']} position-relative`}
      onClick={handleCardClick}
    >
      {isLoading ? <Loader mode='blur' /> : <img src={image} alt='' />}
    </div>
  )
}

export default CarouselCard
