import React, {useState} from 'react'
import {
  DiscountProduct,
  DiscountProductKeys,
  IProductDiscountParams,
} from 'shared/types/discount-types'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import styles from './product-discount-card.module.scss'
import clsx from 'clsx'
import {Tooltip} from 'shared/components/tooltip/tooltip'
import {ButtonLink} from 'shared/components/button/button-link'
import {PRODUCT_ROUTES} from 'shared/config/routes'
import {GeneralDiscountPrice} from '../general-disocunt-price/general-discount-price'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {PriceVariations} from '../price-variations/price-variations'
import {FormikProps, useFormik} from 'formik'
import {
  validateCodesGroups,
  validateGeneral,
} from '../create-product-discount/validation-methods'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {toast} from 'react-toastify'
import {Loader} from 'shared/components/loader/loader'
import {DISCOUNT_MODAL_NAME} from '../../../../../shared/constants/modal-names'
import {getValidationMessage} from '../../helpers/discount-calculation'
import {useModalManager} from '../../../../../shared/context/modal-manager'
import {CustomToastForAddProductToDiscount} from '../add-product-discount-error-toast'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

interface IProductDiscountCardProps {
  product: DiscountProduct
  discountId: number
  isOpenCard?: boolean
  isCreate?: boolean
  onOpenProductRemoveWindow: (productId: number) => void
}

const ProductDiscountCardComponent: React.FC<IProductDiscountCardProps> = ({
  product = null,
  isOpenCard,
  isCreate,
  onOpenProductRemoveWindow,
  discountId,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenCard)
  const [isUsedGeneralPrice, setIsUsedGeneralPrice] = useState(
    product.isGeneral
  )
  const [codesErrors, setCodesErrors] = useState(null)
  const modalManager = useModalManager()
  const updateProductDiscountMutation = useMutation<
    DiscountProduct,
    AxiosError<{
      errors?: {[key: string]: string[]}
      message?: string
      error?: string
    }>,
    IProductDiscountParams
  >(DiscountsApi.updateProductDiscount, {
    onSuccess: (data) => {
      if (data) {
        formik.setValues(data)
        toast.success('Discount successfully updated')
      }
    },
    onError: (
      error: AxiosError<{
        errors?: {[key: string]: string[]}
        message?: string
        error?: string
      }>
    ) => {
      error?.response.data.message &&
        toast.error(
          CustomToastForAddProductToDiscount(error?.response.data.message)
        )
      error?.response.data.error &&
        toast.error(
          CustomToastForAddProductToDiscount(error?.response.data.error)
        )
      if (error?.response.data.message) {
        setCodesErrors(true)
      }
    },
  })

  const formik: FormikProps<DiscountProduct> = useFormik({
    initialValues: product,
    validate: isUsedGeneralPrice ? validateGeneral : validateCodesGroups,
    onSubmit: async (values) => {
      if (product.id && discountId) {
        const generalPriceParams = {
          productId: values.id.toString(),
          discountId: discountId.toString(),
          product: {
            type: values.type ? values.type.toString() : null,
            general_sell_price: Number(values.general_sell_price),
            discount: Number(decimalFormatter(values.discount)) || 0,
            codes: isUsedGeneralPrice ? [] : values.codes,
          },
        }
        const validationMessage = getValidationMessage({
          formData: values,
          isGeneral: isUsedGeneralPrice,
        })
        if (validationMessage.length) {
          modalManager
            .open(DISCOUNT_MODAL_NAME.alert_validation, {
              validationMessageType: validationMessage,
              isGeneralPrice: isUsedGeneralPrice,
            })
            .then(() => {
              updateProductDiscountMutation.mutate(generalPriceParams)
            })
            .catch((error) => {
              return
            })
        } else {
          updateProductDiscountMutation.mutate(generalPriceParams)
        }
      }
    },
  })
  const onRemove = () => onOpenProductRemoveWindow(product.id)

  const onChangeCodesDiscount =
    (hash: string) => (name: DiscountProductKeys, value: number | string) => {
      const tempProduct = {[name]: value}
      const codesGroupIndex = formik.values.codes.findIndex(
        (codes) => codes.hash === hash
      )

      if (codesGroupIndex >= 0) {
        const tempCodes = formik.values.codes
        tempCodes[codesGroupIndex] = {
          ...tempCodes[codesGroupIndex],
          ...tempProduct,
        }
        formik.setFieldValue('codes', [...tempCodes])
        setCodesErrors(null)
      }
    }
  const onChangeGeneralSellPrice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formik.setFieldValue(
      'general_sell_price',
      event.target.value === '' ? '' : decimalFormatter(event.target.value)
    )
  }
  const onChangeCodsSellPrice =
    (hash: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const tempProduct = {sell_price: decimalFormatter(event.target.value)}
      const codesGroupIndex = formik.values.codes.findIndex(
        (codes) => codes.hash === hash
      )
      if (codesGroupIndex >= 0) {
        const tempCodes = formik.values.codes
        tempCodes[codesGroupIndex] = {
          ...tempCodes[codesGroupIndex],
          ...tempProduct,
        }
        formik.setFieldValue('codes', [...tempCodes])
        setCodesErrors(null)
      }
    }
  const onChangeProductDiscount = (
    name: DiscountProductKeys,
    value: number | string
  ) => {
    formik.setFieldValue(name, value)
  }

  const onOpenToggle = () => setIsOpen((prevState) => !prevState)

  const onToggleGeneralPrice = () =>
    setIsUsedGeneralPrice((prevState) => {
      if (prevState === true && !formik.values.codes.length) {
        return prevState
      } else {
        formik.setErrors({})
        return !prevState
      }
    })
  const onUpdate = () => formik.submitForm()

  return (
    <div className='card p-5 shadow mb-5 rounded position-relative'>
      {updateProductDiscountMutation.isLoading && <Loader mode={'blur'} />}
      <div className='d-flex align-items-center justify-content-between'>
        <div className=''>
          <p>Product</p>
          <h4>{product.name}</h4>
        </div>
        <div className='d-flex align-items-center'>
          {product && (
            <ButtonLink
              to={PRODUCT_ROUTES.SINGLE_PRODUCT.generate(`${product.id}`)}
              mode='secondary'
            >
              Go to Product
            </ButtonLink>
          )}

          <Tooltip
            placement='left'
            content='You can remove a certain product from stock and edit all indicators on the corresponding product page.'
          >
            <Icon className='mx-3' icon={ICON_COLLECTION.questionMark} />
          </Tooltip>
          <button
            onClick={onOpenToggle}
            className={clsx(styles['button-open'], {
              [styles['button-open--active']]: !isOpen,
            })}
          >
            <Icon icon={ICON_COLLECTION.largeArrowDown} />
          </button>
        </div>
      </div>
      <div
        className={clsx(styles['section-price'], {
          [styles['section-price--active']]: isOpen,
        })}
      >
        <GeneralDiscountPrice
          onChangeGeneralSellPrice={onChangeGeneralSellPrice}
          errors={formik.errors}
          touched={formik.touched}
          onChangeProductDiscount={onChangeProductDiscount}
          onToggleGeneralPrice={onToggleGeneralPrice}
          isDisabled={isUsedGeneralPrice}
          purchasePrice={formik.values.purchase_price}
          discount={formik.values.discount}
          sellPrice={formik.values.general_sell_price}
          type={Number(formik.values.type)}
        />
        <PriceVariations
          onChangeCodsSellPrice={onChangeCodsSellPrice}
          errors={!!codesErrors}
          onChangeProductDiscount={onChangeCodesDiscount}
          codesGroups={formik.values.codes}
          isDisabled={isUsedGeneralPrice}
        />

        <ButtonList className='justify-content-end mt-5'>
          <Button label='Remove' mode={'remove'} onClick={onRemove} />
          <Button label='Update Product' mainButton onClick={onUpdate} />
        </ButtonList>
      </div>
    </div>
  )
}

export const ProductDiscountCard = React.memo(ProductDiscountCardComponent)
