import { ICurrency } from "shared/types/codes-upload"

export const CurrencyFormatter = (value:number|string,currency:ICurrency): string => {
  if (!value) return `${currency.symbol}0.00`
    const format = Intl.NumberFormat('en-US',{
      style:'currency',
      currency:currency.iso,
      minimumFractionDigits:2,
      maximumFractionDigits:2,
    })
    return format.format(Number(value))
  }
  