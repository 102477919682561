import React from 'react'
import {Button} from 'shared/components/button/button'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'

export const ChatButton: React.FC = () => {
  return (
    <div className={'app-navbar-item'} id='kt_drawer_chat_toggle'>
      <Button
        label={
          <span className='d-flex align-items-center justify-content-center gap-2 app-sidebar-menu'>
            <Icon icon={ICON_COLLECTION.chat} />
            <span className='menu-title text-white'>Support</span>
          </span>
        }
        className='p-0 min-w-auto'
        type='button'
        mainButton
        mode='text'
      />
    </div>
  )
}
