import React, {useState} from 'react';
import {useStateModalManager} from "shared/context/modal-manager";
import {WALLETS_MODAL_NAMES} from "shared/constants/modal-names";
import {Modal} from 'shared/components/modal-base';
import {IOption, Select} from "shared/components/select/select";
import {useSelectController} from "shared/hooks/use-select-controller";
import { CODES_QUERY_KEYS} from "shared/constants/query-keys";
import {SelectsApi} from "app/api/settings-api/selects-api";
import {ButtonList} from "shared/components/button-list/button-list";
import {Button} from "shared/components/button/button";
import {Loader} from "shared/components/loader/loader";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import {WalletsApi} from "app/api/dashboard-api/wallets-api";
import {ICreateWalletParams, IWallet} from "shared/types/api-types/wallets-type";
import {useFormik} from "formik";
import {selectUserData} from "app/store/user/selects";
import {useWalletsState} from "app/store/wallets/state";
import {EventBus, GLOBAL_EVENTS} from "shared/utils/event-bus";

interface IWalletFormValue {
    currency?: number
}

const initialValues: IWalletFormValue = {
    currency: null,
}

interface IErrorForm {
    currency?: string
}

export const CreateWalletModal = () => {
    const user = selectUserData()
    const {setWallet} = useWalletsState()
    const [userId, setUserId] = useState(null);
    const modalState = useStateModalManager(WALLETS_MODAL_NAMES.create_wallet, {
        onBeforeOpen: (userId: number | string) => {
            setUserId(userId)
        }
    });
    const handleClose = () => modalState.close()
    const {
        isLoading: isLoadingCurrencies,
        formationOptions: optionsCurrencies
    } = useSelectController({
        key: CODES_QUERY_KEYS.currencies,
        Fn: SelectsApi.getCurrencies,
        params: 'name',
        format: 'currencyOptions'
    })

    const createWalletMutation = useMutation<
        IWallet,
        AxiosError<{ message: string }>,
        ICreateWalletParams
    >(WalletsApi.createWallet,
        {
            onSuccess: data => {
                if (user.id === Number(userId)) {
                    setWallet(data)
                } else {
                    EventBus.emit(GLOBAL_EVENTS.reset_invoice)
                }
                toast.success('Wallet successfully added !')
                modalState.close()
            },
            onError: error => {
                toast.error(error.response.data.message)
            }
        })
    const handleCreateWallet = (values: IWalletFormValue) => {
        if (userId) {
            createWalletMutation.mutate({
                currency_id: values.currency,
                userId: userId
            })
        }
    }
    const validate = (values: IWalletFormValue) => {
        const error: IErrorForm = {}
        if (!values.currency) {
            error.currency = 'Currency is required field !'
        }
        return error
    }

    const formik = useFormik<IWalletFormValue>(
        {
            initialValues,
            onSubmit: handleCreateWallet,
            validate
        }
    )
    const handleSetValue = (value: IOption) => {
        formik.setFieldValue('currency', value.id)
    }
    const onSubmitForm = () => formik.submitForm()

    return (
        <Modal.Root
            open={modalState.open}
            onClose={handleClose}
            isClosable={!createWalletMutation.isLoading}
        >
            <div className="p-7 position-relative">
                {createWalletMutation.isLoading && <Loader mode="blur"/>}
                <h2 className='mb-5 text-center'>Add new wallet</h2>
                <div className="mb-7">
                    <p>Currency</p>
                    <Select options={optionsCurrencies}
                            isLoading={isLoadingCurrencies}
                            value={formik.values.currency}
                            onChange={handleSetValue}
                            error={!!formik.errors.currency}
                            errorText={formik.errors.currency}
                    />
                </div>
                <ButtonList className='justify-content-end'>
                    <Button
                        label="Cancel"
                        onClick={handleClose}
                        disabled={createWalletMutation.isLoading}
                        isLoading={createWalletMutation.isLoading}
                    />
                    <Button
                        mainButton
                        label="Create Wallet"
                        disabled={createWalletMutation.isLoading}
                        isLoading={createWalletMutation.isLoading}
                        onClick={onSubmitForm}
                    />
                </ButtonList>
            </div>
        </Modal.Root>
    );
};

