export const LANDING_NAVIGATION = {
  benefits: {
    id: 'benefits',
    path: '#benefits',
  },
  contact: {
    id: 'contact',
    path: '#contact',
  },
  api: {
    id: 'api',
    path: '#api',
  },
  discover: {
    id: 'discover',
    path: '#discover',
  },
  hero: {
    id: 'hero',
    path: '#hero',
  },
  solutions: {
    id: 'solutions',
    path: '#solutions',
  },
  offers: {
    id: 'businessHub',
    path: '#businessHub',
  },
}
