import React from 'react'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {IUploadHistory} from 'shared/types/codes-upload'
import {UploadHistoryTableRow} from './upload-history-table-row'
import clsx from 'clsx'
import CheckBox from 'shared/components/check-box/check-box'

export interface IUploadHistoryTable {
  codesList: IUploadHistory[]
  isLoading: boolean
  handleCheckboxChange: (id: number) => void
  handleSelectAll: () => void
  isSelectAll: boolean
}

export const UploadHistoryTable: React.FC<IUploadHistoryTable> = ({
  codesList,
  isLoading,
  handleCheckboxChange,
  handleSelectAll,
  isSelectAll,
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6  border-bottom border-gray-200 bg-light '>
              <th className='text-center'>
                <CheckBox onChange={handleSelectAll} checked={isSelectAll} />
              </th>
              <th>Upload ID</th>
              <th>Date</th>
              <th>Supplier Name</th>
              <th>Product Name</th>
              <th>Quantity</th>
              <th>Code Type</th>
              <th>Errors</th>
            </tr>
          </thead>

          {codesList && codesList.length ? (
            <tbody>
              {codesList.map((code) => (
                <UploadHistoryTableRow
                  code={code}
                  key={code.id}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={8}>Nothing was found</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
