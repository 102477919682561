import {useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {DISCOUNTS_QUERY_KEYS} from 'shared/constants/query-keys'
import {toast} from 'react-toastify'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {AxiosError} from 'axios'
import {
  DiscountType,
  IDiscountListResponse,
  IDiscountUpdateBodyParams,
  IDiscountUpdateParams,
} from 'shared/types/discount-types'
import {useModalManager} from 'shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from 'shared/constants/modal-names'

export const useDiscountsPage = () => {
  const [params, setParams] = useState({page: 1})
  const modalManager = useModalManager()
  const queryClient = useQueryClient()
  const discountsQuery = useQuery({
    queryKey: [DISCOUNTS_QUERY_KEYS.discount_list, params],
    queryFn: async () => await DiscountsApi.getDiscounts(params),
    onError: (error) => {
      toast.error('Something went wrong.')
    },
  })
  const onStopLoading = () => {
    queryClient.setQueryData(
      [DISCOUNTS_QUERY_KEYS.discount_list, params],
      (prevState: IDiscountListResponse) => {
        const tempDiscounts = prevState.data.map((discount) => {
          return {...discount, isLoading: false}
        })
        return {...prevState, data: [...tempDiscounts]}
      }
    )
  }
  const onResetDiscount = (id: number) => {
    queryClient.setQueryData(
      [DISCOUNTS_QUERY_KEYS.discount_list, params],
      (prevState: IDiscountListResponse) => {
        const discountTempList = prevState.data.filter(
          (discount) => discount.id !== id
        )
        return {...prevState, data: discountTempList}
      }
    )
  }

  const onStartLoading = (index: number) => {
    queryClient.setQueryData(
      [DISCOUNTS_QUERY_KEYS.discount_list, params],
      (prevState: IDiscountListResponse) => {
        const list = [...prevState.data]
        list.splice(index, 1, {...list[index], isLoading: true})
        return {...prevState, data: [...list]}
      }
    )
  }

  const updateDiscountMutation = useMutation<
    DiscountType,
    AxiosError<{message?: string; error: string}>,
    IDiscountUpdateParams
  >(DiscountsApi.updateDiscount, {
    onSuccess: (data: DiscountType) => {
      queryClient.setQueryData(
        [DISCOUNTS_QUERY_KEYS.discount_list, params],
        (prevState: IDiscountListResponse) => {
          const discountId = prevState.data.findIndex(
            (discount) => discount.id === data.id
          )
          prevState.data.splice(discountId, 1, data)
          return prevState
        }
      )
      queryClient.invalidateQueries([
        DISCOUNTS_QUERY_KEYS.discount_list,
        params,
      ])
      onStopLoading()
    },
    onError: (data) => {
      data.response.data.message && toast.error(data.response.data.message)
      data.response.data.error && toast.error(data.response.data.error)
      onStopLoading()
    },
  })
  const onUpdateDiscount = (
    id: number,
    params: IDiscountUpdateBodyParams,
    index: number
  ) => {
    updateDiscountMutation.mutate({id, body: params})
    onStartLoading(index)
  }
  const onOpenRemoveDiscountWindow = (id: number) => () => {
    modalManager
      .open(DISCOUNT_MODAL_NAME.remove_discount, id)
      .then((index) => {
        onResetDiscount(Number(index))
      })
      .catch((error) => {
        return
      })
  }
  const onPageChange = (page: number) => {
    setParams((printValue) => ({...printValue, page: page}))
  }

  useEffect(() => {
    if (
      discountsQuery.data?.meta.current_page > 1 &&
      !discountsQuery.data?.data.length
    ) {
      onPageChange(1)
    }
  }, [discountsQuery.data])

  return {
    models: {
      isLoadingPage: discountsQuery.isLoading,
      discounts: discountsQuery.data?.data ?? [],
      meta: discountsQuery.data?.meta ?? null,
    },
    commands: {
      onUpdateDiscount,
      onPageChange,
      onOpenRemoveDiscountWindow,
    },
  }
}
