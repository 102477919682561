import React from 'react'
import clsx from 'clsx'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {AdminSettingsTableRow} from './admin-settings-table-row'
import { IAdminSetting } from 'shared/types/api-types/admin-setting-types'

export interface IAdminSettingsTable {
  settings: IAdminSetting[]
  isLoading: boolean
  isSaveButtonLoading: boolean
  handleSaveChanges: (setting:IAdminSetting) => void
}

export const AdminSettingsTable: React.FC<IAdminSettingsTable> = ({
  settings,
  isLoading,
  isSaveButtonLoading,
  handleSaveChanges,
}) => {
  return (
    <div className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}>
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light text-exl-primary'>
              <th className='px-7'>Key</th>
              <th className='px-7'>Value</th>
              <th className='text-end'>Actions</th>
            </tr>
          </thead>
          {settings && settings.length ? (
            <tbody>
              {settings.map((setting:IAdminSetting) => (
                <AdminSettingsTableRow
                  key={setting.key}
                  setting={setting}
                  isSaveButtonLoading={isSaveButtonLoading}
                  handleSaveChanges={handleSaveChanges}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={3}>No settings.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
