import React, {useEffect, useMemo, useState} from 'react';
import {useQuery} from "react-query";
import {STATISTICS_QUERY_KEYS} from "shared/constants/query-keys";
import {StatisticsApi} from "app/api/dashboard-api/statistics-api";
import {IStatisticFilters, PeriodsType} from "shared/types/api-types/statistic-type";
import {BarChart} from "./bar-chart";
import {STATISTIC_MODAL_NAME} from "shared/constants/modal-names";
import {EventBus, STATISTIC_FILERS_EVENTS} from "shared/utils/event-bus";
import {useModalManager} from "shared/context/modal-manager";
import {DownloadService} from "shared/services/download-service";
import {toast} from "react-toastify";
import { INITIAL_PARAMS } from '../../constants/init-params';

export const CodesUploadStatistic = () => {
    const [params, setParams] = useState<IStatisticFilters>(INITIAL_PARAMS)
    const [isCSVLoading, setIsCSVLoading] = useState(false);
    const modalManager = useModalManager();
    const codesUploadStatisticQuery = useQuery({
        queryKey: [STATISTICS_QUERY_KEYS.codes_upload_statistic, params],
        queryFn: () => StatisticsApi.getCodesUploadStatistic(params),
        onError: () => {
            toast.error('Something went wrong with Codes Upload chart')
        },
    })
    const codesUploadStatistic = useMemo(() => {
        const datesArray = [];
        const valuesArray: number[] = [];
        if (!codesUploadStatisticQuery.data || !codesUploadStatisticQuery.data.data) {
            return {
                datesArray,
                valuesArray: [{name: '+', data: []}]
            }
        }
        for (const date in codesUploadStatisticQuery.data.data) {
            datesArray.push(date);
            valuesArray.push(codesUploadStatisticQuery.data.data[date]);
        }
        return {
            datesArray,
            valuesArray: [{name: 'Codes Upload', data: valuesArray}]
        }
    }, [codesUploadStatisticQuery.data]);

    const handelChangePeriod = (period: PeriodsType) => {
        setParams(prevState => {
            return {...prevState, period}
        })
    }
    const onOpenFilters = () => {
        modalManager.open(STATISTIC_MODAL_NAME.filter_statistics, {
            key: STATISTIC_FILERS_EVENTS.codes_upload_statistic,
            params,
        });
    };
    const onDownloadCSV = async () => {
        try {
            setIsCSVLoading(true)
            const response = await StatisticsApi.getCodesUploadStatistic({...params, csv: 1})
            await DownloadService.downloadObjectAsZip(response, 'Codes Upload Statistic CSV')
            setIsCSVLoading(false)
        } catch (error) {
            setIsCSVLoading(false)
            toast.error('Download CSV Error!')
        }
    }

    useEffect(() => {
        EventBus.on(STATISTIC_FILERS_EVENTS.codes_upload_statistic, (data) => setParams({...data}))
        return () => {
            EventBus.off(STATISTIC_FILERS_EVENTS.codes_upload_statistic, (data) => setParams({...data}))
        };
    }, []);


    return (
        <BarChart
            onDownload={onDownloadCSV}
            title='Codes Upload'
            onOpenFilters={onOpenFilters}
            names={codesUploadStatistic.datesArray}
            isLoading={codesUploadStatisticQuery.isLoading || isCSVLoading}
            period={params.period || 3}
            values={codesUploadStatistic.valuesArray}
            handelChangePeriod={handelChangePeriod}
            isYAxisInteger={true}
        />
    );
};

