/* eslint-disable react-hooks/rules-of-hooks */
// @ts-ignore
import * as React from 'react'
import {selectIsLoggedIn, selectIsLoggedOut} from '../store/auth/selects'
import {AUTH_ROUTES, DASHBOARD_ROUTES} from '../../shared/config/routes'
import {Navigate, useLocation} from 'react-router-dom'
import {useAbility} from '@casl/react'
import {RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import {AbilityContext} from 'casl'
import {DrawerComponent} from '_metronic/assets/ts/components'
import {useEffect} from 'react'
import {useMenuList} from 'shared/hooks/use-menu-list'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AuthGuard: React.FC<React.PropsWithChildren> = ({children}) => {
  // eslint-disable-next-line react/display-name
  const isAuth = selectIsLoggedIn()
  const location = useLocation()
  const ability = useAbility(AbilityContext)
  const isLoggedOut = selectIsLoggedOut()
  
  const isAuthPage = location.pathname.startsWith(AUTH_ROUTES.ROOT.path)
  const state = !isLoggedOut ? (location.state as {from: Location}) : null

  const {links} = useMenuList(ability)
  useEffect(() => {
    DrawerComponent.reinitialization()
  }, [])

  if (!isAuth && !isAuthPage) {
    return (
      <Navigate to={AUTH_ROUTES.ROOT.path} state={{from: location}} replace />
    )
  }
  if (isAuth && isAuthPage) {
    if (
      state && state.from &&
      links.find((link) => state.from.pathname.includes(link.path))
    ) {
      return <Navigate to={state.from.pathname} replace />
    }
    if (ability.can(RouteGuardActions.manage, RouteGuardEntities.Admin)) {
      return <Navigate to={DASHBOARD_ROUTES.STATISTIC.path} replace />
    } else if (
      ability.can(RouteGuardActions.manage, RouteGuardEntities.Customer)
    ) {
      return <Navigate to={DASHBOARD_ROUTES.CATALOG.path} replace />
    } else if (
      ability.can(RouteGuardActions.manage, RouteGuardEntities.CustomerNotAdmin)
    ) {
      return <Navigate to={DASHBOARD_ROUTES.ACCOUNT.path} replace />
    } else {
      return <Navigate to={DASHBOARD_ROUTES.ACCOUNT.path} replace />
    }
  }
  return children || null
}
