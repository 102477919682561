import React from 'react';
import {Outlet} from "react-router-dom";
import { AccountLayout } from 'app/layouts/account-layout';

export const CustomersPagesWrapper = () => {
    return (
        <AccountLayout>
           <Outlet/>
        </AccountLayout>
    );
};

