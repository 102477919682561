/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import {ErrorsLayout} from './ErrorsLayout'
import NotFoundPage from "../../../shared/components/404/not-found-page";

const ErrorsPage = () => (
    <Routes>
            <Route path='404' element={<NotFoundPage/>}/>
            <Route path='500' element={<NotFoundPage/>}/>
            <Route index element={<NotFoundPage/>}/>
    </Routes>
)

export {ErrorsPage}
