import React from 'react'
import { discoverTitle } from 'modules/landing/constants/main'
import {discoverCard} from 'modules/landing/constants/main'
import { DiscoverCard } from './discover-card/discover-card'
import { useInView } from 'react-intersection-observer'
import clsx from 'clsx'
import styles from './landing-discover.module.scss'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'

const LandingDiscover = () => {
  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  })
  return (
    <section id={LANDING_NAVIGATION.discover.id} className={styles['discover']}>
      <div className={styles['discover__container']}>
        <h2
          className={clsx(styles['discover__title'], {
            [styles['discover__title--in-view']]: titleInView,
          })}
          ref={titleRef}
        >
          {discoverTitle.title}
        </h2>
        <div className={`row ${styles['discover__cards']}`}>
          {discoverCard.map((card, index) => (
            <DiscoverCard key={card.id} cardContent={card} index={index} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default LandingDiscover
