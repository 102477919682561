import React from 'react'
import {Button} from 'shared/components/button/button'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {useCurrenciesPage} from './use-currencies-options'
import {CurrenciesTable} from '../../components/tables/currencies/currencies-table'
import {DeleteCurrencyModal} from '../../components/modals/currencies/delete-currency-modal'
import {CreateCurrencyModal} from '../../components/modals/currencies/create-currency-modal'

export const CurrenciesOptions = () => {
  const {models, commands} = useCurrenciesPage()
  return (
    <>
      <div className='d-flex flex-end'>
        <Button label='Add new currency' mainButton onClick={commands.handleCreateClick} />
      </div>
      <div className='card position-relative'>
        <CurrenciesTable
          currencies={models.currencies}
          isLoading={models.isTableLoading}
          isSaveButtonLoading={models.isSaveButtonLoading}
          handleSaveChanges={commands.handleUpdateClick}
          handleDeleteClick={commands.handleDeleteClick}
        />
        {models.currencies && models.currencies.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <DeleteCurrencyModal />
      <CreateCurrencyModal />
    </>
  )
}
