import {BaseHttpServices} from "../../../shared/services/base-http-services";
import {IUser} from "../../../shared/types/user";

export class UserApiService  {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getUserById = async (userId): Promise<IUser> => {
        const payload = await this.http.get(`/users/${userId}`);

        return payload.data.data;
    };
        
}

export const UserApi = new UserApiService(new BaseHttpServices());