import {useEffect, useMemo, useState} from 'react';
import { useQuery} from "react-query";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { CUSTOMER_QUERY_KEYS } from 'shared/constants/query-keys';
import { IOption } from 'shared/components/select/select';
import { useDebounce } from '_metronic/helpers';
import { initialFormName } from './customers-page';
import { CustomerApi } from 'app/api/user-api/customer-api';
import { useModalManager } from 'shared/context/modal-manager';
import { DASHBOARD_MODAL_NAMES } from 'shared/constants/modal-names';
import { DASHBOARD_ROUTES } from 'shared/config/routes';
import { removeEmptyFields } from 'shared/utils/remove-empty-fields';
import { DownloadReportApi } from 'app/api/dashboard-api/download-report-api';
import { DownloadService } from 'shared/services/download-service';
import { toast } from 'react-toastify';
import { BlobErrorResponse } from 'shared/helpers/blob-error-response';

export const useCustomersPage = () => {    
    const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams()    
    const location = useLocation()
    const search = new URLSearchParams(location.search)   
    const [searchValue,setSearchValue] = useState<string>(search.get('company_name'));
    const navigate = useNavigate();  
    const modalManager = useModalManager();

    const queryParams = useMemo(() => {
        return removeEmptyFields({
            company_name: search.get('company_name'),
            status: Number(search.get('status')) || null,
            cart: Number(search.get('cart')) || null,
            page: search.get('page') ? Number(search.get('page')) : 1
        })
    }, [location.search])

    const customersQuery = useQuery({
        queryKey:[CUSTOMER_QUERY_KEYS.customers,queryParams],
        queryFn:async () => await CustomerApi.getCustomers(queryParams),
    })   
    

    const manageParams = (name: string, value: string | number | null) => {
        const ParamsList = {
            company_name: queryParams.company_name ? {company_name: queryParams.company_name} : {},
            status: queryParams.status ? { status: `${queryParams.status}` } : {},
            cart: queryParams.cart ? {cart: `${queryParams.cart}`} : {},
            page: queryParams.page ? {page: `${queryParams.page}`} : {},
        }
        ParamsList[name] = value !== null ? {[name]: `${value}`} : {}
        return ParamsList
    }

    const debouncedSearchTerm = useDebounce(
        searchValue, 1000)

    useEffect(() => {
        const params = manageParams('company_name', debouncedSearchTerm)
        setSearchParams({
            ...params.company_name,
            ...params.cart,
            ...params.status,
        })
    }, [debouncedSearchTerm]);

    const handleChangeFormValue = (name: initialFormName, value: IOption) => {
        const params = manageParams(name, value ? value.id : null)
        setSearchParams({
          ...params.company_name,
          ...params.cart,
          ...params.status,
        })
    }

    const setCurrentPage = (page: number) => {
        const params = manageParams('page', page)
        setSearchParams({
          ...params.company_name,
          ...params.status,
          ...params.cart,
          ...params.page,
        })
    }
    
    const handleDeclineCustomer = () => {
        modalManager.open(DASHBOARD_MODAL_NAMES.decline_customer_warning);
    }; 
    
    const handleRemoveCustomer = (userId:number) => {
        modalManager.open(DASHBOARD_MODAL_NAMES.remove_customer, userId);
    };
           
    const handleGoBackClick = () => {
      navigate(DASHBOARD_ROUTES.CUSTOMERS.path);
    }    

    const customers = useMemo(()=> {
        if (customersQuery.data)
            return customersQuery.data.data.map(user=>({...user.customer,email:user.email,role:user.role, created_at: user.created_at}))
        return []
    }, [customersQuery.data])

    const onDownloadCSV = async () => {
      try {
        setIsCSVLoading(true)
        const params = {
          company_name: queryParams.company_name,
          status: queryParams.status,
          cart: queryParams.cart,
        }
        const csvData = await DownloadReportApi.getCustomersReportCSV(params)
        DownloadService.downloadObjectAsZip(csvData, 'Customers Report')
        setIsCSVLoading(false)
        toast.success('Download CSV Success!')
      } catch (error) {
        setIsCSVLoading(false)
        toast.error(await BlobErrorResponse(error))
      }
    }

    const isTableLoading = customersQuery.isLoading
    
    return {
        models: {
            values: queryParams,
            customers,
            meta: customersQuery.data ? customersQuery.data.meta : null,
            isTableLoading,
            searchValue,
            isCSVLoading: isCSVLoading
        },
        commands: {
            handleChangeFormValue,
            setCurrentPage,
            setSearchValue,
            handleDeclineCustomer,
            handleGoBackClick,
            handleRemoveCustomer,
            onDownloadCSV
        }
    }
}


