import React from 'react'
import {useSupplierTrackerPage} from './use-supplier-tracker'
import {SupplierTrackerTable} from '../../components/table/supplier-tracker-table/supplier-tracker-table'
import {AddSupplierModal} from '../../components/modals/add-supplier-modal'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {SuppliersTrackerSearchBar} from '../../components/supplier-tracker-search-bar'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {SUPPLIERS_NAV_ROUTES} from '../../supliers-routes'
import { Button } from 'shared/components/button/button'
import { UpdateExternalProductModal } from '../../components/modals/update-product-modal'
import { DeleteProductModal } from '../../components/modals/delete-product-modal'
import { AddProductModal } from '../../components/modals/add-product-modal'

export const SupplierTrackerPage = () => {
  const {models, commands} = useSupplierTrackerPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold'>Supplier Tracker</h1>
        <div className='d-flex gap-5 align-items-center'>
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
          <Button
            label='Create External Product'
            type='button'
            mainButton
            onClick={commands.handleCreateProductClick}
          />
          <Button
            label='Recount All'
            type='button'
            mainButton
            disabled={models.isRecountLoading}
            isLoading={models.isRecountLoading}
            onClick={commands.handleRecountClick}
          />
        </div>
      </div>
      <div className='card p-7 position-relative'>
        <div className='d-flex align-items-center justify-content-between flex-wrap'>
          <TabMenu routes={SUPPLIERS_NAV_ROUTES} className='mb-5' />
          <h4 className='text-muted'>Rates update automatically at 1:00 PM daily.</h4>
        </div>
        <SuppliersTrackerSearchBar
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
        />
        <SupplierTrackerTable
          suppliers={models.suppliers}
          isLoading={models.isTableLoading}
          currencies={models.currencies}
          handleTrackerRowClick={commands.handleTrackerRowClick}
          handleAddSupplierClick={commands.handleAddSupplierClick}
          handleEditProductClick={commands.handleEditProductClick}
          handleDeleteProductClick={commands.handleDeleteProductClick}
        />
        {models.suppliers &&
        models.suppliers.length &&
        !models.isTableLoading ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <AddSupplierModal />
      <UpdateExternalProductModal />
      <DeleteProductModal />
      <AddProductModal />
    </>
  )
}
