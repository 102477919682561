import React, {useEffect} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import {Input} from 'shared/components/input/input'
import {PURCHASES_QUERY_KEYS} from 'shared/constants/query-keys'
import {PURCHASES_MODAL_NAME} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {IPurchaseDTO, PurchaseApi} from 'app/api/purchases-api/purchases-api'
import {IPurchase} from 'shared/types/purchase'
import {IOption, Select} from 'shared/components/select/select'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {Textarea} from 'shared/components/textarea/textarea'
import {validationSchema} from '../schemas/purchase-validation-schema'
import { removeEmptyFields } from 'shared/utils/remove-empty-fields'
import { IAllSupplier } from 'app/api/suppliers-api/suppliers-api';
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

const INITIAL_VALUES = {
  invoice_number: null,
  vat_number: null,
  currency_id: null,
  supplier_id: null,
  comment: null,
  fee: null,
}

export const CreatePurchaseModal = () => {
  const modalState = useStateModalManager(PURCHASES_MODAL_NAME.create_purchase)
  const queryClient = useQueryClient()
  const handleClose = () => {
    formik.resetForm()
    modalState.close()
  }

  const createPurchaseMutation = useMutation<
    IPurchase,
    AxiosError<{message: string}>,
    IPurchaseDTO
  >(PurchaseApi.createNewPurchase, {
    onSuccess: (data: IPurchase) => {
      queryClient.invalidateQueries([PURCHASES_QUERY_KEYS.purchases])
      toast.success(`Purchase was created successfully!`)
    },
    onError: async (error) => {
      toast.error(error.response.data.message)
    },
  })

  const onSubmit = (values: IPurchaseDTO) => {
    createPurchaseMutation.mutate(removeEmptyFields({...values, fee: decimalFormatter(values.fee)}))
  }

  const formik = useFormik<IPurchaseDTO>({
    initialValues:INITIAL_VALUES,
    onSubmit,
    validationSchema,
  })

  const handleChangeSelects = (name: string) => (value: IOption | null) => {
    formik.setFieldTouched(name,true)
    formik.setFieldValue(name, value ? value.id : null)
  }

  const handleCreatePurchase = () => {
    formik.submitForm()
  }

  const {
    options: suppliersOptions,
    isLoading: isLoadingSuppliers,
    handleInputChange:handleInputSupplierChange,
    formationOptions: optionsSuppliersMemo,
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    params: 'name',
  })

  const {
    options: currenciesOptions,
    isLoading: isLoadingCurrencies,
    formationOptions: optionsCurrenciesMemo,
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
  })

  const currentCurrency = currenciesOptions.find((cur) => formik.values?.currency_id === cur.id)
  const currencySymbol = currentCurrency?.symbol

  const isDisabledCreateButton =
    createPurchaseMutation.isLoading || !!Object.keys(formik.errors).length || !!!Object.values(removeEmptyFields(formik.values)).length

  useEffect(() => {
    if (createPurchaseMutation.isSuccess) {
      handleClose()
      createPurchaseMutation.reset()
    }
  }, [createPurchaseMutation.isSuccess])

  useEffect(() => {
    const selectedSupplier = suppliersOptions.find(
      (supplier) => supplier.id === formik.values.supplier_id
    )
    if (selectedSupplier) {
      const vatNumber = (selectedSupplier as IAllSupplier).VAT_number
      formik.setFieldValue('vat_number', vatNumber || '')
    }
  }, [formik.values.supplier_id])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!createPurchaseMutation.isLoading}
    >
      <div className='p-10'>
        <h2 className='text-center mb-5'>New Purchase</h2>
        <div>
          <label>Invoice number</label>
          <Input
            {...formik.getFieldProps('invoice_number')}
            disabled={createPurchaseMutation.isLoading}
            placeholder='Invoice number'
            error={
              formik.touched.invoice_number && !!formik.errors.invoice_number
            }
            errorText={formik.errors.invoice_number}
          />
          <label>Supplier</label>
          <Select
            isClearable
            options={optionsSuppliersMemo}
            isLoading={isLoadingSuppliers}
            isDisabled={createPurchaseMutation.isLoading}
            {...formik.getFieldProps('supplier_id')}
            error={formik.touched.supplier_id && !!formik.errors.supplier_id}
            errorText={formik.touched.supplier_id ? formik.errors.supplier_id : null}
            handleInputChange={handleInputSupplierChange}
            onChange={handleChangeSelects('supplier_id')}
            placeholder='Supplier'
          />
          <label>Vat number</label>
          <Input
            {...formik.getFieldProps('vat_number')}
            placeholder='Vat number'
            error={!!formik.errors.vat_number}
            errorText={formik.errors.vat_number}
            disabled={createPurchaseMutation.isLoading}
          />
          <label>Currency</label>
          <Select
            isClearable
            options={optionsCurrenciesMemo}
            isLoading={isLoadingCurrencies}
            isDisabled={createPurchaseMutation.isLoading}
            {...formik.getFieldProps('currency_id')}
            error={formik.touched.currency_id && !!formik.errors.currency_id}
            errorText={formik.touched.currency_id ? formik.errors.currency_id : null}
            onChange={handleChangeSelects('currency_id')}
            placeholder='Currency'
          />
          <label>Fee</label>
          <Input
            {...formik.getFieldProps('fee')}
            type='number'
            currency={currencySymbol}
            isPriceInput
            error={formik.touched.fee && !!formik.errors.fee}
            errorText={formik.errors.fee}
            disabled={createPurchaseMutation.isLoading}
          />
          <label>Comment</label>
          <Textarea
            {...formik.getFieldProps('comment')}
            placeholder='Comment...'
            error={formik.touched.comment && !!formik.errors.comment}
            errorText={formik.errors.comment}
            mode='light'
            disabled={createPurchaseMutation.isLoading}
          />
        </div>
        <ButtonList className='flex-column my-5'>
          <Button
            label='Create'
            mainButton
            isLoading={createPurchaseMutation.isLoading}
            disabled={isDisabledCreateButton}
            onClick={handleCreatePurchase}
          />
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={createPurchaseMutation.isLoading}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
