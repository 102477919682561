import React from 'react'
import {FormikProps} from 'formik/dist/types'
import {Input} from '../../../../shared/components/input/input'
import {Button} from '../../../../shared/components/button/button'
import {AUTH_ROUTES, LANDING_ROUTES} from '../../../../shared/config/routes'
import {ButtonLink} from '../../../../shared/components/button/button-link'
import {useIntl} from 'react-intl'
import { ITwoFactorAuthFormikProps } from 'app/modules/auth/components/TwoFactorAuth'
import { Link } from 'react-router-dom'
 
interface ITwoFactorAuthPage {
  formik: FormikProps<ITwoFactorAuthFormikProps>
  isLoading: boolean
}

export const TwoFactorAuthInner: React.FC<ITwoFactorAuthPage> = ({
  formik,
  isLoading,
}) => {
  const intl = useIntl()
  return (
    <>
      <div className='fv-row mb-6 w-sm-300px mx-auto'>
        <Input
          placeholder='Code'
          {...formik.getFieldProps('one_time_password')}
          type='text'
          name='one_time_password'
          autoComplete='on'
          error={
            formik.touched.one_time_password &&
            !!formik.errors.one_time_password
          }
          errorText={formik.errors.one_time_password}
          disabled={isLoading}
        />
      </div>

      <div className='d-grid mb-3 w-sm-300px mx-auto'>
        <Button
          label={intl.formatMessage({id: 'EXL.AUTH.LOGIN.BUTTON'})}
          mainButton
          type='submit'
          disabled={!formik.isValid || isLoading}
          isLoading={isLoading}
        />
      </div>

      <div className='d-grid mb-3 w-sm-300px mx-auto'>
        <ButtonLink to={AUTH_ROUTES.ROOT.path} mode='secondary'>
          {intl.formatMessage({id: 'EXL.AUTH.BACK'})}
        </ButtonLink>
      </div>
      <div className='mx-auto mb-13 w-sm-300px d-flex align-items-center justify-content-center'>
        <p>
          I don't have codes, need <Link to={`${LANDING_ROUTES.ROOT.path}#contact`}>help</Link>
        </p>
      </div>
      <div className='d-flex justify-content-center mb-20'>
        <ButtonLink to={LANDING_ROUTES.ROOT.path}>
          {intl.formatMessage({id: 'EXL.AUTH.BACK_TO_SITE'})}
        </ButtonLink>
      </div>
    </>
  )
}
