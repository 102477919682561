export const CUSTOMER_QUERY_KEYS = {
    customers: 'customers',
    customer: 'customer',
    countries:'countries',
    businessTypes:'businessTypes',
    apiKeys:'apiKeys',
    chat: 'chat',
    two_factor: 'two_factor'
}
export const CODES_QUERY_KEYS = {
    codes: 'codes',
    suppliers: 'suppliers',
    product: 'product',
    currencies: 'currencies',
    purchases: 'purchases'
}
export const UPLOAD_HISTORY_QUERY_KEYS = {
  codes: 'upload_codes',
}
export const ERROR_CODES_QUERY_KEYS = {
  codes: 'error_codes',
}
export const ORDER_QUERY_KEYS = {
    orders: 'orders',
    order: 'order',
    all_orders: 'all_orders'
}
export const CATALOG_QUERY_KEYS = {
    catalog: 'catalog',
}
export const CATEGORIES_QUERY_KEYS = {
    categories: 'categories',
    category_page: 'category_page',
    single_product: 'single_product',
    codes_group: 'codes_group',
}
export const REGIONS_QUERY_KEYS = {
    regions: 'regions',
}

export const WALLETS_QUERY_KEYS = {
    user_wallets: 'user_wallets',
    all_wallets: 'all_wallets',
    currencies: 'currencies'
}
export const INVOICE_QUERY_KEYS = {
    invoices: 'invoices',
    all_invoices: 'all_invoices'
}
export const PURCHASES_QUERY_KEYS = {
    purchases: 'purchases',
    purchase: 'purchases',
    suppliers: 'suppliers',
    currencies: 'currencies',
    products: 'products',
    purchase_product: 'purchase_product',
    product: 'product',
}

export const SUPPLIERS_TRACKER_QUERY_KEYS = {
  suppliers_traker: 'suppliers_traker',
  product_suppliers: 'product_suppliers',
  all_suppliers: 'all_suppliers',
  single_supplier: 'single_supplier',
  create_supplier: 'create_supplier',
  suppliers: 'suppliers',
  currencies: 'currencies',
  regions: 'regions',
  product_name: 'product_name',
}

export const STATISTICS_QUERY_KEYS = {
    top_selling: "top_selling",
    last_orders: "last_orders",
    top_clients: 'top_clients',
    low_stock: 'low_stock',
    api_usage: 'api_usage',
    revenue: 'revenue',
    proffit: 'proffit',
    units_sold: 'units_sold',
    orders_chart: 'orders_chart',
    orders_avg: 'orders_avg',
    codes_upload_statistic: 'codes_upload_statistic',
    users_on_site: 'users_on_site',
    general_statistic: 'general_statistic',
}

export const OPTIONS_QUERY_KEYS = {
    regions:'regions',
    currencies:'currencies',
    brands:'brands',
    sliders:'sliders',
    admin_settings: 'admin_settings'
}
export const DISCOUNTS_QUERY_KEYS = {
    discount_list: 'discount_list',
    discount: 'discount',
    discount_users: 'discount_users',

}

export const LANDING_QUERY_KEYS = {
    sliderHero: 'sliderHero',
    sliderOffer: 'sliderOffer',
    brands: 'brands',
}

export const CART_QUERY_KEYS = {
    cart:'cart'
}

export const DOCUMENTATION_QUERY_KEYS = {
  documentation: 'documentation',
}