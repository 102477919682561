import React from 'react'
import {ICustomer} from 'shared/types/user'
import {useNavigate} from 'react-router-dom'
import {CART_ROUTE, CUSTOMERS_ROUTES} from 'shared/config/routes'
import {DateFormatter} from 'shared/utils/date-formatter'
import {
  ActionsButton,
  IActionsButtons,
} from 'shared/components/button/actions-button'
import {useTemporaryUserState} from 'app/store/temporary-customer/state'
import {CUSTOMER_STATUS} from 'shared/constants/constants'
import { useModalManager } from 'shared/context/modal-manager'
import { DASHBOARD_MODAL_NAMES } from 'shared/constants/modal-names'

interface ICustomersTableRow {
  customer: ICustomer
}

export const STATUS_COLOR = {
  1: 'success',
  2: 'warning',
  3: 'danger',
  4: 'danger'
}

const CustomersTableRowComponent: React.FC<ICustomersTableRow> = ({
  customer,
}) => {
  const navigate = useNavigate()
  const modalManager = useModalManager()
  const handleViewClick = () => {
    navigate(CUSTOMERS_ROUTES.CUSTOMER.generate(`${customer.user_id}`))
  }
  const {initTemporaryUser} = useTemporaryUserState()
  const handleCartClick = () => {
    initTemporaryUser(customer.user_id)
    navigate(CART_ROUTE.generate(`${customer.user_id}`))
  }
  const handleRemoveClick = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.remove_customer,customer.user_id);
  }
  const customerTableRowActionButtons: IActionsButtons[] = [    
    {
      label: 'View',
      onClick: handleViewClick,
    },
    {
      label: 'Cart',
      onClick: handleCartClick,
    },
    {
      label: 'Remove',
      onClick: handleRemoveClick,
    },
  ]

  return (
    <tr className='border-bottom' key={customer.email}>
      <td className='min-w-100px' style={{whiteSpace:'pre'}}>{DateFormatter(customer.created_at)}</td>
      <td className='min-w-100px'>{customer.company_name || '-'}</td>
      <td>{customer.VAT_number || '-'}</td>
      <td className='mw-150px'>
        {customer.country_of_incorporation
          ? customer.country_of_incorporation.iso_alpha_3
          : '-'}
      </td>
      <td>
        <a href={`mailto:${customer.email}`}>{customer.email}</a>
      </td>
      <td>
        {customer.phone_number ? (
          <a href={`tel:+${customer.phone_number}`}>+{customer.phone_number}</a>
        ) : (
          '-'
        )}
      </td>
      <td>
        <span className={`badge badge-light-${STATUS_COLOR[customer.status]}`}>
          {CUSTOMER_STATUS[customer.status]}
        </span>
      </td>
      <td>
        <ActionsButton
          label='Actions'
          buttons={customerTableRowActionButtons}
        />
      </td>
    </tr>
  )
}

export const CustomersTableRow = React.memo(CustomersTableRowComponent)
