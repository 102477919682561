import React, {useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {STATISTICS_QUERY_KEYS} from 'shared/constants/query-keys'
import {StatisticsApi} from 'app/api/dashboard-api/statistics-api'
import {
  IStatisticFilters,
  PeriodsType,
} from 'shared/types/api-types/statistic-type'
import {BarChart} from './bar-chart'
import {STATISTIC_MODAL_NAME} from 'shared/constants/modal-names'
import {EventBus, STATISTIC_FILERS_EVENTS} from 'shared/utils/event-bus'
import {useModalManager} from 'shared/context/modal-manager'
import {DownloadService} from 'shared/services/download-service'
import {toast} from 'react-toastify'
import {INITIAL_PARAMS} from '../../constants/init-params'

export const OrdersAVG = () => {
  const [params, setParams] = useState<IStatisticFilters>({
    ...INITIAL_PARAMS,
    symbol: '£',
  })
  const [isCSVLoading, setIsCSVLoading] = useState(false)
  const modalManager = useModalManager()
  const ordersAVGQuery = useQuery({
    queryKey: [STATISTICS_QUERY_KEYS.orders_avg, params],
    queryFn: () => StatisticsApi.getOrdersAVG(params),
    onError: () => {
      toast.error('Something went wrong with Orders AVG chart')
    },
  })
  const ordersAVG = useMemo(() => {
    const datesArray = []
    const valuesArray: number[] = []
    if (!ordersAVGQuery.data || !ordersAVGQuery.data.data) {
      return {
        datesArray,
        valuesArray: [{name: '+', data: []}],
      }
    }
    for (const date in ordersAVGQuery.data.data) {
      datesArray.push(date)
      valuesArray.push(parseFloat((ordersAVGQuery.data.data[date].toFixed(2))))
    }
    return {
      datesArray,
      valuesArray: [{name: 'Orders AVG', data: valuesArray}],
    }
  }, [ordersAVGQuery.data])

  const handelChangePeriod = (period: PeriodsType) => {
    setParams((prevState) => {
      return {...prevState, period}
    })
  }
  const onOpenFilters = () => {
    modalManager.open(STATISTIC_MODAL_NAME.filter_statistics, {
      key: STATISTIC_FILERS_EVENTS.orders_avg,
      params,
    })
  }
  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const response = await StatisticsApi.getOrdersAVG({...params, csv: 1})
      await DownloadService.downloadObjectAsZip(response, 'Orders Avg CSV')
      setIsCSVLoading(false)
    } catch (error) {
      setIsCSVLoading(false)
      toast.error('Download CSV Error!')
    }
  }

  useEffect(() => {
    EventBus.on(STATISTIC_FILERS_EVENTS.orders_avg, (data) =>
      setParams({...data})
    )
    return () => {
      EventBus.off(STATISTIC_FILERS_EVENTS.orders_avg, (data) =>
        setParams({...data})
      )
    }
  }, [])

  return (
    <BarChart
      beforeParams={params.symbol}
      beforeValue={params.symbol}
      isYAxisInteger={true}
      isPriceChart={true}
      onDownload={onDownloadCSV}
      title='Average Order Value'
      onOpenFilters={onOpenFilters}
      names={ordersAVG.datesArray}
      isLoading={ordersAVGQuery.isLoading || isCSVLoading}
      period={params.period || 3}
      values={ordersAVG.valuesArray}
      handelChangePeriod={handelChangePeriod}
    />
  )
}
