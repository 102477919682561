import * as Yup from 'yup'

const allowedFormats = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'image/jpeg', 'image/png'];

export const filesSchema = Yup.object().shape({
    documents: Yup.array()
            .max(1000,'You can upload a maximum of 1000 files')
            .of(
                Yup.mixed<File>()
                    .test('fileFormat', 'Unsupported file(s) format', (value) => {
                        if (!value) return false;
                        return allowedFormats.includes(value.type);
                    })
                    .test('fileSize', 'File(s) size must be less than 1 GB', (value) => {
                        if (!value) return false;
                        return value.size <= 1024 * 1024 * 1024; // 1 GB in Bytes
                    })
            )
        || Yup.mixed<File>()
            .test('fileFormat', 'Unsupported file(s) format', (value) => {
                if (!value) return false;                
                return allowedFormats.includes(value.type);
            })
            .test('fileSize', 'File(s) size must be less than 1 GB', (value) => {
                if (!value) return false;
                return value.size <= 1024 * 1024 * 1024; // 1 GB in Bytes
            })

})
