import {BaseHttpServices} from 'shared/services/base-http-services'

interface IInvoiceReportParams {
  wallet?:number
  search?: string
  start_date?: string
  end_date?: string
}

interface IOrderReportParams {
  number?: string
  status?: number
  method?: number
  start_date?: string
  end_date?: string
}

interface IProductReportParams {
  name?: string
  category?: number
  region?: number
  currency?: number
}

interface ICustomerReportParams {
  company_name?: string
  cart?: number
  status?: number
}

interface IPurchaseReportParams {
  search?: string
  supplier?: number
  status?: number
  currency?: number
  start_date?: string
  end_date?: string
}

interface IWalletReportParams {
  search?: string
  user?:number
  currency?: number
  amount_order?: string
  added_order?: string
  spent_order?: string
  credit_order?:string
}

export class DownloadReportApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getInvoiceReportCSV = async (
    params: IInvoiceReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`statistic/reports/invoices`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }

  getOrderReportCSV = async (
    params: IOrderReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`statistic/reports/orders`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }

  getProductReportCSV = async (
    params: IProductReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`statistic/reports/products`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }

  getPreordersReportCSV = async (
    params: IProductReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`statistic/reports/products/preorder`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }

  getCustomersReportCSV = async (
    params: ICustomerReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`/statistic/reports/users`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }

  getPuchaseReportCSV = async (
    params: IPurchaseReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`/statistic/reports/purchases`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }

  getWalletsReportCSV = async (
    params: IWalletReportParams = {}
  ): Promise<string> => {
    const payload = await this.http.get(`/statistic/reports/wallets`, {
      responseType: 'blob',
      params,
    })
    return payload.data
  }
}

export const DownloadReportApi = new DownloadReportApiService(
  new BaseHttpServices()
)
