import React from 'react';
import RadioButton from "shared/components/radio-button/radio-button";
import {Textarea} from "shared/components/textarea/textarea";
import {Dropzone} from "shared/components/dropzone/dropzone";
import {FormikProps} from "formik/dist/types";
import {ICodesUpload} from "shared/types/codes-upload";

interface IUploadMethods {
  formik: FormikProps<ICodesUpload>
  isActiveText: boolean
  isActiveUpload: boolean
  setText: () => void
  setUpload: () => void
  onUpload: (files: File[]) => void
  amountCounter: number
  imagesCounter: number
}

const acceptedFormats = ['.zip', '.png', '.jpg', '.jpeg', '.pdf']

export const UploadMethods: React.FC<IUploadMethods> = (props) => {
    const {formik, isActiveText, isActiveUpload, setText, setUpload, onUpload, amountCounter, imagesCounter} = props
    return (
        <>
            <RadioButton isActive={isActiveText} label='Text Codes' className='mb-5'
                         onChange={setText}/>
            {isActiveText &&
                <>
                    <Textarea rows={7} resize='vertical' className='w-sm-400px mb-3'
                              {...formik.getFieldProps('text_codes')}
                              name='text_codes'
                              error={formik.touched.text_codes && !!formik.errors.text_codes}
                              errorText={formik.touched.text_codes && formik.errors.text_codes}
                    />
                    <p className='text-exl-primary'>{`${amountCounter} codes`}</p>
                </>
            }
            <RadioButton isActive={isActiveUpload}
                         label='Upload Image'
                         className='mb-5'
                         onChange={setUpload}/>
            {
                isActiveUpload &&
                <>
                    <Dropzone 
                             {...formik.getFieldProps('image_codes')}
                              errorMessage={formik.touched.image_codes && formik.errors.image_codes}
                              accept={acceptedFormats}
                              label='You can Upload img or ZIP file using drag and drop'
                              classNames='mb-5 h-auto'
                              onChange={onUpload}
                              isCleared={!formik.values.image_codes}
                    />
                    <p className='text-exl-primary'>{`${imagesCounter} codes`}</p>
                </>
            }
        </>
    );
};

