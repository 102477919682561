import {useEffect, useMemo, useRef, useState} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {useMutation, useQuery} from 'react-query'
import {
  CATALOG_QUERY_KEYS,
  CATEGORIES_QUERY_KEYS,
  CODES_QUERY_KEYS,
  REGIONS_QUERY_KEYS,
} from 'shared/constants/query-keys'
import {CatalogApi} from 'app/api/dashboard-api/catalog-api'
import {toast} from 'react-toastify'
import {IOption} from 'shared/components/select/select'
import {useDebounce} from '_metronic/helpers'
import {useModalManager} from 'shared/context/modal-manager'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {CartApi, ICartParamsDTO} from 'app/api/cart-api/cart-api'
import {AxiosError} from 'axios'
import {useCartState} from 'app/store/cart/state'
import {useUserState} from 'app/store/user/state'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {PRODUCT_ROUTES} from 'shared/config/routes'
import {EventBus, GLOBAL_EVENTS} from 'shared/utils/event-bus'
import {DownloadReportApi} from 'app/api/dashboard-api/download-report-api'
import {DownloadService} from 'shared/services/download-service'
import {ICart} from 'shared/types/cart'
import {useTemporaryUser} from '../../../hooks/use-temporary-user'
import {BlobErrorResponse} from 'shared/helpers/blob-error-response'
import {useAbilityContext} from 'casl'
import {STOCK_OPTIONS_CATALOG} from 'shared/constants/select-options'

export const useProductsPage = () => {
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const [isPreordersLoading, setIsPreorderLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const ability = useAbilityContext()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState(search.get('name'))
  const modalManager = useModalManager()
  const {temporaryUserId} = useTemporaryUser()
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const {setCart} = useCartState()
  const navigate = useNavigate()
  const abortController = useRef<AbortController>()

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      name: search.get('name'),
      category: Number(search.get('category')) || null,
      region: Number(search.get('region')) || null,
      currency: Number(search.get('currency')) || null,
      in_stock: Number(search.get('in_stock')) || null,
      page: search.get('page') ? Number(search.get('page')) : null,
    })
  }, [location.search])

  function manageParams(name: string, value: string | number | null) {
    const ParamsList = {
      name: queryParams.name ? {name: queryParams.name} : {},
      category: queryParams.category
        ? {category: `${queryParams.category}`}
        : {},
      region: queryParams.region ? {region: `${queryParams.region}`} : {},
      currency: queryParams.currency
        ? {currency: `${queryParams.currency}`}
        : {},
      in_stock: queryParams.in_stock
        ? {in_stock: `${queryParams.in_stock}`}
        : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }

    ParamsList[name] =
      value !== null && value !== '' ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const catalogQuery = useQuery({
    queryKey: [
      CATALOG_QUERY_KEYS.catalog,
      {...queryParams, user_id: temporaryUserId},
    ],
    queryFn: async () => {
      if (abortController.current) {
        abortController.current.abort()
      }
      abortController.current = new AbortController()
      return await CatalogApi.getProductCatalog({
        params: {
          ...queryParams,
          user_id: temporaryUserId,
        },
        signal:abortController.current?.signal,
      })
    },
    onError: async (error) => {
      toast.error(await BlobErrorResponse(error))
    },
  })
  const addToCartMutation = useMutation<
    ICart,
    AxiosError<{error: string}>,
    {userId: number; params: ICartParamsDTO}
  >(CartApi.addToCart, {
    onSuccess: (cart, payload) => {
      setIsCartHasProducts(true)
      const productsInCart = cart.codes.reduce(
        (sum, code) => sum + (code.quantity as number),
        0
      )
      setProductsInCartQuantity(productsInCart)
      setCart(cart)
      catalogQuery.refetch()
      toast.success('Product was added to cart.')
    },
    onError: (error) => {
      toast.error(error?.response.data.error)
    },
  })
  const handelAddProductToCart = (quantity: number, productId: number) => {
    if (!temporaryUserId) return null
    addToCartMutation.mutate({
      userId: temporaryUserId,
      params: {
        quantity,
        product_id: productId,
        product_hash: productId.toString(),
      },
    })
  }

  const {isLoading: isLoadingCurrencies, formationOptions: optionsCurrencies} =
    useSelectController({
      key: CODES_QUERY_KEYS.currencies,
      Fn: SelectsApi.getCurrencies,
      params: 'name',
      format: 'currencyOptions',
    })
  const {
    isLoading: isLoadingCategory,
    handleInputChange: handleInputChangeCategory,
    formationOptions: categories,
  } = useSelectController({
    key: CATEGORIES_QUERY_KEYS.categories,
    Fn: SelectsApi.getCategories,
    params: 'name',
  })
  const {
    isLoading: isLoadingRegions,
    handleInputChange: handleInputChangeRegions,
    formationOptions: optionRegions,
  } = useSelectController({
    key: REGIONS_QUERY_KEYS.regions,
    Fn: SelectsApi.getRegions,
    params: 'name',
  })

  const handleChangeFormValue = (name: string, value: IOption) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams({
      ...params.name,
      ...params.in_stock,
      ...params.category,
      ...params.region,
      ...params.currency,
    })
  }
  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.name,
      ...params.in_stock,
      ...params.category,
      ...params.region,
      ...params.currency,
      ...params.page,
    })
  }
  const debouncedSearchTerm = useDebounce(searchValue, 1000)
  useEffect(() => {
    const params = manageParams('name', debouncedSearchTerm)
    setSearchParams({
      ...params.name,
      ...params.in_stock,
      ...params.category,
      ...params.currency,
      ...params.region,
    })
  }, [debouncedSearchTerm])

  const onCreatePreOrder = (quantity: number, productId: number) => {
    modalManager.open(DASHBOARD_MODAL_NAMES.pre_order_warning, {
      quantity,
      productId,
    })
  }
  const navigateToProductCreatePage = () => {
    navigate(PRODUCT_ROUTES.CREATE_PRODUCT.path)
  }

  const isLoadingList = catalogQuery.isLoading
  const isLoadingSelects =
    isLoadingCategory || isLoadingCurrencies || isLoadingRegions

  useEffect(() => {
    EventBus.on(GLOBAL_EVENTS.reset_products, () => {
      catalogQuery.refetch({cancelRefetch:true})
    })
    return () => {
      EventBus.off(GLOBAL_EVENTS.reset_products, () => {
        catalogQuery.refetch({cancelRefetch:true})
      })
    }
  }, [])

  useEffect(() => {
    if (
      catalogQuery.data &&
      !catalogQuery.data?.data.length &&
      catalogQuery.data?.meta.current_page > 1
    ) {
      setCurrentPage(1)
    }
  }, [catalogQuery.data?.data])

  const onDownloadPreorders = async () => {
    try {
      setIsPreorderLoading(true)
      const params = {
        name: queryParams.name,
        category: queryParams.category,
        region: queryParams.region,
        currency: queryParams.currency,
      }
      const csvData = await DownloadReportApi.getPreordersReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Preorders Report')
      setIsPreorderLoading(false)
      toast.success('Download Preorders report success!')
    } catch (error) {
      setIsPreorderLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        name: queryParams.name,
        category: queryParams.category,
        region: queryParams.region,
        currency: queryParams.currency,
      }
      const csvData = await DownloadReportApi.getProductReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Products Report')
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const stockOptions = useMemo(() => STOCK_OPTIONS_CATALOG(ability), [ability])

  return {
    models: {
      optionRegions,
      searchValue,
      categoryOptions: categories,
      currencyOption: optionsCurrencies,
      values: queryParams,
      isLoadingList,
      isLoadingSelects,
      catalog: catalogQuery.data ? catalogQuery.data.data : [],
      meta: catalogQuery.data ? catalogQuery.data.meta : null,
      isAddedToCart: addToCartMutation.isLoading,
      isCSVLoading,
      stockOptions,
      isPreordersLoading,
    },
    commands: {
      onCreatePreOrder,
      setSearchValue,
      setCurrentPage,
      handleChangeFormValue,
      handelAddProductToCart,
      handleInputChangeCategory,
      navigateToProductCreatePage,
      handleInputChangeRegions,
      onDownloadCSV,
      onDownloadPreorders,
    },
  }
}
