import React from 'react'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {Loader} from 'shared/components/loader/loader'
import {useApiPage} from 'modules/dashboard/account/hooks/use-api'
import {ApiSearchBar} from 'modules/dashboard/account/components/api/search-bar/api-search-bar'
import {ApiTable} from 'modules/dashboard/account/components/api/table/api-table'
import {CreateApiKeyModal} from 'modules/dashboard/account/components/create-api-key'
import {ApiHeader} from '../../components/api-header/api-header'
import {CUSTOMER_ROUTES} from '../../constants/routes'

export const CustomerApiPage = () => {
  const {models, commands} = useApiPage()

  return (
    <>
      <ApiHeader
        handleCreateKeyClick={commands.handleCreateKeyClick}
        customer={models.customer}
        handleGoBackClick={commands.handleGoBackClick}
      />
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={CUSTOMER_ROUTES(models.customer)} className='mb-5' />
        <ApiSearchBar
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
        />
        <div className='table-responsive position-relative'>
          {(models.isTableLoading || models.isActiveToggles) && (
            <Loader mode={models.isActiveToggles ? 'blur' : null} />
          )}
          <ApiTable
            keys={models.keys}
            dateFormatter={commands.dateFormatter}
            isExpiredToken={commands.isExpiredToken}
            toggleActiveStateOfKey={commands.toggleActiveStateOfKey}
          />
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta?.total}
            pageSize={models.meta?.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        </div>
        <CreateApiKeyModal />
      </div>
    </>
  )
}
