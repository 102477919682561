import React, {FC, useEffect} from 'react'
import {CartRow} from '../components/cart-row/cart-row'
import styles from './cart.module.scss'
import clsx from 'clsx'
import {Button} from 'shared/components/button/button'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {ClearCart} from '../components/modals/clear-cart'
import {Checkout} from '../components/modals/checkout'
import {useCartPage} from '../hooks/use-cart-page'
import {useModalManager} from 'shared/context/modal-manager'
import {
  DASHBOARD_MODAL_NAMES,
  INVOICES_MODAL_NAME,
} from 'shared/constants/modal-names'
import {selectWalletsData} from 'app/store/wallets/selects'
import {CreateInvoiceModal} from 'modules/dashboard/invoices/components/modals/create-invoice-modal'
import {thousandFormatter} from 'shared/helpers/formatter'
import {useNavigate} from 'react-router-dom'
import {APP_ROLES} from 'shared/constants/constants'
import {CART_ROUTE, ERROR_ROUTE} from 'shared/config/routes'

export const Cart: FC = () => {
  const navigate = useNavigate()
  const {models, commands} = useCartPage()
  const modalManager = useModalManager()
  const wallets = selectWalletsData()  

  const isTopUpAvailable = Number(models.currentUser) === models.user.id
  const amount = models.cart?.amount
    ? `${models.cart?.currency.symbol}${thousandFormatter(models.cart.amount)}`
    : '-'
  const vat = models.cart?.vat
    ? `${models.cart?.currency.symbol}${thousandFormatter(models.cart.vat)}`
    : '-'
  const total = models.cart?.total
    ? `${models.cart?.currency.symbol}${thousandFormatter(models.cart.total)}`
    : '-'
  const cartCurrency = models.cart ? models.cart?.currency?.id : null

  const handleCreateInvoiceClick = () => {
    const currentWallet = wallets.find(
      (wallet) => wallet.currency.id === cartCurrency
    )
    modalManager.open(INVOICES_MODAL_NAME.create_invoice, {
      user: models.user.id,
      wallets,
      total: models.cart ? models.cart.total : 0,
      wallet: currentWallet?.id ?? null,
    })
  }

  const handleClearCart = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.clear_cart, {
      userId: Number(models.currentUser)
    })
  }

  const handleCheckoutClick = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.checkout, {
      cart:models.cart,
      userId: Number(models.currentUser)
    })
  }

  useEffect(() => {
    if (Number(models.currentUser) !== models.user.id && !models.user.role.includes(APP_ROLES.ADMIN))
      navigate(ERROR_ROUTE.path)
    if (`${models.currentUser}`!==models.cartIdParam && !models.userIsLoading)
      navigate(CART_ROUTE.generate(`${models.currentUser}`))
  }, [models.currentUser, models.user.id])

  return (
    <>
      <div className='d-md-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold mb-5'>Cart</h1>
        {isTopUpAvailable ? (
          <Button label={'Top-up'} onClick={handleCreateInvoiceClick} />
        ) : null}
      </div>
      <LoadWrapper isLoading={models.cartIsLoading||models.userIsLoading}>
        {models.cart?.codes.length ? (
          <div className={styles.container}>
            <div className={styles.products}>
              {models.cart?.codes.map((code) => (
                <CartRow
                  key={code.hash}
                  code={code}
                  isDeleting={models.isDeleting}
                  handleChangeQuantityProduct={
                    commands.handleChangeQuantityProduct
                  }
                  handleOnBlur={commands.handleOnBlurInput}
                  handleOnFocus={commands.handleOnFocusInput}
                  handleDeleteClick={commands.handleDeleteClick}
                  isCurrentProductUpdating={commands.isCurrentProductUpdating}
                  isSomeProductUpdating={commands.isSomeProductUpdating}
                />
              ))}
              <Button
                type='button'
                mode='text'
                className={styles.button}
                onClick={handleClearCart}
                label={
                  <span className='d-flex gap-2'>
                    <Icon icon={ICON_COLLECTION.trash} />
                    <span>Delete All</span>
                  </span>
                }
              />
            </div>
            <div className={clsx(styles.card, styles.summary)}>
              <h2 className='text-exl-primary bold mb-5'>Summary</h2>
              <div className={styles.summary__info}>
                <p className={styles['summary__info--item']}>
                  <span>Subtotal:</span>
                  <span>{amount}</span>
                </p>
                <p
                  className={clsx(
                    styles['summary__info--vat'],
                    styles['summary__info--item']
                  )}
                >
                  <span>VAT:</span>
                  <span>{vat}</span>
                </p>
                <p className={styles['summary__info--item']}>
                  <span>Order Total:</span>
                  <span>{total}</span>
                </p>
              </div>
              <Button
                type='button'
                label='Proceed to Checkout'
                mainButton
                disabled={models.isCheckoutDisabled}
                onClick={handleCheckoutClick}
                className='w-100'
              />
            </div>
          </div>
        ) : (
          <h3 className='text-exl-primary'>
            {Number(models.currentUser) !== models.user.id
              ? 'Customer have no products in cart!'
              : 'You have no products in your cart!'}
          </h3>
        )}
      </LoadWrapper>
      <ClearCart />
      <Checkout />
      <CreateInvoiceModal />
    </>
  )
}
