import React from 'react'
import {Loader} from 'shared/components/loader/loader'
import {useQuery} from 'react-query'
import {STATISTICS_QUERY_KEYS} from 'shared/constants/query-keys'
import {StatisticsApi} from 'app/api/dashboard-api/statistics-api'
import {toast} from 'react-toastify'
import {thousandFormatter} from 'shared/helpers/formatter'

export const TotalStatistic = () => {
  const generalStatisticQuery = useQuery({
    queryKey: [STATISTICS_QUERY_KEYS.general_statistic],
    queryFn: () => StatisticsApi.getGeneralStatistic(),
    onError: () => {
      toast.error('Something went wrong with Total Statistic')
    },
  })
  return (
    <div className='d-flex flex-row gap-5 justify-content-between mt-3 mb-5'>
      <div className='flex flex-column w-50 card shadow p-5 position-relative'>
        {generalStatisticQuery.isLoading && <Loader mode='blur' />}
        <h4 className='m-0'>Stock Info:</h4>
        {generalStatisticQuery.data ? (
          <>
            <h4 className='m-0 mt-2'>
              {`Total Uploaded: ${
                thousandFormatter(generalStatisticQuery.data?.total_upload) ?? ''
              }`}
            </h4>
            <h4 className='m-0 mt-2'>
              {`Total Sold: ${thousandFormatter(generalStatisticQuery.data?.total_sold) ?? ''}`}
            </h4>
            <h4 className='m-0 mt-2'>
              {`Total Quantity in Stock: ${
                thousandFormatter(generalStatisticQuery.data?.total_quantity_stock) ?? ''
              }`}
            </h4>
          </>
        ) : (
          <span className='mt-2'>Nothing was found</span>
        )}
      </div>
      <div className='flex flex-column w-50 card shadow p-5 position-relative'>
        {generalStatisticQuery.isLoading && <Loader mode='blur' />}
        <h4 className='m-0'>Life Time Sales:</h4>
        {generalStatisticQuery.data ? (
          generalStatisticQuery.data.total_amount.map((item, index) => (
            <h4 className='m-0 mt-2' key={index}>
              {item.iso} - {item.symbol}
              {thousandFormatter(item.amount.toFixed(2))}
            </h4>
          ))
        ) : (
          <span className='mt-2'>Nothing was found</span>
        )}
      </div>
    </div>
  )
}
