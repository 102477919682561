import { FormikProps, useFormik } from "formik"
import { useAccount } from "modules/dashboard/account/hooks/use-account"
import React, { useState } from "react"
import { ICustomer } from "shared/types/user"
import { CustomerLayout } from "../../layouts/customer-layout"
import { AccountFormFiles } from "modules/dashboard/account/components/forms/account-form-files"
import { filesSchema } from "modules/dashboard/account/utils/account-file-schema"

export const CustomerFiles = () => {  
    const [isEditing, setIsEditing] = useState(false)

    const {updateCustomerMutation, initialValues} = useAccount(); 

    const handleChangeFiles = (files:File[]) => {           
        formik.setFieldValue('documents',files);     
    }    

    const toggleEditMode = () => {        
        formik.resetForm()
        setIsEditing((prevValue) => !prevValue)        
    }

    const formik: FormikProps<ICustomer> = useFormik({
        initialValues,
        validationSchema: filesSchema,
        enableReinitialize:true,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            try {
                updateCustomerMutation.mutate(values);             
                setIsEditing(false)             
            } catch (error) {
                setStatus('Something is went wrong! Try again later.')
                setSubmitting(false)
            }
        },
    })
    return (
        <CustomerLayout formik={formik} isEditing={isEditing}  toggleEditMode={toggleEditMode}>
            <AccountFormFiles formik={formik} isLoading={updateCustomerMutation.isLoading} handleChangeFiles={handleChangeFiles}/>
        </CustomerLayout>
    )
}