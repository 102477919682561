import {ICreateSupplierDTO} from 'app/api/suppliers-api/suppliers-api'
import {FormikProps} from 'formik'
import {RowContainer} from 'modules/dashboard/account/components/row-container/row-container'
import React from 'react'
import {SingleValue} from 'react-select'
import {Input} from 'shared/components/input/input'
import {PhoneInputField} from 'shared/components/input/phone-input'
import {CountrySelect} from 'shared/components/select/country-select'
import {ICountry} from 'shared/types/country'

interface SingleSupplierMain {
  formik: FormikProps<ICreateSupplierDTO>
  isEditing: boolean
  isLoadingRegions: boolean
  handlePhoneInput: (name: string, value: string) => void
  handleCountrySelect: (newValue: SingleValue<ICountry>) => void
}

export const SingleSupplierContent: React.FC<SingleSupplierMain> = ({
  formik,
  isEditing,
  isLoadingRegions,
  handlePhoneInput,
  handleCountrySelect,
}) => {
  return (
    <>
      {formik.values && (
        <>
          <RowContainer label={'Name'} className='mb-5'>
            <Input
              placeholder={`Enter Name`}
              {...formik.getFieldProps('name')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'name'}
              autoComplete='on'
              error={formik.touched['name'] && !!formik.errors['name']}
              errorText={formik.errors['name']}
            />
          </RowContainer>
          <RowContainer label={'Main Email'} className='mb-5'>
            <Input
              placeholder={`Enter Main Email`}
              {...formik.getFieldProps('main_email')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'main_email'}
              autoComplete='on'
              error={
                formik.touched['main_email'] && !!formik.errors['main_email']
              }
              errorText={formik.errors['main_email']}
            />
          </RowContainer>
          <RowContainer label={'Company Director'} className='mb-5'>
            <Input
              placeholder={`Enter Company Director`}
              {...formik.getFieldProps('company_director')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'company_director'}
              autoComplete='on'
              error={
                formik.touched['company_director'] &&
                !!formik.errors['company_director']
              }
              errorText={formik.errors['company_director']}
            />
          </RowContainer>
          <RowContainer label={'Company Address'} className='mb-5'>
            <Input
              placeholder={`Enter Company Address`}
              {...formik.getFieldProps('company_address')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'company_address'}
              autoComplete='on'
              error={
                formik.touched['company_address'] &&
                !!formik.errors['company_address']
              }
              errorText={formik.errors['company_address']}
            />
          </RowContainer>
          <RowContainer label={'City'} className='mb-5'>
            <Input
              placeholder={`Enter City`}
              {...formik.getFieldProps('city')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'city'}
              autoComplete='on'
              error={formik.touched['city'] && !!formik.errors['city']}
              errorText={formik.errors['city']}
            />
          </RowContainer>
          <RowContainer label='Country' className='mb-5'>
            <CountrySelect
              placeholder='Enter Country'
              {...formik.getFieldProps('country_id')}
              name='country_id'
              onChange={handleCountrySelect}
              value={formik.values.country_id}
              isDisabled={!isEditing}
              isLoading={isLoadingRegions}
              error={formik.touched.country_id && !!formik.errors.country_id}
              errorText={
                formik.touched.country_id ? formik.errors.country_id : null
              }
            />
          </RowContainer>
          <RowContainer label={'Zip Post Code'} className='mb-5'>
            <Input
              placeholder={`Enter Zip Post Code`}
              {...formik.getFieldProps('zip_post_code')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'zip_post_code'}
              autoComplete='on'
              error={
                formik.touched['zip_post_code'] &&
                !!formik.errors['zip_post_code']
              }
              errorText={formik.errors['zip_post_code']}
            />
          </RowContainer>
          <RowContainer label={'Website'} className='mb-5'>
            <Input
              placeholder={`Enter ${'Website'}`}
              {...formik.getFieldProps('website')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'website'}
              autoComplete='on'
              error={formik.touched['website'] && !!formik.errors['website']}
              errorText={formik.errors['website']}
            />
          </RowContainer>
          <RowContainer label={'Vat Number'} className='mb-5'>
            <Input
              placeholder={`Enter Vat Number`}
              {...formik.getFieldProps('VAT_number')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'VAT_number'}
              autoComplete='on'
              error={
                formik.touched['VAT_number'] && !!formik.errors['VAT_number']
              }
              errorText={formik.errors['VAT_number']}
            />
          </RowContainer>
          <RowContainer label={'Company Registration Number'} className='mb-5'>
            <Input
              placeholder={`Enter Company Registration Number`}
              {...formik.getFieldProps('VAT_number')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'company_registration_number'}
              autoComplete='on'
              error={
                formik.touched['company_registration_number'] &&
                !!formik.errors['company_registration_number']
              }
              errorText={formik.errors['company_registration_number']}
            />
          </RowContainer>
          <RowContainer label={'Account Payable Email'} className='mb-5'>
            <Input
              placeholder={`Enter Account Payable Email`}
              {...formik.getFieldProps('account_payable_email')}
              readOnly={!isEditing}
              disabled={!isEditing}
              name={'account_payable_email'}
              autoComplete='on'
              error={
                formik.touched['account_payable_email'] &&
                !!formik.errors['account_payable_email']
              }
              errorText={formik.errors['account_payable_email']}
            />
          </RowContainer>
          <RowContainer label='Account Payable Contact' className='mb-5'>
            <PhoneInputField
              country={'us'}
              id={'account_payable_contact'}
              inputClass={isEditing ? 'w-100 ' : 'w-100  py-3 px-4'}
              placeholder='Enter Account Payable Contact'
              buttonClass={isEditing ? 'd-block' : 'd-none'}
              readOnly={!isEditing}
              disabled={!isEditing}
              {...formik.getFieldProps('account_payable_contact')}
              onChange={(value) =>
                handlePhoneInput('account_payable_contact', value)
              }
              error={
                formik.touched.account_payable_contact &&
                !!formik.errors.account_payable_contact
              }
              errorText={formik.errors.account_payable_contact}
            />
          </RowContainer>
          <RowContainer label='Phone Number' className='mb-5'>
            <PhoneInputField
              country={'us'}
              id={'phone_number'}
              inputClass={isEditing ? 'w-100 ' : 'w-100  py-3 px-4'}
              placeholder='Enter Phone Number'
              buttonClass={isEditing ? 'd-block' : 'd-none'}
              readOnly={!isEditing}
              disabled={!isEditing}
              {...formik.getFieldProps('phone_number')}
              onChange={(value) => handlePhoneInput('phone_number', value)}
              error={
                formik.touched.phone_number && !!formik.errors.phone_number
              }
              errorText={formik.errors.phone_number}
            />
          </RowContainer>
        </>
      )}
    </>
  )
}
