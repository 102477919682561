import React from "react";
import { Button } from "shared/components/button/button"
import { ICustomer } from "shared/types/user";
import { STATUS_COLOR } from "../table/customers-table-row";
import { CUSTOMER_STATUS } from "shared/constants/constants";

interface IApiHeader {
    customer:ICustomer
    handleCreateKeyClick:()=>void
    handleGoBackClick:()=>void
}

export const ApiHeader:React.FC<IApiHeader> = ({customer,handleCreateKeyClick,handleGoBackClick}) => {
    return (
        <div className='d-md-flex justify-content-between align-items-center mb-5'>
          <h1 className='text-exl-primary mb-5 mb-md-0 d-flex align-items-center gap-5 flex-fill'>
                <Button label='<' type="button" onClick={handleGoBackClick} className='min-w-25px'/>
                {customer.company_name}
                <span className={`badge badge-light-${STATUS_COLOR[customer.status]}`}>{CUSTOMER_STATUS[customer.status]}</span>
            </h1>
          <div className='d-flex flex-column flex-md-row gap-5'>
            <Button label='New API Key' type='button' mainButton onClick={handleCreateKeyClick}/>
          </div>
        </div>
    )
}