import React, { useState } from 'react'
import {useStateModalManager} from 'shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from 'shared/constants/modal-names'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'

export const AlertValidationAllowSellAtLossModal = () => {
  const [isAllowToSellLoss,setIsAllowToSellLoss] = useState<boolean>(null)
  const modalState = useStateModalManager(DISCOUNT_MODAL_NAME.alert_allow_sell_at_loss,{
    onBeforeOpen:(isAllowToSellLoss)=>{
      setIsAllowToSellLoss(isAllowToSellLoss)
    }
  })
  const onContinue = () => {
    modalState.close()
    modalState.resolveCallback()
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={true}>
      <div className='p-7 text-center'>
        <h2 className='text-exl-primary mb-10'>
          Are you sure you want to {isAllowToSellLoss?'prohibit':'allow'} this discount sell at a loss?          
          <p className='mt-2 text-danger fs-6 fw-light'>Attention! This change will not apply automatically, click "Update" to save it.</p>
        </h2>        
        <ButtonList className='justify-content-end'>
          <Button type='button' label='Cancel' onClick={handleClose} />
          <Button type='button' mainButton label='Continue' onClick={onContinue} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
