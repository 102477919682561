import React from 'react'
import CheckBox from 'shared/components/check-box/check-box'
import {ICodesItem} from 'shared/types/codes-upload'
import {CodesTableRow} from './codes-table-row'
import {LoadWrapper} from "../../../../../../shared/components/load-wrapper/load-wrapper";
import clsx from "clsx";

interface ICodesTable {
  codesList: ICodesItem[]
  handleCheckboxChange: (id: number) => void
  handleSelectAll: () => void
  isSelectAll: boolean
  isLoading?: boolean
}

const CodesTableComponent: React.FC<ICodesTable> = (props) => {
  const {codesList, handleCheckboxChange, handleSelectAll, isSelectAll, isLoading} = props
  return (
    <div  className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}>
      <LoadWrapper isLoading={isLoading} >
      <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
        <thead>
          <tr className='fw-normal fs-6  border-bottom border-gray-200 bg-light '>
            <th className='text-center'>
              <CheckBox onChange={handleSelectAll} checked={isSelectAll} />
            </th>
            <th>Order ID</th>
            <th>Sold date</th>
            <th>Supplier</th>
            <th>Product</th>
            <th>Purchase Price</th>
            <th>Selling Price</th>
            <th>Code</th>
            <th>Status</th>
          </tr>
        </thead>

        {codesList && codesList.length ? (
          <tbody>
            {codesList.map((code) => (
              <CodesTableRow
                code={code}
                key={code.id}
                handleCheckboxChange={handleCheckboxChange}
              />
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr className='border-bottom text-center text-muted'>
              <td colSpan={9}>Nothing was found</td>
            </tr>
          </tbody>
        )}
      </table>
      </LoadWrapper>
    </div>
  )
}
export const CodesTable = React.memo(CodesTableComponent)
