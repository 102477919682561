import {FC, Suspense} from 'react';
import {Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import {dashboardRoutes} from "./dashboard-routes";
import {AuthGuard} from "../guards/auth-guard";
import { CART_ROUTE, ERROR_ROUTE } from 'shared/config/routes';
import { Cart } from 'modules/cart/pages/cart';

export const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>;
};
const PrivateRoutes = [
  {
    element: (
      <SuspensedView>
        <AuthGuard>
          <MasterLayout />
        </AuthGuard>
      </SuspensedView>
    ),
    children: [
      ...dashboardRoutes,
      {path: CART_ROUTE.path, element: <Cart />},
      {path: '*', element: <Navigate to={ERROR_ROUTE.path} />},
    ],
  },
]


export {PrivateRoutes};
