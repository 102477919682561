import React, {useEffect, useState} from 'react'
import {
  DiscountProduct,
  DiscountProductKeys,
  DiscountType,
  IProductDiscountParams,
} from 'shared/types/discount-types'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {
  CODES_QUERY_KEYS,
  DISCOUNTS_QUERY_KEYS,
} from 'shared/constants/query-keys'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {FormikProps, useFormik} from 'formik'
import {IOption} from 'shared/components/select/select'
import {useFilterRequest} from 'shared/hooks/use-filter-request'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {useEvent} from 'shared/hooks/use-event'
import {ICodesGroupItem} from 'app/api/dashboard-api/catalog-api'
import {validateCodesGroups, validateGeneral} from './validation-methods'
import {useMutation, useQueryClient} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {useModalManager} from '../../../../../shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from '../../../../../shared/constants/modal-names'
import {getValidationMessage} from '../../helpers/discount-calculation'
import {CustomToastForAddProductToDiscount} from '../add-product-discount-error-toast'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

const InitDiscountProduct = {
  id: null,
  name: '',
  general_sell_price: null,
  face_price: null,
  discount: null,
  purchase_price: null,
  type: null,
  codes: [],
}
export const useCreateProductDiscount = (discountId: string) => {
  const [isOpenCreatForm, setIsOpenCreatForm] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [isUsedGeneralPrice, setIsUsedGeneralPrice] = useState(true)
  const [productId, setProductId] = useState(null)
  const [isCodesSecLoader, setIsCodesSecLoader] = useState(false)
  const [codesErrors, setCodesErrors] = useState(null)
  const modalManager = useModalManager()
  const queryClient = useQueryClient()

  const addProductDiscountMutation = useMutation<
    DiscountProduct,
    AxiosError<{
      errors?: {[key: string]: string[]}
      message?: string
      error?: string
    }>,
    IProductDiscountParams
  >(DiscountsApi.addProductDiscount, {
    onSuccess: (data) => {
      if (data) {
        queryClient.setQueryData(
          [DISCOUNTS_QUERY_KEYS.discount, discountId],
          (prevData: DiscountType) => {
            return {...prevData, products: [...prevData.products, data]}
          }
        )
        formik.resetForm()
        setIsOpenCreatForm(false)
      }
    },
    onError: (error) => {
      error?.response.data.message &&
        toast.error(
          CustomToastForAddProductToDiscount(error?.response.data.message)
        )
      error?.response.data.error &&
        toast.error(
          CustomToastForAddProductToDiscount(error?.response.data.error)
        )
      setCodesErrors(true)
    },
  })

  const formik: FormikProps<DiscountProduct> = useFormik({
    initialValues: InitDiscountProduct,
    validate: isUsedGeneralPrice ? validateGeneral : validateCodesGroups,
    onSubmit: async (values) => {
      if (productId && discountId) {
        const generalPriceParams = {
          productId: values.id.toString(),
          discountId: discountId,
          product: {
            id: values.id,
            type: values.type ? values.type.toString() : null,
            general_sell_price: Number(values.general_sell_price),
            discount: values.discount || 0,
            codes: isUsedGeneralPrice ? [] : values.codes,
          },
        }
        const validationMessage = getValidationMessage({
          formData: values,
          isGeneral: isUsedGeneralPrice,
        })
        if (validationMessage.length) {
          modalManager
            .open(DISCOUNT_MODAL_NAME.alert_validation, {
              validationMessageType: validationMessage,
              isGeneralPrice: isUsedGeneralPrice,
            })
            .then(() => {
              addProductDiscountMutation.mutate(generalPriceParams)
            })
            .catch((error) => {
              return
            })
        } else {
          addProductDiscountMutation.mutate(generalPriceParams)
        }
      }
    },
  })
  const onOpenCreatForm = () => setIsOpenCreatForm(true)
  const onOpenCloseForm = () => {
    formik.resetForm()
    setCodesErrors(null)
    setIsOpenCreatForm(false)
  }

  const onChangeProductDiscount = (
    name: DiscountProductKeys,
    value: number | string
  ) => {
    formik.setFieldValue(name, value)
  }
  const onChangeDiscountProduct = (option: IOption) => {
    if (option) {
      onChangeProductDiscount('id', option.id)
      setProductId(option.id)
      setIsCodesSecLoader(true)
    }
  }

  const onChangeCodesDiscount =
    (hash: string) => (name: DiscountProductKeys, value: number | string) => {
      const tempProduct = {[name]: value}
      const codesGroupIndex = formik.values.codes.findIndex(
        (codes) => codes.hash === hash
      )
      if (codesGroupIndex >= 0) {
        const tempCodes = formik.values.codes
        tempCodes[codesGroupIndex] = {
          ...tempCodes[codesGroupIndex],
          ...tempProduct,
        }
        formik.setFieldValue('codes', [...tempCodes])
        setCodesErrors(null)
      }
    }
  const onChangeGeneralSellPrice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    formik.setFieldValue('general_sell_price', decimalFormatter(event.target.value))
  }
  const onChangeCodsSellPrice =
    (hash: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const tempProduct = {sell_price: decimalFormatter(event.target.value)}
      const codesGroupIndex = formik.values.codes.findIndex(
        (codes) => codes.hash === hash
      )
      if (codesGroupIndex >= 0) {
        const tempCodes = formik.values.codes
        tempCodes[codesGroupIndex] = {
          ...tempCodes[codesGroupIndex],
          ...tempProduct,
        }
        formik.setFieldValue('codes', [...tempCodes])
        setCodesErrors(null)
      }
    }

  const onOpenToggle = () => setIsOpen((prevState) => !prevState)

  const onToggleGeneralPrice = () =>
    setIsUsedGeneralPrice((prevState) => {
      if (prevState && !formik.values.codes.length) {
        return prevState
      } else {
        formik.setErrors({})
        return !prevState
      }
    })

  const {
    options: products,
    isLoading: isLoadingProduct,
    handleInputChange,
    formationOptions: optionsProducts,
  } = useSelectController({
    key: CODES_QUERY_KEYS.product,
    Fn: SelectsApi.getProducts,
    params: 'name',
    format:'productsOptions'
  })

  const {
    data: codesGroups,
    searchData: searchGroups,
    isGlobalLoading: isCodesLoading,
  } = useFilterRequest<
    ICodesGroupItem[],
    undefined,
    {id: number | string; signal: AbortSignal}
  >({
    manualTriggering: true,
    request: (params) => {
      return DiscountsApi.getCodesGroup(getFindParams(params))
    },
    searchFuncDependencies: [productId],
  })
  const getFindParams = useEvent((params) => {
    if (!productId) return null
    return {
      id: productId,
      signal: params.signal,
    }
  })

  useEffect(() => {
    if (codesGroups) {
      const tempCodesGroups = codesGroups.map((group) => ({
        hash: group.hash,
        purchase_price: group.purchase_price,
        sell_price: group.sell_price_for_admin,
        discount: null,
        quantity: group.code_count,
        type: null,
      }))
      formik.setFieldValue('codes', tempCodesGroups)
      setIsCodesSecLoader(false)
    } else {
      formik.setFieldValue('codes', [])
      setIsCodesSecLoader(false)
    }
  }, [codesGroups])

  useEffect(() => {
    if (formik.values.id && products.length) {
      const tempProduct = products.find(
        (product) => product.id === formik.values.id
      )
      if (!tempProduct) return
      formik.setFieldValue('name', tempProduct.name)
      formik.setFieldValue('general_sell_price', tempProduct.general_price)
      formik.setFieldValue('purchase_price', tempProduct.purchase_price)
      formik.setFieldValue('type', null)
      searchGroups()
    }
  }, [formik.values.id])

  return {
    models: {
      isLoading: addProductDiscountMutation.isLoading,
      isCodesLoading: isCodesSecLoader || isCodesLoading,
      errors: formik.errors,
      touched: formik.touched,
      isOpenCreatForm,
      isOpen,
      isLoadingProduct,
      optionsProducts,
      isUsedGeneralPrice,
      currentProduct: formik.values,
      codesErrors,
    },
    commands: {
      onChangeGeneralSellPrice,
      onChangeCodsSellPrice,
      onOpenCreatForm,
      onOpenCloseForm,
      onOpenToggle,
      onToggleGeneralPrice,
      onChangeCodesDiscount,
      handleInputChange,
      onChangeProductDiscount,
      onChangeDiscountProduct,
      onSubmit: () => formik.submitForm(),
    },
  }
}
