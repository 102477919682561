import React from 'react'
import {Button} from 'shared/components/button/button'
import {ButtonLink} from 'shared/components/button/button-link'
import {CUSTOMERS_ROUTES, DASHBOARD_ROUTES} from 'shared/config/routes'
import {IOrder} from 'shared/types/order'

interface IOrderHeader {
  userId: number
  order: IOrder
  isCSVLoading: boolean
  isInvoiceLoading: boolean
  handleGoBackClick: () => void
  onDownloadCSV: (hash?: string) => void
  onDownloadAllCSV: (event:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onGetInvoiceClick: () => void
}

const OrderHeaderComponent: React.FC<IOrderHeader> = (props) => {
  const {
    userId,
    order,
    isCSVLoading,
    isInvoiceLoading,
    handleGoBackClick,
    onDownloadCSV,
    onDownloadAllCSV,
    onGetInvoiceClick,
  } = props
  return (
    <div className='d-md-flex justify-content-between align-items-center mb-5 gap-5 flex-wrap'>
      <h1 className='d-flex align-items-center gap-5 mb-5 mb-md-0'>
        <Button
          label='<'
          type='button'
          onClick={handleGoBackClick}
          className='min-w-25px'
        />
        <div className='d-flex flex-column gap-2 mt-2'>
          <ButtonLink
            to={
              order.user_id.toString() === userId.toString()
                ? DASHBOARD_ROUTES.ACCOUNT.path
                : CUSTOMERS_ROUTES.CUSTOMER.generate(order.user_id.toString())
            }
            className='bold text-start p-0 fs-2'
          >
            {order.company_name ? order.company_name : 'No company name'}
          </ButtonLink>
          <span className='text-exl-primary text-truncate fs-2'>
            {order.number}
          </span>
        </div>
      </h1>
      <div className='d-flex gap-5 flex-column flex-md-row w-100 w-md-auto w-lg-100 w-xl-auto justify-content-evenly '>
        <Button
          label='Get invoice'
          isLoading={isInvoiceLoading}
          disabled={isInvoiceLoading}
          onClick={onGetInvoiceClick}
        />
        {
          order.is_new_to_download_exists && <Button
            label='Download all new'
            type='button'
            isLoading={isCSVLoading}
            disabled={isCSVLoading}
            onClick={() => onDownloadCSV()}
          />
        }
        <Button
          label='Download all'
          mainButton
          onClick={onDownloadAllCSV}
          disabled={isCSVLoading}
          isLoading={isCSVLoading}
        />
      </div>
    </div>
  )
}

export const OrderHeader = React.memo(OrderHeaderComponent)
