import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ActionsButton, IActionsButtons } from 'shared/components/button/actions-button';
import { CUSTOMERS_ROUTES} from 'shared/config/routes';
import { IWallet } from 'shared/types/api-types/wallets-type';
import { CurrencyFormatter } from 'shared/helpers/currency-formatter';

interface IWalletsTableRow {
    wallet: IWallet
}

const AllWalletsTableRowComponent: React.FC<IWalletsTableRow> = ({ wallet }) => {
  const navigate = useNavigate()
  const handleViewClick = () => {
    navigate(
      CUSTOMERS_ROUTES.CUSTOMER_WALLETS.generate(wallet.user_id.toString()),
      {
        state:wallet.id
      }
    )
  }
  const ALL_WALLETS_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'View',
      onClick: handleViewClick,
    },
  ]
  return (
    <tr className='border-bottom'>
      <td><Link to={CUSTOMERS_ROUTES.CUSTOMER.generate(`${wallet.user_id}`)}>{wallet.company_name ? wallet.company_name : '-'}</Link></td>
      <td>{`${wallet.currency?.iso}`}</td>
      <td>{CurrencyFormatter(wallet.total_added,wallet.currency)}</td>  
      <td>{CurrencyFormatter(wallet.total_spent,wallet.currency)}</td>  
      <td>{CurrencyFormatter(wallet.amount,wallet.currency)}</td>  
      <td>{CurrencyFormatter(wallet.credit_limit,wallet.currency)}</td>        
      <td>
        <ActionsButton label='Actions' buttons={ALL_WALLETS_ACTION_BUTTONS} />
      </td>
    </tr>
  )
}

export const AllWalletsTableRow = React.memo(AllWalletsTableRowComponent)
