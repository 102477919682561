import React from 'react'
import clsx from 'clsx'
import {ISupplier} from 'shared/types/supplier'
import {SupplierTrackerTableRow} from './supplier-tracker-table-row'
import { ICurrenciesDTO } from 'app/api/settings-api/selects-api'
import { LoadWrapper } from 'shared/components/load-wrapper/load-wrapper'

interface ISupplierTrackerTable {
  suppliers: ISupplier[]
  isLoading: boolean
  currencies: ICurrenciesDTO[]
  handleTrackerRowClick: (productId: string) => void
  handleAddSupplierClick: (supplier: ISupplier) => void
  handleEditProductClick: (id: number, name: string) => void
  handleDeleteProductClick: (id: number) => void
}

export const SupplierTrackerTable: React.FC<ISupplierTrackerTable> = ({
  suppliers,
  isLoading,
  currencies,
  handleTrackerRowClick,
  handleAddSupplierClick,
  handleEditProductClick,
  handleDeleteProductClick,
}) => {
  return (
    <LoadWrapper isLoading={isLoading}>
      <div
        className={clsx('card-body px-0 table-responsive', !isLoading && 'p-6')}
      >
        <table className='table gs-3 gy-4 gx-3 text-exl-primary'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
              <th>Supplier Name</th>
              <th>Suppliers Quantity</th>
              <th>Product Name</th>
              {currencies &&
                currencies.map((currency) => (
                  <th key={currency.id}>{currency.name}</th>
                ))}
              <th>Actions</th>
            </tr>
          </thead>
          {suppliers && suppliers.length ? (
            <tbody>
              {suppliers.map((supplier: ISupplier) => (
                <SupplierTrackerTableRow
                  key={supplier.product_id}
                  supplier={supplier}
                  currencies={currencies}
                  handleTrackerRowClick={handleTrackerRowClick}
                  handleAddSupplierClick={handleAddSupplierClick}
                  handleEditProductClick={handleEditProductClick}
                  handleDeleteProductClick={handleDeleteProductClick}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={11}>No suppliers found.</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </LoadWrapper>
  )
}
