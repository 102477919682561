import {SingleProductType} from './single-roduct-types'

export const getProductUpdatedVale = (productFormValue: SingleProductType) => {
  const categoriesListId =
    productFormValue.categories.map((category) => category.id) || []

  return {
    name: productFormValue.name,
    image: productFormValue.editImage || null,
    description: productFormValue.description || '',
    general_sell_price: Number(productFormValue.general_price) || '',
    is_hidden: productFormValue.is_hidden ? 1 : 0,
    is_preorder_unlimited: productFormValue.is_preorder_unlimited ? 1 : 0,
    region_id: productFormValue.region ? productFormValue.region.id : null,    
    preorder_quantity_available: productFormValue.preorder_avaibable
      ? productFormValue.preorder_quantity || 0
      : 0,
    currency_id: productFormValue.currency
      ? productFormValue.currency.id
      : null,
    face_price: Number(productFormValue.face_price),
    ['categories[]']: categoriesListId.length ? categoriesListId : [],
  }
}
