import React from 'react'
import {AccountFormHeader} from './components/forms/account-form-header'
import {FormikProps} from 'formik'
import {ICustomer, ICustomerSettings} from 'shared/types/user'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {useMenuList} from '../../../shared/hooks/use-menu-list'
import {useAbilityContext} from '../../../casl'

interface IAccountLayout {
  children: React.ReactNode
  formik: FormikProps<ICustomer> | FormikProps<ICustomerSettings>
  isEditable?: boolean
  isEditing: boolean
  isLoading?: boolean
  toggleEditMode: React.MouseEventHandler<HTMLButtonElement>
  isSettingsPage?: boolean
  handleRedirectToDocs?: () => void
}

export const AccountLayout: React.FC<IAccountLayout> = ({
  children,
  formik,
  isEditing,
  isEditable = false,
  toggleEditMode,
  isLoading = false,
  isSettingsPage = false,
  handleRedirectToDocs
}) => {
  const ability = useAbilityContext()

  const {accountLinks} = useMenuList(ability)

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <div className='d-md-flex justify-content-between align-items-center mb-5'>
        <AccountFormHeader
          isLoading={isLoading}
          isEditing={isEditing}
          isEditable={isEditable}
          toggleEditMode={toggleEditMode}
          isSettingsPage={isSettingsPage}
          handleRedirectToDocs={handleRedirectToDocs}
        />
      </div>
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={accountLinks} />
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        {children}
      </div>
    </form>
  )
}
