import {
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {AxiosError} from 'axios'
import React, {useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'

export const DeleteProductModal = () => {
  const [productId, setProductId] = useState<number>()
  const modalState = useStateModalManager(SUPPLIERS_MODAL_NAME.delete_product, {
    onBeforeOpen: (productId: number) => {
      setProductId(productId)
    },
  })

  const queryClient = useQueryClient()

  const deleteProductMutation = useMutation(
    async ({productId}: {productId: number}) => {
      await SuppliersApi.deleteExternalProduct(productId)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker,
        ])
        toast.success(`Product was deleted successfully!`)
        modalState.resolveCallback()
        handleClose()
      },
      onError: (error: AxiosError<{error: string}>) => {
        handleClose()
        toast.error(error?.response.data.error)
      },
    }
  )

  const handleDeleteSupplier = (productId: number) => {
    deleteProductMutation.mutate({productId})
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
  }

  const isLoading = deleteProductMutation.isLoading

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!isLoading}
    >
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to delete this product?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' disabled={isLoading} onClick={handleClose} />
          <Button
            label='Delete'
            mode='remove'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => handleDeleteSupplier(productId)}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
