import React from 'react';
import {Navigate} from 'react-router-dom';
import {BoundCanProps} from '@casl/react';
import {Can} from 'casl';
import {AppAbility} from 'casl/ability';

import {ERROR_ROUTE} from "../../shared/config/routes";
import {useAppNavigationContext} from "../../shared/context/app-navigation-context";

type RoleGuardProps = BoundCanProps<AppAbility>;

export const RoleGuard: React.FC<React.PropsWithChildren<RoleGuardProps>> = ({
                                                                                 children,
                                                                                 ...props
                                                                             }) => {
    const { navigationLinks } = useAppNavigationContext();
    return (
      <Can {...props} passThrough>
        {(allowed) =>
          allowed ? (
            children
          ) : navigationLinks[0] ? (
            <Navigate to={navigationLinks[0].path} replace />
          ) : (
            <Navigate to={ERROR_ROUTE.path} />
          )
        }
      </Can>
    )
};
