class _DownloadService {
    downloadObjectAsZip(exportObj, exportName) {
        const url = URL.createObjectURL(exportObj);
        const link = document.createElement('a');
        link.href = url;
        link.download = exportName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }
}

export const DownloadService = new _DownloadService()