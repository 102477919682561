import {Can} from 'casl'
import {RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import clsx from 'clsx'
import {Button} from 'shared/components/button/button'
import {CUSTOMER_STATUS} from 'shared/constants/constants'
import {useCustomersPage} from '../../pages/use-customers-page'
import {useAccount} from 'modules/dashboard/account/hooks/use-account'
import {STATUS_COLOR} from '../table/customers-table-row'
import {selectIsGettingMessages} from 'app/store/chat/selects'

interface ICustomerHeader {
  isEditing: boolean
  isEditable?: boolean
  isLoading: boolean
  toggleEditMode: React.MouseEventHandler<HTMLButtonElement>
}

export const CustomerFormHeader: React.FC<ICustomerHeader> = ({
  isEditing,
  isEditable,
  isLoading,
  toggleEditMode,
}) => {
  const {commands} = useCustomersPage()
  const {approveCustomerMutation, declineCustomerMutation, customer} =
    useAccount()
  const isMessagesLoading = selectIsGettingMessages()
  const isPending = customer.status === 2

  const approveCustomer = () => {
    approveCustomerMutation.mutate(customer.user_id)
  }

  const handleRemoveCustomerClick = () => {
    commands.handleRemoveCustomer(customer.user_id)
  }

  const Approve = () => (
    <Button
      label='Approve'
      type='button'
      className='bg-success text-white'
      mainButton
      onClick={approveCustomer}
      isLoading={approveCustomerMutation.isLoading}
    />
  )

  const Decline = () => (
    <Button
      label='Decline'
      type='button'
      className='bg-danger text-white'
      mainButton
      onClick={commands.handleDeclineCustomer}
      isLoading={declineCustomerMutation.isLoading}
    />
  )

  return (
    <>
      <h1 className='text-exl-primary mb-5 mb-md-0 d-flex align-items-center gap-5 flex-fill'>
        <Button
          label='<'
          type='button'
          onClick={commands.handleGoBackClick}
          className='min-w-25px'
        />
        {customer.company_name}
        <span className={`badge badge-light-${STATUS_COLOR[customer.status]}`}>
          {CUSTOMER_STATUS[customer.status]}
        </span>
      </h1>
      <div
        className={clsx(
          'd-flex flex-column flex-md-row flex-wrap gap-1',
          {'justify-content-evenly flex-fill': isPending},
          {'justify-content-between': !isPending}
        )}
      >
        <Button
          label='Support'
          type='button'
          id='kt_drawer_chat_toggle'
          disabled={isMessagesLoading}
        />
        {isEditing && isEditable ? (
          <>
            <Button label='Cancel' type='button' onClick={toggleEditMode} />
            <Button
              label='Save'
              mainButton
              type='submit'
              isLoading={isLoading}
              disabled={isLoading}
            />
          </>
        ) : (
          isEditable && (
            <Button
              label='Edit'
              type='button'
              mainButton
              onClick={toggleEditMode}
            />
          )
        )}
        <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
          {isPending && (
            <>
              <Approve />
              <Decline />
            </>
          )}
        </Can>
        <Button
          label='Remove'
          type='button'
          className='bg-danger text-white'
          mainButton
          onClick={handleRemoveCustomerClick}
          isLoading={declineCustomerMutation.isLoading}
        />
      </div>
    </>
  )
}
