import React, { ChangeEvent, useEffect, useState } from 'react';
import {Modal} from "../../../../shared/components/modal-base";
import {useStateModalManager} from "../../../../shared/context/modal-manager";
import {ACCOUNT_MODAL_NAMES} from "../../../../shared/constants/modal-names";
import {ButtonList} from "../../../../shared/components/button-list/button-list";
import {Button} from "../../../../shared/components/button/button";
import { Input } from 'shared/components/input/input';
import { Toggle } from 'shared/components/toggle/toggle';
import { IApiKey } from 'shared/types/user';
import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { CustomerApi, ICreateApiKeyParams } from 'app/api/user-api/customer-api';
import { CUSTOMER_QUERY_KEYS } from 'shared/constants/query-keys';
import { useAccountContext } from 'shared/context/app-accout-context';
import { selectAuthUserData } from 'app/store/auth/selects';
import { toast } from 'react-toastify';

export const CreateApiKeyModal = () => {    
    const user = selectAuthUserData()
    const {customer} = useAccountContext()
    const modalState = useStateModalManager(ACCOUNT_MODAL_NAMES.create_api_key)   
    const [name,setName] = useState<string>('')
    const [isSandbox,setIsSandbox] = useState<boolean>(false)
    const queryClient = useQueryClient();


    const handleClose = () => {
        setName('')
        modalState.close()
    }

    const createApiKeyMutation = useMutation<
        IApiKey,
        AxiosError<{error: string}>,
        ICreateApiKeyParams
    >(CustomerApi.createApiKey, {
        onSuccess: (data: IApiKey) => {
            queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.apiKeys, customer.user_id || user])
            toast.success(`API key ${data.name} was created successfully!`)
        },
        onError: async (error) => {
            toast.error(error.response.data.error)
        },
    })

    const handleCreateApikey = () => {
        createApiKeyMutation.mutate({
            userId:customer.user_id,
            name,
            is_sandbox:isSandbox
        })
    }    

    const handleChangeName = (event:ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleToggleSandbox = () => {
        setIsSandbox(prevValue=>!prevValue)
    }

    useEffect(() => {
        if (createApiKeyMutation.isSuccess) {
            handleClose()
        }
    }, [createApiKeyMutation.isSuccess])

    return (
        <Modal.Root 
            open={modalState.open} 
            onClose={handleClose}
            isClosable={!createApiKeyMutation.isLoading}
        >
            <div className="p-5">
                <h2 className='text-center my-5'>New API</h2>
                <div className='d-flex justify-content-between align-items-center gap-10'>
                    <div>
                        <label>Name</label>
                        <Input value={name} onChange={handleChangeName}/>
                    </div>
                    <div className='align-items-center justify-content-center gap-5'>
                        <label className='mb-2'>Sandbox</label>
                        <Toggle isActive={isSandbox} setActive={handleToggleSandbox}/>
                    </div>
                </div>  
                <ButtonList className='flex-column my-5'>
                    <Button label='Create' mainButton isLoading={createApiKeyMutation.isLoading} disabled={createApiKeyMutation.isLoading||!name.length} onClick={handleCreateApikey}/>
                    <Button label='Cancel' onClick={handleClose} disabled={createApiKeyMutation.isLoading}/>                
                </ButtonList>
            </div>
        </Modal.Root>
    );
};

