import {IProductSupplier} from 'app/api/suppliers-api/suppliers-api'
import React from 'react'
import {SupplierTableRow} from './supplier-table-row'
import { ICurrency } from 'shared/types/codes-upload'

interface SupplierTableProps {
  suppliers: IProductSupplier[]
  currencies: string[]
  allCurrencies: ICurrency[]
  handleDeleteSupplier: (supplierId: number) => void
  updateProductSupplier: (
    supplierId: number,
    currency: string,
    price: number
  ) => void
  isUpdateSupplierLoading: boolean
  updatingSupplier: number
}

export const SupplierTable: React.FC<SupplierTableProps> = ({
  suppliers,
  currencies,
  allCurrencies,
  isUpdateSupplierLoading,
  updatingSupplier,
  handleDeleteSupplier,
  updateProductSupplier,
}) => {
  return (
    <div
      className='card-body px-0 table-responsive'
    >
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
              <th>Supplier Name</th>
              {currencies.length > 0 &&
                currencies.map((currency) => (
                  <th key={currency}>{currency}</th>
                ))}
              <th className='col col-2'>Action</th>
            </tr>
          </thead>
          {suppliers && suppliers.length ? (
            <tbody>
              {suppliers.map((supplier) => (
                <SupplierTableRow
                  key={supplier.supplier_id}
                  currencies={currencies}
                  allCurrencies={allCurrencies}
                  supplier={supplier}
                  updatingSupplier={updatingSupplier}
                  isUpdateSupplierLoading={isUpdateSupplierLoading}
                  handleDeleteSupplier={handleDeleteSupplier}
                  updateProductSupplier={updateProductSupplier}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={11}>No suppliers found of this product.</td>
              </tr>
            </tbody>
          )}
        </table>
    </div>
  )
}
