import React from "react";
import { Icon } from "shared/components/icon/icon";
import { ICON_COLLECTION } from "shared/components/icon/icon-list";
import { Input } from "shared/components/input/input";

interface SuppliersTrackerSearchBarProps {
  searchValue: string
  setSearchValue: (text: string) => void
}

export const SuppliersTrackerSearchBar: React.FC<SuppliersTrackerSearchBarProps> = ({
  searchValue,
  setSearchValue,
}) => {
  return (
    <div className='mb-5'>
      <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-5 position-relative'>
        <div className='col mb-5'>
          <Input
            startAdornment={
              <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
            }
            placeholder='Search'
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </div>
      </div>
    </div>
  )
}