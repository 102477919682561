import {SingleProductType} from "../pages/single-product-page/single-roduct-types";

export const initialProductValues: SingleProductType = {
    name: null,
    description: null,
    image: null,
    price: null,
    quantity: null,
    stock: null,
    region: null,
    currency: null,
    categories: [],
    editImage: null,
    preorder_avaibable: null,
    general_sell_price: null,
    preorder_quantity: null,
    is_hidden: null,
    face_price: null,
    general_price: null,
    is_preorder_unlimited: false,
    is_general_price_available: true,
}
export const initialCreateProductValues: SingleProductType = {
    name: null,
    description: null,
    image: null,
    price: null,
    quantity: null,
    stock: null,
    region: null,
    currency: null,
    categories: [],
    editImage: null,
    preorder_avaibable: null,
    general_sell_price: null,
    preorder_quantity: null,
    is_hidden: null,
    face_price: null,
    general_price: null,
    is_preorder_unlimited: false,
    is_general_price_available: true,
}

interface IValidationError {
    name?: string
    face_price?: string
}

export const validateProduct = (values: SingleProductType) => {
    let errors: IValidationError = {}
    if (!values.name || values.name === '') {
        errors.name = 'Name is required field !'
    }
    return errors
}