import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {
  CodesApi,
  ICodesList,
  IDeleteCodesDTO,
  IUpdateBatchStatusDTO,
} from 'app/api/dashboard-api/codes-api'
import {DownloadService} from 'shared/services/download-service'
import {CODES_QUERY_KEYS} from 'shared/constants/query-keys'
import {toast} from 'react-toastify'
import {useDebounce} from '_metronic/helpers'
import {IOption} from 'shared/components/select/select'
import {ICodesItem} from 'shared/types/codes-upload'
import {initialFormName} from './codes-page'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {useModalManager} from 'shared/context/modal-manager'
import {CODES_MODAL_NAME} from 'shared/constants/modal-names'
import {AxiosError} from 'axios'
import {DASHBOARD_ROUTES} from 'shared/config/routes'
import {BlobErrorResponse} from 'shared/helpers/blob-error-response'
import {STOCK_OPTIONS_CODES} from 'shared/constants/select-options'

export const useCodesPage = () => {
  const navigate = useNavigate()
  const [selectAll, setSelectAll] = useState(false)
  const [isCSVLoading, setIsCSVLoading] = useState(false)
  const modalManager = useModalManager()
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState(search.get('search'))

  const {
    isLoading: isLoadingProducts,
    handleInputChange: handleInputChangeProducts,
    formationOptions: productsOptionsMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.product,
    Fn: SelectsApi.getProducts,
    params: 'name',
  })

  const {
    isLoading: isLoadingSuppliers,
    handleInputChange: handleInputChangeSuppliers,
    formationOptions: optionsSuppliersMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    params: 'name',
  })

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      search: search.get('search'),
      product: Number(search.get('product')) || null,
      supplier: Number(search.get('supplier')) || null,
      stock: search.get('stock') || null,
      uploads_history: Number(search.get('uploads_history')) || null,
      page: search.get('page') ? Number(search.get('page')) : null,
    })
  }, [location.search])

  function manageParams(name: string, value: string | number | null) {
    const ParamsList = {
      search: queryParams.search ? {search: queryParams.search} : {},
      product: queryParams.product ? {product: `${queryParams.product}`} : {},
      supplier: queryParams.supplier
        ? {supplier: `${queryParams.supplier}`}
        : {},
      stock: queryParams.stock ? {stock: `${queryParams.stock}`} : {},
      uploads_history: queryParams.uploads_history
        ? {uploads_history: `${queryParams.uploads_history}`}
        : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const codesQuery = useQuery({
    queryKey: [CODES_QUERY_KEYS.codes, queryParams],
    queryFn: async () => {
      const response = await CodesApi.getCodes(queryParams)
      const mappedResponce = response.data.map((item) => ({
        ...item,
        checked: false,
      }))
      return {...response, data: mappedResponce}
    },
    refetchOnWindowFocus: false,
  })

  const handleChangeFormValue = (name: initialFormName, value: IOption) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams({
      ...params.search,
      ...params.product,
      ...params.supplier,
      ...params.uploads_history,
      ...params.stock,
    })
    resetCheckedState()
  }
  const debouncedSearchTerm = useDebounce(searchValue, 1000)
  useEffect(() => {
    const params = manageParams('search', debouncedSearchTerm)
    setSearchParams({
      ...params.search,
      ...params.product,
      ...params.supplier,
      ...params.uploads_history,
      ...params.stock,
    })
  }, [debouncedSearchTerm])

  /////////////////////////////////////////////////////////////////////---------------------------------------
  const codesChecked = useMemo(() => {
    if (codesQuery.data) {
      return codesQuery.data.data.filter((code) => code.checked)
    } else {
      return []
    }
  }, [codesQuery.data])

  const resetCheckedState = () => {
    if (codesQuery && codesQuery.data) {
      queryClient.setQueryData<ICodesList>(
        [CODES_QUERY_KEYS.codes, queryParams],
        (prevState: ICodesList) => {
          const data = prevState.data.map((item) => ({
            ...item,
            checked: false,
          }))
          return {
            ...prevState,
            data: data,
          }
        }
      )
    }
    setSelectAll(false)
  }

  const handleSelectAllQuery = () => {
    const data = codesQuery.data.data.map((item) => ({
      ...item,
      checked: !selectAll,
    }))
    queryClient.setQueryData([CODES_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: data,
    })
    setSelectAll(!selectAll)
  }
  const handleCheckboxChangeQuery = (id) => {
    const newData = codesQuery.data.data.map((item) =>
      item.id === id ? {...item, checked: !item.checked} : item
    )
    queryClient.setQueryData([CODES_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: newData,
    })
    setSelectAll(newData.every((item) => item.checked))
  }
  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const codesId = codesChecked.map((code) => code.id)
      const stockParams = {
        1: 'true',
        2: 'false',
      }
      const params = {
        ...queryParams,
        stock: queryParams.stock ? stockParams[queryParams.stock] : null,
      }
      const response = await CodesApi.getCodesCSV({
        ...params,
        ids: codesId,
      })
      await DownloadService.downloadObjectAsZip(response, 'CSV')
      setIsCSVLoading(false)
      const data = codesQuery.data.data.map((item) => ({
        ...item,
        checked: false,
      }))
      queryClient.setQueryData([CODES_QUERY_KEYS.codes, queryParams], {
        ...codesQuery.data,
        data: data,
      })
      setSelectAll(false)
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }
  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.search,
      ...params.product,
      ...params.supplier,
      ...params.stock,
      ...params.uploads_history,
      ...params.page,
    })
    const data = codesQuery.data.data.map((item) => ({...item, checked: false}))
    queryClient.setQueryData([CODES_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: data,
    })
    setSelectAll(false)
  }

  useEffect(() => {
    resetCheckedState()
  }, [location.search])

  const handleClearFilters = () => {
    setSearchParams({})
    resetCheckedState()
  }

  const onUpdateBatchStatusMutate = useMutation<
    ICodesItem[],
    AxiosError<{message: string}>,
    IUpdateBatchStatusDTO
  >(CodesApi.updateBatchStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([CODES_QUERY_KEYS.codes, queryParams], {
        exact: true,
      })
      setSelectAll(false)
      toast.success('Change codes status succesfully')
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error?.response.data.message)
    },
  })

  const onUpdateBatchStatus = () => {
    const codesChecked: ICodesItem[] = codesQuery.data.data.filter(
      (code) => code.checked && code.checked === true
    )
    const stockParams = {
      1: 'true',
      2: 'false',
    }
    const params = {
      ...queryParams,
      stock: queryParams.stock ? stockParams[queryParams.stock] : null,
    }
    const codesId: number[] =
      codesChecked.length > 0 ? codesChecked.map((code) => code.id) : []
    modalManager
      .open(CODES_MODAL_NAME.change_status)
      .then(() => {
        onUpdateBatchStatusMutate.mutate({
          selected_modules: codesId,
          stock_status: 0,
          params: params,
        })
      })
      .catch((error) => {
        return
      })
  }

  const onDeleteCodesMutate = useMutation<
    string,
    AxiosError<{message: string}>,
    IDeleteCodesDTO
  >(CodesApi.deleteCodes, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([CODES_QUERY_KEYS.codes, queryParams], {
        exact: true,
      })
      if (
        Number(search.get('page')) >
        codesQuery.data.meta.total / codesQuery.data.meta.per_page
      ) {
        setCurrentPage(1)
      } else if (
        codesQuery.data.meta.total / codesQuery.data.meta.per_page ===
        0
      ) {
        navigate(DASHBOARD_ROUTES.CODES.path)
      }
      setSelectAll(false)
      toast.success(data)
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error?.response.data.message)
    },
  })

  const onDeleteCodes = () => {
    const codesChecked: ICodesItem[] = codesQuery.data.data.filter(
      (code) => code.checked && code.checked === true
    )
    const stockParams = {
      1: 'true',
      2: 'false',
    }
    const params = {
      ...queryParams,
      stock: queryParams.stock ? stockParams[queryParams.stock] : null,
    }
    const codesId: number[] =
      codesChecked.length > 0 ? codesChecked.map((code) => code.id) : []
    modalManager
      .open(CODES_MODAL_NAME.delete_codes)
      .then(() => {
        onDeleteCodesMutate.mutate({
          selected_models: codesId,
          params: params,
        })
      })
      .catch((error) => {
        return
      })
  }

  const stockOptions = STOCK_OPTIONS_CODES
  const isTableLoading = codesQuery.isLoading

  return {
    models: {
      isCSVLoading,
      values: queryParams,
      productsOptions: productsOptionsMemo,
      suppliersOptions: optionsSuppliersMemo,
      codes: codesQuery.data ? codesQuery.data.data : [],
      meta: codesQuery.data ? codesQuery.data.meta : null,
      isLoadingSuppliers,
      isLoadingProducts,
      isTableLoading,
      codesChecked,
      searchValue,
      selectAll,
      stockOptions,
      isCodesDeleting:onDeleteCodesMutate.isLoading,
      isCodesBatchStatusUpdating:onUpdateBatchStatusMutate.isLoading,
    },
    commands: {
      handleChangeFormValue,
      setSearchValue,
      onDownloadCSV,
      handleSelectAllQuery,
      handleCheckboxChangeQuery,
      setCurrentPage,
      onDeleteCodes,
      onUpdateBatchStatus,
      handleInputChangeSuppliers,
      handleInputChangeProducts,
      handleClearFilters,
      resetCheckedState,
    },
  }
}
