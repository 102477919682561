import React from 'react'
import { ActionsButton, IActionsButtons } from 'shared/components/button/actions-button'
import {ISupplier} from 'shared/types/supplier'
import { ICurrenciesDTO } from 'app/api/settings-api/selects-api';
import { CurrencyFormatter } from 'shared/helpers/currency-formatter';

interface ISupplierTrackerTableRow {
  supplier: ISupplier
  currencies: ICurrenciesDTO[]
  handleTrackerRowClick: (productId: string) => void
  handleAddSupplierClick: (supplier: ISupplier) => void
  handleEditProductClick: (id: number, name: string) => void
  handleDeleteProductClick: (id: number) => void
}

export const SupplierTrackerTableRow: React.FC<ISupplierTrackerTableRow> = ({
  supplier,
  currencies,
  handleTrackerRowClick,
  handleAddSupplierClick,
  handleEditProductClick,
  handleDeleteProductClick,
}) => {
  const SUPPLIER_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'Add',
      onClick: () => handleAddSupplierClick(supplier),
    },
    {
      label: 'View',
      onClick: () => handleTrackerRowClick(supplier.product_id.toString()),
    },
    {
      label: 'Edit External Product',
      onClick: () =>
        handleEditProductClick(supplier.product_id, supplier.product_name),
    },
    {
      label: 'Delete External Product',
      onClick: () => handleDeleteProductClick(supplier.product_id)
    },
  ]
  return (
    <tr className='border-bottom'>
      <td>{supplier.supplier_name || '--'}</td>
      <td>{supplier.suppliers_count}</td>
      <td>{supplier.product_name}</td>
      {currencies &&
        currencies.map((currency) => (
          <td key={currency.id}>
            {supplier.currencies[currency.id] === 0
              ? '--'
              : CurrencyFormatter(supplier.currencies[currency.id], currency)}
          </td>
        ))}
      <td>
        <ActionsButton label='Actions' buttons={SUPPLIER_ACTION_BUTTONS} />
      </td>
    </tr>
  )
}
