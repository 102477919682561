import React, { useState} from 'react';

const bodyElement = document.querySelector('body');

export const useSidebarController = () => {
        const [isOpen, setIsOpen] = useState(bodyElement?.getAttribute('data-kt-app-sidebar-minimize') === 'on');

        let sidebar = document.querySelector('#kt_app_sidebar');
        const addDataAttribute = () => {
            bodyElement?.setAttribute('data-kt-app-sidebar-minimize', 'on');
        }
        const removeDataAttribute = () => {
            bodyElement?.removeAttribute('data-kt-app-sidebar-minimize');
        }

        function removeDrawer() {
            const overlayElement = document.querySelector('.drawer-overlay');
            if (overlayElement) {
                // @ts-ignore
                overlayElement?.parentNode.removeChild(overlayElement);
            }
        }

        function removeMobileDataAttribute() {
            bodyElement?.removeAttribute('data-kt-drawer-app-sidebar');
            bodyElement?.removeAttribute('data-kt-drawer');
            sidebar?.classList.remove('drawer-on');
        }


        function addDrawer() {
            const overlayElement = document.createElement('div');
            overlayElement.style.zIndex = '105';
            overlayElement.className = 'drawer-overlay';
            overlayElement.onclick = () => {
                removeMobileDataAttribute()
                removeDrawer()
            }
            document.body.appendChild(overlayElement);
        }

        function addMobileDataAttribute() {
            bodyElement?.setAttribute('data-kt-drawer-app-sidebar', 'on');
            bodyElement?.setAttribute('data-kt-drawer', 'on');
            if (!sidebar) {
                sidebar = document.querySelector('#kt_app_sidebar')
                sidebar?.classList.add('drawer', 'drawer-start');
                sidebar?.classList.add('drawer-on');
            }
            if (!sidebar?.classList.contains('drawer') || !sidebar?.classList.contains('drawer-start')) {
                document.querySelector('#kt_app_sidebar')?.classList.add('drawer', 'drawer-start');
                sidebar?.classList.add('drawer', 'drawer-start');
            }
            sidebar?.classList.add('drawer-on');

        }

        const closeMobileSidebar = () => {
            removeMobileDataAttribute()
            removeDrawer()
        }

        const openMobileSidebar = () => {
            addDrawer()
            addMobileDataAttribute()
        }

        const sidebarToggle = () => {
            setIsOpen(prevState => {
                if (bodyElement?.getAttribute('data-kt-app-sidebar-minimize') === 'on') {
                    removeDataAttribute()
                    return false
                } else {
                    addDataAttribute()
                    return true
                }

            })
        }
        return {sidebarToggle, isOpen, openMobileSidebar, closeMobileSidebar}
    }
;

