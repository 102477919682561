import React from "react";
import ReactSelect, {Props, components} from "react-select";
import styled from "./select.module.scss";
import {SELECT_STYLE_CONFIG} from "./select-style";
import {DropdownIndicator} from "./dropdown-indicator";

interface ISelect extends Props {
    label?: string;
    errorMessage?: string;
    menuHeight?: number;
    error?: boolean
}


const RootSelect = ({
                        className,
                        label,
                        name,
                        errorMessage,
                        error,
                        menuHeight = 300,
                        ...rest
                    }: ISelect) => {
    const styles = SELECT_STYLE_CONFIG(errorMessage, error)

    return (
        <div className={styled.container}>
            {!!label && (
                <label className={styled.label} htmlFor={name}>
                    {label}
                </label>
            )}

            <ReactSelect
                id={name}
                name={name}
                className={className}
                classNamePrefix="select"
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator,
                }}
                styles={styles}
                {...rest}
            />
            <p>{errorMessage}</p>
        </div>
    );
};

export const MultiSelect = RootSelect;
