import React, {useEffect, useRef, useState} from 'react'
import {usePopper} from 'react-popper'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from '../../../../../shared/components/icon/icon-list'
import styles from './actions-window.module.scss'

interface IActionsWindow {
  onRemove: () => void
}

const ActionsWindowComponent: React.FC<IActionsWindow> = ({onRemove}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const rootRef = useRef(null)

  const {styles: popperStyles, attributes} = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ],
    }
  )
  const handleClickOutside = (event) => {
    if (rootRef.current && !rootRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', handleClickOutside)
    } else {
      window.removeEventListener('click', handleClickOutside)
    }
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [isOpen])
  const onToggleOpen = (event?: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen((prevState) => !prevState)
  }
  const handelRemove = (event?: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onRemove()
  }

  return (
    <div className='position-relative'>
      <button
        className={styles.button}
        ref={(ref) => {
          setReferenceElement(ref)
          rootRef.current = ref
        }}
        onClick={onToggleOpen}
      >
        <Icon icon={ICON_COLLECTION.verticalDots} />
      </button>
      {isOpen && (
        <ul
          className={styles['action-list']}
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          <li className={styles['action-list__item']}>
            <button onClick={handelRemove}>Remove</button>
          </li>
        </ul>
      )}
    </div>
  )
}

export const ActionsWindow = React.memo(ActionsWindowComponent)
