import {
  ICreateSupplierDTO,
  SuppliersApi,
} from 'app/api/suppliers-api/suppliers-api'
import {AxiosError} from 'axios'
import {useFormik} from 'formik'
import {useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {ISuppliersItem} from 'shared/types/supplier'
import {validationSchema} from '../../components/schemes/create-supplier-schema'
import { useNavigate } from 'react-router-dom'
import { SUPPLIER_ROUTES } from 'shared/config/routes'
import { useSelectController } from 'shared/hooks/use-select-controller'
import { SelectsApi } from 'app/api/settings-api/selects-api'
import { IOption } from 'shared/components/select/select'
import { SingleValue } from 'react-select'
import { ICountry } from 'shared/types/country'

const INITIAL_VALUES = {
  name: null,
  company_director: null,
  company_address: null,
  city: null,
  zip_post_code: null,
  website: null,
  VAT_number: null,
  company_registration_number: null,
  main_email: null,
  email_to_receive_digital_delivery_and_invoice: null,
  account_payable_contact: null,
  account_payable_email: null,
  phone_number: null,
  country_id: null
}

export const useCreateSupplierPage = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
  const handleBackClick = () => {
      formik.resetForm()
      navigate(SUPPLIER_ROUTES.SUPPLIERS.path)
  }

  const createSupplierMutation = useMutation<
    ISuppliersItem,
    AxiosError<{error: string}>,
    ICreateSupplierDTO
  >(SuppliersApi.createNewSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.all_suppliers,
      ])
      navigate(SUPPLIER_ROUTES.SUPPLIERS.path)
      toast.success(`Supplier was created successfully!`)
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const onSubmit = (values: ICreateSupplierDTO) => {
    createSupplierMutation.mutate(values)
  }

  const formik = useFormik<ICreateSupplierDTO>({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validationSchema,
  })

  const handleCreatePurchase = () => {
    formik.submitForm()
  }

  const isDisabledCreateButton =
    createSupplierMutation.isLoading || Object.keys(formik.touched).length === 0  
  
  useEffect(() => {
    if (createSupplierMutation.isSuccess) {
      createSupplierMutation.reset()
    }
  }, [createSupplierMutation.isSuccess])

  const {
    isLoading: isLoadingRegions,
    handleInputChange: handleChangeRegions,
    formationOptions: optionRegions,
  } = useSelectController({
    key: SUPPLIERS_TRACKER_QUERY_KEYS.regions,
    Fn: SelectsApi.getRegions,
    params: 'name',
  })

  const handleChangeFormValue = (name: string, value: IOption) => {
    formik.setFieldValue(name, value.id)
  }

  const handlePhoneInput = (name: string, value: string) => {
    formik.setFieldValue(name, value)
  }

  const handleCountrySelect = (newValue: SingleValue<ICountry>) => {
    formik.setFieldValue('country_id', newValue?.id)
  }

  const isLoading = createSupplierMutation.isLoading
  return {
    models: {
      isDisabledCreateButton,
      isLoading,
      formik,
      isLoadingRegions,
      optionRegions,
    },
    commands: {
      handleBackClick,
      handleCreatePurchase,
      handleChangeRegions,
      handleChangeFormValue,
      handlePhoneInput,
      handleCountrySelect,
    },
  }
}
