import React, {useEffect, useState} from 'react'
import {menuLinks} from '../../constants/header'
import {menuButtons} from '../../constants/header'
import {AUTH_ROUTES} from '../../../../shared/config/routes'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Link} from 'react-router-dom'
import {Icon} from 'shared/components/icon/icon'
import clsx from 'clsx'
import {LANDING_NAVIGATION} from 'modules/landing/constants/landing-navigation'
import ScrollToAnchor from 'modules/landing/components/landing-header/scroll-to-anchor'
import styles from './landing-header.module.scss'

const LandingHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeAnchor, setActiveAnchor] = useState('')

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto'
    const handleScroll = () => {
      menuLinks.forEach((link) => {
        const element = document.getElementById(link.id)
        if (element) {
          const rect = element.getBoundingClientRect()
          if (rect.top <= 100 && rect.bottom >= 0) {
            setActiveAnchor(link.title)
          }
        }
      })
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isMenuOpen])

  return (
    <header
      className={clsx(styles['header'], {
        [styles['header--open']]: isMenuOpen,
      })}
    >
      <div className={styles['header__container']}>
        <ScrollToAnchor
          idName={LANDING_NAVIGATION.hero.id}
          onClick={() => setIsMenuOpen(false)}
          className={clsx(styles['menu__link'], styles['header__logo'])}
        >
          <Icon icon={ICON_COLLECTION.LandingLogoHeader} />
        </ScrollToAnchor>
        <div
          className={clsx(styles['burger-button'], {
            [styles['burger-button--active']]: isMenuOpen,
          })}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={styles['menu']}>
          <ul className={styles['menu__list']}>
            {menuLinks.map((link) => (
              <li
                key={link.id}
                className={clsx(styles['menu__item'], {
                  [styles['menu__item--active']]: activeAnchor === link.title,
                })}
                onClick={() => setActiveAnchor(link.title)}
              >
                <ScrollToAnchor
                  idName={link.id}
                  onClick={() => setIsMenuOpen(false)}
                  className={styles['menu__link']}
                >
                  {link.title}
                </ScrollToAnchor>
              </li>
            ))}
          </ul>
          <div className={styles['header__buttons']}>
            <Link to={AUTH_ROUTES.LOGIN.path} className={styles['header__btn']}>
              {menuButtons.login}
            </Link>
            <Link
              to={AUTH_ROUTES.REGISTRATION.path}
              className={styles['header__btn']}
            >
              {menuButtons.create}
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default LandingHeader
