import React from 'react';
import { DatePickerDialog } from 'shared/components/day-picker/day-picker';
import { Icon } from 'shared/components/icon/icon';
import { ICON_COLLECTION } from 'shared/components/icon/icon-list';
import { Input } from 'shared/components/input/input';
import { IOption, Select } from 'shared/components/select/select';
import { initialFormOrderName } from 'shared/constants/orders';

interface IOrdersSearchBar {
    searchValue:string
    statusValue:number
    methodValue:number
    handleChangeFormValue:(name: initialFormOrderName, value: IOption)=>void
    handleDateChangeFormValue:(startDate:string,endDate:string) => void
    setSearchValue: (text: string) => void
}

const STATUSES: IOption[] = [
  {
    label: 'Pending',
    id: 1,
  },
  {
    label: 'Completed',
    id: 2,
  },
  {
    label: 'Failed',
    id: 3,
  },
  {
    label: 'Pre-ordered',
    id: 4,
  },
  {
    label: 'Unpaid Pre-order',
    id: 5,
  },
  {
    label: 'Part-delivered',
    id: 6,
  },
]


const METHODS: IOption[] = [
    {
        label: 'WEB',
        id: 1
    },
    {
        label: 'API',
        id: 2
    }
]

const OrdersSearchBarComponent: React.FC<IOrdersSearchBar> = (props) => {
    const {
        searchValue,
        statusValue,
        methodValue,
        handleChangeFormValue,
        handleDateChangeFormValue,
        setSearchValue    
    } = props

    return (
        <div className="mb-5">
            <div className="row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-4 position-relative">
                <div className="col mb-5">
                    <Input 
                        startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch}/>}
                        placeholder='Search'
                        value={searchValue}
                        onChange={event=>setSearchValue(event.target.value)}
                    />
                </div>
                <div className="col mb-5">
                    <DatePickerDialog 
                        handleDateChangeFormValue={handleDateChangeFormValue}
                    />                   
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={METHODS} 
                        value={methodValue}
                        onChange={option => handleChangeFormValue('method', option)}
                        placeholder='Method'
                    />
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={STATUSES} 
                        value={statusValue}
                        onChange={option => handleChangeFormValue('status', option)}
                        placeholder='Status'
                    />                    
                </div>
            </div>
        </div> 
    )
}

export const OrdersSearchBar = React.memo(OrdersSearchBarComponent)