import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { IPurchaseProductsList, PurchaseApi } from "app/api/purchases-api/purchases-api";
import { ButtonList } from "shared/components/button-list/button-list";
import { Button } from "shared/components/button/button";
import { Modal } from "shared/components/modal-base";
import { PURCHASES_MODAL_NAME } from "shared/constants/modal-names";
import { PURCHASES_QUERY_KEYS } from "shared/constants/query-keys";
import { useStateModalManager } from "shared/context/modal-manager";
import { AxiosError } from "axios";

export const ConfirmDeletePurchaseProductModal = () => {
  const [purchaseId, setPurchaseId] = useState<number>()
  const [productId, setProductId] = useState<number>()
  const modalState = useStateModalManager(
    PURCHASES_MODAL_NAME.confirm_delete_purchase_product,
    {
      onBeforeOpen: (purchaseId: number, productId: number) => {
        setPurchaseId(purchaseId)
        setProductId(productId)
      },
    }
  )

  const queryClient = useQueryClient()

  const deleteProductMutation = useMutation(
    async ({
      purchaseId,
      productId,
    }: {
      purchaseId: number
      productId: number
    }) => {
      await PurchaseApi.deleteProductFromPurchase(purchaseId, productId)
    },
    {
      onSuccess: (data, variables) => {        
        queryClient.setQueryData(
          [PURCHASES_QUERY_KEYS.purchase_product, `${purchaseId}`],
          (prevData:IPurchaseProductsList)=>{
            const tempProducts = prevData?.data.filter(
              (product) => product.id !== variables.productId
            )
            return {...prevData, data: tempProducts}
          }
        )
        queryClient.invalidateQueries([
          PURCHASES_QUERY_KEYS.purchase_product,
          purchaseId,
        ])
        toast.success(`Product was deleted successfully!`)
      },
      onError: (error:AxiosError<{error:string}>) => {
        toast.error(error?.response.data.error)
      },
    }
  )

  const handleDeleteProductFromPurchase = (
    purchaseId: number,
    productId: number
  ) => {
    deleteProductMutation.mutate({purchaseId, productId})
  }

  const isLoading = deleteProductMutation.isLoading

  const handleConfirmDeleteClick = () => {
    handleDeleteProductFromPurchase(purchaseId, productId)
  }

  const handleClose = () => {
    modalState.close()
  }
  useEffect(() => {
    handleClose()
    deleteProductMutation.reset()
  }, [deleteProductMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!isLoading}
    >
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to delete this product?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} disabled={isLoading} />
          <Button
            label='Delete'
            mode='remove'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleConfirmDeleteClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}