import { CUSTOMERS_ROUTES } from "shared/config/routes";
import { ICustomer } from "shared/types/user";


export const CUSTOMER_ROUTES =(customer:ICustomer) => [
    {
        title:CUSTOMERS_ROUTES.CUSTOMER.title,
        path:CUSTOMERS_ROUTES.CUSTOMER.generate(customer.user_id.toString()),       
    },
    {
        title:CUSTOMERS_ROUTES.CUSTOMER_FILES.title,
        path:CUSTOMERS_ROUTES.CUSTOMER_FILES.generate(customer.user_id.toString()),       
    },
    {
        title:CUSTOMERS_ROUTES.CUSTOMER_WALLETS.title,
        path:CUSTOMERS_ROUTES.CUSTOMER_WALLETS.generate(customer.user_id.toString()),
    },
    {
        title:CUSTOMERS_ROUTES.API.title,
        path:CUSTOMERS_ROUTES.API.generate(customer.user_id.toString()),
    },                         
    {
        title:CUSTOMERS_ROUTES.SETTINGS.title,
        path:CUSTOMERS_ROUTES.SETTINGS.generate(customer.user_id.toString()),
    },                         
    {
        title:CUSTOMERS_ROUTES.RESTRICTIONS.title,
        path:CUSTOMERS_ROUTES.RESTRICTIONS.generate(customer.user_id.toString()),
    },
    {
        title:CUSTOMERS_ROUTES.TWO_FACTOR.title,
        path:CUSTOMERS_ROUTES.TWO_FACTOR.generate(customer.user_id.toString()),
    }
]