import React from 'react'
import clsx from 'clsx'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {IRegion} from 'shared/types/base'
import {RegionsTableRow} from './regions-table-row'

export interface IRegionsTable {
  regions: IRegion[]
  isLoading: boolean
  isSaveButtonLoading: boolean
  handleSaveChanges: (region: IRegion) => void
  handleDeleteClick: (region: IRegion) => void
}

export const RegionsTable: React.FC<IRegionsTable> = ({
  regions,
  isLoading,
  isSaveButtonLoading,
  handleSaveChanges,
  handleDeleteClick,
}) => {
  return (
    <div className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}>
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light text-exl-primary'>
              <th className='px-7 w-25 min-w-200px'>Name</th>
              <th className='d-flex flex-end'>Actions</th>
            </tr>
          </thead>
          {regions && regions.length ? (
            <tbody>
              {regions.map((region: IRegion) => (
                <RegionsTableRow
                  key={region.id}
                  region={region}
                  isSaveButtonLoading={isSaveButtonLoading}
                  handleDeleteClick={handleDeleteClick}
                  handleSaveChanges={handleSaveChanges}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={3}>No regions.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
