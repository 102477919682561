import React, {useState} from 'react'
import {useStateModalManager} from 'shared/context/modal-manager'
import {CODES_MODAL_NAME} from 'shared/constants/modal-names'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'

export type validationMessageType = {
  maxPurchasePrice: number
  sellPrice: number
}
export const AlertValidationCodeUploadModelForLessSelling = () => {
  const [validationMessage, setValidationMessage] =
    useState<validationMessageType>({
      maxPurchasePrice: null,
      sellPrice: null,
    })
  const modalState = useStateModalManager(
    CODES_MODAL_NAME.upload_codes_alert_validation,
    {
      onBeforeOpen: (data: validationMessageType) => {
        setValidationMessage(data)
      },
    }
  )
  const onContinue = () => {
    modalState.resolveCallback()
    modalState.close()
  }
  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={true}>
      <div className='p-5 text-center'>
        <h5 className='text-exl-primary mb-5'>
          This product has codes with purchase price (
          {validationMessage.maxPurchasePrice?.toFixed(2)}) that is bigger than the new sell price ({validationMessage.sellPrice?.toFixed(2)}).
          <p>Do you want to proceed?</p> 
        </h5>

        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} />
          <Button label='Continue' mode={'remove'} onClick={onContinue} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
