/* eslint-disable react-hooks/rules-of-hooks */
import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {IWallet} from '../../../shared/types/api-types/wallets-type'
import {WalletsApi} from '../../api/dashboard-api/wallets-api'
import {toast} from 'react-toastify'
import {useCartState} from '../cart/state'
import {UserApi} from 'app/api/user-api/user-api'
import {useUserState} from '../user/state'
import {useAuthState} from '../auth/state'

interface ITemporaryUserState {
  userId: number
  company_name: string
  wallets: IWallet[]
  isLoading: boolean
  isLoadingWallet: boolean
  initTemporaryUser: (userId: number) => void
  resetTemporaryUser: () => void
  setDefaultWallet: (wallet: IWallet) => void
  getWallets: (userId: number | string) => Promise<void>
}

export const useTemporaryUserState = create<ITemporaryUserState>()(
  devtools(
    (set, get) => ({
      userId: null,
      company_name: null,
      cartData: null,
      wallets: [],
      isLoading: false,
      isLoadingWallet: false,
      initTemporaryUser: async function (userId) {
        try {
          if (userId === get().userId) return null
          set({isLoading: true})
          const tempWalletsData = await WalletsApi.getWallets(userId)
          const user = await UserApi.getUserById(userId)
          useUserState.getState().setIsCartHasProducts(user.cart_has_products)
          useUserState.getState().setProductsInCartQuantity(user.cart_quantity)
          useCartState.getState().getCart(userId)
          set({company_name: user.customer.company_name})
          set({wallets: tempWalletsData})
          set({userId: userId})
          set({isLoading: false})
          set({isLoadingWallet: false})
        } catch (e) {
          set({isLoading: false})
          toast.error('Something went wrong')
        }
      },
      resetTemporaryUser: async () => {
        set({
          userId: null,
          wallets: [],
          isLoading: false,
          company_name: null,
          isLoadingWallet: false,
        })
        const {user: userId} = useAuthState.getState()
        const user = await UserApi.getUserById(userId)
        useUserState.setState({
          isCartHasProducts: user.cart_has_products,
          cartQuantity: user.cart_quantity,
        })
      },
      setDefaultWallet(wallet) {
        set({isLoadingWallet: true})
        const tempWallets: IWallet[] = get().wallets.map(
          (walletItem: IWallet) => {
            if (walletItem.id === wallet.id) {
              return {...walletItem, is_default: 1}
            } else {
              return {...walletItem, is_default: 0}
            }
          }
        )
        set({wallets: tempWallets})
        set({isLoadingWallet: false})
      },
      getWallets: async function (userId) {
        try {
          set({isLoadingWallet: true})
          const tempWalletsData = await WalletsApi.getWallets(userId)
          set({wallets: tempWalletsData})
          set({isLoadingWallet: false})
        } catch (e) {
          set({isLoadingWallet: false})
        }
      },
    }),
    {name: 'useTemporaryUserState', store: 'useTemporaryUserState'}
  )
)
