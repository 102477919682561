import React from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "shared/components/button/button"
import { CODES_ROUTES } from "shared/config/routes"
import { PURCHASES_MODAL_NAME } from "shared/constants/modal-names"
import { useModalManager } from "shared/context/modal-manager"
import { IPurchase } from "shared/types/purchase"
import { DateFormatter } from "shared/utils/date-formatter"

interface IPurchaseHeaderComponent {
    purchase:IPurchase
    isCopyButtonLoading:boolean
    handleCopyClick:(purchaseId:number)=>(event:React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void
    handleGoBackClick:()=>void
}

const PurchaseHeaderComponent:React.FC<IPurchaseHeaderComponent> = ({purchase,isCopyButtonLoading,handleCopyClick, handleGoBackClick}) => {
    const navigate = useNavigate()
    const modalManager = useModalManager()
    const handleUploadClick = () => {
        navigate(CODES_ROUTES.CREATE_CODES.pathFromPurchases({
            purchase_id:`${purchase.id}`,
            product_id:null,
            supplier_id:`${purchase.supplier.id}`,
            currency_id:`${purchase.currency.id}`,
            purchase_price:null
        }))
    }

    const handleAddProductClick = () => {
        modalManager.open(PURCHASES_MODAL_NAME.add_product,{purchase:purchase.id})
    }

    return (
        <div className='d-md-flex justify-content-between align-items-start mb-5 gap-5 flex-wrap'>
            <h1 className='d-flex align-items-start gap-5 mb-5 mb-md-0'>
                <Button label='<' type="button" onClick={handleGoBackClick} className='min-w-25px'/>
                <div className="d-flex flex-column gap-2 mt-2">
                    <span className='text-exl-primary'>ID:{purchase.id}</span>
                    <span className='text-exl-primary mt-5 fs-3'>{purchase?.supplier.name}</span>
                    <span className='text-exl-primary fs-3'>{DateFormatter(purchase?.created_at)}</span>
                    <span className='text-exl-primary fs-3'>{purchase?.currency.iso}</span>
                    <span className='text-exl-primary fs-3 mw-300px text-wrap text-break'>{purchase?.comment}</span>
                </div>
            </h1>
            <div className='d-flex gap-5 flex-column flex-md-row w-100 w-md-auto w-lg-100 w-xl-auto justify-content-evenly '>
                <Button label='Upload' onClick={handleUploadClick}/>
                <Button label='Duplicate' isLoading={isCopyButtonLoading} disabled={isCopyButtonLoading} onClick={handleCopyClick(purchase.id)}/>
                <Button label='Add product' mainButton onClick={handleAddProductClick}/>
            </div>
        </div>
    )
}

export const PurchaseHeader = React.memo(PurchaseHeaderComponent)