import React from 'react'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {useAdminSettingsPage} from './use-admin-settings-options'
import {AdminSettingsTable} from '../../components/tables/admin-settings/admin-settings-table'
import { AdminSettingsSearchBar } from '../../components/admin-settings-search-bar'

export const AdminSettingsOptions = () => {
  const {models, commands} = useAdminSettingsPage()
  return (
    <>
      <div className='card position-relative'>
        <AdminSettingsSearchBar
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
          handleChangeSelectValue={commands.handleChangeSelectValue}
          paymentValue={models.paymentValue}
        />
        <AdminSettingsTable
          settings={models.settings}
          isLoading={models.isTableLoading}
          isSaveButtonLoading={models.isSaveButtonLoading}
          handleSaveChanges={commands.handleUpdateClick}
        />
        {models.settings && models.settings.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
    </>
  )
}
