import { useDebounce } from "_metronic/helpers"
import { SelectsApi } from "app/api/settings-api/selects-api"
import { IProductSuppliersList, SuppliersApi } from "app/api/suppliers-api/suppliers-api"
import { AxiosError } from "axios"
import { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { SUPPLIER_ROUTES } from "shared/config/routes"
import { SUPPLIERS_MODAL_NAME } from "shared/constants/modal-names"
import { SUPPLIERS_TRACKER_QUERY_KEYS } from "shared/constants/query-keys"
import { useModalManager } from "shared/context/modal-manager"
import { BlobErrorResponse } from "shared/helpers/blob-error-response"
import { DownloadService } from "shared/services/download-service"
import { ISupplier } from "shared/types/supplier"
import { removeEmptyFields } from "shared/utils/remove-empty-fields"

export const useSupplierTrackerPage = () => {
  const queryClient = useQueryClient()
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const modalManager = useModalManager()
  const [searchValue, setSearchValue] = useState<string>(search.get('name'))

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      name: search.get('name'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      name: queryParams.name ? {name: queryParams.name} : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const suppliersQuery = useQuery({
    queryKey: [SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker, queryParams],
    queryFn: async () => await SuppliersApi.getSupplierTracker(queryParams),
    refetchOnWindowFocus: false,
  })

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('name', debouncedSearchTerm)
    setSearchParams({
      ...params.name,
    })
  }, [debouncedSearchTerm])

  const navigate = useNavigate()
  const handleTrackerRowClick = (productId: string) => {
    navigate(SUPPLIER_ROUTES.SUPPLIER.generate(productId))
  }

  const handleAddSupplierClick =
    (supplier: ISupplier) => {
      modalManager.open(SUPPLIERS_MODAL_NAME.add_supplier, supplier.product_id, supplier.product_name)
    }

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.name,
      ...params.page,
    })
  }

  const currenciesQuery = useQuery({
    queryKey: [SUPPLIERS_TRACKER_QUERY_KEYS.currencies],
    queryFn: async () => await SelectsApi.getCurrencies(),
  })

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        name:queryParams.name
      }
      const csvData = await SuppliersApi.getSupplierTrackerCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Supplier Tracker Report')
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const handleEditProductClick = (id: number, name: string) => {
    modalManager.open(SUPPLIERS_MODAL_NAME.update_product, id, name)
  }

  const handleDeleteProductClick = (id: number) => {
    modalManager.open(SUPPLIERS_MODAL_NAME.delete_product, id)
  }

  const handleCreateProductClick = () => {
    modalManager.open(SUPPLIERS_MODAL_NAME.create_product)
  }

  const recountSupplierTrackerMutation = useMutation<
    IProductSuppliersList,
    AxiosError<{error: string}>
  >(SuppliersApi.recountSupplierTracker, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        [SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker],
        {
          ...suppliersQuery,
          data: data,
        }
      )
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.suppliers_traker,
      ])
      toast.success(`Supplier Tracker was recount successfully!`)
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const handleRecountClick = () => {
    recountSupplierTrackerMutation.mutate()
  }

  const isRecountLoading = recountSupplierTrackerMutation.isLoading

  const isTableLoading = suppliersQuery.isLoading || currenciesQuery.isLoading

  return {
    models: {
      values: queryParams,
      suppliers: suppliersQuery.data ? suppliersQuery.data.data : [],
      meta: suppliersQuery.data ? suppliersQuery.data.meta : null,
      isTableLoading,
      searchValue,
      currencies: currenciesQuery.data ? currenciesQuery.data : [],
      isCSVLoading,
      isRecountLoading,
    },
    commands: {
      handleTrackerRowClick,
      handleAddSupplierClick,
      handleEditProductClick,
      handleDeleteProductClick,
      setCurrentPage,
      setSearchValue,
      onDownloadCSV,
      handleCreateProductClick,
      handleRecountClick,
    },
  }
}
