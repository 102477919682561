import { IMeta } from 'shared/types/api-types/api-types'
import { BaseHttpServices } from '../../../shared/services/base-http-services'
import { ISupplier, ISuppliersItem } from 'shared/types/supplier'

export interface ISupplierList {
  data: ISupplier[]
  meta: IMeta
}

export interface IAddSupplierDTO {
  product_id?: number
  supplier_id?: number
  currency_id?: number
  price?: string
}

interface ISuppliersParams {
  name?: string | number
  page?: string | number
}

export interface IProductSuppliersList {
  data: IProductSupplier[]
}

export interface IProductSupplier {
  product_id: number
  supplier_name: string
  supplier_id: number
  currencies: Record<string, number>
}

export interface IUpdateProductSupplierParams {
  product_id: string
  supplier_id: number
  currency: string
  price: number
}

export interface IProductSupplierTracker {
  supplier_name: string
  currencies: [
    {
      currency_name: string
      price: string
    }
  ]
}

export interface IAllSuppliersList {
  data: IAllSupplier[]
  meta: IMeta
}

export interface IAllSupplier {
  id: number;
  name: string;
  company_director: string | null;
  company_address: string | null;
  city: string | null;
  zip_post_code: string | null;
  main_email: string | null;
  website: string | null;
  VAT_number: string | null;
  company_registration_number: string | null;
  email_to_receive_digital_delivery_and_invoice: string | null;
  account_payable_contact: string | null;
  account_payable_email: string | null;
  phone_number: string | null;
  country_id: number | null;
}

export interface ICreateSupplierDTO {
  name: string
  company_director: string
  company_address: string
  city: string
  zip_post_code: string
  website: string
  VAT_number: string
  company_registration_number: string
  main_email: string
  account_payable_contact: string
  account_payable_email: string
  phone_number: string
  country_id: number
}

export interface IUpdateSupplierDTO {
  data: ICreateSupplierDTO
  supplierId: string
}

export interface ISingleSupplier {
  data: IAllSupplier
  meta: IMeta
}

export interface IOneExternalProduct {
  id: string | number
  product_id: string | number
  name: string
}

export interface IUpdateExternalProductDTO {
  id: number
  name: string
}

export class SuppliersApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getSupplierTracker = async (
    params: ISuppliersParams | null
  ): Promise<ISupplierList> => {
    const payload = await this.http.get(`/supplier_tracker`, {
      params: params,
    })
    return payload.data
  }

  getProductSuppliers = async (
    productId: number
  ): Promise<IProductSuppliersList> => {
    const payload = await this.http.get(`/supplier_tracker/${productId}`)
    return payload.data
  }

  updateSupplier = async (supplier: IAddSupplierDTO): Promise<ISupplier> => {
    const payload = await this.http.post(
      `/supplier_tracker/${supplier.product_id}`,
      {
        supplier: supplier.supplier_id,
        currency: supplier.currency_id,
        price: supplier.price,
      }
    )
    return payload.data.data
  }

  deleteSupplierFromProduct = async (
    productId: number,
    supplierId: number
  ): Promise<string> => {
    const payload = await this.http.delete(
      `/supplier_tracker/${productId}/${supplierId}`
    )
    return payload.data.message
  }

  updateProductSupplier = async (
    params: IUpdateProductSupplierParams
  ): Promise<IProductSupplierTracker> => {
    const payload = await this.http.put(
      `/supplier_tracker/${params.product_id}/${params.supplier_id}`,
      {currency: params.currency, price: params.price}
    )
    return payload.data.data
  }

  getALLSuppliers = async (
    params: ISuppliersParams | null
  ): Promise<IAllSuppliersList> => {
    const payload = await this.http.get(`/suppliers`, {
      params: params,
    })
    return payload.data
  }

  createNewSupplier = async (
    createSupplierDTO: ICreateSupplierDTO
  ): Promise<ISuppliersItem> => {
    const payload = await this.http.post(`/suppliers`, createSupplierDTO)
    return payload.data.data
  }

  deleteSupplier = async (supplierId: number): Promise<string> => {
    const payload = await this.http.delete(`/suppliers/${supplierId}`)
    return payload.data.message
  }

  getSingleSupplier = async (supplierId: string): Promise<ISingleSupplier> => {
    const payload = await this.http.get(`/suppliers/${supplierId}`)
    return payload.data
  }

  updateSingleSupplier = async (
    data: IUpdateSupplierDTO
  ): Promise<ISingleSupplier> => {
    const payload = await this.http.patch(
      `/suppliers/${data.supplierId}`,
      data.data
    )
    return payload.data.data
  }

  getSuppliersCSV = async (params:Pick<ISuppliersParams,'name'>): Promise<string> => {
    const payload = await this.http.get(`/suppliers/csv`, {
      responseType: 'blob',
      params:params
    })
    return payload.data
  }

  getSupplierTrackerCSV = async (params:Pick<ISuppliersParams,'name'>): Promise<string> => {
    const payload = await this.http.get(`/supplier_tracker/csv`, {
      responseType: 'blob',
      params:params
    })
    return payload.data
  }

  getProductSupplierCSV = async (product: number): Promise<string> => {
    const payload = await this.http.get(`/supplier_tracker/${product}/csv`, {
      responseType: 'blob',
      params: {
        product,
      },
    })
    return payload.data
  }

  getOneExternalProduct = async (
    id: number | string
  ): Promise<IOneExternalProduct> => {
    const payload = await this.http.get(`/potential_products/${id}`)
    return payload.data.data
  }

  createExternalProduct = async(
    productName: string
  ): Promise<IOneExternalProduct> => {
    const payload = await this.http.post('/potential_products', {
      name: productName,
    })
    return payload.data.data
  }

  updateExternalProduct = async (
    extProduct: IUpdateExternalProductDTO
  ): Promise<IOneExternalProduct> => {
    const payload = await this.http.post(
      `/potential_products/${extProduct.id}`,
      {
        name: extProduct.name,
      }
    )
    return payload.data.data
  }

  deleteExternalProduct = async (productId: number): Promise<string> => {
    const payload = await this.http.delete(`/potential_products/${productId}`)
    return payload.data.message
  }

  recountProductSuppliers = async (
    id: number
  ): Promise<IProductSuppliersList> => {
    const payload = await this.http.put(`supplier_tracker/${id}/recount`)
    return payload.data.data
  }

  recountSupplierTracker = async (): Promise<IProductSuppliersList> => {
    const payload = await this.http.put(`/supplier_tracker/recount`)
    return payload.data.data
  }
}
export const SuppliersApi = new SuppliersApiService(new BaseHttpServices())
