import clsx from 'clsx'
import React from 'react'
import {IMessage} from 'shared/types/api-types/chat-types'
import {DateFormatter} from 'shared/utils/date-formatter'

interface IMessageRow {
  message: IMessage
  userId: number
  isDrawer: boolean
}

const MessageRowComponent: React.FC<IMessageRow> = ({
  message,
  userId,
  isDrawer,
}) => {
  const isInMessage =message.author.id !== userId
  const state = isInMessage ? 'info' : 'primary'
  const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
    isInMessage ? 'start' : 'end'
  } mb-10`
  return (
    <div className={clsx('d-flex', contentClass, 'mb-10')}>
      <div
        className={clsx(
          'd-flex flex-column align-items position-relative',
          `align-items-${isInMessage ? 'start' : 'end'}`
        )}
      >
        <div className='d-flex align-items-center mb-2'>
          {isInMessage ? (
            <>
              <p className='fs-5 fw-bolder text-gray-900 m-0 me-1 p-0'>
                {message.author.customer.company_name}
              </p>
              <span className='text-muted fs-7 mb-1'>
                {DateFormatter(message.created_at)}
              </span>
            </>
          ) : (
            <div className='position-relative d-flex align-items-center'>
              <span className='text-muted fs-7 mb-1'>
                {DateFormatter(message.created_at)}
              </span>
              <p className='fs-5 fw-bolder text-gray-900 m-0 ms-1 p-0'>You</p>
            </div>
          )}
        </div>
        <div
          className={clsx(
            'p-5 rounded',
            `bg-light-${state}`,
            'text-dark fw-bold mw-lg-400px',
            `text-${isInMessage ? 'start' : 'end'}`
          )}
          data-kt-element='message-text'
        >
          {message.text}
        </div>
      </div>
    </div>
  )
}

export const MessageRow = React.memo(MessageRowComponent)
