import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import styles from './discover-card.module.scss'
import ScrollToAnchor from '../../landing-header/scroll-to-anchor'

interface DiscoverCardProps {
  cardContent: {
    id: string
    title: string
    text: string
    pathBtn: string
    button: string
  },
  index: number
}

const DiscoverCardComponent: React.FC<DiscoverCardProps> = ({cardContent, index}) => {
  const [cardRef, cardInView] = useInView({
    triggerOnce: true,
    threshold: 0,
    delay: 150,
  })
  
  return (
    <div
      className={`col ${clsx(styles['discover-card'], {
        [styles['discover-card--reversed']]: index % 2 === 0,
        [styles['discover-card--in-view']]: cardInView,
      })}`}
      ref={cardRef}
    >
      <div className={styles['card-content']}>
        <h4 className={styles['card-content__title']}>{cardContent.title}</h4>
        <p className={styles['card-content__text']}>{cardContent.text}</p>
      </div>
      {cardContent.pathBtn === 'contact' ? (
        <ScrollToAnchor idName={cardContent.pathBtn} className={styles['discover-card__btn']}>
          {cardContent.button}
        </ScrollToAnchor>
      ) : (
        <Link to={cardContent.pathBtn} className={styles['discover-card__btn']}>
          {cardContent.button}
        </Link>
      )}
    </div>
  )
}

export const DiscoverCard = React.memo(DiscoverCardComponent)
