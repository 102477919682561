import React from 'react'
import { ActionsButton, IActionsButtons } from 'shared/components/button/actions-button'
import {Tooltip} from 'shared/components/tooltip/tooltip'
import {thousandFormatter} from 'shared/helpers/formatter'
import {ICode, IPreOrderQuantity} from 'shared/types/code'
import {ICurrency} from 'shared/types/codes-upload'

interface IProductTableRow {
  code: ICode
  currency: ICurrency
  isCSVLoading: boolean
  loadingItemHash: string
  onDownloadCSV: (hash?: string) => void
}

const ProductTableRowComponent: React.FC<IProductTableRow> = ({
  code,
  currency,
  isCSVLoading,
  loadingItemHash,
  onDownloadCSV,
}) => {
  const quantity =
    typeof code.quantity === 'number' ? (
      (code.quantity as number)
    ) : (
      <span className='d-flex'>
        {code.quantity.map((item: IPreOrderQuantity, index) => {
          const content = item.value[0].toUpperCase() + item.value.slice(1)
          return (
            <span key={index} className='d-flex'>
              <Tooltip key={item.value} content={content}>
                <span className='text-primary'>{item.key}</span>
              </Tooltip>
              {index !== (code.quantity as IPreOrderQuantity[]).length - 1 &&
                '/'}
            </span>
          )
        })}
      </span>
    )
    const PRODUCTS_ACTION_BUTTONS: IActionsButtons[] = [
      {
        label: 'Download',
        isLoading: code.hash === loadingItemHash && isCSVLoading,
        onClick: () => onDownloadCSV(code.hash),
      },
      ...code.is_new_to_download_exists ? [{
        label: 'Download new',
        isLoading:code.hash === loadingItemHash && isCSVLoading,
        onClick: () => onDownloadCSV(code.hash),
      }] : []
    ]
  return (
    <tr className='border-bottom'>
      <td>{code.product.name}</td>
      <td>{`${currency?.symbol}${thousandFormatter(code.price)}`}</td>
      <td>{quantity}</td>
      <td>{`${currency?.symbol}${thousandFormatter(code.sub_total)}`}</td>
      <td>
        <ActionsButton
          label={'Actions'}
          buttons={PRODUCTS_ACTION_BUTTONS}
        />
      </td>
    </tr>
  )
}

export const ProductTableRow = React.memo(ProductTableRowComponent)
