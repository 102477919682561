import React, {useEffect, useState} from 'react'
import {useAccount} from 'modules/dashboard/account/hooks/use-account'
import {FormikProps, useFormik} from 'formik'
import isEqual from 'lodash/isEqual'
import {ICustomerSettings} from 'shared/types/user'
import {accountSettingsSchema} from 'modules/dashboard/account/utils/account-settings-schema'
import {CustomerLayout} from '../../layouts/customer-layout'
import {AccountFormSettings} from 'modules/dashboard/account/components/forms/account-form-settings'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'

export const CustomerSettingsPage = () => {
  const [isEditing, setIsEditing] = useState(false)
  const {updateCustomerCredentialsMutation, customer, settingsValues} =
    useAccount()

  const toggleEditMode = () => {
    formik.resetForm()
    setIsEditing((prevValue) => !prevValue)
  }

  useEffect(() => {
    setIsEditing(false)
    formik.resetForm()
    updateCustomerCredentialsMutation.reset()
  }, [updateCustomerCredentialsMutation.isSuccess])

  const formik: FormikProps<ICustomerSettings> = useFormik({
    initialValues: settingsValues,
    validationSchema: accountSettingsSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (!isEqual(removeEmptyFields(values), settingsValues))
          updateCustomerCredentialsMutation.mutate({
            userId: customer.user_id,
            params: removeEmptyFields(values),
          })
        else setIsEditing(false)
      } catch (error) {
        console.error(error)
        setStatus('Something is went wrong! Try again later.')
        setSubmitting(false)
      }
    },
  })

  const toggleIsOrderEmailsEnabled = () => {
    formik.setFieldValue(
      'is_order_email_enabled',
      !formik.values.is_order_email_enabled
    )
  }
  const toggleIsInvoiceEmailsEnabled = () => {
    formik.setFieldValue(
      'is_invoice_email_enabled',
      !formik.values.is_invoice_email_enabled
    )
  }

  return (
    <CustomerLayout
      formik={formik}
      isEditing={isEditing}
      isEditable
      toggleEditMode={toggleEditMode}
      isLoading={updateCustomerCredentialsMutation.isLoading}
    >
      <AccountFormSettings
        formik={formik}
        isEditing={isEditing}
        handleToggleInvoiceEnabled={toggleIsInvoiceEmailsEnabled}
        handleToggleOrderEnabled={toggleIsOrderEmailsEnabled}
      />
    </CustomerLayout>
  )
}
