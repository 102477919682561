import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {OptionsApi} from 'app/api/options-api/options-api'
import { IAdminSetting, IAdminSettingsResponse } from 'shared/types/api-types/admin-setting-types'
import { useDebounce } from '_metronic/helpers'
import { IOption } from 'shared/components/select/select'

const paymentOptions = {
  1: 'payment_gbp_',
  2: 'payment_usd_',
  3: 'payment_eur_',
}

export const useAdminSettingsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(search.get('search'))
  const [paymentValue, setPaymentValue] = useState<number>()
  const queryClient = useQueryClient()

  const queryParams = useMemo(() => {
    return {
      search: search.get('search'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    }
  }, [location.search])

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      search: queryParams.search ? {search: queryParams.search} : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('search', debouncedSearchTerm)
    setSearchParams({
      ...params.search,
    })
    setPaymentValue(0)
  }, [debouncedSearchTerm])

  const settingsQuery = useQuery({
    queryKey: [OPTIONS_QUERY_KEYS.admin_settings, queryParams],
    queryFn: async () => await OptionsApi.getAdminSettings(queryParams),
    refetchOnWindowFocus: false,
  })

  const updateSettingMutation = useMutation<IAdminSetting, AxiosError<{error: string}>, IAdminSetting>(
    OptionsApi.updateAdminSetting,
    {
      onSuccess: (data:IAdminSetting) => {
        queryClient.setQueryData(
          [OPTIONS_QUERY_KEYS.admin_settings, queryParams],
          (prevData: IAdminSettingsResponse) => {
            const settingIndex = prevData.data.findIndex((setting:IAdminSetting) => setting.key === data.key)
            prevData.data.splice(settingIndex, 1, data)
            return prevData
          }
        )
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.admin_settings, queryParams])
        toast.success('Setting updated successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.error)
      },
    }
  )

  const handleUpdateClick = (setting: IAdminSetting) => {
    updateSettingMutation.mutate(setting)
  }

  const setCurrentPage = (page: string) => {
    setSearchParams({
      page,
    })
  }

  const handleChangeSelectValue = (option: IOption) => {
    if (option) {
      setPaymentValue(option.id)
      const params = manageParams('search', paymentOptions[option.id])
      setSearchParams({
        ...params.search,
      })
    } else {
      setPaymentValue(0)
      const params = manageParams('search', '')
      setSearchParams({
        ...params.search,
      })
    }
  }

  const isSaveButtonLoading = updateSettingMutation.isLoading

  const isTableLoading = settingsQuery.isLoading

  return {
    models: {
      values: queryParams,
      settings: settingsQuery.data ? settingsQuery.data.data : [],
      meta: settingsQuery.data ? settingsQuery.data.meta : null,
      isTableLoading,
      isSaveButtonLoading,
      searchValue,
      paymentValue,
    },
    commands: {
      setCurrentPage,
      handleUpdateClick,
      setSearchValue,
      handleChangeSelectValue,
    },
  }
}
