import React from 'react';
import {FormikProps} from 'formik/dist/types'
import {useNavigate} from 'react-router-dom';
import {PasswordInput} from "../../../../shared/components/input/password-input";
import {IFormikProps} from "../../../../app/modules/auth/components/ResetPassword";
import {Button} from "../../../../shared/components/button/button";
import {AUTH_ROUTES, LANDING_ROUTES} from '../../../../shared/config/routes';
import {ButtonLink} from "../../../../shared/components/button/button-link";
import {useIntl} from "react-intl";

interface IResetPasswordPage {
    formik: FormikProps<IFormikProps>;
    isLoading: boolean;
}

export const ResetPasswordFormInner: React.FC<IResetPasswordPage> = ({formik, isLoading}) => {
    const intl = useIntl()
    return (
        <>
            <div className='px-5'>
                <div className='fv-row mb-3 mx-auto'>
                    <PasswordInput
                        placeholder={intl.formatMessage({id: 'EXL.AUTH.RESET_PASS.INPUT_PASS'})}
                        {...formik.getFieldProps('password')}
                        autoComplete='off'
                        error={formik.touched.password && !!formik.errors.password}
                        errorText={formik.errors.password}
                        disabled={isLoading}
                    />
                </div>

                <div className='fv-row mb-6 mx-auto'>
                    <PasswordInput
                        placeholder={intl.formatMessage({id: 'EXL.AUTH.RESET_PASS.INPUT_CONFIRM_PASS'})}
                        {...formik.getFieldProps('password_confirmation')}
                        autoComplete='off'
                        error={formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                        errorText={formik.errors.password_confirmation}
                        disabled={isLoading}
                    />
                </div>

                <div className='d-grid  mx-auto'>
                    <Button
                        label={intl.formatMessage({id: 'EXL.AUTH.RESET_PASS.BUTTON'})}
                        mainButton
                        type='submit'
                        disabled={!formik.isValid || isLoading}
                        isLoading={isLoading}
                    />
                </div>
            </div>
            <div className='mt-20  d-flex justify-content-center'>
                <ButtonLink to={LANDING_ROUTES.ROOT.path}>
                    {intl.formatMessage({id: 'EXL.AUTH.BACK_TO_SITE'})}
                </ButtonLink>
            </div>
        </>
    );
};

