import React, {useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {OptionsApi} from 'app/api/options-api/options-api'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {IBrand} from 'app/api/landing-api/landing-api'

export const DeleteBrandModal = () => {
  const [brand, setBrand] = useState<IBrand>(null)
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.delete_brand, {
    onBeforeOpen: (brand: IBrand) => {
      setBrand(brand)
    },
  })

  const handleClose = () => {
    setBrand(null)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const deleteBrandMutation = useMutation<
    IBrand,
    AxiosError<{message: string}>,
    IBrand
  >(OptionsApi.deleteBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.brands])
      setBrand(null)
      toast.success('Brand deleted successfully!')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })

  const handleDeleteClick = () => {
    deleteBrandMutation.mutate(brand)
  }

  useEffect(() => {
    handleClose()
    deleteBrandMutation.reset()
  }, [deleteBrandMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!deleteBrandMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Delete Brand</h2>
        <p className='text-exl-primary my-10 text-center'>
          Are you sure for delete this brand?
        </p>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={deleteBrandMutation.isLoading}
          />
          <Button
            label='OK'
            mainButton
            isLoading={deleteBrandMutation.isLoading}
            disabled={deleteBrandMutation.isLoading}
            onClick={handleDeleteClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
