import React from 'react'

export const CustomToastForAddProductToDiscount = (data: string) => {  
  const matchLink = data.match(/https?:\/\/\S+/gi)?data.match(/https?:\/\/\S+/gi)[0]:null
  const hasUrl = matchLink ? data.includes(matchLink) : false
  const errorString = hasUrl
    ? data.replace(matchLink, '').replace('ID', '')
    : data

  return (
    <div>
      <p className='m-0 p-0'>{`${errorString}`}</p>
      {hasUrl && (
        <a href={matchLink} target='_blank' rel='noreferrer'>
          Click here to view.
        </a>
      )}
    </div>
  )
}
