import {IProductSuppliersList, SuppliersApi } from 'app/api/suppliers-api/suppliers-api'
import { AxiosError } from 'axios'
import React, {useState} from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import { SUPPLIERS_TRACKER_QUERY_KEYS } from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'

export const DeleteSupplierFromProductModal = () => {
    const [productId, setProductId] = useState<number>()
    const [supplierId, setSupplierId] = useState<number>()
  const modalState = useStateModalManager(
    SUPPLIERS_MODAL_NAME.delete_product_supplier,
    {
      onBeforeOpen: (productId: number, supplierId: number) => {
        setProductId(productId)
        setSupplierId(supplierId)
      },
    }
  )
    
    const queryClient = useQueryClient()
    
    const deleteSupplierMutation = useMutation(
      async ({
        productId,
        supplierId,
      }: {
        productId: number
        supplierId: number
      }) => {
        await SuppliersApi.deleteSupplierFromProduct(productId, supplierId)
      },
      {
        onSuccess: (data, variables) => {
          queryClient.setQueryData(
            [SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers, productId],
            (prevData: IProductSuppliersList) => {
              const tempProducts = prevData?.data.filter(
                (supplier) => supplier.supplier_id !== variables.supplierId
              )
              return {...prevData, data: tempProducts}
            }
          )
          queryClient.invalidateQueries([
            SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers,
            productId,
          ])
          toast.success(`Supplier was deleted successfully!`)
          handleClose()
        },
        onError: (error: AxiosError<{error: string}>) => {
          toast.error(error?.response.data.error)
        },
      }
    )

    const handleDeleteSupplierFromProduct = (
      productId: number,
      supplierId: number
    ) => {
      deleteSupplierMutation.mutate({productId, supplierId})
    }

    const isLoading = deleteSupplierMutation.isLoading

  const handleClose = () => {
    modalState.close()
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!isLoading}>
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to delete this supplier?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' disabled={isLoading} onClick={handleClose} />
          <Button
            label='Delete'
            mode='remove'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() =>
              handleDeleteSupplierFromProduct(productId, supplierId)
            }
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
