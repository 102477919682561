import React, {useEffect, useState} from 'react';
import {CardStatistic} from "../card-statistic/card-statistic";
import {IStatisticFilters} from "shared/types/api-types/statistic-type";
import {useQuery} from "react-query";
import {STATISTICS_QUERY_KEYS} from "shared/constants/query-keys";
import {StatisticsApi} from "app/api/dashboard-api/statistics-api";
import {INIT_PARAMS} from "./top-selling";
import {STATISTIC_MODAL_NAME} from "shared/constants/modal-names";
import {useModalManager} from "shared/context/modal-manager";
import {EventBus, STATISTIC_FILERS_EVENTS} from "shared/utils/event-bus";
import {DownloadService} from "../../../../../shared/services/download-service";
import {toast} from "react-toastify";
import { thousandFormatter } from 'shared/helpers/formatter';

export const TopClients = () => {
    const [params, setParams] = useState<IStatisticFilters>(INIT_PARAMS)
    const [isCSVLoading, setIsCSVLoading] = useState(false);
    const modalManager = useModalManager();

    const clientsQuery = useQuery({
      queryKey: [STATISTICS_QUERY_KEYS.top_clients, params],
      queryFn: () => StatisticsApi.getTopClients(params),
      onError: () => {
        toast.error('Something went wrong with Top Clients table')
      },
    })
    const clientsList = clientsQuery.data?.data ?? []

    const onOpenFilters = () => {
        modalManager.open(STATISTIC_MODAL_NAME.filter_statistics, {
            key: STATISTIC_FILERS_EVENTS.top_clients,
            params,
        });
    };
    const onPageChange = (page) => {
        setParams(prevState => {
            return {...prevState, page}
        })
    }
    useEffect(() => {
        EventBus.on(STATISTIC_FILERS_EVENTS.top_clients, (data) => setParams({...data, page: 1}))
        return () => {
            EventBus.off(STATISTIC_FILERS_EVENTS.top_clients, (data) => setParams({...data, page: 1}))
        };
    }, []);

    const onDownloadCSV = async () => {
        try {
            setIsCSVLoading(true)
            const response = await StatisticsApi.getTopClients({...params, csv: 1})
            await DownloadService.downloadObjectAsZip(response, 'Top Clients CSV')
            setIsCSVLoading(false)
        } catch (error) {
            setIsCSVLoading(false)
            toast.error('Download CSV Error!')
        }
    }
    return (
        <CardStatistic title={'Top Clients'}
                       onDownload={onDownloadCSV}
                       onPageChange={onPageChange}
                       onOpenFilters={onOpenFilters}
                       isLoading={clientsQuery.isLoading || isCSVLoading}
                       meta={clientsQuery.data?.meta ?? null}>
            <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
                <thead>
                <tr className='fw-normal fs-6  border-bottom border-gray-200 bg-light'>
                    <th>Order</th>
                    <th>Count</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>

                {clientsList.length ? clientsList.map((item, index) =>
                        <tr className='border-bottom' key={item.id}>
                            <td>
                                {item.company_name || <span className='text-gray-500'>No Name</span>}
                            </td>
                            <td>{thousandFormatter(item.count)}</td>
                            <td>{item.currency?.symbol ?? ''}{thousandFormatter(item.total_amount)}</td>
                        </tr>
                    )
                    : (
                        <td colSpan={2}>Nothing was found</td>
                    )
                }
                </tbody>
            </table>
        </CardStatistic>
    );
};

