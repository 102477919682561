import React from 'react'
import {Button} from 'shared/components/button/button'
import {SupplierTable} from '../../components/table/product-suppliers-table/supplier-table'
import {useProductSuppliersPage} from './use-product-suppliers-page'
import {DeleteSupplierFromProductModal} from '../../components/modals/delete-product-supplier-modal'
import {AddSupplierModal} from '../../components/modals/add-supplier-modal'
import {Loader} from 'shared/components/loader/loader'

export const ProductSupplierPage = () => {
  const {models, commands} = useProductSuppliersPage()
  if (models.isLoading) {
    return <Loader />
  }
  return (
    <>
      <div className='d-flex align-items-center justify-content-between gap-5 mb-5'>
        <div className='d-flex align-items-center gap-5'>
          <Button
            label='<'
            type='button'
            onClick={commands.handleGoBackClick}
            className='min-w-25px'
          />
          <h1 className='text-exl-primary bold m-0'>{models.productName}</h1>
        </div>
        <div className='d-flex align-items-center gap-5'>
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
          <Button
            label='Recount'
            type='button'
            mainButton
            isLoading={models.isRecountLoading}
            disabled={models.isRecountLoading}
            onClick={commands.handleRecountClick}
          />
        </div>
      </div>
      <div className='card p-7 position-relative'>
        <h4 className='text-end text-muted m-0'>Rates update automatically at 1:00 PM daily.</h4>  
        {models.isRecountLoading && <Loader mode='blur'/>}
        <SupplierTable
          suppliers={models.suppliers}
          currencies={models.currencies}
          allCurrencies={models.allCurrencies}
          isUpdateSupplierLoading={models.isUpdateSupplierLoading}
          updatingSupplier={models.updatingSupplier}
          handleDeleteSupplier={commands.handleDeleteSupplier}
          updateProductSupplier={commands.updateProductSupplier}
        />
        <div>
          <Button
            mainButton
            label={'Add supplier'}
            onClick={commands.handleAddSupplierClick}
          />
        </div>
      </div>
      <DeleteSupplierFromProductModal />
      <AddSupplierModal />
    </>
  )
}
