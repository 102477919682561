import React from 'react';
import { ICustomer } from 'shared/types/user';
import { CustomersTableRow } from './customers-table-row';
import { LoadWrapper } from 'shared/components/load-wrapper/load-wrapper';
import clsx from 'clsx';

export interface ICustomersTable{
  customers:ICustomer[]
  isLoading:boolean
}

export const CustomersTable:React.FC<ICustomersTable> = ({customers,isLoading}) => {
  
    return (
      <div className={clsx("card-body px-0 table-responsive",!isLoading&&'py-3')}>
        <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
              <tr className="fw-normal fs-6 border-bottom border-gray-200 bg-light ">
                <th>Created Date</th>
                <th>Company Name</th>
                <th>VAT Number</th>               
                <th>Country</th>
                <th>E-mail</th>
                <th>Phone</th>
                <th>Status</th>
                <th>Action</th>
          </tr>
          </thead>
            {(customers && customers.length) ? 
                <tbody>
                  {customers.map((customer:ICustomer)=>
                    <CustomersTableRow key={customer.user_id} customer={customer}/>                
                  )}
                </tbody> : 
                <tbody>
                  <tr className="border-bottom text-center text-muted">
                    <td colSpan={6}>No customers found with this filters.</td>
                  </tr>
              </tbody>
            }                                              
        </table>
        </LoadWrapper>
      </div>
    );
};


