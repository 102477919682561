import React, {useState} from 'react'
import {useStateModalManager} from 'shared/context/modal-manager'
import {CATALOG_MODAL_NAMES} from 'shared/constants/modal-names'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import { thousandFormatter } from 'shared/helpers/formatter'

export type validationMessageType = {
  purchasePrice: number
  newPrice: number
}
export const AlertCodesValidationModel = () => {
  const [validationMessage, setValidationMessage] =
    useState<validationMessageType>({purchasePrice: null, newPrice: null})
  const modalState = useStateModalManager(
    CATALOG_MODAL_NAMES.codes_group_alert_validation,
    {
      onBeforeOpen: (data: validationMessageType) => {
        setValidationMessage(data)
      },
    }
  )
  const onContinue = () => {
    modalState.close()
    modalState.resolveCallback()
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={true}>
      <div className='p-5 text-center'>
        <h5 className='text-exl-primary mb-5'>
          Are you sure you want to set the selling price lower than the purchase
          price?
        </h5>
        <p className='text-danger'>
          {validationMessage.purchasePrice === validationMessage.newPrice
            ? `Purchase price ${thousandFormatter(validationMessage.purchasePrice)} is equal to new price ${thousandFormatter(validationMessage.newPrice?.toFixed(2))}`
            : `Purchase price ${thousandFormatter(validationMessage.purchasePrice)} bigger then new price  ${thousandFormatter(validationMessage.newPrice?.toFixed(2))}`}
        </p>

        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} />
          <Button label='Continue' mode={'remove'} onClick={onContinue} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
