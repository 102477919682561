import React, {useMemo, useState} from 'react'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {useStateModalManager} from 'shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from 'shared/constants/modal-names'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  CUSTOMER_QUERY_KEYS,
  DISCOUNTS_QUERY_KEYS,
} from 'shared/constants/query-keys'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {toast} from 'react-toastify'
import {DiscountUsersTable} from '../discount-users-table'
import {Loader} from 'shared/components/loader/loader'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {Select} from 'shared/components/select/select'
import {IOption} from 'shared/components/select/select-type'
import {AxiosError} from 'axios'
import {
  DeleteUsersParamsType,
  IDiscountUser,
  UserParamsType,
} from 'shared/types/discount-types'
import {IUser} from 'shared/types/user'
import {DISCOUNT_EVENTS, EventBus} from 'shared/utils/event-bus'

export const DiscountUsersList = () => {
  const [discountId, setDiscountId] = useState(null)
  const [customerValue, setCustomerValue] = useState(null)
  const queryClient = useQueryClient()

  const modalState = useStateModalManager(DISCOUNT_MODAL_NAME.users_list, {
    onBeforeOpen: (id: string) => {
      setDiscountId(id)
    },
  })

  const userListQuery = useQuery({
    queryKey: [DISCOUNTS_QUERY_KEYS.discount_users, discountId],
    queryFn: async () => {
      if (!discountId) return []
      const response = await DiscountsApi.getDiscountUsers(discountId)
      return response
    },
    onError: (error) => {
      toast.error('Something went wrong.')
    },
  })

  const addCustomerMutation = useMutation<
    IUser[],
    AxiosError<{error: string}>,
    UserParamsType
  >(DiscountsApi.addUsersToDiscount, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        [DISCOUNTS_QUERY_KEYS.discount_users, discountId],
        data
      )
      EventBus.emit(DISCOUNT_EVENTS.user_list, data.length)
      setCustomerValue(null)
      toast.success('User successfully added ')
    },
    onError: (error) => {
      toast.error(error?.response.data.error)
    },
  })
  const usersDeleteMutation = useMutation<
    IDiscountUser[],
    AxiosError<{error: string}>,
    DeleteUsersParamsType
  >(DiscountsApi.deleteUsersFromDiscount, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        [DISCOUNTS_QUERY_KEYS.discount_users, discountId],
        data
      )
      EventBus.emit(DISCOUNT_EVENTS.user_list, data.length)
    },
    onError: (error) => {
      toast.error(error?.response.data.error)
    },
  })

  const {
    isLoading: isLoadingCustomers,
    handleInputChange: handleInputChangeCustomers,
    formationOptions: customers,
  } = useSelectController({
    key: CUSTOMER_QUERY_KEYS.customers,
    Fn: SelectsApi.getUsers,
    params: 'company_name',
    format: 'usersOptions',
  })
  const checkedList = useMemo(() => {
    if (!userListQuery.data) return []
    return userListQuery.data.filter((user) => user.checked === true)
  }, [userListQuery.data])
  const onChangeCustomer = (option: IOption) => setCustomerValue(option)
  const unCheckUsers = () => {
    if (!userListQuery.data) return
    const newData = userListQuery.data.map((item) => {
      return {...item, checked: false}
    })
    queryClient.setQueryData(
      [DISCOUNTS_QUERY_KEYS.discount_users, discountId],
      newData
    )
  }

  const handleClose = () => {
    modalState.close()
    setCustomerValue(null)
    unCheckUsers()
  }
  const onAddCustomer = () => {
    if (!customerValue) return
    addCustomerMutation.mutate({
      discount: discountId,
      customerId: customerValue.id,
    })
  }
  const handleCheckboxChangeQuery = (id: number) => {
    const newData = userListQuery.data.map((item) =>
      item.id === id ? {...item, checked: !item.checked} : item
    )
    queryClient.setQueryData(
      [DISCOUNTS_QUERY_KEYS.discount_users, discountId],
      newData
    )
  }
  const handelDeleteUsers = () => {
    if (!checkedList.length) return
    const tempUsersList: number[] = checkedList.map((user) => user.id)
    usersDeleteMutation.mutate({
      selectedUsers: tempUsersList,
      discount: discountId,
    })
  }
  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={
        !addCustomerMutation.isLoading || !usersDeleteMutation.isLoading
      }
    >
      <div className='p-5 text-center position-relative'>
        {userListQuery.isLoading && <Loader />}
        <div className='d-flex align-items-center justify-content-between'>
          <Select
            options={customers}
            isLoading={isLoadingCustomers}
            handleInputChange={handleInputChangeCustomers}
            value={customerValue ? customerValue.id : null}
            onChange={onChangeCustomer}
            placeholder='Customers'
            className='w-sm-300px  me-3 text-start'
          />
          <Button
            label='Add Customer'
            onClick={onAddCustomer}
            isLoading={addCustomerMutation.isLoading}
            disabled={!customerValue || addCustomerMutation.isLoading}
            mainButton
          />
        </div>
        <DiscountUsersTable
          isLoading={
            addCustomerMutation.isLoading || usersDeleteMutation.isLoading
          }
          users={userListQuery.data}
          onChangeCheckbox={handleCheckboxChangeQuery}
        />
        <ButtonList className='justify-content-end flex-column'>
          <Button
            label={`Delete ${
              checkedList.length ? `(${checkedList.length})` : ''
            }`}
            onClick={handelDeleteUsers}
            isLoading={usersDeleteMutation.isLoading}
            disabled={!checkedList.length || usersDeleteMutation.isLoading}
            mainButton
          />
          <Button label='Cancel' onClick={handleClose} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
