import {BaseHttpServices} from "../../../shared/services/base-http-services";
import {IRegion} from "../../../shared/types/base";

export class RegionsApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getRegions = async (): Promise<IRegion[]> => {
        const payload = await this.http.get(`/regions`);
        return payload.data.data;
    };

}

export const RegionsApi = new RegionsApiService(new BaseHttpServices());