import React from 'react';
import {Link} from "react-router-dom";
import styles from './button.module.scss'
import clsx from "clsx";

type Mode = 'secondary' | 'tertiary' | 'purple'

interface IButtonLink {
    to: string
    className?: string
    children: React.ReactNode | string
    mode?: Mode
}

export const ButtonLink: React.FC<IButtonLink> = ({to, className, children, mode}) => {
    return (
        <Link to={to} className={clsx(styles.link, className, mode && styles[`link--${mode}`])}>
            {children}
        </Link>
    );
};

