import React, {useEffect, useMemo, useState} from 'react'
import {CodesHeader} from '../../components/codes-heared'
import {CodesForm} from '../../components/codes-form'
import {ICodesUpload, IWarning} from 'shared/types/codes-upload'
import {FormikHelpers, useFormik} from 'formik'
import {codesUploadSchema} from '../../schems/codes-uploud-schema'
import {Loader} from '../../../../../shared/components/loader/loader'
import {CodesApi} from '../../../../../app/api/dashboard-api/codes-api'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {
  ARCHIVE_FORMATS,
  IMAGE_FORMATS,
} from '../../../../../shared/constants/base'
import {CustomToastForUploadCodes} from '../../components/uploaded-code-toast'
import {useLocation} from 'react-router-dom'
import {AlertValidationCodeUploadPreorderModel} from '../../components/modals/alert-validation-code-upload-model-preorder'
import {useModalManager} from '../../../../../shared/context/modal-manager'
import {CODES_MODAL_NAME} from '../../../../../shared/constants/modal-names'
import { AlertValidationCodeUploadModelForLessSelling } from '../../components/modals/alert-validation-code-upload-modal'
import { CODES_QUERY_KEYS, ERROR_CODES_QUERY_KEYS, UPLOAD_HISTORY_QUERY_KEYS } from 'shared/constants/query-keys'

interface IValidationError {
  image_codes?: string
}

type IUploadMethods = 'text' | 'upload'

const initialFormValue: ICodesUpload = {
  product_id: null,
  purchase_price: null,
  sell_price: null,
  currency_id: null,
  supplier_id: null,
  text_codes: '',
  image_codes: null,
  purchase_id: null,
}

export const CreateCodes = () => {
  const [uploadMethod, setUploadMethod] = useState<IUploadMethods | null>(null)
  const [minPreOrderPrice, setMinPreOrderPrice] = useState<number>(null)
  const [maxPurchasePrice, setMaxPurchasePrice] = useState<number>(null)
  const isActiveText = uploadMethod ? uploadMethod === 'text' : false
  const isActiveUpload = uploadMethod ? uploadMethod === 'upload' : false
  const location = useLocation()
  const modalManager = useModalManager()
  const queryClient = useQueryClient()
  const search = new URLSearchParams(location.search)
  const createCodesMutation = useMutation<
    IWarning,
    AxiosError<{message?: string; error: string}>,
    ICodesUpload
  >(CodesApi.createCodes, {
    onSuccess: (data: IWarning, variables: ICodesUpload) => {
      data.errors?.length
        ? toast.warning(CustomToastForUploadCodes(data))
        : toast.success(CustomToastForUploadCodes(data))
      formik.resetForm({
        values: {
          ...variables,
          text_codes: '',
          image_codes: null,
        },
      })
      queryClient.invalidateQueries([CODES_QUERY_KEYS.codes,UPLOAD_HISTORY_QUERY_KEYS.codes,ERROR_CODES_QUERY_KEYS.codes],{exact:false})
      createCodesMutation.reset()
      formik.setErrors({})
    },
    onError: (data) => {
      data.response.data.message && toast.error(data.response.data.message)
      data.response.data.error && toast.error(data.response.data.error)
    },
  })
  const setText = () =>
    setUploadMethod((prevState) => {
      formik.setFieldValue('image_codes', null)
      return 'text'
    })
  const setUpload = () =>
    setUploadMethod((prevState) => {
      formik.setFieldValue('text_codes', '')
      return 'upload'
    })
  const onUpload = (files: File[]) => {
    formik.setFieldValue('image_codes', files)
  }
  const onCreate = (
    values: ICodesUpload,
    actions: FormikHelpers<ICodesUpload>
  ) => {
    if (!values.image_codes && !values.text_codes)
      return actions.setStatus('Select the Upload method')
    if (minPreOrderPrice && minPreOrderPrice < formik.values.purchase_price) {
      return modalManager
        .open(CODES_MODAL_NAME.upload_codes_alert_validation_preorder, {
          minPreOrderPrice,
          purchasePrice: Number(formik.values.purchase_price),
        })
        .then(() => {
          createCodesMutation.mutate(values)
        })
        .catch((error) => {
          return
        })
    }
    if (maxPurchasePrice && maxPurchasePrice > formik.values.sell_price) {
      return modalManager
        .open(CODES_MODAL_NAME.upload_codes_alert_validation, {
          maxPurchasePrice,
          sellPrice: Number(formik.values.sell_price),
        })
        .then(() => {
          createCodesMutation.mutate(values)
        })
        .catch((error) => {
          return
        })
    }
    createCodesMutation.mutate(values)
  }
  const validate = (values: ICodesUpload) => {
    let errors: IValidationError = {}

    if (values.image_codes && values.image_codes.length > 1) {
      const images = values.image_codes.find((file) =>
        IMAGE_FORMATS.includes(file.type)
      )
      const archives = values.image_codes.filter((file) =>
        ARCHIVE_FORMATS.includes(file.type)
      )
      if (images && archives.length) {
        errors.image_codes = 'You can Upload img or ZIP file'
      }
      if (archives.length > 1) {
        errors.image_codes = 'You can Upload only one ZIP file'
      }
    }
    return errors
  }

  useEffect(() => {
    formik.setValues({
      product_id: Number(search.get('product_id')) || null,
      product_name: search.get('product_name') || null,
      purchase_price: Number(search.get('purchase_price')) || null,
      sell_price: null,
      currency_id: Number(search.get('currency_id')) || null,
      supplier_id: Number(search.get('supplier_id')) || null,
      text_codes: '',
      image_codes: null,
      purchase_id: Number(search.get('purchase_id')) || null,
    })
  }, [location.search])

  const formik = useFormik<ICodesUpload>({
    validationSchema: codesUploadSchema(isActiveText, isActiveUpload),
    onSubmit: onCreate,
    initialValues: initialFormValue,
    validate,
    enableReinitialize: true,
  })

  const codesCounter = useMemo(() => {
    const codesList = formik.values.text_codes.trim().split('\n')
    if (codesList[0] === '') {
      return 0
    } else {
      return codesList.length
    }
  }, [formik.values.text_codes])

  const imageCodesCounter = useMemo(() => {
    return formik.values.image_codes ? formik.values.image_codes.length : 0
  }, [formik.values.image_codes])

  useEffect(() => {
    if (uploadMethod) {
      formik.setStatus(null)
      if (uploadMethod === 'upload') {
        formik.setFieldValue('text_codes', '')
      }
    }
  }, [uploadMethod])

  return (
    <>
      <div>
        <CodesHeader label='Codes' />
        <div className='card mt-3 p-10 position-relative'>
          {createCodesMutation.isLoading && <Loader mode='blur' />}
          <CodesForm
            setMaxPurchasePrice={setMaxPurchasePrice}
            setMinPreOrderPrice={setMinPreOrderPrice}
            formik={formik}
            amountCounter={codesCounter}
            imagesCounter={imageCodesCounter}
            isLoading={createCodesMutation.isLoading}
            isActiveText={isActiveText}
            isActiveUpload={isActiveUpload}
            setText={setText}
            setUpload={setUpload}
            onUpload={(files: File[]) => onUpload(files)}
          />
        </div>
      </div>
      <AlertValidationCodeUploadPreorderModel />
      <AlertValidationCodeUploadModelForLessSelling />
    </>
  )
}
