import React from 'react';
import {IWalletInvoice} from "../../../../../shared/types/api-types/wallets-type";
import {INVOICE_SOURCE, INVOICE_STATUS, INVOICE_TYPE} from "../../../../../shared/constants/constants";
import {STATUS_COLOR} from "../../../invoices/components/table/invoices-table-row";
import {DateFormatter} from 'shared/utils/date-formatter';
import {RouteGuardActions, RouteGuardEntities} from "../../../../../casl/ability";
import {Can} from "../../../../../casl";
import {Button} from "../../../../../shared/components/button/button";
import { CurrencyFormatter } from 'shared/helpers/currency-formatter';

interface IWalletHistoryProps {
    invoices: IWalletInvoice[]
    onApproveInvoice?: (invoiceId: number) => void
}

const WalletHistoryComponent: React.FC<IWalletHistoryProps> = ({invoices, onApproveInvoice}) => {

    return (
        <div className=' py-3 px-0 table-responsive position-relative'>
            <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
                <thead>
                <tr className="fw-normal fs-6  border-bottom border-gray-200 bg-light">
                    <th>Transaction At</th>
                    <th>Reference</th>
                    <th>Amount</th>
                    <th>Source</th>
                    <th>Type</th>
                    <th>Status</th>
                    <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                        {onApproveInvoice && <th>Actions</th> }
                    </Can>
                </tr>
                </thead>
                <tbody>
                {invoices && invoices.length ?
                    invoices.map(invoice =>
                        <tr className="border-bottom" key={invoice.id}>
                            <td className='min-w-150px' style={{whiteSpace:'pre'}}>{DateFormatter(invoice.updated_at)}</td>
                            <td>{invoice.number}</td>
                            <td>{CurrencyFormatter(invoice.amount,invoice.currency)}</td>  
                            <td>{INVOICE_SOURCE[invoice.source]}</td>
                            <td>{INVOICE_TYPE[invoice.type]}</td>
                            <td>
                                <span className={`badge badge-light-${STATUS_COLOR[invoice.status]}`}>
                                    {INVOICE_STATUS[invoice.status]}
                                </span>
                            </td>
                            <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                                <td>
                                    {(invoice.status === 1 && onApproveInvoice) &&
                                        <Button isLoading={false}
                                                disabled={false}
                                                type='button'
                                                mode='text'
                                                label='Approve'
                                                className='p-0 min-w-auto'
                                                onClick={() => onApproveInvoice(invoice.id)}
                                        />
                                    }

                                </td>
                            </Can>
                        </tr>
                    )
                    : <tr className="border-bottom text-center text-muted">
                        <td colSpan={8}>Nothing was found</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    );
};

export const WalletHistory = React.memo(WalletHistoryComponent);
