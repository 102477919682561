import React from 'react'
import {AccountContext} from '../../shared/context/app-accout-context'
import {useQuery} from 'react-query'
import {CustomerApi} from '../api/user-api/customer-api'
import {selectAuthUserData} from '../store/auth/selects'
import {Loader} from '../../shared/components/loader/loader'
import {CUSTOMER_QUERY_KEYS} from '../../shared/constants/query-keys'
import {useParams} from 'react-router-dom'
import {UserApi} from 'app/api/user-api/user-api'
interface AccountLayoutProps {
  children: React.ReactNode
}

export const AccountLayout: React.FC<AccountLayoutProps> = ({children}) => {
  const {customerId} = useParams()
  const userId = selectAuthUserData()
  
  const {isLoading, data:customer} = useQuery({
    queryKey: [
      CUSTOMER_QUERY_KEYS.customer,
      Number.parseInt(customerId) || userId,
    ],
    queryFn: async () => {
      const customerData = await CustomerApi.getCustomerById(
        Number.parseInt(customerId) || userId
      )
      const userData = await UserApi.getUserById(customerId || userId)
      return {
        ...customerData,
        email: userData.email,
        is_blocked: userData.is_blocked,
        is_api_blocked: userData.is_api_blocked,
        is_invoice_email_enabled: userData.is_invoice_email_enabled,
        is_order_email_enabled: userData.is_order_email_enabled,
        google2fa_enabled: userData.google2fa_enabled
      }
    },
    refetchOnWindowFocus: false,
  })  
  
  if (isLoading || !customer) {    
    return <Loader />
  }    

  return (
    <AccountContext.Provider value={{customer}}>      
      {children}
    </AccountContext.Provider>
  )
}
