import React from 'react';
import {Button} from "shared/components/button/button";
import {ProductForm} from "../../components/product-form/product-form";
import {useCreateProduct} from "./use-create-product";
import {Loader} from "../../../../../shared/components/loader/loader";

export const CreateProductPage = () => {
    const {models, commands} = useCreateProduct()
    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <Button label='<'
                            type="button"
                            className='min-w-25px '
                            onClick={commands.navigateToProductPage}
                            disabled={models.isLoading}
                            isLoading={models.isLoading}/>
                    <h1 className="text-exl-primary bold m-0 px-3">{'Product'}</h1>
                </div>
                <Button label={'Save'}
                        isLoading={models.isLoading}
                        disabled={models.isLoading}
                        mainButton
                        onClick={commands.onSave}/>
            </div>
            <div className='card p-7 position-relative'>
                {models.isLoading && <Loader mode='blur'/>}
                    <ProductForm
                        onRemoveImage={commands.onRemoveImage}
                        errors={models.touched.name ? models.errors : null}
                        onUpdateFile={commands.onUpdateFile}
                        value={models.data}
                        isEdit={true}
                        setValue={commands.handelSetFormikField}
                        onEditImage={commands.onEditImage}
                    />
            </div>
        </>
    );
};

