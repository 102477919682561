import React, {ChangeEvent, useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {OptionsApi} from 'app/api/options-api/options-api'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {ICurrency} from 'shared/types/codes-upload'

const INITIAL_VALUES = {
  name: '',
  iso: '',
  symbol: '',
}

export const CreateCurrencyModal = () => {
  const [currency, setCurrency] = useState<ICurrency>(INITIAL_VALUES)
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.create_currency)

  const handleClose = () => {
    setCurrency(INITIAL_VALUES)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const createCurrencyMutation = useMutation<ICurrency, AxiosError<{message: string}>, ICurrency>(
    OptionsApi.createCurrency,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.currencies])
        setCurrency(INITIAL_VALUES)
        toast.success('Currency created successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleChangeInputValue = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setCurrency((prevData: ICurrency) => {
      return {
        ...prevData,
        [name]: event.target.value,
      }
    })
  }

  const handleCreateClick = () => {
    createCurrencyMutation.mutate(currency)
  }

  const isDisabled = createCurrencyMutation.isLoading || !currency.name || !currency.iso || !currency.symbol

  useEffect(() => {
    handleClose()
    createCurrencyMutation.reset()
  }, [createCurrencyMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!createCurrencyMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center'>Create Currency</h2>
        <div className='d-flex justify-content-center align-items-end gap-5 my-10'>
          <div>
            <label>Name</label>
            <Input
              value={currency.name}
              type='text'
              placeholder='Name'
              onChange={handleChangeInputValue('name')}
            />
          </div>
          <div>
            <label>ISO</label>
            <Input
              value={currency.iso}
              type='text'
              placeholder='ISO'
              onChange={handleChangeInputValue('iso')}
            />
          </div>
          <div>
            <label>Symbol</label>
            <Input
              value={currency.symbol}
              type='text'
              placeholder='Symbol'
              onChange={handleChangeInputValue('symbol')}
            />
          </div>
        </div>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} disabled={createCurrencyMutation.isLoading} />
          <Button
            label='OK'
            mainButton
            isLoading={createCurrencyMutation.isLoading}
            disabled={isDisabled}
            onClick={handleCreateClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
