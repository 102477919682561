import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import styles from './ForgotPassword.module.scss'
import {ResetPasswordFormInner} from '../../../../modules/auth/pages/forgot-password/reset-password-inner'
import {useLocation} from 'react-router-dom'
import {useAuth} from "modules/auth/hooks/use-auth";
import {AUTH_ROUTES} from "../../../../shared/config/routes";
import {ButtonLink} from "../../../../shared/components/button/button-link";
import {useIntl} from "react-intl";

const initialValues = {
    password: '',
    password_confirmation: '',
}

export interface IFormikProps {
    password: string;
    password_confirmation: string;
}

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 6 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    password_confirmation: Yup.string()
        .min(3, 'Minimum 6 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
})

export function ResetPassword() {
    const {resetPasswordMutation} = useAuth()
    const location = useLocation();
    const locationState = (location.state as { email: string, code: string });
    const intl = useIntl()
    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit: (values) => {
            resetPasswordMutation.mutate({...values, code: locationState.code})
        },
    })

    return (
        <form
            className='form mx-auto w-sm-300px h-100'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center'>
                <h1 className={clsx('fw-bolder mt-5 mb-6', styles.header, styles.textPrimaryDark)}>
                    {intl.formatMessage({id: 'EXL.AUTH.RESET_PASS.TITLE'})}
                </h1>
            </div>

            {formik.status || resetPasswordMutation.error && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {resetPasswordMutation.error?.response?.data?.message}
                    </div>
                </div>
            )}
            {resetPasswordMutation.isSuccess ?
                <>
                    <div className='mb-lg-15 alert alert-success '>
                        <div className='text-center  font-weight-bold'>
                            {intl.formatMessage({id: 'EXL.AUTH.RESET_PASS.SUCCESS'})}
                        </div>
                    </div>
                    <div className='mt-10  d-flex justify-content-center'>
                        <ButtonLink to={AUTH_ROUTES.ROOT.path} mode="secondary">
                            {intl.formatMessage({id: 'EXL.AUTH.RESET_PASS.BTN_BACK_TO_AUTH'})}
                        </ButtonLink>
                    </div>
                </>

                :
                <ResetPasswordFormInner formik={formik} isLoading={resetPasswordMutation.isLoading}/>

            }
        </form>
    )
}
