import {IAllSupplier} from 'app/api/suppliers-api/suppliers-api'
import React from 'react'
import {ActionsButton} from 'shared/components/button/actions-button'

interface ISuppliersTableRow {
  supplier: IAllSupplier
  deleteSupplier: (supplierId: number) => void
  viewSingleSupplier: (supplierId: string) => void
}

export const SuppliersTableRow: React.FC<ISuppliersTableRow> = ({
  supplier,
  deleteSupplier,
  viewSingleSupplier,
}) => {
  const SUPPLIERS_ACTION_BUTTONS = [
    {
      label: 'Delete',
      onClick: () => deleteSupplier(supplier.id),
    },
    {
      label: 'View',
      onClick: () => viewSingleSupplier(supplier.id.toString()),
    },
  ]
  return (
    <tr className='border-bottom'>
      <td>{supplier.name}</td>
      <td>
        {supplier.phone_number ? (
          <a href={`tel:+${supplier.phone_number}`}>+{supplier.phone_number}</a>
        ) : (
          '--'
        )}
      </td>
      <td>
        {supplier.main_email ? (
          <a href={`mailto:${supplier.main_email}`}>{supplier.main_email}</a>
        ) : (
          '--'
        )}
      </td>
      <td>
        {supplier.website ? (
          <a
            href={`https://${supplier.website}`}
            target='_blank'
            rel='noreferrer'
          >
            {supplier.website}
          </a>
        ) : (
          '--'
        )}
      </td>
      <td>{supplier.VAT_number ? supplier.VAT_number : '--'}</td>
      <td>
        {supplier.company_registration_number
          ? supplier.company_registration_number
          : '--'}
      </td>
      <td>
        <ActionsButton label='Actions' buttons={SUPPLIERS_ACTION_BUTTONS} />
      </td>
    </tr>
  )
}
