import React from 'react'
import {ISingleProduct} from 'shared/types/products'
import {useFormik} from 'formik'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {useMutation} from 'react-query'
import {
  CatalogApi,
  IUpdateProductPayload,
} from 'app/api/dashboard-api/catalog-api'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {PRODUCT_ROUTES} from 'shared/config/routes'
import {SingleProductType} from '../single-product-page/single-roduct-types'
import {
  initialCreateProductValues,
  validateProduct,
} from '../../constants/product-init'

export const useCreateProduct = () => {
  const navigate = useNavigate()

  const createProductMutation = useMutation<
    ISingleProduct,
    AxiosError<{errors: string}>,
    IUpdateProductPayload
  >(CatalogApi.createProduct, {
    onSuccess: (data) => {
      toast.success('Create successfully !')
      navigate(PRODUCT_ROUTES.PRODUCT.path)
    },
    onError: (error) => {
      const ErrorMessages = Object.entries(error?.response.data.errors)
      toast.error(ErrorMessages[0][1][0])
    },
  })
  const onSubmit = (values: SingleProductType) => {
    const categoriesListId =
      values.categories.map((category) => category.id) || []
    const payload = {
      name: values.name,
      image: values.editImage || null,
      description: values.description || '',
      general_sell_price: values.general_price || '',
      is_hidden: values.is_hidden ? 1 : 0,
      region_id: values.region ? values.region.id : null,
      is_preorder_unlimited: values.is_preorder_unlimited ? 1 : 0,
      ['categories[]']: categoriesListId.length ? categoriesListId : null,
      preorder_quantity_available: values.preorder_avaibable
        ? values.preorder_quantity || 0
        : 0,
      currency_id: values.currency ? values.currency.id : null,
      face_price: Number(values.face_price) || null,
    }
    const tempPayload = removeEmptyFields(payload)
    createProductMutation.mutate(tempPayload)
  }
  const formik = useFormik<SingleProductType>({
    initialValues: initialCreateProductValues,
    onSubmit,
    validate: validateProduct,
  })

  const handelSetFormikField = <T>(name: string, value: T) => {
    formik.setFieldValue(name, value || null)
  }
  const onEditImage = (blob: string | null) => {
    formik.setFieldValue('image', blob ? {original_url: blob} : null)
  }
  const onUpdateFile = (file: File) => {
    if (file) {
      formik.setFieldValue('editImage', file)
    }
  }
  const onRemoveImage = () => {
    formik.setFieldValue('editImage', null)
  }
  const navigateToProductPage = () => {
    navigate(PRODUCT_ROUTES.PRODUCT.path)
  }
  return {
    models: {
      isLoading: createProductMutation.isLoading,
      data: formik.values,
      errors: formik.errors,
      touched: formik.touched,
    },
    commands: {
      handelSetFormikField,
      onEditImage,
      onUpdateFile,
      onSave: formik.submitForm,
      navigateToProductPage,
      onRemoveImage,
    },
  }
}
