import React, {forwardRef, InputHTMLAttributes} from 'react';
import clsx from 'clsx';
import mergeRefs from 'shared/utils/merge-refs';
import styles from './textarea.module.scss'

interface TextareaProps {
    className?: string;
    name?: InputHTMLAttributes<HTMLTextAreaElement>['name'];
    label?: string;
    value?: InputHTMLAttributes<HTMLTextAreaElement>['value'];
    disabled?: InputHTMLAttributes<HTMLTextAreaElement>['disabled'];
    readonly?: InputHTMLAttributes<HTMLTextAreaElement>['readOnly'];
    resize?: 'vertical' | 'horizontal' | 'auto' | 'none';
    error?: boolean;
    errorText?:string;
    helpText?: string;
    fulfilled?: boolean;
    placeholder?: InputHTMLAttributes<HTMLTextAreaElement>['placeholder'];
    onChange?: InputHTMLAttributes<HTMLTextAreaElement>['onChange'];
    onFocus?: InputHTMLAttributes<HTMLTextAreaElement>['onFocus'];
    onBlur?: InputHTMLAttributes<HTMLTextAreaElement>['onBlur'];
    rows?: number
    mode?: 'edit' | 'light'
}

const TextareaComponent: React.ForwardRefRenderFunction<
    HTMLTextAreaElement,
    TextareaProps
> = (
    {
        className,
        name,
        label,
        value,
        placeholder,
        resize = 'none',
        error,
        errorText,
        helpText,
        fulfilled,
        disabled,
        readonly,
        onChange,
        onFocus,
        onBlur,
        rows,
        mode,
    },
    ref,
) => {
    const localRef = React.useRef<HTMLTextAreaElement>();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChange && onChange(e);
    };

    return (
        <div className={clsx(className)}>
            <textarea
                ref={mergeRefs(localRef, ref)}
                className={clsx(styles.textarea, {
                    [styles['textarea--error']]: !!error,
                    'has-value': !!value || !!localRef.current?.value,
                    [styles[`textarea--${resize}`]]: !!resize,
                    [styles[`textarea--${mode}`]]: mode,
                })}
                name={name}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readonly}
                // resize={resize}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={onBlur}
                rows={rows}
            />

            {error && errorText && <p className='text-danger'>{errorText}</p>}
        </div>
    );
};

export const Textarea = forwardRef(TextareaComponent);
