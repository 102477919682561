import React, {useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {OrderApi} from 'app/api/order-api/order-api'
import {Loader} from 'shared/components/loader/loader'
import {ERROR_ROUTE, ORDERS_ROUTES} from 'shared/config/routes'
import {ORDER_QUERY_KEYS} from 'shared/constants/query-keys'
import {OrderHeader} from '../../components/order-header'
import {ProductsTable} from '../../components/table/order/products-table'
import {DownloadService} from 'shared/services/download-service'
import {toast} from 'react-toastify'
import {useUserState} from 'app/store/user/state'
import { BlobErrorResponse } from 'shared/helpers/blob-error-response'
import { IOrder } from 'shared/types/order'
export const UserOrderPage = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [isCSVLoading, setIsCSVLoading] = useState(false)
  const [isCSVsLoading, setIsCSVsLoading] = useState(false)
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false)
  const [loadingItemHash, setLoadingItemHash] = useState(null)
  const {userId, orderId} = useParams()
  const user = useUserState()
  const handleGoBackClick = () => {
    navigate(ORDERS_ROUTES.ALL_ORDERS.path)
  }
  const {data: order, isLoading} = useQuery({
    queryKey: [ORDER_QUERY_KEYS.order, orderId],
    queryFn: async () =>
      await OrderApi.getOrderByUser(Number(userId), Number(orderId)),
    onError: () => {
      navigate(ERROR_ROUTE.path)
    },
  })

  const onDownloadCSV = async (hash?: string) => {
    try {
      if (hash) {
        setIsCSVLoading(true)
        setLoadingItemHash(hash)
      } else setIsCSVsLoading(true)
      const response = hash
        ? await OrderApi.getCodesCSV(Number(userId), Number(orderId), order.is_new_to_download_exists, hash)
        : await OrderApi.getCodesCSV(Number(userId), Number(orderId), order.is_new_to_download_exists)
      DownloadService.downloadObjectAsZip(response, order.number)
      if (order.is_new_to_download_exists){
        queryClient.setQueryData(
          [ORDER_QUERY_KEYS.order, orderId],
          (prevData: IOrder) => {
            if (hash){
              const codeId = prevData.codes.findIndex(code=>code.hash===hash)
              const code = prevData.codes[codeId]
              code.is_new_to_download_exists=false
              prevData.codes.splice(codeId,1,code)
              const isNewToDownloadExists = prevData.codes.find(code=>code.is_new_to_download_exists===true)
              if (!isNewToDownloadExists)
                return {
                  ...prevData,
                  is_new_to_download_exists: false,
                }
              return prevData
            }            
            return {
              ...prevData,
              is_new_to_download_exists: false,
            }
          }
        )
        queryClient.invalidateQueries([ORDER_QUERY_KEYS.order, orderId],{exact:true})
      }
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
    } catch (error) {
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const onDownloadAllCSV = async () => {
    try {
      setIsCSVsLoading(true)
      const response = await OrderApi.getCodesCSV(Number(userId), Number(orderId),false)
      DownloadService.downloadObjectAsZip(response, order.number)
      queryClient.setQueryData(
        [ORDER_QUERY_KEYS.order, orderId],
        (prevData: IOrder) => {
          prevData.is_new_to_download_exists = false
          prevData.codes.map(code=>{
            if (code.is_new_to_download_exists) code.is_new_to_download_exists=false
            return code
          })
          return prevData
        }
      )
      queryClient.invalidateQueries([ORDER_QUERY_KEYS.order, orderId], {
        exact: true,
      })
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
    } catch (error) {
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const onGetInvoiceClick = async () => {
    try {
      setIsInvoiceLoading(true)
      const response = await OrderApi.getInvoice(Number(userId), Number(orderId))
      DownloadService.downloadObjectAsZip(response, `${order.number} invoice`)
      setIsInvoiceLoading(false)
    } catch (error) {
      setIsInvoiceLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  if (!order || isLoading) {
    return <Loader />
  }

  return (
    <>
      <OrderHeader
        userId={user.userData.id}
        isCSVLoading={isCSVsLoading}
        order={order}
        onDownloadAllCSV={onDownloadAllCSV}
        isInvoiceLoading={isInvoiceLoading}
        onDownloadCSV={onDownloadCSV}
        onGetInvoiceClick={onGetInvoiceClick}
        handleGoBackClick={handleGoBackClick}        
      />
      <div className='bg-body rounded shadow-sm p-10 mx-auto overflow-hidden'>
        <ProductsTable
          order={order}
          isLoading={isLoading}
          onDownloadCSV={onDownloadCSV}
          isCSVLoading={isCSVLoading}
          loadingItemHash={loadingItemHash}
        />
      </div>
    </>
  )
}
