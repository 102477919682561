import {BaseHttpServices} from 'shared/services/base-http-services'
import {
  ApiProductResponse,
  ISingleProduct,
} from 'shared/types/products'
import {ICurrency} from '../../../shared/types/codes-upload'

interface IProductCatalogParams {
  name?: string
  category?: number
  user_id?: number
  in_stock?: number  
}

interface IProductCatalogRefetchParams {
  params:IProductCatalogParams
  signal?:AbortSignal
}

interface ICategoryOptions {
  id: number
  name: string
}

export interface ICodesGroupItem {
  hash: string
  code_count: number
  in_stock: boolean
  sell_price: number
  purchase_price: number
  currency: ICurrency
  checked?: boolean
  discount?: string
  sell_price_for_admin?: string | number
  preorder_quantity: number
  preorder_avaibable: boolean
}

export interface IUpdateCodesGroupParams {
  productId: string
  payload: {
    code_hash: string
    sell_price?: number
    in_stock?: boolean
  }
}

export interface IUpdateProductPayload {
  name?: string
  image?: File | string
  description?: string
  general_sell_price?: number | string
  is_hidden?: number
  region_id?: number
  categories?: number[]
  preorder_quantity_available?: number
  currency_id?: number
}

export interface IUpdateProductParams {
  productId: string
  payload: IUpdateProductPayload
}
export interface IDeleteCodeGroupParams {
  productId: number | string
  payload: string[]
}

export class CatalogApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getProductCatalog = async (
    {params,signal}: IProductCatalogRefetchParams | null
  ): Promise<ApiProductResponse> => {
    const payload = await this.http.get(`/products`, {
      params: params,
      signal,
    },)
    return payload.data
  }
  getCategories = async (): Promise<ICategoryOptions[]> => {
    const payload = await this.http.get(`/categories`)
    return payload.data.data
  }

  getOneProduct = async (
    id: number | string,
    userID?: number
  ): Promise<ISingleProduct> => {
    const payload = await this.http.get(`/products/${id}`, {
      params: userID
        ? {
            user_id: userID,
          }
        : null,
    })
    return payload.data.data
  }

  updateProduct = async (
    data: IUpdateProductParams
  ): Promise<ICodesGroupItem[]> => {
    const productData = new FormData()
    for (const field in data.payload) {
      productData.append(field, data.payload[field])
    }

    const payload = await this.http.post(
      `/products/${data.productId}`,
      productData
    )
    return payload.data.data
  }

  getCodesGroup = async (
    id: number | string,
    userId: number
  ): Promise<ICodesGroupItem[]> => {
    const payload = await this.http.get(`/products/${id}/codes_group`, {
      params: {
        user_id: userId,
      },
    })
    return payload.data.data
  }
  getCodesGroupForAdmin = async (
    id: number | string,
    userId: number
  ): Promise<ICodesGroupItem[]> => {
    const payload = await this.http.get(
      `/products/${id}/codes_group_without_collapse_admin_view`,
      {
        params: {
          user_id: userId,
        },
      }
    )
    return payload.data.data
  }
  updateCodesGroup = async (
    data: IUpdateCodesGroupParams
  ): Promise<ICodesGroupItem[]> => {
    const payload = await this.http.post(
      `/products/${data.productId}/update_codes_group`,
      data.payload
    )
    return payload.data.data
  }

  deleteProduct = async (id: number): Promise<{name: string}> => {
    const payload = await this.http.delete(`/products/${id}`)
    return payload.data.data
  }

  createProduct = async (
    data: IUpdateProductPayload
  ): Promise<ISingleProduct> => {
    const productData = new FormData()
    for (const field in data) {
      productData.append(field, data[field])
    }
    const payload = await this.http.post(`/products`, productData)
    return payload.data[0]
  }
  deleteCodesGroup = async (
    data: IDeleteCodeGroupParams
  ): Promise<ICodesGroupItem[]> => {
    const payload = await this.http.delete(
      `/products/${data.productId}/delete_codes_group`,
      {
        data: {
          selected_models: data.payload,
        },
      }
    )
    return payload.data.data
  }

  getest = async (id: number | string): Promise<any> => {
    const payload = await this.http.get(`/users/${id}/get-2fa-qr`)
    return payload.data
  }
}

export const CatalogApi = new CatalogApiService(new BaseHttpServices())
