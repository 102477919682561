import React, {ChangeEvent, useEffect, useState} from 'react'
import clsx from 'clsx'
import styles from './codes-item.module.scss'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {ICodesGroupItem} from 'app/api/dashboard-api/catalog-api'
import CheckBox from '../../../../../shared/components/check-box/check-box'
import {Toggle} from '../../../../../shared/components/toggle/toggle'
import {useDebounce} from '../../../../../_metronic/helpers'
import {
  _ProductStatusController,
  StatusProductItemType,
  STOCK_AMOUNT_FLAG,
} from '../product-item/status-config'
import {toast} from 'react-toastify'
import {ChangeCodesValueArgType} from '../../pages/single-product-page/single-roduct-types'
import {CATALOG_MODAL_NAMES} from '../../../../../shared/constants/modal-names'
import {useModalManager} from '../../../../../shared/context/modal-manager'
import { IRegion } from 'shared/types/base'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

interface ICodesItemProps extends ICodesGroupItem {
  isEdit: boolean
  onCheckCode: (hash: string) => void
  handleChangeCodesValue: (codesArgs: ChangeCodesValueArgType) => void
  addToCart: (quantity: number, product_hash: string) => void
  preOrderToCart: (quantity: number, product_hash: string) => void
  isHidden?: boolean
  isUnlimited?: boolean
  face_price?: string
  productStock: boolean
  region: IRegion
}
const IS_INVALID_AMOUT_INIT_VALUE = {
  isInvalid: false,
  isDisable: false,
}
const DEBOUNCE_AMOUNT_DALEY = 1000
const CodesItemComponent: React.FC<ICodesItemProps> = (props) => {
  const {
    hash,
    code_count,
    sell_price_for_admin,
    in_stock,
    sell_price,
    purchase_price,
    region,
    currency,
    isEdit,
    checked,
    onCheckCode,
    handleChangeCodesValue,
    addToCart,
    preOrderToCart,
    isHidden,
    discount,
    isUnlimited,
    face_price,
    productStock,
    preorder_avaibable,
    preorder_quantity,
  } = props
  const [amount, setAmount] = useState<number>(isHidden ? 0 : 1)
  const [price, setPrice] = useState(sell_price_for_admin)
  const [isInvalidAmount, setIsInvalidAmount] = useState(
    IS_INVALID_AMOUT_INIT_VALUE
  )
  const modalManager = useModalManager()
  const stockAmountCodes = productStock ? code_count : preorder_quantity
  const ProductStatusController = new _ProductStatusController({
    isHidden: isHidden,
    isUnlimited: isUnlimited,
    quantity: code_count,
    preorderQuantity: preorder_quantity,
    preorderAvailable: preorder_avaibable,
    stock: in_stock,
  })
  const businessStatus: StatusProductItemType =
    ProductStatusController.getStatus()

  const businessStatusCodes: StatusProductItemType =
    ProductStatusController.getGroupCodesStatus()

  const setMaxAmount = () => {
    setIsInvalidAmount(IS_INVALID_AMOUT_INIT_VALUE)
    toast.dismiss()
    if (productStock) {
      setAmount(code_count)
    } else {
      setAmount(preorder_quantity)
    }
  }
  const handelChangeSellPrice = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPrice(
      event.target.value === '' ? '' : decimalFormatter(event.target.value)
    )
  }
  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    const formattedValue = newValue === '' ? '' : decimalFormatter(newValue)
    setAmount(formattedValue)
    const amountCondition = businessStatus.getAmountCondition(
      formattedValue,
      preorder_quantity,
      code_count
    )
    if (amountCondition.isValid === null) return
    if (!amountCondition.isValid) {
      setIsInvalidAmount((prevState) => ({...prevState, isDisable: true}))
    } else {
      setIsInvalidAmount(IS_INVALID_AMOUT_INIT_VALUE)
    }
  }

  const handelCheck = () => onCheckCode(hash)
  const debounceSekPrice = useDebounce(Number(price), 1000)

  useEffect(() => {
    if (debounceSekPrice && debounceSekPrice !== Number(sell_price_for_admin)) {
      if (purchase_price >= debounceSekPrice) {
        modalManager
          .open(CATALOG_MODAL_NAMES.codes_group_alert_validation, {
            purchasePrice: purchase_price,
            newPrice: debounceSekPrice,
          })
          .then(() => {
            handleChangeCodesValue({
              hash,
              name: 'sell_price',
              value: Number(debounceSekPrice),
              purchasePrice: Number(purchase_price),
            })
          })
          .catch((error) => {
            setPrice(Number(sell_price_for_admin))
          })
      } else {
        handleChangeCodesValue({
          hash,
          name: 'sell_price',
          value: Number(debounceSekPrice),
          purchasePrice: Number(purchase_price),
        })
      }
    }
  }, [debounceSekPrice])

  const handelAddToCart = () => {
    !productStock && preorder_avaibable
      ? preOrderToCart(Number(amount), hash)
      : addToCart(Number(amount), hash)
  }
  const onToggleStock = () =>
    handleChangeCodesValue({hash, name: 'in_stock', value: !in_stock})

  const debouncedAmountTerm = useDebounce(amount, DEBOUNCE_AMOUNT_DALEY)
  useEffect(() => {
    const amountCondition = businessStatus.getAmountCondition(
      Number(debouncedAmountTerm),
      preorder_quantity,
      code_count
    )
    if (amountCondition.isValid === null) return
    if (!amountCondition.isValid) {
      setIsInvalidAmount((prevState) => ({...prevState, isInvalid: true}))
      toast.dismiss()
      toast.error(amountCondition.errorMessage, {
        autoClose: 3000,
      })
    } else {
      setIsInvalidAmount(IS_INVALID_AMOUT_INIT_VALUE)
      toast.dismiss()
    }
  }, [debouncedAmountTerm])

  return (
    <div
      className={clsx(
        styles.card,
        'flex-column flex-xl-row  justify-content-between'
      )}
    >
      <div
        className={clsx('d-flex col-12 col-xl-6 align-items-center flex-wrap', {
          'col-xl-10': isEdit,
        })}
      >
        {isEdit && (
          <div className='col-6 col-xl-2  w-sm-30px'>
            <CheckBox checked={checked} onChange={handelCheck} />
          </div>
        )}
        <div className={clsx(styles.params, 'col-6 col-xl')}>
          <p className={styles.params__name}>Region</p>
          <p className={styles.params__value}>{region ? region.name : '---'}</p>
        </div>

        {isEdit ? (
          <>
            <div className={clsx(styles.params, 'col-6 col-xl')}>
              <p className={styles.params__name}>Purchase price</p>
              <p className={styles.params__value}>{`${currency.symbol}${
                purchase_price || '--'
              }`}</p>
            </div>
            <div className={clsx(styles.params, 'col-6 col-xl')}>
              <p className={styles.params__name}>Sell Price</p>
              <Input
                type='number'
                mode='number'
                isPriceInput
                currency={currency.symbol}
                value={price}
                onChange={handelChangeSellPrice}
              />
            </div>
            <div className={clsx(styles.params, 'col-6 col-xl')}>
              <p className={styles.params__name}>Discount</p>
              <p className={styles.params__value}>{discount}%</p>
            </div>
            <div className={clsx(styles.params, 'col-6 col-xl')}>
              <p className={styles.params__name}>Price</p>
              <p className={styles.params__value}>
                {`${currency.symbol}${sell_price}`}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className={clsx(styles.params, 'col-6 col-xl')}>
              <p className={styles.params__name}>Price</p>
              <p className={styles.params__value}>
                {`${currency.symbol}${sell_price || 0}`}
              </p>
            </div>
            <div className={clsx(styles.params, 'col-6 col-xl')}>
              <p className={styles.params__name}>Discount</p>
              <p className={styles.params__value}>{discount}%</p>
            </div>
          </>
        )}
        {isEdit ? (
          <div className={clsx(styles.params, 'col-6 col-xl')}>
            <p className={styles.params__name}>{businessStatusCodes.name}</p>
            <p className={styles.params__value}>
              {businessStatusCodes.amount === STOCK_AMOUNT_FLAG
                ? stockAmountCodes
                : businessStatusCodes.amount}
            </p>
          </div>
        ) : (
          <div className={clsx(styles.params, 'col-6 col-xl')}>
            <p className={styles.params__name}>{businessStatus.name}</p>
            <p className={styles.params__value}>
              {businessStatus.amount === STOCK_AMOUNT_FLAG
                ? stockAmountCodes
                : businessStatus.amount}
            </p>
          </div>
        )}
      </div>
      {isEdit ? (
        <div className={'col col-xl-2 d-flex align-items-center'}>
          <div className='d-flex align-items-center'>
            <p className='mx-3 mb-0'>
              {in_stock ? 'In stock' : 'Out of stock'}
            </p>
            <div className='w-sm-60px'>
              <Toggle isActive={in_stock} setActive={onToggleStock} />
            </div>
          </div>
        </div>
      ) : (
        <div className='col-12 col-xl-6 d-flex align-items-center  justify-content-xl-end'>
          <Button
            label={'Max'}
            mode='small'
            onClick={setMaxAmount}
            disabled={businessStatus.is_disabled_max_button}
          />
          <Input
            type='number'
            mode='number'
            min={0}
            step={1}
            integerLimit={4}
            className='w-sm-100px mx-2'
            onChange={handleNumberChange}
            error={isInvalidAmount.isInvalid}
            value={amount}
            disabled={businessStatus.is_disabled_input}
          />
          <Button
            label={businessStatus.btn_name}
            disabled={
              isInvalidAmount.isDisable ||
              businessStatus.is_disabled_action_button
            }
            onClick={handelAddToCart}
            mainButton
          />
        </div>
      )}
    </div>
  )
}

export const CodesItem = React.memo(CodesItemComponent)
