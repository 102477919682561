import {RouteObject} from "react-router-dom";
import {OrdersPagesWrapper} from "../../modules/dashboard/orders/orders-pages-wrapper";
import {DASHBOARD_ROUTES, ORDERS_ROUTES} from "../../shared/config/routes";
import {RoleGuard} from "../guards/role-guard";
import {RouteGuardActions, RouteGuardEntities} from "../../casl/ability";
import {OrdersPage} from "../../modules/dashboard/orders/pages/orders/orders-page";
import {OrderPage} from "../../modules/dashboard/orders/pages/order/order-page";
import {AllOrdersPage} from "../../modules/dashboard/all-orders/pages/orders/all-orders-page";
import {UserOrderPage} from "../../modules/dashboard/orders/pages/user-order/user-order-page";

export const ordersRoutes: RouteObject[] = [
    {
        element: <OrdersPagesWrapper/>,
        children: [
            {
                path: DASHBOARD_ROUTES.ORDERS.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
                        <OrdersPage/>
                    </RoleGuard>
                ),
            },
            {
                path: ORDERS_ROUTES.ORDER.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
                        <OrderPage/>
                    </RoleGuard>
                ),
            },
        ],
    },

    {
        element: <OrdersPagesWrapper/>,
        children: [
            {
                path: DASHBOARD_ROUTES.ALL_ORDERS.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                        <AllOrdersPage/>
                    </RoleGuard>
                ),
            },
            {
                path: ORDERS_ROUTES.ORDER.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                        <OrderPage/>
                    </RoleGuard>
                ),
            },
        ],
    },
    {
        element: <OrdersPagesWrapper/>,
        children: [
            {
                path: DASHBOARD_ROUTES.ORDERS.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
                        <OrdersPage/>
                    </RoleGuard>
                ),
            },
            {
                path: ORDERS_ROUTES.ORDER.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
                        <OrderPage/>
                    </RoleGuard>
                ),
            },
            {
                path: ORDERS_ROUTES.USER_ORDER.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                        <UserOrderPage/>
                    </RoleGuard>
                ),
            },
        ],
    },
    {
        element: <OrdersPagesWrapper/>,
        children: [
            {
                path: DASHBOARD_ROUTES.ALL_ORDERS.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
                        <AllOrdersPage/>
                    </RoleGuard>
                ),
            },
            {
                path: ORDERS_ROUTES.ORDER.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Customer}>
                        <OrderPage/>
                    </RoleGuard>
                ),
            },
            {
                path: ORDERS_ROUTES.USER_ORDER.path,
                element: (
                    <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
                        <UserOrderPage/>
                    </RoleGuard>
                ),
            },
        ],
    },
]