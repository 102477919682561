/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {CodeCheck} from './components/CodeCheck'
import {ResetPassword} from './components/ResetPassword'
import {AUTH_ROUTES} from 'shared/config/routes'
import {AuthGuard} from "../../guards/auth-guard";
import { Icon } from 'shared/components/icon/icon'
import { ICON_COLLECTION } from 'shared/components/icon/icon-list'
import { TwoFactorAuth } from './components/TwoFactorAuth'

const AuthLayout = () => {

    useEffect(() => {
        document.body.classList.add('bg-body')
        return () => {
            document.body.classList.remove('bg-body')
        }
    }, [])

    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed bg-light'
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Wrapper */}
                <div
                    className='w-xl-1000px w-lg-850px w-sm-500px min-h-75 bg-body rounded shadow-sm p-10 p-lg-20 mx-auto'>
                    <div className='w-100 d-flex justify-content-center pb-10'>
                        <Icon icon={ICON_COLLECTION.logoDark} />
                    </div>
                    <Outlet/>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
        </div>
    )
}


const AuthPage = [
    {
        path: AUTH_ROUTES.ROOT.path,
        element:
            <AuthGuard>
                <AuthLayout/>
            </AuthGuard>,
        children: [
            {path: AUTH_ROUTES.LOGIN.path, element: <Login/>},
            {path: AUTH_ROUTES.REGISTRATION.path, element: <Registration/>},
            {path: AUTH_ROUTES.FORGOT_PASSWORD.path, element: <ForgotPassword/>},
            {path: AUTH_ROUTES.CODE_CHECK.path, element: <CodeCheck/>},
            {path: AUTH_ROUTES.RESET_PASSWORD.path, element: <ResetPassword/>},
            {path: AUTH_ROUTES.ROOT.path, element: <Login />},
            {path: AUTH_ROUTES.TWO_FACTOR_AUTH.path, element: <TwoFactorAuth/>}
        ]
    },
]


export {AuthPage}
