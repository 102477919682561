import { TabMenu } from 'shared/components/tab-menu/tab-menu'
import { useAbilityContext } from 'casl'
import { useMenuList } from 'shared/hooks/use-menu-list'
import { RowContainer } from '../../components/row-container/row-container'
import { Toggle } from 'shared/components/toggle/toggle'
import { useEffect, useState } from 'react'
import { useModalManager } from 'shared/context/modal-manager'
import { ACCOUNT_MODAL_NAMES } from 'shared/constants/modal-names'
import { AddTwoFactorModal } from '../../components/modals/add-two-factor-modal'
import { useUserState } from 'app/store/user/state'
import { Loader } from 'shared/components/loader/loader'
import { useMutation } from 'react-query'
import { CustomerApi, ITwoFactorAuthData } from 'app/api/user-api/customer-api'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export const AccountTwoFactorPage = () => {
  const modalManager = useModalManager()
  const ability = useAbilityContext()
  const user = useUserState()
  const isUserEnable2Fa = user.userData.google2fa_enabled
  const { accountLinks } = useMenuList(ability)
  const [isToggleEnabled, setIsToggleEnabled] = useState(isUserEnable2Fa)

  const handleToggleClick = () => {
     setIsToggleEnabled(!isToggleEnabled) 
  }

  const disable2FaMutation = useMutation<
    ITwoFactorAuthData,
    AxiosError<{error: string}>,
    number
  >(CustomerApi.disable2Fa, {
    onSuccess: () => {
      toast.success('Two Factor Authentication is disabled')
    },
    onError: (error: AxiosError) => {
      toast.error(error.response.data.message)
    },
  })

 useEffect(() => {
   if (isToggleEnabled !== isUserEnable2Fa) {
     if (isToggleEnabled) {
       modalManager.open(ACCOUNT_MODAL_NAMES.add_two_factor)
     } else {
       disable2FaMutation.mutate(user.userData.id)
     }
   }
 }, [isToggleEnabled, isUserEnable2Fa])
  

  return (
    <>
      <div className='d-md-flex justify-content-between align-items-center mb-5'>
        <h1 className={'text-exl-primary mb-5'}>My Account</h1>
      </div>
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={accountLinks} />
        <div className='card-body mt-10'>
          <RowContainer label='Enable 2FA for account'>
            <div className='d-flex flex-column gap-2'>
              <span>Use Google Authenticator</span>
              <div className='mw-75px position-relative'>
                {disable2FaMutation.isLoading && <Loader mode='blur' />}
                <Toggle
                  isActive={isToggleEnabled}
                  setActive={handleToggleClick}
                />
              </div>
            </div>
          </RowContainer>
        </div>
      </div>
      <AddTwoFactorModal />
    </>
  )
}
