export const menuLinks = [
    {
        id: "solutions",
        title: "Our Solutions",
    },
    {
        id: "benefits",
        title: "Who Benefits",
    },
    {
        id: "businessHub",
        title: "Business Hub",
    },    
    {
        id: "discover",
        title: "Discover",
    },
    {
        id: "api",
        title: "API",
    },
    {
        id: "contact",
        title: "Contact Us",
    },
];

export const menuButtons = {
    login: "Login", 
    create: "Create account",
}