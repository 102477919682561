import {ChangeEvent, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {DISCOUNTS_QUERY_KEYS} from 'shared/constants/query-keys'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {toast} from 'react-toastify'
import {
  DiscountType,
  ICreateDiscount,
  IDiscountListResponse,
  IDiscountUpdateParams,
} from 'shared/types/discount-types'
import {AxiosError} from 'axios'
import {FormikProps, useFormik} from 'formik'
import {createDiscountSchema} from '../create-discount/create-discount-schema'
import {useModalManager} from 'shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from 'shared/constants/modal-names'
import {DISCOUNT_EVENTS, EventBus} from 'shared/utils/event-bus'
import {ERROR_ROUTE} from '../../../../../shared/config/routes'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

const initialDiscountValues = {
  name: '',
  isActive: false,
  start: null,
  finish: null,
  is_allow_to_sell_loss: false,
}

export const useSingleDiscountPage = () => {
  const location = useLocation()
  const discountId = location.pathname.split('/')[2]
  const modalManager = useModalManager()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const discountsQuery = useQuery({
    queryKey: [DISCOUNTS_QUERY_KEYS.discount, discountId],
    queryFn: async () => {
      const response = await DiscountsApi.getDiscount(discountId)
      formik.setValues({
        name: response.name,
        isActive: response.is_active,        
        start: response.start_date || null,
        finish: response.end_date || null,
        is_allow_to_sell_loss: response.is_allow_to_sell_loss,
      })
      return response
    },
    onError: (error) => {
      toast.error('Something went wrong.')
      navigate(ERROR_ROUTE.path)
    },
  })

  const updateDiscountMutation = useMutation<
    DiscountType,
    AxiosError<{message?: string; error?: string}>,
    IDiscountUpdateParams
  >(DiscountsApi.updateDiscount, {
    onSuccess: async (data) => {
      const discountListData: IDiscountListResponse = queryClient.getQueryData(
        [DISCOUNTS_QUERY_KEYS.discount_list],
        {exact: false}
      )
      if (discountListData) {
        const params = {page: discountListData.meta.current_page}
        queryClient.setQueryData(
          [DISCOUNTS_QUERY_KEYS.discount_list, params],
          (prevState: IDiscountListResponse) => {
            const discountId = prevState.data.findIndex(
              (discount) => discount.id === data.id
            )
            prevState.data.splice(discountId, 1, data)
            return prevState
          }
        )
        queryClient.invalidateQueries(
          [DISCOUNTS_QUERY_KEYS.discount_list, params],
          {exact: false}
        )
      }
      toast.success('Updated successfully!')
    },
    onError: (error: AxiosError<{message?: string; error?: string}>) => {
      error?.response.data.message && toast.error(error?.response.data.message)
      error?.response.data.error && toast.error(error?.response.data.error)
    },
  })

  const formik: FormikProps<ICreateDiscount> = useFormik({
    initialValues: initialDiscountValues,
    validationSchema: createDiscountSchema,
    onSubmit: async (values) => {
      updateDiscountMutation.mutate({
        id: discountId,
        body: values,
      })
    },
  })
  const handleToggleIsActive = () => {
    formik.setFieldValue('isActive', !formik.values.isActive)
  }
  const handleToggleIsAllowToSellLess = () => {
    modalManager
      .open(
        DISCOUNT_MODAL_NAME.alert_allow_sell_at_loss,
        formik.values.is_allow_to_sell_loss
      )
      .then(() => {
        formik.setFieldValue(
          'is_allow_to_sell_loss',
          !formik.values.is_allow_to_sell_loss
        )
      })
      .catch((error) => {
        return
      })
  }
  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('name', event.target.value)
  }
  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    formik.setFieldValue(
      'start',
      startDate ? startDate.replace(/\//g, '-') : null
    )
    formik.setFieldValue('finish', endDate ? endDate.replace(/\//g, '-') : null)
  }
  const onOpenUsersList = () => {
    modalManager.open(DISCOUNT_MODAL_NAME.users_list, discountId)
  }
  const onOpenProductRemoveWindow = (productId: number) => {
    modalManager.open(DISCOUNT_MODAL_NAME.remove_product_discount, {
      discountId,
      productId,
    })
  }

  useEffect(() => {
    EventBus.on(
      DISCOUNT_EVENTS.remove_product_discount,
      (data: DiscountType) => {
        queryClient.setQueryData(
          [DISCOUNTS_QUERY_KEYS.discount, discountId],
          data
        )
      }
    )

    EventBus.on(DISCOUNT_EVENTS.user_list, (usersCount: number) => {
      queryClient.setQueryData(
        [DISCOUNTS_QUERY_KEYS.discount, discountId],
        (prevData: DiscountType) => {
          return {
            ...prevData,
            users_count: usersCount,
          }
        }
      )
    })

    return () => {
      EventBus.off(
        DISCOUNT_EVENTS.remove_product_discount,
        (data: DiscountType) => {
          queryClient.setQueryData(
            [DISCOUNTS_QUERY_KEYS.discount, discountId],
            data
          )
        }
      )
      EventBus.off(DISCOUNT_EVENTS.user_list, (usersCount: number) => {
        queryClient.setQueryData(
          [DISCOUNTS_QUERY_KEYS.discount, discountId],
          (prevData: DiscountType) => {
            return {
              ...prevData,
              users_count: usersCount,
            }
          }
        )
      })
    }
  }, [])

  return {
    models: {
      discountId,
      discount: {
        ...formik.values,
        usersCount: discountsQuery.data?.users_count ?? 0,
      },
      products: discountsQuery.data?.products ?? [],
      isLoadingDiscount: discountsQuery.isLoading,
      isUpdating: updateDiscountMutation.isLoading || discountsQuery.isLoading,
    },
    commands: {
      handleToggleIsActive,
      handleToggleIsAllowToSellLess,
      handleDateChangeFormValue,
      handleChangeName,
      onUpdateDiscount: () => formik.submitForm(),
      onOpenUsersList,
      onOpenProductRemoveWindow,
    },
  }
}
