import React from 'react'
import clsx from 'clsx'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {CurrenciesTableRow} from './currencies-table-row'
import {ICurrency} from 'shared/types/codes-upload'

export interface ICurrenciesTable {
  currencies: ICurrency[]
  isLoading: boolean
  isSaveButtonLoading: boolean
  handleSaveChanges: (currency: ICurrency) => void
  handleDeleteClick: (currency: ICurrency) => void
}

export const CurrenciesTable: React.FC<ICurrenciesTable> = ({
  currencies,
  isLoading,
  isSaveButtonLoading,
  handleSaveChanges,
  handleDeleteClick,
}) => {
  return (
    <div className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}>
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light text-exl-primary'>
              <th className='px-7 w-25 min-w-200px'>Name</th>
              <th className='px-7 w-25 min-w-200px'>ISO</th>
              <th className='px-7 w-25 min-w-50px'>Symbol</th>
              <th className='d-flex flex-end'>Actions</th>
            </tr>
          </thead>
          {currencies && currencies.length ? (
            <tbody>
              {currencies.map((currency: ICurrency) => (
                <CurrenciesTableRow
                  key={currency.id}
                  currency={currency}
                  isSaveButtonLoading={isSaveButtonLoading}
                  handleDeleteClick={handleDeleteClick}
                  handleSaveChanges={handleSaveChanges}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={4}>No currencies.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
