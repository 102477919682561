import React, {FC, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Button} from 'shared/components/button/button'
import {MenuComponent} from '_metronic/assets/ts/components'
import {Input} from 'shared/components/input/input'
import productImg from 'shared/assets/images/product.png'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {CART_ROUTE} from 'shared/config/routes'
import {useCartPage} from 'modules/cart/hooks/use-cart-page'
import {thousandFormatter} from 'shared/helpers/formatter'
import {selectTemporaryUserId} from 'app/store/temporary-customer/selects'
import clsx from 'clsx'

export interface ICartComponent {
  isLoading: boolean
}

export const Cart: FC<ICartComponent> = ({isLoading}) => {
  const {models, commands} = useCartPage()
  const navigate = useNavigate()

  const handleClose = () => {
    const menuElement = document.querySelector('#kt_menu') as HTMLElement
    MenuComponent.hideDropdowns(menuElement)
  }
  const temporaryUserId = selectTemporaryUserId()
  const userId = temporaryUserId ? temporaryUserId : models.user.id
  const navigateToOrderPage = () => {
    navigate(CART_ROUTE.generate(`${userId}`))
    handleClose()
  }

  useEffect(() => {
    if (!models.itemsInCart) handleClose()
  }, [models.itemsInCart])

  return (
    <div
      className={clsx('menu menu-sub menu-sub-dropdown menu-column menu-rounded py-4 w-sm-550px',{'py-10':isLoading})}
      data-kt-menu='true'
    >
      <LoadWrapper isLoading={isLoading}>
        <div className='menu-item px-3 mb-2'>
          <div className='menu-content d-flex justify-content-between align-items-start px-3'>
            <div className='d-flex flex-column'>
              <h2 className='text-exl-primary'>Shopping Cart</h2>
              <span className='text-muted font-weight-light'>
                {`${temporaryUserId ? 'Customer' : 'You'} have ${
                  models.itemsInCart || 'no'
                } item${
                  models.itemsInCart > 1 ? 's' : ''
                }  in ${temporaryUserId ? '' : 'your'} Shopping Bag`}
              </span>
            </div>
            <Button
              mode='text'
              type='button'
              className='min-w-auto p-0'
              label={<Icon icon={ICON_COLLECTION.cancel} />}
              onClick={handleClose}
            />
          </div>
        </div>
        {models.cart && models.itemsInCart ? (
          <>
            <div className='mh-300px overflow-auto'>
              {models.cart.codes.map((code) => (
                <div className='menu-item p-3' key={code.hash}>
                  <div className='d-flex align-items-center justify-content-between gap-5 px-3'>
                    <div className='d-flex align-items-center gap-5'>
                      <img
                        src={code.product.image?.original_url || productImg}
                        width={49}
                        height={49}
                        alt='Product'
                        className='rounded object-fit-cover'
                      />
                      <div className='mw-250px'>
                        <h4 className='text-exl-primary text-truncate'>
                          {code.product.name}
                        </h4>
                        <span className='text-muted'>{`${
                          models.cart.currency.symbol
                        }${thousandFormatter(code.price)}`}</span>
                      </div>
                    </div>
                    <div className='d-flex align-items-center gap-5'>
                      <div className='d-flex align-items-center gap-2'>
                        <label className='text-muted'>Quantity:</label>
                        <Input
                          type='number'
                          step={1}
                          mode='number'
                          min={1}
                          integerLimit={4}                          
                          className='mw-80px'
                          onFocus={commands.handleOnFocusInput}
                          onBlur={commands.handleOnBlurInput}
                          value={code.quantity as number}
                          onChange={commands.handleChangeQuantityProduct(
                            code.hash
                          )}
                          disabled={
                            commands.isSomeProductUpdating(code.hash) ||
                            models.isDeleting
                          }
                        />
                      </div>
                      <Button
                        isLoading={commands.isCurrentProductUpdating(code.hash)}
                        disabled={
                          commands.isSomeProductUpdating(code.hash) ||
                          commands.isCurrentProductUpdating(code.hash) ||
                          models.isDeleting
                        }
                        label={<Icon icon={ICON_COLLECTION.trash} />}
                        mode='text'
                        type='button'
                        className='min-w-auto p-0'
                        onClick={commands.handleDeleteClick(code.hash)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='separator my-2'></div>

            <div className='menu-item px-6 py-3 my-1 d-flex align-items-center justify-content-between'>
              <div className='d-flex flex-column gap-2'>
                <span className='fs-5 fw-bold text-muted'>Subtotal:</span>
                <h2 className='text-exl-primary'>{`${
                  models.cart.currency.symbol
                }${thousandFormatter(models.cart.amount)}`}</h2>
              </div>
              <Button
                type='button'
                mainButton
                label='Order'
                onClick={navigateToOrderPage}
              />
            </div>
          </>
        ) : null}
      </LoadWrapper>
    </div>
  )
}
