import React from 'react';
import { ProductTableRow } from './product-table-row';
import { IOrder } from 'shared/types/order';
import { LoadWrapper } from 'shared/components/load-wrapper/load-wrapper';
import { ICode } from 'shared/types/code';
import { thousandFormatter } from 'shared/helpers/formatter';

export interface IProductsTable{
  order:IOrder
  isLoading:boolean
  isCSVLoading:boolean
  loadingItemHash:string
  onDownloadCSV:(hash?:string) => void
}

export const ProductsTable:React.FC<IProductsTable> = ({order,isLoading,isCSVLoading,loadingItemHash,onDownloadCSV}) => {    
    return (
      <div className='table-responsive'>
        <LoadWrapper isLoading={isLoading}>
          <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
              <thead>
                  <tr className="fw-normal fs-6 border-bottom border-gray-200 bg-light">
                      <th className='min-w-200px'>Product Name</th>         
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Subtotal</th>
                      <th className='min-w-100px'>Action</th>
                  </tr>
              </thead>                
                {(order.codes && order.codes.length) ? 
                  <>
                    <tbody>
                      {order.codes.map((code:ICode)=>
                        <ProductTableRow key={code.hash} currency={order.currency} code={code} onDownloadCSV={onDownloadCSV} isCSVLoading={isCSVLoading} loadingItemHash={loadingItemHash}/>                
                      )}
                    </tbody>
                    <tfoot className='bg-light'>
                      <tr>
                        <td colSpan={4} className='text-end'>Subtotal</td>
                        <td>{`${order.currency.symbol}${thousandFormatter(order.amount)}`}</td>
                      </tr>
                      <tr>
                        <td colSpan={4} className='text-end'>VAT</td>
                        <td>{`${order.currency.symbol}${thousandFormatter(order.vat)}`}</td>
                      </tr>
                      <tr>
                         <td colSpan={4} className='text-end fw-bold'>Grand Total</td>
                         <td>{`${order.currency.symbol}${thousandFormatter(order.total)}`}</td>
                      </tr>
                    </tfoot>  
                  </> :
                  <tbody>
                    <tr className="border-bottom text-center text-muted">
                      <td colSpan={5}>No products found in this order.</td>
                    </tr>
                  </tbody>
                }                                                                 
            </table>
          </LoadWrapper>     
      </div>
    );
};


