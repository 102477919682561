/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {SidebarMenuItem} from './SidebarMenuItem'
import {Icon} from "shared/components/icon/icon";
import {ILink} from "shared/types/base";

interface ISidebarMenuMain {
    links: ILink[]
}

const SidebarMenuMain: React.FC<ISidebarMenuMain> = ({links}) => {
    return (
        <>
            {
                links.map(nav => <SidebarMenuItem
                    key={nav.path}
                    to={nav.path}
                    title={nav.title}
                    svgIcon={<Icon
                        className='menu-icon'
                        icon={nav.icon}/>}
                />)
            }
        </>
    )
}

export {SidebarMenuMain}
