import React, {useState} from 'react'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {useStateModalManager} from 'shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from 'shared/constants/modal-names'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {DISCOUNT_EVENTS, EventBus} from 'shared/utils/event-bus'
import {number} from 'yup'
import {DiscountType} from '../../../../../shared/types/discount-types'

export const RemoveProductDiscount = () => {
  const [beforeParams, setBeforeParams] = useState(null)
  const modalState = useStateModalManager(
    DISCOUNT_MODAL_NAME.remove_product_discount,
    {
      onBeforeOpen: (beforeData: {
        discountId: number
        productId: string | number
      }) => {
        setBeforeParams(beforeData)
      },
    }
  )
  const handleClose = () => modalState.close()
  const deleteProductDiscountMutation = useMutation<
    DiscountType,
    AxiosError<{error: string}>,
    {
      discountId: number
      productId: string | number
    }
  >(DiscountsApi.deleteProductDiscount, {
    onError: (error) => {
      toast.error(error?.response.data.error)
    },
    onSuccess: (data) => {
      EventBus.emit(DISCOUNT_EVENTS.remove_product_discount, data)
      toast.success('Successfully removed !')
      setBeforeParams(null)
      modalState.close()
    },
  })
  const handelRemoveProduct = () => {
    if (beforeParams) {
      deleteProductDiscountMutation.mutate(beforeParams)
    }
  }
  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!deleteProductDiscountMutation.isLoading}
    >
      <div className='p-5 text-center'>
        <p className='text-exl-primary mb-10'>
          Are you sure you want to remove discount from a product ?
        </p>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={deleteProductDiscountMutation.isLoading}
            isLoading={deleteProductDiscountMutation.isLoading}
          />
          <Button
            label='Remove'
            mode={'remove'}
            disabled={deleteProductDiscountMutation.isLoading}
            isLoading={deleteProductDiscountMutation.isLoading}
            onClick={handelRemoveProduct}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
