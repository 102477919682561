import React from 'react'
import {
  DiscountProductKeys,
  IDiscountCodes,
} from 'shared/types/discount-types'
import {CodesPrice} from '../codes-price/codes-price'
import {Loader} from '../../../../../shared/components/loader/loader'

interface IPriceVariationsProps {
  codesGroups: IDiscountCodes[]
  isDisabled?: boolean
  isLoading?: boolean
  errors?: boolean
  onChangeProductDiscount: (
    hash: string
  ) => (name: DiscountProductKeys, value: number | string) => void
  onChangeCodsSellPrice: (
    hash: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void
}

const PriceVariationsComponents: React.FC<IPriceVariationsProps> = ({
  codesGroups,
  isDisabled,
  isLoading,
  onChangeProductDiscount,
  errors,
  onChangeCodsSellPrice,
}) => {
  return (
    <div className='p-7 position-relative'>
      {isLoading && <Loader mode={'blur'} />}
      <h4 className='my-5'>Price variations:</h4>
      {codesGroups.length ? (
        codesGroups.map((codes, index) => (
          <CodesPrice
            isErrors={errors}
            onChangeProductDiscount={onChangeProductDiscount(codes.hash)}
            onChangeCodsSellPrice={onChangeCodsSellPrice(codes.hash)}
            codesGroup={codes}
            key={codes.hash}
            isDisabled={isDisabled}
          />
        ))
      ) : (
        <div className='border-bottom text-center text-muted col-12 '>
          <p>Nothing was found </p>
        </div>
      )}
    </div>
  )
}

export const PriceVariations = React.memo(PriceVariationsComponents)
