import React from 'react'
import {footerTitle} from '../../constants/footer'
import {footerMenu} from '../../constants/footer'
import { AUTH_ROUTES } from 'shared/config/routes'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'
import ScrollToAnchor from '../landing-header/scroll-to-anchor'
import styles from './landing-footer.module.scss'
import { Link } from 'react-router-dom'

const LandingFooter = () => {
  return (
    <footer className={styles['footer']}>
      <div className={styles['footer__container']}>
        <a href={LANDING_NAVIGATION.hero.path} className={styles['footer__title']}>
          {footerTitle.title}
        </a>
        <nav className={styles['menu']}>
          <ul className={styles['menu__list']}>
            {footerMenu.map((link) => (
              <li key={link.id} className={styles['menu__item']}>
                <ScrollToAnchor idName={link.id} className={styles['menu__link']}>
                  {link.title}
                </ScrollToAnchor>
              </li>
            ))}
            <li className={styles['menu__item']}>
              <Link to={AUTH_ROUTES.LOGIN.path} className={styles['menu__link']}>
                Log In
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  )
}

export default LandingFooter
