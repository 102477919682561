import {BaseHttpServices} from "shared/services/base-http-services";

import {
    ICategoryOption,
    ICategoryOptions,
    ICreateCategoryDTO,
    ICreateCategoryResponse, IUpdateCategory,
} from "../../../shared/types/api-types/caterogy-api-types";


export class CategoryApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    createCategory = async (data: ICreateCategoryDTO): Promise<ICreateCategoryResponse> => {
        const categoryData = new FormData();
        for (const field in data) {
            categoryData.append(field, data[field]);
        }
        const payload = await this.http.post(`/categories`, categoryData);
        return payload.data.data;
    };
    updateCategory = async (data: IUpdateCategory): Promise<ICreateCategoryResponse> => {
        const categoryData = new FormData();
        for (const field in data.payload) {
            categoryData.append(field, data.payload[field]);
        }
        const payload = await this.http.post(`/categories/${data.idCategory}`, categoryData);
        return payload.data.data;
    };

    getCategories = async (params): Promise<ICategoryOptions> => {
        const payload = await this.http.get(`/categories`, {params: params});
        return payload.data;
    };

    removeCategory = async (categoryId:number): Promise<ICategoryOption> => {
        const payload = await this.http.delete(`/categories/${categoryId}`);
        return payload.data.data;
    };

}

export const CategoryApi = new CategoryApiService(new BaseHttpServices());