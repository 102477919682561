import React from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ActionsButton,
  IActionsButtons,
} from 'shared/components/button/actions-button'
import {CODES_ROUTES} from 'shared/config/routes'
import {thousandFormatter} from 'shared/helpers/formatter'
import {IPurchase, IPurchaseProduct} from 'shared/types/purchase'
import { CurrencyFormatter } from 'shared/helpers/currency-formatter'

interface IPurchaseProductTableRowComponent {
  product: IPurchaseProduct
  purchase: IPurchase
  handleConfirmDeleteClick: (purchaseId: number, productId: number) => void
  handleEditProductFromPurchase: (
    product: IPurchaseProduct
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const PurchaseProductTableRowComponent: React.FC<
  IPurchaseProductTableRowComponent
> = ({
  product,
  purchase,
  handleConfirmDeleteClick,
  handleEditProductFromPurchase,
}) => {
  const navigate = useNavigate()
  const handleDeleteClick =
    (purchaseId: number, productId: number) =>
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleConfirmDeleteClick(purchaseId, productId)
    }
  const handleUploadClick = () => {
    navigate(
      CODES_ROUTES.CREATE_CODES.pathFromPurchases({
        purchase_id: `${purchase.id}`,
        product_name: `${product.product.name}`,
        product_id: `${product.product.id}`,
        supplier_id: `${purchase.supplier.id}`,
        currency_id: `${purchase.currency.id}`,
        purchase_price: `${product.purchase_price}`,
      })
    )
  }
  const PURCHASE_PRODUCT_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'Upload',
      onClick: handleUploadClick,
    },
    {
      label: 'Delete',
      onClick: handleDeleteClick(purchase.id, product.id),
    },
    {
      label: 'Edit',
      onClick: handleEditProductFromPurchase(product),
    },
  ]
  return (
    <tr>
      <td className='min-w-150px'>{product.product.name}</td>
      <td>{`${product.currency.symbol}${thousandFormatter(
        product.purchase_price
      )}`}</td>
      <td>{product.quantity}</td>
      <td>{CurrencyFormatter(product.net,purchase.currency)}</td>
      <td>{CurrencyFormatter(product.vat,purchase.currency)}</td>
      <td>{CurrencyFormatter(parseFloat(product.net) + parseFloat(product.vat),purchase.currency)}</td>
      <td>{`${product.delivered}/${product.quantity}`}</td>
      <td className='d-flex gap-5'>
        <ActionsButton
          label='Actions'
          buttons={PURCHASE_PRODUCT_ACTION_BUTTONS}
        />
      </td>
    </tr>
  )
}

export const PurchaseProductTableRow = React.memo(
  PurchaseProductTableRowComponent
)
