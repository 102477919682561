import React, {ChangeEventHandler, useRef, useState} from 'react'

import {format, isValid, parse, isAfter, isBefore} from 'date-fns'
import FocusTrap from 'focus-trap-react'
import {DateRange, DayPicker, SelectRangeEventHandler} from 'react-day-picker'
import {usePopper} from 'react-popper'
import 'react-day-picker/dist/style.css'
import clsx from 'clsx'
import styles from './day-picker.module.scss'
import {ICON_COLLECTION} from '../icon/icon-list'
import {Icon} from '../icon/icon'
import {Button} from '../button/button'
import {ButtonList} from '../button-list/button-list'

interface IDatePickerDialogProps {
  handleDateChangeFormValue: (startDate: string, endDate: string) => void
  className?: string
  from?: string
  to?: string
  isError?: boolean
  isRequired?: boolean
}

 const DatePickerDialogComponent: React.FC<IDatePickerDialogProps> = ({
  handleDateChangeFormValue,
  className,
  from,
  to,
  isError,
  isRequired = false,
}) => {
  const [selectedRange, setSelectedRange] = useState<DateRange>()
  const [fromValue, setFromValue] = useState<string>(from)
  const [toValue, setToValue] = useState<string>(to)
  const [isPopperOpen, setIsPopperOpen] = useState(false)

  const popperRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start',
  })
  const closePopper = () => {
    setIsPopperOpen(false)
    buttonRef?.current?.focus()
  }

  const handleButtonClick = () => {
    setIsPopperOpen(true)
  }

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value)
    const date = parse(e.target.value, 'y/MM/dd', new Date())
    if (!isValid(date)) {
      return setSelectedRange({from: undefined, to: undefined})
    }
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({from: selectedRange.to, to: date})
    } else {
      setSelectedRange({from: date, to: selectedRange?.to})
    }
  }

  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value)
    const date = parse(e.target.value, 'y/MM/dd', new Date())

    if (!isValid(date)) {
      return setSelectedRange({from: selectedRange?.from, to: undefined})
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({from: date, to: selectedRange.from})
    } else {
      setSelectedRange({from: selectedRange?.from, to: date})
    }
  }

  const handleRangeSelect: SelectRangeEventHandler = (
    range: DateRange | undefined
  ) => {
    if (isRequired === true) {
      if (range) {
        setSelectedRange(range)
        if (range.from && range.to) {
          if (range.from) {
            setFromValue(format(range.from, 'y/MM/dd'))
          } else {
            setFromValue('')
          }
          if (range.to) {
            setToValue(format(range.to, 'y/MM/dd'))
          } else {
            setToValue('')
          }
        }
      }
    } else {
      setSelectedRange(range)
      if (range?.from) {
        setFromValue(format(range.from, 'y/MM/dd'))
      } else {
        setFromValue('')
      }
      if (range?.to) {
        setToValue(format(range.to, 'y/MM/dd'))
      } else {
        setToValue('')
      }
    }
  }

  const handleApplyClick = () => {
      const fromDate = fromValue ? new Date(fromValue) : null
      const toDate = toValue ? new Date(toValue) : null

      if (fromDate && toDate) {
        toDate.setDate(toDate.getDate())
        handleDateChangeFormValue(
          format(fromDate, 'y/MM/dd'),
          format(toDate, 'y/MM/dd')
        )
      } else {
        handleDateChangeFormValue(fromValue, toValue)
      }

    setIsPopperOpen(false)
  }

  const handleCancelClick = () => {
    setFromValue('')
    setToValue('')
    setSelectedRange(null)
    handleDateChangeFormValue('','')
    setIsPopperOpen(false)
  }

  const isDayDisabled = (day: Date) => {
    const today = new Date()
    return isAfter(day, today)
  }

  return (
    <div className={clsx(className, {[styles.error]: isError})}>
      <div
        className={clsx('ma2', styles['input-wrapper'])}
        ref={popperRef}
        onClick={handleButtonClick}
      >
        <div
          className={clsx('d-flex align-items-center overflow-hidden m-0', {
            error: isError,
          })}
        >
          <input
            size={10}
            placeholder='From Date'
            value={fromValue}
            onChange={handleFromChange}
            className={clsx(styles.input, {[styles['input--error']]: isError})}
            disabled={true}
          />
          <p className={clsx({'text-danger': isError}, {'opacity-50':fromValue||toValue}, 'opacity-20 mb-0 me-1')}>
            -
          </p>
          <input
            size={10}
            placeholder='To Date'
            value={toValue}
            onChange={handleToChange}
            className={clsx(styles.input, {[styles['input--error']]: isError})}
            disabled={true}
          />
        </div>
        <button
          ref={buttonRef}
          type='button'
          aria-label='Pick a date'
          onClick={handleButtonClick}
          className={styles.button}
        >
          <Icon icon={ICON_COLLECTION.arrowDown} />
        </button>
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current!,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className={clsx('dialog-sheet', styles.calendar)}
            {...popper.attributes.popper}
            ref={setPopperElement}
            role='dialog'
            aria-label='DayPicker calendar'
          >
            <DayPicker
              showOutsideDays
              numberOfMonths={2}
              mode='range'
              selected={selectedRange}
              onSelect={handleRangeSelect}
              // modifiers={{disabled: isDayDisabled}}
              modifiersClassNames={{
                selected: styles.selected,
                today: styles.today,
              }}
              className={styles.head}
            />
            <div className={styles.footer}>
              <p className={styles.footer__days}>
                {(fromValue || toValue) && `${fromValue} -  ${toValue}`}
              </p>

              <ButtonList>
                <Button label='Cancel' onClick={handleCancelClick} />
                <Button label='Apply' mainButton onClick={handleApplyClick} />
              </ButtonList>
            </div>
          </div>
        </FocusTrap>
      )}
    </div>
  )
}
export const DatePickerDialog = React.memo(DatePickerDialogComponent)