import React from 'react';
import {FormikProps} from 'formik/dist/types'
import {Input} from "../../../../shared/components/input/input";
import {IFormikProps} from "../../../../app/modules/auth/components/CodeCheck";
import {Button} from "../../../../shared/components/button/button";
import {ButtonLink} from "../../../../shared/components/button/button-link";
import {AUTH_ROUTES} from "../../../../shared/config/routes";
import {useIntl} from "react-intl";

interface ICodeCheckPage {
    formik: FormikProps<IFormikProps>;
    isLoading: boolean;
}

export const CodeCheckFormInner: React.FC<ICodeCheckPage> = ({formik, isLoading}) => {
    const intl = useIntl()
    return (
        <>
            <div className='fv-row mb-6 w-sm-300px mx-auto'>
                <Input
                    placeholder='Enter Code'
                    {...formik.getFieldProps('code')}
                    type='code'
                    name='code'
                    autoComplete='on'
                    error={formik.touched.code && !!formik.errors.code }
                    errorText={formik.errors.code}
                    disabled={isLoading}
                />
            </div>

            <div className='d-grid mb-3 w-sm-300px mx-auto'>
                <Button
                    label={intl.formatMessage({id: 'EXL.AUTH.CODE_CHECK.BUTTON'})}
                    mainButton
                    type='submit'
                    disabled={!formik.isValid || isLoading}
                    isLoading={isLoading}
                />
            </div>
            <div className='d-grid mb-3 w-sm-300px mx-auto'>
                <ButtonLink to={AUTH_ROUTES.FORGOT_PASSWORD.path} mode='secondary'>
                    {intl.formatMessage({id: 'EXL.AUTH.BACK'})}
                </ButtonLink>
            </div>
        </>
    );
};

