import {FormikProps} from 'formik'
import clsx from 'clsx'
import {useEffect} from 'react'
import {SingleValue} from 'react-select'
import {useQuery} from 'react-query'
import {Select} from 'shared/components/select/select'
import {Input} from 'shared/components/input/input'
import {PhoneInputField} from 'shared/components/input/phone-input'
import {RowContainer} from '../row-container/row-container'
import {CountrySelect} from 'shared/components/select/country-select'
import {ICountry} from 'shared/types/country'
import {SettingsApi} from 'app/api/settings-api/settings-api'
import {IBusinessType} from 'shared/types/business-type'
import {ICustomer} from 'shared/types/user'
import { CUSTOMER_QUERY_KEYS } from 'shared/constants/query-keys'
import { selectIsEmailVerified } from 'app/store/user/selects'
import { selectAuthUserData } from 'app/store/auth/selects'

interface IAccountMain {
    formik: FormikProps<ICustomer>
    isEditing: boolean
}

export const AccountFormInner: React.FC<IAccountMain> = ({formik, isEditing}) => {
    const {data, refetch} = useQuery(
        [CUSTOMER_QUERY_KEYS.businessTypes],
        async () => await SettingsApi.getBusinessTypes(),
        {
            enabled: false
        }
    )
    const isEmailVerified = selectIsEmailVerified()
    const userId = selectAuthUserData()
    useEffect(() => {
        refetch();
    }, [refetch]);
    const handlePhoneInput = (value: string) => {
        formik.setFieldValue('phone_number', value);
    }
    const handleContactPhoneInput = (value: string) => {
        formik.setFieldValue('account_payable_contact', value);
    }
    const handleBusinessTypeSelect = (newValue: SingleValue<IBusinessType>) => {
        formik.setFieldValue('business_type', newValue?.id);
    }
    const handleCountrySelect = (newValue: SingleValue<ICountry>) => {
        formik.setFieldValue('country_of_incorporation_id', newValue?.id);
    }
    const isEmailVerifiedAlertShow = !isEmailVerified && userId===formik.values.user_id
    return (
        <div className='card-body'>
            { isEmailVerifiedAlertShow&& <p className='mb-0 mt-10 p-5 text-muted' style={{border:'2px solid #D4AFFA', borderRadius:'6px'}}>Please check your inbox to verify your email.</p>}
            <h2 className={clsx('pt-12 pb-5 mb-0 text-exl-primary')}>Contact Information</h2>
            <RowContainer label='Name' className='mb-5'>
                <Input
                    placeholder='Enter Name'
                    {...formik.getFieldProps('full_name')}
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    name='full_name'
                    autoComplete='on'
                    error={formik.touched.full_name && !!formik.errors.full_name}
                    errorText={formik.errors.full_name}
                />
            </RowContainer>
            <RowContainer label='Contact & News email'>
                <Input
                    placeholder='Enter contact & news email'
                    {...formik.getFieldProps('contact_email')}
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    name='contact_email'
                    autoComplete='on'
                    error={formik.touched.contact_email && !!formik.errors.contact_email}
                    errorText={formik.errors.contact_email}
                />
            </RowContainer>

            <h2 className={clsx('pt-12 pb-5 mb-0 text-exl-primary')}>Business Information</h2>
            <RowContainer className='mb-5' label='Company Name'>
                <Input
                    placeholder='Enter Company Name'
                    {...formik.getFieldProps('company_name')}
                    name='company_name'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    autoComplete='on'
                    error={formik.touched.company_name && !!formik.errors.company_name}
                    errorText={formik.errors.company_name}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Company Registration Number'>
                <Input
                    placeholder='Enter Company Number'
                    {...formik.getFieldProps('company_registration_number')}
                    name='company_registration_number'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    autoComplete='on'
                    error={
                        formik.touched.company_registration_number &&
                        !!formik.errors.company_registration_number
                    }
                    errorText={formik.errors.company_registration_number}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Company VAT'>
                <Input
                    placeholder='Enter Company VAT'
                    {...formik.getFieldProps('VAT_number')}
                    name='VAT_number'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={formik.touched.VAT_number && !!formik.errors.VAT_number}
                    errorText={formik.errors.VAT_number}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Website'>
                <Input
                    placeholder='Enter Website'
                    {...formik.getFieldProps('website')}
                    type='url'
                    name='website'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={formik.touched.website && !!formik.errors.website}
                    errorText={formik.errors.website}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='City'>
                <Input
                    placeholder='Enter City'
                    {...formik.getFieldProps('city')}
                    name='city'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={formik.touched.city && !!formik.errors.city}
                    errorText={formik.errors.city}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Street address'>
                <Input
                    placeholder='Enter Street Address'
                    {...formik.getFieldProps('company_address')}
                    name='company_address'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={formik.touched.company_address && !!formik.errors.company_address}
                    errorText={formik.errors.company_address}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Postal code'>
                <Input
                    placeholder='Enter Postal Code'
                    {...formik.getFieldProps('zip_post_code')}
                    name='zip_post_code'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={formik.touched.zip_post_code && !!formik.errors.zip_post_code}
                    errorText={formik.errors.zip_post_code}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Country of incorporation'>
                <CountrySelect
                    placeholder='Choose Country of incorporation'
                    {...formik.getFieldProps('country_of_incorporation_id')}
                    name='country_of_incorporation_id'
                    onChange={handleCountrySelect}                    
                    value={formik.values.country_of_incorporation_id}
                    isDisabled={!isEditing}
                    error={
                        formik.touched.country_of_incorporation_id &&
                        !!formik.errors.country_of_incorporation_id
                    }
                    errorText={formik.errors.country_of_incorporation_id}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Contact number'>
                <PhoneInputField
                    country={'us'}
                    id={'phone_number'}
                    inputClass={isEditing ? 'w-100 ' : 'w-100  py-3 px-4'}
                    placeholder='Enter Contact Number'
                    buttonClass={isEditing ? 'd-block' : 'd-none'}
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    {...formik.getFieldProps('phone_number')}
                    onChange={handlePhoneInput}
                    error={formik.touched.phone_number && !!formik.errors.phone_number}
                    errorText={formik.errors.phone_number}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Email for delivery and invoice'>
                <Input
                    placeholder='Enter Email'
                    {...formik.getFieldProps('email_to_receive_digital_delivery_and_invoice')}
                    name='email_to_receive_digital_delivery_and_invoice'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={
                        formik.touched.email_to_receive_digital_delivery_and_invoice &&
                        !!formik.errors.email_to_receive_digital_delivery_and_invoice
                    }
                    errorText={formik.errors.email_to_receive_digital_delivery_and_invoice}
                />
            </RowContainer>

            <RowContainer className='mb-4' label='Business Type'>
                <Select
                    placeholder='Choose Business Type'
                    {...formik.getFieldProps('business_type')}
                    name='business_type'
                    options={data}
                    isDisabled={!isEditing}
                    onChange={handleBusinessTypeSelect}
                    error={formik.touched.business_type && !!formik.errors.business_type}
                    errorText={formik.touched.business_type && formik.errors.business_type}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Company director'>
                <Input
                    placeholder='Enter Company Director'
                    {...formik.getFieldProps('company_director')}
                    name='company_director'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={
                        formik.touched.company_director &&
                        !!formik.errors.company_director
                    }
                    errorText={formik.errors.company_director}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Account payable contact'>
                <PhoneInputField
                    country={'us'}
                    id={'account_payable_contact'}
                    inputClass={isEditing ? 'w-100 ' : 'w-100  py-3 px-4'}
                    placeholder='Enter Account Payable Number'
                    buttonClass={isEditing ? 'd-block' : 'd-none'}
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    {...formik.getFieldProps('account_payable_contact')}
                    onChange={handleContactPhoneInput}
                    error={formik.touched.account_payable_contact && !!formik.errors.account_payable_contact}
                    errorText={formik.errors.account_payable_contact}
                />
            </RowContainer>

            <RowContainer className='mb-5' label='Account payable email'>
                <Input
                    placeholder='Enter Email'
                    {...formik.getFieldProps('account_payable_email')}
                    name='account_payable_email'
                    readOnly={!isEditing}
                    disabled={!isEditing}
                    error={
                        formik.touched.account_payable_email &&
                        !!formik.errors.account_payable_email
                    }
                    errorText={formik.errors.account_payable_email}
                />
            </RowContainer>
        </div>
    )
}
