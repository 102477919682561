/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Navigate, useRoutes} from 'react-router-dom'
import {wrapUseRoutes} from '@sentry/react'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {AuthPage} from '../modules/auth'
import {LandingPage} from '../../modules/landing/landing'
import {AUTH_ROUTES, LANDING_ROUTES} from '../../shared/config/routes'
import {documentationRoutes} from './documentation-routes'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const AppRoutes: FC = () => {
  return useSentryRoutes([
    {
      path: LANDING_ROUTES.ROOT.path,
      element: <LandingPage />,
    },
    {
      path: 'error/*',
      element: <ErrorsPage />,
    },
    ...AuthPage,
    ...PrivateRoutes,
    ...documentationRoutes,

    {path: '*', element: <Navigate to={AUTH_ROUTES.ROOT.path} />},
  ])
}

export {AppRoutes}
