import {Cart} from '../../../partials'
import { Button } from 'shared/components/button/button'
import { Icon } from 'shared/components/icon/icon'
import { ICON_COLLECTION } from 'shared/components/icon/icon-list'
import { selectIsCartHasProducts, selectProductsInCartQuantity } from 'app/store/user/selects'
import { selectIsGettingCart } from 'app/store/cart/selects'

const Navbar = () => {    
  const isCartHasProducts = selectIsCartHasProducts();
  const cartQuantity = selectProductsInCartQuantity();
  return (
    <div className='app-navbar flex-shrink-0'>    
      <div className={'app-navbar-item ms-1 ms-lg-3'}>
        <div
          className={'symbol'}
          data-kt-menu-trigger="hover"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-static="true"          
        >
          <Button 
              type='button'
              mode={'navbar'}
              label={
                  <span className="d-flex gap-3">
                      {isCartHasProducts ? 
                        <div className='d-flex align-items-start'>
                          <Icon icon={ICON_COLLECTION.emptyCart}/>
                          <span className="badge badge-danger">{cartQuantity}</span>
                        </div> :
                        <Icon icon={ICON_COLLECTION.emptyCart}/>
                      }
                      <span>Cart</span>
                  </span>
              }
          />
          <Cart isLoading={selectIsGettingCart()}/>          
        </div>        
      </div>
    </div>
  )
}

export {Navbar}
