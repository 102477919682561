import React from 'react';
import { Icon } from 'shared/components/icon/icon';
import { ICON_COLLECTION } from 'shared/components/icon/icon-list';
import { Input } from 'shared/components/input/input';
import { IOption, Select } from 'shared/components/select/select';
import { ORDER_OPTIONS } from 'shared/constants/select-options';
import { initialFormWalletsName } from 'shared/constants/wallets';

interface IWalletsSearchBar {
  currencyValue: number
  amountOrderValue: number
  addedOrderValue: number
  spentOrderValue: number
  creditOrderValue: number
  currencyValues: IOption[]
  isLoadingCurrencies: boolean
  handleChangeFormValue: (name: initialFormWalletsName, value: IOption) => void
  handleChangeCurrencyValue: (option: IOption) => void
  setSearchValue: (text: string) => void
  searchValue: string
}

const AllWalletsSearchBarComponent: React.FC<IWalletsSearchBar> = (props) => {
    const {
      currencyValue,
      amountOrderValue,
      addedOrderValue,
      spentOrderValue,
      creditOrderValue,
      currencyValues,
      isLoadingCurrencies,
      handleChangeFormValue,
      setSearchValue,
      handleChangeCurrencyValue,
      searchValue,
    } = props

    return (
      <div className='mb-5'>
        <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-4 position-relative'>
          <div className='col mb-5'>
            <Input
              startAdornment={
                <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
              }
              placeholder='Search'
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              options={ORDER_OPTIONS}
              value={addedOrderValue}
              onChange={(option) =>
                handleChangeFormValue('added_order', option)
              }
              placeholder='Total Money Added'
            />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              options={ORDER_OPTIONS}
              value={amountOrderValue}
              onChange={(option) =>
                handleChangeFormValue('amount_order', option)
              }
              placeholder='Remain Amount'
            />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              options={ORDER_OPTIONS}
              value={spentOrderValue}
              onChange={(option) =>
                handleChangeFormValue('spent_order', option)
              }
              placeholder='Used Amount'
            />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              isLoading={isLoadingCurrencies}
              options={currencyValues}
              value={currencyValue}
              onChange={handleChangeCurrencyValue}
              placeholder='Currency'
            />
          </div>
          <div className='col mb-5'>
            <Select
              isClearable
              options={ORDER_OPTIONS}
              value={creditOrderValue}
              onChange={(option) =>
                handleChangeFormValue('credit_order', option)
              }
              placeholder='Credit limit'
            />
          </div>
        </div>
      </div>
    )
}

export const AllWalletsSearchBar = React.memo(AllWalletsSearchBarComponent)