import {BaseHttpServices} from 'shared/services/base-http-services'

export interface IFormData {
  full_name: string
  company_name: string
  website?: string | null
  email: string
  phone?: string | null
  describe?: number | null
  text?: string | null
}

export interface IFormDataResponce {
  id: number
  full_name: string
  company_name: string
  email: string
  describtion?: string
  describe?: number
  is_read?: boolean | null
}

export interface IDescriptionOption {
    value: number
    label: string
}

interface IDescriptionOptions {
  data: IDescriptionOption[]
}

export class ContactApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  submitFormData = async (data: IFormData): Promise<IFormDataResponce> => {
    const response = await this.http.post('/contacts', {...data})
    return response.data.data
  }

  getDescriptions = async (): Promise<IDescriptionOptions> => {
    const payload = await this.http.get(`/settings/descriptions`)
    return payload.data
  }
}

export const ContactApi = new ContactApiService(new BaseHttpServices())
