import React from 'react'
import clsx from 'clsx';

interface IRowContainer{
  children:React.ReactNode;
  label:string;
  className?:string;
}

export const RowContainer:React.FC<IRowContainer> = ({children, label,className}) => {
  return (
    <div className={clsx('row align-items-center',className)}>
      <label className='col-sm-6 col-lg-4 text-muted'>{label}</label> 

      <div className='col-sm-6 col-lg-4 p-0'>
        {children}
      </div>
    </div>
  )
}
                          