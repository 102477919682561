import React from 'react'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {IFile} from 'shared/types/user'
import styles from './file.module.scss'
import clsx from 'clsx'
import {useModalManager} from 'shared/context/modal-manager'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'

const formats = {
  image: ['.jpg', '.jpeg', '.png', '.webp', '.avif', '.gif', '.svg'],
  docx: ['.doc', '.docx'],
  pdf: ['.pdf'],
  zip: ['.zip']
}

const isImage = (url: string) => {
  return formats.image.some((ext) => url.includes(ext))
}
const isDocx = (url: string) => {
  return formats.docx.some((ext) => url.includes(ext))
}
const isPdf = (url: string) => {
  return formats.pdf.some((ext) => url.includes(ext))
}
const isZip = (url: string) => {
  return formats.zip.some((ext) => url.includes(ext))
}

const FileIcon = (url: string) => {
  if (isDocx(url)) return ICON_COLLECTION.word
  else if (isPdf(url)) return ICON_COLLECTION.pdf
  else if (isZip(url)) return ICON_COLLECTION.zip
}

interface IFileComponent {
  file: IFile
}

export const File: React.FC<IFileComponent> = ({file}) => {
  const modalManager = useModalManager()
  const handleDeleteFile = () => {
    modalManager.open(DASHBOARD_MODAL_NAMES.delete_file, file.id)
  }
  return (
    <div className='d-flex align-items-center position-relative'>
      {isImage(file.file) ? (
        <a
          target='blank'
          href={file.file}
          className='d-flex flex-column align-items-center'
        >
          <img
            src={file.file}
            alt='Uploaded file'
            className='img-thumbnail img-fluid img-center rounded w-150px h-150px w-sm-200px h-sm-200px object-fit-contain'
          />
          <span className='text-exl-primary'>{file.originalName}</span>
        </a>
      ) : (
        <a
          target='blank'
          href={file.file}
          className='d-flex flex-column align-items-center'
        >
          {FileIcon(file.file) ? (
            <Icon
              icon={FileIcon(file.file)}
              className='img-thumbnail img-fluid img-center rounded w-150px h-150px w-sm-200px h-sm-200px object-fit-contain'
            />
          ) : (
            <img
              src={file.file}
              alt='Uploaded file'
              className='img-thumbnail img-fluid img-center rounded w-150px h-150px w-sm-200px h-sm-200px object-fit-contain'
            />
          )}

          <span className='text-exl-primary'>{file.originalName}</span>
        </a>
      )}
      <button
        type='button'
        className={clsx(
          'btn btn-light shadow-sm bg-white rounded-circle border-0 p-4 d-flex align-items-center justify-content-center ',
          styles.button
        )}
        onClick={handleDeleteFile}
      >
        <Icon className='text-danger' icon={ICON_COLLECTION.cancel} />
      </button>
    </div>
  )
}
