import React from 'react'
import {DiscountProduct} from 'shared/types/discount-types'
import {ProductDiscountCard} from './product-discount-card/product-discount-card'

interface IProductDiscountListProps {
  products: DiscountProduct[]
  onOpenProductRemoveWindow: (productId: number) => void
  discountId: number
}
const ProductDiscountListComponent: React.FC<IProductDiscountListProps> = ({
  products,
  onOpenProductRemoveWindow,
  discountId,
}) => {
  return (
    <div className='mt-5'>
      {products.length
        ? products.map((product, index) => (
            <ProductDiscountCard
              discountId={discountId}
              onOpenProductRemoveWindow={onOpenProductRemoveWindow}
              key={product.id}
              product={product}
              isOpenCard={index === 0}
            />
          ))
        : null}
    </div>
  )
}
export const ProductDiscountList = React.memo(ProductDiscountListComponent)
