import React, {useEffect, useState} from 'react'
import styles from './image-cards.module.scss'

interface LandingCardsProps {
  children: React.ReactNode
  activeAccordionIndex: number
}

interface CustomStyles {
  '--active': number
  '--offset': number
  '--direction': number
  '--abs-offset': number
  pointerEvents: 'auto' | 'none'
  opacity: '0' | '1'
  display: 'none' | 'block'
}

const LandingImageCards: React.FC<LandingCardsProps> = ({children, activeAccordionIndex}) => {
  const [active, setActive] = useState(2)
    const MAX_VISIBILITY = 3
    
    useEffect(() => {
      setActive(activeAccordionIndex)
    }, [activeAccordionIndex])

  return (
    <div className={styles['carousel']}>
      {React.Children.map(children, (child, i) => (
        <div
          className={styles['card-container']}
          style={
            {
              '--active': i === active ? 1 : 0,
              '--offset': (active - i) / 3,
              '--direction': Math.sign(active - i),
              '--abs-offset': Math.abs(active - i) / 3,
              pointerEvents: active === i ? 'auto' : 'none',
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? '0' : '1',
              display: Math.abs(active - i) > MAX_VISIBILITY ? 'none' : 'block',
            } as CustomStyles
          }
        >
          {child}
        </div>
      ))}
    </div>
  )
}

export default LandingImageCards
