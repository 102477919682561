import React, {FC} from 'react'
import {Button} from 'shared/components/button/button'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {ICode} from 'shared/types/code'
import styles from './cart-row.module.scss'
import productImg from 'shared/assets/images/product.png'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {PRODUCT_ROUTES} from 'shared/config/routes'
import {Input} from 'shared/components/input/input'
import { thousandFormatter } from 'shared/helpers/formatter'

interface ICartRow {
  code: ICode
  isDeleting:boolean
  handleOnBlur:()=>void
  handleOnFocus:()=>void
  handleChangeQuantityProduct:(hash:string)=>(event: React.ChangeEvent<HTMLInputElement>)=>void
  isSomeProductUpdating:(hash:string)=>boolean
  isCurrentProductUpdating:(hash:string)=>boolean
  handleDeleteClick:(hash:string)=>(event: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>void
}

export const CartRow: FC<ICartRow> = ({ code, isDeleting, handleOnBlur, handleOnFocus, handleChangeQuantityProduct, isSomeProductUpdating, isCurrentProductUpdating, handleDeleteClick }) => {
  return (
    <div className={clsx('p-5', styles.card)}>
      <Link
        to={PRODUCT_ROUTES.SINGLE_PRODUCT.generate(code.product.id.toString())}
        className={styles.card__section}
      >
        <img
          src={code.product.image?.original_url || productImg}
          alt='Product'
          className={styles.card__img}
        />
        <span className={styles.card__name}>
          {code.product.name || '-'}
        </span>
      </Link>
      <div className={styles.card__section}>
        <div className={styles.params}>
          <p className={styles.params__name}>Region</p>
          <p className={styles.params__value}>
            {code.product.region?.name || '-'}
          </p>
        </div>
        <div className={styles.params}>
          <p className={styles.params__name}>Price</p>
          <p
            className={styles.params__value}
          >{`${code.product.currency?.symbol}${thousandFormatter(code.price)}`}</p>
        </div>
        <div className={styles.params}>
          <p className={styles.params__name}>Quantity</p>
          <Input
            placeholder='Qty'
            type={'number'}
            step={1}
            mode='number'
            min={1}
            integerLimit={4}
            value={code.quantity as number}
            className={clsx('mw-100px mx-xl-0', styles.params__value)}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            onChange={handleChangeQuantityProduct(code.hash)}
            disabled={
              isSomeProductUpdating(code.hash) || isDeleting
            }
          />
        </div>
      </div>
      <div className={styles.card__section}>
        <div className={styles.actions}>
          <Button
            label={<Icon icon={ICON_COLLECTION.trash} />}
            isLoading={isCurrentProductUpdating(code.hash)}
            disabled={
              isSomeProductUpdating(code.hash) ||
              isCurrentProductUpdating(code.hash) ||
              isDeleting
            }
            type='button'
            mode='text'
            className='min-w-auto p-0'
            onClick={handleDeleteClick(code.hash)}
          />
        </div>
      </div>
    </div>
  )
}
