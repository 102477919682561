import { IMeta } from "shared/types/api-types/api-types";
import {BaseHttpServices} from "../../../shared/services/base-http-services";
import {IApiKey, ICustomer, IUser} from "../../../shared/types/user";
import { ILink } from "shared/types/base";

export interface DeleteFileParams {
    userId: number
    fileId: number
}

export interface ICreateApiKeyParams {
    userId:number
    name:string
    is_sandbox:boolean
}

export interface IUpdateApiKeyParams{
    userId:number
    apiId:number
    enable:boolean
}

export interface ICustomersList {
    data:IUser[]
    meta:IMeta
}

export interface IUpdateCustomerCredentialsDTO {
    email?:string
    password?:string
    password_confirmation?:string
    is_order_email_enabled?:boolean
    is_invoice_email_enabled?:boolean    
}

export interface IUpdateCredentialsParams {
    userId:number,
    params:IUpdateCustomerCredentialsDTO
}

export interface IUpdateCustomerRestrictionsDTO {
    is_blocked?:boolean
    is_api_blocked?:boolean
}

export interface IUpdateRestrictionsParams {
    userId:number,
    params:IUpdateCustomerRestrictionsDTO
}

interface ICustomersParams {
    name?:string
    status?: string | number
    cart?: string | number
    page?:string|number
}

interface IKeysParams {
    name?:string
    page?:string|number
}

export interface IApiKeys {
    data:IApiKey[]
    links:ILink
    meta:IMeta
}

export interface ITwoFactor {
  data: ITwoFactorAuthData
}

export interface ITwoFactorAuthData {
  qr: string
  secret: string
}

export interface ISet2Fa {
    userId: number
    one_time_password: string
}

export class CustomerApiService  {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getCustomers = async (params:ICustomersParams) : Promise<ICustomersList> => {
        const payload = await this.http.get(`/users`,{
            params:params
        });
        return payload.data;
    }

    updateCustomerCredentials = async ({userId,params}:IUpdateCredentialsParams):Promise<IUser> => {
        const payload = await this.http.post(`/users/${userId}`,params);
        return payload.data.data;
    }

    updateCustomerRestrictions = async ({userId,params}:IUpdateRestrictionsParams):Promise<IUser> => {
        const payload = await this.http.post(`/users/${userId}`,params);
        return payload.data.data;
    }

    getCustomerById = async (userId:number): Promise<ICustomer> => {
        const payload = await this.http.get(`/users/${userId}/customer`);
        return {...payload.data.data,documentList:payload.data.data.documents};
    };

    updateCustomerInfo = async (
        data:ICustomer
    ):Promise<ICustomer> => {
        const formData = new FormData();
        // Insert data ICustomer in FormData
        Object.entries(data).forEach(([key, value]) => {
            if (key !== 'documents') {
                if (!value)
                    formData.append(key, '');
                else
                    formData.append(key, value);
            }
        });    
        // Insert files in FormData
        if (data.documents && data.documents.length > 0) {
            data.documents.forEach((file, index) => {
                formData.append(`documents[${index}]`, file);
            });
        }        

        const payload = await this.http.post(`/users/${data.user_id}/customer`,formData);
        return payload.data.data;
    }
    
    deleteFile = async ({userId,fileId}:DeleteFileParams): Promise<string> => {
        const payload = await this.http.delete(`/users/${userId}/customer/file/${fileId}`);
        return payload.data.data;
    };

    approveCustomer = async (userId:number): Promise<ICustomer> => {
        const payload = await this.http.post(`/users/${userId}/customer/status`,{
            status:'1'
        });

        return payload.data.data;
    };

    declineCustomer = async (userId:number): Promise<ICustomer> => {
        const payload = await this.http.post(`/users/${userId}/customer/status`,{
            status:'3'
        });

        return payload.data.data;
    };    

    getApiKeys = async (userId:number,params:IKeysParams|null):Promise<IApiKeys> => {
        const payload = await this.http.get(`/users/${userId}/api-keys`,{
            params:params
        });
        return payload.data;    
    }
    
    getApiKey = async (userId:number):Promise<IApiKey> => {
        const payload = await this.http.get(`/users/${userId}/api-keys`);
        return payload.data.data;    
    }

    updateApiKey = async ({userId,apiId,enable}:IUpdateApiKeyParams):Promise<IApiKey> => {
        const payload = await this.http.post(`/users/${userId}/api-keys/${apiId}`,{
            enable
        });
        return payload.data.data;    
    }

    createApiKey = async ({userId,name,is_sandbox}:ICreateApiKeyParams): Promise<IApiKey> => {
        const payload = await this.http.post(`/users/${userId}/api-keys`,{
            name,
            is_sandbox
        });

        return payload.data.data;
    };
    getTwoFactorQr = async (userId: number): Promise<ITwoFactor> => {
        const payload = await this.http.get(`/users/${userId}/get-2fa-qr`)
        return payload.data
    }
    set2Fa = async ({ userId, one_time_password }: ISet2Fa): Promise<ITwoFactorAuthData> => {
        const formData = new FormData()
        if (one_time_password) {
            formData.append('one_time_password', one_time_password)
        }
          const payload = await this.http.post(
            `/users/${userId}/set-2fa`,
            formData
          )
        return payload.data.data;
    };
    disable2Fa = async (userId: number): Promise<ITwoFactorAuthData> => {
          const payload = await this.http.post(
            `/users/${userId}/disable-2fa`)
        return payload.data.data;
    };

    removeCustomer = async (customerId:number): Promise<string> => {
        const payload = await this.http.delete(`/users/${customerId}`)
        return payload.data.data;
    }
}

export const CustomerApi = new CustomerApiService(new BaseHttpServices());