import React, {ChangeEvent, useState} from 'react'
import {UploadImage} from 'modules/dashboard/catalog/components/upload-image/upload-image'
import imgMoc from 'shared/assets/images/placehoder.jpg'
import {Input} from 'shared/components/input/input'
import {Loader} from 'shared/components/loader/loader'
import {IBrand, IBrandImage} from 'app/api/landing-api/landing-api'
import {ACCEPT_TYPES_FOR_IMAGES} from 'shared/constants/constants'
import isEqual from 'lodash/isEqual'
import clsx from 'clsx'
import {
  ActionsButton,
  IActionsButtons,
} from 'shared/components/button/actions-button'

interface IBrandsTableRow {
  brand: IBrand
  isSaveButtonLoading: boolean
  handleSaveChanges: (brand: IBrand) => void
  handleDeleteClick: (brand: IBrand) => void
}

const BrandsTableRowComponent: React.FC<IBrandsTableRow> = ({
  brand,
  isSaveButtonLoading,
  handleSaveChanges,
  handleDeleteClick,
}) => {
  const INITIAL_BRAND_DATA = {
    ...brand,
    original_url: (brand.image as IBrandImage).original_url,
  }
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [brandData, setBrandData] = useState<IBrand>(INITIAL_BRAND_DATA)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const {original_url, ...initialBrandDataWithoutImage} = brandData

  const isValidFiles = (file: File | null): boolean => {
    if (!ACCEPT_TYPES_FOR_IMAGES.includes(file.type)) {
      setErrorMessage('Unsupported file(s) format')
      return false
    }
    if (file.size > 1024 * 1024 * 1024) {
      setErrorMessage('File size must be less than 1 GB')
      return false
    }
    return true
  }

  const handleEditClick = () => {
    setIsEditing(true)
  }
  const handleCancelClick = () => {
    setBrandData(INITIAL_BRAND_DATA)
    setErrorMessage(null)
    setIsEditing(false)
  }
  const handleDeleteRegion = () => {
    handleDeleteClick(brand)
  }
  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setBrandData((prevData: IBrand) => {
      return {
        ...prevData,
        name: event.target.value,
      }
    })
  }
  const handleSaveChangesClick = () => {
    handleSaveChanges(brandData)
    setErrorMessage(null)
    setIsEditing(false)
  }
  const setImage = (newPreset: string | null) => {
    if (newPreset?.includes('image'))
      setBrandData((prevData: IBrand) => {
        return {
          ...prevData,
          original_url: newPreset,
        }
      })
  }
  const onUpload = (file: File) => {
    if (file && isValidFiles(file)) {
      setErrorMessage(null)
      setBrandData((prevData: IBrand) => {
        return {
          ...prevData,
          image: file,
        }
      })
    }
  }

  const isDisabledSaveButton =
    isEqual(brand, initialBrandDataWithoutImage) || errorMessage

  const BRANDS_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: isEditing ? 'Cancel' : 'Edit',
      onClick: isEditing ? handleCancelClick : handleEditClick,
    },
    {
      label: isEditing ? 'Save' : 'Delete',
      disabled: isEditing ? isDisabledSaveButton : false,
      onClick: isEditing ? handleSaveChangesClick : handleDeleteRegion,
    },
  ]

  return (
    <tr className='border-bottom'>
      <td>
        <Input
          value={brandData.name}
          disabled={!isEditing}
          readOnly={!isEditing}
          onChange={handleChangeName}
          placeholder='Name'
        />
      </td>
      <td className='py-10'>
        <div className='position-relative h-100px w-225px'>
          {isEditing && (
            <UploadImage
              setImage={setImage}
              onUpdateFile={onUpload}
              mode='only-edit'
            />
          )}
          <figure
            className={clsx('text-center', {
              'border border-danger': errorMessage,
            })}
          >
            <img
              src={brandData.original_url ? brandData.original_url : imgMoc}
              alt='Brand'
              className='object-fit-contain'
              height={100}
              width={200}
            />
          </figure>
          <p className='text-danger'>{errorMessage}</p>
        </div>
      </td>
      <td className='d-flex flex-end border-0 h-100px'>
        <div className='d-flex gap-5 position-relative flex-end w-150px'>
          <ActionsButton label='Actions' buttons={BRANDS_ACTION_BUTTONS} />
          {isSaveButtonLoading && (
            <Loader classNane='bg-white justify-content-end' />
          )}
        </div>
      </td>
    </tr>
  )
}

export const BrandsTableRow = React.memo(BrandsTableRowComponent)
