import { InvoiceApi } from 'app/api/invoices-api/invoices-api';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ActionsButton, IActionsButtons } from 'shared/components/button/actions-button';
import CheckBox from 'shared/components/check-box/check-box';
import { INVOICE_SOURCE, INVOICE_STATUS, INVOICE_TYPE } from 'shared/constants/constants';
import { DownloadService } from 'shared/services/download-service';
import { IInvoice } from 'shared/types/invoice';
import { CurrencyFormatter } from 'shared/helpers/currency-formatter';
import { DateFormatter } from 'shared/utils/date-formatter';
import { AxiosError } from 'axios';

interface IInvoicesTableRow {
  invoice: IInvoice
  handleSelectModel: (value: number) => void
  onDeleteInvoiceClick: (invoiceId: number) => void
}

export const STATUS_COLOR = {
    1:'warning',
    2:'success',
    3:'danger'
}

const InvoicesTableRowComponent: React.FC<IInvoicesTableRow> = ({
  invoice,
  handleSelectModel,
  onDeleteInvoiceClick,
}) => {
  const [isPDFLoading, setIsPDFLoading] = useState<boolean>(false)
  const onDownloadPDF = async () => {
    try {
      setIsPDFLoading(true)
      const response = await InvoiceApi.getInvoicePDF(
        invoice.user_id,
        invoice.id
      )
      DownloadService.downloadObjectAsZip(response, invoice.number)
      setIsPDFLoading(false)
    } catch (error) {
      setIsPDFLoading(false)
      toast.error(`${(error as AxiosError<{message:string}>).response.data.message}`)
    }
  }

  const INVOICES_ROW_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'Download',
      isLoading: isPDFLoading,
      disabled: isPDFLoading,
      onClick: onDownloadPDF,
    },
    {
      label: 'Delete',
      onClick: () => onDeleteInvoiceClick(invoice.id),
    },
  ]
  return (
    <tr className='border-bottom'>
      <td>
        <CheckBox
          checked={invoice.checked || false}
          onChange={() => handleSelectModel(invoice.id)}
        />
      </td>
      <td className='min-w-100px' style={{whiteSpace:'pre'}}>{DateFormatter(invoice.created_at)}</td>
      <td>{invoice.number}</td>
      <td>{INVOICE_SOURCE[invoice.source]}</td>
      <td>{INVOICE_TYPE[invoice.type]}</td>
      <td>{CurrencyFormatter(invoice.amount,invoice.currency)}</td>    
      <td>
        <span className={`badge badge-light-${STATUS_COLOR[invoice.status]}`}>
          {INVOICE_STATUS[invoice.status]}
        </span>
      </td>
      <td>
        <ActionsButton
          label={'Actions'}
          buttons={INVOICES_ROW_ACTION_BUTTONS}
        />
      </td>
    </tr>
  )
}

export const InvoicesTableRow = React.memo(InvoicesTableRowComponent)
