import clsx from "clsx";
import React from "react";
import { LoadWrapper } from "shared/components/load-wrapper/load-wrapper";
import { IErrorCodes } from "shared/types/codes-upload";
import { ErrorCodesTableRow } from "./error-codes-table-row";

export interface IErrorCodesTable {
  codesList: IErrorCodes[]
  isLoading: boolean
}

export const ErrorCodesTable: React.FC<IErrorCodesTable> = ({
  codesList,
  isLoading,
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light '>
              <th>Code</th>
              <th>Error</th>
              <th>Upload ID</th>
            </tr>
          </thead>

          {codesList && codesList.length ? (
            <tbody>
              {codesList.map((code) => (
                <ErrorCodesTableRow code={code} key={code.id} />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={8}>Nothing was found</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}