import React from 'react';
import {PasswordInput} from "../../../../shared/components/input/password-input";
import {Input} from "../../../../shared/components/input/input";
import {IFormikProps} from "../../../../app/modules/auth/components/Registration";
import {FormikProps} from 'formik/dist/types'
import {Button} from "../../../../shared/components/button/button";
import {ButtonLink} from "../../../../shared/components/button/button-link";
import {AUTH_ROUTES, LANDING_ROUTES} from "../../../../shared/config/routes";
import {useIntl} from "react-intl";

interface IRegisterPage {
    formik: FormikProps<IFormikProps>
    isLoading: boolean
}

export const RegisterFormInner: React.FC<IRegisterPage> = ({formik, isLoading}) => {
    const intl = useIntl()

    return (
        <>
            <div className='fv-row mb-4'>
                <Input placeholder='Company name'
                       error={!!(formik.touched.companyName && formik.errors.companyName)}
                       {...formik.getFieldProps('companyName')}
                       errorText={formik.errors.companyName}
                       disabled={isLoading}
                />
            </div>

            <div className='fv-row mb-4'>
                <Input placeholder='Email'
                       error={!!(formik.touched.email && formik.errors.email)}
                       {...formik.getFieldProps('email')}
                       errorText={formik.errors.email}
                       disabled={isLoading}
                       type='email'
                />
            </div>
            <div className='fv-row mb-4'>
                <PasswordInput placeholder='Password'
                               error={!!(formik.touched.password && formik.errors.password)}
                               {...formik.getFieldProps('password')}
                               errorText={formik.errors.password}
                               disabled={isLoading}
                />
            </div>
            <div className='fv-row mb-4'>
                <PasswordInput placeholder='Confirm Password'
                               error={!!(formik.touched.password_confirmation && formik.errors.password_confirmation)}
                               {...formik.getFieldProps('password_confirmation')}
                               errorText={formik.errors.password_confirmation}
                               disabled={isLoading}
                />
            </div>
            <div className='d-grid mb-4'>
                <Button type="submit"
                        label={intl.formatMessage({id: 'EXL.AUTH.REGISTRATION.BUTTON'})}
                        mainButton
                        isLoading={isLoading}
                        disabled={formik.isSubmitting || !formik.isValid || isLoading}/>
            </div>
            <div className='d-grid mb-20'>
                <ButtonLink mode='secondary' to={AUTH_ROUTES.ROOT.path}>{intl.formatMessage({id: 'EXL.AUTH.BACK'})}</ButtonLink>
            </div>
            <div className='d-grid'>
                <ButtonLink to={LANDING_ROUTES.ROOT.path}>{intl.formatMessage({id: 'EXL.AUTH.BACK_TO_SITE'})}</ButtonLink>
            </div>
        </>
    );
};

