import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import styles from './discount-card.module.scss'
import {Toggle} from "shared/components/toggle/toggle";
import {useNavigate} from "react-router-dom";
import {DISCOUNT_ROUTES} from "shared/config/routes";
import {IDiscountUpdateBodyParams} from "../../../../../shared/types/discount-types";
import {Loader} from "../../../../../shared/components/loader/loader";
import {ActionsWindow} from "../actions-window/actions-window";


interface IDiscountProps {
    title: string
    userCount: number
    id: number
    isActive: boolean
    onUpdateDiscount: (id: number, params: IDiscountUpdateBodyParams, index: number) => void
    isLoading: boolean,
    index: number,
    onRemove: ()=> void
}

const DiscountCardComponent: React.FC<IDiscountProps> = (props) => {
    const {
        title,
        userCount,
        isActive,
        id,
        onUpdateDiscount,
        isLoading,
        index,
        onRemove
    } = props
    const [isActiveToggle, setIsActiveToggle] = useState(isActive)
    const navigate = useNavigate()
    const navigateToDiscount = () => {
        navigate(DISCOUNT_ROUTES.DISCOUNT.generate(`${id}`))
    }
    const handelToggle = (event?: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setIsActiveToggle(prevState => {
            onUpdateDiscount(id, {isActive: !prevState, name: title}, index)
            return !prevState
        })
    }
    useEffect(()=>{
        setIsActiveToggle(isActive)
    },[isActive])
    return (
        <div className={clsx('card position-relative', styles.card)} onClick={navigateToDiscount}>
            {isLoading && <Loader mode='blur'/>}
            <div className={styles.card__header}>
                <div className="d-flex justify-content-between align-items-start">
                    <h4 className={styles.card__title}>{title || ''}</h4>
                    <ActionsWindow onRemove = {onRemove}/>
                </div>
                <p className={styles.card__value}>{userCount || 0} users</p>
            </div>
            <div className={styles.card__footer}>
                <p>Active</p>
                <Toggle isActive={isActiveToggle} setActive={handelToggle}/>
            </div>
        </div>
    );
};
export const DiscountCard = React.memo(DiscountCardComponent)
