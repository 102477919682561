import React, {useEffect, useMemo, useState} from 'react'
import {CodesApi, IUploadHistoryList} from 'app/api/dashboard-api/codes-api'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {IOption} from 'shared/components/select/select'
import {
  CODES_QUERY_KEYS,
  UPLOAD_HISTORY_QUERY_KEYS,
} from 'shared/constants/query-keys'
import {IUploadHistory} from 'shared/types/codes-upload'
import {useModalManager} from 'shared/context/modal-manager'
import {CODES_MODAL_NAME} from 'shared/constants/modal-names'
import {AxiosError} from 'axios'
import {CODES_ROUTES} from 'shared/config/routes'
import {toast} from 'react-toastify'
import {useDebounce} from '_metronic/helpers'

export type initialFormName = 'code' | 'product' | 'supplier'

export const useUploadHistory = () => {
  const navigate = useNavigate()
  const modalManager = useModalManager()
  const [selectAll, setSelectAll] = useState(false)
  const queryClient = useQueryClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [uploadsHistoryValue, setUploadsHistoryValue] = useState(
    search.get('uploads_history')
  )

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      product: Number(search.get('product')) || null,
      supplier: Number(search.get('supplier')) || null,
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      uploads_history: Number(search.get('uploads_history')) || null,
      page: search.get('page') ? Number(search.get('page')) : null,
    })
  }, [location.search])

  const codesQuery = useQuery({
    queryKey: [UPLOAD_HISTORY_QUERY_KEYS.codes, queryParams],
    queryFn: async () => {
      const response = await CodesApi.getUploadCodesHistory(queryParams)
      const mappedResponce = response.data.map((item) => ({
        ...item,
        checked: false,
      }))
      return {...response, data: mappedResponce}
    },
    refetchOnWindowFocus: false,
  })

  function manageParams(name: string, value: string | number | null) {
    const ParamsList = {
      product: queryParams.product ? {product: `${queryParams.product}`} : {},
      supplier: queryParams.supplier
        ? {supplier: `${queryParams.supplier}`}
        : {},
      start_date: queryParams.start_date
        ? {start_date: `${queryParams.start_date}`}
        : {},
      end_date: queryParams.end_date
        ? {end_date: `${queryParams.end_date}`}
        : {},
      uploads_history: queryParams.uploads_history
        ? {uploads_history: `${queryParams.uploads_history}`}
        : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.product,
      ...params.supplier,
      ...params.start_date,
      ...params.end_date,
      ...params.page,
    })
    const data = codesQuery.data.data.map((item) => ({...item}))
    queryClient.setQueryData([UPLOAD_HISTORY_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: data,
    })
  }

  const debouncedSearchTerm = useDebounce(uploadsHistoryValue, 1000)
  useEffect(() => {
    const params = manageParams('uploads_history', debouncedSearchTerm)
    setSearchParams({
      ...params.product,
      ...params.supplier,
      ...params.uploads_history,
    })
  }, [debouncedSearchTerm])

  const {
    isLoading: isLoadingProduct,
    handleInputChange: handleInputChangeProducts,
    formationOptions: productsOptionsMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.product,
    Fn: SelectsApi.getProducts,
    params: 'name',
  })

  const {
    isLoading: isLoadingSuppliers,
    handleInputChange: handleInputChangeSuppliers,
    formationOptions: optionsSuppliersMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    params: 'name',
  })

  const handleChangeFormValue = (name: initialFormName, value: IOption) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams({
      ...params.product,
      ...params.supplier,
      ...params.start_date,
      ...params.end_date,
    })
    resetCheckedState()
  }

  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    const params = manageParams('page', 1)
    if (startDate === '' || endDate === '')
      setSearchParams({
        ...params.product,
        ...params.supplier,
      })
    else
      setSearchParams({
        start_date: startDate,
        end_date: endDate,
        ...params.product,
        ...params.supplier,
      })
    resetCheckedState()
  }

  const uploadHistoryChecked = useMemo(() => {
    if (!codesQuery.data) return []
    return codesQuery.data.data.filter((code) => code.checked) || []
  }, [codesQuery.data])
  const handleSelectAllQuery = () => {
    const data = codesQuery.data.data.map((item) => ({
      ...item,
      checked: !selectAll,
    }))
    queryClient.setQueryData([UPLOAD_HISTORY_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: data,
    })
    setSelectAll(!selectAll)
  }
  const handleCheckboxChangeQuery = (id) => {
    const newData = codesQuery.data.data.map((item) =>
      item.id === id ? {...item, checked: !item.checked} : item
    )
    queryClient.setQueryData([UPLOAD_HISTORY_QUERY_KEYS.codes, queryParams], {
      ...codesQuery.data,
      data: newData,
    })
    setSelectAll(newData.every((item) => item.checked))
  }

  const onDeleteUploadHistoryMutate = useMutation<
    string,
    AxiosError<{message: string}>,
    number[]
  >(CodesApi.deleteUploadHistory, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        [UPLOAD_HISTORY_QUERY_KEYS.codes, queryParams],
        {exact: true}
      )
      if (
        Number(search.get('page')) >
        codesQuery.data.meta.total / codesQuery.data.meta.per_page
      ) {
        setCurrentPage(1)
      } else if (
        codesQuery.data.meta.total / codesQuery.data.meta.per_page ===
        0
      ) {
        navigate(CODES_ROUTES.UPLOAD_HISTORY.path)
      }
      setSelectAll(false)
      toast.success(data||'Delete upload history succesfully')
    },
  })

  const onDeleteUploadHistory = () => {
    const uploadHistoryChecked: IUploadHistory[] = codesQuery.data.data.filter(
      (code) => code.checked && code.checked === true
    )
    const codesId: number[] = uploadHistoryChecked.map((code) => code.id)
    modalManager
      .open(CODES_MODAL_NAME.delete_upload_history)
      .then(() => {
        onDeleteUploadHistoryMutate.mutate(codesId)
      })
      .catch((error) => {
        return
      })
  }

  const resetCheckedState = () => {
    if (codesQuery && codesQuery.data) {
      queryClient.setQueryData<IUploadHistoryList>(
        [UPLOAD_HISTORY_QUERY_KEYS.codes, queryParams],
        (prevState: IUploadHistoryList) => {
          const data = prevState.data.map((item) => ({
            ...item,
            checked: false,
          }))
          return {
            ...prevState,
            data: data,
          }
        }
      )
    }
    setSelectAll(false)
  }

  useEffect(() => {
    resetCheckedState()
  }, [location.search])

  const isTableLoading = codesQuery.isLoading
  return {
    models: {
      productsOptions: productsOptionsMemo,
      suppliersOptions: optionsSuppliersMemo,
      values: queryParams,
      codes: codesQuery.data ? codesQuery.data.data : [],
      meta: codesQuery.data ? codesQuery.data.meta : null,
      isTableLoading,
      isCodeDeleting:onDeleteUploadHistoryMutate.isLoading,
      isLoadingSuppliers,
      isLoadingProduct,
      selectAll,
      uploadHistoryChecked,
      uploadsHistoryValue,
    },
    commands: {
      handleChangeFormValue,
      setCurrentPage,
      handleInputChangeSuppliers,
      handleInputChangeProducts,
      handleDateChangeFormValue,
      handleSelectAllQuery,
      handleCheckboxChangeQuery,
      onDeleteUploadHistory,
      setUploadsHistoryValue,
    },
  }
}
