import React, {useEffect, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {contactTitle} from '../../constants/main'
import {placeholderTexts} from '../../constants/main'
import {MultiSelect} from 'shared/components/select/multi-select'
import {useInView} from 'react-intersection-observer'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {
  ContactApi,
  IDescriptionOption,
  IFormData,
  IFormDataResponce,
} from 'app/api/contact-api/contact-api'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {Button} from 'shared/components/button/button'
import styles from './landing-contact.module.scss'
import {initialValues} from './initialValues'
import {validationSchema} from './validationSchema'
import { LANDING_NAVIGATION } from 'modules/landing/constants/landing-navigation'
import { useLocation } from 'react-router-dom'

const LandingContact = () => {
  const location = useLocation()
  const [selectValue, setSelectValue] = useState(null)

  const [descriptionOptions, setDescriptionOptions] = useState<IDescriptionOption[]>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ContactApi.getDescriptions()
        setDescriptionOptions(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [])

  

  const [formRef, formInView] = useInView({
    triggerOnce: true,
    threshold: 0.4,
  })

  const contactMutation = useMutation<IFormDataResponce, AxiosError<{message: string}>, IFormData>(
    ContactApi.submitFormData,
    {
      onSuccess: (values) => {
        toast.success('Form submitted successfully')
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )


  useEffect(() => {
    if (location.hash === '#contact') {
      const contactElement = document.getElementById('contact')
      if (contactElement) {
        window.scrollTo({
          top: contactElement.offsetTop,
          behavior: 'smooth',
        })
      }
    }
  })

  const handleSubmit = (values) => {
    const requestData = {
      ...values,
      describe: selectValue.value,
      phone: values.phone.toString(),
    }
    contactMutation.mutate(requestData)
  }

  const onSelectChange = (value) => {
    setSelectValue(value)
  }

  return (
    <section id={LANDING_NAVIGATION.contact.id} className={styles['contact']}>
      <div
        className={clsx(styles['contact__container'], {
          [styles['contact__container--in-view']]: formInView,
        })}
        ref={formRef}
      >
        <h4 className={styles['contact__title']}>{contactTitle.title}</h4>
        <p className={styles['contact__subtitle']}>{contactTitle.subtitle}</p>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, {resetForm}) => {
            handleSubmit(values)
            resetForm()
            setSelectValue(null)
          }}
          validationSchema={validationSchema}
        >
          {({touched, errors}) => (
            <Form className={styles['form']}>
              <div
                className={clsx(styles['form__input'], {
                  [styles['form__input--disabled']]: contactMutation.isLoading,
                  [styles['input--error']]: touched.full_name && errors.full_name,
                })}
              >
                <Field
                  type='text'
                  name='full_name'
                  placeholder={placeholderTexts.fullName}
                  disabled={contactMutation.isLoading}
                />
                <ErrorMessage
                  name='full_name'
                  component='div'
                  className={styles['form__input--error']}
                />
              </div>
              <div
                className={clsx(styles['form__input'], {
                  [styles['form__input--disabled']]: contactMutation.isLoading,
                  [styles['input--error']]: touched.company_name && errors.company_name,
                })}
              >
                <Field type='text' name='company_name' placeholder={placeholderTexts.companyName} />
                <ErrorMessage
                  name='company_name'
                  component='div'
                  className={styles['form__input--error']}
                />
              </div>
              <div
                className={clsx(styles['form__input'], {
                  [styles['form__input--disabled']]: contactMutation.isLoading,
                  [styles['input--error']]: touched.website && errors.website,
                })}
              >
                <Field
                  type='text'
                  name='website'
                  placeholder={placeholderTexts.website}
                  disabled={contactMutation.isLoading}
                />
                <ErrorMessage
                  name='website'
                  component='div'
                  className={styles['form__input--error']}
                />
              </div>
              <div
                className={clsx(styles['form__input'], {
                  [styles['form__input--disabled']]: contactMutation.isLoading,
                  [styles['input--error']]: touched.email && errors.email,
                })}
              >
                <Field
                  type='email'
                  name='email'
                  placeholder={placeholderTexts.email}
                  disabled={contactMutation.isLoading}
                />
                <ErrorMessage
                  name='email'
                  component='div'
                  className={styles['form__input--error']}
                />
              </div>
              <div
                className={clsx(styles['form__input'], {
                  [styles['form__input--disabled']]: contactMutation.isLoading,
                  [styles['input--error']]: touched.phone && errors.phone,
                })}
              >
                <Field
                  name='phone'
                  placeholder={placeholderTexts.contactNumber}
                  disabled={contactMutation.isLoading}
                />
                <ErrorMessage
                  name='phone'
                  component='div'
                  className={styles['form__input--error']}
                />
              </div>
              <div className={styles['form__input']}>
                <MultiSelect
                  id='describe'
                  name='describe'
                  options={descriptionOptions}
                  onChange={onSelectChange}
                  placeholder={placeholderTexts.dropdown}
                  isDisabled={contactMutation.isLoading}
                  value={selectValue}
                  styles={{
                    input: (base) => ({
                      ...base,
                      padding: '0',
                      margin: '0',
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: '#1B2561',
                      fontWeight: '500',
                      fontSize: '14px',
                    }),
                    container: (base) => ({
                      ...base,
                      maxHeight: '44px',
                      color: '#1B2561',
                      fontWeight: '400',
                      fontSize: '14px',
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      maxHeight: '44px',
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#727BB5',
                      fontWeight: '500',
                    }),
                    option: (base, {isSelected}) => ({
                      ...base,
                      backgroundColor: isSelected ? '#EEEFF8' : '#fff',
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      color: '#1B2561',
                      '&:hover': {
                        backgroundColor: '#EEEFF8',
                      },
                      '&:active': {
                        backgroundColor: '#E9ECFE',
                      },
                    }),
                    control: (base, state) => ({
                      ...base,
                      border: state.isFocused
                        ? '2px solid #1B2561 !important'
                        : '2px solid #fff !important',
                      outline: 'none',
                      '&:hover': {
                        borderColor: '#1B2561 !important',
                      },
                      boxShadow: '0 !important',
                    }),
                  }}
                />
              </div>
              <div
                className={clsx(styles['form__input'], {
                  [styles['form__input--disabled']]: contactMutation.isLoading,
                  [styles['input--error']]: touched.text && errors.text,
                })}
              >
                <Field
                  as='textarea'
                  name='text'
                  placeholder={placeholderTexts.shortDescription}
                  disabled={contactMutation.isLoading}
                />
                <ErrorMessage
                  name='text'
                  component='div'
                  className={styles['form__input--error']}
                />
              </div>
              <Button
                className={styles['form__button']}
                type='submit'
                label={placeholderTexts.button}
                isLoading={contactMutation.isLoading}
                disabled={contactMutation.isLoading}
              />
            </Form>
          )}
        </Formik>
      </div>
    </section>
  )
}

export default LandingContact
