import React from 'react'
import clsx from 'clsx'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {SuppliersTableRow} from './suppliers-table-row'
import {IAllSupplier} from 'app/api/suppliers-api/suppliers-api'

interface ISuppliersTable {
  suppliers: IAllSupplier[]
  isLoading: boolean
  deleteSupplier: (supplierId: number) => void
  viewSingleSupplier: (supplierId: string) => void
}

export const SuppliersTable: React.FC<ISuppliersTable> = ({
  suppliers,
  isLoading,
  deleteSupplier,
  viewSingleSupplier,
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive', !isLoading && 'p-6')}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
              <th>Supplier Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Website</th>
              <th>VAT</th>
              <th>Company Registration Number</th>
              <th>Actions</th>
            </tr>
          </thead>
          {suppliers && suppliers.length ? (
            <tbody>
              {suppliers.map((supplier: IAllSupplier) => (
                <SuppliersTableRow
                  key={supplier.id}
                  supplier={supplier}
                  deleteSupplier={deleteSupplier}
                  viewSingleSupplier={viewSingleSupplier}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={11}>No suppliers found.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
