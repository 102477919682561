import * as Yup from "yup";
import {ARCHIVE_FORMATS, IMAGE_FORMATS} from "../../../../shared/constants/base";


const allowedFormats = [...IMAGE_FORMATS, ...ARCHIVE_FORMATS]

const specialCharacterRegex = /[!@#$=№~%^&*()+\[\]{};':"\\|,.<>\/?]/;

export const codesUploadSchema = (isText: boolean, isImage: boolean) => {
    const schema = Yup.object().shape({
        purchase_price: Yup.number().required('Purchase price is required').positive('Purchase price must be a positive number'),
        sell_price: Yup.number().required('Selling price is required').positive('Selling  price must be a positive number')
            .test('sellPriceGreaterThanPurchasePrice', 'Selling price must be greater than purchase price', function (value) {
                if (!value) return false;
                const {purchase_price} = this.parent;
                if (purchase_price && value) {
                    return value > purchase_price;
                }
                return true;
            }),
        currency_id: Yup.number().required('Currency is required'),
        product_id: Yup.number().required('Product is required'),
        supplier_id: Yup.number().required('Supplier is required'),
        text_codes: isText
            ? Yup.string().required('Codes is required').test('no-special-chars', 'Text must not contain any special characters except -', value => {
                return !specialCharacterRegex.test(value);
            })
            : Yup.string(),
        image_codes: isImage
            ? Yup.array()
                .max(1000,'You can upload a maximum of 1000 files')
                .of(
                    Yup.mixed<File>()
                        .test('fileFormat', 'Unsupported file(s) format', (value) => {
                            if (!value) return false;
                            return allowedFormats.includes(value.type);
                        })
                        .test('fileSize', 'File(s) size must be less than 1 GB', (value) => {
                            if (!value) return false;
                            return value.size <= 1024 * 1024 * 1024; // 1 GB in Bytes
                        })
                )
                .required('At least one file must be uploaded!')
            : Yup.mixed<File>().notRequired(),
    });

    return schema;
}