import React from 'react'
import {useSlidersPage} from './use-sliders-options'
import {SlidersTable} from '../../components/tables/sliders/sliders-table'
import {DeleteSliderImageModal} from '../../components/modals/sliders/delete-slider-image-modal'

export const SlidersOptions = () => {
  const {models, commands} = useSlidersPage()
  return (
    <>
      <div className='card position-relative'>
          <p>Slider 1 <br/>Recommended image size W: 373 H: 463 (4x5)</p>
          <p>Slider 2 <br/>Recommended image size  W: 576 H: 316 (5X3)</p>
          <p>If the aspect ratio is incorrect, the image is cropped so that the smaller side completely contains the image and the larger side will be cropped.</p>
        <SlidersTable
          sliders={models.sliders}
          isLoading={models.isTableLoading}
          isUpdating={models.isUpdating}
          handleUpdate={commands.handleUpdate}
          handleDeleteFile={commands.handleDeleteFile}
        />
      </div>
      <DeleteSliderImageModal />
    </>
  )
}
