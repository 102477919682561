import { SelectsApi } from 'app/api/settings-api/selects-api'
import {IProductSupplierTracker, IProductSuppliersList, IUpdateProductSupplierParams, SuppliersApi} from 'app/api/suppliers-api/suppliers-api'
import { AxiosError } from 'axios'
import { useState } from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import { toast } from 'react-toastify'
import {ERROR_ROUTE, SUPPLIER_ROUTES} from 'shared/config/routes'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useModalManager} from 'shared/context/modal-manager'
import { BlobErrorResponse } from 'shared/helpers/blob-error-response'
import { useSelectController } from 'shared/hooks/use-select-controller'
import { DownloadService } from 'shared/services/download-service'
import { decimalFormatter } from 'shared/helpers/decimalFormatter';

export const useProductSuppliersPage = () => {
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const { productId } = useParams()
  const navigate = useNavigate()
  const modalManager = useModalManager()
  const [updatingSupplier, setUpdatingSupplier] = useState<number>()
  const queryClient = useQueryClient()

  const {
    data: suppliers,
    isLoading: isProductLoading,
  } = useQuery({
    queryKey: [SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers, productId],
    queryFn: async () =>
      await SuppliersApi.getProductSuppliers(Number(productId)),
    onError: () => {
      navigate(ERROR_ROUTE.path)
    },
  })

  const {data: oneExternalProd} = useQuery({
    queryKey: [SUPPLIERS_TRACKER_QUERY_KEYS.product_name, productId],
    queryFn: async () =>
      await SuppliersApi.getOneExternalProduct(Number(productId)),
  })

  const handleGoBackClick = () => {
    navigate(SUPPLIER_ROUTES.SUPPLIERS_TRACKER.path)
  }

  const handleDeleteSupplier = (supplierId: number) => {
    modalManager.open(
      SUPPLIERS_MODAL_NAME.delete_product_supplier,
      productId,
      supplierId
    )
  }

  const handleAddSupplierClick = () => {
    modalManager.open(SUPPLIERS_MODAL_NAME.add_supplier, parseInt(productId), oneExternalProd.name)
  }

  const currencies = Array.from(
    new Set(
      suppliers &&
        suppliers.data.reduce((currencyList, item) => {
          const currencyNames = Object.keys(item.currencies)
          return [...currencyList, ...currencyNames]
        }, [])
    )
  )
  
  const {
    options: currenciesOptions,
    isLoading: isLoadingCurrencies,
    formationOptions: optionsCurrenciesMemo,
  } = useSelectController({
    key: SUPPLIERS_TRACKER_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
  })

  const updateProductSupplierMutation = useMutation<
    IProductSupplierTracker,
    AxiosError<{error: string}>,
    IUpdateProductSupplierParams
  >(SuppliersApi.updateProductSupplier, {
    onMutate(variables) {
      setUpdatingSupplier(variables.supplier_id)
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        [SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers, productId],
        {
          ...suppliers,
          data: data,
        }
      )
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers,
        productId,
      ])
      toast.success(`Supplier was updated successfully!`)
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })
  
  const updateProductSupplier = (
    supplierId: number,
    currency: string,
    price: number
  ) => {
    const selectedCurrency = optionsCurrenciesMemo && !isLoadingCurrencies &&
      optionsCurrenciesMemo.find((item) => item.label === currency)
    
    if (price) {
      updateProductSupplierMutation.mutate({
        product_id: productId,
        supplier_id: supplierId,
        currency: selectedCurrency.id,
        price: decimalFormatter(price)
      })
    }
  }

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const csvData = await SuppliersApi.getProductSupplierCSV(Number(productId))
      DownloadService.downloadObjectAsZip(csvData, `${oneExternalProd.name} Suppliers Report`)
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const recountProductSupplierMutation = useMutation<
    IProductSuppliersList,
    AxiosError<{error: string}>,
    number
  >(SuppliersApi.recountProductSuppliers, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        [SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers, productId],
        {
          ...suppliers,
          data: data,
        }
      )
      queryClient.invalidateQueries([
        SUPPLIERS_TRACKER_QUERY_KEYS.product_suppliers,
        productId,
      ])
      toast.success(`Suppliers was recount successfully!`)
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const handleRecountClick = () => {
    recountProductSupplierMutation.mutate(Number(productId))
  }

  const isUpdateSupplierLoading = updateProductSupplierMutation.isLoading

  return {
    models: {
      productName: oneExternalProd ? oneExternalProd.name : '',
      suppliers: suppliers?.data,
      isLoading: isProductLoading,
      isUpdateSupplierLoading: isUpdateSupplierLoading,
      updatingSupplier,
      currencies,
      allCurrencies: currenciesOptions,
      isCSVLoading,
      isRecountLoading: recountProductSupplierMutation.isLoading
    },
    commands: {
      handleGoBackClick,
      handleDeleteSupplier,
      handleAddSupplierClick,
      updateProductSupplier,
      handleRecountClick,
      onDownloadCSV,
    },
  }
}
