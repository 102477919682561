import { GroupBase, OptionsOrGroups } from "react-select";
import { IOption } from "shared/components/select/select";
import {BaseHttpServices} from "shared/services/base-http-services";
import { ICountry } from "shared/types/country";

export class SettingsApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getCountriesByName = async (
        name: string,
    ): Promise<OptionsOrGroups<any, GroupBase<ICountry>> | undefined > => {
        const payload = await this.http.get(`/settings/countries?name=${name}`);        
        return payload.data.data.sort((a:ICountry, b:ICountry) => a.official_name.localeCompare(b.official_name));
    };
    getBusinessTypes = async (): Promise<IOption[]> => {
        const payload = await this.http.get(`/settings/business_types`);
        return payload.data.data;
    };

}

export const SettingsApi = new SettingsApiService(new BaseHttpServices());