import React from 'react'
import clsx from 'clsx'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import {SlidersTableRow} from './sliders-table-row'
import {ISlider, ISliderImage} from 'app/api/options-api/options-api'

export interface ISlidersTable {
  sliders: ISlider[]
  isLoading: boolean
  isUpdating: boolean
  handleUpdate: (slider: ISlider) => void
  handleDeleteFile: (
    slug: string,
    image: ISliderImage
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const SlidersTable: React.FC<ISlidersTable> = ({
  sliders,
  isLoading,
  isUpdating,
  handleUpdate,
  handleDeleteFile,
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive',{'py-3':!isLoading} )}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light text-exl-primary'>
              <th>Slug</th>
              <th className='min-w-200px'>Images</th>
            </tr>
          </thead>
          {sliders && sliders.length ? (
            <tbody>
              {sliders.map((slider: ISlider) => (
                <SlidersTableRow
                  key={slider.id} 
                  handleUpdate={handleUpdate}
                  isLoading={isUpdating}
                  slider={slider}
                  handleDeleteFile={handleDeleteFile}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={3}>No sliders.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
