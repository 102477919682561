import {BaseHttpServices} from 'shared/services/base-http-services'

export interface IBrand {
  id?: number
  name: string
  image: IBrandImage | null | File
  original_url?:string
}

export interface IBrandImage {
  id?: number
  name?: string
  file_name?: string
  mime_type?: string
  size?: string
  original_url: string
}

interface IBrandResponse {
  data: IBrand[]
}

interface ISlider {
  id: number
  name: string
  file_name: string
  mime_type: string
  size: string
  original_url: string
}

interface ISliderItem {
  id: number
  slug: string
  image: ISlider[]
}

interface ISliderResponse {
  data: ISliderItem[]
}

export class LandingApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getBrands = async (): Promise<IBrandResponse> => {
    const response = await this.http.get('/brands')
    return response.data
  }

  getSLiders = async (): Promise<ISliderResponse> => {
    const response = await this.http.get('/sliders')
    return response.data
  }
}

export const LandingApi = new LandingApiService(new BaseHttpServices())
