import React, {useState} from 'react';
import {useStateModalManager} from "shared/context/modal-manager";
import {DASHBOARD_MODAL_NAMES} from "shared/constants/modal-names";
import {Modal} from "shared/components/modal-base";
import {ButtonList} from "shared/components/button-list/button-list";
import {Button} from "shared/components/button/button";
import {useMutation, useQueryClient} from "react-query";
import {AxiosError} from "axios";
import {toast} from "react-toastify";
import { CategoryApi } from 'app/api/dashboard-api/category-api';
import { CATEGORIES_QUERY_KEYS } from 'shared/constants/query-keys';
import { ICategoryOptions } from 'shared/types/api-types/caterogy-api-types';

export const ModalRemoveCategory = () => {
    const [categoryId, setCategoryId] = useState(NaN);
    const queryClient = useQueryClient()
    const [queryParams,setQueryParams] = useState(null);
    const modalState = useStateModalManager(DASHBOARD_MODAL_NAMES.remove_category, {
        onBeforeOpen: (categoryId: number,queryParams) => {
            setCategoryId(categoryId)
            setQueryParams(queryParams)
        }
    });
    const deleteCategoryMutation = useMutation<
        { name: string },
        AxiosError<{ message: string }>,
        number>(CategoryApi.removeCategory, {
        onSuccess: (data) => {
            modalState.close()
            queryClient.setQueryData([CATEGORIES_QUERY_KEYS.category_page,queryParams],(prevData:ICategoryOptions)=>{
                const categoryIdx = prevData.data.findIndex(category=>category.id === categoryId)
                prevData.data.splice(categoryIdx,1)
                return prevData
            })
            queryClient.invalidateQueries([CATEGORIES_QUERY_KEYS.category_page,queryParams],{exact:false})
            toast.success(`${data.name} category successfully removed!`)
        },
        onError: (error) => {
            toast.error(error?.response.data.message)
        }
    })
    const handelRemoveProduct = () => {
        if (categoryId) {
            deleteCategoryMutation.mutate(categoryId)
        }
    }
    const handleClose = () => modalState.close()
    return (
        <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!deleteCategoryMutation.isLoading}>
            <div className="p-5 text-center">
                <h5 className='text-exl-primary mb-10'>
                    Are you sure you want to remove this category ?
                </h5>
                <ButtonList className='justify-content-end'>
                    <Button label='Cancel'
                            onClick={handleClose}
                            disabled={deleteCategoryMutation.isLoading}
                            isLoading={deleteCategoryMutation.isLoading}/>
                    <Button label='Remove'
                            mode={'remove'}
                            disabled={deleteCategoryMutation.isLoading}
                            isLoading={deleteCategoryMutation.isLoading}
                            onClick={handelRemoveProduct}/>
                </ButtonList>
            </div>

        </Modal.Root>
    );
};

