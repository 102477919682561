import React, {useRef, useState} from 'react'
import clsx from 'clsx'
import {ISlider, ISliderImage} from 'app/api/options-api/options-api'
import imgMoc from 'shared/assets/images/placehoder.jpg'
import {Dropzone} from 'shared/components/dropzone/dropzone'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Icon} from 'shared/components/icon/icon'
import styles from 'modules/dashboard/account/components/file/file.module.scss'
import {ACCEPT_TYPES_FOR_IMAGES} from 'shared/constants/constants'

interface ISlidersTableRow {
  slider: ISlider
  isLoading: boolean
  handleUpdate: (slider: ISlider) => void
  handleDeleteFile: (
    slug: string,
    image: ISliderImage
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const SlidersTableRowComponent: React.FC<ISlidersTableRow> = ({
  slider,
  isLoading,
  handleUpdate,
  handleDeleteFile,
}) => {
  const [error, setError] = useState<string>(null)
  const isValidFiles = (files: File[]): boolean => {
    const isFilesValid = files.every((file) => {
      if (
        !ACCEPT_TYPES_FOR_IMAGES.includes(file.type) ||
        file.size > 1024 * 1024 * 1024
      ) {
        setError('File can be only JPEG, JPG, PNG, SVG format and not bigger than 1 Gb !')
        return false
      }
      setError(null)
      return true
    })    
    return isFilesValid
  }

  const handleUploadFiles = (files: File[]) => {
    if (isValidFiles(files)) {      
      const payload: ISlider = {
        ...slider,
        image: files,
      }
      handleUpdate(payload)
    }
  }
  const ref = useRef<HTMLInputElement | null>(null)
  return (
    <tr className='border-bottom'>
      <td>
        <p>{slider.slug}</p>
      </td>
      <td className='py-10'>
        <div className='d-flex justify-content-around justify-content-sm-start align-items-start flex-wrap gap-10'>
          {slider.image &&
            (slider.image as ISliderImage[]).map((image: ISliderImage) => (
              <div className='position-relative' key={image.id}>
                <figure className='text-start'>
                  <a
                    target='blank'
                    href={image.original_url}
                    className='d-flex flex-column align-items-center'
                  >
                    <img
                      src={image.original_url ? image.original_url : imgMoc}
                      alt='Slider'
                      className='object-fit-contain'
                      height={200}
                      width={200}
                    />
                  </a>
                </figure>
                <button
                  type='button'
                  className={clsx(
                    'btn btn-light shadow-sm bg-white rounded-circle border-0 p-4 d-flex align-items-center justify-content-center',
                    styles.button
                  )}
                  onClick={handleDeleteFile(slider.slug, image)}
                >
                  <Icon icon={ICON_COLLECTION.cancel} />
                </button>
              </div>
            ))}
        </div>
        <Dropzone
          onChange={handleUploadFiles}
          classNames='mt-10'
          isLoading={isLoading}
          disabled={isLoading}
          label='Upload'
          name={slider.slug}
          errorMessage={error}
          accept={['image/jpeg', 'image/png', 'image/svg+xml']}
          ref={ref}
        />
      </td>
    </tr>
  )
}

export const SlidersTableRow = React.memo(SlidersTableRowComponent)
