import {
  DiscountProduct,
  IDiscountCodesValid,
} from '../../../../../shared/types/discount-types'

export interface IValidationProductDiscountError {
  id?: string
  general_sell_price?: string
  type?: string
  discount?: string
  codes?: IDiscountCodesValid[]
}
export const validateGeneral = (value: DiscountProduct) => {
  const error: IValidationProductDiscountError = {}

  if (!value.id) {
    error.id = 'Product is required field !'
  }
  if (!value.discount) {
    error.discount = 'Discount is required field !'
  }
  if (value.discount <= 0) {
    error.discount = 'Discount - must be greater than zero'
  }
  if (!value.general_sell_price) {
    error.general_sell_price = 'Sell Price is required field !'
  }
  if (value.general_sell_price <= 0) {
    error.general_sell_price = 'Sell price - must be greater than zero'
  }
  if (!value.type) {
    error.type = 'Type is required field !'
  }

  return error
}
export const validateCodesGroups = (value: DiscountProduct) => {
  const error: IValidationProductDiscountError = {}
  if (!value.id) {
    error.id = 'Product is required field !'
  }

  return error
}
