import React from 'react'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {Outlet} from 'react-router-dom'
import {OPTIONS_ROUTES} from 'shared/config/routes'

const OPTIONS_TAB_ROUTES = [
  OPTIONS_ROUTES.REGIONS,
  OPTIONS_ROUTES.CURRENCIES,
  OPTIONS_ROUTES.BRANDS,
  OPTIONS_ROUTES.SLIDERS,
  OPTIONS_ROUTES.ADMIN_SETTINGS,
]

export const OptionsLayout = () => {
  return (
    <>
      <div className='d-md-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary mb-5 mb-md-0'>Options</h1>
      </div>
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={OPTIONS_TAB_ROUTES} className='mb-5' />
        <Outlet />
      </div>
    </>
  )
}
