import { IReorder } from 'app/api/order-api/order-api'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {ActionsButton, IActionsButtons} from 'shared/components/button/actions-button'
import {ORDERS_ROUTES} from 'shared/config/routes'
import {ORDER_METHOD, ORDER_STATUS} from 'shared/constants/constants'
import {IOrder} from 'shared/types/order'
import { CurrencyFormatter } from 'shared/helpers/currency-formatter'
import {DateFormatter} from 'shared/utils/date-formatter'

interface IOrdersTableRow {
  order: IOrder
  isReorderLoading: boolean
  isPreorderCancelling: boolean
  preorderIdForLoader: number
  orderIdForLoader: number
  handleReorderAction: ({userId, orderId}: IReorder) => void
  handleCancelPreorderAction: (orderId: number) => void
}

export const STATUS_COLOR = {
  1: 'warning',
  2: 'success',
  3: 'danger',
  4: 'primary',
  5: 'warning',
  6: 'warning',
}

const AllOrdersTableRowComponent: React.FC<IOrdersTableRow> = ({
  order,
  isReorderLoading,
  isPreorderCancelling,
  orderIdForLoader,
  preorderIdForLoader,
  handleReorderAction,
  handleCancelPreorderAction,
}) => {
  const navigate = useNavigate()
  const handleViewClick = () => {
    navigate(
      ORDERS_ROUTES.USER_ORDER.generate(
        order.user_id.toString(),
        order.id.toString()
      )
    )
  }
  const handleCancelClick = () => {
    handleCancelPreorderAction(order.id)
  }
  const handleReorderClick = () => {
    handleReorderAction({userId: Number(order.user_id), orderId: order.id})
  }

  const isPreOrder =
    order.status === 4 || order.status === 5 || order.status === 6

  const ORDERS_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: 'View Order',
      onClick: handleViewClick,
    },
    {
      label: isPreOrder ? 'Cancel' : 'Reorder',
      isLoading:
        isPreOrder
          ? isPreorderCancelling && order.id === preorderIdForLoader
          : isReorderLoading && order.id === orderIdForLoader,
      onClick: isPreOrder ? handleCancelClick : handleReorderClick,
    }
  ]
  return (
    <tr className='border-bottom'>
      <td className='min-w-100px' style={{whiteSpace:'pre'}}>{DateFormatter(order.created_at)}</td>
      <td>{order.number}</td>
      <td>{order.company_name}</td>
      <td>{ORDER_METHOD[order.method]}</td>
      <td>{CurrencyFormatter(order.total,order.currency)}</td>
      <td>
        {order.profit_price
          ? CurrencyFormatter(order.profit_price,order.currency)
          : '--'}
      </td>
      <td>
        <span className={`badge badge-light-${STATUS_COLOR[order.status]}`}>
          {ORDER_STATUS[order.status]}
        </span>
      </td>
      <td>
        <ActionsButton label='Actions' buttons={ORDERS_ACTION_BUTTONS}/>
      </td>
    </tr>
  )
}

export const AllOrdersTableRow = React.memo(AllOrdersTableRowComponent)
