import React from 'react'
import {PurchasesTable} from '../components/table/purchase-tracker-table'
import {Pagination} from 'modules/dashboard/components/pagination/pagination'
import {PurchasesSearchBar} from '../components/purchases-search-bar'
import {usePurchasesPage} from './use-purchases-page'
import {Button} from 'shared/components/button/button'
import {CreatePurchaseModal} from '../components/modals/create-purchase-modal'
import {EditPurchaseModal} from '../components/modals/edit-purchase-modal'
import {ConfirmDeletePurchaseModal} from '../components/modals/confirm-delete-purchase-modal'
import {ButtonList} from 'shared/components/button-list/button-list'
export const PurchaseTrackerPage = () => {
  const {models, commands} = usePurchasesPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold'>Purchase Tracker</h1>
        <ButtonList>
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
          <Button
            mainButton
            type='button'
            label='Add purchase'
            onClick={commands.handleAddPurchaseClick}
          />
        </ButtonList>
      </div>
      <div className='card p-7 position-relative'>
        <PurchasesSearchBar
          searchValue={models.searchValue}
          supplierValue={models.values.supplier}
          statusValue={models.values.status}
          currencyValue={models.values.currency}
          isSuppliersLoading={models.isLoadingSuppliers}
          isCurrenciesLoading={models.isLoadingCurrencies}
          suppliers={models.suppliers}
          currencies={models.currencies}
          handleInputChangeSuppliers={commands.handleInputChangeSuppliers}
          handleChangeFormValue={commands.handleChangeFormValue}
          handleDateChangeFormValue={commands.handleDateChangeFormValue}
          handleInputChangeCurrencies={commands.handleInputChangeCurrencies}
          setSearchValue={commands.setSearchValue}
        />
        <PurchasesTable
          handleDuplicateClick={commands.handleDuplicateClick}
          purchases={models.purchases}
          isLoading={models.isTableLoading}
          handleConfirmDelete={commands.handleConfirmDeleteClick}
          handleEditPurchase={commands.handleEditPurchase}
        />
        {models.purchases && models.purchases.length ? (
          <Pagination
            currentPage={Number(models.values.page)}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
      </div>
      <CreatePurchaseModal />
      <EditPurchaseModal />
      <ConfirmDeletePurchaseModal />
    </>
  )
}
