import {BaseHttpServices} from 'shared/services/base-http-services'
import {
  DeleteUsersParamsType,
  DiscountProduct,
  DiscountType,
  ICreateDiscount,
  IDiscountListResponse,
  IDiscountUpdateParams,
  IDiscountUser,
  IProductDiscountParams,
  UserParamsType,
} from '../../../shared/types/discount-types'
import {IUser} from '../../../shared/types/user'
import {ICodesGroupItem} from './catalog-api'
import {FindEntityResponse} from '../../../shared/hooks/use-filter-request'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'
import { AxiosResponse } from 'axios'

export class DiscountsApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getDiscounts = async (params: {
    page?: number
  }): Promise<IDiscountListResponse> => {
    const payload = await this.http.get(`/discounts`, {
      params: {...params, per_page: 10},
    })
    return payload.data
  }

  updateDiscount = async (
    params: IDiscountUpdateParams
  ): Promise<DiscountType> => {
    const payload = await this.http.post(`/discounts/${params.id}`, params.body)
    return payload.data.data
  }
  deleteDiscount = async (discountId: number): Promise<string> => {
    const payload = await this.http.delete(`/discounts/${discountId}`)
    return payload.data.message
  }

  createDiscount = async (
    params: Partial<ICreateDiscount>
  ): Promise<DiscountType> => {
    const payload = await this.http.post(`/discounts`, params)
    return payload.data.data
  }
  getDiscount = async (discountId: string): Promise<DiscountType> => {
    const payload:AxiosResponse<{data:DiscountType}> = await this.http.get(`/discounts/${discountId}`)
    const response:DiscountType = {...payload.data.data,products:payload.data.data.products.map((product)=>({...product,discount:decimalFormatter(product.discount)}))}
    return response
  }
  getDiscountUsers = async (discountId: string): Promise<IDiscountUser[]> => {
    const payload = await this.http.get(`/discounts/${discountId}/users`)
    return payload.data.data
  }
  addUsersToDiscount = async (params: UserParamsType): Promise<IUser[]> => {
    const payload = await this.http.post(
      `/discounts/${params.discount}/users`,
      {
        selected_models: [params.customerId],
      }
    )
    return payload.data.data
  }
  deleteUsersFromDiscount = async (
    data: DeleteUsersParamsType
  ): Promise<IDiscountUser[]> => {
    const payload = await this.http.delete(
      `/discounts/${data.discount}/users`,
      {
        data: {
          selected_models: data.selectedUsers,
        },
      }
    )
    return payload.data.data
  }

  getCodesGroup = async (data: {
    id: number | string
    signal: AbortSignal
  }): Promise<FindEntityResponse<ICodesGroupItem[]>> => {
    const payload = await this.http.get(`/products/${data.id}/codes_group_without_collapse`, {
      signal: data.signal,
    })
    return payload.data.data
  }

  addProductDiscount = async (
    params: IProductDiscountParams
  ): Promise<DiscountProduct> => {
    const payload = await this.http.post(
      `discounts/${params.discountId}/products/${params.productId}`,
      {product: params.product}
    )
    return payload.data.data
  }
  updateProductDiscount = async (
      params: IProductDiscountParams
  ): Promise<DiscountProduct> => {
    const payload:AxiosResponse<{data:DiscountProduct}> = await this.http.put(
        `discounts/${params.discountId}/products/${params.productId}`,
        {product: params.product}
    )
    const response:DiscountProduct = {...payload.data.data,discount:decimalFormatter(payload.data.data.discount)}
    return response
  }

  deleteProductDiscount = async (data: {
    discountId: number
    productId: string | number
  }): Promise<DiscountType> => {
    const payload = await this.http.delete(
      `/discounts/${data.discountId}/products/${data.productId}/remove`
    )
    return payload.data.data
  }
}

export const DiscountsApi = new DiscountsApiService(new BaseHttpServices())
