import React, {InputHTMLAttributes} from "react";
import styled from './checkBox.module.scss';

interface ICheckBox extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
}

const CheckBox = ({label = '', ...rest}: ICheckBox) => {
    return (
        <label className={styled.container}>
            <input {...rest} type="checkbox"/>
            <span className={styled.checkmark}/>
            {label}
        </label>
    );
};

export default CheckBox;
