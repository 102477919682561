import React from 'react';

interface ICodesHeader {
    label?: string
}

export const CodesHeader: React.FC<ICodesHeader> = ({label}) => {
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-5">
                <h1 className="text-exl-primary bold m-0">{label || "Codes"}</h1>
            </div>
        </div>
    );
};

