import { CustomerApi, ISet2Fa, ITwoFactorAuthData } from 'app/api/user-api/customer-api'
import React, {useState } from 'react'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import { Loader } from 'shared/components/loader/loader'
import {Modal} from 'shared/components/modal-base'
import {ACCOUNT_MODAL_NAMES} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'
import { useAccountContext } from 'shared/context/app-accout-context'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import { FormikProps, useFormik } from 'formik'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  one_time_code: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(6, 'Maximum 6 symbols')
    .required('Code is required'),
})

const initialValues = {
  one_time_code: null
}
interface IFormik {
  one_time_code: string
}

export const AddTwoFactorModal = () => {
  const [data, setData] = useState<ITwoFactorAuthData>()
  const [isLoading, setIsLoading] = useState(false)
  const { customer } = useAccountContext()
  const modalState = useStateModalManager(ACCOUNT_MODAL_NAMES.add_two_factor, {
    onBeforeOpen() {
      const onToggleActive = async () => {
        setIsLoading(true)
        try {
          const response = await CustomerApi.getTwoFactorQr(customer.user_id)
          if (response) {
            setData(response.data)
          }
        } catch (error) {
          toast.error(`${error}`)
          handleClose()
        } finally {
          setIsLoading(false)
        }
      }
      onToggleActive()
    },
  })
  
  const set2FaMutation = useMutation<
    ITwoFactorAuthData,
    AxiosError<{error: string}>,
    ISet2Fa
  >(CustomerApi.set2Fa, {
    onSuccess: () => {
      toast.success('Two Factor Authentication is enabled')
      handleClose()
      formik.resetForm()
    },
    onError: (error: AxiosError) => {
      toast.error(error.response.data.message)
    },
  })

  const formik: FormikProps<IFormik> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      await set2FaMutation.mutate({userId: customer.user_id, one_time_password: formik.values.one_time_code})
    },
  })

  const handleAddTwoFactor = () => {
    formik.submitForm()
  }

  const handleClose = () => {
    modalState.close()
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!set2FaMutation.isLoading}>
      <div className='p-7'>
        <h2 className='text-center text-exl-primary'>Add 2FA for account</h2>
        <div className='d-flex flex-column gap-3 my-5 position-relative'>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <figure className='text-center m-0 h-sm-330px'>
                <img
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    data && data.qr
                  )}`}
                  className='object-fit-contain'
                  height={330}
                  width={330}
                  alt='QR Code'
                />
              </figure>
              <span className='text-exl-primary text-center fs-3 mb-4'>
                Manual code: <strong>{data && data.secret}</strong>
              </span>
            </>
          )}
          <Input
            {...formik.getFieldProps('one_time_code')}
            type='text'
            placeholder='Enter one-time code'
            error={
              formik.touched.one_time_code && !!formik.errors.one_time_code
            }
            errorText={formik.errors.one_time_code}
            disabled={set2FaMutation.isLoading}
          />
        </div>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={set2FaMutation.isLoading}
            isLoading={set2FaMutation.isLoading}
          />
          <Button
            label='Add'
            mainButton
            onClick={handleAddTwoFactor}
            disabled={set2FaMutation.isLoading}
            isLoading={set2FaMutation.isLoading}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
