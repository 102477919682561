import React, {ReactNode} from 'react';
import {ButtonList} from "shared/components/button-list/button-list";
import {Button} from "shared/components/button/button";
import {IMeta} from "shared/types/api-types/api-types";
import {Loader} from "shared/components/loader/loader";
import {Pagination} from "../../../components/pagination/pagination";

interface ICardStatisticProps {
    title: string,
    isLoading: boolean
    meta?: IMeta
    children: ReactNode,
    onOpenFilters?: () => void
    onPageChange?: (page: number) => void
    onDownload?: () => void

}


const CardStatisticComponent: React.FC<ICardStatisticProps> = (props) => {
    const {
        title,
        children,
        meta,
        isLoading,
        onOpenFilters,
        onPageChange,
        onDownload
    } = props
    return (
        <div className='col-12 col-lg-6 column-gap-0 position-relative'>
            {isLoading && <Loader mode={'blur'}/>}
            <div className="card shadow p-5 h-100">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h3>{title}</h3>
                    <ButtonList>
                        {onDownload && <Button label={'Download CSV'}
                                               mode='text'
                                               onClick={onDownload}
                        />}
                        <Button label={'Filter'}
                                mode='small'
                                onClick={onOpenFilters}
                        />
                    </ButtonList>
                </div>
                {children}
                {meta && <Pagination onPageChange={onPageChange}
                                     className={'mt-3'}
                                     totalCount={meta.total}
                                     currentPage={meta.current_page}
                                     pageSize={meta.per_page}/>}
            </div>

        </div>
    );
};
export const CardStatistic = React.memo(CardStatisticComponent)

