import React, {useState} from 'react'
import {useQuery, useQueryClient} from 'react-query'
import {useNavigate, useParams} from 'react-router-dom'
import {OrderApi} from 'app/api/order-api/order-api'
import {useAuthState} from 'app/store/auth/state'
import {Loader} from 'shared/components/loader/loader'
import {ERROR_ROUTE, ORDERS_ROUTES} from 'shared/config/routes'
import {ORDER_QUERY_KEYS} from 'shared/constants/query-keys'
import {OrderHeader} from '../../components/order-header'
import {ProductsTable} from '../../components/table/order/products-table'
import {DownloadService} from 'shared/services/download-service'
import {toast} from 'react-toastify'
import {CHAT_TEMPLATE_MESSAGES_EVENT, EventBus} from 'shared/utils/event-bus'
import {BlobErrorResponse} from 'shared/helpers/blob-error-response'
import {IOrder} from 'shared/types/order'
export const OrderPage = () => {
  const navigate = useNavigate()
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false)
  const [isCSVLoading, setIsCSVLoading] = useState(false)
  const [isCSVsLoading, setIsCSVsLoading] = useState(false)
  const [loadingItemHash, setLoadingItemHash] = useState(null)
  const {user} = useAuthState()
  const queryClient = useQueryClient()
  const {orderId} = useParams()
  const handleGoBackClick = () => {
    navigate(ORDERS_ROUTES.ORDERS.path)
  }
  const {data: order, isLoading} = useQuery({
    queryKey: [ORDER_QUERY_KEYS.order, orderId],
    queryFn: async () => await OrderApi.getOrderByUser(user, Number(orderId)),
    onSuccess: (data) => {
      EventBus.emit(
        CHAT_TEMPLATE_MESSAGES_EVENT.template_from_order,
        data.number
      )
    },
    onError: () => {
      navigate(ERROR_ROUTE.path)
    },
  })

  const onGetInvoiceClick = async () => {
    try {
      setIsInvoiceLoading(true)
      const response = await OrderApi.getInvoice(user, Number(orderId))
      DownloadService.downloadObjectAsZip(response, `${order.number} invoice`)
      setIsInvoiceLoading(false)
    } catch (error) {
      setIsInvoiceLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const onDownloadCSV = async (hash?: string) => {
    try {
      if (hash) {
        setIsCSVLoading(true)
        setLoadingItemHash(hash)
      } else setIsCSVsLoading(true)
      const response = hash
        ? await OrderApi.getCodesCSV(
            user,
            Number(orderId),
            order.is_new_to_download_exists,
            hash
          )
        : await OrderApi.getCodesCSV(
            user,
            Number(orderId),
            order.is_new_to_download_exists
          )
      DownloadService.downloadObjectAsZip(response, order.number)
      if (order.is_new_to_download_exists) {
        queryClient.setQueryData(
          [ORDER_QUERY_KEYS.order, orderId],
          (prevData: IOrder) => {
            if (hash) {
              const codeId = prevData.codes.findIndex(
                (code) => code.hash === hash
              )
              const code = prevData.codes[codeId]
              code.is_new_to_download_exists = false
              prevData.codes.splice(codeId, 1, code)
              const isNewToDownloadExists = prevData.codes.find(
                (code) => code.is_new_to_download_exists === true
              )
              if (!isNewToDownloadExists)
                return {
                  ...prevData,
                  is_new_to_download_exists: false,
                }
              return prevData
            }
            return {
              ...prevData,
              is_new_to_download_exists: false,
            }
          }
        )
        queryClient.invalidateQueries([ORDER_QUERY_KEYS.order, orderId], {
          exact: true,
        })
      }
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
    } catch (error) {
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const onDownloadAllCSV = async () => {
    try {
      setIsCSVsLoading(true)
      const response = await OrderApi.getCodesCSV(user, Number(orderId), false)
      DownloadService.downloadObjectAsZip(response, order.number)
      queryClient.setQueryData(
        [ORDER_QUERY_KEYS.order, orderId],
        (prevData: IOrder) => {
          prevData.is_new_to_download_exists = false
          prevData.codes.map(code=>{
            if (code.is_new_to_download_exists) code.is_new_to_download_exists=false
            return code
          })
          return prevData
        }
      )
      queryClient.invalidateQueries([ORDER_QUERY_KEYS.order, orderId], {
        exact: true,
      })
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
    } catch (error) {
      setIsCSVsLoading(false)
      setLoadingItemHash(null)
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  if (!order || isLoading) {
    return <Loader />
  }

  return (
    <>
      <OrderHeader
        userId={user}
        order={order}
        isCSVLoading={isCSVsLoading}
        isInvoiceLoading={isInvoiceLoading}
        handleGoBackClick={handleGoBackClick}
        onGetInvoiceClick={onGetInvoiceClick}
        onDownloadCSV={onDownloadCSV}
        onDownloadAllCSV={onDownloadAllCSV}
      />
      <div className='bg-body rounded shadow-sm p-10 mx-auto overflow-hidden'>
        <ProductsTable
          order={order}
          isLoading={isLoading}
          onDownloadCSV={onDownloadCSV}
          isCSVLoading={isCSVLoading}
          loadingItemHash={loadingItemHash}
        />
      </div>
    </>
  )
}
