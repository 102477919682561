import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {brandsTitle} from '../../constants/main'
import {LandingApi, IBrandImage} from 'app/api/landing-api/landing-api'
import styles from './landing-brands.module.scss'
import { useQuery } from 'react-query'
import { LANDING_QUERY_KEYS } from 'shared/constants/query-keys'
import { Loader } from 'shared/components/loader/loader'
import './landing-slider.scss'
import { toast } from 'react-toastify'

const LandingBrands = () => {
  const {isLoading, data, error} = useQuery({
    queryKey: [LANDING_QUERY_KEYS.brands],
    queryFn: async () => {
      const brandsData = await LandingApi.getBrands()
      return brandsData.data
    },
    refetchOnWindowFocus: false,
    onError: (error) => {
      toast.error('Error loading slider images')
    },
  })

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 7000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect:false,
    variableWidth: true,
    centerMode: true,
    lazyLoad:'progressive'
  }
  return (
    <section className={styles['brands']}>
      <div className={styles['brands__container']}>
        <h4 className={styles['brands__title']}>{brandsTitle.title}</h4>
        <div className={styles['slider-overlay']}>
          {isLoading || error ? (
            <Loader mode='blur'/>
          ) : (
          <Slider {...settings}>
            {data.map((slide) => (
              <div key={slide.id} className={styles['brands__slide']}>
                <img
                  src={(slide.image as IBrandImage).original_url}
                  alt={`Brand ${slide.id}`}
                />
              </div>
            ))}
          </Slider>
          )}
        </div>
      </div>
    </section>
  )
}

export default LandingBrands
