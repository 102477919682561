import React from 'react'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {useCreateSupplierPage} from './use-create-supplier-page'
import {PhoneInputField} from 'shared/components/input/phone-input'
import {CountrySelect} from 'shared/components/select/country-select'

export const CreateSupplierPage = () => {
  const {models, commands} = useCreateSupplierPage()
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <div className='d-flex align-items-center'>
          <Button
            label='<'
            type='button'
            className='min-w-25px '
            onClick={commands.handleBackClick}
            disabled={models.isLoading}
            isLoading={models.isLoading}
          />
          <h1 className='text-exl-primary bold m-0 px-3'>Create Supplier</h1>
        </div>
        <Button
          label={'Create'}
          isLoading={models.isLoading}
          disabled={models.isLoading || models.isDisabledCreateButton}
          mainButton
          onClick={commands.handleCreatePurchase}
        />
      </div>
      <div className='card p-7 position-relative'>
        <label>Supplier Name</label>
        <Input
          {...models.formik.getFieldProps('name')}
          disabled={models.isLoading}
          placeholder='Supplier Name'
          error={models.formik.touched.name && !!models.formik.errors.name}
          errorText={models.formik.errors.name}
        />
        <label>Company Director</label>
        <Input
          {...models.formik.getFieldProps('company_director')}
          disabled={models.isLoading}
          placeholder='Company Director'
          error={
            models.formik.touched.company_director &&
            !!models.formik.errors.company_director
          }
          errorText={models.formik.errors.company_director}
        />
        <label>Company Address</label>
        <Input
          {...models.formik.getFieldProps('company_address')}
          disabled={models.isLoading}
          placeholder='Company Address'
          error={
            models.formik.touched.company_address &&
            !!models.formik.errors.company_address
          }
          errorText={models.formik.errors.company_address}
        />
        <label>City</label>
        <Input
          {...models.formik.getFieldProps('city')}
          disabled={models.isLoading}
          placeholder='City'
          error={models.formik.touched.city && !!models.formik.errors.city}
          errorText={models.formik.errors.city}
        />
        <label>Country</label>
        <CountrySelect
          placeholder='Enter Country'
          {...models.formik.getFieldProps('country_id')}
          name='country_id'
          onChange={commands.handleCountrySelect}
          value={models.formik.values.country_id}
          isDisabled={models.isLoading}
          isLoading={models.isLoadingRegions}
          error={
            models.formik.touched.country_id &&
            !!models.formik.errors.country_id
          }
          errorText={models.formik.errors.country_id}
        />
        <label>Zip Post Code</label>
        <Input
          {...models.formik.getFieldProps('zip_post_code')}
          disabled={models.isLoading}
          placeholder='Zip Post Code'
          error={
            models.formik.touched.zip_post_code &&
            !!models.formik.errors.zip_post_code
          }
          errorText={models.formik.errors.zip_post_code}
        />
        <label>Website</label>
        <Input
          {...models.formik.getFieldProps('website')}
          disabled={models.isLoading}
          placeholder='Website'
          error={
            models.formik.touched.website && !!models.formik.errors.website
          }
          errorText={models.formik.errors.website}
        />
        <label>VAT Number</label>
        <Input
          {...models.formik.getFieldProps('VAT_number')}
          disabled={models.isLoading}
          placeholder='VAT Number'
          error={
            models.formik.touched.VAT_number &&
            !!models.formik.errors.VAT_number
          }
          errorText={models.formik.errors.VAT_number}
        />
        <label>Company Registration Number</label>
        <Input
          {...models.formik.getFieldProps('company_registration_number')}
          disabled={models.isLoading}
          placeholder='Company Registration Number'
          error={
            models.formik.touched.company_registration_number &&
            !!models.formik.errors.company_registration_number
          }
          errorText={models.formik.errors.company_registration_number}
        />
        <label>Email</label>
        <Input
          {...models.formik.getFieldProps('main_email')}
          disabled={models.isLoading}
          placeholder='Email'
          error={
            models.formik.touched.main_email &&
            !!models.formik.errors.main_email
          }
          errorText={models.formik.errors.main_email}
        />
        <label>Account Payable Contact</label>
        <PhoneInputField
          country={'us'}
          id={'account_payable_contact'}
          inputClass={'w-100'}
          placeholder='Enter Account Payable Contact'
          buttonClass={'d-block'}
          disabled={models.isLoading}
          {...models.formik.getFieldProps('account_payable_contact')}
          onChange={(value) =>
            commands.handlePhoneInput('account_payable_contact', value)
          }
          error={
            models.formik.touched.account_payable_contact &&
            !!models.formik.errors.account_payable_contact
          }
          errorText={models.formik.errors.account_payable_contact}
        />
        <label>Account Payable Email</label>
        <Input
          {...models.formik.getFieldProps('account_payable_email')}
          disabled={models.isLoading}
          placeholder='Account Payable Email'
          error={
            models.formik.touched.account_payable_email &&
            !!models.formik.errors.account_payable_email
          }
          errorText={models.formik.errors.account_payable_email}
        />
        <label>Phone Number</label>
        <PhoneInputField
          country={'us'}
          id={'phone_number'}
          inputClass={'w-100'}
          placeholder='Enter Phone Number'
          buttonClass={'d-block'}
          disabled={models.isLoading}
          {...models.formik.getFieldProps('phone_number')}
          onChange={(value) => commands.handlePhoneInput('phone_number', value)}
          error={
            models.formik.touched.phone_number &&
            !!models.formik.errors.phone_number
          }
          errorText={models.formik.errors.phone_number}
        />
      </div>
    </>
  )
}
