import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import styles from './ForgotPassword.module.scss'
import { ForgotPasswordFormInner } from '../../../../modules/auth/pages/forgot-password/forgot-password-inner'
import { useNavigate } from 'react-router-dom'
import { AUTH_ROUTES } from 'shared/config/routes'
import {useAuth} from "modules/auth/hooks/use-auth";
import {useIntl} from "react-intl";

const initialValues = {
  email: '',
}

export type NavigationStateDTO = {
  email:string;
}

export interface IFormikProps{
  email:string;
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate()
  const {sendForgotPasswordMutation} = useAuth()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      sendForgotPasswordMutation.mutate(values.email)
    },
  })

  const navigateToCodeCheckPage = ({email}:NavigationStateDTO) => {
    navigate(AUTH_ROUTES.CODE_CHECK.path,{state:{email}})
  }

  useEffect(()=>{
    if(sendForgotPasswordMutation.data){
      navigateToCodeCheckPage(formik.values)
    }
  },[sendForgotPasswordMutation.isSuccess])

  return (
    <form
      className='form mx-auto '
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center'>
        <h1 className={clsx('fw-bolder mt-5',styles.header, styles.textPrimaryDark)}>
          {intl.formatMessage({id: 'EXL.AUTH.FORGOT_PASS.TITLE'})}
        </h1>
        <div className={clsx('fw-semibold fs-6 my-11',styles.textPrimaryDark)}>
          {intl.formatMessage({id: 'EXL.AUTH.FORGOT_PASS.DESCRIPTION'})}
        </div>
      </div>

      {formik.status || sendForgotPasswordMutation.error  && (
        <div className='mb-lg-15 text-center m-auto w-sm-300px alert alert-danger'>
          <div className='font-weight-bold'>
            {sendForgotPasswordMutation.error?.response?.data?.message}
          </div>
        </div>
      )}

      <ForgotPasswordFormInner formik={formik} isLoading={sendForgotPasswordMutation.isLoading}/>
    </form>
  )
}
