import React, { useEffect, useMemo, useState } from "react";
import { IPurchaseList, PurchaseApi } from "app/api/purchases-api/purchases-api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { ButtonList } from "shared/components/button-list/button-list";
import { Button } from "shared/components/button/button";
import { Modal } from "shared/components/modal-base";
import { PURCHASES_MODAL_NAME } from "shared/constants/modal-names";
import { PURCHASES_QUERY_KEYS } from "shared/constants/query-keys";
import { useStateModalManager } from "shared/context/modal-manager";
import { AxiosError } from "axios";
import { useLocation } from "react-router-dom";
import { removeEmptyFields } from "shared/utils/remove-empty-fields";

export const ConfirmDeletePurchaseModal = () => {
  const [purchaseId, setPurchaseId] = useState<number>()
  const modalState = useStateModalManager(PURCHASES_MODAL_NAME.confirm_delete_purchase, {
    onBeforeOpen: (purchaseId: number) => {
      setPurchaseId(purchaseId)
    }
  })

  const queryClient = useQueryClient()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const queryParams = useMemo(() => {
    return removeEmptyFields({
      search: search.get('search'),
      supplier: Number(search.get('supplier')) || null,
      status: Number(search.get('status')) || null,
      currency: Number(search.get('currency')) || null,
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])
  const deletePurchase = useMutation<
    string,
    AxiosError<{message:string}>,
    number
  >(PurchaseApi.deletePurchase,
    {
      onSuccess: (data, variable) => {        
        queryClient.setQueryData(
          [PURCHASES_QUERY_KEYS.purchases,queryParams],
          (prevData:IPurchaseList) => {
            const tempPurchases = prevData.data.filter(
              (purchase) => purchase.id !== variable
            )
            return {...prevData, data: tempPurchases}
          }
        )
        queryClient.invalidateQueries([PURCHASES_QUERY_KEYS.purchases,queryParams])
        toast.success(`Purchase was deleted successfully!`)
      },
      onError: (error:AxiosError<{message:string}>) => {
        toast.error(error?.response.data.message)
      },
    }
  )

  const isDeletePurchaseLoading = deletePurchase.isLoading

  const handleDeletePurchase = (purchaseId: number) => {
    deletePurchase.mutate(purchaseId)
  }

  const handleConfirmDeleteClick = () => {
    handleDeletePurchase(purchaseId)
  }

  const handleClose = () => {
    modalState.close()
  }

  useEffect(() => {
    handleClose()
    deletePurchase.reset()
  }, [deletePurchase.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!isDeletePurchaseLoading}
    >
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to delete this purchase?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={isDeletePurchaseLoading}
          />
          <Button
            label='Delete'
            mode='remove'
            isLoading={isDeletePurchaseLoading}
            disabled={isDeletePurchaseLoading}
            onClick={handleConfirmDeleteClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}