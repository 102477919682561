import React from 'react'
import {CalculationLine} from '../calculation-line/calculation-line'
import {
  DiscountProduct,
  DiscountProductKeys,
  IDiscountCodes,
} from '../../../../../shared/types/discount-types'
import {FormikErrors, FormikTouched} from 'formik'
import {IValidationProductDiscountError} from '../create-product-discount/validation-methods'
import clsx from 'clsx'

interface ICodesPriceProps {
  codesGroup: IDiscountCodes
  isDisabled?: boolean
  isErrors?: boolean
  onChangeCodsSellPrice: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeProductDiscount: (
    name: DiscountProductKeys,
    value: number | string
  ) => void
}
const CodesPriceComponent: React.FC<ICodesPriceProps> = ({
  codesGroup,
  isDisabled,
  onChangeProductDiscount,
  isErrors,
  onChangeCodsSellPrice,
}) => {
  return (
    <div
      className={clsx('border-top d-flex align-items-center py-5  flex-wrap', {
        'border-danger': isErrors,
      })}
    >
      <p className='me-10 mb-0'>Quantity: {codesGroup.quantity || 0}</p>
      <CalculationLine
        onChangeSellPrice={onChangeCodsSellPrice}
        onChangeProductDiscount={onChangeProductDiscount}
        isDisabled={isDisabled}
        discount={codesGroup.discount}
        purchasePrice={codesGroup.purchase_price}
        type={Number(codesGroup.type)}
        sellPrice={codesGroup.sell_price || null}
      />
    </div>
  )
}

export const CodesPrice = React.memo(CodesPriceComponent)
