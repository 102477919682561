import * as React from 'react'
import {forwardRef, InputHTMLAttributes, useId, useMemo} from 'react'
import {Form} from 'react-bootstrap'
import ExternalSelect, {GroupBase, SelectInstance, components, SingleValue, DropdownIndicatorProps} from 'react-select'
import styles from './select.module.scss'
import {Icon} from '../icon/icon'
import {ICON_COLLECTION} from '../icon/icon-list'
import {SELECT_STYLE_CONFIG} from "./select-style";

export interface IOption {
    id: number,
    label: string
}

export interface SelectProps {
    value?: number
    className?: string
    placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder']
    options: IOption[]
    isDisabled?: boolean
    isLoading?: boolean
    error?: boolean
    errorText?: React.ReactNode
    isClearable?: boolean
    name?: InputHTMLAttributes<HTMLInputElement>['name']
    autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus']
    tabIndex?: InputHTMLAttributes<HTMLInputElement>['tabIndex']
    onClick?: InputHTMLAttributes<HTMLInputElement>['onClick']
    onChange?: ((newValue: SingleValue<IOption>) => void)
    onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur']
    onFocus?: InputHTMLAttributes<HTMLInputElement>['onFocus']
    handleInputChange?: (inputValue: string) => void;
}

const DropdownIndicator: React.FC<DropdownIndicatorProps<IOption, false, GroupBase<IOption>>> = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon icon={ICON_COLLECTION.arrowDown}/>
            </components.DropdownIndicator>
        )
    );
};

const RootSelect: React.ForwardRefRenderFunction<
    SelectInstance<IOption, false, GroupBase<IOption>>,
    SelectProps
> = (
    {
        value,
        className,
        placeholder,
        error,
        options,
        isDisabled = false,
        isLoading,
        isClearable,
        errorText,
        name,
        autoFocus,
        onChange,
        onBlur,
        onFocus,
        handleInputChange
    },
    ref
) => {
    const id = useId()
    const selectedValue = useMemo(() => {
        if (!value) return null
        return options?.find(option => option.id === value);
    }, [options, value]);
    const stylesSelect = SELECT_STYLE_CONFIG(errorText, error)
    return (
        <>
            <ExternalSelect
                ref={ref}
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator
                }}
                isDisabled={isDisabled}
                isLoading={isLoading}
                defaultValue={options?.find((option) => option.id === value)}
                options={options}
                classNamePrefix="select"
                isClearable={isClearable}
                id={id}
                inputId={id}
                value={selectedValue}
                className={className}
                autoFocus={autoFocus}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onInputChange={handleInputChange}
                styles={stylesSelect}
            />
            {error && errorText && (
                <Form.Control.Feedback className={styles.error} type='invalid'>
                    {errorText}
                </Form.Control.Feedback>
            )}
        </>
    )
}

export const Select = forwardRef(RootSelect)
