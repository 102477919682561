import React, {useState} from 'react'
import {Modal} from 'shared/components/modal-base'
import {useStateModalManager} from 'shared/context/modal-manager'
import {STATISTIC_MODAL_NAME} from 'shared/constants/modal-names'
import {useSelectController} from 'shared/hooks/use-select-controller'
import {
  CATEGORIES_QUERY_KEYS,
  CODES_QUERY_KEYS,
} from 'shared/constants/query-keys'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {DatePickerDialog} from 'shared/components/day-picker/day-picker'
import {IOption, Select} from 'shared/components/select/select'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {IStatisticFilters} from 'shared/types/api-types/statistic-type'
import {FILTER_VIEW_CONDITIONS} from './filter-view-conditions'
import {EventBus} from 'shared/utils/event-bus'

interface IBeforeParams {
  key: string
  params: IStatisticFilters
}

const METHODS_OPTIONS = [
  {
    id: 1,
    label: 'WEB',
  },
  {
    id: 2,
    label: 'API',
  },
]

export const StatisticFilter = () => {
  const [key, setKey] = useState(null)
  const [params, setParams] = useState<IStatisticFilters>(null)
  const modalState = useStateModalManager(
    STATISTIC_MODAL_NAME.filter_statistics,
    {
      onBeforeOpen: (data: IBeforeParams) => {
        setKey(data.key)
        setParams(data.params)
      },
    }
  )
  const {
    options: baseCurrencies,
    isLoading: isLoadingCurrencies,
    formationOptions: optionsCurrencies,
  } = useSelectController({
    key: CODES_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
  })
  const {
    isLoading: isLoadingCategory,
    handleInputChange: handleInputChangeCategory,
    formationOptions: categories,
  } = useSelectController({
    key: CATEGORIES_QUERY_KEYS.categories,
    Fn: SelectsApi.getCategories,
    params: 'name',
  })
  const {
    isLoading: isLoadingProduct,
    handleInputChange: handleInputChangeProduct,
    formationOptions: productsOptionsMemo,
  } = useSelectController({
    key: CODES_QUERY_KEYS.product,
    Fn: SelectsApi.getProducts,
    params: 'name',
  })
  const getCurrenciesSymbol = (id: number | null) => {
    if (!id) return null
    const symbol = baseCurrencies.find((item) => item.id === id)
    if (!symbol) return null
    return symbol.symbol
  }
  const handleClose = () => {
    setParams(null)
    setKey(null)
    modalState.close()
  }
  const handleChangeSelects = (name: string) => (value: IOption | null) => {
    setParams((prevState) => {
      return {
        ...prevState,
        [name]: value?.id,
        symbol: name === 'currency' ? getCurrenciesSymbol(value?.id) : '',
      }
    })
  }
  const handleChangeDate = (startDate: string, endDate: string) => {
    setParams((prevState) => {
      return {
        ...prevState,
        start_date: startDate
          ? startDate.replace(/\//g, '-')
          : prevState.start_date,
        end_date: endDate ? endDate.replace(/\//g, '-') : prevState.end_date,
      }
    })
  }

  const onSearch = () => {
    EventBus.emit(key, params)
    handleClose()
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose}>
      <div className='p-7 position-relative'>
        {FILTER_VIEW_CONDITIONS.dayPicker.includes(key) && (
          <DatePickerDialog
            from={params.start_date}
            to={params.end_date}
            handleDateChangeFormValue={handleChangeDate}
            isRequired={true}
            className='mb-5'
          />
        )}
        {FILTER_VIEW_CONDITIONS.currency.includes(key) && (
          <Select
            value={params.currency}
            placeholder={'Currency'}
            onChange={handleChangeSelects('currency')}
            options={optionsCurrencies}
            className='mb-5'
            isLoading={isLoadingCurrencies}
          />
        )}
        {FILTER_VIEW_CONDITIONS.category.includes(key) && (
          <Select
            options={categories}
            value={params.category}
            isClearable
            onChange={handleChangeSelects('category')}
            handleInputChange={handleInputChangeCategory}
            placeholder={'category'}
            isLoading={isLoadingCategory}
            className='mb-5'
          />
        )}
        {FILTER_VIEW_CONDITIONS.product.includes(key) && (
          <Select
            options={productsOptionsMemo}
            value={params.product}
            placeholder={'Product'}
            isClearable
            onChange={handleChangeSelects('product')}
            handleInputChange={handleInputChangeProduct}
            isLoading={isLoadingProduct}
            className='mb-5'
          />
        )}
        {FILTER_VIEW_CONDITIONS.method.includes(key) && (
          <Select
            options={METHODS_OPTIONS}
            value={params.method}
            isClearable
            onChange={handleChangeSelects('method')}
            placeholder={'Method'}
            className='mb-5'
          />
        )}
        <ButtonList className='justify-content-end'>
          <Button label={'Cancel'} onClick={handleClose} />
          <Button label={'Apply'} mainButton onClick={onSearch} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
