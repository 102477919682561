import React from 'react'
import { CustomersTable } from '../components/table/customers-table'
import { Pagination } from 'modules/dashboard/components/pagination/pagination'
import { CustomersSearchBar } from '../components/customers-search-bar'
import { useCustomersPage } from './use-customers-page'
import { Button } from 'shared/components/button/button'
import { RemoveCustomerModal } from '../components/modals/remove-customer-modal'

export interface ICustomersInitValue {
    company_name?: string,
    status?: string
    page: number
}

export type initialFormName = 'company_name' | 'status' | 'cart'

export const CustomersPage = () => {
    const { models, commands } = useCustomersPage();
  
    return (
      <>
        <div className='d-flex justify-content-between align-items-center mb-5'>
          <h1 className='text-exl-primary bold m-0'>Customers</h1>
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
        </div>
        <div className='card p-7'>
          <CustomersSearchBar
            searchValue={models.searchValue}
            statusValue={models.values.status}
            cartValue={models.values.cart}
            handleChangeFormValue={commands.handleChangeFormValue}
            setSearchValue={commands.setSearchValue}
          />
          <CustomersTable
            customers={models.customers}
            isLoading={models.isTableLoading}
          />
          {models.customers && models.customers.length ? (
            <Pagination
              currentPage={Number(models.values.page)}
              totalCount={models.meta.total}
              pageSize={models.meta.per_page}
              onPageChange={commands.setCurrentPage}
            />
          ) : null}
        </div>
        <RemoveCustomerModal />
      </>
    )
}


