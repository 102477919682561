import React, {useEffect, useState} from 'react';
import {CardStatistic} from "../card-statistic/card-statistic";
import {IStatisticFilters} from "shared/types/api-types/statistic-type";
import {useQuery} from "react-query";
import {STATISTICS_QUERY_KEYS} from "shared/constants/query-keys";
import {StatisticsApi} from "app/api/dashboard-api/statistics-api";
import {INIT_PARAMS} from "./top-selling";
import {STATISTIC_MODAL_NAME} from "shared/constants/modal-names";
import {useModalManager} from "shared/context/modal-manager";
import {EventBus, STATISTIC_FILERS_EVENTS} from "shared/utils/event-bus";
import {DownloadService} from "../../../../../shared/services/download-service";
import {toast} from "react-toastify";
import { thousandFormatter } from 'shared/helpers/formatter';

export const LowStock = () => {
    const [params, setParams] = useState<IStatisticFilters>(INIT_PARAMS)
    const modalManager = useModalManager();
    const [isCSVLoading, setIsCSVLoading] = useState(false);
    const stockQuery = useQuery({
        queryKey: [STATISTICS_QUERY_KEYS.low_stock, params],
        queryFn: () => StatisticsApi.getLowStock(params),
        onError: () => {
            toast.error('Something went wrong with Low Stock table')
        },
    })
    const stockList = stockQuery.data?.data ?? []
    const onOpenFilters = () => {
        modalManager.open(STATISTIC_MODAL_NAME.filter_statistics, {
            key: STATISTIC_FILERS_EVENTS.low_stock,
            params,
        });
    };
    const onPageChange = (page) => {
        setParams(prevState => {
            return {...prevState, page}
        })
    }
    const onDownloadCSV = async () => {
        try {
            setIsCSVLoading(true)
            const response = await StatisticsApi.getLowStock({...params, csv: 1})
            await DownloadService.downloadObjectAsZip(response, 'Low Stock CSV')
            setIsCSVLoading(false)
        } catch (error) {
            setIsCSVLoading(false)
            toast.error('Download CSV Error!')
        }
    }
    useEffect(() => {
        EventBus.on(STATISTIC_FILERS_EVENTS.low_stock, (data) => setParams({...data, page: 1}))
        return () => {
            EventBus.off(STATISTIC_FILERS_EVENTS.low_stock, (data) => setParams({...data, page: 1}))
        };
    }, []);


    return (
        <CardStatistic title={'Low Stock'}
                       onDownload={onDownloadCSV}
                       onPageChange={onPageChange}
                       onOpenFilters={onOpenFilters}
                       isLoading={stockQuery.isLoading || isCSVLoading}
                       meta={stockQuery.data?.meta ?? null}>
            <table className='table gs-3 gy-4 gx-3 text-exl-primary position-relative'>
                <thead>
                <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
                    <th>Product</th>
                    <th className='text-end'>Count</th>
                </tr>
                </thead>
                <tbody>

                {stockList.length ? stockList.map((item, index) =>
                        <tr className='border-bottom' key={item.id}>
                            <td>
                                {item.name || <span className='text-gray-500'>No Name</span>}
                            </td>
                            <td className='text-end'>{thousandFormatter(item.count)}</td>
                        </tr>
                    )
                    : (
                        <td colSpan={2}>Nothing was found</td>
                    )
                }
                </tbody>
            </table>
        </CardStatistic>
    );
};

