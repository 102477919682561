import {IFile} from 'shared/types/user'
import { File } from '../file/file'

interface IFileList {
  documentList: IFile[]
}

export const FileList: React.FC<IFileList> = ({documentList}) => {  
  return (
    <>
      <div className='d-flex justify-content-around justify-content-sm-start flex-row flex-wrap gap-10'>
        {documentList.length ? (
          documentList.map((file: IFile) =>             
            <File key={file.id} file={file}/>            
          )
        ) : 
           <span className='text-muted'>Files not uploaded yet!</span>
        }        
      </div>
    </>
  )
}
