import {createContext, useContext} from "react";
import {ICustomer} from "../types/user";

export const AccountContext = createContext<{
    customer: ICustomer;
}>({
    customer: null,
});

export const useAccountContext = () => useContext(AccountContext);

