import * as React from 'react';
import clsx from "clsx";
import styles from './ButtonList.module.scss'

interface ButtonListProps {
    className?: string;
}

export const ButtonList: React.FC<React.PropsWithChildren<ButtonListProps>> = ({
                                                                                   className,
                                                                                   children,
                                                                               }) => {
    return <div className={clsx(className, styles['button-list'])}>{children}</div>;
};
