import React from "react";
import { Button } from "shared/components/button/button"

interface IApiHeader {
  handleCreateKeyClick: () => void
  handleApiDocumentation: () => void
}

export const ApiHeader: React.FC<IApiHeader> = ({
  handleCreateKeyClick,
  handleApiDocumentation,
}) => {
  return (
    <div className='d-md-flex justify-content-between align-items-start mb-5'>
      <h1 className='text-exl-primary mb-5 mb-md-0'>My Account</h1>
      <div className='d-flex flex-column flex-md-row gap-5'>
        <Button
          label='API Documentation'
          type='button'
          onClick={handleApiDocumentation}
        />
        <Button
          label='New API Key'
          type='button'
          mainButton
          onClick={handleCreateKeyClick}
        />
      </div>
    </div>
  )
}