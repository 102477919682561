import heroSLiderOne from 'shared/assets/images/hero-slider-1.jpg'
import heroSLiderTwo from 'shared/assets/images/hero-slider-2.jpg'
import heroSLiderThree from 'shared/assets/images/hero-slider-3.jpg'
import heroSLiderFour from 'shared/assets/images/hero-slider-4.jpg'
import heroSLiderFive from 'shared/assets/images/hero-slider-5.jpg'
import accordionImageOne from 'shared/assets/images/accordion-image-1.jpg'
import accordionImageTwo from 'shared/assets/images/accordion-image-2.jpg'
import accordionImageThree from 'shared/assets/images/accordion-image-3.jpg'
import brandsImg from 'shared/assets/images/benefit-brands-img.svg'
import gameImg from 'shared/assets/images/benefit-game-img.svg'
import studiosImg from 'shared/assets/images/benefit-studios-img.svg'
import retailersImg from 'shared/assets/images/benefit-retailers-img.svg'
import brandsBg from 'shared/assets/images/benefit-bg-brands.svg'
import gameBg from 'shared/assets/images/benefit-bg-game.svg'
import studiosBg from 'shared/assets/images/benefit-bg-studios.svg'
import retailersBg from 'shared/assets/images/benefit-bg-retailers.svg'
import PortalImageOne from 'shared/assets/images/portal-image-1.png'
import PortalImageTwo from 'shared/assets/images/portal-image-2.png'
import ApiImageOne from 'shared/assets/images/api-image-1.png'
import ApiImageTwo from 'shared/assets/images/api-image-2.png'
import ManualImage from 'shared/assets/images/manual.jpg'
import {AUTH_ROUTES} from 'shared/config/routes'

export const heroText = {
    title: 'Incentive Digital',
    titleMotivate: 'Motivate & Reward Your Employees.',
    subtitle:
        'We are passionate about recognizing and motivating individuals and organizations through our comprehensive rewards and incentives solutions.',
}

export const heroSliderImages = [
    {
        id: '1',
        image: heroSLiderOne,
    },
    {
        id: '2',
        image: heroSLiderThree,
    },
    {
        id: '3',
        image: heroSLiderFive,
    },
    {
        id: '4',
        image: heroSLiderFour,
    },
    {
        id: '5',
        image: heroSLiderTwo,
    },
]

export const offerText = {
    title: 'Our solutions',
    subtitle:
        'With a deep understanding of human psychology and the power of positive reinforcement, we have created a platform that drives engagement, loyalty, and achievement.',
}
export const accordionOffer = [
    {
        id: 1,
        title: 'Rewards & Incentives',
        text: 'Boost performance, engagement, and loyalty with our dynamic rewards and incentives programs. From employee recognition to customer loyalty initiatives, we provide tailored solutions that inspire and motivate. Achieve remarkable results and foster a culture of excellence with our cutting-edge offerings.',
        image: accordionImageOne,
    },
    {
        id: 2,
        title: 'Gift Cards & eGifts',
        text: 'Experience the convenience and flexibility of eGifts with our innovative platform. Delight your recipients with instant digital gifts that can be personalized and redeemed at their convenience. Whether it`s for employee rewards, customer appreciation, or special occasions, our eGifts are the perfect way to show your appreciation and make a lasting impression.',
        image: accordionImageTwo,
    },
    {
        id: 3,
        title: 'Gaming solutions',
        text: 'Level up your gaming experience with our cutting-edge gaming solutions. Provide your employees with the games they love with a comprehensive range of innovative products and services designed to enhance your employees gaming environment. From immersive games & solutions, we offer tailored solutions for individual gamers, esports teams, and gaming venues. Elevate your gameplay, engage with a vibrant community, and unleash your full gaming potential with our state-of-the-art gaming solutions.',
        image: accordionImageThree,
    },
]

export const benefitTitle = {
    title: 'Who benefits',
}
export const benefitText = [
    {
        id: 'brands',
        title: 'Brands',
        image: brandsImg,
        bg: brandsBg,
        imgPosition: {
            top: '7%',
            right: '30%',
            width: '50%',
        },
    },
    {
        id: 'game',
        title: 'Game Publishers',
        image: gameImg,
        bg: gameBg,
        imgPosition: {
            top: '7%',
            right: '10%',
            width: '35%',
        },
    },
    {
        id: 'studios',
        title: 'Studios',
        image: studiosImg,
        bg: studiosBg,
        imgPosition: {
            top: '0',
            right: '5%',
            width: '35%',
        },
    },
    {
        id: 'retailers',
        title: 'Retailers',
        image: retailersImg,
        bg: retailersBg,
        imgPosition: {
            top: '15%',
            right: '5%',
            width: '35%',
        },
    },
]

export const portalTitle = {
    title: 'Business hub',
}
export const portalText = [
    {
        id: '1',
        text: 'The gift card industry and gaming industry are both significant players in the global market. The gift card industry has experienced substantial growth, with a wide range of industries utilizing gift cards as a popular gifting option. ',
        image: PortalImageOne,
    },
    {
        id: '2',
        text: 'From retail and e-commerce to hospitality and entertainment, gift cards offer convenience and flexibility for both givers and recipients. The industry continues to thrive as consumers increasingly embrace the convenience of digital gift cards, allowing for instant delivery and easy redemption. The gaming industry, on the other hand, has witnessed explosive growth and has become a multi-billion dollar global phenomenon.',
        image: PortalImageTwo,
    },
]

export const discoverTitle = {
    title: 'Discover The Incentive Digital Platform',
}
export const discoverCard = [
    {
        id: '1',
        title: 'Partners',
        text: `Join forces with us and unlock new horizons of success! As a trusted partner, we offer collaborative opportunities that drive mutual growth and prosperity. Leverage our expertise, innovative solutions, and extensive network to expand your reach and achieve your business objectives. Together, we can create powerful synergies and seize exciting opportunities in the ever evolving market landscape. Let's embark on a journey of partnership and unlock boundless potential together.`,
        pathBtn: 'contact',
        button: 'Contact Us',
    },
    {
        id: '2',
        title: 'Retailers',
        text: `Elevate your retail offerings with our premium products! As a trusted supplier, we provide retailers with a curated selection of high-quality digital products designed to captivate customers and drive sales. From trending consumer goods to innovative technologies, our diverse product catalog offers something for every shopper. Partner with us to enhance your product lineup, attract new customers, and stay ahead of the competition. Let us be your preferred supplier and together we'll create retail experiences that leave a lasting impression.`,
        pathBtn: AUTH_ROUTES.REGISTRATION.path,
        button: 'Register',
    },
]

export const apiContent = {
    api: {
        id: 1,
        title: 'API',
        text: 'Empower your business with our robust digital delivery API. Seamlessly integrate our powerful solution into your existing systems and unlock a world of possibilities. With our API, you can effortlessly distribute digital content, deliver real-time notifications, and streamline your digital workflows. Experience secure, reliable, and scalable digital delivery that enhances customer experiences and accelerates business growth. Simplify your operations and stay ahead in the digital era with our cutting-edge digital delivery API.',
        image: ApiImageOne,
    },
    manual: {
        id: 2,
        title: 'Manual',
        text: 'Revolutionize your digital delivery process with our manual digital delivery solution. Say goodbye to complex automation and embrace a streamlined approach that puts you in control. With our user-friendly platform, you can effortlessly deliver digital content, files, or products directly to your customers with just a few clicks. Enjoy the flexibility of personalization and customization, ensuring a tailored experience for each recipient. Simplify your workflow, save time, and enhance customer satisfaction with our efficient manual digital delivery solution.',
        image: ManualImage,
    },
}

export const walletContent = {
    id: 2,
    title: 'Wallet',
    text: 'Experience seamless financial management with our wallet system and invoice solution. Our robust wallet system offers a secure and convenient way to store and manage funds, making transactions a breeze. With our intuitive interface, users can easily track their balances, make payments, and view funds hassle-free. Additionally, our comprehensive invoice system simplifies the billing process, allowing you to view and store professional invoices with ease. Streamline your financial operations, improve cash flow, and enhance your business efficiency with our integrated wallet system and invoice solution.',
    image: ApiImageTwo,
}

export const brandsTitle = {
    title: 'Our Brands:',
}

export const contactTitle = {
    title: 'Contact Us',
    subtitle: 'Unsure want to find out more contact us below and find out how we can really help you',
}

export const placeholderTexts = {
    fullName: '* Full Name',
    companyName: '* Company Name',
    website: 'Your Website',
    email: '* Email',
    contactNumber: 'Contact Number',
    dropdown: 'Select an Option',
    shortDescription: 'Short Description',
    button: 'Send Form',
}