import React from 'react'
import {FormikProps} from 'formik'
import {
  ICustomer,
  ICustomerRestrictions,
  ICustomerSettings,
} from 'shared/types/user'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {CustomerFormHeader} from '../components/forms/customer-form-header'
import {useAccount} from 'modules/dashboard/account/hooks/use-account'
import {DeclineWarningModal} from '../components/modals/decline-warning-modal'
import {CUSTOMER_ROUTES} from '../constants/routes'
import { RemoveCustomerModal } from '../components/modals/remove-customer-modal'

interface ICustomerLayout {
  children: React.ReactNode
  formik:
    | FormikProps<ICustomer>
    | FormikProps<ICustomerSettings>
    | FormikProps<ICustomerRestrictions>
  isEditing?: boolean
  isEditable?: boolean
  isLoading?: boolean
  toggleEditMode?: React.MouseEventHandler<HTMLButtonElement>
}

export const CustomerLayout: React.FC<ICustomerLayout> = ({
  children,
  formik,
  isEditing,
  isEditable = false,
  isLoading = false,
  toggleEditMode,
}) => {
  const {customer} = useAccount()
  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='d-md-flex justify-content-between align-items-center mb-5 flex-wrap gap-5'>
          <CustomerFormHeader
            isEditing={isEditing}
            isEditable={isEditable}
            isLoading={isLoading}
            toggleEditMode={toggleEditMode}
          />
        </div>
        <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
          <TabMenu routes={CUSTOMER_ROUTES(customer)} />
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {children}
        </div>
      </form>
      <DeclineWarningModal />
      <RemoveCustomerModal />
    </>
  )
}
