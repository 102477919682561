import {FormikProps, useFormik} from 'formik'
import {useEffect, useState} from 'react'
import isEqual from 'lodash/isEqual'
import {AccountLayout} from '../../account-layout'
import {ICustomerSettings} from 'shared/types/user'
import {useAccount} from '../../hooks/use-account'
import {AccountFormSettings} from '../../components/forms/account-form-settings'
import {accountSettingsSchema} from '../../utils/account-settings-schema'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import { useNavigate } from 'react-router-dom'
import { DOCUMENTATION_ROUTES } from 'shared/config/routes';

export const AccountSettingsPage = () => {
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = useState(false)
  const {updateCustomerCredentialsMutation, settingsValues, customer} =
    useAccount()

  const toggleEditMode = () => {
    formik.resetForm()
    setIsEditing((prevValue) => !prevValue)
  }
  useEffect(() => {
    setIsEditing(false)
    formik.resetForm()
    updateCustomerCredentialsMutation.reset()
  }, [updateCustomerCredentialsMutation.isSuccess])

  const formik: FormikProps<ICustomerSettings> = useFormik({
    initialValues: settingsValues,
    validationSchema: accountSettingsSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        if (!isEqual(removeEmptyFields(values), settingsValues))
          updateCustomerCredentialsMutation.mutate({
            userId: customer.user_id,
            params: removeEmptyFields(values),
          })
        else setIsEditing(false)
      } catch (error) {
        console.error(error)
        setStatus('Something is went wrong! Try again later.')
        setSubmitting(false)
      }
    },
  })

  const toggleIsOrderEmailsEnabled = () => {
    formik.setFieldValue(
      'is_order_email_enabled',
      !formik.values.is_order_email_enabled
    )
  }
  const toggleIsInvoiceEmailsEnabled = () => {
    formik.setFieldValue(
      'is_invoice_email_enabled',
      !formik.values.is_invoice_email_enabled
    )
  }

  const handleRedirectToDocs = () => {
    navigate(DOCUMENTATION_ROUTES.ROOT.path)
  }

  return (
    <AccountLayout
      formik={formik}
      isEditing={isEditing}
      isEditable
      toggleEditMode={toggleEditMode}
      isLoading={updateCustomerCredentialsMutation.isLoading}
      isSettingsPage={true}
      handleRedirectToDocs={handleRedirectToDocs}
    >
      <AccountFormSettings
        formik={formik}
        isEditing={isEditing}
        handleToggleInvoiceEnabled={toggleIsInvoiceEmailsEnabled}
        handleToggleOrderEnabled={toggleIsOrderEmailsEnabled}
      />
    </AccountLayout>
  )
}
