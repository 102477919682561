import React, { useEffect, useState } from 'react';
import {Modal} from "../../../../shared/components/modal-base";
import {useStateModalManager} from "../../../../shared/context/modal-manager";
import {DASHBOARD_MODAL_NAMES} from "../../../../shared/constants/modal-names";
import {ButtonList} from "../../../../shared/components/button-list/button-list";
import {Button} from "../../../../shared/components/button/button";
import { useAccount } from 'modules/dashboard/account/hooks/use-account';

export const DeleteFileModal = () => {    
    const {deleteFileMutation, customer} = useAccount();        
    const [fileId,setFileId] = useState<number|null>(null);      
    const modalState = useStateModalManager(DASHBOARD_MODAL_NAMES.delete_file,{
        onBeforeOpen:(selectedFileId:number) => {       
            setFileId(selectedFileId);
        },
    });    
    const handleDeleteFile = () => {
        if (!fileId) return null
        deleteFileMutation.mutate({userId:customer.user_id,fileId});
    }
    const handleClose = () => modalState.close()

    useEffect(() => {
        if (deleteFileMutation.isSuccess) {
            modalState.close()
        }
    }, [deleteFileMutation.isSuccess])
    return (
        <Modal.Root 
            open={modalState.open} 
            onClose={handleClose}
            isClosable={!deleteFileMutation.isLoading}
        >
            <div className="p-5">
                <h2 className='text-center mb-5'>Delete File?</h2>
                <p className='text-exl-primary my-10 text-center'>
                    Are you sure for deleting this file?
                </p>
                <ButtonList className='justify-content-end'>
                    <Button label='Cancel' onClick={handleClose} disabled={deleteFileMutation.isLoading}/>
                    <Button label='OK' mainButton isLoading={deleteFileMutation.isLoading} disabled={deleteFileMutation.isLoading} onClick={handleDeleteFile}/>
                </ButtonList>
            </div>
        </Modal.Root>
    );
};

