import {selectTemporaryUserId} from '../../../app/store/temporary-customer/selects'
import {selectUserData} from '../../../app/store/user/selects'

export const useTemporaryUser = () => {
  const temporaryUserIdState = selectTemporaryUserId()
  const userData = selectUserData()

  return {
    temporaryUserIdState,
    temporaryUserId: temporaryUserIdState ? temporaryUserIdState : userData.id,
  }
}
