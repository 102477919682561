import React, {useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ISlider, ISliderDeleteDTO, OptionsApi} from 'app/api/options-api/options-api'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'

interface DeleteSliderImageModalPayload {
  slug: string
  id: number
}

export const DeleteSliderImageModal = () => {
  const [payload, setPayload] = useState<ISliderDeleteDTO>(null)
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.delete_slider_image, {
    onBeforeOpen: ({slug, id}: DeleteSliderImageModalPayload) => {
      setPayload({slug, selected_model: id})
    },
  })

  const handleClose = () => {
    setPayload(null)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const deleteSliderMutation = useMutation<
    ISlider,
    AxiosError<{message: string}>,
    ISliderDeleteDTO
  >(OptionsApi.deleteSliderImages, {
    onSuccess: (data) => {
      queryClient.setQueryData([OPTIONS_QUERY_KEYS.sliders], (prevData: ISlider[]) => {
        const regionIndex = prevData.findIndex((region) => region.id === data.id)
        prevData.splice(regionIndex, 1, data)
        return prevData
      })
      queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.sliders])
      toast.success(`Image from slider ${data.slug} deleted successfully!`)
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })

  const handleDeleteImage = () => {
    deleteSliderMutation.mutate(payload)
  }

  useEffect(() => {
    handleClose()
    deleteSliderMutation.reset()
  }, [deleteSliderMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!deleteSliderMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Delete Image</h2>
        <p className='text-exl-primary my-10 text-center'>Are you sure for delete this image?</p>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} disabled={deleteSliderMutation.isLoading} />
          <Button
            label='OK'
            mainButton
            isLoading={deleteSliderMutation.isLoading}
            disabled={deleteSliderMutation.isLoading}
            onClick={handleDeleteImage}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
