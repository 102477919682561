import {createRoot} from 'react-dom/client'
// Axios
import {axios} from './app/config/axios/axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider, useQueryClient} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import * as Sentry from '@sentry/react'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'
import React, {useEffect} from 'react'
import {App} from './app/App'
import {LocalStorageService} from './shared/services/local-storage-service'
import {useAuthState} from './app/store/auth/state'
import {ERROR_ROUTE} from './shared/config/routes'
import {ModalManager} from 'shared/context/modal-manager'
import {SENTRY_PROJECT_ID, SENTRY_PUBLIC_KEY} from 'shared/config/variables'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})
axios.interceptors.response.use(
  (res) => {
    const token = res.headers?.authorization
    if (token) {
      LocalStorageService.set('token', 'Bearer ' + token)
    }
    return res
  },
  (error) => { 
    if (error.response?.status === 401) {
      useAuthState.getState().setUser(null, null)
      LocalStorageService.remove('token')
      LocalStorageService.remove('userId')
    } 
    if (error.response?.status === 403) {
      window.location.href = ERROR_ROUTE.path;
    }
    return Promise.reject(error)
  }
)

Sentry.init({
  dsn: `https://${SENTRY_PUBLIC_KEY}@sentry.groupbwt.com/${SENTRY_PROJECT_ID}`,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.replayCanvasIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root')

if (container) {
  ;(function () {
    const savedToken = LocalStorageService.get('token')
    const userId = LocalStorageService.get('userId')
    if (savedToken && userId) {
      useAuthState.getState().setUser(savedToken, userId)
    }
  })()

  createRoot(container).render(
    <ModalManager.Provider>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <BrowserRouter>
            {/*<AuthProvider>*/}
            <App />
            {/*</AuthProvider>*/}
          </BrowserRouter>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ModalManager.Provider>
  )
}
