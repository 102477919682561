import React from 'react';
import clsx from 'clsx';
import { LoadWrapper } from 'shared/components/load-wrapper/load-wrapper';
import { AllWalletsTableRow } from './all-wallets-table-row';
import { IWallet } from 'shared/types/api-types/wallets-type';

export interface IWalletsTable{
  wallets:IWallet[]
  isLoading:boolean
}

export const AllWalletsTable: React.FC<IWalletsTable> = ({ wallets, isLoading }) => {
  return (
    <div className={clsx('card-body px-0 table-responsive', {'py-3':!isLoading})}>
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
              <th>Company Name</th>
              <th>Currency</th>
              <td>Total Money Added</td>
              <th>Used Amount</th>
              <td>Remain Amount</td>
              <th>Credit limit</th>
              <th>Actions</th>
            </tr>
          </thead>
          {wallets && wallets.length ? (
            <tbody>
              {wallets.map((wallet: IWallet) => (
                <AllWalletsTableRow key={wallet.id} wallet={wallet} />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={5}>No wallets found with this filters.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}


