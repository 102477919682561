import {Params, generatePath} from "react-router-dom";

interface ICreateCodesFromPurchases extends Params<string>{
    product_id?:string
    product_name?:string
    purchase_price?:string
    currency_id?:string
    supplier_id?:string
    purchase_id:string
}

export const LANDING_ROUTES = {
    ROOT: {
        title: 'Home',
        path: '/'
    }
}

export const AUTH_ROUTES = {
    ROOT: {
        path: '/auth',
    },
    LOGIN: {
        path: '/auth/login',
    },
    REGISTRATION: {
        path: '/auth/registration',
    },
    FORGOT_PASSWORD: {
        path: '/auth/forgot-password',
    },
    CODE_CHECK: {
        path: '/auth/forgot-password/code-check',
    },
    RESET_PASSWORD: {
        path: '/auth/forgot-password/reset',
    },
    TWO_FACTOR_AUTH: {
        path: '/auth/login/two-factor-auth'
    }
};
export const CODES_ROUTES = {
    UPLOAD: {
        path: '/codes/:id',
        generate: (id: string) => generatePath('/codes/:id', {id}),
    },
    CREATE_CODES: {
        title: "Upload History",
        path: '/codes/upload',
        pathFromPurchases:(params:ICreateCodesFromPurchases)=>{
            const path = '/codes/upload?'+                
                Object.keys(params).map(key => {
                    return (params[key] && params[key].length!==0 )?(encodeURIComponent(key) + '=' + encodeURIComponent(params[key])):""
                }).filter(element => element!=='').join('&')
            return path;
        }
    },
    UPLOAD_HISTORY: {
        title: "Upload History",
        path: '/codes/upload_history',
        generate: (id: string) => generatePath('/codes?uploads_history=:id', { id }),
        redirectToErrorCodes : (id: string) => generatePath('/codes/error_codes?uploads_history=:id', {id})
    },
    ERROR_CODES: {
        title: "Error Codes",
        path: '/codes/error_codes',
        redirectToUploadHistory : (id: string) => generatePath('/codes/upload_history?uploads_history=:id', {id})
    }
}

export const CUSTOMERS_ROUTES = {
    CUSTOMERS: {
        title: "Customers",
        path: '/customers',
    },
    CUSTOMER: {
        title: 'Main information',
        path: '/customers/:customerId',
        generate: (customerId: string) => generatePath('/customers/:customerId', {customerId}),
    },
    CUSTOMER_FILES: {
        title: 'Files',
        path: '/customers/:customerId/files',
        generate: (customerId: string) => generatePath('/customers/:customerId/files', {customerId}),
    },
    CUSTOMER_WALLETS: {
        title: 'Wallets',
        path: '/customers/:customerId/customer-wallets',
        generate: (customerId: string) => generatePath('/customers/:customerId/customer-wallets', {customerId}),
    },
    API: {
        title: 'API',
        path: '/customers/:customerId/api-keys',
        generate: (customerId: string) => generatePath('/customers/:customerId/api-keys', {customerId}),
    },
    SETTINGS: {
        title: 'Settings',
        path: '/customers/:customerId/settings',
        generate: (customerId: string) => generatePath('/customers/:customerId/settings', {customerId}),
    },
    RESTRICTIONS: {
        title: 'Restrictions',
        path: '/customers/:customerId/restrictions',
        generate: (customerId: string) => generatePath('/customers/:customerId/restrictions', {customerId}),
    },
    TWO_FACTOR: {
        title: 'Two Factor Authentication',
        path: '/customers/:customerId/two-factor',
        generate: (customerId: string) => generatePath('/customers/:customerId/two-factor', {customerId}),
    }
}

export const ORDERS_ROUTES = {
    ORDERS: {
        title: 'My Orders',
        path: '/orders',
    },
    ALL_ORDERS: {
        title: 'All Orders',
        path: '/all-orders',
    },
    ALL_ORDERS_ORDER: {
        title: 'Order',
        path: '/all-orders/:userId/orders/:orderId',
        generate: (userId:string,orderId: string) => generatePath('/all-orders/:userId/orders/:orderId', {userId,orderId}),
    },
    ORDER: {
        title: 'Order',
        path: '/orders/:orderId',
        generate: (orderId: string) => generatePath('/orders/:orderId', {orderId}),
    },
    USER_ORDER: {
        title: 'Order',
        path: '/all-orders/:userId/orders/:orderId',
        generate: (userId: string, orderId: string) =>
            generatePath('/all-orders/:userId/orders/:orderId', {userId, orderId}),
    },
}

export const DASHBOARD_ROUTES = {
    CATALOG: {
        title: 'Catalog',
        path: '/catalog',
    },
    ORDERS: {
        title: 'Orders',
        path: '/orders',
    },
    ALL_ORDERS: {
        title: 'Orders',
        path: '/all-orders',
    },
    ALL_INVOICES: {
        title: 'Invoices',
        path: '/all-invoices',
    },
    INVOICES: {
        title: 'Invoices',
        path: '/invoices',
    },
    ALL_WALLETS: {
        title: 'Wallets',
        path: '/wallets',
    },
    // WALLET: {
    //     title: 'Wallet',
    //     path: '/wallets',
    // },
    CODES: {
        title: 'Codes',
        path: '/codes',
    },
    ACCOUNT: {
        title: 'Account',
        path: '/account',
    },
    CUSTOMERS: {
        title: 'Customers',
        path: '/customers',
    },
    PURCHASE_TRACKER: {
        title: 'Purchase Tracker',
        path: '/purchase-tracker',
    },
    SUPPLIER_TRACKER: {
        title: 'Supplier Tracker',
        path: '/supplier-tracker'
    },
    STATISTIC: {
        title: 'Dashboard',
        path: '/dashboard',
    }
}
export const PRODUCT_ROUTES = {
    PRODUCT: {
        title: "Product",
        path: '/catalog/products',
    },
    CREATE_PRODUCT: {
        title: "Product",
        path: '/catalog/products/create',
    },
    SINGLE_PRODUCT: {
        path: '/catalog/products/:productId',
        generate: (productId: string) => generatePath('/catalog/products/:productId', {productId}),
    },
}


export const ACCOUNT_ROUTES = {
    MAIN: {
        title: "Main information",
        path: DASHBOARD_ROUTES.ACCOUNT.path,
    },
    FILES: {
        title: "Files",
        path: '/account/files',
    },
    WALLETS: {
        title: "Wallets",
        path: '/wallets/my-wallets',
    },
    API: {
        title: "API",
        path: '/account/api',
    },
    SETTINGS: {
        title: "Settings",
        path: '/account/settings',
    },
    TWO_FACTOR: {
        title: "Two Factor Authentication",
        path: '/account/two-factor',
    }
}

export const CART_ROUTE = {
    title: 'Cart',
    path: '/cart/:cartId',
    generate: (cartId: string) => generatePath('/cart/:cartId', {cartId}),
}

export const OPTIONS_ROUTES = {
    ROOT: {
        title: 'Options',
        path: '/options'
    },
    REGIONS: {
        title: 'Regions',
        path: '/options'
    },
    CURRENCIES: {
        title: 'Currencies',
        path: '/options/currencies'
    },
    BRANDS: {
        title: 'Brands',
        path: '/options/brands'
    },
    SLIDERS: {
        title: 'Sliders',
        path: '/options/sliders'
    },
    ADMIN_SETTINGS: {
        title: 'Admin Settings',
        path: '/options/admin-settings'
    },
}


export const ERROR_ROUTE = {
    title: '404 page',
    path: '/error/404'
}

export const PURCHASES_ROUTES = {
    PURCHASES: {
        ...DASHBOARD_ROUTES.PURCHASE_TRACKER
    },
    PURCHASE: {
        path: '/purchase-tracker/:purchaseId',
        generate: (purchaseId: string) => generatePath('/purchase-tracker/:purchaseId', {purchaseId}),
    }

}
export const SUPPLIER_ROUTES = {
  SUPPLIERS_TRACKER: {
    ...DASHBOARD_ROUTES.SUPPLIER_TRACKER,
  },
  SUPPLIER: {
    path: '/supplier-tracker/:productId',
    generate: (productId: string) =>
      generatePath('/supplier-tracker/:productId', {productId}),
  },
  SUPPLIERS: {
    title: 'Suppliers',
    path: '/supplier-tracker/suppliers',
  },
  CREATE_SUPPLIER: {
    path: '/supplier-tracker/suppliers/create',
  },
  SINGLE_SUPPLIER: {
    path: '/supplier-tracker/suppliers/:supplierId',
    generate: (supplierId: string) =>
      generatePath('/supplier-tracker/suppliers/:supplierId', {supplierId}),
  },
}
export const DISCOUNT_ROUTES = {
    ROOT: {
        title: 'Discounts',
        path: '/discounts'
    },
    DISCOUNT: {
        path: '/discounts/:discountId',
        generate: (discountId: string) => generatePath('/discounts/:discountId', {discountId}),
    },
    CREATE: {
        title: 'Create Discounts',
        path: '/discounts/create'
    }
}

export const DOCUMENTATION_ROUTES = {
    ROOT: {
        path: '/api-documentation'
    }
}

