import React, {ChangeEvent, useEffect} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {OptionsApi} from 'app/api/options-api/options-api'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {IBrand} from 'app/api/landing-api/landing-api'
import imgMoc from 'shared/assets/images/placehoder.jpg'
import {UploadImage} from 'modules/dashboard/catalog/components/upload-image/upload-image'
import { validationSchema } from 'modules/dashboard/options/schemas/brand-validation-schema'

const INITIAL_VALUES = {
  name: '',
  image: null,
  original_url: null,
}

export const CreateBrandModal = () => {
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.create_brand)
  const queryClient = useQueryClient()

  const createBrandMutation = useMutation<
    IBrand,
    AxiosError<{message: string}>,
    IBrand
  >(OptionsApi.createBrand, {
    onSuccess: () => {
      queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.brands])
      formik.resetForm()
      toast.success('Brand created successfully!')
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })

  const onSubmit = (values: IBrand) => {
    createBrandMutation.mutate(values)
  }  

  const formik = useFormik<IBrand>({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validationSchema,
  })

  const handleClose = () => {
    formik.resetForm()
    modalState.close()
  }

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('name', event.target.value)
  }
  const setImage = (newPreset: string | null) => {
    if (!newPreset) return    
    formik.setFieldValue(
      'original_url',
      newPreset?.includes('image') ? newPreset : null
    )
  }
  const onUpload = (file: File) => {    
    if (!file) return    
    formik.setFieldTouched('image')
    formik.setFieldValue('image', file)
  }
  const handleCreateClick = () => {
    formik.submitForm()
  }

  useEffect(() => {
    handleClose()
    createBrandMutation.reset()
  }, [createBrandMutation.isSuccess])

  const isDisabled =
    createBrandMutation.isLoading || !formik.values.name || !formik.values.image || !!formik.errors.image || !!formik.errors.name

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!createBrandMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center'>Create Brand</h2>
        <div className='my-10'>
          <div>
            <label>Name</label>
            <Input
              {...formik.getFieldProps('name')}
              value={formik.values.name}
              type='text'
              placeholder='Name'
              error={formik.touched.name && !!formik.errors.name}
              errorText={formik.errors.name}
              onChange={handleNameChange}
            />
          </div>
          <div>
            <label>Image</label>
            <div className='position-relative h-100px w-225px'>
              <UploadImage
                setImage={setImage}
                onUpdateFile={onUpload}
                mode='only-edit'
              />
              <figure className='text-center'>
                <img
                  src={
                    formik.values.original_url
                      ? formik.values.original_url
                      : imgMoc
                  }
                  alt='Brand'
                  className={clsx(
                    'object-fit-contain',
                    formik.touched.image && !!formik.errors.image && 'border border-danger'
                  )}
                  height={100}
                  width={200}
                />
              </figure>
              {(formik.touched.image && !!formik.errors.image && (formik.values.image as File) ) && (
                <p className='text-danger'>{`${formik.errors.image}`}</p>
              )}
            </div>
          </div>
        </div>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={createBrandMutation.isLoading}
          />
          <Button
            label='OK'
            mainButton
            type='button'
            isLoading={createBrandMutation.isLoading}
            disabled={isDisabled}
            onClick={handleCreateClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
