import React from 'react';
import clsx from "clsx";
import styled from './Pagination.module.scss'
import {Icon} from "../../../../shared/components/icon/icon";
import {ICON_COLLECTION} from "../../../../shared/components/icon/icon-list";
import {DOTS, usePagination} from "../../../../shared/hooks/use-pagination";

interface IPagination {
    onPageChange: (number) => void
    totalCount: number
    siblingCount?: number
    currentPage: number
    pageSize: number
    className?: string
}

const PaginationComponent: React.FC<IPagination> = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;
   
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });
    const dotIdxs = paginationRange.map((page,index)=>{
        if (page===DOTS) return index
        return null
    })
    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };    
    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul
            className={clsx(styled['pagination-container'], {[className]: className})}
        >
            <button onClick={onPrevious} className={clsx(styled.button, styled['pagination-item'], {
                [styled['disabled']]: currentPage === 1
            })}><Icon icon={ICON_COLLECTION.pageLeft}/></button>
            {paginationRange.map((pageNumber,index) => {
                if (pageNumber === DOTS) {                    
                    return <li key={`${pageNumber}-dots-${dotIdxs[index]}`} className={clsx(styled['pagination-item'], styled.dots)}>&#8230;</li>;
                }
                return (
                    <li
                        key={`${pageNumber}-key`}
                        className={clsx(styled['pagination-item'], {
                            [styled['selected']]: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(pageNumber)}
                    >
                        {pageNumber}
                    </li>
                );
            })}

            <button onClick={onNext} className={clsx(styled['pagination-item'], styled.button, {
                [styled['disabled']]: currentPage === lastPage
            })}><Icon icon={ICON_COLLECTION.pageRight}/></button>
        </ul>
    );
};

export const Pagination = React.memo(PaginationComponent);
