import React from 'react';
import { DatePickerDialog } from 'shared/components/day-picker/day-picker';
import { Icon } from 'shared/components/icon/icon';
import { ICON_COLLECTION } from 'shared/components/icon/icon-list';
import { Input } from 'shared/components/input/input';
import { IOption, Select } from 'shared/components/select/select';
import { initialFormName } from '../pages/invoices-page';

interface IInvoicesSearchBar {
    searchValue:string
    statusValue:number
    sourceValue:number
    typeValue:number
    isLoading:boolean
    handleChangeFormValue:(name: initialFormName, value: IOption)=>void
    handleDateChangeFormValue:(startDate:string,endDate:string) => void
    setSearchValue: (text: string) => void
}

const STATUSES: IOption[] = [
    {
        label: 'Unpaid',
        id: 1
    },
    {
        label: 'Paid',
        id: 2
    },
    {
        label: 'Canceled',
        id: 3
    }
]


const SOURCES: IOption[] = [
    {
        label: 'WEB',
        id: 1
    },
    {
        label: 'API',
        id: 2
    }
]

const TYPES: IOption[] = [
    {
        label: 'Balance deposit',
        id: 1
    },
    {
        label: 'Balance withdrawal',
        id: 2
    },
    {
        label: 'Order invoice',
        id: 3
    },
    {
        label: 'Top-up',
        id: 4
    },
]

const InvoicesSearchBarComponent: React.FC<IInvoicesSearchBar> = (props) => {
    const {
        searchValue,
        statusValue,
        sourceValue,
        typeValue,
        isLoading,
        handleChangeFormValue,
        handleDateChangeFormValue,
        setSearchValue    
    } = props

    return (
        <div className="mb-5">
            <div className="row align-items-center row-cols-1 row-cols-sm-3 row-cols-lg-5 position-relative">
                <div className="col mb-5">
                    <Input 
                        startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch}/>}
                        placeholder='Search'
                        value={searchValue}
                        onChange={event=>setSearchValue(event.target.value)}
                    />
                </div>               
                <div className="col mb-5">
                    <DatePickerDialog 
                        handleDateChangeFormValue={handleDateChangeFormValue}
                    />                   
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable={!isLoading}
                        options={SOURCES} 
                        value={sourceValue}
                        onChange={option => handleChangeFormValue('source', option)}
                        placeholder='Source'
                    />
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable={!isLoading}
                        options={TYPES} 
                        value={typeValue}
                        onChange={option => handleChangeFormValue('type', option)}
                        placeholder='Type'
                    />
                </div>
                <div className="col mb-5">
                    <Select                        
                        isClearable={!isLoading}
                        options={STATUSES} 
                        value={statusValue}
                        onChange={option => handleChangeFormValue('status', option)}
                        placeholder='Status'
                    />                    
                </div>
            </div>
        </div> 
    )
}

export const InvoicesSearchBar = React.memo(InvoicesSearchBarComponent)