import React, {useState} from 'react'
import {Modal} from 'shared/components/modal-base'
import {useStateModalManager} from 'shared/context/modal-manager'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {CartApi, ICartParamsDTO} from 'app/api/cart-api/cart-api'
import {toast} from 'react-toastify'
import {useUserState} from 'app/store/user/state'
import {useCartState} from 'app/store/cart/state'
import {EventBus, GLOBAL_EVENTS} from '../../../../shared/utils/event-bus'
import {ICart} from 'shared/types/cart'
import {useTemporaryUser} from '../../hooks/use-temporary-user'

interface IPreOrderOnBeforeParams {
  quantity: number
  productId: number
}

export const PreOrderWarningModal = () => {
  const [payload, setPayload] = useState<IPreOrderOnBeforeParams | null>(null)
  const {setIsCartHasProducts, setProductsInCartQuantity} = useUserState()
  const {setCart} = useCartState()
  const {temporaryUserId} = useTemporaryUser()
  const modalState = useStateModalManager(
    DASHBOARD_MODAL_NAMES.pre_order_warning,
    {
      onBeforeOpen: (data: IPreOrderOnBeforeParams) => {
        setPayload(data)
      },
    }
  )
  const addToCartMutation = useMutation<
    ICart,
    AxiosError<{error: string}>,
    {userId: number; params: ICartParamsDTO}
  >(CartApi.addToCart, {
    onSuccess: (data) => {
      setIsCartHasProducts(true)
      setCart(data)
      const productsInCart = data.codes.reduce(
        (sum, code) => sum + (code.quantity as number),
        0
      )
      setProductsInCartQuantity(productsInCart)
      modalState.close()
      toast.success('Added to Cart')
      EventBus.emit(GLOBAL_EVENTS.reset_products)
      EventBus.emit(GLOBAL_EVENTS.reset_groups_codes)
    },
    onError: (error) => {
      toast.error(error?.response.data.error)
    },
  })
  const onSubmit = () => {
    addToCartMutation.mutate({
      userId: temporaryUserId,
      params: {
        quantity: payload.quantity,
        product_id: payload.productId,
        product_hash: payload.productId.toString(),
      },
    })
  }

  const handleClose = () => modalState.close()
  return (
    <Modal.Root
      open={modalState.open}
      isClosable={!addToCartMutation.isLoading}
      onClose={handleClose}
    >
      <div className='p-5'>
        <p className='text-exl-primary mb-5'>
          For all pre ordered products once it becomes in stock again the system
          will automatically deduct the funds from your wallet and deliver you
          the voucher codes if the funds are not available you will get notified
          by email
        </p>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={addToCartMutation.isLoading}
          />
          <Button
            label='OK'
            disabled={addToCartMutation.isLoading}
            isLoading={addToCartMutation.isLoading}
            mainButton
            onClick={onSubmit}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
