import {RouteObject} from "react-router-dom";
import {OPTIONS_ROUTES} from "shared/config/routes";
import {RoleGuard} from "../guards/role-guard";
import {RouteGuardActions, RouteGuardEntities} from "../../casl/ability";
import {OptionsLayout} from "../layouts/options-layout";
import {RegionsOptions} from "../../modules/dashboard/options/pages/regions-options/regions-options";
import { CurrenciesOptions } from "modules/dashboard/options/pages/currencies-options/currencies-options";
import { BrandsOptions } from "modules/dashboard/options/pages/brands/brands-options";
import { SlidersOptions } from "modules/dashboard/options/pages/sliders/sliders-options";
import { AdminSettingsOptions } from "modules/dashboard/options/pages/admin-settings/admin-settings-options";

export const optionsRoutes: RouteObject[] = [
    {
        element: <RoleGuard I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}> <OptionsLayout/> </RoleGuard>,
        children: [
            {
                path: OPTIONS_ROUTES.REGIONS.path,
                element: <RegionsOptions/>
            },
            {
                path: OPTIONS_ROUTES.CURRENCIES.path,
                element: <CurrenciesOptions/>
            },
            {
                path: OPTIONS_ROUTES.BRANDS.path,
                element: <BrandsOptions/>
            },
            {
                path: OPTIONS_ROUTES.SLIDERS.path,
                element: <SlidersOptions/>
            },
            {
                path: OPTIONS_ROUTES.ADMIN_SETTINGS.path,
                element: <AdminSettingsOptions/>
            },
        ],
    },
]