import React, {useState} from 'react';
import {Modal} from "../../../../../shared/components/modal-base";
import {ButtonList} from "../../../../../shared/components/button-list/button-list";
import {Button} from "../../../../../shared/components/button/button";
import {useNavigate} from "react-router-dom";
import {useStateModalManager} from "../../../../../shared/context/modal-manager";
import {CATALOG_MODAL_NAMES, DASHBOARD_MODAL_NAMES} from "../../../../../shared/constants/modal-names";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {CatalogApi, ICodesGroupItem, IDeleteCodeGroupParams} from "../../../../../app/api/dashboard-api/catalog-api";
import {toast} from "react-toastify";
import {EventBus, GLOBAL_EVENTS} from "../../../../../shared/utils/event-bus";

export const RemoveGroupCodes = () => {
    const [codesData, setCodesData] = useState(null);
    const modalState = useStateModalManager(CATALOG_MODAL_NAMES.remove_group_codes, {
        onBeforeOpen: (data: IDeleteCodeGroupParams) => {
            setCodesData(data)
        }
    });
    const handleClose = () => modalState.close()
    const deleteGroupCodesMutation = useMutation<
        ICodesGroupItem[],
        AxiosError<{ message: string }>,
        IDeleteCodeGroupParams>(CatalogApi.deleteCodesGroup, {
        onSuccess: (data) => {
            EventBus.emit(GLOBAL_EVENTS.reset_groups_codes, data)
            toast.success(`Successfully removed !`)
            modalState.close()
        },
        onError: (error) => {
            toast.error(error?.response.data.message)
        }
    })
    const handelRemoveProduct = () => {
        if (codesData) {
            deleteGroupCodesMutation.mutate(codesData)
        }
    }
    return (
        <Modal.Root open={modalState.open} onClose={handleClose} isClosable={!deleteGroupCodesMutation.isLoading}>
            <div className="p-5 text-center">
                <p className='text-exl-primary mb-10'>
                    Are you sure you want to remove this codes ?
                </p>
                <ButtonList className='justify-content-end'>
                    <Button label='Cancel'
                            onClick={handleClose}
                            disabled={deleteGroupCodesMutation.isLoading}
                            isLoading={deleteGroupCodesMutation.isLoading}/>
                    <Button label='Remove'
                            mode={'remove'}
                            disabled={deleteGroupCodesMutation.isLoading}
                            isLoading={deleteGroupCodesMutation.isLoading}
                            onClick={handelRemoveProduct}/>
                </ButtonList>
            </div>

        </Modal.Root>
    );
};

