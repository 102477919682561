import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  full_name: Yup.string()
    .min(2, 'Must be at least 2 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('This field is required'),
  company_name: Yup.string()
    .min(2, 'Must be at least 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('This field is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Must be at least 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Invalid URL!'
    )
    .min(7, 'URL must be at least 7 symbols'),
  phone: Yup.string()
    .min(5, 'Phone number must be at least 5 symbols')
    .max(20, 'Phone number is too long'),
  text: Yup.string()
    .max(255, 'Text is too long'),
})
