import {BaseHttpServices} from "shared/services/base-http-services";
import {IUser} from "shared/types/user";
import {AxiosError, AxiosResponse} from "axios";


export interface RegisterDataDto {
    email: string;
    company_name: string;
    password: string;
    password_confirmation: string;
}

export interface LoginDataDto {
    email: string;
    password: string;
}

export interface IAuthResult {
    token: string,
    user: IUser
}
export interface IResetPasswordDto {
    code: string,
    password: string,
    password_confirmation: string,
}

export interface I2Fa {
  data: string
  token: string
}
export class AuthApiService {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    register = async (
        data: RegisterDataDto,
    ): Promise<{ data: IAuthResult }> => {
        const payload = await this.http.post(`/auth/register`, data, undefined);
        return payload.data;
    };

    login = async (data: LoginDataDto): Promise<{data: IAuthResult }> => {
        const payload = await this.http.post<
            AxiosError<{ error: string; status: number }>,
            AxiosResponse<{data: IAuthResult }>
        >(`/auth/login`, data, undefined);

        return payload.data;
    };


    forgotPassword = async (data: string): Promise<string> => {
        const payload = await this.http.post(`/auth/forgot-password/send`, {email: data});
        return payload.data;
    };

    codeCheck = async (data: string): Promise<string> => {
        const payload = await this.http.post(`/auth/forgot-password/code-check`, {code: data});
        return payload.data;
    };
    resetPassword = async (data: IResetPasswordDto): Promise<string> => {
        const payload = await this.http.post(`/auth/forgot-password/reset`, {...data});
        return payload.data;
    };

    twoFactorAuth = async ({data, token}: I2Fa): Promise<string> => {
    const payload = await this.http.post(`/auth/2fa`, null, {
      params: {one_time_password: data},
      headers: {Authorization: `Bearer ${token}`},
    })
    return payload.data;
};


}

export const AuthApi = new AuthApiService(new BaseHttpServices());