import {useDebounce} from '_metronic/helpers'
import {SuppliersApi} from 'app/api/suppliers-api/suppliers-api'
import {useEffect, useMemo, useState} from 'react'
import {useQuery} from 'react-query'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {SUPPLIER_ROUTES} from 'shared/config/routes'
import {SUPPLIERS_MODAL_NAME} from 'shared/constants/modal-names'
import {SUPPLIERS_TRACKER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useModalManager} from 'shared/context/modal-manager'
import {BlobErrorResponse} from 'shared/helpers/blob-error-response'
import {DownloadService} from 'shared/services/download-service'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'

export const useSuppliersPage = () => {
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const modalManager = useModalManager()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(search.get('name'))

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      name: search.get('name'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      name: queryParams.name ? {name: queryParams.name} : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const suppliersQuery = useQuery({
    queryKey: [SUPPLIERS_TRACKER_QUERY_KEYS.all_suppliers, queryParams],
    queryFn: async () => await SuppliersApi.getALLSuppliers(queryParams),
    refetchOnWindowFocus: false,
  })

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('name', debouncedSearchTerm)
    setSearchParams({
      ...params.name,
    })
  }, [debouncedSearchTerm])

  const setCurrentPage = (page: number) => {
    const params = manageParams('page', page)
    setSearchParams({
      ...params.name,
      ...params.page,
    })
  }

  const onCreateSupplierClick = () => {
    navigate(SUPPLIER_ROUTES.CREATE_SUPPLIER.path)
  }

  const handleDeleteSupplier = (supplierId) => {
    modalManager.open(SUPPLIERS_MODAL_NAME.delete_supplier, supplierId)
  }

  const handleViewSingleSupplier = (supplierId: string) => {
    navigate(SUPPLIER_ROUTES.SINGLE_SUPPLIER.generate(supplierId))
  }

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        name: queryParams.name,
      }
      const csvData = await SuppliersApi.getSuppliersCSV(params)
      DownloadService.downloadObjectAsZip(csvData, `Suppliers Report`)
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const isTableLoading = suppliersQuery.isLoading
  return {
    models: {
      values: queryParams,
      suppliers: suppliersQuery.data ? suppliersQuery.data.data : [],
      meta: suppliersQuery.data ? suppliersQuery.data.meta : null,
      isTableLoading,
      searchValue,
      isCSVLoading,
    },
    commands: {
      setCurrentPage,
      setSearchValue,
      onCreateSupplierClick,
      handleDeleteSupplier,
      handleViewSingleSupplier,
      onDownloadCSV,
    },
  }
}
