import React from 'react';
import {Icon} from "shared/components/icon/icon";
import {ICON_COLLECTION} from "shared/components/icon/icon-list";
import styles from './wallet-card.module.scss'
import clsx from "clsx";
import {IWallet} from "../../../../../shared/types/api-types/wallets-type";
import { CurrencyFormatter } from 'shared/helpers/currency-formatter';

interface IWalletCardProps {
    isActive: boolean,
    wallet: IWallet,
    setActiveWallet: (id: number) => void
    handelSetDefault: (id: number) => void
}

const WalletCardComponent: React.FC<IWalletCardProps> = ({isActive, wallet, setActiveWallet, handelSetDefault}) => {
    const onActivate = () => setActiveWallet(wallet.id)

    const onDefault = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        handelSetDefault(wallet.id)
    }

    const isDefaultWallet = wallet.is_default > 0 ? true : false
    return (
        <div className={clsx(styles.card, {[styles['card--active']]: isActive})}
             onClick={onActivate}
        >
            <div className={styles.card__head}>
                <p className={styles.symbol}>{wallet.currency.symbol}</p>
                <p className={styles.card__name}>
                    {wallet.currency.iso} Wallet
                </p>
            </div>
            <div className={styles.card__inner}>
                <div className={styles.balance}>
                    <p className={styles.balance__lable}>Balance:</p>
                    <p className={styles.balance__amout}>{CurrencyFormatter(wallet.amount,wallet.currency)}</p>
                </div>
                <div className={styles.spent}>
                    <p className={styles.spent__lable}>
                        <Icon icon={ICON_COLLECTION.walletArrow}
                              className={styles.spent__icon}/>
                        Total spent:</p>
                    <p className={styles.spent__amout}>{CurrencyFormatter(wallet.total_spent,wallet.currency)}</p>
                </div>
            </div>
            <div className={styles.card__inner}>
                <button className={styles.default}
                        onClick={onDefault}
                >
                    <span className={styles.circle}>
                        {isDefaultWallet && <span/>}
                    </span>
                    <span>Set as default</span>
                </button>
                <p className={styles.credit}>Credit Limit: {CurrencyFormatter(wallet.credit_limit,wallet.currency)}</p>
            </div>
        </div>
    );
};

export const WalletCard = React.memo(WalletCardComponent)
