import React, {useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {CUSTOMER_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {CustomerApi} from 'app/api/user-api/customer-api'
import {useLocation, useNavigate} from 'react-router-dom'
import {DASHBOARD_ROUTES} from 'shared/config/routes'
export const RemoveCustomerModal = () => {
  const [userId, setUserId] = useState<number>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const modalState = useStateModalManager(
    DASHBOARD_MODAL_NAMES.remove_customer,
    {
      onBeforeOpen: (customerId: number) => {
        setUserId(customerId)
      },
    }
  )

  const handleClose = () => {
    setUserId(null)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const removeCustomerMutation = useMutation<
    string,
    AxiosError<{message?: string, error?:string}>,
    number
  >(CustomerApi.removeCustomer, {
    onSuccess: () => {
      navigate(
        location.pathname === DASHBOARD_ROUTES.CUSTOMERS.path
          ? location.pathname + location.search
          : DASHBOARD_ROUTES.CUSTOMERS.path
      )
      queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customers], {
        exact: false,
      })
      setUserId(null)
      toast.success('Customer deleted successfully!')
    },
    onError: (error) => {
      toast.error(error.response.data.message||error.response.data.error)
    },
  })

  const handleRemoveClick = () => {
    removeCustomerMutation.mutate(userId)
  }

  useEffect(() => {
    handleClose()
    removeCustomerMutation.reset()
  }, [removeCustomerMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!removeCustomerMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Remove Customer</h2>
        <p className='text-exl-primary my-10 text-center'>
          Are you sure for remove this customer?
        </p>
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={removeCustomerMutation.isLoading}
          />
          <Button
            label='OK'
            mode='remove'
            isLoading={removeCustomerMutation.isLoading}
            disabled={removeCustomerMutation.isLoading}
            onClick={handleRemoveClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
