import React from 'react';
import { Icon } from 'shared/components/icon/icon';
import { ICON_COLLECTION } from 'shared/components/icon/icon-list';
import { Input } from 'shared/components/input/input';
import { IOption, Select } from 'shared/components/select/select';
import { initialFormName } from '../pages/customers-page';

interface ICustomersSearchBar {
    searchValue:string
    statusValue: number
    cartValue: number
    setSearchValue:(searchText:string)=>void
    handleChangeFormValue: (name: initialFormName, value: IOption) => void
}

const STATUSES: IOption[] = [
    {
        label: 'Approved',
        id: 1
    },
    {
        label: 'Pending',
        id: 2
    },
    {
        label: 'Declined',
        id: 3
    },
    {
        label: 'Blocked',
        id: 4
    },
]

const CART_OPTIONS: IOption[] = [
  {
    label: 'All',
    id: 1,
  },
  {
    label: 'Cart is empty',
    id: 2,
  },
  {
    label: 'Has products',
    id: 3,
  },
]

const CustomersSearchBarComponent: React.FC<ICustomersSearchBar> = (props) => {
    const {
        searchValue,
        statusValue,
        cartValue,
        handleChangeFormValue,
        setSearchValue
    } = props

    return (
        <>
            <div className="row align-items-center row-cols-1 row-cols-sm-2 row-cols-lg-4">
                <div className="col mb-5">
                    <Input 
                        startAdornment={<Icon className='p-0' icon={ICON_COLLECTION.minSearch}/>}
                        placeholder='Search'
                        value={searchValue}
                        onChange={event=>setSearchValue(event.target.value)}
                    />
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={CART_OPTIONS}
                        value={cartValue} 
                        onChange={option => handleChangeFormValue('cart',option)} 
                        placeholder='Cart'
                    />
                </div>
                <div className="col mb-5">
                    <Select
                        isClearable                             
                        options={STATUSES}
                        value={statusValue} 
                        onChange={option => handleChangeFormValue('status',option)} 
                        placeholder='Status'
                    />
                </div>
            </div>
        </>
    )
}

export const CustomersSearchBar = React.memo(CustomersSearchBarComponent)