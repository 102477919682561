import React from 'react'
import {IOption, Select} from 'shared/components/select/select'
import { DatePickerDialog } from 'shared/components/day-picker/day-picker'
import { initialFormName } from '../pages/codes-upload-history/use-upload-history'
import { Input } from 'shared/components/input/input'
import { Icon } from 'shared/components/icon/icon'
import { ICON_COLLECTION } from 'shared/components/icon/icon-list'

interface IUploadHistorySearchBar {
  productsOptions: IOption[]
  suppliersOptions: IOption[]
  isLoadingSupplier: boolean
  isLoadingProduct: boolean
  handleChangeFormValue: (name: initialFormName, value: IOption) => void
  productValue: number
  supplierValue: number
  handleInputChangeSuppliers: (text: string) => void
  handleInputChangeProducts: (text: string) => void
  handleDateChangeFormValue: (startDate: string, endDate: string) => void
  uploadsHistoryValue: string
  setUploadsHistoryValue: (text: string) => void
}

const UploadHistorySearchBarComponent: React.FC<IUploadHistorySearchBar> = (props) => {
  const {
    handleInputChangeProducts,
    handleInputChangeSuppliers,
    productsOptions,
    suppliersOptions,
    isLoadingSupplier,
    isLoadingProduct,
    productValue,
    supplierValue,
    handleChangeFormValue,
    handleDateChangeFormValue,
    uploadsHistoryValue,
    setUploadsHistoryValue,
  } = props

  return (
    <div className='mb-5'>
      <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-6'>
        <div className='col mb-5'>
          <Input
            startAdornment={
              <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
            }
            placeholder='Search'
            value={uploadsHistoryValue}
            onChange={(event) => setUploadsHistoryValue(event.target.value)}
          />
        </div>
        <div className='col-lg-2 mb-5'>
          <Select
            options={productsOptions}
            value={productValue ? productValue : null}
            isClearable
            placeholder='Product'
            handleInputChange={handleInputChangeProducts}
            isLoading={isLoadingProduct}
            onChange={(option) => handleChangeFormValue('product', option)}
          />
        </div>
        <div className='col-lg-auto mb-5'>
          <DatePickerDialog
            handleDateChangeFormValue={handleDateChangeFormValue}
          />
        </div>
        <div className='col-lg-2 mb-5'>
          <Select
            options={suppliersOptions}
            value={supplierValue}
            isClearable
            handleInputChange={handleInputChangeSuppliers}
            isLoading={isLoadingSupplier}
            placeholder='Supplier'
            onChange={(option) => handleChangeFormValue('supplier', option)}
          />
        </div>
      </div>
    </div>
  )
}

export const UploadHistorySearchBar = React.memo(UploadHistorySearchBarComponent)
