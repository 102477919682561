import {create} from 'zustand';
import {devtools} from 'zustand/middleware';
import {LocalStorageService} from 'shared/services/local-storage-service';

interface IAuthState {
    user: number | null;
    loggedOut?:boolean
    setUser: (token: string | null, user: number | null) => void;
    setLoggedOut:(loggedOut:boolean)=>void
}

export const useAuthState = create<IAuthState>()(
    devtools(
        (set) => ({
            user: null,
            loggedOut:false,
            setUser(token, user) {
                if (!token) {
                    LocalStorageService.remove('token');
                    LocalStorageService.remove('userId');
                    set({user: null});
                    set({loggedOut: false});
                    return;
                }
                LocalStorageService.set('token', token);
                LocalStorageService.set('userId', user);
                set({user: user});
            },
            setLoggedOut(loggedOut:boolean){
                set({loggedOut:loggedOut})
            }
        }),
        {name: 'useAuthState', store: 'useAuthState'},
    ),
);