import React from 'react';
import {CategoryItem} from "./catalog-item/category-item";
import {LoadWrapper} from "shared/components/load-wrapper/load-wrapper";
import {IOption} from "shared/components/select/select";
import {ICategoryOption} from "shared/types/api-types/caterogy-api-types";
import {ICategoryUpdateProps} from "../pages/catalog-page/use-catalog-page";
import imgAllProducts from 'shared/assets/images/allProducts.png'

interface IListCatalog {
    isLoading: boolean,
    catalog: ICategoryOption[]
    onClearQuery: () => void
    handelSelect: (name: string, value: IOption) => void
    onEditCategory: (data: ICategoryUpdateProps) => void
    onRemoveCategory: (categoryId:number) => void
}

const CategoryListComponent: React.FC<IListCatalog> = ({
                                                           isLoading,
                                                           catalog,
                                                           onClearQuery,
                                                           handelSelect,
                                                           onEditCategory,
                                                           onRemoveCategory
                                                       }) => {

    return (
        <LoadWrapper isLoading={isLoading} >
            <div className="row" >
                <div className="col-12 col-md-6 col-lg-4" onClick={onClearQuery}>
                    <CategoryItem image={imgAllProducts} name='All Products' id={0}/>
                </div>
                {catalog.length ?
                    catalog.map(category =>
                        <div key={category.id}
                             onClick={() => handelSelect('category', {id: category.id, label: category.name})}
                             className="col-12 col-md-6 col-lg-4">
                            <CategoryItem image={category.image?.original_url}
                                          name={category.name}
                                          id={category.id}
                                          onEditCategory={onEditCategory}
                                          onRemoveCategory={onRemoveCategory}
                            />
                        </div>
                    ) :
                    <div className="border-bottom text-center text-muted col-12 ">
                        <p>Nothing was found </p>
                    </div>
                }
            </div>
        </LoadWrapper>

    );
};

export const CategoryList = React.memo(CategoryListComponent)
