import React from 'react'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {Toggle} from 'shared/components/toggle/toggle'
import {DatePickerDialog} from 'shared/components/day-picker/day-picker'
import {FormikProps, useFormik} from 'formik'
import {createDiscountSchema} from './create-discount-schema'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {DiscountsApi} from 'app/api/dashboard-api/discounts-api'
import {DiscountType, ICreateDiscount} from 'shared/types/discount-types'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {Loader} from 'shared/components/loader/loader'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {DISCOUNT_ROUTES} from 'shared/config/routes'

const initialValues = {
  name: '',
  isActive: false,
  start: null,
  finish: null,
  is_allow_to_sell_loss:false
}

export const CreateDiscount = () => {
  const navigate = useNavigate()

  const createDiscountMutation = useMutation<
    DiscountType,
    AxiosError<{message: string}>,
    Partial<ICreateDiscount>
  >(DiscountsApi.createDiscount, {
    onSuccess: async (data) => {
      toast.success('Created successfully !')
      navigate(DISCOUNT_ROUTES.DISCOUNT.generate(`${data.id}`))
    },
  })

  const formik: FormikProps<ICreateDiscount> = useFormik({
    initialValues,
    validationSchema: createDiscountSchema,
    onSubmit: async (values) => {
      createDiscountMutation.mutate(removeEmptyFields<ICreateDiscount>(values))
    },
  })
  const handleToggleIsActive = () => {
    formik.setFieldValue('isActive', !formik.values.isActive)
  }
  const handleToggleIsAllowToSellLess = () => {
    formik.setFieldValue('is_allow_to_sell_loss', !formik.values.is_allow_to_sell_loss)
  }
  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    formik.setFieldValue(
      'start',
      startDate ? startDate.replace(/\//g, '-') : null
    )
    formik.setFieldValue('finish', endDate ? endDate.replace(/\//g, '-') : null)
  }

  const handleCancelClick = () => {
    formik.resetForm();
    navigate(DISCOUNT_ROUTES.ROOT.path)
  }

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold m-0'>Create Discount</h1>
        <div className='d-flex align-items-center gap-2'>
          <Button
            label='Cancel'
            type='button'
            onClick={handleCancelClick}
            isLoading={createDiscountMutation.isLoading}
            disabled={createDiscountMutation.isLoading}
          />
          <Button
            label='Save'
            mainButton
            onClick={() => formik.submitForm()}
            isLoading={createDiscountMutation.isLoading}
            disabled={createDiscountMutation.isLoading}
          />
        </div>
      </div>
      <div className='card mt-3 p-10 position-relative'>
        {createDiscountMutation.isLoading && <Loader mode='blur' />}

        <div className='w-sm-300px'>
          <label className={' mb-2'}>Name</label>
          <Input
            placeholder='Discount name'
            error={formik.touched.name && !!formik.errors.name}
            errorText={formik.touched.name && formik.errors.name}
            {...formik.getFieldProps('name')}
          />
          <DatePickerDialog
            className='my-5'
            isError={
              (formik.touched.start && !!formik.errors.start) ||
              (formik.touched.finish && !!formik.errors.finish)
            }
            handleDateChangeFormValue={handleDateChangeFormValue}
          />
          {(formik.touched.start && !!formik.errors.start) ||
          (formik.touched.finish && !!formik.errors.finish) ? (
            <p className={'error'}>
              {formik.errors.start || formik.errors.finish}
            </p>
          ) : null}
          <div className='d-flex align-items-center mb-3'>
            <p className='me-3 mb-0'>Active</p>
            <Toggle
              isActive={formik.values.isActive}
              setActive={handleToggleIsActive}
            />
          </div>
          <div className='d-flex align-items-center'>
            <p className='me-3 mb-0'>Allow to sell at a loss</p>
            <Toggle              
              isActive={formik.values.is_allow_to_sell_loss}
              setActive={handleToggleIsAllowToSellLess}
            />
          </div>
        </div>
      </div>
    </>
  )
}
