import React from 'react'
import {Modal} from 'shared/components/modal-base'
import {useStateModalManager} from 'shared/context/modal-manager'
import {DASHBOARD_MODAL_NAMES} from 'shared/constants/modal-names'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'

export const PreOrderGroupCodesModal = () => {
  const modalState = useStateModalManager(
    DASHBOARD_MODAL_NAMES.pre_order_group_codes_warning
  )
  const onSubmit = () => {
    modalState.resolveCallback()
    modalState.close()
  }

  const handleClose = () => {
    modalState.rejectCallback('error')
    modalState.close()
  }
  return (
    <Modal.Root open={modalState.open} isClosable={true} onClose={handleClose}>
      <div className='p-5'>
        <p className='text-exl-primary mb-5'>
          For all pre ordered group codes once it becomes in stock again the
          system will automatically deduct the funds from your wallet and
          deliver you the voucher codes if the funds are not available you will
          get notified by email
        </p>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} />
          <Button label='Continue' mainButton onClick={onSubmit} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
