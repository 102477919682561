import { useEffect,  useRef, useState} from "react";

interface AnimationWrapperProps {
    show: boolean;
    children: React.ReactNode;
    from?: Record<string, any>;
    to?: Record<string, any>;
    unMountAnimation?: Record<string, any>;
    options?: KeyframeAnimationOptions;
}

export const AnimationWrapper: React.FC<AnimationWrapperProps> = ({
                                                               show,
                                                               children,
                                                               from = { opacity: 0 },
                                                               to = { opacity: 1 },
                                                               unMountAnimation,
                                                               options = { duration: 500, fill: 'forwards' },
                                                           }) => {
    const elementRef = useRef<HTMLSpanElement | null>(null);

    const [removeState, setRemove] = useState(!show);

    useEffect(() => {
        const childElement = elementRef.current;
        if (show) {
            setRemove(false);
            if (!childElement) return;
            childElement.animate([from, to], options);
        } else {
            if (!childElement) return;
            const animation = childElement.animate(unMountAnimation || [to, from], options);
            animation.onfinish = () => {
                setRemove(true);
            };
        }
    }, [show, removeState, from, to, unMountAnimation, options]);

    return !removeState ? <span ref={elementRef}>{children}</span> : null;
};

