import { IPurchase, IPurchaseProduct } from "shared/types/purchase";
import {BaseHttpServices} from "../../../shared/services/base-http-services";
import { IMeta } from "shared/types/api-types/api-types";
import { IProduct } from "../dashboard-api/codes-api";

export interface IPurchaseDTO {
  id?:number
  invoice_number?:string
  vat_number?:string
  fee?:string
  comment?:string
  supplier_id?:number
  currency_id?:number
}

export interface IProductInPurchaseDTO {
  product_id:number
  purchase_price:number
  quantity:number  
  net:number
  vat:number
}

export interface IProductInPurchaseParams {
  purchase:number
  params:IProductInPurchaseDTO
}

export interface IPurchaseProductsList {
  data:IPurchaseProduct[]
  meta:IMeta
}

export interface IPurchaseList {
    data:IPurchase[]
    meta:IMeta
}

interface IPurchaseParams {
    search?:string | number
    start_date?:string | Date
    end_date?:string | Date
    supplier?:string | number
    page?:string | number
}
interface IPaginationParams {
  page?:string
}

export class PurchaseApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getProducts = async (name?: string): Promise<IProduct[]> => {
    const payload = await this.http.get(`/products`, {params: {name: name}})
    return payload.data.data
  }
  setProducts = async (data): Promise<{data: IProduct}> => {
    const payload = await this.http.post(`/products`, {...data})
    return payload.data
  }

  getAllPurchases = async (params: IPurchaseParams | null): Promise<IPurchaseList> => {
    const payload = await this.http.get(`/purchase`, {
      params: params,
    })
    return payload.data
  }

  getPurchaseById = async (purchaseId: number): Promise<IPurchase> => {
    const payload = await this.http.get(`/purchase/${purchaseId}`)    
    return payload.data.data
  }

  getPurchaseProduct = async (params:IPaginationParams,purchaseId: number): Promise<IPurchaseProductsList> => {
    const payload = await this.http.get(`/purchase/${purchaseId}/product`,{
      params:params
    })
    return payload.data
  }

  createNewPurchase = async (createPurchaseDTO:IPurchaseDTO):Promise<IPurchase> => {
    const payload = await this.http.post(`/purchase`,createPurchaseDTO)
    return payload.data.data
  }

  copyPurchase = async (purchaseId:number):Promise<IPurchase> => {
    const payload = await this.http.post(`/purchase/${purchaseId}/copy`)
    return payload.data.data
  }

  addProductToPurchase = async (addProductToPurchaseParams:IProductInPurchaseParams):Promise<IPurchaseProduct> => {
    const payload = await this.http.post(`/purchase/${addProductToPurchaseParams.purchase}/product`,addProductToPurchaseParams.params)
    return payload.data.data
  }

  deletePurchase = async (purchaseId: number): Promise<string> => {
    const payload = await this.http.delete(`/purchase/${purchaseId}`);
    return payload.data.message
  }

  deleteProductFromPurchase = async (purchaseId: number, productId: number): Promise<string> => {
    const payload = await this.http.delete(`/purchase/${purchaseId}/product/${productId}`);
    return payload.data.message
  }

  updatePurchase = async (purchase:IPurchaseDTO):Promise<IPurchase> => {
    const payload = await this.http.post(`/purchase/${purchase.id}`,purchase)
    return payload.data.data
  }

  updatePurchaseProduct = async (purchaseProduct:IPurchaseProduct): Promise<IPurchaseProduct> => {
    const payload = await this.http.post(`/purchase/${purchaseProduct.purchase_id}/product/${purchaseProduct.id}`,purchaseProduct);
    return payload.data.data
  }
}

export const PurchaseApi = new PurchaseApiService(new BaseHttpServices());