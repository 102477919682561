import React from 'react'
import CheckBox from 'shared/components/check-box/check-box'
import styles from './general-discount-price.module.scss'
import {CalculationLine} from '../calculation-line/calculation-line'
import {
  DiscountProduct,
  DiscountProductKeys,
} from '../../../../../shared/types/discount-types'
import {IValidationProductDiscountError} from '../create-product-discount/validation-methods'
import {FormikErrors, FormikTouched} from 'formik'

interface IGeneralDiscountPriceProps {
  sellPrice: number
  purchasePrice?: string
  discount?: number
  type: number
  errors?: FormikErrors<DiscountProduct>
  touched?: FormikTouched<DiscountProduct>
  isDisabled?: boolean
  onToggleGeneralPrice: () => void
  onChangeProductDiscount: (
    name: DiscountProductKeys,
    value: number | string
  ) => void
  onChangeGeneralSellPrice: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const GeneralDiscountPriceComponent: React.FC<IGeneralDiscountPriceProps> = ({
  sellPrice,
  purchasePrice,
  discount,
  type,
  isDisabled,
  onToggleGeneralPrice,
  onChangeProductDiscount,
  errors,
  touched,
  onChangeGeneralSellPrice,
}) => {

  return (
    <div className={styles.card}>
      <h3 className='mb-5'>General Price:</h3>
      <div className='d-flex align-items-center flex-column flex-md-row justify-content-md-between'>
        <CalculationLine
          isGeneralComponentType={true}
          onChangeSellPrice={onChangeGeneralSellPrice}
          touched={touched}
          errors={errors}
          onChangeProductDiscount={onChangeProductDiscount}
          discount={discount}
          purchasePrice={purchasePrice}
          type={Number(type)}
          sellPrice={sellPrice}
          isDisabled={!isDisabled}
        />
        <div className='d-flex align-items-center'>
          <p className='mb-0 me-3'>Use General Price:</p>
          <CheckBox onChange={onToggleGeneralPrice} checked={isDisabled} />
        </div>
      </div>
    </div>
  )
}

export const GeneralDiscountPrice = React.memo(GeneralDiscountPriceComponent)
