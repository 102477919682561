import { ILink, IRegion } from "shared/types/base";
import {BaseHttpServices} from "../../../shared/services/base-http-services";
import { IMeta } from "shared/types/api-types/api-types";
import { ICurrency } from "shared/types/codes-upload";
import {IBrand} from '../landing-api/landing-api'
import { IAdminSetting, IAdminSettingsResponse } from "shared/types/api-types/admin-setting-types";

export interface IRegionsList {
    data: IRegion[]
    meta: IMeta
    links: ILink
}

export interface ICurrenciesList {
    data: ICurrency[]
    meta: IMeta
    links: ILink
}

export interface ISliderImage {
    id:number
    file_name:string
    mime_type:string
    name:string
    original_url:string
    size:number
}

export interface ISlider {
    id?: number
    slug: string
    image: ISliderImage[] | null | File[]
    original_url?:string
}

export interface ISliderDeleteDTO {
    slug:string,
    selected_model:number
}

interface IOptionsParams {    
    page?: string | number
}

export class OptionsApiService  {
    private readonly http: BaseHttpServices;

    constructor(httpService: BaseHttpServices) {
        this.http = httpService;
    }

    getRegions = async (params:IOptionsParams | null): Promise<IRegionsList> => {
        const payload = await this.http.get(`/regions`,{
            params:params
        });
        return payload.data;
    };

    createRegion = async (region:IRegion): Promise<IRegion> => {
        const payload = await this.http.post(`/regions`,region);
        return payload.data.data;
    };

    updateRegion = async (region:IRegion): Promise<IRegion> => {
        const payload = await this.http.patch(`/regions/${region.id}`,region);
        return payload.data.data;
    };

    deleteRegion = async (region:IRegion): Promise<IRegion> => {
        const payload = await this.http.delete(`/regions/${region.id}`);
        return payload.data.data;
    };

    getCurrencies = async (params:IOptionsParams | null): Promise<ICurrenciesList> => {
        const payload = await this.http.get(`/currencies`,{
            params:params
        });
        return payload.data;
    };

    createCurrency = async (currency:ICurrency): Promise<ICurrency> => {
        const payload = await this.http.post(`/currencies`,currency);
        return payload.data.data;
    };

    updateCurrency = async (currency:ICurrency): Promise<ICurrency> => {
        const payload = await this.http.patch(`/currencies/${currency.id}`,currency);
        return payload.data.data;
    };

    deleteCurrency = async (currency:ICurrency): Promise<ICurrency> => {
        const payload = await this.http.delete(`/currencies/${currency.id}`);
        return payload.data.data;
    };

    getBrands = async (): Promise<IBrand[]> => {
        const payload = await this.http.get(`/brands`);
        return payload.data.data;
    };

    createBrand = async (brand:IBrand): Promise<IBrand> => {
        const formData = new FormData();
        Object.entries(brand).forEach(([key, value]) => {
            formData.append(key, value);
        }); 
        const payload = await this.http.post(`/brands`,formData);
        return payload.data.data;
    };

    updateBrand = async (brand:IBrand): Promise<IBrand> => {
        const formData = new FormData()
        Object.entries(brand).forEach(([key, value]) => {
            formData.append(key, value);
        });    
        const payload = await this.http.post(`/brands/${brand.id}`,formData);
        return payload.data.data;
    };

    deleteBrand = async (brand:IBrand): Promise<IBrand> => {
        const payload = await this.http.delete(`/brands/${brand.id}`);
        return payload.data.data;
    };

    getSliders = async (): Promise<ISlider[]> => {
        const payload = await this.http.get(`/sliders`);
        return payload.data.data;
    };

    updateSlider = async (slider:ISlider): Promise<ISlider> => {
        const formData = new FormData();
        formData.append('slug',slider.slug);
        if (slider.image && slider.image.length > 0) {
            slider.image.forEach((file, index) => {
                formData.append(`images[${index}]`, file);
            });
        } 
        const payload = await this.http.post(`/sliders/${slider.slug}`,formData);
        return payload.data.data;
    };

    deleteSliderImages = async (sliderDeleteDTO:ISliderDeleteDTO): Promise<ISlider> => {
        const payload = await this.http.delete(`/sliders/${sliderDeleteDTO.slug}`,{
            data:sliderDeleteDTO
        });
        return payload.data.data;
    };

    getAdminSettings = async (params:IOptionsParams | null): Promise<IAdminSettingsResponse> => {
        const payload = await this.http.get(`/admin_settings`,{
            params:params
        });
        return payload.data;
    };

    updateAdminSetting = async (setting:IAdminSetting): Promise<IAdminSetting> => {
        const payload = await this.http.post(`/admin_settings/${setting.key}`,setting);
        return payload.data.data;
    };
        
}

export const OptionsApi = new OptionsApiService(new BaseHttpServices());