import {BaseHttpServices} from 'shared/services/base-http-services'

import {
  ICodesItem,
  ICodesUpload,
  IUploadHistory,
  IWarning,
} from 'shared/types/codes-upload'
import {ARCHIVE_FORMATS, IMAGE_FORMATS} from '../../../shared/constants/base'
import {IRegion} from '../../../shared/types/base'
import {IErrorCodes} from '../../../shared/types/codes-upload'

export interface IOptionsDTO {
  id: number
  name: string
}

export interface ICurrenciesDTO {
  id: number
  iso: string
  name: string
  symbol: string
}

export interface IProduct {
  categories: Array<unknown>
  currency: ICurrenciesDTO
  description: string
  id: number
  image?: unknown
  name: string
  price: Array<number | null>
  product_type: number
  quantity: number
  sell_price: string
  purchase_price: string
  region?: IRegion
  stock: boolean
  general_price?: string
  min_pre_order_price?: number
  max_purchase_price?: number
}

interface ICodesParams {
  search?: string | number
  product?: string | number
  supplier?: string | number
  stock?: string | number
  page?: string | number
  uploads_history?: string | number[] | number
  ids?: number[]
  hashes?: string[]
}

interface IMeta {
  per_page: number
  to: number
  total: number
}

export interface ICodesList {
  data: ICodesItem[]
  meta: IMeta
}

export interface IUpdateBatchStatusDTO {
  selected_modules: number[]
  stock_status: number
  params: ICodesParams | null
}

interface IUploadHistoryParams {
  search?: string | number
  product?: string | number
  supplier?: string | number
  start_date?: string | number
  end_date?: string | number
  page?: string | number
}

export interface IUploadHistoryList {
  data: IUploadHistory[]
  meta: IMeta
}

export interface IDeleteCodesDTO {
  selected_models: number[]
  params: ICodesParams | null
}

interface IErrorCodesParams {
  error?: string
  code?: string
  uploads_history?: number
  start_date?: string | number
  end_date?: string | number
  page?: string | number
}

interface IErrorCodesList {
  data: IErrorCodes[]
  meta: IMeta
}

const OPTIONS = [true, true, false]

export class CodesApiService {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
  }

  getSuppliers = async (name: string): Promise<IOptionsDTO[]> => {
    const payload = await this.http.get(`/suppliers`, {
      params: {name: name},
    })
    return payload.data.data
  }
  setSupplier = async (
    data: string
  ): Promise<{data: {id: number; name: string}}> => {
    const payload = await this.http.post(`/suppliers`, {name: data})
    return payload.data
  }

  getProducts = async (name?: string): Promise<IProduct[]> => {
    const payload = await this.http.get(`/products`, {params: {name: name}})
    return payload.data.data
  }
  setProducts = async (data): Promise<{data: IProduct}> => {
    const payload = await this.http.post(`/products`, {...data})
    return payload.data
  }

  getCurrencies = async (): Promise<ICurrenciesDTO[]> => {
    const payload = await this.http.get(`/currencies`)
    return payload.data.data
  }

  createCodes = async (data: ICodesUpload): Promise<IWarning> => {
    const codesData = new FormData()
    for (const field in data) {
      if (data[field] && field !== 'image_codes') {
        codesData.append(field, data[field])
      }
    }
    if (
      data.image_codes &&
      data.image_codes.length &&
      IMAGE_FORMATS.includes(data.image_codes[0].type)
    ) {
      for (const field in data.image_codes) {
        codesData.append(`image_codes[${field}]`, data.image_codes[field])
      }
    }
    if (
      data.image_codes &&
      data.image_codes.length &&
      ARCHIVE_FORMATS.includes(data.image_codes[0].type)
    ) {
      codesData.append(`image_codes`, data.image_codes[0])
    }

    const payload = await this.http.post(
      `/products/${data.product_id}/code`,
      codesData
    )
    return payload.data.data
  }

  getCodes = async (params: ICodesParams | null): Promise<ICodesList> => {
    const payload = await this.http.get(`/codes`, {
      params: {...params, stock: OPTIONS[params.stock]},
    })
    return payload.data
  }
  getCodesCSV = async (params: ICodesParams | null): Promise<string> => {
    const payload = await this.http.get(`/codes/csv`, {
      params: params,
      responseType: 'blob',
    })
    return payload.data
  }
  updateBatchStatus = async (
    data: IUpdateBatchStatusDTO
  ): Promise<ICodesItem[]> => {
    const payload = await this.http.post(
      `/codes/batch_status_update`,
      {
        selected_models: data.selected_modules,
        stock_status: data.stock_status,
      },
      {
        params: data.params,
      }
    )
    return payload.data.message
  }
  deleteCodes = async (data: IDeleteCodesDTO): Promise<string> => {
    const payload = await this.http.delete(`/codes/batch_delete`, {
      data: {
        selected_models: data.selected_models,
      },
      params: data.params,
    })
    return payload.data.data
  }
  getUploadCodesHistory = async (
    params: IUploadHistoryParams | null
  ): Promise<IUploadHistoryList> => {
    const payload = await this.http.get(`/uploads_history`, {
      params: params,
    })
    return payload.data
  }
  deleteUploadHistory = async (data: number[]): Promise<string> => {
    const payload = await this.http.delete(
      `uploads_history/${data}/delete_with_codes`
    )
    return payload.data.data
  }
  getErrorCodes = async (
    params: IErrorCodesParams | null
  ): Promise<IErrorCodesList> => {
    const payload = await this.http.get(`error_uploads`, {
      params: params,
    })
    return payload.data
  }
}

export const CodesApi = new CodesApiService(new BaseHttpServices())
