import React from 'react'
import {useProductsPage} from './use-products-page'
import {ProductList} from '../../components/product-list'
import {CatalogSearchBar} from '../../components/catalog-search-bar'
import {Pagination} from '../../../components/pagination/pagination'
import {PreOrderWarningModal} from '../../components/pre-order-warning-modal'
import {Can} from '../../../../../casl'
import {RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import {Button} from 'shared/components/button/button'
import { ButtonList } from 'shared/components/button-list/button-list'

export const ProductsPage = () => {
  const {models, commands} = useProductsPage()

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold m-0'>Products</h1>
        <ButtonList>
          <Button
            label='Export Pre-Orders'
            type='button'
            isLoading={models.isPreordersLoading}
            disabled={models.isPreordersLoading}
            onClick={commands.onDownloadPreorders}
          />
          <Button
            label='Export CSV'
            type='button'
            isLoading={models.isCSVLoading}
            disabled={models.isCSVLoading}
            onClick={commands.onDownloadCSV}
          />
          <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
            <Button
              label={'Create Product'}
              mainButton
              onClick={commands.navigateToProductCreatePage}
            />
          </Can>
        </ButtonList>
      </div>
      <div className='card p-7'>
        <CatalogSearchBar
          handleInputChangeRegion={commands.handleInputChangeRegions}
          regionOptions={models.optionRegions}
          searchValue={models.searchValue}
          setSearchValue={commands.setSearchValue}
          isLoading={models.isLoadingSelects}
          categoryOptions={models.categoryOptions}
          handleChangeFormValue={commands.handleChangeFormValue}
          categoryValue={models.values.category}
          stockOption={models.stockOptions}
          stockValue={models.values.in_stock}
          regionValue={models.values.region}
          currencyOption={models.currencyOption}
          currencyValue={models.values.currency}
          handleInputChangeCategory={commands.handleInputChangeCategory}
        />
        <ProductList
          products={models.catalog}
          isAddedToCart={models.isAddedToCart}
          handelAddProductToCart={commands.handelAddProductToCart}
          isLoading={models.isLoadingList}
          onCreatePreOrder={commands.onCreatePreOrder}
        />

        {models.catalog && models.catalog.length ? (
          <Pagination
            currentPage={Number(models.values.page) || 1}
            totalCount={models.meta.total}
            pageSize={models.meta.per_page}
            onPageChange={(page) => commands.setCurrentPage(page)}
          />
        ) : null}
        <PreOrderWarningModal />
      </div>
    </>
  )
}
