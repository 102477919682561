import React from 'react'
import clsx from 'clsx'
import styles from './carousel-dots.module.scss'

interface CarouselDotsProps {
  count: number
  activeIndex: number
  setActive: (index: number) => void
}

const CarouselDotsComponent: React.FC<CarouselDotsProps> = ({count, activeIndex, setActive}) => {
  return (
    <div className={styles['carousel-dots']}>
      {Array.from({length: count}).map((_, index) => (
        <button
          key={index}
          className={clsx(styles.dot, {
            [styles['dot--active']]: index === activeIndex,
          })}
          onClick={() => setActive(index)}
        />
      ))}
    </div>
  )
}

export const CarouselDots = React.memo(CarouselDotsComponent)