import React, {useEffect, useMemo, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {AxiosError} from 'axios'
import {useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import isEqual from 'lodash/isEqual'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {PURCHASES_MODAL_NAME} from 'shared/constants/modal-names'
import {PURCHASES_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {
  IPurchaseDTO,
  IPurchaseList,
  PurchaseApi,
} from 'app/api/purchases-api/purchases-api'
import {IOption, Select} from 'shared/components/select/select'
import {MutationConfig, useSelectController} from 'shared/hooks/use-select-controller'
import {SelectsApi} from 'app/api/settings-api/selects-api'
import {Input} from 'shared/components/input/input'
import {Textarea} from 'shared/components/textarea/textarea'
import {IPurchase} from 'shared/types/purchase'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {validationSchema} from '../schemas/purchase-validation-schema'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

export const EditPurchaseModal = () => {
  const [purchase, setPurchase] = useState<IPurchaseDTO>(null)
  const modalState = useStateModalManager(
    PURCHASES_MODAL_NAME.update_purchase,
    {
      onBeforeOpen: (purchase: IPurchase) => {
        formik.setValues({
          ...purchase,
          supplier_id: Number(purchase.supplier.id),
          currency_id: Number(purchase.currency.id),
        })
        setPurchase({
          ...purchase,
          supplier_id: Number(purchase.supplier.id),
          currency_id: Number(purchase.currency.id),
        })
      },
    }
  )

  const [supplierOption,setSupplierOption] = useState(null)
  
  const queryClient = useQueryClient()
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      search: search.get('search'),
      supplier: Number(search.get('supplier')) || null,
      status: Number(search.get('status')) || null,
      currency: Number(search.get('currency')) || null,
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const updatePurchaseMutation = useMutation<
    IPurchase,
    AxiosError<{error: string}>,
    IPurchaseDTO
  >(PurchaseApi.updatePurchase, {
    onSuccess: (data: IPurchase) => {
      queryClient.setQueryData(
        [PURCHASES_QUERY_KEYS.purchases, queryParams],
        (prevData: IPurchaseList) => {
          const index = prevData.data.findIndex((item) => item.id === data.id)
          prevData.data.splice(index, 1, data)
          return prevData
        }
      )
      queryClient.invalidateQueries([
        PURCHASES_QUERY_KEYS.purchases,
        queryParams,
      ])
      toast.success(`Purchase was updated successfully!`)
    },
    onError: (error: AxiosError<{error: string}>) => {
      toast.error(error?.response.data.error)
    },
  })

  const onSubmit = (values: IPurchaseDTO) => {
    updatePurchaseMutation.mutate({
      ...values,
      fee: decimalFormatter(values.fee) || 0,
    })
  }

  const formik = useFormik<IPurchaseDTO>({
    initialValues: purchase,
    onSubmit,
    validationSchema,
  })

  
  const handleClose = () => {
    setPurchase(null)
    modalState.close()
  }

  const handleChangeSelects = (name: string) => (value: IOption | null) => {
    formik.setFieldValue(name, value ? value.id : null)
  }

  const {
    options:optionsSuppliers,
    isLoading: isLoadingSuppliers,
    formationOptions: optionsSuppliersMemo,
    getUnknownItem:getUnknownSupplier,
    isGettingUnknownItems:isUnknownSupplierGetting,
    handleInputChange:handleInputSupplierChange
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.suppliers,
    Fn: SelectsApi.getSuppliers,
    getUnkownFn:SelectsApi.getUnknownSupplier,
    params: 'name',
  })

  const {
    options: currenciesOptions,
    isLoading: isLoadingCurrencies,
    formationOptions: optionsCurrenciesMemo,
  } = useSelectController({
    key: PURCHASES_QUERY_KEYS.currencies,
    Fn: SelectsApi.getCurrencies,
    params: 'name',
  })

  const currentCurrency = currenciesOptions.find(
    (cur) => formik.values?.currency_id === cur.id
  )
  const currencySymbol = currentCurrency?.symbol

  const isDisabledCreateButton =
    updatePurchaseMutation.isLoading ||
    !!Object.keys(formik.errors).length  ||
    isEqual(formik.values, purchase)

  const handleUpdateClick = () => {
    formik.submitForm()
  }

  useEffect(() => {
    if (!formik.values) return setSupplierOption(null)
    if (!optionsSuppliersMemo.length) return setSupplierOption(null)
    const supplierValue = optionsSuppliers.find(
      (supplier) => supplier.id === formik.values.supplier_id
    )
    if (supplierValue) {
      const sup = MutationConfig['defaultOptions']([supplierValue])[0]
      return setSupplierOption(sup.id)
    }    
    if (!supplierValue && formik.values.supplier_id) {
      ;(async () => {
        const unknownSupplier = await getUnknownSupplier(
          `${formik.values.supplier_id}`,
          formik.values.supplier_id
        )
        const sup = MutationConfig['defaultOptions']([unknownSupplier])[0]
        optionsSuppliers.unshift(unknownSupplier)
        optionsSuppliersMemo.unshift(sup)
        return setSupplierOption(sup.id)
      })()
    }
    if (!supplierValue) return setSupplierOption(null)
  }, [
    optionsSuppliersMemo,
    formik.values?.supplier_id
  ])

  useEffect(() => {
    handleClose()
    updatePurchaseMutation.reset()
  }, [updatePurchaseMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!updatePurchaseMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center mb-5'>Edit Purchase</h2>
        <label>Invoice number</label>
        <Input
          {...formik.getFieldProps('invoice_number')}
          value={formik.values?.invoice_number || ''}
          disabled={updatePurchaseMutation.isLoading}
          placeholder='Invoice number'
          error={formik.touched.invoice_number && !!formik.errors.invoice_number}
          errorText={formik.errors.invoice_number}
        />
        <label>Supplier</label>
        <Select
          isClearable
          options={optionsSuppliersMemo}
          {...formik.getFieldProps('supplier_id')}
          isLoading={isLoadingSuppliers||isUnknownSupplierGetting}
          isDisabled={updatePurchaseMutation.isLoading}
          value={supplierOption}
          error={formik.touched.supplier_id && !!formik.errors.supplier_id}
          errorText={formik.errors.supplier_id ? formik.errors.supplier_id : null}
          onChange={handleChangeSelects('supplier_id')}          
          handleInputChange={handleInputSupplierChange}
          placeholder='Supplier'
        />
        <label>Vat number</label>
        <Input
          {...formik.getFieldProps('vat_number')}
          value={formik.values?.vat_number || ''}
          placeholder='Vat number'
          error={formik.touched.vat_number && !!formik.errors.vat_number}
          errorText={formik.errors.vat_number}
          disabled={updatePurchaseMutation.isLoading}
        />
        <label>Currency</label>
        <Select
          isClearable
          options={optionsCurrenciesMemo}          
          isLoading={isLoadingCurrencies}
          isDisabled={updatePurchaseMutation.isLoading}
          {...formik.getFieldProps('currency_id')}
          value={formik.values?.currency_id}
          error={formik.touched.currency_id && !!formik.errors.currency_id}
          errorText={formik.errors.currency_id ? formik.errors.currency_id : null}
          onChange={handleChangeSelects('currency_id')}
          placeholder='Currency'
        />
        <label>Fee</label>
        <Input
          {...formik.getFieldProps('fee')}
          value={formik.values?.fee || ''}
          type='number'
          isPriceInput
          error={formik.touched.fee && !!formik.errors.fee}
          errorText={formik.errors.fee}
          disabled={updatePurchaseMutation.isLoading}
          currency={currencySymbol}
        />
        <label>Comment</label>
        <Textarea
          {...formik.getFieldProps('comment')}
          value={formik.values?.comment || ''}
          placeholder='Comment...'
          mode='light'
          error={formik.touched.comment && !!formik.errors.comment}
          errorText={formik.errors.comment}
          disabled={updatePurchaseMutation.isLoading}
        />
        <ButtonList className='justify-content-end'>
          <Button
            label='Cancel'
            onClick={handleClose}
            disabled={updatePurchaseMutation.isLoading}
          />
          <Button
            label='Update'
            mainButton
            isLoading={updatePurchaseMutation.isLoading}
            disabled={isDisabledCreateButton}
            onClick={handleUpdateClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
