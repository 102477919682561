import React from 'react'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {Modal} from 'shared/components/modal-base'
import {CODES_MODAL_NAME} from 'shared/constants/modal-names'
import {useStateModalManager} from 'shared/context/modal-manager'

export const ConfirmChangeStatusModal = () => {
  const modalState = useStateModalManager(CODES_MODAL_NAME.change_status)

  const handleConfirmChangeStatusClick = () => {
    modalState.resolveCallback()
    modalState.close()
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback('error')
  }

  return (
    <Modal.Root open={modalState.open} onClose={handleClose}>
      <div className='p-7'>
        <h2 className='text-center text-exl-primary mb-10'>
          Are you sure that you want to change status this codes?
        </h2>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} />
          <Button
            label='Change'
            mode='remove'
            onClick={handleConfirmChangeStatusClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
