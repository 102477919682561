import {ReactComponent as Logo} from './collection/logo.svg'
import {ReactComponent as Key} from './collection/key.svg'
import {ReactComponent as Bank} from './collection/bank.svg'
import {ReactComponent as Folder} from './collection/folder.svg'
import {ReactComponent as Setting} from './collection/setting.svg'
import {ReactComponent as Shopping} from './collection/shopping.svg'
import {ReactComponent as UpSquare} from './collection/up-square.svg'
import {ReactComponent as CreditCard} from './collection/credit-card.svg'
import {ReactComponent as HidePassword} from './collection/hide-password.svg'
import {ReactComponent as ShowPassword} from './collection/show-password.svg'
import {ReactComponent as Logout} from './collection/logout.svg'
import {ReactComponent as ArrowDown} from './collection/arrow-down.svg'
import {ReactComponent as Plus} from './collection/plus.svg'
import {ReactComponent as MinSearch} from './collection/min-search.svg'
import {ReactComponent as Cancel} from './collection/cancel.svg'
import {ReactComponent as Customers} from './collection/customers.svg'
import {ReactComponent as PageRight} from './collection/page-right.svg'
import {ReactComponent as PageLeft} from './collection/page-left.svg'
import {ReactComponent as QuestionMark} from './collection/question-mark.svg'
import {ReactComponent as Edit} from './collection/edit.svg'
import {ReactComponent as CloseMark} from './collection/close.svg'
import {ReactComponent as Cart} from './collection/cart.svg'
import {ReactComponent as EmptyCart} from './collection/empty-cart.svg'
import {ReactComponent as Bell} from './collection/bell.svg'
import {ReactComponent as Word} from './collection/docx.svg'
import {ReactComponent as PDF} from './collection/pdf.svg'
import {ReactComponent as Zip} from './collection/zip.svg'
import {ReactComponent as Trash} from './collection/trash.svg'
import {ReactComponent as SliderArrowLeft} from './collection/slider-arrow-left.svg'
import {ReactComponent as SliderArrowRight} from './collection/slider-arrow-right.svg'
import {ReactComponent as LogoHeader} from './collection/logoHeader.svg'
import {ReactComponent as LandingLogoHeader} from './collection/landing-logo-header.svg'
import {ReactComponent as EditCircle} from './collection/edit-circle.svg'
import {ReactComponent as RemoveCircle} from './collection/remove-circle.svg'
import {ReactComponent as SidebarArrow} from './collection/sidebar-arrow.svg'
import {ReactComponent as WalletArrow} from './collection/wallet-arrow.svg'
import {ReactComponent as BadgeDefault} from './collection/badge-default.svg'
import {ReactComponent as ArrowUp} from './collection/arrow-up.svg'
import {ReactComponent as Purchase } from './collection/radar.svg'
import {ReactComponent as Supplier} from './collection/supplier-tracker.svg'
import {ReactComponent as Statistic} from './collection/statistic.svg'
import {ReactComponent as Options} from './collection/options.svg'
import {ReactComponent as Discounts} from './collection/discounts.svg'
import {ReactComponent as AuthUser} from './collection/auth-user.svg'
import {ReactComponent as NavLogout} from './collection/nav-logout.svg'
import {ReactComponent as VerticalDots} from './collection/vertical-dots.svg'
import { ReactComponent as LargeArrowDown } from './collection/large-arrow-down.svg'
import {ReactComponent as LogoDark} from './collection/dark-logo.svg'
import {ReactComponent as Chat} from './collection/chat.svg'

export const ICON_COLLECTION = {
  logo: Logo,
  key: Key,
  bank: Bank,
  folder: Folder,
  setting: Setting,
  shopping: Shopping,
  upSquare: UpSquare,
  creditCard: CreditCard,
  hidePassword: HidePassword,
  showPassword: ShowPassword,
  logout: Logout,
  arrowDown: ArrowDown,
  plus: Plus,
  minSearch: MinSearch,
  cancel: Cancel,
  word: Word,
  pdf: PDF,
  zip: Zip,
  customers: Customers,
  pageRight: PageRight,
  pageLeft: PageLeft,
  questionMark: QuestionMark,
  edit: Edit,
  close: CloseMark,
  cart: Cart,
  emptyCart: EmptyCart,
  bell: Bell,
  trash: Trash,
  SliderArrowLeft: SliderArrowLeft,
  SliderArrowRight: SliderArrowRight,
  LogoHeader: LogoHeader,
  LandingLogoHeader: LandingLogoHeader,
  editCircle: EditCircle,
  sidebarArrow: SidebarArrow,
  walletArrow: WalletArrow,
  badgeDefault: BadgeDefault,
  arrowUp: ArrowUp,
  purchase: Purchase,
  statistic: Statistic,
  options: Options,
  discounts: Discounts,
  authUser: AuthUser,
  navLogout: NavLogout,
  verticalDots: VerticalDots,
  largeArrowDown: LargeArrowDown,
  logoDark: LogoDark,
  supplier: Supplier,
  chat:Chat,
  removeCircle:RemoveCircle
}

type Keys = keyof typeof ICON_COLLECTION
export type IconCollectionType = typeof ICON_COLLECTION[Keys]
