import React from 'react'
import TopSelling from './components/cards/top-selling'
import {LastOrders} from './components/cards/last-orders'
import {StatisticFilter} from './components/modals/statistic-filter'
import {TopClients} from './components/cards/top-clients'
import {LowStock} from './components/cards/low-stock'
import {ApiUsage} from './components/cards/api-usage'
import {Revenue} from './components/charts/revenue'
import {UnitsSold} from './components/charts/units-sold'
import {OrdersChart} from './components/charts/orders-chart'
import {OrdersAVG} from './components/charts/orders-avg'
import {CodesUploadStatistic} from './components/charts/codes-upload-statistic'
import {UsersOnSite} from './components/charts/users-on-site'
import {TotalStatistic} from './components/total-statistic/total-statistic'
import {Proffit} from './components/charts/proffit'

export const StatisticPage = () => {
  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary bold'>Dashboard</h1>
      </div>
      <div className='card mt-3 p-10 position-relative'>
        <TotalStatistic />
        <div className='row g-5'>
          <TopSelling />
          <LastOrders />
          <TopClients />
          <LowStock />
          <ApiUsage />
        </div>
        <Revenue />
        <Proffit />
        <UnitsSold />
        <OrdersChart />
        <OrdersAVG />
        <CodesUploadStatistic />
        <UsersOnSite />
        <StatisticFilter />
      </div>
    </>
  )
}
