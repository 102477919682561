import React from 'react'
import {DatePickerDialog} from 'shared/components/day-picker/day-picker'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Input} from 'shared/components/input/input'

interface IErrorCodesSearchBar {
  codeValue: string
  setCodeValue: (text: string) => void
  errorValue: string
  setErrorValue: (text: string) => void
  uploadIdValue: string
  setUploadIdValue: (text: string) => void
  handleDateChangeFormValue: (startDate: string, endDate: string) => void
}

export const ErrorCodesSearchBar: React.FC<IErrorCodesSearchBar> = ({
  codeValue,
  setCodeValue,
  errorValue,
  setErrorValue,
  uploadIdValue,
  setUploadIdValue,
  handleDateChangeFormValue,
}) => {
  return (
    <div className='mb-5'>
      <div className='row align-items-center row-cols-1 row-cols-sm-2 row-cols-md-3  row-cols-lg-6'>
        <div className='col mb-5'>
          <Input
            startAdornment={
              <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
            }
            placeholder='Code'
            value={codeValue}
            onChange={(event) => setCodeValue(event.target.value)}
          />
        </div>
        <div className='col mb-5'>
          <Input
            type='text'
            startAdornment={
              <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
            }
            placeholder='Error'
            value={errorValue}
            onChange={(event) => setErrorValue(event.target.value)}
          />
        </div>
        <div className='col mb-5'>
          <Input
            type='number'
            mode='number'
            min={1}
            step={1}
            startAdornment={
              <Icon className='p-0' icon={ICON_COLLECTION.minSearch} />
            }
            placeholder='Upload History ID'
            value={uploadIdValue}
            onChange={(event) => setUploadIdValue(event.target.value)}
          />
        </div>
        <div className='col-lg-auto mb-5'>
          <DatePickerDialog
            handleDateChangeFormValue={handleDateChangeFormValue}
          />
        </div>
      </div>
    </div>
  )
}
