import React, {useState} from 'react'
import {useStateModalManager} from 'shared/context/modal-manager'
import {DISCOUNT_MODAL_NAME} from 'shared/constants/modal-names'
import {Modal} from 'shared/components/modal-base'
import {ButtonList} from 'shared/components/button-list/button-list'
import {Button} from 'shared/components/button/button'
import {thousandFormatter} from '../../../../../shared/helpers/formatter'

export type validationMessageType = {
  purchasePrice: number
  newPrice: number
  id: number
}
export const AlertValidationProductDiscountModel = () => {
  const [validationMessage, setValidationMessage] = useState<
    validationMessageType[]
  >([])
  const [isUsedGeneralPrice, setIsUsedGeneralPrice] = useState(false)
  const modalState = useStateModalManager(
    DISCOUNT_MODAL_NAME.alert_validation,
    {
      onBeforeOpen: (beforeData: {
        validationMessageType: validationMessageType[]
        isGeneralPrice: boolean
      }) => {
        setValidationMessage(beforeData.validationMessageType)
        setIsUsedGeneralPrice(beforeData.isGeneralPrice)
      },
    }
  )
  const onContinue = () => {
    modalState.close()
    modalState.resolveCallback()
  }

  const handleClose = () => {
    modalState.close()
    modalState.rejectCallback()
  }

  const purchasePriceLabel = isUsedGeneralPrice
    ? 'Last purchase price'
    : 'Purchase price'

  return (
    <Modal.Root open={modalState.open} onClose={handleClose} isClosable={true}>
      <div className='p-5 text-center'>
        <h5 className='text-exl-primary mb-5'>
          Are you sure you want to set the selling price lower than the{' '}
          <span className='text-lowercase'>{purchasePriceLabel}</span>?
        </h5>

        {validationMessage.length
          ? validationMessage.map((message) => (
              <p className='text-danger' key={message.id}>
                {message.purchasePrice === message.newPrice
                  ? `${purchasePriceLabel} ${thousandFormatter(
                      message.purchasePrice?.toFixed(2)
                    )} is equal to new price ${thousandFormatter(
                      message.newPrice?.toFixed(2)
                    )}`
                  : `${purchasePriceLabel} ${thousandFormatter(
                      message.purchasePrice?.toFixed(2)
                    )} bigger then new price  ${thousandFormatter(
                      message.newPrice?.toFixed(2)
                    )}`}
              </p>
            ))
          : null}

        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} />
          <Button label='Continue' mode={'remove'} onClick={onContinue} />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
