import {useMutation, useQueryClient} from "react-query";
import {AxiosError} from "axios";
import {ICustomer, ICustomerRestrictions, ICustomerSettings, IUser} from "../../../../shared/types/user";
import { useAccountContext } from "shared/context/app-accout-context";
import { CustomerApi, DeleteFileParams, IUpdateCredentialsParams, IUpdateRestrictionsParams } from "app/api/user-api/customer-api";
import { CUSTOMER_QUERY_KEYS } from "shared/constants/query-keys";
import { useStateModalManager } from "shared/context/modal-manager";
import { DASHBOARD_MODAL_NAMES } from "shared/constants/modal-names";
import { useUserState } from "app/store/user/state";
import { toast } from "react-toastify";
import { selectAuthUserData } from "app/store/auth/selects";
import { selectUserData } from "app/store/user/selects";

export const useAccount = () => {
    const {customer} = useAccountContext()
    const user = selectUserData()
    const userId = selectAuthUserData()
    const {setProfile} = useUserState()
    const declineCustomerModalState = useStateModalManager(DASHBOARD_MODAL_NAMES.decline_customer_warning)
    const initialValues:ICustomer = {
      id: customer.id,
      user_id: customer.user_id,
      company_name: customer?.company_name||null,
      contact_email: customer?.contact_email||null,
      full_name: customer?.full_name||null,
      VAT_number: customer?.VAT_number||null,
      website: customer?.website||null,
      email:customer.email,
      company_registration_number: customer?.company_registration_number||null,
      country_of_incorporation_id:customer?.country_of_incorporation?.id||null,
      business_type: customer?.business_type||null,
      company_director:customer?.company_director||null,
      company_address: customer?.company_address||null,
      email_to_receive_digital_delivery_and_invoice: customer?.email_to_receive_digital_delivery_and_invoice||null,
      account_payable_contact: customer?.account_payable_contact||null,
      account_payable_email: customer?.account_payable_email||null,
      city: customer?.city||null,
      zip_post_code: customer?.zip_post_code||null,
      phone_number: customer?.phone_number||null,
      documents: null,
      documentList:customer?.documentList||null,
      _method: 'PUT',
      created_at: customer?.created_at || null,
      google2fa_enabled: customer?.google2fa_enabled || null
    }       
    const settingsValues:ICustomerSettings = {
        email:customer.email,
        is_order_email_enabled:customer.is_order_email_enabled,
        is_invoice_email_enabled:customer.is_invoice_email_enabled
    }

    const restrictionsValues:ICustomerRestrictions = {
      is_blocked:customer.is_blocked,
      is_api_blocked:customer.is_api_blocked,
  }

    const queryClient  = useQueryClient();

    const updateCustomerMutation = useMutation<
        ICustomer,
        AxiosError<{ message: string }>,
        ICustomer
    >(CustomerApi.updateCustomerInfo, {
        onSuccess: (customer) => {
          setProfile({...user,customer})
          queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customers])
          queryClient.setQueryData([CUSTOMER_QUERY_KEYS.customer,Number(customer.user_id)],{
            ...customer,
            email: user.email,
            is_blocked: user.is_blocked,
            is_api_blocked: user.is_api_blocked,
            is_invoice_email_enabled: user.is_invoice_email_enabled,
            is_order_email_enabled: user.is_order_email_enabled,
            google2fa_enabled: user.google2fa_enabled
          })
          queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customer,Number(customer.user_id)])
        },
        onError: (error:AxiosError) => {
          toast.error(error.response.data.message)
        }
    })
    
    const updateCustomerCredentialsMutation = useMutation<
        IUser,
        AxiosError<{ message: string }>,
        IUpdateCredentialsParams
    >(CustomerApi.updateCustomerCredentials, {
        onSuccess: (data:IUser) => {
          if (userId===customer.user_id) setProfile(data)
          queryClient.setQueryData([CUSTOMER_QUERY_KEYS.customer,customer.user_id],{
            ...data.customer,
            email:data.email,
            is_api_blocked:data.is_api_blocked,
            is_blocked:data.is_blocked,
            is_invoice_email_enabled:data.is_invoice_email_enabled,
            is_order_email_enabled:data.is_order_email_enabled
          })
          toast.success('Credentials was updated successfully!')         
        },
        onError: (error:AxiosError) => {
          toast.error(error.response.data.message)
        }
    })

    const updateCustomerRestrictionsMutation = useMutation<
        IUser,
        AxiosError<{ message: string }>,
        IUpdateRestrictionsParams
    >(CustomerApi.updateCustomerRestrictions, {
        onSuccess: (data:IUser) => {
          queryClient.setQueryData([CUSTOMER_QUERY_KEYS.customer,customer.user_id],(prevData:ICustomer)=> {
            return {            
              ...prevData, 
              is_api_blocked:data.is_api_blocked,               
              is_blocked:data.is_blocked              
            }
          })
          toast.success('Restrictions was updated successfully!')         
          queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customer,customer.user_id],{exact:true})
        },
        onError: (error:AxiosError) => {
          toast.error(error.response.data.message)
        }
    })

    const deleteFileMutation = useMutation<
        string,
        AxiosError<{ message: string }>,
        DeleteFileParams
    >(CustomerApi.deleteFile, {
        onSuccess: (data,variables) => {          
          queryClient.setQueryData([CUSTOMER_QUERY_KEYS.customer,customer.user_id], (data:ICustomer)=>{            
            return { ...data,documentList:data.documentList.filter((item)=>item.id!==variables.fileId) }
          })          
          return queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customer,customer.user_id],{exact:true});
        },
    })
  
    const approveCustomerMutation = useMutation<
        ICustomer,
        AxiosError<{ message: string }>,
        number
    >(CustomerApi.approveCustomer, {
        onSuccess: () => {
          queryClient.setQueryData([CUSTOMER_QUERY_KEYS.customer,customer.user_id], (data:ICustomer)=>{
            return { ...data,status:1 }
          })
          return queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customer,customer.user_id],{exact:true});
        },
    })

    const declineCustomerMutation = useMutation<
        ICustomer,
        AxiosError<{ message: string }>,
        number
    >(CustomerApi.declineCustomer, {
        onSuccess: () => {     
          declineCustomerModalState.close();    
          queryClient.setQueryData([CUSTOMER_QUERY_KEYS.customer,customer.user_id], (data:ICustomer)=>{            
            return { ...data,status:3 }
          })  
          return queryClient.invalidateQueries([CUSTOMER_QUERY_KEYS.customer,customer.user_id],{exact:true});
        },
    })

    return {
      updateCustomerMutation,
      deleteFileMutation,
      approveCustomerMutation,
      declineCustomerMutation,
      updateCustomerCredentialsMutation,
      updateCustomerRestrictionsMutation,
      initialValues,
      customer,
      settingsValues,
      restrictionsValues
    }

}