import React, {ChangeEvent, useEffect, useState} from 'react'
import {AxiosError} from 'axios'
import {useMutation, useQueryClient} from 'react-query'
import {toast} from 'react-toastify'
import {ButtonList} from 'shared/components/button-list/button-list'
import {OptionsApi} from 'app/api/options-api/options-api'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import {Modal} from 'shared/components/modal-base'
import {OPTIONS_MODAL_NAME} from 'shared/constants/modal-names'
import {OPTIONS_QUERY_KEYS} from 'shared/constants/query-keys'
import {useStateModalManager} from 'shared/context/modal-manager'
import {IRegion} from 'shared/types/base'

const INITIAL_VALUES = {
  name: '',
}

export const CreateRegionModal = () => {
  const [region, setRegion] = useState<IRegion>(INITIAL_VALUES)
  const modalState = useStateModalManager(OPTIONS_MODAL_NAME.create_region)

  const handleClose = () => {
    setRegion(INITIAL_VALUES)
    modalState.close()
  }

  const queryClient = useQueryClient()

  const createRegionMutation = useMutation<IRegion, AxiosError<{message: string}>, IRegion>(
    OptionsApi.createRegion,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([OPTIONS_QUERY_KEYS.regions])
        setRegion(INITIAL_VALUES)
        toast.success('Region created successfully!')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
      },
    }
  )

  const handleChangeName = (event: ChangeEvent<HTMLInputElement>) => {
    setRegion((prevData: IRegion) => {
      return {
        ...prevData,
        name: event.target.value,
      }
    })
  }

  const handleCreateClick = () => {
    createRegionMutation.mutate(region)
  }

  const isDisabled = createRegionMutation.isLoading || !region.name

  useEffect(() => {
    handleClose()
    createRegionMutation.reset()
  }, [createRegionMutation.isSuccess])

  return (
    <Modal.Root
      open={modalState.open}
      onClose={handleClose}
      isClosable={!createRegionMutation.isLoading}
    >
      <div className='p-5'>
        <h2 className='text-center'>Create Region</h2>
        <div className='d-flex justify-content-center align-items-end gap-5 my-10'>
          <div>
            <label>Name</label>
            <Input value={region.name} type='text' placeholder='Name' onChange={handleChangeName} />
          </div>          
        </div>
        <ButtonList className='justify-content-end'>
          <Button label='Cancel' onClick={handleClose} disabled={createRegionMutation.isLoading} />
          <Button
            label='OK'
            mainButton
            isLoading={createRegionMutation.isLoading}
            disabled={isDisabled}
            onClick={handleCreateClick}
          />
        </ButtonList>
      </div>
    </Modal.Root>
  )
}
