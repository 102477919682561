import React, {DragEvent, forwardRef, InputHTMLAttributes, useEffect, useId, useRef, useState} from 'react'
import clsx from 'clsx'
import mergeRefs from 'shared/utils/merge-refs'
import styled from './drag-uploader.module.scss'
import {Icon} from '../icon/icon'
import {ICON_COLLECTION} from '../icon/icon-list'
import {FormikErrors} from 'formik'
import {IFile} from 'shared/types/user'
import {Loader} from '../loader/loader'

interface DropzoneProps {
    name?: InputHTMLAttributes<HTMLInputElement>["name"];
    label?: string;
    accept?: Array<string>;
    multiple?: boolean;
    errorMessage?: string | string[] | FormikErrors<File|IFile>[];
    isLoading?: boolean;
    disabled?:boolean;
    onChange: (files: File[]) => void;
    classNames?: string;
    isCleared?: boolean;
}

const convertFileListToFileArray = (fileList: FileList) => {
  const files: File[] = []
  for (let i = 0; i < fileList.length; i++) {
    files.push(fileList[i])
  }
  return files
}

const DragUploader: React.ForwardRefRenderFunction<
    HTMLInputElement,
    DropzoneProps
> = (
    {
        name,
        label,
        accept,
        multiple = true,
        errorMessage,
        onChange,
        disabled,
        classNames,
        isLoading,
        isCleared,
    },
    ref
) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const dragCounter = useRef<number>(0)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  useEffect(()=>{
      if (disabled) setFiles(null);  
      if (isCleared) setFiles(null);
  },[disabled, isCleared]);


  const filesSelected = () => {  
    if (fileInputRef?.current?.files?.length) {
      handleFiles(fileInputRef.current.files)
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
  }
  }

  const [files,setFiles] = useState<File[]>(
     fileInputRef?.current?.files?.length 
    ? convertFileListToFileArray(fileInputRef.current.files) 
    : null
  );
  
  const handleFiles = (fileList: FileList) => {
    const files: File[] = convertFileListToFileArray(fileList)
    setFiles(files);
    onChange(files)
  }

  const preventDefault = (event: DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const dragOver = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event)
  }

  const dragEnter = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event)
    dragCounter.current++
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true)
    }
  }

  const dragLeave = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event)
    dragCounter.current--
    if (dragCounter.current > 0) return
    setIsDragging(false)
  }

  const fileDrop = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event)
    setIsDragging(false)
    if (event.dataTransfer.files.length) {
      handleFiles(event.dataTransfer.files)
      dragCounter.current = 0
    }
  }

  const id = useId()

  return (
    <label
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      htmlFor={`file-upload ${id}`}
      className={clsx('position-relative min-h-200px', styled.zone, classNames, {
        [styled['zone--error']]: !!errorMessage,
      })}
    >
      <input
        id={`file-upload ${id}`}
        className={styled['input-fide']}
        ref={mergeRefs(fileInputRef, ref)}
        name={name}
        disabled={disabled}
        type='file'
        accept={accept?.join(', ')}
        multiple={multiple}
        onChange={filesSelected}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <p className={styled.zone__title}>
            <Icon icon={ICON_COLLECTION.plus} />
          </p>

          <div className={clsx({'is-dragging': isDragging})}>
            {label && <span className={styled.here}>{label}</span>}
          </div>
          {files && (
                <div className="mt-3">
                    {files.map(({name})=>{
                        return (
                            <p key={name} className={clsx('m-0 p-0 ', styled.here,{
                                [styled["error"]]: !!errorMessage
                            })}>
                                {name}
                            </p>                            
                        )
                    })}
                </div>
            )}
        </>
      )}

      {errorMessage && (
        <div className={styled.error}>
          {(Array.isArray(errorMessage) && errorMessage.length > 1) ? errorMessage[0].toString() : errorMessage.toString()}
        </div>        
      )}
    </label>
  )
}

export const Dropzone = forwardRef(DragUploader)
