import React from 'react';
import styles from './radioButton.module.scss'
import clsx from "clsx";

interface IRadioButton {
    label?: string
    isActive: boolean
    className?: string
    onChange: () => void
}

const RadioButton: React.FC<IRadioButton> = ({label, isActive, className, onChange}) => {

    return (
        <div className={clsx('d-flex align-items-center', className)} onClick={onChange}>
            <button className={styles.button} type='button' >
                {isActive && <div className={clsx(
                    styles.indicator,
                    {[styles['indicator--active']]: isActive}
                )}/>}
            </button>
            {label && <label className={styles.label}>{label}</label>}
        </div>

    );
};

export default RadioButton;
