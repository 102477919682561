import React, {useEffect, useState} from 'react'
import {Button} from 'shared/components/button/button'
import {CUSTOMER_STATUS} from 'shared/constants/constants'
import {useAccountContext} from 'shared/context/app-accout-context'
import {STATUS_COLOR} from '../../components/table/customers-table-row'
import {useNavigate} from 'react-router-dom'
import {DASHBOARD_ROUTES} from 'shared/config/routes'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {CUSTOMER_ROUTES} from '../../constants/routes'
import {RowContainer} from 'modules/dashboard/account/components/row-container/row-container'
import {Toggle} from 'shared/components/toggle/toggle'
import {useMutation} from 'react-query'
import {CustomerApi, ITwoFactorAuthData} from 'app/api/user-api/customer-api'
import {AxiosError} from 'axios'
import {toast} from 'react-toastify'
import {Loader} from 'shared/components/loader/loader'

export const CustomerTwoFactorPage = () => {
  const {customer} = useAccountContext()
  const navigate = useNavigate()
  const isUserEnable2Fa = customer.google2fa_enabled
  const [isToggleEnabled, setIsToggleEnabled] = useState(isUserEnable2Fa)

  const handleGoBackClick = () => {
    navigate(DASHBOARD_ROUTES.CUSTOMERS.path)
  }

  const handleToggleClick = () => {
    setIsToggleEnabled(false)
  }

  const disable2FaMutation = useMutation<
    ITwoFactorAuthData,
    AxiosError<{error: string}>,
    number
  >(CustomerApi.disable2Fa, {
    onSuccess: () => {
      toast.success('Two Factor Authentication is disabled for this customer!')
    },
    onError: (error: AxiosError) => {
      toast.error(error.response.data.message)
    },
  })

  useEffect(() => {
    if (isToggleEnabled !== isUserEnable2Fa) {
      if (isToggleEnabled === false && isUserEnable2Fa === true) {
        disable2FaMutation.mutate(customer.user_id)
      }
    }
  }, [isToggleEnabled, isUserEnable2Fa])

  const isToggleDisabled =
    !!!isUserEnable2Fa || disable2FaMutation.isLoading || !isToggleEnabled

  return (
    <>
      <div className='d-md-flex justify-content-between align-items-center mb-5'>
        <h1 className='text-exl-primary mb-5 mb-md-0 d-flex align-items-center gap-5 flex-fill'>
          <Button
            label='<'
            type='button'
            onClick={handleGoBackClick}
            className='min-w-25px'
          />
          {customer.company_name}
          <span
            className={`badge badge-light-${STATUS_COLOR[customer.status]}`}
          >
            {CUSTOMER_STATUS[customer.status]}
          </span>
        </h1>
      </div>
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={CUSTOMER_ROUTES(customer)} className='mb-5' />
        <RowContainer label='2FA for account'>
          <div className='mw-75px position-relative'>
            {disable2FaMutation.isLoading && <Loader mode='blur' />}
            <Toggle
              isActive={isToggleEnabled}
              setActive={handleToggleClick}
              disabled={isToggleDisabled}
            />
          </div>
        </RowContainer>
      </div>
    </>
  )
}
