import {Ability, AbilityBuilder} from '@casl/ability';

import {IUser} from 'shared/types/user';

import {APP_ROLES} from 'shared/constants/constants';

export enum RouteGuardActions {
    create = 'create',
    read = 'read',
    update = 'update',
    delete = 'delete',
    manage = 'manage',
}

export enum RouteGuardEntities {
    Admin = 'Admin',
    Customer = "Customer",
    UnapprovedCustomer = "UnapprovedCustomer",
    CustomerNotAdmin = 'CustomerNotAdmin',
    Users = 'Users'
}

type Action = RouteGuardActions;
type Subject = RouteGuardEntities;
export type AppAbility = Ability<[Action, Subject]>;
export default function defineAbilityFor(user?: IUser | null) {
    const {can, build} = new AbilityBuilder<AppAbility>(Ability);


    if (
        user?.role.some((roleE) =>
            [
                APP_ROLES.ADMIN,
                APP_ROLES.CUSTOMER,
            ].includes(roleE),)
    ) {
        can(RouteGuardActions.manage, RouteGuardEntities.Customer);
    }    
    if (user?.role.includes(APP_ROLES.ADMIN)) {
        can(RouteGuardActions.manage, RouteGuardEntities.Admin);
    }       
    if (user?.role.includes(APP_ROLES.CUSTOMER)) {
        can(RouteGuardActions.manage, RouteGuardEntities.CustomerNotAdmin);
    } 
    if (user?.role.includes(APP_ROLES.UNAPPROVED_CUSTOMER)) {
        can(RouteGuardActions.manage, RouteGuardEntities.UnapprovedCustomer);
    }  
    if (
        user?.role.some((roleE) =>
            [
                APP_ROLES.UNAPPROVED_CUSTOMER,
                APP_ROLES.CUSTOMER,
            ].includes(roleE),)
    ) {
        can(RouteGuardActions.manage, RouteGuardEntities.Users);
    } 

    return build();
}
