import {useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useLocation, useSearchParams} from 'react-router-dom'
import {INVOICE_QUERY_KEYS} from 'shared/constants/query-keys'
import {IOption} from 'shared/components/select/select'
import {useDebounce} from '_metronic/helpers'
import {removeEmptyFields} from 'shared/utils/remove-empty-fields'
import {initialFormName} from './all-invoices-page'
import {
  IApproveInvoiceParams,
  IDeleteInvoiceParams,
  IInvoicesList,
  InvoiceApi,
} from 'app/api/invoices-api/invoices-api'
import {selectAuthUserData} from 'app/store/auth/selects'
import {DownloadService} from 'shared/services/download-service'
import {toast} from 'react-toastify'
import {useModalManager} from 'shared/context/modal-manager'
import {INVOICES_MODAL_NAME} from 'shared/constants/modal-names'
import {selectWalletsData} from '../../../../app/store/wallets/selects'
import {IInvoice} from 'shared/types/invoice'
import {AxiosError} from 'axios'
import {DownloadReportApi} from 'app/api/dashboard-api/download-report-api'
import {BlobErrorResponse} from 'shared/helpers/blob-error-response'

export const useInvoicesPage = () => {
  const user = selectAuthUserData()
  const [isPDFsLoading, setIsPDFsLoading] = useState<boolean>(false)
  const [isCSVLoading, setIsCSVLoading] = useState<boolean>(false)
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [searchValue, setSearchValue] = useState<string>(
    search.get('search') || ''
  )
  const queryClient = useQueryClient()
  const modalManager = useModalManager()
  const wallets = selectWalletsData()
  const handleCreateInvoiceClick = () => {
    modalManager.open(INVOICES_MODAL_NAME.create_invoice, {user, wallets})
  }

  const queryParams = useMemo(() => {
    return removeEmptyFields({
      search: search.get('search'),
      status: Number(search.get('status')) || null,
      source: Number(search.get('source')) || null,
      type: Number(search.get('type')) || null,
      start_date: search.get('start_date'),
      end_date: search.get('end_date'),
      page: search.get('page') ? Number(search.get('page')) : 1,
    })
  }, [location.search])

  const invoicesQuery = useQuery({
    queryKey: [INVOICE_QUERY_KEYS.all_invoices, queryParams],
    queryFn: async () => await InvoiceApi.getAllInvoices(queryParams),
    refetchOnWindowFocus: false,
  })

  const invoicesChecked = useMemo(() => {
    if (invoicesQuery.data) {
      return invoicesQuery.data.data.filter((invoice) => invoice.checked)
    } else {
      return []
    }
  }, [invoicesQuery.data])

  const handleCheckboxChangeQuery = (id: number) => {
    const newData = invoicesQuery.data.data.map((item) =>
      item.id === id ? {...item, checked: !item.checked} : item
    )
    queryClient.setQueryData([INVOICE_QUERY_KEYS.all_invoices, queryParams], {
      ...invoicesQuery.data,
      data: newData,
    })
    setIsSelectedAll(newData.every((item) => item.checked))
  }

  const handleSelectAllQuery = () => {
    const data = invoicesQuery.data.data.map((item) => ({
      ...item,
      checked: !isSelectedAll,
    }))
    queryClient.setQueryData([INVOICE_QUERY_KEYS.all_invoices, queryParams], {
      ...invoicesQuery.data,
      data: data,
    })
    setIsSelectedAll(!isSelectedAll)
  }

  const manageParams = (name: string, value: string | number | null) => {
    const ParamsList = {
      search: queryParams.search ? {search: queryParams.search} : {},
      status: queryParams.status ? {status: `${queryParams.status}`} : {},
      type: queryParams.type ? {type: `${queryParams.type}`} : {},
      source: queryParams.source ? {source: `${queryParams.source}`} : {},
      start_date: queryParams.start_date
        ? {start_date: queryParams.start_date}
        : {},
      end_date: queryParams.end_date ? {end_date: queryParams.end_date} : {},
      page: queryParams.page ? {page: `${queryParams.page}`} : {},
    }
    ParamsList[name] = value !== null && value ? {[name]: `${value}`} : {}
    return ParamsList
  }

  const debouncedSearchTerm = useDebounce(searchValue, 1000)

  useEffect(() => {
    const params = manageParams('search', debouncedSearchTerm)
    setSearchParams({
      ...params.search,
      ...params.status,
      ...params.source,
      ...params.type,
      ...params.start_date,
      ...params.end_date,
    })
  }, [debouncedSearchTerm])

  const resetCheckedState = () => {
    if (invoicesQuery && invoicesQuery.data) {
      queryClient.setQueryData(
        [INVOICE_QUERY_KEYS.all_invoices, queryParams],
        (prevState: IInvoicesList) => {
          const data = prevState.data.map((item) => ({
            ...item,
            checked: false,
          }))
          return {
            ...prevState,
            data: data,
          }
        }
      )
    }
    setIsSelectedAll(false)
  }

  const handleChangeFormValue = (name: initialFormName, value: IOption) => {
    const params = manageParams(name, value ? value.id : null)
    setSearchParams({
      ...params.search,
      ...params.status,
      ...params.source,
      ...params.type,
      ...params.start_date,
      ...params.end_date,
    })
    resetCheckedState()
  }
  const handleDateChangeFormValue = (startDate: string, endDate: string) => {
    const params = manageParams('page', 1)
    if (startDate === '' || endDate === '')
      setSearchParams({
        ...params.search,
        ...params.status,
        ...params.source,
        ...params.type,
      })
    else
      setSearchParams({
        ...params.search,
        ...params.status,
        ...params.source,
        ...params.type,
        start_date: startDate,
        end_date: endDate,
      })
    resetCheckedState()
  }

  const setCurrentPage = (page: number) => {
    setIsSelectedAll(false)
    const data = invoicesQuery.data.data.map((item) => ({
      ...item,
      checked: false,
    }))
    queryClient.setQueryData([INVOICE_QUERY_KEYS.all_invoices, queryParams], {
      ...invoicesQuery.data,
      data: data,
    })
    const params = manageParams('page', page)
    setSearchParams({
      ...params.search,
      ...params.status,
      ...params.source,
      ...params.type,
      ...params.page,
      ...params.start_date,
      ...params.end_date,
    })
  }

  const idsToDownload = useMemo(() => {
    return invoicesChecked.map((invoice) => invoice.id)
  }, [invoicesChecked])

  const onDownloadPDFs = async () => {
    try {
      setIsPDFsLoading(true)
      const params = {
        search: queryParams.search,
        start_date: queryParams.start_date,
        status: queryParams.status,
        source: queryParams.source,
        type: queryParams.type,
        end_date: queryParams.end_date,
      }
      const response = await InvoiceApi.getAdminInvoicesPDFs(
        idsToDownload,
        params
      )
      DownloadService.downloadObjectAsZip(response, 'Invoices')
      setIsPDFsLoading(false)
      resetCheckedState()
    } catch (error) {
      setIsPDFsLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const approveInvoiceMutation = useMutation<
    IInvoice,
    AxiosError<{message: string}>,
    IApproveInvoiceParams
  >(InvoiceApi.approveInvoice, {
    onSuccess: (approvedInvoice: IInvoice) => {
      toast.success(
        `Invoice ${approvedInvoice.number} was approved successfully!`
      )
      const invoiceId = invoicesQuery.data.data.findIndex(
        ({id}) => approvedInvoice.id === id
      )
      invoicesQuery.data.data.splice(invoiceId, 1, approvedInvoice)
      queryClient.setQueryData([INVOICE_QUERY_KEYS.all_invoices, queryParams], {
        ...invoicesQuery.data,
        data: invoicesQuery.data.data,
      })
      queryClient.invalidateQueries([
        INVOICE_QUERY_KEYS.all_invoices,
        queryParams,
      ])
    },
    onError: (data: AxiosError<{message: string}>) => {
      toast.error(data.response.data.message)
    },
  })

  const handleApproveInvoiceClick = (user: number, invoice: number) => {
    approveInvoiceMutation.mutate({user, invoice})
  }

  const onDownloadCSV = async () => {
    try {
      setIsCSVLoading(true)
      const params = {
        search: queryParams.search,
        start_date: queryParams.start_date,
        status: queryParams.status,
        source: queryParams.source,
        type: queryParams.type,
        end_date: queryParams.end_date,
      }
      const csvData = await DownloadReportApi.getInvoiceReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Invoices Report')
      setIsCSVLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const deleteInvoiceMutation = useMutation<
    string,
    AxiosError<{message: string}>,
    IDeleteInvoiceParams
  >(InvoiceApi.deleteInvoice, {
    onSuccess: (data, variable) => {
      queryClient.setQueryData(
        [INVOICE_QUERY_KEYS.all_invoices, queryParams],
        (prevData: IInvoicesList) => {
          const tempInvoices = prevData.data.filter(
            (invoice) => invoice.id !== variable.invoiceId
          )
          return {...prevData, data: tempInvoices}
        }
      )
      queryClient.invalidateQueries([
        INVOICE_QUERY_KEYS.all_invoices,
        queryParams,
      ])
      toast.success('Invoice delete successfully!')
    },
    onError: (error: AxiosError<{message: string}>) => {
      toast.error(error?.response.data.message)
    },
  })

  const onDeleteInvoiceClick = (invoiceId: number) => {
    modalManager
      .open(INVOICES_MODAL_NAME.delete_admin_invoice)
      .then(() => {
        deleteInvoiceMutation.mutate({userId: user, invoiceId})
      })
      .catch((error) => {
        return
      })
  }

  const isApproveLoading = (invoice: number) =>
    approveInvoiceMutation.isLoading &&
    approveInvoiceMutation.variables.invoice === invoice

  const isTableLoading = invoicesQuery.isLoading

  return {
    models: {
      values: queryParams,
      invoices: invoicesQuery.data ? invoicesQuery.data.data : [],
      meta: invoicesQuery.data ? invoicesQuery.data.meta : null,
      isTableLoading,
      searchValue,
      isSelectedAll,
      isPDFsLoading,
      invoicesChecked,
      isCSVLoading,
    },
    commands: {
      handleChangeFormValue,
      handleDateChangeFormValue,
      setCurrentPage,
      setSearchValue,
      handleSelectAllQuery,
      handleCheckboxChangeQuery,
      onDownloadPDFs,
      isApproveLoading,
      handleCreateInvoiceClick,
      handleApproveInvoiceClick,
      onDownloadCSV,
      onDeleteInvoiceClick,
    },
  }
}
