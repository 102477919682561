import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button} from 'shared/components/button/button'
import {Input} from 'shared/components/input/input'
import clsx from 'clsx'
import styles from './product-item.module.scss'
import mocImg from 'shared/assets/images/placehoder.jpg'
import {IProductCatalogItem} from 'shared/types/products'
import {Icon} from 'shared/components/icon/icon'
import {ICON_COLLECTION} from 'shared/components/icon/icon-list'
import {Tooltip} from 'shared/components/tooltip/tooltip'
import {Link} from 'react-router-dom'
import {PRODUCT_ROUTES} from 'shared/config/routes'
import {
  _ProductStatusController,
  StatusProductItemType,
  STOCK_AMOUNT_FLAG,
} from './status-config'
import {toast} from 'react-toastify'
import {useDebounce} from '../../../../../_metronic/helpers'
import {CurrencyFormatter} from 'shared/helpers/currency-formatter'
import { decimalFormatter } from 'shared/helpers/decimalFormatter'

interface IProductItemProps
  extends Omit<IProductCatalogItem, 'categories' | 'product_type'> {
  onCreatePreOrder: (productId: number, quantity: number) => void
  handelAddProductToCart: (quantity: number, productId: number) => void
  isLoading: boolean
}

const IS_INVALID_AMOUT_INIT_VALUE = {
  isInvalid: false,
  isDisable: false,
}
const DEBOUNCE_AMOUNT_DALEY = 1000
const ProductItemComponent: React.FC<IProductItemProps> = (props) => {
  const {
    id,
    name,
    description,
    image,
    price,
    is_hidden,
    is_preorder_unlimited,
    quantity,
    stock,
    region,
    currency,
    preorder_quantity,
    preorder_avaibable,
    onCreatePreOrder,
    handelAddProductToCart,
    isLoading,
    discount,
    face_price,
  } = props

  const [amount, setAmount] = useState<number | ''>(1)

  const [isInvalidAmount, setIsInvalidAmount] = useState(
    IS_INVALID_AMOUT_INIT_VALUE
  )
  const ProductStatusController = new _ProductStatusController({
    isHidden: is_hidden,
    isUnlimited: is_preorder_unlimited,
    quantity: quantity,
    preorderQuantity: preorder_quantity,
    preorderAvailable: preorder_avaibable,
    stock: stock,
  })
  const businessStatus: StatusProductItemType =
    ProductStatusController.getStatus()
  const isStock = stock && quantity > 0
  const isPreorder = preorder_avaibable && preorder_quantity > 0

  const imagePath = image ? image.original_url : mocImg

  const stockValue = isStock ? quantity : '0'
  const preorderValue = isPreorder ? preorder_quantity : '0'

  const general_price = Array.isArray(price) ? price[0] : price
  const general_discount = Array.isArray(discount)
    ? discount[0]
      ? discount[0]
      : 0
    : discount
    ? discount
    : 0

  const stockAmountCodes = isStock ? stockValue : preorderValue

  const setMaxAmount = () => {
    setIsInvalidAmount(IS_INVALID_AMOUT_INIT_VALUE)
    toast.dismiss()
    if (isStock) {
      setAmount(quantity)
    } else {
      setAmount(preorder_quantity)
    }
  }
  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    const formattedValue = newValue ? decimalFormatter(newValue) : null
    setAmount(formattedValue)
    const amountCondition = businessStatus.getAmountCondition(
      formattedValue,
      preorder_quantity,
      quantity
    )
    if (amountCondition.isValid === null) return
    if (!amountCondition.isValid) {
      setIsInvalidAmount((prevState) => ({...prevState, isDisable: true}))
    } else {
      setIsInvalidAmount(IS_INVALID_AMOUT_INIT_VALUE)
    }
  }

  const handelCreatePreOrder = () => {
    onCreatePreOrder(Number(amount), id)
  }
  const addToCart = () => {
    handelAddProductToCart(Number(amount), id)
  }
  useEffect(() => {
    setAmount(businessStatus.initProductAmount)
  }, [businessStatus.initProductAmount])

  const debouncedAmountTerm = useDebounce(amount, DEBOUNCE_AMOUNT_DALEY)

  useEffect(() => {
    const amountCondition = businessStatus.getAmountCondition(
      Number(debouncedAmountTerm),
      preorder_quantity,
      quantity
    )
    if (amountCondition.isValid === null) return
    if (!amountCondition.isValid) {
      setIsInvalidAmount((prevState) => ({...prevState, isInvalid: true}))
      toast.dismiss()
      toast.error(amountCondition.errorMessage, {
        autoClose: 3000,
      })
    } else {
      setIsInvalidAmount(IS_INVALID_AMOUT_INIT_VALUE)
      toast.dismiss()
    }
  }, [debouncedAmountTerm])

  return (
    <div className={clsx(styles.card, 'row flex-wrap flex-xl-nowrap ')}>
      <div className='col col-xl-3 col-xxl-4  d-flex align-items-center '>
        <Link
          to={PRODUCT_ROUTES.SINGLE_PRODUCT.generate(`${id}`)}
          className={clsx(styles.card__section)}
        >
          <img src={imagePath} alt='' className={styles.card__img} />
          <p className={styles.card__name}>{name || '----'} </p>
        </Link>
      </div>
      <div className='col col-xl-9 col-xxl-8 row flex-column flex-xl-row'>
        <div className='col col-xl-6 d-flex flex-wrap align-items-center justify-content-between my-5 my-xl-0'>
          <div className={clsx(styles.params, 'col-6 col-xl')}>
            <p className={styles.params__name}>Region</p>
            <p className={styles.params__value}>
              {region ? region.name : '--'}
            </p>
          </div>
          <div className={clsx(styles.params, 'col-6 col-xl')}>
            <p className={styles.params__name}>Price</p>
            <p className={clsx(styles.params__value, 'd-flex')}>
              <span>
                {general_price
                  ? CurrencyFormatter(general_price, currency)
                  : '--'}
              </span>

              {Array.isArray(price) && price.length === 2 && (
                <Tooltip
                  placement='left'
                  content={`The minimum and maximum prices of the product are ${
                    price[0] ? CurrencyFormatter(price[0], currency) : '--'
                  } and ${
                    price[1] ? CurrencyFormatter(price[1], currency) : '--'
                  }`}
                >
                  <Icon className='mx-3' icon={ICON_COLLECTION.questionMark} />
                </Tooltip>
              )}
            </p>
          </div>
          <div className={clsx(styles.params, 'col-6 col-xl')}>
            <p className={styles.params__name}>Discount</p>
            <p className={clsx(styles.params__value, 'd-flex')}>
              <span>{`${general_discount}%`}</span>

              {Array.isArray(discount) && discount.length === 2 && (
                <Tooltip
                  placement='left'
                  content={`The minimum and maximum discounts of the product are ${
                    discount[1]
                      ? `${discount[1]}%`
                      : '--'
                  } and ${
                    discount[0]
                      ? `${discount[0]}%`
                      : '--'
                  }`}
                >
                  <Icon className='mx-3' icon={ICON_COLLECTION.questionMark} />
                </Tooltip>
              )}
            </p>
          </div>
          <div className={clsx(styles.params, 'col-6 col-xl')}>
            <p className={styles.params__name}>{businessStatus.name}</p>
            <p className={styles.params__value}>
              {businessStatus.amount === STOCK_AMOUNT_FLAG
                ? stockAmountCodes
                : businessStatus.amount}
            </p>
          </div>
        </div>

        <div
          className={clsx(
            'col col-xl-6 d-flex align-items-center justify-content-between '
          )}
        >
          <Button
            label={'Max'}
            mode='small'
            onClick={setMaxAmount}
            disabled={businessStatus.is_disabled_max_button || isLoading}
          />

          <Input
            type='number'
            mode='number'
            className='mw-75px w-100 mx-2'
            min={0}
            step={1}
            integerLimit={4}
            error={isInvalidAmount.isInvalid}
            onChange={handleNumberChange}
            disabled={businessStatus.is_disabled_input || isLoading}
            value={amount}
          />

          <div className=' text-end'>
            <Button
              label={businessStatus.btn_name}
              mainButton
              isLoading={isLoading}
              onClick={
                businessStatus.is_in_stock_btn
                  ? addToCart
                  : handelCreatePreOrder
              }
              disabled={
                isInvalidAmount.isDisable ||
                businessStatus.is_disabled_action_button ||
                isLoading
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export const ProductItem = React.memo(ProductItemComponent)
