import {AuthApi, IResetPasswordDto, LoginDataDto, RegisterDataDto} from "app/api/auth-api/auth-api";
import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {IUser} from "../../../shared/types/user";
import {useAuthState} from "../../../app/store/auth/state";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTES } from "shared/config/routes";


export interface IFormikProps {
    email: string;
    firstname: string;
    lastname: string;
    password: string;
    changepassword: string;
}

export const useAuth = () => {
    const navigate = useNavigate()
    const {setUser} = useAuthState();
    const [error,setError] = useState<string>(null)
    const registerMutation = useMutation<
        { data: { token: string, user: IUser } },
        AxiosError<{ message: string }>,
        RegisterDataDto
    >(AuthApi.register, {
        onSuccess: (data) => {
            setUser(data.data.token, data.data.user.id)
        },
    });

    const loginMutation = useMutation<
        {data: {token: string, user: IUser } },
        AxiosError<{ message: string }>,
        LoginDataDto
    >(AuthApi.login, {
        onSuccess: async (data) => {
            if (data.data.user.google2fa_enabled) {
                const navData = {
                  token: data.data.token,
                  userId: data.data.user.id,
                }
                navigate(AUTH_ROUTES.TWO_FACTOR_AUTH.path, {state: navData})
            } else {
                setUser(data.data.token, data.data.user.id)
                setError(null)
            }
        },
        onError:async (error:AxiosError<{ message: string }>) => {
            setError(error.response.data.message)
        },
    });

    const sendForgotPasswordMutation = useMutation<
        string,
        AxiosError<{ message: string }>,
        string
    >(AuthApi.forgotPassword, {
        onSuccess: (res, payload) => {
            setError(null)
        },
        onError:async (error:AxiosError<{ message: string }>) => {
            setError(error.response.data.message)
        },
    });

    const codeCheckMutation = useMutation<
        string,
        AxiosError<{ message: string }>,
        string>(AuthApi.codeCheck);
    const resetPasswordMutation = useMutation<
        string,
        AxiosError<{ message: string }>,
        IResetPasswordDto>(AuthApi.resetPassword);
    return {
        registerMutation,
        loginMutation,
        sendForgotPasswordMutation,
        codeCheckMutation,
        resetPasswordMutation,
        error
    }

}