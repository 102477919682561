import React, {ChangeEvent, useState} from 'react'
import { ActionsButton, IActionsButtons } from 'shared/components/button/actions-button'
import {Input} from 'shared/components/input/input'
import {Loader} from 'shared/components/loader/loader'
import {ICurrency} from 'shared/types/codes-upload'

interface ICurrenciesTableRow {
  currency: ICurrency
  isSaveButtonLoading: boolean
  handleSaveChanges: (currency: ICurrency) => void
  handleDeleteClick: (currency: ICurrency) => void
}

const CurrenciesTableRowComponent: React.FC<ICurrenciesTableRow> = ({
  currency,
  isSaveButtonLoading,
  handleSaveChanges,
  handleDeleteClick,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [currencyData, setCurrencyData] = useState<ICurrency>(currency)
  const handleEditClick = () => {
    setIsEditing(true)
  }
  const handleCancelClick = () => {
    setCurrencyData(currency)
    setIsEditing(false)
  }
  const handleDeleteCurrency = () => {
    handleDeleteClick(currency)
  }
  const handleChangeValues = (name:string) => (event: ChangeEvent<HTMLInputElement>) => {
    setCurrencyData((prevData: ICurrency) => {
      return {
        ...prevData,
        [name]: event.target.value,
      }
    })
  }
  const handleSaveChangesClick = () => {
    handleSaveChanges(currencyData)
    setIsEditing(false)
  }
  const CURRENCIES_ACTION_BUTTONS: IActionsButtons[] = [
    {
      label: isEditing ? 'Cancel' : 'Edit',
      onClick: isEditing ? handleCancelClick : handleEditClick,
    },
    {
      label: isEditing ? 'Save' : 'Delete',
      onClick: isEditing ? handleSaveChangesClick : handleDeleteCurrency,
    },
  ]
  return (
    <tr className='border-bottom'>
      <td>
        <Input
          value={currencyData.name}
          disabled={!isEditing}
          readOnly={!isEditing}
          onChange={handleChangeValues('name')}
          placeholder='Name'
        />
      </td>
      <td>
        <Input
          value={currencyData.iso}
          disabled={!isEditing}
          readOnly={!isEditing}
          onChange={handleChangeValues('iso')}
          placeholder='ISO'
        />
      </td>
      <td>
        <Input
          value={currencyData.symbol}
          disabled={!isEditing}
          readOnly={!isEditing}
          onChange={handleChangeValues('symbol')}
          placeholder='Symbol'
        />
      </td>
      <td className='d-flex flex-end border-0 h-75px'>
        <div className='d-flex gap-5 position-relative flex-end w-150px'>
          <ActionsButton label='Actions' buttons={CURRENCIES_ACTION_BUTTONS}/>
          {isSaveButtonLoading && <Loader classNane='bg-white justify-content-end' />}
        </div>
      </td>
    </tr>
  )
}

export const CurrenciesTableRow = React.memo(CurrenciesTableRowComponent)
