import * as React from 'react';
import styles from './icon.module.scss'

import {IconCollectionType} from './icon-list';
interface IconProps {
    className?: string;
    icon: IconCollectionType;
}


export const Icon: React.FC<IconProps> = ({ className, icon }) => {
    const Component = icon;

    return (
        <span className={`${styles.icon} ${className}`}>
            <Component />
        </span>
    );
};
