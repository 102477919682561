import React, {useEffect, useState} from 'react'
import {Can, useAbilityContext} from 'casl'
import {useMenuList} from 'shared/hooks/use-menu-list'
import {TabMenu} from 'shared/components/tab-menu/tab-menu'
import {Button} from 'shared/components/button/button'
import {WalletHistory} from '../../../wallet/components/wallet-history/wallet-history'
import {WalletsList} from '../../../wallet/components/wallets-list/wallets-list'
import {useModalManager} from 'shared/context/modal-manager'
import {
  INVOICES_MODAL_NAME,
  WALLETS_MODAL_NAMES,
} from 'shared/constants/modal-names'
import {CreateWalletModal} from '../../../wallet/components/modals/create-wallet-modal'
import {ButtonList} from 'shared/components/button-list/button-list'
import {selectUserData} from 'app/store/user/selects'
import {
  selectDefaultWallet,
  selectIsLoadingWallets,
  selectWalletsData,
} from 'app/store/wallets/selects'
import {WalletsApi} from 'app/api/dashboard-api/wallets-api'
import {useFilterRequest} from 'shared/hooks/use-filter-request'
import {
  IWallet,
  IWalletDefaultPrams,
  IWalletInvoiceResponse,
  IWalletInvoicesParams,
} from 'shared/types/api-types/wallets-type'
import {Loader} from 'shared/components/loader/loader'
import {Pagination} from '../../../components/pagination/pagination'
import {RouteGuardActions, RouteGuardEntities} from 'casl/ability'
import {useMutation} from 'react-query'
import {AxiosError} from 'axios'
import {useWalletsState} from 'app/store/wallets/state'
import {toast} from 'react-toastify'
import {AdjustWalletModal} from '../../../wallet/components/modals/adjust-wallet/adjust-wallet-modal'
import {GLOBAL_EVENTS} from 'shared/utils/event-bus'
import {useEvent} from 'shared/hooks/use-event'
import {CreditBalanceModal} from '../../../wallet/components/modals/credit-balance-modal'
import {CreateInvoiceModal} from '../../../invoices/components/modals/create-invoice-modal'
import {IInvoice} from '../../../../../shared/types/invoice'
import {
  IApproveInvoiceParams,
  InvoiceApi,
} from '../../../../../app/api/invoices-api/invoices-api'
import { DownloadService } from 'shared/services/download-service'
import { BlobErrorResponse } from 'shared/helpers/blob-error-response'
import { DownloadReportApi } from 'app/api/dashboard-api/download-report-api'
import { ScrollTopComponent } from '_metronic/assets/ts/components'

export const AccountWalletsPage = () => {
  const ability = useAbilityContext()
  const {accountLinks} = useMenuList(ability)
  const modalManager = useModalManager()
  const user = selectUserData()
  const {setDefaultWallet, getWallets} = useWalletsState()
  const wallets = selectWalletsData()
  const isLoadingWallets = selectIsLoadingWallets()
  const [isCSVWalletsLoading, setIsCSVWalletsLoading] = useState<boolean>(false)
  const [isCSVInvoicesLoading, setIsCSVInvoicesLoading] = useState<boolean>(false)
  const defaultWallet = selectDefaultWallet()
  const [activeWallet, setActiveWallet] = useState<null | number>(
    defaultWallet?.id
  )
  const activeWalletFull = wallets.find((wallet) => activeWallet === wallet.id)
  const [page, setPage] = useState<null | number>(1)

  const setCurrentPage = (page: number) => setPage(page)
  const handleCreateWallet = () => {
    modalManager.open(WALLETS_MODAL_NAMES.create_wallet, user.id)
  }

  const getFindParams = useEvent((params) => {
    const resultedParams = {
      user: user.id,
      wallet: activeWallet,
      signal: params.signal,
      page: page,
    }
    return resultedParams
  })

  const {
    data: invoicesData,
    searchData: searchGroups,
    isInitialLoading: isLoadingGroups,
    isSearching: isSearchingGroups,
    isInitialLoading,
    isRefetching,
    isSearching,
  } = useFilterRequest<
    IWalletInvoiceResponse,
    undefined,
    IWalletInvoicesParams
  >({
    requestKey: GLOBAL_EVENTS.reset_invoice,
    request: (params) => {
      return WalletsApi.getWalletInvoices(getFindParams(params))
    },
    manualTriggering: true,
    searchFuncDependencies: [activeWallet],
  })

  useEffect(() => {
    if (activeWallet) {
      ScrollTopComponent.goTop()
      searchGroups()
    }
  }, [activeWallet, page])

  const invoiceList = invoicesData?.data ?? []
  const invoiceMeta = invoicesData?.meta ?? null

  const setDefaultWalletMutation = useMutation<
    IWallet,
    AxiosError<{message: string}>,
    IWalletDefaultPrams
  >(WalletsApi.setDefaultWallet, {
    onSuccess: (data) => {
      setDefaultWallet(data)
    },
    onError: (error) => {
      toast.error(error.response.data.message)
    },
  })
  const approveInvoiceMutation = useMutation<
    IInvoice,
    AxiosError<{error: string}>,
    IApproveInvoiceParams
  >(InvoiceApi.approveInvoice, {
    onSuccess: (data) => {
      getWallets(user.id)
      searchGroups()
    },
    onError: (error) => {
      toast.error(error.response.data.error)
    },
  })
  const onApproveInvoice = (invoiceId: number) => {
    approveInvoiceMutation.mutate({
      user: user.id,
      invoice: invoiceId,
    })
  }
  const handelSetDefault = (userid: number) => (walletId: number) => {
    setDefaultWalletMutation.mutate({user: userid, wallet: walletId})
  }
  const onEditAdjust = () => {
    if (activeWallet) {
      modalManager.open(WALLETS_MODAL_NAMES.adjust_wallet, {
        user: user.id,
        wallet: activeWallet,
        currency: activeWalletFull.currency.symbol
      })
      setPage(1)
    } else {
      toast.warning('You need to choose a wallet')
    }
  }
  const onChangeWallet = (wallet: number) => {
    setActiveWallet((prevState) => {
      setPage(1)
      return wallet
    })
  }
  const onEditCreditBalance = () => {
    if (activeWallet) {
      const activeIndex = wallets.findIndex(
        (wallet) => wallet.id === activeWallet
      )
      modalManager.open(WALLETS_MODAL_NAMES.credit_balance, {
        user: user.id,
        wallet: activeWallet,
        credit_balance: wallets[activeIndex].credit_limit,
        currency: activeWalletFull.currency.symbol,
      })
      setPage(1)
    } else {
      toast.warning('You need to choose a wallet')
    }
  }
  const handleCreateInvoiceClick = () => {
    modalManager.open(INVOICES_MODAL_NAME.create_invoice, {
      user: user.id,
      wallets,
    })
  }

  const onDownloadCSVWallets = async () => {
    try {
      setIsCSVWalletsLoading(true)
      const params = {
        user:user.id
      }
      const csvData = await DownloadReportApi.getWalletsReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Wallets Report')
      setIsCSVWalletsLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVWalletsLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const onDownloadCSVInvoices = async () => {
    try {
      setIsCSVInvoicesLoading(true)
      const params = {
        wallet:activeWallet
      }
      const csvData = await DownloadReportApi.getInvoiceReportCSV(params)
      DownloadService.downloadObjectAsZip(csvData, 'Wallets Report')
      setIsCSVInvoicesLoading(false)
      toast.success('Download CSV Success!')
    } catch (error) {
      setIsCSVInvoicesLoading(false)
      toast.error(await BlobErrorResponse(error))
    }
  }

  const isLoadingInvoiceHistory =
    isLoadingGroups ||
    isSearchingGroups ||
    isInitialLoading ||
    isRefetching ||
    isSearching ||
    approveInvoiceMutation.isLoading
  return (
    <>
      <div className='d-md-flex justify-content-between align-items-start mb-5'>
        <h1 className='text-exl-primary mb-5 mb-md-0'>My Account</h1>
        <ButtonList>
          <Button
            label='Add new Wallet'
            mainButton
            onClick={handleCreateWallet}
          />
          {wallets && wallets.length ? (
            <Button label='Top Up' onClick={handleCreateInvoiceClick} />
          ) : null}
          <Button
            label='Export wallets'
            type='button'
            isLoading={isCSVWalletsLoading}
            disabled={isCSVWalletsLoading}
            onClick={onDownloadCSVWallets}
          />
        </ButtonList>
      </div>
      <div className='bg-body rounded shadow-sm p-10 mx-auto position-relative'>
        <TabMenu routes={accountLinks} />
        <WalletsList
          className='mb-5 mt-7'
          isLoading={
            setDefaultWalletMutation.isLoading ||
            approveInvoiceMutation.isLoading ||
            isLoadingWallets
          }
          handelSetDefault={handelSetDefault(user.id)}
          wallets={wallets}
          activeWallet={activeWallet}
          setActiveWallet={onChangeWallet}
        />        
          <ButtonList className='justify-content-end mb-5'>
            <Button
              label='Export invoices'
              type='button'
              isLoading={isCSVInvoicesLoading}
              disabled={isCSVInvoicesLoading}
              onClick={onDownloadCSVInvoices}
            />
            <Can I={RouteGuardActions.manage} a={RouteGuardEntities.Admin}>
            <Button
              label='Adjust Wallet Balance'
              mainButton
              onClick={onEditAdjust}
            />
            <Button
              label='Adjust Credit Limit'
              mainButton
              onClick={onEditCreditBalance}
            />
            </Can>
          </ButtonList>        

        <div className='position-relative'>
          {isLoadingInvoiceHistory && <Loader mode='blur' />}
          <WalletHistory
            invoices={invoiceList}
            onApproveInvoice={onApproveInvoice}
          />
          {invoiceMeta && (
            <Pagination
              onPageChange={setCurrentPage}
              totalCount={invoiceMeta.total}
              currentPage={invoiceMeta.current_page}
              pageSize={invoiceMeta.per_page}
            />
          )}
        </div>
      </div>
      <CreateWalletModal />
      <AdjustWalletModal />
      <CreditBalanceModal />
      <CreateInvoiceModal />
    </>
  )
}
