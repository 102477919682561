import React from 'react'
import {IOrder} from 'shared/types/order'
import {LoadWrapper} from 'shared/components/load-wrapper/load-wrapper'
import clsx from 'clsx'
import {AllOrdersTableRow} from './all-orders-table-row'
import {IReorder} from 'app/api/order-api/order-api'

export interface IOrdersTable {
  orders: IOrder[]
  isReorderLoading: boolean
  isPreorderCancelling: boolean
  preorderIdForLoader: number
  isLoading: boolean
  orderIdForLoader: number
  handleReorderAction: ({userId, orderId}: IReorder) => void
  handleCancelPreorderAction: (orderId: number) => void
}

export const AllOrdersTable: React.FC<IOrdersTable> = ({
  orders,
  isReorderLoading,
  isPreorderCancelling,
  isLoading,
  orderIdForLoader,
  preorderIdForLoader,
  handleReorderAction,
  handleCancelPreorderAction,
}) => {
  return (
    <div
      className={clsx('card-body px-0 table-responsive', !isLoading && 'py-3')}
    >
      <LoadWrapper isLoading={isLoading}>
        <table className='table gs-3 gy-4 gx-3 text-exl-primary'>
          <thead>
            <tr className='fw-normal fs-6 border-bottom border-gray-200 bg-light'>
              <th>Date</th>
              <th>Order #</th>
              <th>Company Name</th>
              <th>Source</th>
              <th>Order Total</th>
              <th>Profit</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          {orders && orders.length ? (
            <tbody>
              {orders.map((order: IOrder) => (
                <AllOrdersTableRow
                  key={order.id}
                  order={order}
                  isReorderLoading={isReorderLoading}
                  isPreorderCancelling={isPreorderCancelling}
                  preorderIdForLoader={preorderIdForLoader}
                  handleCancelPreorderAction={handleCancelPreorderAction}
                  handleReorderAction={handleReorderAction}
                  orderIdForLoader={orderIdForLoader}
                />
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className='border-bottom text-center text-muted'>
                <td colSpan={8}>No orders found with this filters.</td>
              </tr>
            </tbody>
          )}
        </table>
      </LoadWrapper>
    </div>
  )
}
