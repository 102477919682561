import React from "react"
import { Link } from "react-router-dom"
import { DASHBOARD_ROUTES } from "shared/config/routes"
import { IWarning } from "shared/types/codes-upload"

export const CustomToastForUploadCodes = (data:IWarning) => {
    return(
        <div>
            {
                data.errors?.length ? <p className='m-0 p-0'>{`${data.code_uploaded} from ${data.errors?.length+data.code_uploaded} codes uploaded! Duplicate code error!`}</p>
                 : <p className='m-0 p-0'>{`${data.code_uploaded} codes uploaded successfully!`}</p> 
            }
            
            <Link to={`${DASHBOARD_ROUTES.CODES.path}?uploads_history=${data.upload_history_id}`}>Click here to view.</Link>
        </div>
    )
}