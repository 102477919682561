/* eslint-disable react-hooks/rules-of-hooks */
import {useTemporaryUserState} from 'app/store/temporary-customer/state'

export const selectTemporaryUserId = () =>
  useTemporaryUserState((state) => state.userId)
export const selectTemporaryWalletsData = () =>
  useTemporaryUserState((state) => state.wallets)
export const selectTemporaryIsLoading = () =>
  useTemporaryUserState((state) => state.isLoading)
export const selectTemporaryIsLoadingWallet = () =>
  useTemporaryUserState((state) => state.isLoadingWallet)
export const selectTemporaryCompanyName = () =>
  useTemporaryUserState((state) => state.company_name)
export const selectTemporaryDefaultWallet = () =>
  useTemporaryUserState((state) => {
    if (!state.wallets && !state.wallets.length) return null
    return (
      state.wallets.find((wallet) => wallet.is_default === 1) ||
      state.wallets[0]
    )
  })
