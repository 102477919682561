import React from 'react'
import CheckBox from 'shared/components/check-box/check-box'
import clsx from 'clsx'
import {ICodesItem} from 'shared/types/codes-upload'
import {Link} from 'react-router-dom'
import {ORDERS_ROUTES} from 'shared/config/routes'
import { DateFormatter } from 'shared/utils/date-formatter'
import { CurrencyFormatter } from 'shared/helpers/currency-formatter'
import styles from './codes-table-row.module.scss'

interface ICodesTableRow {
  code: ICodesItem
  handleCheckboxChange: (id: number) => void
}

const CodesTableRowComponent: React.FC<ICodesTableRow> = ({code, handleCheckboxChange}) => {
  return (
    <tr className='border-bottom'>
      <td>
        <CheckBox
          onChange={() => handleCheckboxChange(code.id)}
          checked={code.checked}
        />
      </td>
      <td>
        {code.order ? (
          <Link
            to={ORDERS_ROUTES.USER_ORDER.generate(
              `${code.order.user_id}`,
              `${code.order.id}`
            )}
          >
            {code.order.id}
          </Link>
        ) : (
          '-'
        )}
      </td>
      <td className='min-w-100px' style={{whiteSpace:'pre'}}>{code.delivered_time ? DateFormatter(code.delivered_time) : '-'}</td>
      <td className={styles['column__truncated']}>{code.supplier_name}</td>
      <td className={'mw-150px text-truncate'}>{code.product_name}</td>
      <td>{code.purchase_price ? CurrencyFormatter(code.purchase_price,code.currency) : '-'}</td>
      <td>{code.sell_price ? CurrencyFormatter(code.sell_price,code.currency) : '-'}</td>      
      <td className={styles['column__truncated']}>
        {code.image && code.image.original_url ? (
          <a
            target='blank'
            href={code.image.original_url}
            className='text-primary'
          >
            {code.code}
          </a>
        ) : (
          code.code
        )}
      </td>
      <td>
        <span
          className={clsx(
            'badge',
            code.in_stock ? 'badge-light-success' : 'badge-light-danger'
          )}
        >
          {code.in_stock ? 'In Stock' : 'Out of stock'}
        </span>
      </td>
    </tr>
  )
}

export const CodesTableRow = React.memo(CodesTableRowComponent)
